import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";

import AssessmentTab from "../../../Components/PopUpView/Page/AssessmentLayout/AssessmentTab";
import ResultTab from "../../../Components/PopUpView/Page/PrizmLayout/ResultTab";
import SingleResult from "../../../Components/PopUpView/Page/PrizmLayout/ResultTab/SingleResult";

import "./index.scss";
// const initState = {
//   assessment: false,
//   result: false,
//   answer: false,
// };
const PrizmContent = (props) => {
  const { bookData, resourceData, prizmBookId, t, isReadyToRender, B2C, intervalId } =
    props;

  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;

  // const [isReadyToRender, setisReadyToRender] = useState(false);
  // const [initReady, setinitReady] = useState(initState);

  if (props.location.pathname.indexOf("/result") != -1) {
    return (
      <div className="card ani-card learnosity" id="assessment">
        <div
          className="body"
          style={
            {
              // paddingTop: "10px"
            }
          }
        >
          <Switch>
            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/result"
              component={(p) => (
                <ResultTab
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  prizmBookId={prizmBookId}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />
            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/result/:userId"
              component={(p) => (
                <SingleResult
                  t={t}
                  {...p}
                  action={"page"}
                  //   resourceId={resourceIdGa}
                  isInappBrowser={false}
                  //   studyGroupData={studyGroupData}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  //   resourceIdGa={resourceIdGa}
                  bookData={bookData}
                  prizmBookId={prizmBookId}
                  resourceData={resourceData}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {props.location.state?.studName && <p className="view-result-student-name">
          {props.location.state?.studName}
        </p>}
      <div
        className="card card-top-borders iframe-top-sections"
        id="prizm"
        style={{ marginBottom: "0px" }}
      >
        <Switch>
          <Route
            exact
            path="/a/studygroup/:id/assessment/:bookNunitId/:resId/start"
            component={(p) => (
              <AssessmentTab
                t={t}
                isInappBrowser={false}
                bookData={bookData}
                //   resourceIdGa={resourceIdGa}
                //   resourceIngestionIdGa={resourceIngestionIdGa}
                resourceData={resourceData}
                //   studyGroupData={studyGroupData}
                B2C={B2C}
                {...p}
                prizmBookId={prizmBookId}
                isReadyToRender={isReadyToRender}
                intervalId={intervalId}
              />
            )}
          />
          <Route
            exact
            path="/a/studygroup/:id/assessment/:bookNunitId/:resId/submission"
            component={(p) => (
              <AssessmentTab
                t={t}
                isInappBrowser={false}
                bookData={bookData}
                //   resourceIdGa={resourceIdGa}
                //   resourceIngestionIdGa={resourceIngestionIdGa}
                resourceData={resourceData}
                //   studyGroupData={studyGroupData}
                B2C={B2C}
                {...p}
                prizmBookId={prizmBookId}
                isReadyToRender={isReadyToRender}
                intervalId={intervalId}
              />
            )}
          />
        </Switch>
      </div>
      </>
    );
  }
};

export default PrizmContent;