/**
 * @generated SignedSource<<d9a9fe042d6529675e8ac45dec7f5905>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type SingleResultStudentReportHistoryQuery$variables = {|
  UserId: string,
  ResourceId: string,
  Lang: LangEnumType,
  Credential?: ?string,
|};
export type SingleResultStudentReportHistoryQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
      +IsCompleted: ?string,
      +AttemptDate: ?string,
      +LearnositySessionResult: ?string,
    |}>,
  |},
|};
export type SingleResultStudentReportHistoryQuery = {|
  variables: SingleResultStudentReportHistoryQuery$variables,
  response: SingleResultStudentReportHistoryQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Credential"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "IncludeAssignmentRecord",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "kind": "LinkedField",
  "name": "SelfLearnAttemptHistory",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IsCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "Credential",
          "variableName": "Credential"
        },
        {
          "kind": "Variable",
          "name": "Lang",
          "variableName": "Lang"
        }
      ],
      "kind": "ScalarField",
      "name": "LearnositySessionResult",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleResultStudentReportHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SingleResultStudentReportHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13a4c8debcc9ecdbf7255d7a6b266239",
    "id": null,
    "metadata": {},
    "name": "SingleResultStudentReportHistoryQuery",
    "operationKind": "query",
    "text": "query SingleResultStudentReportHistoryQuery(\n  $UserId: String!\n  $ResourceId: String!\n  $Lang: LangEnumType!\n  $Credential: String\n) {\n  user(UserId: $UserId) {\n    UserId\n    SelfLearnAttemptHistory(ResourceId: $ResourceId, IncludeAssignmentRecord: true) {\n      IsCompleted\n      AttemptDate\n      LearnositySessionResult(Lang: $Lang, Credential: $Credential)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ee2c4ab57fdee90dae9e948cd420dea6";

module.exports = ((node/*: any*/)/*: Query<
  SingleResultStudentReportHistoryQuery$variables,
  SingleResultStudentReportHistoryQuery$data,
>*/);
