import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../Components/Loading/PEC/Loading";
import ViewNotificationPopUp from "./ViewNotificationPopUp";
import ResourceIconMapping from "../../../Components/Feature/Function/ResourceIconMappingPEC";
import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { useSelector, useDispatch } from "react-redux";
import VidoeLightBox from "../../../Components/Feature/VideoLightBox";
import ImageLightBox from "../../../Components/Feature/ImageLightBox";
import sorting_arrow_black from "../../../assets/img/pec/all-images/sorting-arrow.png";
import sorting_arrow_black_asc from "../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc.png";
import sorting_arrow_black_desc from "../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc.png";
import sorting_arrow_white from "../../../assets/img/pec/all-images/sorting-arrow-white.png";
import sorting_arrow_white_asc from "../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc-white.png";
import sorting_arrow_white_desc from "../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc-white.png";
var browserRef = null;
import {
  isMobile,
  isBrowser,
  isTablet,
  isMobileOnly,
  osName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera,
} from "react-device-detect";
import ReadResourceMutation from "../../../Components/Feature/Function/ReadResourceMutation";
import UnitListGetSelfLearnConfig from "../../../Services/api/GraphQL/Query/UnitListGetSelfLearnConfig";

import UpdateSelfLearnConfigMutation from "../../../Services/api/GraphQL/Mutation/UpdateSelfLearnConfigMutation";
import * as XLSX from "xlsx";
import { data } from "jquery";
const UnitPageStudent = (props) => {
  const {
    t,
    selectedBookSeriesId,
    selectedBookId,
    ClassId,
    refreshBtn,
    selectedClass,
    showSDLPage,
    setShowSDLPage,
    toggleProgress,
    getScoreAndAverageClass,
    retainChapterFunc
  } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  
 // const [activeBook, setActiveBook] = useState(1);
  const [sortColumn, setSortColumn] = useState('')
  const [activeBook, setActiveBook] = useState(
    selectOptions.selectecResourceTab
      ? selectOptions.selectecResourceTab
      : 1
  );

  const [unitLoadingHere, setUnitLoadingHere] = useState(true);
  const [sortOrder, setSortOrder] = useState('desc');

  const [currentPage, setcurrentPage] = useState(1);
  const [originData, setoriginData] = useState([]);
  const [resourceCenterStructure, setresourceCenterStructure] = useState(null);
  const [resourceCenterStructureList, setresourceCenterStructureList] =
    useState([]);

  const [classUsers, setclassUsers] = useState([]);
  const [studentLoading, setstudentLoading] = useState(true);
  const [allStudentData, setallStudentData] = useState([]);

  // const [dummyreportData, setDummyreportData] = useState([]);

  const [classSummaryInfo, setclassSummaryInfo] = useState({});

  // const UnitPageGetBookDataQuery = graphql`
  //   query UnitPageGetBookDataQuery($BookId: [String]!) {
  //     BooksData(BookId: $BookId){
  //       id
  //       BookSeriesId
  //       LeapBookSeriesId
  //       BookSeriesName
  //       DisplaySequence
  //       Subject
  //       Books(BookId: String)
  //       ResourceAttrMappings
  //       BookSeriesImage
  //       BookSeriesBGImage
  //       BookSeriesColorTone
  //       EBookLinkage
  //       MasterWebLinkage
  //       DwbLinkage
  //     }
  //   }
  // `;

  const UnitPageStudentStructureResourcesListQuery = graphql`
    query UnitPageStudentStructureResourcesListQuery(
      $StudyGroupId: String!
      $StructureId: String!
      $SchoolYearId: String!
      $UserId: String!
      $PageSize: Int!
      $PageNo: Int!
      $StudyGroupIds: [String]!
    ) {
      resourceCenterStructure(StructureId: $StructureId) {
        StructureLang {
          Lang
          StructureTitle
        }
        SummaryData(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)
        RelatedResources(
          StudyGroupId: $StudyGroupId
          PageSize: $PageSize
          PageNo: $PageNo
          SchoolYearId: $SchoolYearId
        ) {
          Resources {
            IngestionId
            ResourceVisibilityStatus(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            )
            AssignementInvloved(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              AssignmentName
            }
            MappedLevels {
              LevelName {
                LevelName
              }
            }
            Thumbnail {
              FilePath
            }
            SelfLearnConfiguration(
              StudyGroupId: $StudyGroupIds
              SchoolYearId: $SchoolYearId
            ) {
              ConfigName
              ConfigValue
            }

            SelfLearnAttemptHistory(UserId: $UserId) {
              IsCompleted
              Score
              MaxScore
              AttemptDate
              LearnositySessionRawResult
              LearnositySessionResult
              LastAttempt
            }
            ResourceReadStatus(
              StructureId: $StructureId
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              id
              StructureId
              ResourceId
              SchoolYearId
              UserId
              Status
              StatusTime
            }

            SelfLearnAttemptHistoryByStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
              IncludeAssignmentRecord: true
            ) {
              UserDetail {
                UserId
                UserDisplayName {
                  Lang
                  UserName
                }
                BelongsToStudyGroup(
                  StudyGroupId: $StudyGroupId
                  SchoolYearId: $SchoolYearId
                ) {
                  UserId
                  ClassNumber
                }
              }
              Score
              MaxScore
              LearnositySessionRawResult
            }
            SelfLearnLastAttemptHistoryByStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
              IncludeAssignmentRecord: true
            ) {
              UserDetail {
                UserId
                UserDisplayName {
                  Lang
                  UserName
                }
                BelongsToStudyGroup(
                  StudyGroupId: $StudyGroupId
                  SchoolYearId: $SchoolYearId
                ) {
                  UserId
                  ClassNumber
                }
              }
              Score
              MaxScore
              LearnositySessionResult
              LearnositySessionRawResult
            }
            VisibleByStudent
            ShareableToStudent
            ResourceId
            RelatedId
            ResourceType
            FileSource
            ResourceLangs {
              Lang
              ResourceName
            }
            Linkage
            ResourceAccessUrl
            ResourceDownloadUrl
            Downloadable
          }
          TotalNumberOfRecord
        }
      }
    }
  `;

  const UnitPageStudentGetStudentReportQuery = graphql`
    query UnitPageStudentGetStudentReportQuery(
      $UserId: String!
      $StudyGroupId: String!
      $SchoolYearId: String!
      $BookSeriesId: String
      $PageSize: Int!
      $PageNo: Int!
      $BookId: String
    ) {
      studyGroup(StudyGroupId: $StudyGroupId) {
        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
          ClassNumber
          User {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
          }
        }
      }
      user(UserId: $UserId) {
        AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
          Books(BookId: $BookId) {
            BookId
            BookName {
              Lang
              BookName
            }
            ResourceCenterStructure(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              StructureId
              SummaryData(
                StudyGroupId: $StudyGroupId
                SchoolYearId: $SchoolYearId
              )
              StructureLang {
                Lang
                StructureTitle
              }
              ParentStructureId
              DisplaySequence
              ChildStructure {
                StructureId
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructureId
                DisplaySequence
              }
              RelatedResourcesReadStatus(
                StudyGroupId: $StudyGroupId
                SchoolYearId: $SchoolYearId
              ) {
                id
                StructureId
                ResourceId
                SchoolYearId
                UserId
                Status
                StatusTime
              }
              RelatedResources(
                StudyGroupId: $StudyGroupId
                PageSize: $PageSize
                PageNo: $PageNo
                SchoolYearId: $SchoolYearId
              ) {
                Resources {
                  IngestionId
                  ResourceId
                  ResourceType
                  MappedLevels {
                    LevelName {
                      LevelName
                    }
                  }

                  SelfLearnLastAttemptHistoryByStudyGroup(
                    StudyGroupId: $StudyGroupId
                    SchoolYearId: $SchoolYearId
                    IncludeAssignmentRecord: false
                  ) {
                    LearnositySessionRawResult
                    LearnositySessionResult
                    AttemptSessionId
                    AttemptDate
                    UserDetail {
                      UserId
                      UserDisplayName {
                        Lang
                        UserName
                      }
                      BelongsToStudyGroup(
                        StudyGroupId: $StudyGroupId
                        SchoolYearId: $SchoolYearId
                      ) {
                        UserId
                        ClassNumber
                      }
                    }
                  }
                }
                TotalNumberOfRecord
              }
            }
          }
        }
      }
    }
  `;

  // view settings code starts here

  // view settings code ends here
  const getStudentList = () => {
    var variables = {
      UserId: userData.UserId,
      BookSeriesId: selectedBookSeriesId,
      BookId: selectedBookId,
      StudyGroupId: ClassId || "",
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      PageNo: currentPage,
      PageSize: 20,
    };
    return fetchQuery(
      environment,
      UnitPageStudentGetStudentReportQuery,
      variables
    );
  };

  // useEffect(() => {
  //   if (ClassId != "" && selectedBookId != "") {
  //     getStudentList().then((data) => {
  //     });
  //   } }, []);



  useEffect(() => {
    
    if (ClassId != "" && selectedBookId != "") {
      //alert(activeBook);
      console.log("ActiveBook",activeBook);
      setUnitLoadingHere(true);
      setstudentLoading(true);
      if(activeBook == 1 || activeBook == selectedBookId){
        manipulateData("Book", selectedBookId);
      }else{
        manipulateData("Structure", selectOptions.selectecResourceTab);
      }
      
    }
console.log("retain", selectOptions.selectecResourceTab)
    //setActiveBookName(BookLists[0].unit);
  }, [ClassId, selectedBookId,refreshBtn]);

 
  const manipulateData = (type, argfilter) => {
    console.log("Arg",argfilter, type)
    //alert("comin")
    getStudentList().then((data) => {
      setoriginData([]);
      setresourceCenterStructureList([]);
      const { user: { AdoptedBookSeries } = {} } = data || {};

      const {
        studyGroup: { StudyGroupUser = [{}] },
      } = data || {};
      let findOnlyOneStudyId;

      //const booksList = AdoptedBookSeries[0];

      let booksListT = data.user.AdoptedBookSeries[0].Books.filter(
        (o) => o.BookId == selectedBookId
      );

      let booksList = booksListT[0];

      var preSetData = [],
        topLevelIds = [],
        callback = JSON.parse(
          JSON.stringify(booksList.ResourceCenterStructure)
        );

      preSetData.push({
        book: {
          bookseriesId: booksList.BookSeriesId,
          DisplaySequence: booksList.DisplaySequence,
          BookSeriesName: booksList.BookSeriesName,
          options: [],
        },
      });
      var allReadRes = [];
      callback.map((robj, rinde) => {
        topLevelIds.push(robj.StructureId);
        //get all parent first
        robj.RelatedResourcesReadStatus.map((readObj, rind) => {
          allReadRes.push(readObj);
        });

        if (robj.ParentStructureId == null || robj.ParentStructureId == "") {
          preSetData[0].book.options.push(robj);
        }
      });

      preSetData[0].book.options.map((oobj, ooindex) => {
        var tempSave = oobj.ChildStructure,
          newChil = [];
        tempSave.map((tc, tindex) => {
          if (topLevelIds.indexOf(tc.StructureId) != -1) {
            newChil.push(tc);
          }
        });
        oobj.ChildStructure = newChil;
        oobj.ChildStructure.map((cobj, cindex) => {
          if (
            callback
              .map((i) => {
                return i.ParentStructureId;
              })
              .indexOf(cobj.StructureId) != -1 &&
            topLevelIds.indexOf(oobj.StructureId) != -1
          ) {
            callback.map((i) => {
              if (
                i.ParentStructureId == cobj.StructureId &&
                topLevelIds.indexOf(i.StructureId) != -1
              ) {
                if (!cobj.ChildStructure) {
                  cobj.ChildStructure = [];
                  cobj.ChildStructure.push(i);
                } else {
                  cobj.ChildStructure.push(i);
                }
              }
            });
          }
        });
      });
      preSetData.map((i) => {
        i.book.options.sort((a, b) => {
          return parseInt(a.DisplaySequence) - parseInt(b.DisplaySequence);
        });
      });

      preSetData.map((obj, index) => {
        obj.book.options.map((oobj, oindex) => {
          if (oobj.ParentStructureId == null || oobj.ParentStructureId == "") {
          }
        });
      });

      // if (preSetData[0].book.options.length > 0) {
      //   setActiveBookName(
      //     preSetData[0].book.options[0].StructureLang[
      //       preSetData[0].book.options[0].StructureLang[0].Lang == lang
      //         ? 0
      //         : 1
      //     ].StructureTitle
      //   );
      //   setActiveBook(preSetData[0].book.options[0].StructureId);

      // }

      var unitDataWithBook = [];
      if (type == "Book") {
        setActiveBook(selectedBookId);
      }

      var displaySeq = 1;
      preSetData.map((obj, index) => {
        obj.book.options.map((oobj, oindex) => {
          var gradedResources = [];
          var viewResources = [];
          var readResources = [];
          if (oobj.ChildStructure.length > 0) {
            oobj.ChildStructure.map((childobj, oindex) => {
              let csres = callback.filter(
                (o) => o.StructureId == childobj.StructureId
              );

              var readResFilter = allReadRes.filter(
                (o) => o.StructureId == childobj.StructureId
              );
              readResFilter.map((readObj, iv) => {
                readResources.push(readObj);
              });

              if (csres[0].RelatedResources.TotalNumberOfRecord > 0) {
                csres[0].RelatedResources.Resources.map((resObj, oindex) => {
                  if (
                    resObj.ResourceType == "PRIZM" ||
                    resObj.ResourceType == "LEARNOSITY"
                  ) {
                    gradedResources.push(resObj);
                  } else {
                    viewResources.push(resObj);
                  }
                });
              }
            });
          }
          var summaryInfo = JSON.parse(oobj.SummaryData);
          var progressClassVal = "";
          var progressClassName = "large-progressbar-highest";
          if (summaryInfo.avgScore === null) {
          } else {
            progressClassVal = Math.round(summaryInfo.avgScore);
          }

          var resobj = {
            Name: oobj.StructureLang[oobj.StructureLang[0].Lang == lang ? 0 : 1]
              .StructureTitle,
            AvgScore: progressClassVal,
            Type: "Unit",
            StructureId: oobj.StructureId,
            RelatedResources: readResources,
            GradedResources: gradedResources,
            ViewResources: viewResources,
            ChildStructure: oobj.ChildStructure,
            DisplayLevel: displaySeq + 1,
          };
          unitDataWithBook.push(resobj);
        });
      });
      var gradeResourcesSummary = [];
      var viewResourcesSummary = [];
      var relatedResourcesSummary = [];
      var bookSummaryTotalavg = 0;
      var avgTotCount = 0;
      unitDataWithBook.map((oobj, oindex) => {
        if (oobj.AvgScore != "") {
          avgTotCount = avgTotCount + 1;
          bookSummaryTotalavg = bookSummaryTotalavg + oobj.AvgScore;
        }

        oobj.GradedResources.map((gobj, ind) => {
          gradeResourcesSummary.push(gobj);
        });
        oobj.ViewResources.map((vobj, ind) => {
          viewResourcesSummary.push(vobj);
        });
        oobj.RelatedResources.map((robj, ind) => {
          relatedResourcesSummary.push(robj);
        });
      });
      var bookSumAvg = "";
      if (avgTotCount > 0) {
        bookSumAvg = Math.round(bookSummaryTotalavg / avgTotCount);
      }
      var obj = {
        Name: "Book Summary",
        AvgScore: bookSumAvg,
        Type: "Book",
        StructureId: selectedBookId,
        GradedResources: gradeResourcesSummary,
        ViewResources: viewResourcesSummary,
        RelatedResources: relatedResourcesSummary,
        DisplayLevel: 1,
        ChildStructure: [],
      };
      unitDataWithBook.push(obj);
      unitDataWithBook.sort((a, b) => {
        return parseInt(a.DisplayLevel) - parseInt(b.DisplayLevel);
      });

      if (type == "Book") {
        let booksListT = unitDataWithBook.filter((o) => o.Type == "Book");
        var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
        handleTeacherReportData(booksListT, StudyGroupUserTemp);
      } else {
        let booksListT = unitDataWithBook.filter(
          (o) => o.StructureId == argfilter
        );
        var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
        handleTeacherReportData(booksListT, StudyGroupUserTemp);
      }
      setclassUsers(StudyGroupUserTemp);
      setresourceCenterStructureList(unitDataWithBook);
      setoriginData(callback);
      setUnitLoadingHere(false);
      setstudentLoading(false);

      // setstudyGroupData(mixedData);

      // initGuide(skipGuide, skipMenuSteps);
    });
  };
  const handleTeacherReportData = (dataLists, studentLists) => {
    let teacherReportTableData = [],
      attemptsData = [],
      questionCount = 0;

    if (studentLists === null || typeof studentLists == "undefined") {
      studentLists = [];
    }
    if (dataLists === null || typeof dataLists == "undefined") {
      dataLists = [];
    }
    var userInfo = [];
    var userDetails = [];
    var gradeResLen = 0;
    var viewResLen = 0;
    var totalAttemptView = 0;
    var totalAttempt = 0;
    var classSummary = {
      AttemptgradedRes: 0,
      GradeResLen: 0,
      Percentage: 0,
      TotalAttempt: 0,
      ResPercentage: 0,
      TotPercentage: 0,
      ViewResPercentage: 0,
      ViewTotPercentage: 0,
      AvgScore: 0,
    };

    var userInfoView = [];
    var userDetailsView = [];

    dataLists.map((allObj, index) => {
      if (viewResLen == 0)
        viewResLen = viewResLen + allObj.ViewResources.length;

      allObj.ViewResources.map((viewObj, vindex) => {
        if (allObj.RelatedResources) {
          //allObj
          var readResourceStatus = allObj.RelatedResources.filter(
            (o) => o.ResourceId == viewObj.ResourceId
          );
          readResourceStatus.map((readObj, aindex) => {
            if (userInfoView.includes(readObj.UserId)) {
              totalAttemptView = totalAttemptView + 1;
              var userIndex = userDetailsView.findIndex(
                (x) => x.UserId === readObj.UserId
              );
              userDetailsView[userIndex].AttemptedViewRes =
                userDetailsView[userIndex].AttemptedViewRes + 1;
              userDetailsView[userIndex].ViewResourcesLength = viewResLen;
              userDetailsView[userIndex].ViewAvgCount =
                userDetailsView[userIndex].ViewAvgCount + 1;
            } else {
              totalAttemptView = totalAttemptView + 1;
              userInfoView.push(readObj.UserId);
              userDetailsView.push({
                UserId: readObj.UserId,
                ViewResourcesLength: viewResLen,
                ViewAvgCount: 1,
                AttemptedViewRes: 1,
              });
            }
          });
        }
      });
    });
    dataLists.map((allObj, index) => {
      if (gradeResLen == 0)
        gradeResLen = gradeResLen + allObj.GradedResources.length;
      allObj.GradedResources.map((gradeObj, gindex) => {
        gradeObj.SelfLearnLastAttemptHistoryByStudyGroup.map((attm, aindex) => {
          var json_data = JSON.parse(attm.LearnositySessionRawResult);
          if (userInfo.includes(attm.UserDetail.UserId)) {
            totalAttempt = totalAttempt + 1;
            var userIndex = userDetails.findIndex(
              (x) => x.UserId === attm.UserDetail.UserId
            );
            userDetails[userIndex].AttemptedGradedRes =
              userDetails[userIndex].AttemptedGradedRes + 1;
            userDetails[userIndex].GradedResourcesLength = gradeResLen;
            userDetails[userIndex].AvgCount =
              userDetails[userIndex].AvgCount + 1;

            if (gradeObj.ResourceType == "PRIZM") {
              userDetails[userIndex].Score =
                (json_data.score.raw / json_data.score.max) * 100 +
                userDetails[userIndex].AvgScore;
              userDetails[userIndex].AvgScore = userDetails[userIndex].Score;
            } else {
              userDetails[userIndex].Score =
                (json_data.score / json_data.max_score) * 100 +
                userDetails[userIndex].AvgScore;
              userDetails[userIndex].AvgScore = userDetails[userIndex].Score;
            }
          } else {
            userInfo.push(attm.UserDetail.UserId);
            totalAttempt = totalAttempt + 1;
            if (gradeObj.ResourceType == "PRIZM") {
              var avgScore = (json_data.score.raw / json_data.score.max) * 100;

              userDetails.push({
                UserId: attm.UserDetail.UserId,
                UserDisplayName: attm.UserDetail.UserDisplayName,
                GradedResourcesLength: gradeResLen,
                AvgCount: 1,
                AttemptedGradedRes: 1,
                Score: avgScore,
                AvgScore: avgScore,
              });
            } else {
              //totalAttempt= totalAttempt+1;
              var avgScore = (json_data.score / json_data.max_score) * 100;

              userDetails.push({
                UserId: attm.UserDetail.UserId,
                UserDisplayName: attm.UserDetail.UserDisplayName,
                GradedResourcesLength: allObj.GradedResources.length,
                AttemptedGradedRes: 1,
                AvgCount: 1,
                Score: avgScore,
                AvgScore: avgScore,
              });
            }
          }
        });
      });
    });
    const map = new Map();
    userDetails.forEach((item) => map.set(item.UserId, item));
    userDetailsView.forEach((item) =>
      map.set(item.UserId, { ...map.get(item.UserId), ...item })
    );
    var finalUserInfo = [];
    finalUserInfo = Array.from(map.values());
    classSummary.GradeResLen = gradeResLen;
    classSummary.ViewResLen = viewResLen;
    classSummary.AttemptgradedRes = userInfo.length;
    classSummary.TotalAttempt = totalAttempt;

    if (studentLists.length > 0)
      classSummary.ResPercentage = Math.round(
        totalAttempt / studentLists.length
      );
    if (gradeResLen > 0)
      classSummary.TotPercentage = !totalAttempt
        ? ""
        : Math.round((classSummary.ResPercentage / gradeResLen) * 100);
    if (studentLists.length > 0)
      classSummary.ViewResPercentage = Math.round(
        totalAttemptView / studentLists.length
      );
    if (viewResLen > 0)
      classSummary.ViewTotPercentage = !totalAttemptView
        ? ""
        : Math.round((classSummary.ViewResPercentage / viewResLen) * 100);
    classSummary.AvgScore = !totalAttempt
      ? ""
      : Math.round(dataLists[0].AvgScore);
    classSummary.TotalAttempt = totalAttempt;
    classSummary.TotalAttemptView = totalAttemptView;

    if (studentLists.length > 0) {
      studentLists.map((obj, index) => {
        var matchId = finalUserInfo
          .map((i) => {
            return i.UserId;
          })
          .indexOf(obj.User.UserId);
        if (matchId != -1) {
          if (!finalUserInfo[matchId].UserDisplayName) {
            finalUserInfo[matchId].UserDisplayName = obj.User.UserDisplayName;
          }
          if (!finalUserInfo[matchId].GradedResourcesLength) {
            finalUserInfo[matchId].GradedResourcesLength = gradeResLen;
          }
          if (!finalUserInfo[matchId].AttemptedGradedRes) {
            finalUserInfo[matchId].AttemptedGradedRes = 0;
          }
          if (!finalUserInfo[matchId].ViewResourcesLength) {
            finalUserInfo[matchId].ViewResourcesLength = viewResLen;
          }
          if (!finalUserInfo[matchId].AttemptedViewRes) {
            finalUserInfo[matchId].AttemptedViewRes = 0;
          }
          if (!finalUserInfo[matchId].ViewAvgCount) {
            if (finalUserInfo[matchId].ViewAvgCount != 0) {
              finalUserInfo[matchId].ViewAvgCount = "";
            }
          }
          if (!finalUserInfo[matchId].AvgScore) {
            if (finalUserInfo[matchId].AvgScore != 0) {
              finalUserInfo[matchId].AvgScore = "";
            }
          }
          if (!finalUserInfo[matchId].Score) {
            if (finalUserInfo[matchId].Score != 0) {
              finalUserInfo[matchId].Score = "";
            }
          }
          finalUserInfo[matchId].classNum = obj.ClassNumber;
          teacherReportTableData.push(finalUserInfo[matchId]);
        } else {
          teacherReportTableData.push({
            UserId: obj.User.UserId,
            classNum : obj.ClassNumber,
            UserDisplayName: obj.User.UserDisplayName,
            GradedResourcesLength: gradeResLen,
            AttemptedGradedRes: 0,
            ViewResourcesLength: viewResLen,
            AttemptedViewRes: 0,
            ViewAvgCount: "",
            AvgScore: "",
            Score: "",
          });
        }
      });
    }
    var studentObj = [];
    teacherReportTableData.map((uobj, uindex) => {
      var avgScore = "";
      if (uobj.Score != "") {
        if (uobj.AvgCount > 0)
          avgScore = Math.round(uobj.Score / uobj.AvgCount);
      } else {
        if (uobj.Score == 0) {
          if (uobj.AvgCount > 0)
            avgScore = Math.round(uobj.Score / uobj.AvgCount);
        }
      }
      var avgProgress = "";
      var viewAvgProgress = "";
      if (uobj.GradedResourcesLength > 0)
        avgProgress = Math.round(
          (uobj.AttemptedGradedRes / uobj.GradedResourcesLength) * 100
        );

      if (uobj.ViewResourcesLength > 0)
        viewAvgProgress = Math.round(
          (uobj.AttemptedViewRes / uobj.ViewResourcesLength) * 100
        );

      // studentObj.push({
      //   id: uindex,
      //   student_number: uindex + 1,
      //   student_name: uobj.UserDisplayName[0].UserName,
      //   score: avgScore,
      //   average_progress: avgProgress,
      //   completion: uobj.AttemptedGradedRes + "/" + uobj.GradedResourcesLength,
      //   view_progress: viewAvgProgress,
      //   view_completion: uobj.AttemptedViewRes + "/" + uobj.ViewResourcesLength,
      // });
      studentObj.push({
        id: uindex,
        student_number: uobj.classNum,
        student_name: uobj.UserDisplayName[0].UserName,
        score: avgScore,
        average_progress: uobj.AttemptedGradedRes ? avgProgress : "",
        completion: uobj.AttemptedGradedRes + "/" + uobj.GradedResourcesLength,
        view_progress: uobj.AttemptedViewRes ? viewAvgProgress : "",
        view_completion: uobj.AttemptedViewRes + "/" + uobj.ViewResourcesLength,
      });
    });
    console.log("studentObj", studentObj);
    setallStudentData(studentObj);
    setclassSummaryInfo(classSummary);
    setstudentLoading(false);
  };

  const updateStudentListing = (strutureId, type) => {
    if (type == "Book") {
      manipulateData("Book", strutureId);
    } else {
      manipulateData("Structure", strutureId);
    }
  };

  const alphabetically = (a, b) => {
    // equal items sort equally
    if (a.score === b.score) {
        return 0;
    }
    // nulls sort after anything else
    if (a.score === null || a.score === "") {
        return 1;
    }
    if (b.score === null || b.score === "") {
        return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    return parseInt(a.score) < parseInt(b.score) ? -1 : 1;
    // if descending, highest sorts first
    //return a.score < b.score ? 1 : -1;
  }

  const alphabeticallyDesc = (a, b) => {
    // equal items sort equally
    if (a.score === b.score) {
        return 0;
    }

    // nulls sort after anything else
    if (a.score === null || a.score === "") {
        return 1;
    }
    if (b.score === null || b.score === "") {
        return -1;
    }  
    // if descending, highest sorts first
    return parseInt(a.score) < parseInt(b.score) ? 1 : -1;
  }

  return unitLoadingHere ? (
    <Loading type={`normal`} />
  ) : (
    <>
      {!showSDLPage ? (
        <>
          <div className="main__section-unit">
            {/* <button
              onClick={() => {
                dowloadReport();
              }}
              className="btns buttons sdl-download-btn"
            >
              {t("Download Report")}
            </button> */}
            {/* <button
              onClick={() => {
                exportExcelSheetData();
              }}
              className="btns buttons sdl-download-btn"
            >
              {t("Export Excel Report")}
            </button> */}
            {resourceCenterStructureList.map((oobj, oindex) => {
              var progressClassVal = "";
              var progressClassName = "";
              if (oobj.AvgScore != "") {
                progressClassVal = Math.round(oobj.AvgScore);
                // var progressClassName = "large-progressbar-highest";
                progressClassName = getScoreAndAverageClass(
                  progressClassVal,
                  "largescroll"
                );
              }

              return (
                <React.Fragment key={oindex}>
                  <div
                    className={`main__section-unit-section ${
                      activeBook == oobj.StructureId ? "active" : ""
                    }`}
                    onClick={() => {
                      // setUnitLoading(true);
                      setstudentLoading(true);
                      setActiveBook(oobj.StructureId);

                      updateStudentListing(oobj.StructureId, oobj.Type);
                      retainChapterFunc(
                        oobj.StructureId,
                        oobj.Name
                      );
                    }}
                  >
                    <h4 className="main__section-unit-heading">{oobj.Name}</h4>
                    <div className="main__section-unit-details">
                      <span className="main__section-unit-details-label">
                        {props.t("Avg. Score")}
                      </span>
                      {!toggleProgress || progressClassVal == 0 ? (
                        <span
                          className={`main__section-unit-details-progress ${
                            activeBook == oobj.StructureId ? "active" : ""
                          }`}
                        >
                          <i className="main__section-unit-details-progress-bar">
                            -
                          </i>
                        </span>
                      ) : (
                        <div
                          className={`large-progressbar ${progressClassName}`}
                          role="large-progressbar"
                          aria-valuenow={progressClassVal}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ "--value": progressClassVal }}
                        ></div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {studentLoading ? (
            <Loading type={`normal`} />
          ) : (
            <div className="main__section-student-unit-grid">
              {/* Students Details Table */}
              <table className="main__section-student-unit-grid-details">
                <thead>
                  <tr className="main__section-student-unit-grid-details-top-row">
                    <th
                      className="main__section-student-unit-grid-details-topheading"
                      colSpan={2}
                    >
                      &nbsp;
                    </th>
                  </tr>
                  <tr className="main__section-student-unit-grid-details-bottom-row">
                    <th className={`main__section-student-unit-grid-details-bottomheading-primary ${!toggleProgress ? 'disable-sorting-click': ''}`} 
                      onClick={()=>{
                        setSortColumn('No')
                        const sortedData = allStudentData.sort((a,b) => sortOrder=='asc' ? b.student_number - a.student_number : a.student_number - b.student_number);
                        setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                        setallStudentData(sortedData)
                      }}>
                      {t("No")}
                      {toggleProgress && <img
                        src={sortColumn != 'No' ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_asc : sorting_arrow_black_desc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                    <th className={`main__section-student-unit-grid-details-bottomheading-primary ${!toggleProgress ? 'disable-sorting-click': ''}`}
                    onClick={()=>{
                      setSortColumn('Student Name')
                      const sortedData = allStudentData.sort(function(a, b){                   
                        if(sortOrder=='asc'){
                          if(a.student_name < b.student_name) { return -1; }
                          if(a.student_name > b.student_name) { return 1; }
                          return 0;
                        } else {
                          if(a.student_name < b.student_name) { return 1; }
                          if(a.student_name > b.student_name) { return -1; }
                          return 0;
                        }
                      })
                      setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                      setallStudentData(sortedData)
                    }}>
                      {t("Student Name")}
                      {toggleProgress && <img
                        src={sortColumn != 'Student Name' ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_desc : sorting_arrow_black_asc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                  </tr>
                </thead>
                <tr className="main__section-student-unit-grid-details-up-row">
                  <td>-</td>
                  <td>
                    {/* {t("Class")} {selectedClass} */}
                    {`${selectedClass.includes('Class') ? '' : t("Class")}  ${selectedClass}`}
                  </td>
                </tr>

                {allStudentData.map((data) => {
                  return (
                    <tr className="main__section-student-unit-grid-details-down-row">
                      <td className="main__section-student-unit-grid-details-down-row-number">
                        {data.student_number}
                      </td>
                      <td className="main__section-student-unit-grid-details-down-row-name">
                        {data.student_name}
                      </td>
                    </tr>
                  );
                })}
              </table>
              {/* Graded Resources Table */}
              <table className="main__section-student-unit-grid-grade main__section_centerAln">
                <thead>
                  <tr className="main__section-student-unit-grid-grade-top-row">
                    <th
                      colSpan={3}
                      className="main__section-student-unit-grid-details-topheading"
                    >
                      {/* {t("Graded Resources")} */}
                      {t("Auto-marked Exercises​")}
                    </th>
                  </tr>
                  <tr className="main__section-student-unit-grid-grade-bottom-row">
                    <th className={`main__section-student-unit-grid-details-bottomheading ${!toggleProgress ? 'disable-sorting-click': ''}`}
                    onClick={()=>{
                      setSortColumn('Avg. Score')
                      // const sortedData = allStudentData.sort((a,b) => {
                      //   console.log('cvbcvbcvbcv', {a, b})
                      //   if(a.score == "" && a.score!= "0"){
                      //     return sortOrder =='asc' ? 1 : -1
                      //   } else if(a.score == "0" && sortOrder =='asc'){
                      //     console.log('cvbcvbcvbcv-inside zero')
                      //     return sortOrder =='asc' ? -1 : -1 
                      //   }
                      //   else if(a.score == "0" && a.score != ""){
                      //     // return sortOrder =='asc' ? b.score??0 - 0 : 0 - b.score ?? 0
                      //     console.log('cvbcvbcvbcv-inside zero')
                      //     return sortOrder =='asc' ? -1 : -1 
                      //   } else {
                      //     return sortOrder =='asc' ? b.score - a.score : a.score - b.score
                      //   }
                      //   // return sortOrder =='asc' ? parseInt(b.score) - parseInt(a.score) : parseInt(a.score) - parseInt(b.score)
                      //   // return sortOrder =='asc' ? parseInt(b.score??0) - parseInt(a.score??0) : parseInt(a.score??0) - parseInt(b.score??0)
                      // });
                      var sortedDataOverAll;
                      if(sortOrder == "asc"){
                        sortedDataOverAll = allStudentData.sort(alphabeticallyDesc)
                      }else{
                        sortedDataOverAll = allStudentData.sort(alphabetically)
                      }
                      setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                      setallStudentData(sortedDataOverAll)
                    }}>
                      {/* {t("Score")} */}
                      {t("Avg. Score​")}
                      {toggleProgress && <img
                        src={sortColumn != 'Avg. Score' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                    <th className={`main__section-student-unit-grid-details-bottomheading ${!toggleProgress ? 'disable-sorting-click': ''}`}
                    onClick={()=>{
                      setSortColumn('Progress')
                      // const sortedData = allStudentData.sort((a,b) => sortOrder=='asc' ? b.average_progress - a.average_progress : a.average_progress - b.average_progress);
                      const sortedData = allStudentData.sort((a,b)=>{
                        if(sortOrder == "asc"){
                          // equal items sort equally
                          if (a.average_progress === b.average_progress) {
                            return 0;
                          }
                      
                          // nulls sort after anything else
                          if (a.average_progress === null || a.average_progress === "") {
                            return 1;
                          }
                          if (b.average_progress === null || b.average_progress === "") {
                            return -1;
                          }  
                          // if descending, highest sorts first
                          return a.average_progress < b.average_progress ? 1 : -1;
                        } else {
                          if (a.average_progress === b.average_progress) {
                              return 0;
                          }
                          // nulls sort after anything else
                          if (a.average_progress === null || a.average_progress === "") {
                            return 1;
                          }
                          if (b.average_progress === null || b.average_progress === "") {
                            return -1;
                          }
                      
                          // otherwise, if we're ascending, lowest sorts first
                          return a.average_progress < b.average_progress ? -1 : 1;
                        }
                      });
                      setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                      setallStudentData(sortedData)
                    }}>
                      {/* {t("Average Progress")} */}
                      {t("Progress​")}
                      {toggleProgress && <img
                        src={sortColumn != 'Progress' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                    <th className={`main__section-student-unit-grid-details-bottomheading ${!toggleProgress ? 'disable-sorting-click': ''}`}
                      onClick={()=>{
                        setSortColumn('Completion')
                        console.log('allStudentData', allStudentData)
                        const sortedData = allStudentData.sort((a,b) =>{
                          console.log('progressdata', a)
                        const firstData =  a.completion.split('/')
                        const secondData =  b.completion.split('/')
                        // return sortOrder=='asc' ? secondData[0] - firstData[0] : firstData[0] - secondData[0]
                        if(sortOrder == "asc"){
                          // equal items sort equally
                          if (firstData[0] === secondData[0]) {
                            return 0;
                          }
                      
                          // nulls sort after anything else
                          if (firstData[0] === null || firstData[0] === "") {
                            return 1;
                          }
                          if (secondData[0] === null || secondData[0] === "") {
                            return -1;
                          }  
                          // if descending, highest sorts first
                          return parseInt(firstData[0]) < parseInt(secondData[0]) ? 1 : -1;
                        } else {
                          firstData[0] = firstData[0]== 0 && a.average_progress=="" ? firstData[1] : firstData[0];
                          secondData[0] = secondData[0]== 0 && b.average_progress=="" ? firstData[1] : secondData[0];
                          console.log('firstData[0]'+firstData[0])
                          if(firstData[0] == 0){
                            return -1
                          }
                          if (firstData[0] === secondData[0]) {
                            return 1;
                          }

                          // nulls sort after anything else
                          if (firstData[0] === null || firstData[0] === "") {
                            return -1;
                          }
                          if (secondData[0] === null || secondData[0] === "") {
                            return 1;
                          }  
                          // if descending, highest sorts first
                          return parseInt(firstData[0]) < parseInt(secondData[0]) ? -1 : 1;
                        }
                        });
                        setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                        setallStudentData(sortedData)
                      }} 
                    >
                      {t("Completion")}
                      {toggleProgress && <img
                        src={sortColumn != 'Completion' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                  </tr>
                </thead>
                <tr className="main__section-student-unit-grid-grade-up-row">
                  <td
                    className={`${
                      toggleProgress
                        ? "column-data-with-progress-color"
                        : "column-data-without-progress-color"
                    }`}
                  >
                    {toggleProgress && classSummaryInfo.TotalAttempt > 0 ? (
                      <div
                        className={`student-progressbar ${getScoreAndAverageClass(
                          classSummaryInfo.AvgScore,
                          "score"
                        )}`}
                        role="progressbar"
                        aria-valuenow={classSummaryInfo.AvgScore}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ "--value": classSummaryInfo.AvgScore }}
                      ></div>
                    ) : (
                      <div><span
                        className={`main__section-unit-grid-details-student-progress active`}
                      >
                        <i className="main__section-unit-grid-details-student-progress-bar active">
                          -
                        </i>
                      </span>
                      </div>
                    )}
                  </td>
                  <td
                    className={`${
                      toggleProgress
                        ? "column-data-with-progress-color"
                        : "column-data-without-progress-color"
                    }`}
                  >
                    {toggleProgress && classSummaryInfo.TotalAttempt > 0 ? (
                      <div className="main__progress-linebar">
                        <div className="progress-linebar">
                          <div
                            className={`${getScoreAndAverageClass(
                              classSummaryInfo.TotPercentage,
                              "defaultAverageTop"
                            )}`}
                            style={{
                              width: `${classSummaryInfo.TotPercentage}%`,
                            }}
                          ></div>
                        </div>
                        <span
                          className={`progress-linebar-percentage ${getScoreAndAverageClass(
                            classSummaryInfo.TotPercentage,
                            "defaultAverageTop"
                          )}-text`}
                        >
                          {classSummaryInfo.TotPercentage}%
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    className={`${
                      toggleProgress
                        ? "column-data-with-progress-color"
                        : "column-data-without-progress-color"
                    }`}
                  >
                    {/* {toggleProgress
                      ? classSummaryInfo.ResPercentage +
                        "/" +
                        classSummaryInfo.GradeResLen
                      : "-"} */}
                  </td>
                </tr>
                {allStudentData.map((data) => {
                  var studentProgressLevelClass = getScoreAndAverageClass(
                    data.score,
                    "score"
                  );

                  var studentProgressLineClass = getScoreAndAverageClass(
                    data.average_progress,
                    "defaultAverageBottom"
                  );
                  return (
                    <tr className="main__section-student-unit-grid-grade-down-row">
                      <td
                        className={`${
                          toggleProgress
                            ? "column-data-with-progress"
                            : "column-data-without-progress"
                        }`}
                      >
                        {toggleProgress && studentProgressLevelClass != "" ? (
                          <div
                            key={data.id}
                            className={`student-progressbar ${studentProgressLevelClass}`}
                            role="progressbar"
                            aria-valuenow={data.score}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ "--value": data.score }}
                          ></div>
                        ) : (
                          <div>
                          <span
                            className={`main__section-unit-grid-details-student-progress`}
                          >
                            <i className="main__section-unit-grid-details-student-progress-bar">
                              -
                            </i>
                          </span>
                          </div>
                        )}
                      </td>
                      <td
                        className={`${
                          toggleProgress
                            ? "column-data-with-progress"
                            : "column-data-without-progress"
                        }`}
                      >
                        {toggleProgress ? (
                          <div className="main__progress-linebar">
                            <div className="progress-linebar">
                              <div
                                className={`${studentProgressLineClass}`}
                                style={{ width: `${data.average_progress}%` }}
                              ></div>
                            </div>
                            <span
                              className={`progress-linebar-percentage ${studentProgressLineClass}-text1`}
                            >{`${data.average_progress}%`}</span>
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td
                        className={`${
                          toggleProgress
                            ? "column-data-with-progress"
                            : "column-data-without-progress"
                        }`}
                      >
                        {toggleProgress ? data.completion : "-"}
                      </td>
                    </tr>
                  );
                })}
              </table>
              {/* View-only Resources Table */}
              <table className="main__section-student-unit-grid-view">
                <thead>
                  <tr className="main__section-student-unit-grid-view-top-row">
                    <th
                      colSpan={2}
                      className="main__section-student-unit-grid-details-topheading"
                    >
                      {t("View-only Resources")}
                    </th>
                  </tr>
                  <tr className="main__section-student-unit-grid-view-bottom-row">
                    <th className={`main__section-student-unit-grid-details-bottomheading view_progress ${!toggleProgress ? 'disable-sorting-click': ''}`}
                    onClick={()=>{
                      setSortColumn('View Progress')
                      // const sortedData = allStudentData.sort((a,b) => sortOrder=='asc' ? b.view_progress - a.view_progress : a.view_progress - b.view_progress);
                      const sortedData = allStudentData.sort((a,b) => {
                        if(sortOrder == "asc"){
                          // equal items sort equally
                          if (a.view_progress === b.view_progress) {
                            return 0;
                          }
                      
                          // nulls sort after anything else
                          if (a.view_progress === null || a.view_progress === "") {
                            return 1;
                          }
                          if (b.view_progress === null || b.view_progress === "") {
                            return -1;
                          }  
                          // if descending, highest sorts first
                          return a.view_progress < b.view_progress ? 1 : -1;
                        } else {
                          if (a.view_progress === b.view_progress) {
                              return 0;
                          }
                          // nulls sort after anything else
                          if (a.view_progress === null || a.view_progress === "") {
                            return 1;
                          }
                          if (b.view_progress === null || b.view_progress === "") {
                            return -1;
                          }
                      
                          // otherwise, if we're ascending, lowest sorts first
                          return a.view_progress < b.view_progress ? -1 : 1;
                        }
                      });
                      setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                      setallStudentData(sortedData)
                    }}>
                      {/* {t("Viewed Progress")} */}
                      {t("Progress")}
                      {toggleProgress && <img
                        src={sortColumn != 'View Progress' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                    <th className={`main__section-student-unit-grid-details-bottomheading ${!toggleProgress ? 'disable-sorting-click': ''}`}
                    onClick={()=>{
                        setSortColumn('Viewed Resources')
                        const sortedData = allStudentData.sort((a,b) =>{
                        const firstData =  a.view_completion.split('/')
                        const secondData =  b.view_completion.split('/')
                        // return sortOrder=='asc' ? secondData[0] - firstData[0] : firstData[0] - secondData[0]
                        if(sortOrder == "asc"){
                          // equal items sort equally
                            if (firstData[0] === secondData[0]) {
                              return 0;
                            }
                        
                            // nulls sort after anything else
                            if (firstData[0] === null || firstData[0] === "") {
                              return 1;
                            }
                            if (secondData[0] === null || secondData[0] === "") {
                              return -1;
                            }  
                            // if descending, highest sorts first
                            return parseInt(firstData[0]) < parseInt(secondData[0]) ? 1 : -1;
                          } else {
                            firstData[0] = firstData[0]== 0 && a.view_progress=="" ? firstData[1] : firstData[0];
                            secondData[0] = secondData[0]== 0 && b.view_progress=="" ? firstData[1] : secondData[0];
                            console.log('firstData[0]'+firstData[0])
                            if(firstData[0] == 0){
                              return -1
                            }
                            if (firstData[0] === secondData[0]) {
                              return 1;
                            }

                            // nulls sort after anything else
                            if (firstData[0] === null || firstData[0] === "") {
                              return -1;
                            }
                            if (secondData[0] === null || secondData[0] === "") {
                              return 1;
                            }  
                            // if descending, highest sorts first
                            return parseInt(firstData[0]) < parseInt(secondData[0]) ? -1 : 1;
                          }                        
                        });
                        setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                        setallStudentData(sortedData)
                      }}>
                      {t("Viewed Resources")}
                      {toggleProgress && <img
                        src={sortColumn != 'Viewed Resources' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                        alt="sorting"
                        className="table-sorting-arrow"
                      />}
                    </th>
                  </tr>
                </thead>
                <tr className="main__section-student-unit-grid-view-up-row">
                  <td
                    className={`${
                      toggleProgress
                        ? "column-data-with-progress-color"
                        : "column-data-without-progress-color"
                    }`}
                  >
                    {toggleProgress && classSummaryInfo.TotalAttemptView > 0 ? (
                      <div className="main__progress-linebar">
                        <div className="progress-linebar">
                          <div
                            className={`${getScoreAndAverageClass(
                              classSummaryInfo.ViewTotPercentage,
                              "defaultAverageTop"
                            )}`}
                            style={{
                              width: `${classSummaryInfo.ViewTotPercentage}%`,
                            }}
                          ></div>
                        </div>
                        <span
                          className={`progress-linebar-percentage ${getScoreAndAverageClass(
                            classSummaryInfo.ViewTotPercentage,
                            "defaultAverageTop"
                          )}-text`}
                        >
                          {classSummaryInfo.ViewTotPercentage}%
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    className={`${
                      toggleProgress
                        ? "column-data-with-progress-color"
                        : "column-data-without-progress-color"
                    }`}
                  >
                    {/* {toggleProgress
                      ? classSummaryInfo.ViewResPercentage +
                        "/" +
                        classSummaryInfo.ViewResLen
                      : "-"} */}
                  </td>
                </tr>
                {allStudentData.map((data) => {
                  var studentProgressLineClass = getScoreAndAverageClass(
                    data.view_progress,
                    "defaultAverageBottom"
                  );
                  return (
                    <tr className="main__section-student-unit-grid-view-down-row">
                      <td
                        className={`${
                          toggleProgress
                            ? "column-data-with-progress"
                            : "column-data-without-progress"
                        }`}
                      >
                        {/* {toggleProgress ? data.view_progress : "-"} */}
                        {toggleProgress  ? (
                        // {toggleProgress && data.view_progress != "" ? (
                          <div className="main__progress-linebar">
                            <div className="progress-linebar"> 
                              <div
                                className={`${studentProgressLineClass}`}
                                style={{ width: `${data.view_progress}%` }}
                              ></div>
                            </div>
                            <span
                              className={`progress-linebar-percentage ${studentProgressLineClass}-text1`}
                            >{`${data.view_progress}%`}</span>
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td
                        className={`${
                          toggleProgress
                            ? "column-data-with-progress"
                            : "column-data-without-progress"
                        }`}
                      >
                        {toggleProgress ? data.view_completion : "-"}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default UnitPageStudent;
