import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import icon from "../../assets/img/character2.svg";

import { Bar } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import { Radar } from "react-chartjs-2";
import goldMedal from "../../assets/img/gold-medal.svg";
import silverMedal from "../../assets/img/silver.svg";
import bronzeMedal from "../../assets/img/bronze.svg";
import emptyMedal from "../../assets/img/empty.svg";

import Loading from "../Loading/Loading";
import MyResourceReport from "../../Services/api/GraphQL/Query/MyResourceReport";
import { isMobileOnly } from "react-device-detect";

const ReportLineChart = (props) => {
  const { isInappBrowser, inappData, t, levelId, subjectId } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);
  const [chartInfo, setchartInfo] = useState({ series: [], options: {} });
  const [topicData, settopicData] = useState([]);
  const effectiveSchoolYear = useSelector(
    (state) => state.SystemReducer.effectiveSchoolYear
  );
  const userData = useSelector((state) => state.UserReducer.user);
  const testData = {
    series: [
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20],
      },
      {
        name: "Series 2",
        data: [20, 30, 40, 80, 20, 80],
      },
    ],
    options: {
      colors: ["#12b2a6", "#ffbb1c"],
      chart: {
        // height: 350,
        type: "radar",
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      yaxis: {
        show: false,
        tooltip: {
          enabled: true,
          offsetX: 0,
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      legend: {
        show: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: ["2011", "2012", "2013", "2014", "2015", "2016"],
      },
    },
  };
  useEffect(() => {
    getScoreList();
  }, []);

  useEffect(() => {
    setisLoading(true);
    getScoreList();
  }, [levelId, subjectId]);

  const getScoreList = () => {
    var variables;
    variables = {
      UserId: userData.UserId,
      SchoolYearId: effectiveSchoolYear.SchoolYearId,
      PearsonLevelId: levelId,
    };

    MyResourceReport(variables).then((data) => {
      console.log("subject" + subjectId);
      var tagList = data.user.AdoptedTagHierarchy.filter(
        (o) => o.ForReport == "Y" && o.HierarchyReference == subjectId
      );
      if (tagList[0].ReferenceLevelScore.length > 0) {
        if (tagList[0].RelatedTagType.length > 0) {
          var relatedTags = tagList[0].RelatedTagType;
          let tagsWithoutParent = relatedTags.filter(
            (o) => o.ParentTagType == null
          );
          var relatedTagType = tagsWithoutParent[0].Tags;
          var chartData = [];

          relatedTagType.map(function (obj, index) {
            var chartObj = {};
            let scoreVal = tagList[0].ReferenceLevelScore.filter(
              (o) =>
                o.PearsonLevelId == levelId &&
                o.TagType == tagsWithoutParent[0].TagType &&
                o.TagName == obj.TagName
            );
            if (scoreVal.length > 0) {
              var mypercentage;
              var allpercentage;
              if (scoreVal[0].MyScore == null) {
                mypercentage = null;
              } else {
                mypercentage = Math.round(
                  (scoreVal[0].MyScore / scoreVal[0].MyMaxScore) * 100
                );
              }
              if (scoreVal[0].AllScore == null) {
                allpercentage = null;
              } else {
                allpercentage = Math.round(
                  (scoreVal[0].AllScore / scoreVal[0].AllMaxScore) * 100
                );
              }
              chartObj.TagName = obj.TagName;
              chartObj.ParentTagType = tagsWithoutParent[0].TagType;
              chartObj.TagLongName = obj.TagLongName;
              chartObj.DisplaySequence = obj.DisplaySequence;
              chartObj.TagDisplayNameEn = obj.TagDisplayNameEn;
              chartObj.TagDisplayNameChi = obj.TagDisplayNameChi;
              chartObj.TagType = scoreVal[0].TagType;
              chartObj.MyScore = mypercentage;
              chartObj.MyMaxScore = scoreVal[0].MyMaxScore;
              chartObj.AllScore = allpercentage;
              chartObj.AllMaxScore = scoreVal[0].AllMaxScore;
              chartData.push(chartObj);
            } else {
              // chartObj.TagName = obj.TagName;
              // chartObj.ParentTagType = tagsWithoutParent[0].TagType;
              // chartObj.TagLongName = obj.TagLongName;
              // chartObj.DisplaySequence = obj.DisplaySequence;
              // chartObj.TagDisplayNameEn = obj.TagDisplayNameEn;
              // chartObj.TagDisplayNameChi = obj.TagDisplayNameChi;
              // chartObj.TagType = tagsWithoutParent[0].TagType;
              // chartObj.MyScore = null;
              // chartObj.MyMaxScore = null;
              // chartObj.AllScore = null;
              // chartObj.AllMaxScore = null;
              // chartData.push(chartObj);
            }
          });
          console.log(chartData);
          chartData.sort(function (a, b) {
            var seq1 = parseInt(a.DisplaySequence),
              seq2 = parseInt(b.DisplaySequence);
            if (seq1 < seq2) return -1;
            if (seq1 > seq2) return 1;
            return 0;
          });

          settopicData(chartData);
          if (chartData.length > 0) {
            prepareChartData(chartData);
          }
        } else {
        }
      } else {
        var chartData = [];
        var chartObj = {};
        chartObj.TagName = "NoData";
        chartObj.TagLongName = "NoData";
        chartObj.TagDisplayNameEn = "NoData";
        chartObj.TagDisplayNameChi = "NoData";
        chartObj.MyScore = null;
        chartObj.MyMaxScore = null;
        chartObj.AllScore = null;
        chartObj.AllMaxScore = null;
        chartData.push(chartObj);

        settopicData(chartData);
        prepareChartData(chartData);
      }

      console.log(chartData);
      setisLoading(false);
    });
  };

  const prepareChartData = (chartarg) => {
    var labelNames = [];
    var labelColors = [];
    var myScoreArray = [];
    var otherScoreArray = [];

    chartarg.map(function (obj, index) {
      labelColors.push("#151515");
      let labelNameLang =
        lang == "en-US" ? obj.TagDisplayNameEn : obj.TagDisplayNameChi;
      let lname = labelNameLang ? labelNameLang : obj.TagLongName;
      //labelNames.push(lname.match(/.{1,10}/g));

      labelNames.push(lname.match(/.{1,25}(\s|$)/g));

      myScoreArray.push(obj.MyScore);
      otherScoreArray.push(obj.AllScore);
    });

    let chartObjData = {
      series: [
        {
          name: "我的表現",
          data: myScoreArray,
        },
        {
          name: "全港用戶平均表現",
          data: otherScoreArray,
        },
      ],
      options: {
        responsive: [
          {
            breakpoint: 599,
            options: {
              xaxis: {
                labels: {
                  show: true,
                  fontSize: "12px",
                },
              },
            },
          },
        ],
        colors: ["#12b2a6", "#ffbb1c"],
        legend: {
          show: false,
        },
        fill: {
          opacity: 0.1,
          colors: ["#12b2a6", "#ffbb1c"],
        },
        chart: {
          height: 450,
          width: "100%",
          type: "radar",
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },

        stroke: {
          show: true,
          width: 2,
          colors: ["#12b2a6", "#ffbb1c"],
        },

        markers: {
          size: 0,
        },
        dataLabels: {
          // enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
        yaxis: {
          show: false,
          tooltip: {
            enabled: true,
            offsetX: 0,
          },
        },
        xaxis: {
          categories: labelNames,
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: labelColors,
              fontSize: "12px",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    };
    setchartInfo(chartObjData);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="box-loading">
          <Loading type="inpage" />
        </div>
      ) : (
        <React.Fragment>
          {/* <ReactApexChart
            options={testData.options}
            series={testData.series}
            type="radar"
            height={300}
          /> */}
          <div className="heading">
            <div className="line"></div>
            <div className="chart-title">{t("By Learning Area")}</div>
            <div className="line"></div>
            <div className="chart-view">
              <ReactApexChart
                options={chartInfo.options}
                series={chartInfo.series}
                type="radar"
                height={isMobileOnly ? 240 : 450}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReportLineChart;
