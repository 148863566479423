import React, { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

const CustomLineChart = ({ data, options, width }) => {
  const chart = useRef(null);
  useEffect(() => {
    console.log(chart);
  }, [chart]);

  return (
    <Line
      // width={width}
      // height={"100%"}
      ref={chart}
      data={data}
      options={{
        ...options,
        tooltips: {
          backgroundColor: "#003057",
          bodyFontSize: 18,
          yPadding: 10,
          xPadding: 10,
          displayColors: false,
          mode: "label",
          // yAlign: "bottom",
          callbacks: {
            title: function (tooltipItem, callbackData) {
              return "";
            },
            // label: (tooltipItem, callbackData) => {
            //   return data["datasets"][0]["data"][tooltipItem["index"]] || 0;
            // },
          },
        },
      }}
    />
  );
};

CustomLineChart.defaultProps = {
  // height: 200,
  // width: 500,
  // horizontalGuides: 4,
  // verticalGuides: null,
  // precision: 2,
  options: {},
};

CustomLineChart.propTypes = {
  data: PropTypes.object.isRequired,
  // height: PropTypes.number,
  // width: PropTypes.number,
  // horizontalGuides: PropTypes.number,
  // verticalGuides: PropTypes.number,
  // precision: PropTypes.number,
};

export default CustomLineChart;
