import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation UpdateSelfLearnConfigMutation(
    $NotifyStudent: Boolean!
    $SelfLearnConfigurations: [SelfLearnConfigurationInputType]!
  ) {
    updateSelfLearnConfig(NotifyStudent: $NotifyStudent, SelfLearnConfigurations: $SelfLearnConfigurations)
  }
`;
export default function UpdateSelfLearnConfigMutation(data, action, type="other", notifyset= false) {
  if(type == "resource"){
    var variables = {
      NotifyStudent : notifyset,
      SelfLearnConfigurations: data,
    };
  }else{
    var variables = {
      NotifyStudent : false,
      SelfLearnConfigurations: data,
    };
  }
  

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
