import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import dummy_img2 from "../../../assets/img/pec/all-images/cover-elect-1b.png";
import default_dummy_img from "../../../assets/img/pec/all-images/cover-default.png";
import { dashboard_book_level_favorites } from "../../../Actions";
import "./FavouritePage.scss";
import Loading from "../../../Components/Loading/PEC/Loading";

import NotificationDropDownStudent from "./NotificationDropDownStudent";
import {processImageUrl} from "../../../utils/ImageProcessUtils";
import useGATracking from "../../../hooks/useGATracking";
// import { bookseries_favorites } from "../../../Actions";

const FavouritePage = (props) => {
  const {
    loadBookNotification,
    activeMenu,
    role,
    studentClassId,
    t,
    studyGroupList,
    notificationPopupOverlay,
    setNotificationPopupOverlay,
  } = props;
  const dispatch = useDispatch();
  // const [showClass, setShowClass] = useState(false);
  // const [showClassData, setShowClassData] = useState([]);
  // const [favouriteClass, setFavouriteClass] = useState([]);
  // const [activeClass, setActiveClass] = useState("all");
  // const [resfavIds, setresFavIds] = useState({});
  // const [myResFav, setmyResFav] = useState([]);
  // const [classNoticationLoading, setClassNoticationLoading] = useState(false);
  // const [selectClass, setSelectClass] = useState("Please select");
  const [selectClass, setSelectClass] = useState([]);
  const class_dropdownRef = useRef(null);
  // const { t, studyGroupList } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const { sendGaEvent } = useGATracking();

  const favBookSeriesData = props.allData ? props.allData : [];
  const totalFavouriteCnt = props.allData ? props.allData.length : 0;
  // const t = (para) => {
  //   return para;
  // };

  useEffect(() => {
    studyGroupList.length > 0
      ? setSelectClass({
          StudyGroupName:
            studyGroupList[0]?.StudyGroupName[[0].Lang == lang ? 0 : 1]
              .StudyGroupName,
          StudyGroupId: studyGroupList[0]?.StudyGroupId,
        })
      : "";
  }, [studyGroupList]);

  // const favBookSeriesData = props.allData.filter((el) => {
  //   return props.addToFavourite.includes(el.BookId);
  // });

  return (
    <>
      <div
        className={`favourited-books ${
          totalFavouriteCnt <= 0 ? "favourited-books1" : ""
        }`}
      >
        {/* {totalFavouriteCnt > 0 ? (
        <h3 className="select-favourite-books-heading">My Favourites</h3>
      ) : (
        ""
      )} */}
        {/* {totalFavouriteCnt > 0  && (
        <h3 className="select-favourite-books-heading">{t("My Favourites")}</h3>)} */}
        {totalFavouriteCnt > 0 ? (
          loadBookNotification ? (
            <Loading type={`normal`} />
          ) : (
            <div className="selected-favourite">
              {favBookSeriesData.map((favBookData) => {
                var showEbook = false;
                var showResBool = false;
                if (favBookData.UsefulTools) {
                  var ebookLinkObj = favBookData.UsefulTools.filter(
                    (o) =>
                      o.ToolsType == "ebook" &&
                      (o.ApplicableRole == role || o.ApplicableRole == "ALL")
                  );

                  if (ebookLinkObj.length > 0) {
                    showEbook = true;
                  }
                  var resourceLinkObj = favBookData.UsefulTools.filter(
                    (o) =>
                      o.ToolsType == "web" &&
                      (o.ApplicableRole == role || o.ApplicableRole == "ALL")
                  );
                  //console
                  if (resourceLinkObj.length > 0) {
                    showResBool = true;
                  }
                }

                return (
                  <div className="selected-book-favourite">
                    <div className="selected-book-favourite-first-section">
                      <img
                        className="selected-fav-image"
                        // src={`../${favBookData.images}`}
                        src={
                          favBookData.BookImage
                            ? processImageUrl(favBookData.BookImage.FilePath)
                            : default_dummy_img
                        }
                      />
                      {showEbook && (
                        <button
                          className="buttons selected-fav-btn"
                          onClick={() => {
                            if (!window.cordova) {
                              window.gtag('event', 'Dashboard - Click button in book section', {
                                'Button' : 'Launch E-Book',
                                'Book': favBookData.BookName[0].BookName,
                                'Book_Series': props.activeMenuName,
                                'user_Role': role 
                              });
                            } else {
                              sendGaEvent("Dashboard - Click button in book section", {
                                'Button' : 'Launch E-Book',
                                'Book': favBookData.BookName[0].BookName,
                                'Book_Series': props.activeMenuName,
                                'user_Role': role
                              });
                            }
                            props.onBtnClickOpenLink(ebookLinkObj[0])
                            }
                          }
                        >
                          {t("Launch E-Book")}
                          <i className="icon mdi mdi-open-in-new  favourite-mdi-icons"></i>
                        </button>
                      )}
                      <div className="fav-button-section">
                        <button
                          className="buttons selected-fav-sdl-btn"
                          onClick={() =>{
                            if (!window.cordova) {
                              window.gtag('event', 'Dashboard - Click button in book section', {
                                'Button' : 'SDL',
                                'Book': favBookData.BookName[0].BookName,
                                'Book_Series': props.activeMenuName,
                                'user_Role': role 
                              });
                            } else {
                              sendGaEvent("Dashboard - Click button in book section", {
                                  'Button' : 'SDL',
                                  'Book': favBookData.BookName[0].BookName,
                                  'Book_Series': props.activeMenuName,
                                  'user_Role': role
                              });
                            }
                            dispatch({
                              type: "SET_SELECTED_TYPE",
                              selectOptions: {

                                selectedResourceName:"",
                                selectecResourceTab:"",
                                selectedToggle:false,
                                showIframeOrSDL:"sdl"

                              },
                            });
                            props.onClickResource(
                              activeMenu,
                              "history",
                              favBookData.BookId,
                              favBookData.BookName[
                                favBookData.BookName[0].Lang == lang ? 0 : 1
                              ].BookName
                            )
                          }}
                        >
                          {t("SDL")}
                        </button>
                        {showResBool && (
                          <button
                            className="buttons selected-fav-resource-btn"
                            onClick={() => {
                              if (!window.cordova) {
                                window.gtag('event', 'Dashboard - Click button in book section', {
                                  'Button' : 'Resources',
                                  'Book': favBookData.BookName[0].BookName,
                                  'Book_Series': props.activeMenuName,
                                  'user_Role': role 
                                });
                              } else {
                                sendGaEvent("Dashboard - Click button in book section", {
                                    'Button' : 'Resources',
                                    'Book': favBookData.BookName[0].BookName,
                                    'Book_Series': props.activeMenuName,
                                    'user_Role': role
                                });
                              }
                              //props.onBtnClickOpenLink(resourceLinkObj[0])
                              dispatch({
                                type: "SET_SELECTED_TYPE",
                                selectOptions: {
  
                                  selectedResourceName:"",
                                  selectecResourceTab:"",
                                  selectedToggle:false,
                                  showIframeOrSDL:"resource"
  
                                },
                              });
                              props.onClickResource(
                                activeMenu,
                                "history",
                                favBookData.BookId,
                                favBookData.BookName[
                                  favBookData.BookName[0].Lang == lang ? 0 : 1
                                ].BookName
                              )
                              }
                            }
                          >
                            {t("Resource")}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="selected-book-favourite-second-section">
                      <div className="favourite-book-heading">
                        <label
                          className="fav-book-name"
                          title={favBookData.BookName[0].BookName}
                        >
                          {/* {favBookData.bookName} */}
                          {
                            favBookData.BookName[
                              favBookData.BookName[0].Lang == lang ? 0 : 1
                            ].BookName
                          }
                        </label>
                      </div>

                      <>
                        <NotificationDropDownStudent
                          // toggleCheckResource={toggleCheckResource}
                          favBookData={favBookData}
                          t={t}
                          role={props.role}
                          studentClassId={studentClassId}
                          studyGroupList={studyGroupList}
                          selectClass={selectClass}
                          activeMenu={props.activeMenu}
                          BookId={favBookData.BookId}
                          favBookSeriesData={favBookSeriesData}
                          notificationPopupOverlay={notificationPopupOverlay}
                          setNotificationPopupOverlay={
                            setNotificationPopupOverlay
                          }
                        />
                      </>
                    </div>
                  </div>
                );
              })}
            </div>
          )
        ) : (
          <label className="default-messages">There is no content.</label>
        )}
      </div>
    </>
  );
};

export default FavouritePage;
