import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const GetNotificationDataStudentGeneralQuery = graphql`
  query GetNotificationDataStudentGeneralQuery(
    $UserId: String!
    $SchoolYearId: String!
    $PageSize: Int
    $PageNo: Int
    $OrderBy: AssignmentOrderByEnumType
  ) {
    user(UserId: $UserId) {
      NotificationList(
        SchoolYearId: $SchoolYearId
        PageSize: $PageSize
        PageNo: $PageNo
      ) {
        TotalNumberOfRecord
        NotificationList {
          AssignmentOrSharing
          AssignmentObj {
            id
            ResourceAssignId
            SubjectId
            BookSeriesId
            SchoolYearId
            AssignmentName
            AssignmentDescription
            DueDate
            CreateDate
            CreateBy
            LastUpdateDate
            LastUpdateBy
            Subject {
              SubjectId
            }
          }
          SharingObj {
            id
            SharingId
            SharingDate
            Deleted
            SchoolYearId
            NotifyStudent
            EnterDate
            EnterBy
            SharingReadTargetStatus(UserId: $UserId){
              SharingId
              Status
            }
            EnterByUser{
              UserDisplayName {
                UserName
              }
            }
            LastUpdatedDate
            LastUpdateBy
            LastUpdatedBy
            RelatedResourcesConfig {
              id
              ResourceId
              StructureId
              SchoolYearId
              StudyGroupId
              StudyGroup{
                StudyGroupName{
                  StudyGroupName
                  Lang
                }
              }
             
              ConfigName
              ConfigValue
              EnterDate
              EnterBy
              LastUpdatedDate
              LastUpdatedBy
              RelatedStructure{
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructure{
                  StructureLang {
                    Lang
                    StructureTitle
                  }
                }
              }
              Resource {
                ResourceId
                FileSource
                IngestionId
                ResourceType
                Thumbnail {
                  FilePath
                }
                ChildResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                ParentResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                Linkage
                ResourceAccessUrl
                ResourceDownloadUrl
                Downloadable
                ResourceLangs {
                  Lang
                  ResourceName
                }
                Book{
                  BookName {
                    Lang
                    BookName
                  }
                }
                BookSeriesId
                BookId
                BookSeries {
                  BookSeriesId
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
            }
          }
        }
      }
      AssignmentBelongsTo(
        SchoolYearId: $SchoolYearId
        OrderBy: $OrderBy
        PageSize: $PageSize
      ) {
        ResourceAssignments {
          AssignmentName
          ResourceAssignId
          DueDate
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          AssignmentTargets(FilterCurrentUser: true) {
            ResourceAssignId
            User {
              UserDisplayName {
                Lang
                UserName
                UserId
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupName {
                Lang
                StudyGroupName
              }
              BookSeries {
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
              }
            }
          }
          AssignmentTargetStatus(UserId: $UserId) {
            ResourceAssignId
            Status
            UserId
          }
        }
      }
    }
  }
`;
const GetNotificationDataTeacherGeneralQuery = graphql`
  query GetNotificationDataTeacherGeneralQuery(
    $UserId: String!
    $SchoolYearId: String!
    $OrderBy: AssignmentOrderByEnumType
    $PageSize: Int
    $PageNo: Int
  ) {
    user(UserId: $UserId) {
      NotificationList(
        SchoolYearId: $SchoolYearId
        PageSize: $PageSize
        PageNo: $PageNo
      ) {
        TotalNumberOfRecord
        NotificationList {
          AssignmentOrSharing
          AssignmentObj {
            id
            ResourceAssignId
            SubjectId
            BookSeriesId
            SchoolYearId
            AssignmentName
            AssignmentDescription
            DueDate
            CreateDate
            CreateBy
            LastUpdateDate
            LastUpdateBy
            Subject {
              SubjectId
            }
          }
          SharingObj {
            id
            SharingId
            SharingDate
            Deleted
            SchoolYearId
            NotifyStudent
            EnterDate
            EnterBy
            SharingReadTargetStatus(UserId: $UserId){
              SharingId
              Status
            }
            EnterByUser{
              UserDisplayName {
                UserName
              }
            }
            LastUpdatedDate
            LastUpdateBy
            LastUpdatedBy
            RelatedResourcesConfig {
              id
              ResourceId
              StructureId
              SchoolYearId
              StudyGroupId
              StudyGroup{
                StudyGroupName{
                  StudyGroupName
                  Lang
                }
              }
              ConfigName
              ConfigValue
              EnterDate
              EnterBy
              LastUpdatedDate
              LastUpdatedBy
              RelatedStructure{
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructure{
                  StructureLang {
                    Lang
                    StructureTitle
                  }
                }
              }
              Resource {
                ChildResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                ParentResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                ResourceId
                IngestionId
                FileSource
                ResourceType
                Linkage
            ResourceAccessUrl
            ResourceDownloadUrl
            Downloadable
                Thumbnail {
                  FilePath
                }
                ResourceLangs {
                  Lang
                  ResourceName
                }
                Book{
                  BookName {
                    Lang
                    BookName
                  }
                }
                BookSeriesId
                BookId
                BookSeries {
                  BookSeriesId
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
            }
          }
        }
      }
      AssignmentCreated(
        SchoolYearId: $SchoolYearId
        PageSize: $PageSize
        OrderBy: $OrderBy
      ) {
        TotalNumberOfRecord
        ResourceAssignments {
          AssignmentName
          Subject {
            SubjectId
            SubjectCode
            SubjectName {
              SubjectId
              SubjectName
            }
          }
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          CreateDate
          CreateByUser {
            UserDisplayName {
              UserName
            }
          }
          ResourceAssignId
          DueDate
          AssignmentTargets {
            ResourceAssignId
            UserId
            User {
              UserDisplayName {
                Lang
                UserName
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
                UserId
              }
              StudyGroupName {
                Lang
                StudyGroupName
              }
              BookSeries {
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
              }
            }
          }
          AssignmentTargetStatus {
            ResourceAssignId
            Status
            UserId
          }
        }
      }
    }
  }
`;
const GetNotificationDataStudentQuery = graphql`
  query GetNotificationDataStudentQuery(
    $UserId: String!
    $SchoolYearId: String!
    $StudyGroupId: Int
    $BookId: Int
    $PageSize: Int
    $PageNo: Int
    $OrderBy: AssignmentOrderByEnumType
  ) {
    user(UserId: $UserId) {
      NotificationList(
        SchoolYearId: $SchoolYearId
        StudyGroupId: $StudyGroupId
        BookId: $BookId
        PageSize: $PageSize
        PageNo: $PageNo
      ) {
        TotalNumberOfRecord
        NotificationList {
          AssignmentOrSharing
          AssignmentObj {
            id
            ResourceAssignId
            SubjectId
            BookSeriesId
            SchoolYearId
            AssignmentName
            AssignmentDescription
            DueDate
            CreateDate
            CreateBy
            LastUpdateDate
            LastUpdateBy
            Subject {
              SubjectId
            }
          }
          SharingObj {
            id
            SharingId
            SharingDate
            Deleted
            SchoolYearId
            NotifyStudent
            EnterDate
            EnterBy
            SharingReadTargetStatus(UserId: $UserId){
              SharingId
              Status
            }
            EnterByUser{
              UserDisplayName {
                UserName
              }
            }
            LastUpdatedDate
            LastUpdateBy
            LastUpdatedBy
            RelatedResourcesConfig {
              id
              ResourceId
              StructureId
              SchoolYearId
              StudyGroupId
              StudyGroup{
                StudyGroupName{
                  StudyGroupName
                  Lang
                }
              }
             
              ConfigName
              ConfigValue
              EnterDate
              EnterBy
              LastUpdatedDate
              LastUpdatedBy
              RelatedStructure{
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructure{
                  StructureLang {
                    Lang
                    StructureTitle
                  }
                }
              }
              Resource {
                ChildResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                ParentResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                IngestionId
                FileSource
                ResourceId
                ResourceType
                Thumbnail {
                  FilePath
                }
                Linkage
            ResourceAccessUrl
            ResourceDownloadUrl
            Downloadable
                ResourceLangs {
                  Lang
                  ResourceName
                }
                Book{
                  BookName {
                    Lang
                    BookName
                  }
                }
                BookId
                BookSeriesId
                BookSeries {
                  BookSeriesId
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
            }
          }
        }
      }
      AssignmentBelongsTo(
        SchoolYearId: $SchoolYearId
        OrderBy: $OrderBy
        PageSize: $PageSize
      ) {
        ResourceAssignments {
          AssignmentName
          ResourceAssignId
          DueDate
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          AssignmentTargets(FilterCurrentUser: true) {
            ResourceAssignId
            User {
              UserDisplayName {
                Lang
                UserName
                UserId
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupName {
                Lang
                StudyGroupName
              }
              BookSeries {
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
              }
            }
          }
          AssignmentTargetStatus(UserId: $UserId) {
            ResourceAssignId
            Status
            UserId
          }
        }
      }
    }
  }
`;
const GetNotificationDataTeacherQuery = graphql`
  query GetNotificationDataTeacherQuery(
    $UserId: String!
    $SchoolYearId: String!
    $StudyGroupId: Int
    $BookId: Int
    $OrderBy: AssignmentOrderByEnumType
    $PageSize: Int
    $PageNo: Int
  ) {
    user(UserId: $UserId) {
      NotificationList(
        SchoolYearId: $SchoolYearId
        StudyGroupId: $StudyGroupId
        BookId: $BookId
        PageSize: $PageSize
        PageNo: $PageNo
      ) {
        TotalNumberOfRecord
        NotificationList {
          AssignmentOrSharing
          AssignmentObj {
            id
            ResourceAssignId
            SubjectId
            BookSeriesId
            SchoolYearId
            AssignmentName
            AssignmentDescription
            DueDate
            CreateDate
            CreateBy
            LastUpdateDate
            LastUpdateBy
            Subject {
              SubjectId
            }
          }
          SharingObj {
            id
            SharingId
            SharingDate
            Deleted
            SchoolYearId
            NotifyStudent
            EnterDate
            EnterBy
            SharingReadTargetStatus(UserId: $UserId){
              SharingId
              Status
            }
            EnterByUser{
              UserDisplayName {
                UserName
              }
            }
            LastUpdatedDate
            LastUpdateBy
            LastUpdatedBy
            RelatedResourcesConfig {
              id
              ResourceId
              StructureId
              SchoolYearId
              StudyGroupId
              StudyGroup{
                StudyGroupName{
                  StudyGroupName
                  Lang
                }
              }
              ConfigName
              ConfigValue
              EnterDate
              EnterBy
              LastUpdatedDate
              LastUpdatedBy
              RelatedStructure{
                StructureLang {
                  Lang
                  StructureTitle
                }
                ParentStructure{
                  StructureLang {
                    Lang
                    StructureTitle
                  }
                }
              }
              Resource {
                ChildResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                ParentResources{
                  ResourceLangs {
                    Lang
                    ResourceName
                  }
                }
                ResourceId
                FileSource
                IngestionId
                ResourceType
                Linkage
            ResourceAccessUrl
            ResourceDownloadUrl
            Downloadable
                Thumbnail {
                  FilePath
                }
                ResourceLangs {
                  Lang
                  ResourceName
                }
                Book{
                  BookName {
                    Lang
                    BookName
                  }
                }
                BookSeriesId
                BookId
                BookSeries {
                  BookSeriesId
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
            }
          }
        }
      }
      AssignmentCreated(
        SchoolYearId: $SchoolYearId
        PageSize: $PageSize
        OrderBy: $OrderBy
      ) {
        TotalNumberOfRecord
        ResourceAssignments {
          AssignmentName
          Subject {
            SubjectId
            SubjectCode
            SubjectName {
              SubjectId
              SubjectName
            }
          }
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          CreateDate
          CreateByUser {
            UserDisplayName {
              UserName
            }
          }
          ResourceAssignId
          DueDate
          AssignmentTargets {
            ResourceAssignId
            UserId
            User {
              UserDisplayName {
                Lang
                UserName
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
                UserId
              }
              StudyGroupName {
                Lang
                StudyGroupName
              }
              BookSeries {
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
              }
            }
          }
          AssignmentTargetStatus {
            ResourceAssignId
            Status
            UserId
          }
        }
      }
    }
  }
`;

const GetNotificationData = (variables, role, page="unique") => {
  if(page=="unique"){
    if (role == "T") {
      return fetchQuery(environment, GetNotificationDataTeacherQuery, variables);
    } else {
      return fetchQuery(environment, GetNotificationDataStudentQuery, variables);
    }
  }else {
    if (role == "T") {
      return fetchQuery(environment, GetNotificationDataTeacherGeneralQuery, variables);
    } else {
      return fetchQuery(environment, GetNotificationDataStudentGeneralQuery, variables);
    }
  }
  
};

export default GetNotificationData;
