import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import environment from "../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import Loading from "../../../../../../Components/Loading/Loading";
import monent from "moment";
import { activityLayoutTab } from "../../../../../../Services/Common/common";
import useEncryption from "../../../../../../hooks/useEncryption";

var reportsApp;

const SingleResultB2CStudentReportHistoryQuery = graphql`
  query SingleResultB2CStudentReportHistoryQuery(
    $UserId: String!
    $ResourceId: String!
    $Lang: LangEnumType!
    $Credential: String
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(
        ResourceId: $ResourceId
        IncludeAssignmentRecord: true
      ) {
        IsCompleted
        AttemptDate
        LearnositySessionResult(Lang: $Lang, Credential: $Credential)
      }
    }
  }
`;

const SingleResultB2CStudentReportQuery = graphql`
  query SingleResultB2CStudentReportQuery(
    $SessionId: String!
    $Lang: LangEnumType!
    $Credential: String
  ) {
    learnositySelfLearnResult(SessionId: $SessionId) {
      AttemptSessionId
      AttemptDate
      IsCompleted
      CompletedDate
      UserId
      ResourceId
      LearnositySessionResult(Lang: $Lang, Credential: $Credential)
      UserDetail {
        UserDisplayName {
          Lang
          UserName
        }
      }
    }
  }
`;

const index = (props) => {
  const { B2C, t, resourceData, bookData, isInappBrowser, standalone } = props;

  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = localStorage.getItem("i18nextLng");

  const [loading, setloading] = useState(true);
  const [studentDisplayName, setstudentDisplayName] = useState(null);
  const [questionsData, setquestionsData] = useState(null);
  const [correctCount, setcorrectCount] = useState(0);
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre")?decryptToLocal(window.localStorage.getItem("userCre")):null;

  // useEffect(() => {
  //   console.log("[checkReportScriptIsReady]", props.action);

  //   if (props.selectedHistory != null) {
  //     //get object by  key
  //     getReportObjectByAttemptSessionId(props.selectedHistory.AttemptSessionId);
  //   }
  // }, []);

  useEffect(() => {
    console.log(props, "componentWillReceiveProps");
    if (props.selectedHistory != null) {
      getReportObjectByAttemptSessionId(props.selectedHistory.AttemptSessionId);
    }
  }, [props.selectedHistory]);

  const errorListener = (e) => {
    console.log(e);
    switch (e.code) {
      case 10025:
        break;
    }
  };

  const readyListener = () => {
    // const { B2C } = this.props;
    console.log("[window.LearnosityReports readyListener] Done");
    var report = reportsApp.getReport("session-detail");
    window.testing = report;
    // var that = this;

    report.on("load:data", (data) => {
      console.log("this item has been loaded with the following data: ", data);
      //get question data [start]
      var e = data.items;

      console.log(e);
      var newArray = [];
      e.map((item, index) => {
        newArray.push({
          name: item.item_reference,
          data: item,
        });
      });
      console.log(newArray);
      renderQuestionsBar(newArray);
      activityLayoutTab({ disable: false, isInappBrowser });
    });
  };

  const getReportObjectByAttemptSessionId = (id) => {
    let variables = {
      SessionId: id,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(environment, SingleResultB2CStudentReportQuery, variables).then(
      (data) => {
        console.log(data);

        if (!data.learnositySelfLearnResult) {
          console.log("Faill to get Data : learnositySelfLearnResult null");
        }
        var objectData = JSON.parse(
          data.learnositySelfLearnResult.LearnositySessionResult
        );

        setstudentDisplayName(userData.UserDisplayName);
        setloading(false);
        getReport(objectData);
      }
    );
  };

  const renderQuestionsBar = (question) => {
    let key = question;
    let questions = [];
    let correct = 0,
      max_score = 0;
    key.map((obj, index) => {
      console.log(obj, "obj");
      questions.push(obj.data);
      if (obj.data.score) {
        correct = correct + obj.data.score;
      }
      max_score = max_score + obj.data.max_score;
    });
    correct = (correct / max_score) * 100;
    console.log(questions, "questions", correct);
    setquestionsData(questions);
    setcorrectCount(Math.round(correct));
  };

  // const getStudentHistoryList = (userid, resid, callback) => {
  //   let variables = {
  //     UserId: userid,
  //     ResourceId: resid,
  //     Lang: lang == "en-US" ? "EN" : "TC",
  //   };

  //   fetchQuery(
  //     environment,
  //     SingleResultB2CStudentReportHistoryQuery,
  //     variables
  //   ).then((data) => {
  //     console.log(data);
  //     var listArray = [...data.user.SelfLearnAttemptHistory];
  //     listArray.sort(function (a, b) {
  //       return new monent(a.AttemptDate) - new monent(b.AttemptDate);
  //     });
  //     var _defauleAttemptDate = listArray.length - 1;
  //     if (props.location.state) {
  //       if (props.location.state.attemptDate) {
  //         _defauleAttemptDate = listArray
  //           .map((i) => {
  //             return i.AttemptDate;
  //           })
  //           .indexOf(props.location.state.attemptDate);
  //       }
  //     }
  //     console.log(listArray, _defauleAttemptDate);
  //     callback();
  //   });
  // };

  const getReport = (object) => {
    setquestionsData(null);
    var callbacks = {
      errorListener: errorListener,
      readyListener: readyListener,
    };
    var checkReportScriptIsReady = setInterval(() => {
      if (window.LearnosityReports) {
        console.log(
          "[checkReportScriptIsReady]  Find window.LearnosityReports"
        );
        clearInterval(checkReportScriptIsReady);
        if (object) {
          console.log(object);
          reportsApp = window.LearnosityReports.init(
            object.reportObj,
            callbacks
          );
        } else {
          console.log(
            JSON.parse(props.selectedHistory.LearnositySessionResult)
          );
          reportsApp = window.LearnosityReports.init(
            JSON.parse(props.selectedHistory.LearnositySessionResult).reportObj,
            callbacks
          );
        }
      }
    }, 1000);
  };

  return (
    <>
      {loading && (
        <div className="splash-container">
          <div className="loader mx-auto" />
        </div>
      )}
      {/* {!loading && ( */}
      <div className={"l-content"}>
        {!loading && (
          <div className="questions-bar">
            {questionsData ? (
              <table>
                <thead>
                  <tr>
                    <th>{t("Student")}</th>
                    <th>{t("Score")}</th>
                    {questionsData &&
                      questionsData.map((obj, index) => {
                        return <th key={index}>{"Q" + obj.item_number}</th>;
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {studentDisplayName &&
                      studentDisplayName[
                        lang == studentDisplayName[0].Lang ? 0 : 1
                      ].UserName.trim().length == 0
                        ? studentDisplayName[0].UserName
                        : studentDisplayName[
                            lang == studentDisplayName[0].Lang ? 0 : 1
                          ].UserName}
                    </td>
                    <td>{correctCount}%</td>
                    {questionsData &&
                      questionsData.map((obj, index) => {
                        return (
                          <td key={index}>
                            <div
                              className={`dot ${
                                obj.attempted == false
                                  ? "grey"
                                  : obj.score == obj.max_score
                                  ? "green"
                                  : obj.score
                                  ? "yellow"
                                  : "red"
                              }`}
                            ></div>
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </table>
            ) : (
              <Loading type="normal" />
            )}
          </div>
        )}
        <div id="sessions-summary"></div>

        <br />
        <div id="session-detail"></div>
      </div>
    </>
  );
};

export default index;
