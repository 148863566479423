import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import { useDispatch, useSelector } from "react-redux";
import environment from "../../../Environment";
import moment from "moment";
import Loading from "../../Loading/Loading";
import Pagination from "../../Pagination";
import MessageBox from "../../Feature/MessageBox";
import icon from "../../../assets/img/Group 99.png";
import new_assignment from "../../../assets/img/new-assignment.svg";
import "../../../Layouts/ClassViewLayout/Body/Assignment/index.scss";
import NotificationItem from "./NotificationItem"; // import FullScreenModal from "../../Feature/FullScreenModal/index";
import { isMobileOnly } from "react-device-detect";
import { Trans } from "react-i18next";
import "./ViewAllNotification.scss";
import {
  handbook_book,
  handbook_level,
  handbook_book_tab,
  handbook_level_tab,
} from "../../../Actions";
import CircleCloseButton from "../../Feature/CircleCloseButton";
import ReadResourceAssignmentMutation from "../../Feature/Function/ReadResourceAssignmentMutation";
import GetDueDateStyle from "../../Feature/Function/GetDueDateStyle";
import HandBookDropdownNew from "../Dashboard/HandBookDropdownNew";
import HandBookSelectPopUp from "./HandBookSelectPopUp";
import arrowIcon from "../../../assets/img/icon.png";
import UpdateUserPreferencesMutation from "../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
const ViewAllNotificationStudentListQuery = graphql`
  query ViewAllNotificationStudentListQuery(
    $UserId: String!
    $SchoolYearId: String!
    $PageNo: Int
    $OrderBy: AssignmentOrderByEnumType
    $Order: DisplayOrderEnumType
    $PageSize: Int
    $BookSeriesId: String!
  ) {
    user(UserId: $UserId) {
      AssignmentBelongsTo(
        SchoolYearId: $SchoolYearId
        PageNo: $PageNo
        PageSize: $PageSize
        OrderBy: $OrderBy
        Order: $Order
        BookSeriesId: $BookSeriesId
      ) {
        TotalNumberOfRecord
        ResourceAssignments {
          AssignmentName
          LastUpdateDate
          Subject {
            SubjectId
            SubjectCode
            SubjectName {
              SubjectId
              SubjectName
            }
          }
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          CreateDate
          CreateByUser {
            UserDisplayName {
              UserName
            }
          }
          ResourceAssignId
          DueDate
          AssignmentTargets(FilterCurrentUser: true) {
            ResourceAssignId
            User {
              UserDisplayName {
                Lang
                UserName
                UserId
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupName {
                Lang
                StudyGroupName
              }
              BookSeries {
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
              }
            }
          }
          AssignmentTargetStatus(UserId: $UserId) {
            ResourceAssignId
            Status
            UserId
          }
        }
      }
    }
  }
`;
const ViewAllNotificationTeacherListQuery = graphql`
  query ViewAllNotificationTeacherListQuery(
    $UserId: String!
    $SchoolYearId: String!
    $PageNo: Int
    $OrderBy: AssignmentOrderByEnumType
    $Order: DisplayOrderEnumType
    $PageSize: Int
    $StudyGroupId: String!
    $BookSeriesId: [String]
  ) {
    user(UserId: $UserId) {
      AssignmentCreated(
        SchoolYearId: $SchoolYearId
        PageNo: $PageNo
        PageSize: $PageSize
        OrderBy: $OrderBy
        Order: $Order
        BookSeriesId: $BookSeriesId
        StudyGroupId: $StudyGroupId
      ) {
        TotalNumberOfRecord
        ResourceAssignments {
          AssignmentName
          LastUpdateDate
          Subject {
            SubjectId
            SubjectCode
            SubjectName {
              SubjectId
              SubjectName
            }
          }
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          CreateDate
          CreateByUser {
            UserDisplayName {
              UserName
            }
          }
          ResourceAssignId
          DueDate
          AssignmentTargets {
            ResourceAssignId
            UserId
            User {
              UserDisplayName {
                Lang
                UserName
                UserId
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupName {
                Lang
                StudyGroupName
              }
              StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
                UserId
              }
              BookSeries {
                BookSeriesName {
                  Lang
                  BookSeriesName
                }
              }
            }
          }
          AssignmentTargetStatus {
            ResourceAssignId
            Status
            UserId
          }
        }
      }
    }
  }
`;

const ViewAllNotificationSubjectListQuery = graphql`
  query ViewAllNotificationSubjectListQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {
        BookSeriesId
        DisplaySequence
        BookSeriesName {
          BookSeriesId
          BookSeriesName
        }
      }
    }
  }
`;

const ViewAllNotificationTeacherSubjectListQuery = graphql`
  query ViewAllNotificationTeacherSubjectListQuery(
    $UserId: String!
    $SchoolYearId: String!
    $FilterOnboarded: Boolean!
  ) {
    user(UserId: $UserId) {
      UserId
      School {
        StudyGroups(
          SchoolYearId: $SchoolYearId
          FilterOnboarded: $FilterOnboarded
        ) {
          StudyGroupId
          BookSeries {
            IngestionId
            BookSeriesId
            DisplaySequence
            BookSeriesName {
              BookSeriesId
              BookSeriesName
            }
            Subject {
              DisplaySequence
              SubjectId
              SubjectName {
                SubjectName
                Lang
              }
            }
          }
          StudyGroupName {
            StudyGroupName
            Lang
          }
        }
      }
    }
  }
`;

export default (props) => {
  const { t, setAssignmentDetailsData, onCloseButton } = props;

  const dispatch = useDispatch();
  const assignmentList = useSelector(
    (state) => state.HandbookReducer.handbookData
  );
  const totalCount = useSelector(
    (state) => state.HandbookReducer.TotalNumberOfRecord
  );

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const lang = localStorage.getItem("i18nextLng");
  const role = localStorage.getItem("role");
  const [orderBy, setOrderBy] = useState("LAST_MODIFY_DATE"); //BookSeries ASSIGNMENT_NAME  SUBJECT  BookSeries  ASSIGN_DATE  DUE_DATE LAST_MODIFY_DATE
  const [order, setOrder] = useState("DESC"); //ASC   DESC
  const [loading, setLoading] = useState(true);
  const [listloading, setListLoading] = useState(true);
  //   const [assignmentEmpty, setAssignmentEmpty] = useState(false);
  // const [assignmentList, setAssignmentList] = useState([]);
  //   const [subjectFilterEmpty, setSubjectFilterEmpty] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  // const [totalCount, settotalCount] = useState(0);
  const [page, setPage] = useState(1); //1
  const userData = JSON.parse(localStorage.getItem("userData"));

  //For teacher only
  const [ableClasses, setableClasses] = useState([]);
  const [SelectedClass, setSelectedClass] = useState("");
  const [myHandbookBookFav, setmyHandbookBookFav] = useState([]);
  const [myHandbookBookFavTab, setmyHandbookBookFavTab] = useState(0);
  const [favSubjectList, setfavSubjectList] = useState([]);
  const [favLevelList, setfavLevelList] = useState([]);

  const [myHandbookLevelFav, setmyHandbookLevelFav] = useState([]);
  const [myHandbookLevelFavTab, setmyHandbookLevelFavTab] = useState(0);
  const [showSelect, setshowSelect] = useState(false);
  const [showSelectLevel, setshowSelectLevel] = useState(false);
  const [selectLevelValue, setselectLevelValue] = useState(t("All Classes"));
  const [selectBookValue, setselectBookValue] = useState(t("All Books"));

  const [favSeriesIds, setfavSeriesIds] = useState({});
  const [favHandbookLevelIds, setfavHandbookLevelIds] = useState({});
  //   useEffect(() => {
  //     if (lang == "en-US") {
  //       moment.locale("en-US");
  //     } else {
  //       moment.locale("zh-HK");
  //     }
  //   }, []);

  //When subject option change

  useEffect(() => {
    if (role != "student") {
      var preferencesObj = handbook_book(userData.Preferences);
      var myFavBookSeries = [];
      if (preferencesObj.length > 0) {
        let preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        if (preferencesArr.length > 0) {
          preferencesArr[0].BSId.forEach(function (id, index) {
            var obj = { BookSeriesId: id };
            myFavBookSeries.push(id);
          });
          setmyHandbookBookFav(myFavBookSeries);
        }
      }

      var preferencesObjLevel = handbook_level(userData.Preferences);
      var myFavHandbookLevel = [];
      if (preferencesObjLevel.length > 0) {
        let preferencesArrLevel = JSON.parse(
          preferencesObjLevel[0].PreferenceValue
        );

        if (preferencesArrLevel.length > 0) {
          preferencesArrLevel[0].BSId.forEach(function (id, index) {
            var obj = { BookSeriesId: id };
            myFavHandbookLevel.push(id);
          });
          setmyHandbookLevelFav(myFavHandbookLevel);
        }
      }

      var preferencesObjBtn = handbook_book_tab(userData.Preferences);
      var preferencesArrBtn = [];
      if (preferencesObjBtn.length > 0) {
        preferencesArrBtn = JSON.parse(preferencesObjBtn[0].PreferenceValue);
        setmyHandbookBookFavTab(preferencesArrBtn[0].tabSelect);
      }

      var preferencesObjBtn = handbook_level_tab(userData.Preferences);
      var preferencesArrBtn = [];
      if (preferencesObjBtn.length > 0) {
        preferencesArrBtn = JSON.parse(preferencesObjBtn[0].PreferenceValue);
        setmyHandbookLevelFavTab(preferencesArrBtn[0].tabSelect);
      }
    }

    //get allowes subject
    fetchSubjectQuery().then((data) => {
      if (role == "student") {
        var bkSort = [...data.user.AssignmentBookSeriesList];

        setSubjectList(
          bkSort.sort((a, b) => {
            return a.DisplaySequence - b.DisplaySequence;
          })
        );
      } else {
        console.log(data, "Subject list teacher");
        var filterNoIngestionIdGroups = data.user.School.StudyGroups.filter(
          (item, index) => {
            return (
              item.BookSeries.IngestionId != null &&
              item.BookSeries.IngestionId != ""
            );
          }
        ).sort((a, b) => {
          return a.StudyGroupName[
            a.StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName.localeCompare(
            b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
              .StudyGroupName
          );
        });
        var allsubjects = filterNoIngestionIdGroups
          .filter((item, index) => {
            return (
              filterNoIngestionIdGroups
                .map((o) => {
                  // return o.BookSeries.Subject.SubjectId;
                  return o.BookSeries.BookSeriesId;
                })
                // .indexOf(item.BookSeries.Subject.SubjectId) === index
                .indexOf(item.BookSeries.BookSeriesId) === index
            );
          })
          .sort((a, b) => {
            return (
              // a.BookSeries.Subject.DisplaySequence -
              // b.BookSeries.Subject.DisplaySequence
              a.BookSeries.DisplaySequence - b.BookSeries.DisplaySequence
            );
          });

        var subjectList = [];
        allsubjects.map((o) => {
          // subjectList.push(o.BookSeries.Subject);
          subjectList.push(o.BookSeries);
        });
        console.log(
          subjectList,
          "Subject list teacher",
          filterNoIngestionIdGroups
        );

        if (myFavBookSeries.length > 0) {
          var favBookup = subjectList.filter((obj) => {
            return myFavBookSeries.includes(obj.BookSeriesId);
          });
          setfavSubjectList(favBookup);
        }

        if (myFavHandbookLevel.length > 0) {
          var favLevelup = filterNoIngestionIdGroups.filter((obj) => {
            return myFavHandbookLevel.includes(obj.StudyGroupId);
          });
          setfavLevelList(favLevelup);
        }
        setableClasses(filterNoIngestionIdGroups);
        setSubjectList(subjectList);
      }
    });
  }, []);

  // code to detect the outside of clicked element, start here
  const specifiedElement = document.querySelector(".levelNameDivHandbook");
  const specifiedElement1 = document.body.contains(
    document.querySelector(".allClasses")
  )
    ? document.querySelector(".allClasses")
    : "";
  document.addEventListener("click", (event) => {
    const isClickInside = specifiedElement.contains(event.target);
    // if (!isClickInside) {
    if (
      !isClickInside &&
      document.body.contains(document.querySelector(".Component-handbook")) &&
      !document.querySelector(".Component-handbook").contains(event.target)
    ) {
      setshowSelect(false);
    }
    const isVisible = specifiedElement1.contains(event.target);
    // if (!isVisible) {
    if (
      !isVisible &&
      document.body.contains(document.querySelector(".component-new-select")) &&
      !document.querySelector(".component-new-select").contains(event.target)
    ) {
      setshowSelectLevel(false);
    }
  });

  // code to detect the outside of clicked element, ends here

  useEffect(() => {
    //get all by default
    // setAssignmentList([]);
    // dispatch({
    //   type: "SET_HANDBOOK",
    //   handbookData: [],
    // });
    setListLoading(true);
    fetchAssignmentQuery().then((data) => {
      console.log(data);

      const {
        user: {
          [role == "teacher" ? "AssignmentCreated" : "AssignmentBelongsTo"]: {
            ResourceAssignments = [{}],
            TotalNumberOfRecord = 0,
          },
        },
      } = data || {};

      dispatch({
        type: "SET_HANDBOOK",
        handbookData: JSON.parse(JSON.stringify(ResourceAssignments)),
      });
      dispatch({
        type: "SET_HANDBOOK_TOTAL_ITEMS",
        TotalNumberOfRecord: TotalNumberOfRecord,
      });
      // setAssignmentList(ResourceAssignments);
      // settotalCount(TotalNumberOfRecord);

      setLoading(false);
      setListLoading(false);
      //   if (ResourceAssignments.length == 0) {
      //     setAssignmentEmpty(true);
      //   }
    });
  }, [orderBy, order, page, subjectId, SelectedClass, totalCount]);

  const fetchAssignmentQuery = () => {
    var tempSub = [];
    var tempClass = [];
    if (subjectId != "") {
      tempSub.push(subjectId);
    }
    if (SelectedClass != "") {
      tempClass.push(SelectedClass);
    }
    var variables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      OrderBy: orderBy,
      Order: order,
      PageNo: page,
      PageSize: 20,
      BookSeriesId: tempSub,
    };
    var svariables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      OrderBy: orderBy,
      Order: order,
      PageNo: page,
      PageSize: 20,
      BookSeriesId: subjectId,
    };
    if (role == "teacher") {
      variables = {
        ...variables,
        StudyGroupId: SelectedClass,
      };

      return fetchQuery(
        environment,
        ViewAllNotificationTeacherListQuery,
        variables
      );
    } else {
      return fetchQuery(
        environment,
        ViewAllNotificationStudentListQuery,
        svariables
      );
    }
  };

  const fetchSubjectQuery = () => {
    var variables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
    };
    if (role == "teacher") {
      variables = {
        ...variables,
        FilterOnboarded: false,
      };
    }
    return fetchQuery(
      environment,
      role == "student"
        ? ViewAllNotificationSubjectListQuery
        : ViewAllNotificationTeacherSubjectListQuery,
      variables
    );
  };

  const getTotalPage = (total) => {
    if (parseInt(total) > 20) {
      var totalPages = Math.ceil(parseInt(total) / 20);
      return totalPages;
    } else {
      return 1;
    }
  };
  const onPageChange = (page) => {
    setPage(page);
  };

  const handleAssignmentIsView = (obj, returnBool) => {
    if (
      obj.AssignmentTargetStatus.map((i) => {
        return i.UserId;
      }).indexOf(userData.UserId) != -1
    ) {
      console.log(returnBool);
      return returnBool ? false : "";
    }

    return returnBool ? (
      true
    ) : (
      <div>
        <img src={new_assignment}></img>
      </div>
    );
  };

  const renderTheViews = (obj) => {
    var userIds = [];

    obj.AssignmentTargets.map((record, index) => {
      if (!record.UserId) {
        if (!record.StudyGroup) return;
        if (!record.StudyGroup.StudyGroupUser) return;
        var _userids = record.StudyGroup.StudyGroupUser.map((i) => {
          return i.UserId;
        });
        userIds = [...userIds, ..._userids];
      } else {
        userIds.push(record.UserId);
      }
    });
    userIds = [...new Set(userIds)];
    if (userIds.indexOf(userData.UserId) != -1) {
      //it will show the views for teacher only, so no need to check is student or not
      userIds.splice(userIds.indexOf(userData.UserId), 1);
    }
    return obj.AssignmentTargetStatus.length + "/" + userIds.length;
  };

  const renderAssignee = (obj) => {
    console.log(obj);
    var current_target_classes = obj.AssignmentTargets.filter((item, index) => {
      return item.User == null;
    }).sort((a, b) => {
      return a.StudyGroup.StudyGroupName[
        a.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
      ].StudyGroupName.localeCompare(
        b.StudyGroup.StudyGroupName[
          b.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
        ].StudyGroupName
      );
    });
    var current_target_users = obj.AssignmentTargets.filter((item, index) => {
      return item.StudyGroup == null;
    });
    var tempUsers = [],
      userObj = {};
    current_target_users.map((item, index) => {
      var matchGroup = item.User.BelongsToStudyGroup.map((i) => {
        return i.StudyGroup.BookSeries.BookSeriesId;
      }).indexOf(obj.BookSeries.BookSeriesId);
      if (matchGroup != -1) {
        userObj = {
          ...item,
          UserGroup: item.User.BelongsToStudyGroup[matchGroup],
        };
        delete userObj.BelongsToStudyGroup;
      } else {
        userObj = { ...item };
      }
      tempUsers.push(userObj);
    });
    current_target_users = tempUsers;
    console.log(current_target_classes, current_target_users);
    if (role == "teacher") {
      //For teacher
      return (
        <>
          {current_target_classes.map((obj, i) => {
            if (i == 0) {
              return (
                <Trans key={i} i18nKey="ClassTitle" count={1}>
                  {{
                    class:
                      obj.StudyGroup.StudyGroupName[
                        lang == obj.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                      ].StudyGroupName,
                  }}
                </Trans>
              );
            } else {
              return (
                <React.Fragment key={i}>
                  {", "}
                  <Trans i18nKey="ClassTitle" count={1} key={i}>
                    {{
                      class:
                        obj.StudyGroup.StudyGroupName[
                          lang == obj.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                        ].StudyGroupName,
                    }}
                  </Trans>
                </React.Fragment>
              );
            }
          })}
          {current_target_classes.length != 0 &&
            current_target_users.length != 0 &&
            ", "}
          {current_target_users.map((u, i) => {
            if (i == 0)
              return (
                u.UserGroup.StudyGroup.StudyGroupName[
                  lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                ].StudyGroupName +
                "-" +
                u.UserGroup.ClassNumber +
                " " +
                u.User.UserDisplayName[
                  u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                ].UserName
              );
            else
              return (
                ", " +
                u.UserGroup.StudyGroup.StudyGroupName[
                  lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                ].StudyGroupName +
                "-" +
                u.UserGroup.ClassNumber +
                " " +
                u.User.UserDisplayName[
                  u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                ].UserName
              );
          })}
        </>
      );
    }
    if (role == "student") {
      //For Student
      if (current_target_classes.length != 0) {
        //show class
        return (
          <Trans i18nKey="ClassTitle" count={1}>
            {{
              class:
                current_target_classes[0].StudyGroup.StudyGroupName[0]
                  .StudyGroupName,
            }}
          </Trans>
        );
      } else {
        //show user
        return (
          <>
            {current_target_users[0].UserGroup.StudyGroup.StudyGroupName[
              lang ==
              current_target_users[0].UserGroup.StudyGroup.StudyGroupName[0]
                .Lang
                ? 0
                : 1
            ].StudyGroupName +
              "-" +
              current_target_users[0].UserGroup.ClassNumber +
              " " +
              current_target_users[0].User.UserDisplayName[
                current_target_users[0].User.UserDisplayName[0].Lang == lang
                  ? 0
                  : 1
              ].UserName}
          </>
        );
      }
    }
  };
  const doSelectFunc = (e, val, name) => {
    e.stopPropagation();
    // console.log("book name is ", name, val);
    // on selecting the book series menu lists , starts here
    gaTrack(
      "send",
      "event",
      "General",
      "Select in class favourite list",
      `Book Series:${name}, Location:Handbook, Role:${userData.UserType}`
    );
    // on selecting the book series menu lists , starts here
    setselectBookValue(name);
    setSubjectId(val);
    setPage(1);
    setOrderBy("ASSIGN_DATE");
    setOrder("DESC");
    setshowSelect(false);
    if (role == "teacher") {
      setSelectedClass("");
    }
  };

  const doSelectFuncLevel = (e, val, name) => {
    e.stopPropagation();
    // console.log("class name is ", name, val);
    // on viewing the list add gatrack, starts here
    gaTrack(
      "send",
      "event",
      "General",
      "Select in class favourite list",
      `Book Series:${selectBookValue}, Class:${name}, Location:Handbook, Role:${userData.UserType}`
    );

    // on viewing the list add gatrack, ends here
    setselectLevelValue(name);
    setshowSelectLevel(false);
    setSelectedClass(val);
  };

  const openDetails = (obj) => {
    gaTrack(
      "send",
      "event",
      "Assignment",
      "Open",
      "Position:Handbook, Role:" + userData.UserType
    );

    if (role == "student") {
      if (handleAssignmentIsView(obj, true)) {
        var variables = {
          ResourceAssignId: obj.ResourceAssignId,
        };
        ReadResourceAssignmentMutation(variables, (data) => {
          if (data.readResourceAssignment == "true") {
            window.setnotificationUnreadCount();
          }
          console.log(data);
          var targetMyschool = document.querySelectorAll(
            `[data-myschool-handbook-id='${obj.ResourceAssignId}']`
          );
          if (targetMyschool.length > 0) {
            targetMyschool[0].innerHTML = "";
          }
          var target = document.querySelectorAll(
            `[data-studygroup-handbook-id='${obj.ResourceAssignId}']`
          );
          if (target.length > 0) {
            target[0].innerHTML = "";
          }
        });
      }
      // var target = document.querySelectorAll(
      //   `[data-studygroup-handbook-id='${obj.ResourceAssignId}']`
      // );
      // let new_assignmentList = assignmentList;
      // assignmentList.map((item, index) => {
      //   if (item.ResourceAssignId == obj.ResourceAssignId) {
      //     console.log(new_assignmentList, "new_assignmentList");

      //     // target[0].innerHTML = "";
      //     var match_index = new_assignmentList[
      //       index
      //     ].AssignmentTargetStatus.map((i) => {
      //       return i.UserId;
      //     });
      //     if (match_index.indexOf(userData.UserId) == -1) {
      //       console.log("[new_assignmentList AssignmentTargetStatus] find");
      //       new_assignmentList[index].AssignmentTargetStatus = [
      //         ...new_assignmentList[index].AssignmentTargetStatus,
      //       ];
      //       new_assignmentList[index].AssignmentTargetStatus.push({
      //         ResourceAssignId: obj.ResourceAssignId,
      //         Status: "Read",
      //         UserId: userData.UserId,
      //       });

      //       dispatch({
      //         type: "SET_HANDBOOK",
      //         handbookData: new_assignmentList,
      //       });
      //     }

      //     // console.log(target, "target");
      //   }
      // });
    }
    setAssignmentDetailsData({
      isOpen: true,
      resId: obj.ResourceAssignId,
      AssignmentName: obj.AssignmentName,
    });
  };

  return (
    <div className={"handbook-card"}>
      <div className="title_row">{t("Handbook")}</div>
      <div className="filter-row">
        <CircleCloseButton
          onCloseButton={onCloseButton}
          design="design_assign"
        />
        {subjectList && (
          <div
            className="levelNameDivHandbook"
            onClick={() => {
              // on opening the book series favorite menu lists , starts here
              gaTrack(
                "send",
                "event",
                "General",
                "Open book series favourite list",
                `Location:Handbook, Role:${userData.UserType}`
              );
              // on opening the book series favorite menu lists , starts here
              setshowSelect(!showSelect);
              setshowSelectLevel(false);
            }}
          >
            {t("Book series")}
            <div className="new-label-text">{selectBookValue}</div>
            <span className="class-arrow-down-hand">
              <img src={arrowIcon} />
            </span>
          </div>
        )}
        {showSelect && (
          <HandBookDropdownNew
            {...props}
            subjectList={subjectList}
            favSubjectList={favSubjectList}
            myBookFav={myHandbookBookFav}
            bookFavTab={myHandbookBookFavTab}
            settabOpt={(val) => setmyHandbookBookFavTab(val)}
            doSelectFunc={doSelectFunc}
            favSeriesIds={favSeriesIds}
            role={role}
            setfavSeriesIds={setfavSeriesIds}
            setfavSubjectList={(data) => setfavSubjectList(data)}
          />
        )}
        {ableClasses && role == "teacher" && subjectId != "" && (
          <div
            className="levelNameDivHandbook allClasses"
            onClick={() => {
              setshowSelect(false);
              setshowSelectLevel(!showSelectLevel);
            }}
          >
            {t("Class")}
            <div className="new-label-text-2">{selectLevelValue}</div>
            <span className="class-arrow-down-hand">
              <img src={arrowIcon} />
            </span>
          </div>
        )}
        {role == "teacher" && subjectId != "" && showSelectLevel && (
          <HandBookSelectPopUp
            {...props}
            ableClasses={ableClasses}
            subjectId={subjectId}
            favLevelList={favLevelList}
            myLevelFav={myHandbookLevelFav}
            levelFavTab={myHandbookLevelFavTab}
            settabOptLevel={(val) => setmyHandbookLevelFavTab(val)}
            doSelectFunc={doSelectFuncLevel}
            favHandbookLevelIds={favHandbookLevelIds}
            setfavHandbookLevelIds={setfavHandbookLevelIds}
            role={role}
            setfavLevelList={(data) => setfavLevelList(data)}
            selectBookValue={selectBookValue}
          />
        )}

        {/* {role == "teacher" && subjectId != "" && (
          <select
            value={SelectedClass}
            onChange={(e) => {
              setSelectedClass(e.target.value);
            }}
            // style={{ height: "36px", overflowX: "auto", margin: "10px" }}
          >
            <option key="0" value="">
              {t("All Classes")}
            </option>
            {ableClasses.map((obj, index) => {
              if (obj.BookSeries.BookSeriesId == subjectId)
                return (
                  <option key={index} value={obj.StudyGroupId}>
                    {
                      obj.StudyGroupName[
                        obj.StudyGroupName[0].Lang == lang ? 0 : 1
                      ].StudyGroupName
                    }
                  </option>
                );
            })}
          </select>
        )} */}
      </div>

      <section className="assignment-handbook">
        {!loading && !listloading && assignmentList == null && (
          <MessageBox
            id={"Noti_Assignment"}
            src={icon}
            message={t("There is no assignment yet.")}
          />
        )}
        {loading ? (
          <Loading type={"inpage"} />
        ) : (
          assignmentList != null && (
            <>
              {!isMobileOnly ? (
                <table className="table handbook">
                  <thead>
                    <tr>
                      <th className="dot"></th>
                      {/* <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderBy("SUBJECT");
                          if (orderBy == "SUBJECT") {
                            setOrder(order == "DESC" ? "ASC" : "DESC");
                          }
                        }}
                      >
                        {t("Subject")}{" "}
                        {orderBy == "SUBJECT" &&
                          (order == "DESC" ? (
                            <i className="icon mdi mdi-long-arrow-down"></i>
                          ) : (
                            <i className="icon mdi mdi-long-arrow-up"></i>
                          ))}
                      </th> */}
                      <th
                        className="bookseries"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderBy("BookSeries");
                          if (orderBy == "BookSeries") {
                            setOrder(order == "DESC" ? "ASC" : "DESC");
                          }
                        }}
                      >
                        {t("Book")}{" "}
                        {orderBy == "BookSeries" &&
                          (order == "DESC" ? (
                            <i className="icon mdi mdi-long-arrow-down"></i>
                          ) : (
                            <i className="icon mdi mdi-long-arrow-up"></i>
                          ))}
                      </th>
                      <th
                        className="title"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderBy("ASSIGNMENT_NAME");
                          if (orderBy == "ASSIGNMENT_NAME") {
                            setOrder(order == "DESC" ? "ASC" : "DESC");
                          }
                        }}
                      >
                        {t("Title")}{" "}
                        {orderBy == "ASSIGNMENT_NAME" &&
                          (order == "DESC" ? (
                            <i className="icon mdi mdi-long-arrow-down"></i>
                          ) : (
                            <i className="icon mdi mdi-long-arrow-up"></i>
                          ))}
                      </th>
                      <th
                        className="date"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderBy("LAST_MODIFY_DATE");
                          if (orderBy == "LAST_MODIFY_DATE") {
                            setOrder(order == "DESC" ? "ASC" : "DESC");
                          }
                        }}
                      >
                        {t("Date")}{" "}
                        {orderBy == "LAST_MODIFY_DATE" &&
                          (order == "DESC" ? (
                            <i className="icon mdi mdi-long-arrow-down"></i>
                          ) : (
                            <i className="icon mdi mdi-long-arrow-up"></i>
                          ))}
                      </th>
                      <th className="from">{t("From")}</th>
                      {role == "teacher" && <th className="to">{t("To")}</th>}
                      <th
                        className="due_date"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderBy("DUE_DATE");
                          if (orderBy == "DUE_DATE") {
                            setOrder(order == "DESC" ? "ASC" : "DESC");
                          }
                        }}
                      >
                        {t("Due Date")}{" "}
                        {orderBy == "DUE_DATE" &&
                          (order == "DESC" ? (
                            <i className="icon mdi mdi-long-arrow-down"></i>
                          ) : (
                            <i className="icon mdi mdi-long-arrow-up"></i>
                          ))}
                      </th>
                      <th className="view">
                        {role == "teacher" ? t("View") : ""}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listloading && (
                      <tr className="loading_tr">
                        <td colSpan={role == "student" ? "7" : "8"}>
                          <Loading type={"inpage"} />
                        </td>
                      </tr>
                    )}
                    {!listloading &&
                      assignmentList.map((obj, index) => {
                        return (
                          <tr
                            className="pointer"
                            key={index}
                            onClick={() => {
                              openDetails(obj);
                            }}
                          >
                            <td
                              className="dot"
                              data-studygroup-handbook-id={obj.ResourceAssignId}
                            >
                              {role == "student" &&
                                handleAssignmentIsView(obj, false)}
                            </td>
                            <td className="bookseries">
                              {
                                // obj.Subject.SubjectName[
                                //   obj.Subject.SubjectName[0].Lang == lang
                                //     ? 0
                                //     : 1
                                // ].SubjectName
                                obj.BookSeries.BookSeriesName[
                                  obj.BookSeries.BookSeriesName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].BookSeriesName
                              }
                            </td>
                            <td className="title">{obj.AssignmentName}</td>
                            <td>
                              {/* {moment(obj.CreateDate).format(
                            lang == "en-US"
                              ? "DD MMMM YYYY"
                              : "YYYY[年] M[月] D[日]"
                          )} */}
                              {/* {moment(obj.CreateDate).format("YYYY-MM-DD")} */}
                              {moment(obj.LastUpdateDate).format("YYYY-MM-DD")}
                            </td>
                            <td className="from">
                              {
                                obj.CreateByUser.UserDisplayName[
                                  obj.CreateByUser.UserDisplayName[0].Lang ==
                                  lang
                                    ? 0
                                    : 1
                                ].UserName
                              }
                            </td>
                            {/* for teacher only */}
                            {role == "teacher" && (
                              <td className="to">{renderAssignee(obj)}</td>
                            )}
                            <td className="due_date">
                              {obj.DueDate ? (
                                <span style={GetDueDateStyle(obj?.DueDate)}>
                                  {moment(obj.DueDate).format("YYYY-MM-DD")}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="view">
                              <div className="views">
                                <div className="number">
                                  {role == "teacher" ? renderTheViews(obj) : ""}
                                </div>
                                <div className="handle">
                                  <i className="icon mdi mdi-chevron-right"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <NotificationItem
                  from="Handbook"
                  notificationData={assignmentList}
                  handleAssignmentDetails={(data) => {
                    setAssignmentDetailsData({
                      isOpen: data.isOpen,
                      resId: data.resId,
                      AssignmentName: data.AssignmentName,
                    });
                  }}
                  t={t}
                />
              )}
              <div className="pagination-row">
                <Pagination
                  currentPage={page}
                  totalPage={getTotalPage(totalCount)}
                  onPageChange={onPageChange}
                  t={t}
                />
              </div>
            </>
          )
        )}
      </section>
    </div>
  );
};
