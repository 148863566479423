/**
 * @generated SignedSource<<5ea0ac8ccb2dcb089b55e35b60c6ec6e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type ModelAnswerTabObjectQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  Lang?: ?LangEnumType,
|};
export type ModelAnswerTabObjectQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnosityModelAnswer: ?string,
    |}>,
  |},
|};
export type ModelAnswerTabObjectQuery = {|
  variables: ModelAnswerTabObjectQuery$variables,
  response: ModelAnswerTabObjectQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "Lang",
      "variableName": "Lang"
    }
  ],
  "kind": "ScalarField",
  "name": "LearnosityModelAnswer",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModelAnswerTabObjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModelAnswerTabObjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2cb79b1f35e0af3bcc22b1b44ffc7e33",
    "id": null,
    "metadata": {},
    "name": "ModelAnswerTabObjectQuery",
    "operationKind": "query",
    "text": "query ModelAnswerTabObjectQuery(\n  $ResourceId: [String]!\n  $Lang: LangEnumType\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      LearnosityModelAnswer(Lang: $Lang)\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f997fd4673511e1c7f26765ab17bf439";

module.exports = ((node/*: any*/)/*: Query<
  ModelAnswerTabObjectQuery$variables,
  ModelAnswerTabObjectQuery$data,
>*/);
