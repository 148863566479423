import React, { useState, useEffect, useRef } from "react";
import "./idlepopup.scss";
import { useDispatch, useSelector } from "react-redux";
const IdlePopUp = (props) => {
  const { intervalId, bookData, setShowIdlePopUp, showIdlePopUp, t,prizmBookId, resetIdleTime } = props;
  const dispatch = useDispatch();
  const [currentBookPopup, setcurrentBookPopup] = useState([]);
  const [counter, setCounter] = useState(30);
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000);
    }

    if(counter == 0){
        clearTimeout(timer);
        clearInterval(intervalId)
        onClickBackSDL()
    }
    

  },[counter]);

  
  const padTime = time => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  
  const format = time => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);
  
    // Get the seconds left after converting minutes
    const seconds = time % 60;
  
    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };
  useEffect(() => {
    var listArray = bookData.Books.filter((o) => o.BookId == prizmBookId);
    //console.log("kkkk", prizmBookId, listArray);
    setcurrentBookPopup(listArray);
    const pageClickEvent = (e) => {
      if (
        notification_popupref.current !== null &&
        !notification_popupref.current.contains(e.target)
      ) {
        setShowIdlePopUp(false);
        resetIdleTime()
      }
    };
    if (showIdlePopUp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showIdlePopUp]);
  const notification_popupref = useRef(null);

  const onClickBackSDL = () => {

    if(props.location.pathname.indexOf("/start") != -1){
      console.log("Stop", intervalId)
      clearInterval(intervalId)
    }
    props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");

      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: currentBookPopup[0].BookId,
          selectedBookName: currentBookPopup[0].BookName[0].BookName,
          selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
          selectedBookSeriesId: bookData.BookSeriesId,
          selectedResourceName:selectOptions.selectedResourceName,
          selectedClassLevelId: selectOptions.selectedStudyGroupId,
          selectedClassLevelName: selectOptions.selectedStudyGroupName,
          selectecResourceTab:selectOptions.selectecResourceTab,
          seletctedTabSDL:selectOptions.seletctedTabSDL,
          selectedToggle: selectOptions.selectedToggle,

        },
      });
 
  };
  return (
    <div className="activity_notification__popup-overlay">
      <div className="idle_notification__popup" ref={notification_popupref}>
       
        <h3 className="activity_notification__popup-heading">
          
          {t("Are you still working?")}
        </h3>
        <span className="activity_notification__popup-border"></span>
        <div className="activity_notification__popup-bottom">
        
          {/* <button
            className="buttons btns activity_notification__popup-back-btn"
            onClick={() => {
              onClickBackSDL();
            }}
          >
            
            {t("OK")}
          </button>  */}
          <button
            className="buttons btns activity_notification__popup-ok-btn"
            onClick={() => {
               // clearTimeout(timer);
              setShowIdlePopUp(false);
              resetIdleTime()
            }}
          >
            {t("Yes, stay here")}
          </button>
        
        </div>
      </div>
    </div>
  );
};

export default IdlePopUp;
