import React, { useState, useRef } from "react";
import { Button, Modal, Input } from 'antd';

const useConfig = () => {

    const [showConfigBox, setShowConfigBox] = useState(false);
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
    const inputRef = useRef(null);
    const passwordRef = useRef(null);
    const INIT_URL_LOCAL_STORAGE_KEY = "pec_app_init_url";

    const handleOk = () => {
        if (isPasswordCorrect){
            console.log(inputRef.current.input.value);
            window.localStorage.setItem(INIT_URL_LOCAL_STORAGE_KEY, inputRef.current.input.value);
            window.location.reload();
            setShowConfigBox(false);
        } else {
            if (passwordRef.current.input.value === "Pears0n") {
                setIsPasswordCorrect(true);
            } else {
                alert("wrong password")
            }
        }
    };

    const handleCancel = () => {
        setShowConfigBox(false);
        setIsPasswordCorrect(false);
    };

    const ConfigModal = () => (
        <Modal title="Config" open={showConfigBox} onOk={handleOk} onCancel={handleCancel}>
            {isPasswordCorrect?
                <Input placeholder="Please enter the init Url."
                       ref={inputRef}
                       defaultValue={window.localStorage.getItem(INIT_URL_LOCAL_STORAGE_KEY)?
                           window.localStorage.getItem(INIT_URL_LOCAL_STORAGE_KEY):
                           window.location.href + "app/" }

                />:
                <Input placeholder="Please enter the password"
                       ref={passwordRef}
                />
            }
        </Modal>
    )

    return { setShowConfigBox, ConfigModal }
}

export default useConfig;