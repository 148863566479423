import React from "react";
import PropTypes from "prop-types";
import { Bar as BarChart } from "react-chartjs-2";
require("./RoundedBars.js");
export const BasicChart = ({ datasets }) => {
  return (
    <BarChart
      data={datasets}
      options={{
        maintainAspectRatio: false,
        cornerRadius: 15,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          ],
        },
      }}
    />
  );
};

BasicChart.propTypes = {
  datasets: PropTypes.object,
};

export default BasicChart;
