/**
 * @generated SignedSource<<06279c5b04110a2ca52cc5238eaa283c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type QuestionViewGetStudyGroupListQuery$variables = {|
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type QuestionViewGetStudyGroupListQuery$data = {|
  +studyGroup: ?{|
    +StudyGroupName: ?$ReadOnlyArray<?{|
      +Lang: ?string,
      +StudyGroupName: ?string,
    |}>,
    +StudyGroupInSameLevel: ?$ReadOnlyArray<?{|
      +StudyGroupId: ?string,
      +StudyGroupName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +StudyGroupName: ?string,
      |}>,
    |}>,
  |},
|};
export type QuestionViewGetStudyGroupListQuery = {|
  variables: QuestionViewGetStudyGroupListQuery$variables,
  response: QuestionViewGetStudyGroupListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionViewGetStudyGroupListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "StudyGroup",
        "kind": "LinkedField",
        "name": "studyGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "StudyGroup",
            "kind": "LinkedField",
            "name": "StudyGroupInSameLevel",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuestionViewGetStudyGroupListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "StudyGroup",
        "kind": "LinkedField",
        "name": "studyGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "StudyGroup",
            "kind": "LinkedField",
            "name": "StudyGroupInSameLevel",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca0267667dc17ff71b47a56317f2bb17",
    "id": null,
    "metadata": {},
    "name": "QuestionViewGetStudyGroupListQuery",
    "operationKind": "query",
    "text": "query QuestionViewGetStudyGroupListQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupName {\n      Lang\n      StudyGroupName\n    }\n    StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {\n      StudyGroupId\n      StudyGroupName {\n        Lang\n        StudyGroupName\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "468cf4190ace9268dd4e43378c215458";

module.exports = ((node/*: any*/)/*: Query<
  QuestionViewGetStudyGroupListQuery$variables,
  QuestionViewGetStudyGroupListQuery$data,
>*/);
