import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation CreateAssignmentMutation(
    $ResourceId: [String]!
    $BookSeriesId: String!
    $UserId: [String]
    $SchoolYearId: String
    $StudyGroupId: [String]
    $AssignmentName: String!
    $AssignmentDescription: String
    $DueDate: String
    $AllowRedo: Boolean
  ) {
    createAssignment(
      ResourceId: $ResourceId
      BookSeriesId: $BookSeriesId
      UserId: $UserId
      SchoolYearId: $SchoolYearId
      StudyGroupId: $StudyGroupId
      AssignmentName: $AssignmentName
      AssignmentDescription: $AssignmentDescription
      DueDate: $DueDate
      AllowRedo: $AllowRedo
    ) {
      ResultCode
      ResultDescription
    }
  }
`;
export default function CreateAssignmentMutation(data, action) {
  const variables = {
    ResourceId: data.ResourceId,
    BookSeriesId: data.BookSeriesId,
    UserId: data.UserId,
    SchoolYearId: data.SchoolYearId,
    StudyGroupId: data.StudyGroupId,
    AssignmentName: data.AssignmentName,
    AssignmentDescription: data.AssignmentDescription,
    DueDate: data.DueDate,
    AllowRedo: data.AllowRedo ? data.AllowRedo : null,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
