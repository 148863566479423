import React from "react";

const RawResultEmpty = (props) => {
  const { reload, t } = props;
  return (
    <div
      className="margin-box"
      style={{
        margin: "auto",
        textAlign: "center",
      }}
    >
      <div style={{ padding: "20px", color: "#505759", fontSize: "16px" }}>
        {t("The data is being processed.")}
      </div>
      <button
        className="btn btn-warning btn-orange"
        style={{
          borderRadius: "21px",
          padding: "12px 36px",
        }}
        onClick={() => {
          window.scrollTo(0, 0);
          reload();
        }}
      >
        {t("Refresh")}
      </button>
    </div>
  );
};

export default RawResultEmpty;
