import React, { useState, useEffect } from "react";
import "./index.scss";

const Pagination = (props) => {
  const {  handbookPagination } = props;
  const { t, haveFirstLastButton } = props;
  console.log("pagination", props)
  const [currentPage, setcurrentPage] = useState(props.currentPage);
  const [totalPage, settotalPage] = useState(props.totalPage);

  //const [totalPage, settotalPage] = useState(props.totalPage);

  const [allPage, setAllPage] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const pages = [];

  useEffect(() => {
    //alert("PAgination")
    // console.log(props.totalPage, props.currentPage);
    //alert(props.totalPage);
    //alert(props.currentPage);
    for (let i = 1; i <= totalPage; i++) {
      pages.push(i);
    }
    setAllPage(pages);
    setcurrentPage(props.currentPage);
    settotalPage(props.totalPage);
  }, [props.currentPage, props.totalPage]);

  const onChangePage = (action, page) => {
    // action -> dropDown / button
    console.log(action, page);
    
    if (action == "button") {
      // this.setState({
      //   currentPage: page,
      // });
      // this.props.onPageChange(page);
      setcurrentPage(page);
      props.onPageChange(page);
    }
    //call back function
  };

  const renderPagesOption = (totalPaget, currentPaget) => {
    const options = [];
    //alert(totalPaget);
    for (var i = 1; i <= totalPaget; i++) {
      //alert()
      //alert(totalPaget)
      return (
        <button
              className={`main__handbooks-pagination-btn ${
                i == currentPaget ? "active" : ""
              }`}
              disabled={currentPaget == totalPaget ? true : false}
              onClick={() => onChangePage("button", parseInt(currentPaget) + 1)}
              // onClick={() => {
              //   onChangePage(page);
              //   handbookPagination(page);
              // }}
            >
              {i}
            </button>
  
       
      );
    }
    
  };


 

  return (
    <>
    {haveFirstLastButton && (
      <div className="main__handbooks-pagination-top">
        <label className="pagination-label-top">First</label>
        <div className="main__handbooks-pagination-lt"></div>
      </div>
    )}
    {allPage.map((page) => {
        return (
          <button
            className={`main__handbooks-pagination-btn ${
              page == currentPage ? "active" : ""
            }`}
            onClick={() => {
              onChangePage("button", parseInt(page))
              //setActivePage(page);
              //handbookPagination(page);
            }}
          >
            {page}
          </button>
        );
      })}
      {haveFirstLastButton && (
      <div className="main__handbooks-pagination-bottom">
        <label className="pagination-label-bottom">Last</label>
        <div className="main__handbooks-pagination-gt"></div>
      </div>)}
    </>
  );
};

export default Pagination;
