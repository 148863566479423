import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetB2CResourcesCenterTopicsMenuQuery = graphql`
  query GetB2CResourcesCenterTopicsMenuQuery(
    $UserId: String!
    $BookSeriesId: String!
    $BookId: String!
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesName {
          Lang
          BookSeriesName
        }
        Books(BookId: $BookId) {
          BookName {
            Lang
            BookName
          }
          AdoptedTagHierarchy {
            RelatedTagType {
              Tags(BookId: $BookId) {
                TagName
                TagLongName
                TagType
              }
            }
            AdoptedLearnosityResource(BookId: $BookId) {
              Level2TagType
              Level2TagName
            }
          }
        }
      }
    }
  }
`;
const GetB2CResourcesCenterTopicsMenu = (variables) => {
  return fetchQuery(
    environment,
    GetB2CResourcesCenterTopicsMenuQuery,
    variables
  );
};

export default GetB2CResourcesCenterTopicsMenu;
