import React, { useState, useEffect } from "react";

export default (props) => {
  const {
    tabName,
    t,
    studyGroupListAdvance,
    listAdvance,
    statusMapping,
    onListChange,
  } = props;
  const lang = localStorage.getItem("i18nextLng");

  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableStatus, setTableStatus] = useState(statusMapping.hide);
  const [studyGroups, setStudyGroups] = useState([]);

  useEffect(() => {
    if (studyGroupListAdvance) {
      setStudyGroups(studyGroupListAdvance.slice());
    }
  }, [studyGroupListAdvance]);

  useEffect(() => {
    setCols(setColumnsStatus());
    setRows(setRowsStatus());
    setTableStatus(setStudyGroupStatus());
    onListChange(studyGroups);
  }, [JSON.stringify(studyGroups)]);

  const setRowsStatus = () => {
    return studyGroups.map((data) => {
      const accessmentVisibility = data.studyData.reduce((acc, d) => {
        acc[d.config] = (acc[d.config] || 0) + 1;
        return acc;
      }, {});

      const { length } = data.studyData;

      if (accessmentVisibility[statusMapping.show] === length) {
        return statusMapping.show;
      }

      if (accessmentVisibility[statusMapping.hide] === length) {
        return statusMapping.hide;
      }

      return "Partial";
    });
  };

  const setColumnsStatus = () => {
    return studyGroups.reduce((acc, data) => {
      data.studyData.map((d, i) => {
        if (!acc[i]) {
          acc[i] = d.config;
        }

        acc[i] = acc[i] === d.config ? d.config : "Partial";
      });
      return acc;
    }, []);
  };

  const setStudyGroupStatus = () => {
    let result = "";

    for (let data of studyGroups) {
      const { show, hide } = statusMapping;
      const { length } = data.studyData;

      const accessmentVisibility = data.studyData.reduce((acc, d) => {
        acc[d.config] = (acc[d.config] || 0) + 1;
        return acc;
      }, {});

      if (
        accessmentVisibility[show] === length &&
        ["", show].includes(result)
      ) {
        result = show;
        continue;
      }

      if (
        accessmentVisibility[hide] === length &&
        ["", hide].includes(result)
      ) {
        result = hide;
        continue;
      }

      result = `Partial`;
      break;
    }

    return result;
  };

  const tickWholeColumns = (studyDataIndex) => {
    const column = cols[studyDataIndex];

    const groups = studyGroups.map((data) => {
      const studyData = data.studyData.slice().map((d, i) => {
        if (i !== studyDataIndex) {
          return d;
        }

        return {
          ...d,
          config: [statusMapping.hide, "Partial"].includes(column)
            ? statusMapping.show
            : statusMapping.hide,
        };
      });

      return { ...data, studyData };
    });

    setStudyGroups(groups);
  };

  const tickWholeRows = (resId, studyDataIndex) => {
    const row = rows[studyDataIndex];

    const groups = studyGroups.map((data) => {
      if (data.resId !== resId) {
        return data;
      }

      const studyData = data.studyData.slice().map((d) => {
        return {
          ...d,
          config: [statusMapping.hide, "Partial"].includes(row)
            ? statusMapping.show
            : statusMapping.hide,
        };
      });

      return { ...data, studyData };
    });

    setStudyGroups(groups);
  };

  const toggleAccessment = (resId, studyGroupId) => {
    const groups = studyGroups.map((data) => {
      if (data.resId !== resId) {
        return data;
      }

      const studyData = data.studyData.slice().map((d) => {
        if (d.StudyGroupId !== studyGroupId) {
          return d;
        }

        return {
          ...d,
          config: [statusMapping.hide, "Partial"].includes(d.config)
            ? statusMapping.show
            : statusMapping.hide,
        };
      });

      return { ...data, studyData };
    });

    setStudyGroups(groups);
  };

  const tickWholeTable = () => {
    const groups = studyGroups.map((data) => {
      const studyData = data.studyData.slice().map((d) => {
        return {
          ...d,
          config: [statusMapping.hide, "Partial"].includes(tableStatus)
            ? statusMapping.show
            : statusMapping.hide,
        };
      });

      return { ...data, studyData };
    });

    setStudyGroups(groups);
  };

  return (
    <table>
      <thead>
        <tr className="tab">
          <th className="assessment-title">
            <div>{t("Assessment")}</div>
          </th>
          <th className="studygroup-list">
            <div className="select-all-tab">
              <label className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={tableStatus === statusMapping.show ? true : false}
                  onChange={() => tickWholeTable()}
                  ref={(el) =>
                    el &&
                    (el.indeterminate =
                      `Partial` === tableStatus ? true : false)
                  }
                />
                <span className="custom-control-label" id="checkC2" />
                {t(tabName)}
              </label>
            </div>

            <div>
              {listAdvance.map((obj, i) => {
                return (
                  <label
                    className="custom-control custom-control-sm custom-checkbox"
                    key={obj.StudyGroupId}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={
                        cols[i] && cols[i] === statusMapping.show ? true : false
                      }
                      onChange={() => tickWholeColumns(i)}
                      ref={(el) =>
                        el &&
                        (el.indeterminate =
                          `Partial` === cols[i] ? true : false)
                      }
                    />
                    <span className="custom-control-label" id="checkC2" />
                    {
                      obj.StudyGroupName[
                        obj.StudyGroupName[0].Lang == lang ? 0 : 1
                      ].StudyGroupName
                    }
                  </label>
                );
              })}
            </div>
          </th>
        </tr>
      </thead>

      <tbody
        style={{
          display: "table-row-group",
        }}
      >
        {studyGroups &&
          studyGroups.map((obj, index) => {
            return (
              <tr key={index}>
                <td
                  data-tut={"studygroup_assessment_list_title_" + index}
                  className="title"
                >
                  <div className="assessment">
                    <div className="assessment-name">
                      <label className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={
                            rows[index] && rows[index] === statusMapping.show
                              ? true
                              : false
                          }
                          onChange={(e) => tickWholeRows(obj.resId, index)}
                          ref={(el) =>
                            el &&
                            (el.indeterminate =
                              `Partial` === rows[index] ? true : false)
                          }
                        />
                        <span className="custom-control-label" />
                        {obj.resName ? obj.resName : ""}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="studygroup-list">
                  <div>
                    {obj.studyData.map((cobj) => {
                      return (
                        <label
                          className="custom-control custom-control-sm custom-checkbox"
                          key={cobj.StudyGroupId}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={
                              cobj.config === statusMapping.show ? true : false
                            }
                            onChange={(e) =>
                              toggleAccessment(obj.resId, cobj.StudyGroupId)
                            }
                          />
                          <span className="custom-control-label" id="checkC2" />
                          {
                            cobj.StudyGroupName[
                              cobj.StudyGroupName[0].Lang == lang ? 0 : 1
                            ].StudyGroupName
                          }
                        </label>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
