import { commitMutation, graphql } from "react-relay";
import environment from "../../Environment";

const mutation = graphql`
  mutation OnBoardingMutation(
    $SchoolYearId: String!
    $StudyGroupIds: [String]!
  ) {
    teacherOnboard(SchoolYearId: $SchoolYearId, StudyGroupIds: $StudyGroupIds)
  }
`;

export default function OnBoardingMutation(data, action) {
  const variables = {
    SchoolYearId: data.SchoolYearId,
    StudyGroupIds: data.StudyGroupIds,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
