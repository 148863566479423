import image from "../../../assets/img/file_type/image.svg";
import video from "../../../assets/img/file_type/video.svg";
import pdf from "../../../assets/img/file_type/pdf.svg";
import ppt from "../../../assets/img/file_type/ppt.svg";
import exercise from "../../../assets/img/exercise.svg";
import doc from "../../../assets/img/file_type/doc.svg";
import web from "../../../assets/img/file_type/web.svg";
import zip from "../../../assets/img/file_type/zip.svg";
import xls from "../../../assets/img/file_type/xls.svg";
import audio from "../../../assets/img/file_type/audio.svg";
import interactive from "../../../assets/img/file_type/interactive.svg";
import iactivity from "../../../assets/img/file_type/iactivity.svg";
import ilesson from "../../../assets/img/file_type/ilesson.svg";
import ope from "../../../assets/img/file_type/ope.svg";
import pack from "../../../assets/img/file_type/pack.svg";
import default_s from "../../../assets/img/file_type/default-s.svg";
import prizm from "../../../assets/img/file_type/exercise-formative.svg";

//big
import audio_b from "../../../assets/img/resources_icon/audio.svg";
import aiactivity_b from "../../../assets/img/resources_icon/iactivity.svg";
import doc_b from "../../../assets/img/resources_icon/doc.svg";
import ilesson_b from "../../../assets/img/resources_icon/iLesson.svg";
import image_b from "../../../assets/img/resources_icon/image.svg";
import ppt_b from "../../../assets/img/resources_icon/ppt.svg";
import ebook_b from "../../../assets/img/resources_icon/ebook-b.svg";
import html_b from "../../../assets/img/resources_icon/html.svg";
import web_b from "../../../assets/img/resources_icon/web.svg";
import pdf_b from "../../../assets/img/resources_icon/pdf.svg";
import video_b from "../../../assets/img/resources_icon/video-b.svg";
// import video_b_2 from "../../../assets/img/resources_icon/video-b2.svg";
import video_b_2 from "../../../assets/img/resources_icon/video.svg";
import smartclass_b from "../../../assets/img/resources_icon/smartclass.svg";
import interactive_b from "../../../assets/img/resources_icon/interactive.svg";
import ope_b from "../../../assets/img/resources_icon/ope.svg";
import ggb_b from "../../../assets/img/resources_icon/ggb.svg";
import xls_b from "../../../assets/img/resources_icon/xls.svg";
import zip_b from "../../../assets/img/resources_icon/zip.svg";
import pack_b from "../../../assets/img/resources_icon/pack.svg";
import default_b from "../../../assets/img/resources_icon/default.svg";
import prizm_b from "../../../assets/img/resources_icon/exercise-formative-large.svg";

const ResourceIconMapping = (ResourceType, type, file_type) => {
  if (type == "small")
    switch (ResourceType) {
      case "PACK":
        return pack;
      case "PRIZM":
        return prizm;
      case "OPE":
        return ope;
      case "ILESSON":
        return ilesson;
      case "IACTIVITY":
        return iactivity;
      case "WEB_LINK":
        return web;
      case "DOCUMENT":
        const checkDocumentTypeS = file_type.split(".");
        switch (checkDocumentTypeS.pop()) {
          case "csv":
            return xls;
          case "pdf":
            return pdf;
          case "ppt":
            return ppt;
          case "doc":
            return doc;
          default:
            return doc;
        }
      case "VIDEO":
        return video;

      case "YOUTUBE":
        return video;
      case "VIMEO":
        return video;
      case "LEARNOSITY":
        return exercise;
      case "AUDIO":
        return audio;
      case "IMAGE":
        return image;
      case "INTERACTIVE":
        return interactive;
      case "OTHER":
        const checkOtherTypeS = file_type.split(".");

        switch (checkOtherTypeS.pop()) {
          case "zip":
            return zip;
          default:
            return default_s;
        }
      default:
        return web;
    }
  if (type == "big")
    switch (ResourceType) {
      case "PACK":
        return pack_b;
      case "PRIZM":
        return prizm_b;
      case "OPE":
        return ope_b;
      case "ILESSON":
        return ilesson_b;
      case "IACTIVITY":
        return aiactivity_b;
      case "WEB_LINK":
        return web_b;
      case "DOCUMENT":
        const checkDocumentTypeB = file_type.split(".");
        switch (checkDocumentTypeB.pop()) {
          case "csv":
            return xls_b;
          case "pdf":
            return pdf_b;
          case "ppt":
            return ppt_b;
          case "doc":
            return doc_b;
          default:
            return doc_b;
        }
        break;
      case "VIDEO":
        return video_b_2;
      case "YOUTUBE":
        return video_b_2;
      case "VIMEO":
        return video_b_2;
      case "AUDIO":
        return audio_b;
      case "EBOOK4":
        return ebook_b;
      case "INTERACTIVE":
        return interactive_b;
      case "LEARNOSITY":
        return html_b;
      case "IMAGE":
        return image_b;
      case "OTHER":
        const checkOtherTypeB = file_type.split(".");

        switch (checkOtherTypeB.pop()) {
          case "zip":
            return zip_b;
          default:
            return default_b;
        }
      default:
        return html_b;
    }
};

export default ResourceIconMapping;
