import * as types from "./types";
const initState = {
  layout: "dashboard", // dashboard / class / activity
  info: null,
};
const CommonNavBarReducer = (state = initState, action) => {
  console.log("[CommonNavBarReducer]", state, action);
  switch (action.type) {
    case types.CommonNavBarReducer.SET_LAYOUT:
      return {
        ...state,
        layout: action.layout,
        info: action.info,
      };
    // case types.CommonNavBarReducer.SET_INFO:
    //   return {
    //     ...state,
    //     info: action.info,
    //   };
    default:
      return state;
  }
};

export default CommonNavBarReducer;
