/**
 * @generated SignedSource<<99ecb30bca818abf12be1c1064fc2750>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AdoptedBookSeriesInfoQuery$variables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
|};
export type AdoptedBookSeriesInfoQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +IngestionId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesName: ?string,
        +Lang: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
      |}>,
    |}>,
  |},
|};
export type AdoptedBookSeriesInfoQuery = {|
  variables: AdoptedBookSeriesInfoQuery$variables,
  response: AdoptedBookSeriesInfoQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdoptedBookSeriesInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdoptedBookSeriesInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d7c18f149b6a1a9b4e2e438ac58bc1b",
    "id": null,
    "metadata": {},
    "name": "AdoptedBookSeriesInfoQuery",
    "operationKind": "query",
    "text": "query AdoptedBookSeriesInfoQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesId\n      IngestionId\n      BookSeriesName {\n        BookSeriesName\n        Lang\n      }\n      Books(BookId: $BookId) {\n        BookName {\n          Lang\n          BookName\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d59197f1e3cc9f2ae98cd4f63aae7fea";

module.exports = ((node/*: any*/)/*: Query<
  AdoptedBookSeriesInfoQuery$variables,
  AdoptedBookSeriesInfoQuery$data,
>*/);
