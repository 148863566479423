/**
 * @generated SignedSource<<185916e3b3e2e004eebe74e7d8b8cf2b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type MigrateStandaloneAttemptHistoryMutation$variables = {|
  StandaloneAttemptSessionId: string,
  ResourceIngestionId: string,
|};
export type MigrateStandaloneAttemptHistoryMutation$data = {|
  +migrateStandaloneAttemptHistory: ?boolean,
|};
export type MigrateStandaloneAttemptHistoryMutation = {|
  variables: MigrateStandaloneAttemptHistoryMutation$variables,
  response: MigrateStandaloneAttemptHistoryMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceIngestionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StandaloneAttemptSessionId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceIngestionId",
        "variableName": "ResourceIngestionId"
      },
      {
        "kind": "Variable",
        "name": "StandaloneAttemptSessionId",
        "variableName": "StandaloneAttemptSessionId"
      }
    ],
    "kind": "ScalarField",
    "name": "migrateStandaloneAttemptHistory",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MigrateStandaloneAttemptHistoryMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MigrateStandaloneAttemptHistoryMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "598af65a8b24dae8f6d1e4529c2ee512",
    "id": null,
    "metadata": {},
    "name": "MigrateStandaloneAttemptHistoryMutation",
    "operationKind": "mutation",
    "text": "mutation MigrateStandaloneAttemptHistoryMutation(\n  $StandaloneAttemptSessionId: String!\n  $ResourceIngestionId: String!\n) {\n  migrateStandaloneAttemptHistory(StandaloneAttemptSessionId: $StandaloneAttemptSessionId, ResourceIngestionId: $ResourceIngestionId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "4c6ce02907fb4af7bf94295557c407c8";

module.exports = ((node/*: any*/)/*: Mutation<
  MigrateStandaloneAttemptHistoryMutation$variables,
  MigrateStandaloneAttemptHistoryMutation$data,
>*/);
