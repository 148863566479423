/**
 * @generated SignedSource<<62cb029853d82f37ba8ecc46a2cf99fb>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type MyResourceReportQuery$variables = {|
  UserId: string,
  PearsonLevelId: string,
|};
export type MyResourceReportQuery$data = {|
  +user: ?{|
    +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
      +ForReport: ?string,
      +id: ?string,
      +DisplaySequence: ?string,
      +HierarchyReference: ?string,
      +HierarchyReferenceLabelEn: ?string,
      +ReferenceLevelScore: ?$ReadOnlyArray<?{|
        +id: ?string,
        +HierarchyReference: ?string,
        +MyScore: ?string,
        +MyMaxScore: ?string,
        +AllScore: ?string,
        +AllMaxScore: ?string,
        +PearsonLevelId: ?string,
        +LastUpdateDate: ?string,
        +TagType: ?string,
        +TagName: ?string,
      |}>,
      +RelatedTagType: ?$ReadOnlyArray<?{|
        +id: ?string,
        +TagType: ?string,
        +EnterDate: ?string,
        +Tags: ?$ReadOnlyArray<?{|
          +id: ?string,
          +DisplaySequence: ?string,
          +TagName: ?string,
          +TagLongName: ?string,
          +TagDisplayNameEn: ?string,
          +TagDisplayNameChi: ?string,
        |}>,
        +ParentTagType: ?{|
          +TagType: ?string,
        |},
      |}>,
    |}>,
  |},
|};
export type MyResourceReportQuery = {|
  variables: MyResourceReportQuery$variables,
  response: MyResourceReportQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "PearsonLevelId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ForReport",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReference",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelEn",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "PearsonLevelId",
      "variableName": "PearsonLevelId"
    }
  ],
  "concreteType": "LearnosityTagLevelScore",
  "kind": "LinkedField",
  "name": "ReferenceLevelScore",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "MyScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "MyMaxScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AllScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AllMaxScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "PearsonLevelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LastUpdateDate",
      "storageKey": null
    },
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EnterDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnosityTagHierarchyTagType",
  "kind": "LinkedField",
  "name": "Tags",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "TagLongName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "TagDisplayNameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "TagDisplayNameChi",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyResourceReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchy",
            "kind": "LinkedField",
            "name": "AdoptedTagHierarchy",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagTypeType",
                "kind": "LinkedField",
                "name": "RelatedTagType",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchyTagTypeType",
                    "kind": "LinkedField",
                    "name": "ParentTagType",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyResourceReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchy",
            "kind": "LinkedField",
            "name": "AdoptedTagHierarchy",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagTypeType",
                "kind": "LinkedField",
                "name": "RelatedTagType",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchyTagTypeType",
                    "kind": "LinkedField",
                    "name": "ParentTagType",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "393c56b7642d90d4176d220f56bdfd2a",
    "id": null,
    "metadata": {},
    "name": "MyResourceReportQuery",
    "operationKind": "query",
    "text": "query MyResourceReportQuery(\n  $UserId: String!\n  $PearsonLevelId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedTagHierarchy {\n      ForReport\n      id\n      DisplaySequence\n      HierarchyReference\n      HierarchyReferenceLabelEn\n      ReferenceLevelScore(PearsonLevelId: $PearsonLevelId) {\n        id\n        HierarchyReference\n        MyScore\n        MyMaxScore\n        AllScore\n        AllMaxScore\n        PearsonLevelId\n        LastUpdateDate\n        TagType\n        TagName\n      }\n      RelatedTagType {\n        id\n        TagType\n        EnterDate\n        Tags {\n          id\n          DisplaySequence\n          TagName\n          TagLongName\n          TagDisplayNameEn\n          TagDisplayNameChi\n        }\n        ParentTagType {\n          TagType\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f37387a96728a39642f6f354b697f00c";

module.exports = ((node/*: any*/)/*: Query<
  MyResourceReportQuery$variables,
  MyResourceReportQuery$data,
>*/);
