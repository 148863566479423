import * as types from "./types";
const initState = {
  resCenterIsReload: false,
};
const ResCenterListReloadReducer = (state = initState, action) => {
  console.log("[ResCenterListReloadReducer]", state, action);
  switch (action.type) {
    case types.ResCenterListReloadReducer.SET_RESCENTER_RELOAD:
      return {
        ...state,
        resCenterIsReload: action.resCenterIsReload,
      };
    default:
      return state;
  }
};

export default ResCenterListReloadReducer;
