/**
 * @generated SignedSource<<ce8b12b956d5afa4d3b2d4859dcba98c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type OnBoardingGetSchoolAdoptionQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type OnBoardingGetSchoolAdoptionQuery$data = {|
  +user: ?{|
    +School: ?{|
      +SchoolAdoptedBookSeries: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +LeapBookSeriesId: ?string,
        +IngestionId: ?string,
        +BookSeriesName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookSeriesName: ?string,
        |}>,
        +DisplaySequence: ?number,
      |}>,
    |},
  |},
|};
export type OnBoardingGetSchoolAdoptionQuery = {|
  variables: OnBoardingGetSchoolAdoptionQuery$variables,
  response: OnBoardingGetSchoolAdoptionQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LeapBookSeriesId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnBoardingGetSchoolAdoptionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "bookSeries",
                "kind": "LinkedField",
                "name": "SchoolAdoptedBookSeries",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OnBoardingGetSchoolAdoptionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "bookSeries",
                "kind": "LinkedField",
                "name": "SchoolAdoptedBookSeries",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ec143a451c94969d1df6f3fa15d658f",
    "id": null,
    "metadata": {},
    "name": "OnBoardingGetSchoolAdoptionQuery",
    "operationKind": "query",
    "text": "query OnBoardingGetSchoolAdoptionQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    School {\n      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {\n        BookSeriesId\n        LeapBookSeriesId\n        IngestionId\n        BookSeriesName {\n          Lang\n          BookSeriesName\n        }\n        DisplaySequence\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "3e4580ff6f9b14ae179534cdee192159";

module.exports = ((node/*: any*/)/*: Query<
  OnBoardingGetSchoolAdoptionQuery$variables,
  OnBoardingGetSchoolAdoptionQuery$data,
>*/);
