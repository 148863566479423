import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../Components/Loading/PEC/Loading";
import NotificationDetails from "./NotificationDetails";
import { bookseries_favorites } from "../../../Actions";
import GetNotificationData from "../../../Components/Feature/Function/GetNotificationData";
import ReadResourceSharingMutation from "../../../Services/api/GraphQL/Mutation/ReadResourceSharingMutation";

import moment from "moment";
import NotificationPopUp from "./NotificationPopUp";
const NotificationDropDown = (props) => {
  const dispatch = useDispatch();
  const [noticationLoading, setNoticationLoading] = useState(false);
  const {
    selectClass,
    studentClassId,
    t,
    studyGroupList,
    BookId,
    notificationPopupOverlay,
    setNotificationPopupOverlay,
  } = props;
  const [selectGroupClass, setSelectGroupClass] = useState(
    selectClass.StudyGroupName
  );
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const [notificationsDetails, setNotificationsDetails] = useState([]);
  const [notificationsCount, setnotificationsCount] = useState(0);
  const [showNotificatioPopUp, setShowNotificatioPopUp] = useState(false);
  const [notificationTitle, setnotificationTitle] = useState("");
  const [notificationResourceInfo, setnotificationResourceInfo] = useState({});
  const [deleteupdate, setdeleteupdate] = useState(false);
  //const [selectClassNotify, setselectClassNotify] = useState("");
  useEffect(() => {
    //bookLevelId = studyGroupList[0].StudyGroupId;
    //var bookLevelId = "";
    if (studentClassId != "") {
      handleAssignmentList(parseInt(studentClassId), parseInt(BookId));
    }

    //   handleAssignmentList(27729, 701);
  }, [studentClassId]); //selectClass

  // notification details starts here
  // BookId:"780", StudyGroupId: "27689"
  const handleAssignmentList = (StudyGroupId, BookId) => {
    setNoticationLoading(true);

    var variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      StudyGroupId: StudyGroupId,
      BookId: BookId,
      OrderBy: "LAST_MODIFY_DATE",
      PageNo: 1,
      PageSize: 10,
    };
    GetNotificationData(variables, props.role).then((data) => {
      // dispatch({
      //   type: "SET_NOTIFICATION_LIST",
      //   handbookData:
      //     data.user[props.role == "S" ? "NotificationList" : "NotificationList"]
      //       .NotificationList || [],
      // });
      var notifyInfo = data.user.NotificationList.TotalNumberOfRecord;

      setnotificationsCount(notifyInfo);
      setNotificationsDetails(data.user.NotificationList.NotificationList);
      setNoticationLoading(false);
    });
  };
  // notification details ends here
  const renderBody = (targets) => {
    if (targets.AssignmentOrSharing == "Sharing") {
      return (
        <div className="favourite-notification-main"
        onClick={() => {
          toggleNotificationPopUp(
            "yes",
            targets.SharingObj.RelatedResourcesConfig[0].Resource
              .ResourceLangs[
              targets.SharingObj.RelatedResourcesConfig[0].Resource
                .ResourceLangs[0].Lang == lang
                ? 0
                : 1
            ].ResourceName,
            targets.SharingObj
          );
        }}>
          <label
            className="favourite-notification-title"
            // onClick={() => {
            //   toggleNotificationPopUp(
            //     "yes",
            //     targets.SharingObj.RelatedResourcesConfig[0].Resource
            //       .ResourceLangs[
            //       targets.SharingObj.RelatedResourcesConfig[0].Resource
            //         .ResourceLangs[0].Lang == lang
            //         ? 0
            //         : 1
            //     ].ResourceName,
            //     targets.SharingObj
            //   );
            // }}
          >
            {/* {props.intro} */}
            {targets.SharingObj.RelatedResourcesConfig[0].Resource
              .ResourceLangs[
              targets.SharingObj.RelatedResourcesConfig[0].Resource
                .ResourceLangs[0].Lang == lang
                ? 0
                : 1
            ].ResourceName + " - "}
          </label>
          <label className="favourite-notification-details">
            {
              targets.SharingObj.EnterByUser.UserDisplayName[
                targets.SharingObj.EnterByUser.UserDisplayName[0].Lang == lang
                  ? 0
                  : 1
              ].UserName
            }
            &nbsp;&nbsp;
            {moment(targets.SharingObj.SharingDate).format("YYYY-MM-DD")}
          </label>

          <span className="favourite-notification-lines"></span>
        </div>
      );
    }
  };
  const updateReadNotification = (dataArg) => {
    const callback = (result) => {
      console.log("Read",result)
      var outRes = JSON.parse(result.readResourceSharing)
      dispatch({
        type: "UPDATE_NOTIFICATION_COUNT",
        count: parseInt(outRes.unreadCount),
      });
     // setnotificationCountLocal(parseInt(outRes.unreadCount));
     console.log("ss",outRes.unreadCount) ;
    };
    //props.toggleNotificationPopUp("delete");
    //refreshhandbook(HandbookPopupFlag);
    ReadResourceSharingMutation(dataArg, callback);
  };
  const toggleNotificationPopUp = (val, msg, resourceObj) => {
    if (val == "yes") {
      
        var variables = {
          SharingId: resourceObj.SharingId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
        };
        updateReadNotification(variables)
      



      
      setnotificationTitle(msg);
      setnotificationResourceInfo(resourceObj);
      setShowNotificatioPopUp(true);
      setNotificationPopupOverlay(true);
    } else {
      setShowNotificatioPopUp(false);
      setNotificationPopupOverlay(false);
      setdeleteupdate(true);
      //test
    }
  };
  return (
    <>
      {showNotificatioPopUp && (
        <NotificationPopUp
            {...props}
          toggleNotificationPopUp={toggleNotificationPopUp}
          selectClass={selectClass}
          notificationTitle={notificationTitle}
          notificationResourceInfo={notificationResourceInfo}
          t={t}
          showNotificatioPopUp={showNotificatioPopUp}
          setShowNotificatioPopUp={setShowNotificatioPopUp}
          notificationPopupOverlay={notificationPopupOverlay}
          setNotificationPopupOverlay={setNotificationPopupOverlay}
        />
      )}
      <div className="favourite-book-notification">
        <div className="notification-area">
          <label className="notification-label-name">
            {/* {t("View notifications for")} */}
            {t("Notifications")}
          </label>
        </div>
      </div>
      <div className="favourite-book-notification-details favourite-book-notification-details-scroll-bar">
        <div className="single-favourite-notification">
          {/* <NotificationDetails
            toggleNotificationPopUp={toggleNotificationPopUp}
            favBookData={favBookData}
            activeMenu={props.activeMenu}
            BookId={favBookData.BookId}
            favBookSeriesData={favBookSeriesData}
            role={props.role}
            noticationLoading={noticationLoading}
            setNoticationLoading={setNoticationLoading}
            selectClass={props.selectClass}
          /> */}
          {noticationLoading ? (
            <Loading type={`normal`} />
          ) : notificationsDetails ? (
            notificationsDetails.map((el) => {
              return <> {renderBody(el)}</>;
            })
          ) : (
            <p className="no-notification-message">
              {/* {t("There is no notification")} */}
              {t("No notifications")}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationDropDown;
