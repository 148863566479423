import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation EditAssignmentMutation(
    $ResourceAssignId: String!
    $AssignmentName: String
    $AssignmentDescription: String
    $DueDate: String
    $AllowRedo: Boolean
  ) {
    editAssignment(
      ResourceAssignId: $ResourceAssignId
      AssignmentName: $AssignmentName
      AssignmentDescription: $AssignmentDescription
      DueDate: $DueDate
      AllowRedo: $AllowRedo
    )
  }
`;
export default function EditAssignmentMutation(data, action) {
  const variables = {
    ResourceAssignId: data.ResourceAssignId,
    AssignmentName: data.AssignmentName,
    AssignmentDescription: data.AssignmentDescription,
    DueDate: data.DueDate,
    AllowRedo: data.AllowRedo ? data.AllowRedo : null,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
