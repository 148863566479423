import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import environment from "../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import ResultList from "./ResultList";
import SingleResult from "./SingleResultB2C";
import banner from "../../../../../assets/img/bird.svg";
import monent from "moment";
import Loading from "../../../../../Components/Loading/Loading";
import StudentAttemptyHistory from "../../../../../Services/api/GraphQL/Query/StudentAttemptyHistory";
import "./index.scss";
import { activityLayoutTab } from "../../../../../Services/Common/common";
const ResultAndAnswerSelfLearnAttemptHistoryQuery = graphql`
  query ResultAndAnswerSelfLearnAttemptHistoryQuery(
    $UserId: String!
    $ResourceId: String!
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(ResourceId: $ResourceId) {
        ResourceId
        IsCompleted
        AttemptSessionId
        AttemptDate
        UserId
      }
    }
  }
`;

const index = (props) => {
  const {
    resourceData,
    bookData,
    match,
    t,
    B2C,
    activity,
    inappData,
    isInappBrowser,
    // standalone,
  } = props;
  // const params = props.standalone ? match.params.resId.split("&") : null;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [loading, setloading] = useState(true);
  const [selectedHistory, setselectedHistory] = useState(null);
  const [selectedValueId, setselectedValueId] = useState(null);
  const [SelfLearnAttemptHistoryArray, setSelfLearnAttemptHistoryArray] =
    useState(null);

  useEffect(() => {
    activityLayoutTab({ disable: true, isInappBrowser });
    getSelfLearnAttemptHistory();
  }, []);

  const onClickHistory = (item, id) => {
    var bookTemp = "NoData";
if(!activity){
  bookTemp= bookData.Books[0].BookName[0].BookName;
}
    gaTrack(
      "send",
      "event",
      "Assessment",
      "Change result attempt",
      "Attempt:" +
        item.AttemptDate +
        ", Assessment:" +
        resourceData.ResourceLangs[0].ResourceName +
        ", Resource ID:" +
        resourceData.IngestionId +
        ", Level:NoData" +
        ", Book Series:" +
        bookData.BookSeriesName[0].BookSeriesName +
        ", Book:" +
        bookTemp +
        ", Role:" +
        userType +
        ", From:Supplementary"
    );
    activityLayoutTab({ disable: true, isInappBrowser });
    console.log(item, id);
    setselectedHistory(item);
    setselectedValueId(id);
  };

  const getSelfLearnAttemptHistory = async () => {
    // if (standalone) {
    //   var variables;
    //   if (activity) {
    //     // resId = props.match.params.resId;
    //     variables = {
    //       ResourceId: props.match.params.resId,
    //       UserId: userData?.UserId,
    //       IncludeAssignmentRecord: !B2C ? true : false,
    //       Lang: lang == "en-US" ? "EN" : "TC",
    //     };
    //   } else {
    //     variables = {
    //       UserId: userData.UserId,
    //       ResourceAssignId: params[2],
    //       UserId: userData?.UserId,
    //       // IncludeAssignmentRecord: !B2C ? true : false,
    //       Lang: lang == "en-US" ? "EN" : "TC",
    //     };
    //   }
    //   // variables = {
    //   //   UserId: userData.UserId,
    //   //   ResourceAssignId: params[2],
    //   //   Lang: lang == "en-US" ? "EN" : "TC",
    //   // };
    //   StudentAttemptyHistory(variables, activity ? false : true).then(
    //     (data) => {
    //       console.log(data);
    //       let targetRes;
    //       let _Resources;
    //       if (activity) {
    //         const {
    //           user: { SelfLearnAttemptHistory = [{}] },
    //         } = data || {};
    //         _Resources = SelfLearnAttemptHistory;
    //       } else {
    //         const {
    //           resourceAssignment: { Resources = [{}] },
    //         } = data || {};
    //         _Resources = Resources;
    //       }
    //       targetRes = _Resources.filter((o, i) => {
    //         return o.ResourceAssignResId == params[1];
    //       });
    //       console.log(targetRes);
    //       if (targetRes.length == 0) {
    //         if (!isInappBrowser) {
    //           window.document.getElementById("studygroup_back") &&
    //             window.document
    //               .getElementById("studygroup_back")
    //               ?.classList.remove("dim-button");
    //         }
    //         window.document.getElementById("assessment_tab") &&
    //           window.document
    //             .getElementById("assessment_tab")
    //             ?.classList.remove("dim-button");
    //         window.document.getElementById("result_tab") &&
    //           window.document
    //             .getElementById("result_tab")
    //             ?.classList.remove("dim-button");
    //         window.document.getElementById("as_tab") &&
    //           window.document
    //             .getElementById("as_tab")
    //             ?.classList.remove("dim-button");
    //       }
    //       setselectedHistory(
    //         activity ? targetRes[0] : targetRes[0].LearnosityAttemptHistory[0]
    //       );
    //       setselectedValueId(0);
    //       setSelfLearnAttemptHistoryArray(
    //         activity ? targetRes : targetRes[0].LearnosityAttemptHistory
    //       );
    //       setloading(false);
    //     }
    //   );
    // } else {
    var variables;
    if (!isInappBrowser) {
      variables = {
        UserId: userData?.UserId,
        ResourceId: match.params.resId,
        Lang: lang == "en-US" ? "EN" : "TC",
      };
    } else {
      variables = {
        UserId: userData?.UserId,
        ResourceId: inappData.resourceId,
        Lang: lang == "en-US" ? "EN" : "TC",
      };
    }
    fetchQuery(
      environment,
      ResultAndAnswerSelfLearnAttemptHistoryQuery,
      variables
    ).then((data) => {
      let listArray = [...data.user.SelfLearnAttemptHistory];
      console.log("[getSelfLearnAttemptHistory]", listArray);
      if (listArray.length > 0) {
        listArray.sort(function (a, b) {
          return new monent(a.AttemptDate) - new monent(b.AttemptDate);
        });

        onClickHistory(listArray[listArray.length - 1], listArray.length - 1);
      } else {
        activityLayoutTab({ disable: false, isInappBrowser });
      }

      setSelfLearnAttemptHistoryArray(listArray);
      setloading(false);
    });
    // }
  };
  if (loading) return <Loading />;

  if (SelfLearnAttemptHistoryArray.length > 0) {
    return (
      <div className="result-tab" id="result-tag">
        <div className="report-box">
          <ResultList
            {...props}
            HistoryArray={SelfLearnAttemptHistoryArray}
            onClickHistory={onClickHistory}
            selectedValueId={selectedValueId}
          />

          <SingleResult
            {...props}
            onClickHistory={onClickHistory}
            inappData={inappData}
            selectedHistory={selectedHistory}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="result-tab">
        <div className="no-history">
          <div className="model-answer-box-no-permission">
            <div className="model-answer-image">
              <img src={banner} />
            </div>
            <div className="model-answer-text">
              <h3>{t("Please complete the assessment first.​")}</h3>
              {/* <h5>
                {t(
                  "Your result can be reviewed after you have finished the assessment."
                )}
              </h5> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default index;
