import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetPrizmPecDataQuery = graphql`
  query GetPrizmPecDataQuery($SessionId: String!) {
    learnositySelfLearnResult(SessionId: $SessionId) {
      LearnositySessionRawResult
    }
  }
`;

const GetPrizmPecData = (variables) => {
  return fetchQuery(environment, GetPrizmPecDataQuery, variables);
};

export default GetPrizmPecData;
