/**
 * @generated SignedSource<<38c2751a2275f8fdd6336fb7414cf571>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type MyResourceScoreQuery$variables = {|
  UserId: string,
  PearsonLevelId: string,
|};
export type MyResourceScoreQuery$data = {|
  +user: ?{|
    +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
      +ForReport: ?string,
      +id: ?string,
      +DisplaySequence: ?string,
      +HierarchyReference: ?string,
      +HierarchyReferenceLabelEn: ?string,
      +AdoptedPearsonLevel: ?$ReadOnlyArray<?{|
        +id: ?string,
        +PearsonLevelId: ?string,
        +LevelName: ?$ReadOnlyArray<?{|
          +LevelName: ?string,
          +PearsonLevelId: ?string,
        |}>,
      |}>,
      +ReferenceLevelScore: ?$ReadOnlyArray<?{|
        +id: ?string,
        +HierarchyReference: ?string,
        +MyScore: ?string,
        +MyMaxScore: ?string,
        +AllScore: ?string,
        +AllMaxScore: ?string,
        +PearsonLevelId: ?string,
        +LastUpdateDate: ?string,
        +TagType: ?string,
        +TagName: ?string,
      |}>,
    |}>,
  |},
|};
export type MyResourceScoreQuery = {|
  variables: MyResourceScoreQuery$variables,
  response: MyResourceScoreQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "PearsonLevelId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReference",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PearsonLevelId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnosityTagHierarchy",
  "kind": "LinkedField",
  "name": "AdoptedTagHierarchy",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ForReport",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "DisplaySequence",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "HierarchyReferenceLabelEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "pearsonLevel",
      "kind": "LinkedField",
      "name": "AdoptedPearsonLevel",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "pearsonLevelLang",
          "kind": "LinkedField",
          "name": "LevelName",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "LevelName",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "PearsonLevelId",
          "variableName": "PearsonLevelId"
        }
      ],
      "concreteType": "LearnosityTagLevelScore",
      "kind": "LinkedField",
      "name": "ReferenceLevelScore",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "MyScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "MyMaxScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "AllScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "AllMaxScore",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "LastUpdateDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "TagType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "TagName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyResourceScoreQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyResourceScoreQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d369be1e8188d3a62d690f94461f1ed5",
    "id": null,
    "metadata": {},
    "name": "MyResourceScoreQuery",
    "operationKind": "query",
    "text": "query MyResourceScoreQuery(\n  $UserId: String!\n  $PearsonLevelId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedTagHierarchy {\n      ForReport\n      id\n      DisplaySequence\n      HierarchyReference\n      HierarchyReferenceLabelEn\n      AdoptedPearsonLevel {\n        id\n        PearsonLevelId\n        LevelName {\n          LevelName\n          PearsonLevelId\n        }\n      }\n      ReferenceLevelScore(PearsonLevelId: $PearsonLevelId) {\n        id\n        HierarchyReference\n        MyScore\n        MyMaxScore\n        AllScore\n        AllMaxScore\n        PearsonLevelId\n        LastUpdateDate\n        TagType\n        TagName\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5145bfd3dde0ecf61ed3b4e08770eb58";

module.exports = ((node/*: any*/)/*: Query<
  MyResourceScoreQuery$variables,
  MyResourceScoreQuery$data,
>*/);
