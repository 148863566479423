import { commitMutation, graphql } from "react-relay";
import environment from "../../../../../Environment";

const mutation = graphql`
  mutation CompleteSelfLearnMutation(
    $ResourceId: String!
    $SessionId: String!
    $Lang: LangEnumType
    $StudyGroupId: String
    $SchoolYearId: String!
  ) {
    completeSelfLearn(
      ResourceId: $ResourceId
      SessionId: $SessionId
      Lang: $Lang
      StudyGroupId: $StudyGroupId
      SchoolYearId: $SchoolYearId
    )
  }
`;

export default function CompleteSelfLearnMutation(values, action, B2C) {
  let variables = {
    ResourceId: values.ResourceId,
    SessionId: values.SessionId,
    Lang: values.Lang,
    SchoolYearId: values.SchoolYearId,
    StudyGroupId: values.StudyGroupId ? values.StudyGroupId : null,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
