import * as types from "./types";
const initState = {
  entryPoints: "selfResource",
  selectOptions: {
    selectedBookId: "",
    selectedBookName: "",
    selectedBookSeriesName: "",
    selectedBookSeriesId: "",
    selectedClassLevelId:"",
    selectedClassLevelName:""
    },
 
};

const EntryOfResourceReducer = (state = initState, action) => {
  let _isEmtyRecords = true;
  console.log("[EntryOfResourceReducer]", state, action);
  switch (action.type) {
    case types.EntryOfResourceReducer.SET_ENTRY_RESOURCE:
      return {
        ...state,
        entryPoints: action.entryPoints,
      };
    case types.EntryOfResourceReducer.SET_SELECTED_BOOK:
      const { selectOptions } = action;

      if (
        selectOptions.selectedBookId !== "" ||
        selectOptions.selectedBookName !== "" ||
        selectOptions.selectedBookSeriesName !== "" ||
        selectOptions.selectedBookSeriesId !== "" ||
        selectOptions.selectedClassLevelId!== "" || 
        selectOptions.selectedClassLevelName!==""
      ) {
        _isEmtyRecords = false;
      }

      return {
        ...state,
        isEmtyRecords: _isEmtyRecords,
        selectOptions: action.selectOptions,
      };
    
    default:
      return state;
  }
};

export default EntryOfResourceReducer;
