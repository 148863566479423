import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const MyResourcesQuery = graphql`
  query MyResourcesQuery($UserId: String!) {
    user(UserId: $UserId) {
      UserId
      AdoptedTagHierarchy {
        ForReport
        id
        DisplaySequence
        HierarchyReference
        HierarchyReferenceLabelEn
        HierarchyReferenceLabelChi
        AdoptedPearsonLevel {
          id
          PearsonLevelId
          TransferSequence
          LevelName {
            LevelName
            PearsonLevelId
          }
        }
      }
      AdoptedBookSeries {
        IsB2CBookSeries
        DisplaySequence
        BookSeriesId
        BookSeriesName {
          Lang
          BookSeriesName
        }
        BookSeriesColorTone
        BookSeriesImage {
          id
          FileName
          FilePath
          FileId
        }
        BookSeriesBGImage {
          id
          FileId
          FilePath
          FileName
        }
        Books {
          DisplaySequence
          BookId
          AdoptedTagHierarchy {
            id
          }
          BookName {
            Lang
            BookName
          }
          BookImage {
            FileId
            FilePath
            FileName
            id
          }
          Units {
            UnitName {
              Lang
              UnitName
              UnitTitle
            }
            MasterTeachingPlanResourcesWithFilter(ResourceType: LEARNOSITY) {
              DisplaySequence
              Resource {
                ResourceId
                ResourceType
                RelatedId
              }
            }
          }
        }
      }
    }
  }
`;

const MyResources = (variables) => {
  return fetchQuery(environment, MyResourcesQuery, variables);
};

export default MyResources;
