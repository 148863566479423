import * as types from "./types";
const initState = {
  handbookData: [],
  TotalNumberOfRecord: 0,
};
const HandbookReducer = (state = initState, action) => {
  console.log("[HandbookReducer]", state, action);
  switch (action.type) {
    case types.HandbookReducer.SET_HANDBOOK:
      return {
        ...state,
        handbookData: action.handbookData,
      };
    case types.HandbookReducer.SET_HANDBOOK_TOTAL_ITEMS:
      return {
        ...state,
        TotalNumberOfRecord: action.TotalNumberOfRecord,
      };
    default:
      return state;
  }
};

export default HandbookReducer;
