import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
// import Loading from "../../../Components/Loading/Loading";
import Loading from "../../../Components/Loading/PEC/Loading";
import ActivityLayout from "../../ActivityLayout";
import { config } from "../../../Config/config";
// activity save/submit pop up file
import ActivityPopUp from "../../ClassViewLayoutPEC/Body/ActivityPopUp";
const B2BBody = (props) => {
  const { t, B2C } = props;
  // console.log("B2c", B2C);
  const dispatch = useDispatch();
  const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  //const [loading, setloading] = useState(true);
  //const [showLoader, setShowLoader] = useState(false);
  const [selectedMenuTitle, setselectedMenuTitle] = useState(null);
  const [pageInfo, setpageInfo] = useState(null);
 
  // activity pop up states
   
 
 

  // if (loading) {
  //   return <Loading />;
  // }
  // console.log(menuTabsState, "menuTabsState");
  return (
    <>
     
      
      <div className="be-container be-container-prizmcontainer">
        <div
          className={`be-content be-prizmcontent card-pb bg-color site-wrapper ${
            // props?.location?.pathname.indexOf("/result") != -1 ||
            props?.location?.pathname.indexOf("/prizm") != -1
              ? "be-content-without-shadow"
              : ""
            }`}
        >
          <div id="a" className="dashboard-body">
            <div
              id="studygroup"
              className={`card ${layout == "activity" ? (layoutInfo.B2C ? "b2c" : "b2b") : ""
                }`}
            >
               
              <div className="body">
               
                <Route
                        path="/a/studygroup/:id/assessment/:bookNunitId/:resId"
                        component={(p) => (
                          <ActivityLayout 
                            {...p} t={props.t} 
                            B2C={B2C}
                            //setShowLoader={setShowLoader}
                            
                          />
                        )}
                      />
              </div>
            </div>
          </div>
        </div>
      </div>
       
    </>
  );
};

export default B2BBody;
