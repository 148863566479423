import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const B2CAssessmentInfoQuery = graphql`
  query B2CAssessmentInfoQuery(
    $UserId: String!
    $BookSeriesId: String
    $BookId: String!
    $ResourceId: [String]
  ) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        RelatedId
        ResourceId
        IngestionId
        ResourceLangs {
          Lang
          ResourceName
        }
      }
    }
    user(UserId: $UserId) {
      UserName
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesId
        BookSeriesName {
          BookSeriesName
          Lang
          BookLabel
          UnitLabel
          ChapterLabel
        }
        IngestionId

        Books(BookId: $BookId) {
          DisplaySequence
          BookId
          BookName {
            Lang
            BookName
          }
          Units {
            UnitId
            UnitName {
              Lang
              UnitName
              UnitTitle
            }
          }
        }
      }
    }
  }
`;
const B2CAssessmentInfo = (variables) => {
  return fetchQuery(environment, B2CAssessmentInfoQuery, variables);
};

export default B2CAssessmentInfo;
