import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginUsingSSOToken from "../../../Layouts/LoginPage/LoginUsingSSOToken";
import { config } from "../../../Config/config";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import LoginHawkMutation from "../AppSSOLogin/LoginHawkMutation";
import GetAllSchoolYears from "../../Feature/Function/GetAllSchoolYears";
import GetUserData from "../../Feature/Function/GetUserData";
import moment from "moment";
import {
  isBrowser,
  isMobileOnly,
  isTablet,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
} from "react-device-detect";
import useGATracking from "../../../hooks/useGATracking";
// import { useStateValue } from "../../../StateProvider/StateProvider";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const index = (props) => {
  let query = useQuery();
  // const { dispatch } = props;
  const dispatch = useDispatch();
  const [ssoToken, setssoToken] = useState(null);
  const [loginUsingSSOComponent, setloginUsingSSOComponent] = useState(false);
  const [loginUsingSSOToken, setloginUsingSSOToken] = useState("");
  const [longmanpluslogintype, setlongmanpluslogintype] = useState(null);
  const [username, setusername] = useState(null);
  const [iamTokenLogin, setiamTokenLogin] = useState(false);
  const {sendGaEvent} = useGATracking();

  useEffect(() => {
    var platform = "WEB"; //WEB MOBILE TABLET
    if (isMobileOnly || isTablet) {
      if (isMobileOnly && window.cordova) {
        platform = "MOBILE";
      }

      if (isTablet && window.cordova) {
        platform = "TABLET";
      }
    } else {
      if (
        !window.cordova &&
        (isBrowser ||
          isChrome ||
          isFirefox ||
          isSafari ||
          isOpera ||
          isIE ||
          isEdge ||
          navigator.userAgent.match(
            /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
          ))
      ) {
        platform = "WEB";
      }
    }
    console.log("[Login with platform]", platform);
    if (props.type == "url") {
      //console.log(this.props.match.params.token.split('&'));
      const userKey = query.get("userKey"); // For userKey action
      const token = query.get("token");
      const lang = query.get("lang");
      const longmanpluslogintype = query.get("longmanpluslogintype");
      const uid = query.get("uid");
      if (lang) {
        dispatch({
          type: "SET_LANG",
          lang: lang,
        });
      }
      if (userKey) {
        console.log("[SSO] with userKey", userKey);
        const callback = (result) => {
          console.log("SSO result", result);
          if (result.loginHawk != null) {
            getUserInfo(result.loginHawk);
          } else {
            console.log("[SSO] Fail to SSO, Redirct...");
            handleFaildAppSSO();
          }
        };
        LoginHawkMutation(userKey, platform, callback);
      } else {
        console.log("[SSO] with token longmanpluslogintype uid");
        //console.log(token, longmanpluslogintype, uid, allowParams, params.length);

        if (token && !longmanpluslogintype && !uid) {
          //SSO login Pearson.com
          //console.log('SSO login Pearson.com',token);
          // this.setState({
          //   ssoToken: token,
          // });
          // this.ssoLogin(token, platform);
          setssoToken(token);
          ssoLogin(token, platform);
        } else {
          //SSO from other place

          if (longmanpluslogintype == null || uid == null) {
            //console.log(longmanpluslogintype ? '' : 'longmanpluslogintype=missing', uid ? '' : 'uid=missing');
            props.history.push("/");
          } else {
            // this.setState({
            //   ssoToken: token,
            //   loginUsingSSOToken: token,
            //   longmanpluslogintype: longmanpluslogintype,
            //   username: decodeURIComponent(uid),
            //   iamTokenLogin: true,
            //   loginUsingSSOComponent: true,
            // });
            setssoToken(token);
            setloginUsingSSOToken(token);
            setlongmanpluslogintype(longmanpluslogintype);
            setusername(uid);
            setiamTokenLogin(true);
            setloginUsingSSOComponent(true);
            LoginUsingSSOToken(
              {
                ...props,
                ssoToken: token,
                loginUsingSSOToken: token,
                platform: platform,
                loadingStyle: (n) => "",
                userName: decodeURIComponent(uid),
                dispatch: dispatch,
              },
              "iamssologin"
            );
          }
        }
      }
    }
    if (props.type == "cookies") {
      var SSOToken = Cookies.get("SSOToken");
      var uid = Cookies.get("username");
      //console.log(SSOToken, uid);
      if (!SSOToken || !uid) {
        //console.log('login page');
        //console.log(longmanpluslogintype ? '' : 'longmanpluslogintype=missing', uid ? '' : 'uid=missing');
        props.history.push("/");
      } else {
        // this.setState({
        //   ssoToken: SSOToken,
        //   loginUsingSSOToken: SSOToken,
        //   longmanpluslogintype: null,
        //   username: uid,
        //   iamTokenLogin: true,
        //   loginUsingSSOComponent: true,
        // });
        setssoToken(SSOToken);
        setloginUsingSSOToken(SSOToken);
        setlongmanpluslogintype(null);
        setusername(uid);
        setiamTokenLogin(true);
        setloginUsingSSOComponent(true);
        LoginUsingSSOToken(
          {
            ...props,
            ssoToken: SSOToken,
            loadingStyle: (n) => "",
            userName: uid,
            platform: platform,
            iamSSOLoginFrom: true,
            dispatch: dispatch,
          },
          "iamssologin"
        );
      }
    }
  }, []);

  const handleFaildAppSSO = () => {
    console.log("[AppSSO] no Auth , Redirct login");
    // localStorage.removeItem("SessionId");
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("ssoToken");
    // localStorage.removeItem("userData");
    // localStorage.removeItem("effectiveSchoolYear");
    // localStorage.removeItem("role");
    localStorage.removeItem("dp_");
    dispatch({
      type: "SET_USER",
      user: null,
    });

    return props.history.push("/");
  };
  const ssoLogin = (token, platform) => {
    window
      .fetch(config.forgerock_sso_url + token, {
        method: "GET",
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log("Error:", response);
          props.history.push("/");
        }
      })
      .then((data) => {
        //console.log(data);
        if (data) {
          // this.setState({
          //   username: data.uid,
          //   loginUsingSSOToken: data.access_token,
          //   loginUsingSSOComponent: true,
          // });
          setusername(data.uid);
          setloginUsingSSOToken(data.access_token);
          setloginUsingSSOComponent(true);
          LoginUsingSSOToken(
            {
              ...props,
              ssoToken: data.access_token,
              loadingStyle: (n) => "",
              userName: data.uid,
              platform: platform,
              dispatch: dispatch,
            },
            "ssologin"
          );
        }
      })
      .catch((error) => {
        console.log("SSO login Error:", error);
        props.history.push("/");
      });
  };

  const getUserInfo = (loginData) => {
    // props.loadingStyle && props.loadingStyle(true);
    console.log("[Start get user info]", loginData);
    localStorage.setItem("userData", JSON.stringify(loginData.User));
    localStorage.setItem("SessionId", loginData.SessionId);
    localStorage.setItem("accessToken", loginData.SAMAssessToken);
    localStorage.setItem(
      "ssoToken",
      loginData.SAMSsoToken ? loginData.SAMSsoToken : null
    );
    localStorage.setItem("__from", "l");

    var isAbleAccountType = false,
      sindex;
    // loginData.User.UserType.map((obj, index) => {
    //   if (config.userTypeList.indexOf(obj.RoleId) != -1 && !isAbleAccountType) {
    //     isAbleAccountType = true;
    //     sindex = index;
    //   }
    // });

    if (loginData.User.UserType == "T") {
      localStorage.setItem("role", "teacher");
      isAbleAccountType = true;
    } else if (loginData.User.UserType == "S") {
      localStorage.setItem("role", "student");
      isAbleAccountType = true;
    } else {
      localStorage.setItem("role", null);
    }
    if (isAbleAccountType) {
      // if (loginData.User.UserType[sindex].RoleId == "2") {
      //   localStorage.setItem("role", "teacher");
      // } else if (loginData.User.UserType[sindex].RoleId == "1") {
      //   localStorage.setItem("role", "student");
      // } else {
      //   localStorage.setItem("role", null);
      // }
    } else {
      window.alert("Login UserType Error!");
      // localStorage.removeItem("SessionId");
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("ssoToken");
      // localStorage.removeItem("userData");
      localStorage.removeItem("dp_URL");
      dispatch({
        type: "SET_USER",
        user: null,
      });
      setTimeout(() => props.history.push("/"), 100);
      return;
    }

    // console.log("[App] Have Auth");

    gaTrack("set", "dimension1", loginData.User.UserType);
    gaTrack(
      "send",
      "event",
      "Login",
      "Success",
      "Role:" + localStorage.getItem("role")
    );

    var haveEffective = false;
    GetAllSchoolYears((list) => {
      var schoolYearList = list;
      console.log(list);
      schoolYearList.map((obj, index) => {
        if (obj.IsEffective == "true") {
          haveEffective = true;
          localStorage.setItem("effectiveSchoolYear", JSON.stringify(obj));
          dispatch({
            type: "SET_SCHOOL_YEAR",
            effectiveSchoolYear: obj,
          });
        }
      });

      if (!haveEffective) {
        localStorage.setItem(
          "effectiveSchoolYear",
          JSON.stringify(schoolYearList[0])
        );
        dispatch({
          type: "SET_SCHOOL_YEAR",
          effectiveSchoolYear: schoolYearList[0],
        });
      }

      GetUserData((userData) => {
        localStorage.setItem("userData", userData);
        // localStorage.setItem("dp_", true);
        var userObj = JSON.parse(userData);
        dispatch({
          type: "SET_COSTOM_SCHOOL_YEAR",
          selectedSchoolYear:
            userObj.AdoptedSchoolYear.sort(function (a, b) {
              return (
                new moment(b.EffectiveStartDate) -
                new moment(a.EffectiveStartDate)
              );
            })[0] || schoolYearList[0],
        });
        try {
          sendGaEvent('General_Login_server', {
            "user_Device": "Web",
            "user_School": userObj?.School?.SchoolCode,
            "user_Role": userObj?.UserType,
            "user_Class": userObj?.UserType == "T" ? "" : userObj?.BelongsToStudyGroup[0]?.StudyGroup?.StudyGroupName[0]?.StudyGroupName ?? '',
            "user_Level": userObj?.UserType == "T" ? "" : userObj?.BelongsToStudyGroup[0]?.StudyGroup?.Level?.LevelName[0]?.LevelName ?? '',
          })
        } catch (error) {
          console.log('Error:', error);
        }
        dispatch({
          type: "SET_USER",
          user: userObj,
        });
        dispatch({
          type: "SET_USER_PREFERENCES",
          user_preferences: userObj.Preferences,
        });

        //check match two account for ios review
        if (
          (userObj.UserName == "tp16992702" ||
            userObj.UserName == "sp16992703") &&
          window.cordova
        ) {
          props.history.push("/m/studynotes");
        } else {
          console.log(userObj);
          if (userObj.UserType == "T") {

            var listArray = [];
            userObj.School.SchoolAdoptedBookSeries.map((obj, index) => {
              if (obj.IngestionId == null || obj.IngestionId == "") return;
              listArray.push(obj);
            });
           
            if(userObj.AdoptedSchoolYear.length>0 && listArray.length > 0){
              props.history.push("/m");
              // if (
              //   userObj.PreferencesWithYear.map((obj) => {
              //     return obj.PreferenceName;
              //   }).indexOf("ONBOARDING_SKIP") != -1
              // ) {
              //   if (
              //     userObj.PreferencesWithYear[
              //       userObj.PreferencesWithYear.map((obj) => {
              //         return obj.PreferenceName;
              //       }).indexOf("ONBOARDING_SKIP")
              //     ].PreferenceValue == "true"
              //   ) {
              //     props.history.push("/m");
              //   } else {
              //     props.history.push("/onboarding");
              //   }
              // } else {
              //       props.history.push("/onboarding");
              //  }
  
            }else{
              props.history.push("/m");
            }
          } else {
            props.history.push("/m");
          }
        }
      });
    });
  };

  return loginUsingSSOComponent && loginUsingSSOToken.length != 0
    ? iamTokenLogin
      ? "iamssologin"
      : "ssologin"
    : "Loading...";
};

export default index;
