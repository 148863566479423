/**
 * @generated SignedSource<<4bbda2b80cb130de39d721c3d452c81f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type TagInfoInputObj = {|
  Level1TagType: string,
  Level1TagName: string,
  Level2TagType: string,
  Level2TagName: string,
|};
export type B2CAssessmentListQuery$variables = {|
  BookId: string,
  HierarchyReference: string,
  TagInfoInputObj?: ?TagInfoInputObj,
|};
export type B2CAssessmentListQuery$data = {|
  +learnosityTagHierarchyStructure: ?$ReadOnlyArray<?{|
    +DisplaySequence: ?string,
    +HierarchyReferenceLabelEn: ?string,
    +HierarchyReferenceLabelChi: ?string,
    +HierarchyReference: ?string,
    +RelatedTagType: ?$ReadOnlyArray<?{|
      +Tags: ?$ReadOnlyArray<?{|
        +TopicPreStudyResources: ?{|
          +TotalNumberOfRecord: ?number,
          +Resources: ?$ReadOnlyArray<?{|
            +IngestionId: ?string,
            +ResourceId: ?string,
            +ResourceLangs: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +ResourceName: ?string,
            |}>,
            +ResourceType: ?string,
            +Linkage: ?string,
            +ResourceAccessUrl: ?string,
            +FileSource: ?string,
          |}>,
        |},
      |}>,
    |}>,
    +AdoptedLearnosityResource: ?$ReadOnlyArray<?{|
      +LearnosityReferenceId: ?string,
      +Level1TagType: ?string,
      +Level1TagName: ?string,
      +Level2TagType: ?string,
      +Level2TagName: ?string,
      +RelatedResource: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +ResourceLangs: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +ResourceName: ?string,
        |}>,
        +IngestionId: ?string,
        +LearnosityTagHierarchyRef: ?string,
        +LearnositySuggestionMapping: ?$ReadOnlyArray<?{|
          +MappedResources: ?$ReadOnlyArray<?{|
            +Resource: ?{|
              +DisplaySequence: ?string,
              +IngestionId: ?string,
              +ResourceId: ?string,
              +ResourceLangs: ?$ReadOnlyArray<?{|
                +Lang: ?string,
                +ResourceName: ?string,
              |}>,
              +FileSource: ?string,
              +RelatedId: ?string,
              +ResourceType: ?string,
              +Linkage: ?string,
              +ResourceAccessUrl: ?string,
            |},
          |}>,
        |}>,
      |}>,
    |}>,
  |}>,
|};
export type B2CAssessmentListQuery = {|
  variables: B2CAssessmentListQuery$variables,
  response: B2CAssessmentListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "BookId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "HierarchyReference"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "TagInfoInputObj"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "HierarchyReference",
    "variableName": "HierarchyReference"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelEn",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelChi",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReference",
  "storageKey": null
},
v6 = {
  "kind": "Variable",
  "name": "BookId",
  "variableName": "BookId"
},
v7 = [
  (v6/*: any*/)
],
v8 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "Order",
    "value": "ASC"
  },
  {
    "kind": "Literal",
    "name": "PageSize",
    "value": 999
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TotalNumberOfRecord",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Linkage",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileSource",
  "storageKey": null
},
v17 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "TagInfo",
    "variableName": "TagInfoInputObj"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnosityReferenceId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level1TagType",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level1TagName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level2TagType",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level2TagName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnosityTagHierarchyRef",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "B2CAssessmentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "kind": "LinkedField",
        "name": "learnosityTagHierarchyStructure",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "kind": "LinkedField",
            "name": "RelatedTagType",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "LearnosityTagHierarchyTagType",
                "kind": "LinkedField",
                "name": "Tags",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "resourceSearchResult",
                    "kind": "LinkedField",
                    "name": "TopicPreStudyResources",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resource",
                        "kind": "LinkedField",
                        "name": "Resources",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v17/*: any*/),
            "concreteType": "LearnosityActivityTagInfo",
            "kind": "LinkedField",
            "name": "AdoptedLearnosityResource",
            "plural": true,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "resource",
                "kind": "LinkedField",
                "name": "RelatedResource",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v10/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMapping",
                    "kind": "LinkedField",
                    "name": "LearnositySuggestionMapping",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resourceLearnositySuggestionMappingResource",
                        "kind": "LinkedField",
                        "name": "MappedResources",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "resource",
                            "kind": "LinkedField",
                            "name": "Resource",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v16/*: any*/),
                              (v24/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "B2CAssessmentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "kind": "LinkedField",
        "name": "learnosityTagHierarchyStructure",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "kind": "LinkedField",
            "name": "RelatedTagType",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "LearnosityTagHierarchyTagType",
                "kind": "LinkedField",
                "name": "Tags",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "resourceSearchResult",
                    "kind": "LinkedField",
                    "name": "TopicPreStudyResources",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resource",
                        "kind": "LinkedField",
                        "name": "Resources",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v17/*: any*/),
            "concreteType": "LearnosityActivityTagInfo",
            "kind": "LinkedField",
            "name": "AdoptedLearnosityResource",
            "plural": true,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "resource",
                "kind": "LinkedField",
                "name": "RelatedResource",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v10/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMapping",
                    "kind": "LinkedField",
                    "name": "LearnositySuggestionMapping",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resourceLearnositySuggestionMappingResource",
                        "kind": "LinkedField",
                        "name": "MappedResources",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "resource",
                            "kind": "LinkedField",
                            "name": "Resource",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v16/*: any*/),
                              (v24/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v25/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/)
            ],
            "storageKey": null
          },
          (v25/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90e192fc218e5087491b6fe8af258897",
    "id": null,
    "metadata": {},
    "name": "B2CAssessmentListQuery",
    "operationKind": "query",
    "text": "query B2CAssessmentListQuery(\n  $BookId: String!\n  $HierarchyReference: String!\n  $TagInfoInputObj: TagInfoInputObj\n) {\n  learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {\n    DisplaySequence\n    HierarchyReferenceLabelEn\n    HierarchyReferenceLabelChi\n    HierarchyReference\n    RelatedTagType {\n      Tags(BookId: $BookId) {\n        TopicPreStudyResources(BookId: $BookId, PageSize: 999, Order: ASC) {\n          TotalNumberOfRecord\n          Resources {\n            IngestionId\n            ResourceId\n            ResourceLangs {\n              Lang\n              ResourceName\n            }\n            ResourceType\n            Linkage\n            ResourceAccessUrl\n            FileSource\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n    AdoptedLearnosityResource(BookId: $BookId, TagInfo: $TagInfoInputObj) {\n      LearnosityReferenceId\n      Level1TagType\n      Level1TagName\n      Level2TagType\n      Level2TagName\n      RelatedResource(BookId: $BookId) {\n        ResourceId\n        ResourceLangs {\n          Lang\n          ResourceName\n        }\n        IngestionId\n        LearnosityTagHierarchyRef\n        LearnositySuggestionMapping {\n          MappedResources {\n            Resource {\n              DisplaySequence\n              IngestionId\n              ResourceId\n              ResourceLangs {\n                Lang\n                ResourceName\n              }\n              FileSource\n              RelatedId\n              ResourceType\n              Linkage\n              ResourceAccessUrl\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e51ad231359f9dbb1b94a709958bc675";

module.exports = ((node/*: any*/)/*: Query<
  B2CAssessmentListQuery$variables,
  B2CAssessmentListQuery$data,
>*/);
