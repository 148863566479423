import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import SelectStudent from "./SelectStudent";
import DndAssignment from "./DndAssignment";
import CreateAssignmentMutation from "./CreateAssignmentMutation";
// import DeleteAssignment from "./DeleteAssignmentMutation";
import "./index.scss";
import "./react-datePicker.scss";
import Loading from "../../../Loading/Loading";
import successIcon from "../../../../assets/img/system/check-circle-24-px.svg";
import classes from "../../../../assets/img/class.svg";
import CircleCloseButton from "../../CircleCloseButton";
import ResourceIconMapping from "../../Function/ResourceIconMapping";
import EditAssignmentMutation from "./EditAssignmentMutation";
import { isMobile, isBrowser, isIOS } from "react-device-detect";
// import { Prompt } from "react-router-dom";
export default (props) => {
  const {
    t,
    assignmentList,
    bookSeriesName,
    studyGroupId,
    clearAssignment,
    assignStudyGroup,
    onCloseButton,
    onConfirm,
    isEdit,
    assignmentData,
  } = props;
  let assignFinal = {};
  if (!isEdit) {
    if (assignStudyGroup == null) {
      assignFinal = null;
    } else {
      assignFinal = {
        ...assignStudyGroup,
        image: classes,
      };
    }
  }
  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const dispatch = useDispatch();
  const sg_handbookData = useSelector(
    (state) => state.StudyGroupHandbookReducer.handbookData
  );
  const sg_handbook_totalCount = useSelector(
    (state) => state.StudyGroupHandbookReducer.TotalNumberOfRecord
  );

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const handbookData = useSelector(
    (state) => state.HandbookReducer.handbookData
  );
  const handbook_totalCount = useSelector(
    (state) => state.HandbookReducer.TotalNumberOfRecord
  );
  const resCenterAssignedRelatedData = useSelector(
    (state) => state.ResCenterAssignedRelatedReducer.resCenterAssignedRelated
  );
  const resCenterAssignedRelatedData_totalCount = useSelector(
    (state) => state.ResCenterAssignedRelatedReducer.TotalNumberOfRecord
  );
  // const UserType = JSON.parse(localStorage.getItem("userData")).UserType;

  const lang = localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(false);
  const [isVaildTitle, setIsVaildTitle] = useState(false);
  const [isVaildAssign, setIsVaildAssign] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formClassList, setFormClassList] = useState([assignFinal]);
  const [formMessage, setFormMessage] = useState("");
  const [formDueDateChecked, setFormDueDateChecked] = useState(false);
  const [formDueDate, setFormDueDate] = useState("");

  const [showAllowRedo, setshowAllowRedo] = useState(false);
  const [allowRedo, setallowRedo] = useState(false);

  const [formAssignmentList, setFormAssignmentList] = useState(
    isEdit ? assignmentData.Resources : assignmentList
  );
  const [isLeaving, setIsLeaving] = useState(true);
  // const [backButton, setbackButton] = useState(true);

  const inputElement = useRef(null);

  console.log(formAssignmentList);

  useEffect(() => {
    if (isEdit) {
      setFormTitle(assignmentData.AssignmentName);
      setFormMessage(assignmentData.AssignmentDescription);

      if (assignmentData.DueDate != "" && assignmentData.DueDate != null) {
        setFormDueDate(moment(assignmentData.DueDate, "YYYY-MM-DD").toDate());
        setFormDueDateChecked(true);
      }
      var LenosityRes = assignmentData.Resources.filter(
        (obj) => obj.Resource.ResourceType === "LEARNOSITY"
      );
      var lenoisty_resources_atr = LenosityRes.filter((obj, i) => {
        var count = [];
        if (obj.ResourceAssignResAttrs) {
          count = obj.ResourceAssignResAttrs.filter((attr, ai) => {
            return attr.AttrName == "AllowRedo" && attr.AttrValue == "true";
          });
        }
        return count.length > 0;
      });
      console.log(lenoisty_resources_atr);
      if (lenoisty_resources_atr.length > 0) {
        // set true
        setallowRedo(true);
      }
    }
    setshowAllowRedo(checkIsRedo());
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setFormDueDate(formDueDateChecked ? moment().add(7, "d").toDate() : "");
    } else {
      if (!formDueDateChecked) {
        setFormDueDate("");
      } else {
        if (assignmentData.DueDate == "" || assignmentData.DueDate == null) {
          setFormDueDate(moment().add(7, "d").toDate());
        } else {
          setFormDueDate(moment(assignmentData.DueDate, "YYYY-MM-DD").toDate());
        }
      }
    }
  }, [formDueDateChecked]);

  useEffect(() => {
    if (isLeaving) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, []);

  useEffect(() => {
    inputElement.current.onfocus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };
  });

  const notifyMessage = (messageString) => {
    toast(
      <div className="notify-box">
        <div className="notify-title">
          <div className="notify-icon">
            <img src={successIcon} alt="" />
          </div>
          <div className="notify-text">
            <p> {messageString}</p>
          </div>
        </div>
      </div>,
      {
        position: "top-center",
      }
    );
  };

  const removeEmojis = (string) => {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, "");
  };

  const handleSelectChange = (opt) => {
    setFormClassList(opt);
    handleSelectVaild(opt);
    console.log(opt);
  };

  const onSubmit = () => {
    console.log(
      formTitle,
      formClassList,
      formMessage,
      formDueDateChecked,
      moment(formDueDate).format("YYYY-MM-DD"),
      formAssignmentList
    );
    if (formTitle.length === 0 && formClassList.length === 0) {
      setIsVaildTitle(true);
      setIsVaildAssign(true);
      return;
    }
    if (formTitle.length === 0 && formClassList.length > 0) {
      setIsVaildTitle(true);
      return;
    }
    if (formTitle.length > 0 && formClassList.length === 0) {
      setIsVaildAssign(true);
      return;
    }
    if(formClassList[0]==null){
      setIsVaildAssign(true);
      return;
    }

    setLoading(true);
    const convertDate =
      formDueDateChecked == true
        ? moment(formDueDate).format("YYYY-MM-DD")
        : "";
    const booksSeriesId = formAssignmentList[0].BookSeriesId;
    const resourceId = formAssignmentList.map((i) => i.ResourceId);
    const userId = formClassList.filter((obj) => {
      if (obj.type == "users") {
        return obj.userId;
      }
    });
    const studyGroupId = formClassList.filter((obj) => {
      if (obj.type == "studyGroup") {
        return obj.studyGroupId;
      }
    });

    const allName = formClassList.reduce((acc, current) => {
      acc.push({
        name: current.label,
      });
      return acc;
    }, []);
    var data = {
      ResourceId: resourceId,
      BookSeriesId: booksSeriesId,
      UserId: userId.map((i) => i.userId),
      StudyGroupId: studyGroupId.map((i) => i.studyGroupId),
      AssignmentName: formTitle,
      AssignmentDescription: formMessage,
      DueDate: convertDate,
      SchoolYearId: selectedSchoolYear?.SchoolYearId,
      AllowRedo: showAllowRedo ? allowRedo : null,
    };
    console.log(data);

    const callback = (result) => {
      console.log(result);
      setLoading(false);
      if (result.createAssignment.ResultDescription == "success") {
        gaTrack(
          "send",
          "event",
          "Create assignment",
          "Submit form",
          "Book Series:" + bookSeriesName + ", Role:" + role
        );
        notifyMessage(
          t("Assign successfully. Assigned to") +
            " " +
            allName.map((i) => i.name)
        );
        if (!isEdit) {
          clearAssignment();
        }

        // setbackButton(false);

        dispatch({
          type: "SET_RESCENTER_RELOAD",
          resCenterIsReload: true,
        });

        setIsLeaving(false);
      } else {
        setFormTitle("");
        setFormClassList([]);
        setFormMessage("");
        setFormDueDate(null);
      }
    };

    CreateAssignmentMutation(data, callback);
  };

  const onEdit = () => {
    setLoading(true);
    console.log("[On Edit]", {
      assignmentData,
      formTitle,
      formMessage,
      formDueDate,
      allowRedo,
    });
    let convertDate = "";
    if (formDueDateChecked) {
      // no due date   -> add due date
      convertDate = moment(formDueDate).format("YYYY-MM-DD");
    }
    const formdata = {
      ResourceAssignId: assignmentData.ResourceAssignId,
      AssignmentName: formTitle,
      AssignmentDescription: formMessage,
      DueDate: convertDate,
      AllowRedo: showAllowRedo ? allowRedo : null,
    };
    //handle GA
    gaTrack(
      "send",
      "event",
      "Edit assignment",
      "Click save",
      "Book Series:" + bookSeriesName + ", Role:" + role
    );

    const callback = (data, error) => {
      console.log(data, error);
      if (data.editAssignment == true) {
        let check_sg_handbook = sg_handbookData
          ? sg_handbookData.filter((item, index) => {
              return item.ResourceAssignId == assignmentData.ResourceAssignId;
            })
          : [];
        let check_handbook = handbookData
          ? handbookData.filter((item, index) => {
              return item.ResourceAssignId == assignmentData.ResourceAssignId;
            })
          : [];

        let check_resCenterAssignedRelatedData = resCenterAssignedRelatedData
          ? resCenterAssignedRelatedData.filter((item, index) => {
              return (
                item.AssignmentTargets[0].ResourceAssignId ==
                assignmentData.ResourceAssignId
              );
            })
          : [];

        console.log(check_sg_handbook, check_handbook);
        if (check_sg_handbook.length != 0) {
          dispatch({
            type: "SET_STUDYGROUP_HANDBOOK_TOTAL_ITEMS",
            TotalNumberOfRecord: sg_handbook_totalCount - 1,
          });
        }
        if (check_handbook.length != 0) {
          dispatch({
            type: "SET_HANDBOOK_TOTAL_ITEMS",
            TotalNumberOfRecord: handbook_totalCount - 1,
          });
        }
        if (check_resCenterAssignedRelatedData.length != 0) {
          console.log("HIHI");
          dispatch({
            type: "SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS",
            TotalNumberOfRecord: resCenterAssignedRelatedData_totalCount - 1,
          });
        }

        setLoading(false);
        onConfirm();
        notifyMessage(t("The assignment is updated successfully."));
      } else {
        setLoading(false);
        console.log("[Edit assignment error]");
        onConfirm();
        notifyMessage(t("The assignment is updated successfully."));
      }
    };
    EditAssignmentMutation(formdata, callback);
  };

  const handleTitleVaild = (e) => {
    if (e.target.value.length === 0) {
      setIsVaildTitle(true);
    } else {
      setIsVaildTitle(false);
    }
  };

  const handleSelectVaild = (data) => {
    console.log(data, "have data");
    if (data === null || data.length === 0) {
      setIsVaildAssign(true);
    } else if (data.length > 0) {
      setIsVaildAssign(false);
    }
  };

  const renderAssignee = (targets) => {
    //for edit assignment only
    // var bookSeriesData = BookSeries;
    console.log(targets);
    var current_target_classes = targets
      .filter((item, index) => {
        return item.User == null;
      })
      .sort((a, b) => {
        return a.StudyGroup.StudyGroupName[
          a.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
        ].StudyGroupName.localeCompare(
          b.StudyGroup.StudyGroupName[
            b.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName
        );
      });
    var current_target_users = targets.filter((item, index) => {
      return item.StudyGroup == null;
    });
    var tempUsers = [],
      userObj = {};
    current_target_users.map((item, index) => {
      var matchGroup = item.User.BelongsToStudyGroup.map((i) => {
        return i.StudyGroup.BookSeries.BookSeriesId;
      }).indexOf(assignmentData.BookSeries.BookSeriesId);
      if (matchGroup != -1) {
        userObj = {
          ...item,
          UserGroup: item.User.BelongsToStudyGroup[matchGroup],
        };
        delete userObj.BelongsToStudyGroup;
      } else {
        userObj = { ...item };
      }
      tempUsers.push(userObj);
    });
    current_target_users = tempUsers;

    if (role == "T") {
      //For teacher

      return (
        <>
          <span className="notification-item-body">
            {current_target_classes.map((obj, i) => {
              if (i == 0) {
                return (
                  <Trans key={i} i18nKey="ClassTitle" count={1}>
                    {{
                      class:
                        obj.StudyGroup.StudyGroupName[
                          lang == obj.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                        ].StudyGroupName,
                    }}
                  </Trans>
                );
              } else {
                return (
                  <React.Fragment key={i}>
                    {", "}
                    <Trans i18nKey="ClassTitle" count={1} key={i}>
                      {{
                        class:
                          obj.StudyGroup.StudyGroupName[
                            lang == obj.StudyGroup.StudyGroupName[0].Lang
                              ? 0
                              : 1
                          ].StudyGroupName,
                      }}
                    </Trans>
                  </React.Fragment>
                );
              }
            })}
            {current_target_classes.length != 0 &&
              current_target_users.length != 0 &&
              ", "}
            {current_target_users.map((u, i) => {
              if (i == 0)
                return (
                  u.UserGroup.StudyGroup.StudyGroupName[
                    lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang
                      ? 0
                      : 1
                  ].StudyGroupName +
                  "-" +
                  u.UserGroup.ClassNumber +
                  " " +
                  u.User.UserDisplayName[
                    u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                  ].UserName
                );
              else
                return (
                  ", " +
                  u.UserGroup.StudyGroup.StudyGroupName[
                    lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang
                      ? 0
                      : 1
                  ].StudyGroupName +
                  "-" +
                  u.UserGroup.ClassNumber +
                  " " +
                  u.User.UserDisplayName[
                    u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                  ].UserName
                );
            })}
          </span>
        </>
      );
    }
    // return "";

    if (role == "S") {
      //For Student
      if (current_target_classes.length != 0) {
        //show class
        return (
          <Trans i18nKey="ClassTitle" count={1}>
            {{
              class:
                current_target_classes[0].StudyGroup.StudyGroupName[0]
                  .StudyGroupName,
            }}
          </Trans>
        );
      } else {
        //show user
        return (
          <>
            {current_target_users[0].UserGroup.StudyGroup.StudyGroupName[
              lang ==
              current_target_users[0].UserGroup.StudyGroup.StudyGroupName[0]
                .Lang
                ? 0
                : 1
            ].StudyGroupName +
              "-" +
              current_target_users[0].UserGroup.ClassNumber +
              " " +
              current_target_users[0].User.UserDisplayName[
                current_target_users[0].User.UserDisplayName[0].Lang == lang
                  ? 0
                  : 1
              ].UserName}
          </>
        );
      }
      // return "";
    }
  };

  const checkIsRedo = () => {
    var findLearn = [];
    if (isEdit) {
      findLearn = formAssignmentList.filter(
        (obj) => obj.Resource.ResourceType === "LEARNOSITY"
      );
    } else {
      findLearn = formAssignmentList.filter(
        (obj) => obj.ResourceType === "LEARNOSITY"
      );
    }
    // console.log(findLearn);
    if (findLearn.length === 0 || findLearn === null) {
      return false;
    } else {
      return true;
    }
  };

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  return (
    <div className="assign_form">
      {/* Title */}
      <CircleCloseButton
        onCloseButton={() => {
          onCloseButton();
          isEdit &&
            gaTrack(
              "send",
              "event",
              "Edit assignment",
              "Click close",
              "Book Series:" + bookSeriesName + ", Role:" + role
            );
        }}
        design="design_assign"
      />

      {loading == true && <Loading />}

      <div className="assign_title form-group">
        <div className="form-label">{t("Title")}</div>

        <input
          ref={inputElement}
          placeholder={t("Please enter the assignment title...")}
          type="text"
          className={
            isVaildTitle == true ? "form-control vaild_border" : "form-control"
          }
          onChange={(e) => {
            console.log(e.target.value);
            setFormTitle(removeEmojis(e.target.value)), handleTitleVaild(e);
          }}
          value={formTitle}
          maxLength="100"
        />
        {/* {isEdit && (
          <input
            ref={inputElement}
            placeholder={t("Please enter the assignment title...")}
            type="text"
            className={
              isVaildTitle == true
                ? "form-control vaild_border"
                : "form-control"
            }
            onChange={(e) => {
              console.log(e.target.value);
              setFormTitle(removeEmojis(e.target.value)), handleTitleVaild(e);
            }}
            defaultValue={formTitle}
            maxLength="100"
          />
        )} */}
        <div
          className="input_valid"
          style={{ display: isVaildTitle ? "block" : "none" }}
        >
          <span className="mdi mdi-info-outline" />{" "}
          {t("Please enter the title")}
        </div>
      </div>

      {/* Book Series Name */}
      <div className="assign_bookseries form-group">
        <div className="form-label">{t("Book Series")}</div>
        {isEdit
          ? assignmentData.BookSeries.BookSeriesName[
              lang == assignmentData.BookSeries.BookSeriesName[0].Lang ? 0 : 1
            ].BookSeriesName
          : bookSeriesName}
      </div>

      {/* Classes */}
      <div className="assign_classes form-group">
        <div className="form-label"> {t("Assign to")}</div>
        {isEdit ? (
          renderAssignee(assignmentData?.AssignmentTargets)
        ) : (
          <SelectStudent
            t={t}
            classListValue={formClassList}
            assignmentList={assignmentList}
            studyGroupId={studyGroupId}
            handleSelectChange={handleSelectChange}
            className={isVaildAssign == true ? "vaild_border" : ""}
            bookSeriesName={bookSeriesName}
          />
        )}

        <div
          className="input_valid"
          style={{ display: isVaildAssign ? "block" : "none" }}
        >
          <span className="mdi mdi-info-outline" />{" "}
          {t("Please add at least one assignee")}
        </div>
      </div>

      {/* Message */}
      <div className="assign_message form-group">
        <div className="form-label"> {t("Message")}</div>
        <textarea
          type="text-area"
          name="message"
          className="form-control"
          placeholder={t("Enter message to student")}
          onChange={(e) => {
            if (e.keyCode == 13) {
              e.preventDefault();
              const values = e.target.value + "\n";
              setFormMessage(removeEmojis(values));
            } else {
              setFormMessage(removeEmojis(e.target.value));
            }
          }}
          value={formMessage}
          maxLength="500"
        />
      </div>

      {/* Due Date */}
      <div className="assign_due_date form-group">
        <label className="custom-control custom-control-sm custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            onChange={() => {
              setFormDueDateChecked(!formDueDateChecked);
              const isDueDate =
                formDueDateChecked == true
                  ? "Remove due date "
                  : "Add due date";

              gaTrack(
                "send",
                "event",
                !isEdit ? "Create assignment" : "Edit assignment",
                isDueDate,
                "Book Series:" +
                  (!isEdit
                    ? bookSeriesName
                    : assignmentData.BookSeries.BookSeriesName[
                        lang == assignmentData.BookSeries.BookSeriesName[0].Lang
                          ? 0
                          : 1
                      ].BookSeriesName) +
                  ", Role:" +
                  role
              );
            }}
            checked={formDueDateChecked}
          />
          <span className="custom-control-label" id="checkC2" />
          <div className="due_date_text">{t("Due Date")}</div>
        </label>

        <div
          style={{
            display: formDueDateChecked ? "block" : "none",
          }}
          className="date_picker"
        >
          <label>
            <DatePicker
              selected={formDueDate}
              onChange={(date) => {
                document
                  .getElementsByClassName(
                    "react-datepicker-ignore-onclickoutside"
                  )[0]
                  .blur();
                setFormDueDate(date);
              }}
              dateFormat="yyyy-MM-dd"
              customInput={isIOS && <DatepickerInput />}
            />
            <i className="mdi mdi-calendar"></i>
          </label>
          {formDueDateChecked && (
            <div className="date_picker_message">
              {moment(moment().format("YYYY-MM-DD")).diff(formDueDate) > 0 && (
                <>
                  <i className="mdi mdi-info-outline"></i>
                  {t("The due date is passed.")}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Allow redo */}
      {checkIsRedo() && (
        <div className="assign_redo form-group">
          <span>{t("Allow Redo")}</span>
          <label className="switch">
            <input
              type="checkbox"
              id={"checkbox_"}
              onChange={(e) => {
                // onChange(e, obj.StudyGroupId);
                setallowRedo(e.target.checked);
                //ga
                gaTrack(
                  "send",
                  "event",
                  isEdit ? "Edit assignment" : "Create assignment",
                  `Allow redo (Turn ${e.target.checked ? "on" : "off"})`,
                  "Book Series:" +
                    (isEdit
                      ? assignmentData.BookSeries.BookSeriesName[
                          lang ==
                          assignmentData.BookSeries.BookSeriesName[0].Lang
                            ? 0
                            : 1
                        ].BookSeriesName
                      : bookSeriesName) +
                    ", Role:" +
                    role
                );
              }}
              checked={allowRedo}
            />
            <span className="slider round"></span>
            {allowRedo == true ? (
              <span className="on">{t("ON")}</span>
            ) : (
              <span className="off">{t("OFF")}</span>
            )}
          </label>
        </div>
      )}

      {/* Assignments */}
      <div className="assign_resource form-group">
        <div className="form-label">{t("Resource")}</div>
        {isEdit ? (
          assignmentData.Resources.map((_, i) => {
            return (
              <div key={i} className="assignment-item disable">
                <div
                  className="image_dnd"
                  style={{
                    backgroundImage: `url(${ResourceIconMapping(
                      _.Resource.ResourceType,
                      "small",
                      _.Resource.Linkage
                    )})`,
                  }}
                ></div>
                <span>
                  {
                    _.Resource.ResourceLangs[
                      _.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
                    ].ResourceName
                  }
                </span>
              </div>
            );
          })
        ) : (
          <DndAssignment
            lang={lang}
            assignmentList={formAssignmentList}
            onChange={setFormAssignmentList}
            bookSeriesName={bookSeriesName}
          />
        )}
      </div>

      {/* Submit Button */}
      {isEdit ? (
        <div className="yellow_footer" onClick={onEdit}>
          <div>{t("Save")}</div>
        </div>
      ) : (
        <div className="yellow_footer" onClick={onSubmit}>
          <div>{t("Assign")}</div>
        </div>
      )}
    </div>
  );
};
