/**
 * @generated SignedSource<<ffa1ad754da5d6f7992bd6adf995aedd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GetB2CTopicResourcesQuery$variables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
  TagName: string,
  PageNo?: ?number,
  PageSize?: ?number,
|};
export type GetB2CTopicResourcesQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +Books: ?$ReadOnlyArray<?{|
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +RelatedTagType: ?$ReadOnlyArray<?{|
            +Tags: ?$ReadOnlyArray<?{|
              +TopicPreStudyResources: ?{|
                +Resources: ?$ReadOnlyArray<?{|
                  +IngestionId: ?string,
                  +ResourceId: ?string,
                  +RelatedId: ?string,
                  +ResourceDownloadUrl: ?string,
                  +ResourceFileNameIfAny: ?string,
                  +ResourceAccessUrl: ?string,
                  +ResourceType: ?string,
                  +Linkage: ?string,
                  +FileSource: ?string,
                  +Downloadable: ?string,
                  +Thumbnail: ?{|
                    +FilePath: ?string,
                  |},
                  +ResourceLangs: ?$ReadOnlyArray<?{|
                    +Lang: ?string,
                    +ResourceName: ?string,
                  |}>,
                |}>,
                +TotalNumberOfRecord: ?number,
              |},
            |}>,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
export type GetB2CTopicResourcesQuery = {|
  variables: GetB2CTopicResourcesQuery$variables,
  response: GetB2CTopicResourcesQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "PageNo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "PageSize"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "TagName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  }
],
v8 = {
  "kind": "Variable",
  "name": "BookId",
  "variableName": "BookId"
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "TagName",
    "variableName": "TagName"
  }
],
v11 = [
  (v8/*: any*/),
  {
    "kind": "Literal",
    "name": "IncludeFollowUpRes",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "Order",
    "value": "ASC"
  },
  {
    "kind": "Variable",
    "name": "PageNo",
    "variableName": "PageNo"
  },
  {
    "kind": "Variable",
    "name": "PageSize",
    "variableName": "PageSize"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceDownloadUrl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceFileNameIfAny",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Linkage",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileSource",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Downloadable",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FilePath",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TotalNumberOfRecord",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetB2CTopicResourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "kind": "LinkedField",
                    "name": "AdoptedTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "kind": "LinkedField",
                        "name": "RelatedTagType",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "kind": "LinkedField",
                            "name": "Tags",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": (v11/*: any*/),
                                "concreteType": "resourceSearchResult",
                                "kind": "LinkedField",
                                "name": "TopicPreStudyResources",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "resource",
                                    "kind": "LinkedField",
                                    "name": "Resources",
                                    "plural": true,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v13/*: any*/),
                                      (v14/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      (v17/*: any*/),
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      (v20/*: any*/),
                                      (v21/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "systemFile",
                                        "kind": "LinkedField",
                                        "name": "Thumbnail",
                                        "plural": false,
                                        "selections": [
                                          (v22/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v23/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v24/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetB2CTopicResourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "kind": "LinkedField",
                    "name": "AdoptedTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "kind": "LinkedField",
                        "name": "RelatedTagType",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v10/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "kind": "LinkedField",
                            "name": "Tags",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": (v11/*: any*/),
                                "concreteType": "resourceSearchResult",
                                "kind": "LinkedField",
                                "name": "TopicPreStudyResources",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "resource",
                                    "kind": "LinkedField",
                                    "name": "Resources",
                                    "plural": true,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v13/*: any*/),
                                      (v14/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      (v17/*: any*/),
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      (v20/*: any*/),
                                      (v21/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "systemFile",
                                        "kind": "LinkedField",
                                        "name": "Thumbnail",
                                        "plural": false,
                                        "selections": [
                                          (v22/*: any*/),
                                          (v25/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v23/*: any*/),
                                      (v25/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v24/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v25/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v25/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/)
            ],
            "storageKey": null
          },
          (v25/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f85d1586750bd936a39e08d97c1a9645",
    "id": null,
    "metadata": {},
    "name": "GetB2CTopicResourcesQuery",
    "operationKind": "query",
    "text": "query GetB2CTopicResourcesQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n  $TagName: String!\n  $PageNo: Int\n  $PageSize: Int\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      Books(BookId: $BookId) {\n        AdoptedTagHierarchy {\n          RelatedTagType {\n            Tags(BookId: $BookId, TagName: $TagName) {\n              TopicPreStudyResources(BookId: $BookId, IncludeFollowUpRes: true, PageNo: $PageNo, PageSize: $PageSize, Order: ASC) {\n                Resources {\n                  IngestionId\n                  ResourceId\n                  RelatedId\n                  ResourceDownloadUrl\n                  ResourceFileNameIfAny\n                  ResourceAccessUrl\n                  ResourceType\n                  Linkage\n                  FileSource\n                  Downloadable\n                  Thumbnail {\n                    FilePath\n                    id\n                  }\n                  ResourceLangs {\n                    Lang\n                    ResourceName\n                  }\n                  id\n                }\n                TotalNumberOfRecord\n              }\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1350c94a56cd122cc5cee448c2ec151c";

module.exports = ((node/*: any*/)/*: Query<
  GetB2CTopicResourcesQuery$variables,
  GetB2CTopicResourcesQuery$data,
>*/);
