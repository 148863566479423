import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Prompt } from "react-router-dom";
import { config } from "../../../../../Config/config";
import { animateScroll as scroll } from "react-scroll";
import AssessmentGetLearnosityObjectMutation from "../../../../../Components/PopUpView/Page/AssessmentLayout/AssessmentTab/AssessmentGetLearnosityObjectMutation";
import CompleteSelfLearnMutation from "../../../../../Components/PopUpView/Page/AssessmentLayout/AssessmentTab/CompleteSelfLearnMutation";
import CompleteAssignmentMutation from "../../../../../Services/api/GraphQL/Mutation/CompleteAssignment";
import AttemptAssignmentMutation from "../../../../../Services/api/GraphQL/Mutation/AttemptAssignment";
import StudentAttemptyHistory from "../../../../../Services/api/GraphQL/Query/StudentAttemptyHistory";

import "./index.scss";
import { activityLayoutTab } from "../../../../../Services/Common/common";
import {
  getMetadata,
  getObjectKeys,
} from "../../../../../Services/Common/leanosity";
import useEncryption from "../../../../../hooks/useEncryption";

var itemApp;
var LearnositySession_id = null;
var submited = "";
var submitAssessmentResult = null;
// var isInappBrowser = false;
const index = (props) => {
  const {
    t,
    isReadyToRender,
    resourceData,
    bookData,
    // match,
    B2C,
    activity,
    // entry,
    // standalone,
    isInappBrowser,
    inappData,
  } = props;
  // const params = props.standalone ? match.params.resId.split("&") : null;
  const userData = useSelector((state) => state.UserReducer.user);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const effectiveSchoolYear = useSelector(
    (state) => state.SystemReducer.effectiveSchoolYear
  );
  const userType = userData?.UserType;
  const UserDisplayName = userData?.UserDisplayName;
  const [loading, setloading] = useState(true);
  const [initReady, setinitReady] = useState(false);
  const [LeaveAlert, setLeaveAlert] = useState(true);
  // const [isFinish, setisFinish] = useState(false);
  const [isDoneBefore, setisDoneBefore] = useState(false);
  const [removeStart, setremoveStart] = useState(false);
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre")?decryptToLocal(window.localStorage.getItem("userCre")):null;

  useEffect(() => {
    console.log("[Start page]", props);
    activityLayoutTab({ disable: true, isInappBrowser });
    checkUserHaveDoneAssessmet();
  }, []);

  const checkUserHaveDoneAssessmet = async () => {
    let variables;

    // if (standalone) {
    //   if (activity) {
    //     variables = {
    //       ResourceId: props.match.params.resId,
    //       UserId: userData?.UserId,
    //       IncludeAssignmentRecord: !B2C ? true : false,
    //     };
    //   } else {
    //     variables = {
    //       ResourceAssignId: params[2],
    //       UserId: userData?.UserId,
    //       // IncludeAssignmentRecord: !B2C ? true : false,
    //     };
    //   }

    //   StudentAttemptyHistory(variables, activity ? false : true).then(
    //     (data) => {
    //       console.log(data);

    //       if (activity) {
    //         console.log(data);
    //         if (data.user.SelfLearnAttemptHistory.length != 0) {
    //           var allCompleted = data.user.SelfLearnAttemptHistory.length;
    //           data.user.SelfLearnAttemptHistory.map((item) => {
    //             if (!item.IsCompleted) {
    //               allCompleted = allCompleted - 1;
    //             }
    //           });
    //           console.log(allCompleted, "history completed");
    //           if (allCompleted != 0) {
    //             //have history
    //             if (!isInappBrowser) {
    //               window.document
    //                 .getElementById("studygroup_back")
    //                 ?.classList.remove("dim-button");
    //             }
    //             window.document
    //               .getElementById("assessment_tab")
    //               ?.classList.remove("dim-button");
    //             window.document
    //               .getElementById("result_tab")
    //               ?.classList.remove("dim-button");

    //             window.document
    //               .getElementById("as_tab")
    //               ?.classList.remove("dim-button");

    //             var checkItemScriptIsReady = setInterval(() => {
    //               if (window.LearnosityItems) {
    //                 clearInterval(checkItemScriptIsReady);
    //                 console.log(
    //                   "[checkItemScriptIsReady]  Find window.LearnosityItems"
    //                 );

    //                 getLearnosityObject(true);
    //               }
    //             }, 1000);
    //           } else {
    //             var checkItemScriptIsReady = setInterval(() => {
    //               if (window.LearnosityItems) {
    //                 console.log(
    //                   "[checkItemScriptIsReady]  Find window.LearnosityItems"
    //                 );
    //                 clearInterval(checkItemScriptIsReady);
    //                 getLearnosityObject(false);
    //               }
    //             }, 1000);
    //           }
    //         } else {
    //           var checkItemScriptIsReady = setInterval(() => {
    //             if (window.LearnosityItems) {
    //               clearInterval(checkItemScriptIsReady);
    //               console.log(
    //                 "[checkItemScriptIsReady]  Find window.LearnosityItems"
    //               );

    //               getLearnosityObject(false);
    //             }
    //           }, 1000);
    //         }
    //       } else {
    //         let targetRes = data.resourceAssignment.Resources.filter((o, i) => {
    //           return o.ResourceAssignResId == params[1];
    //         });
    //         console.log(targetRes);
    //         targetRes = targetRes[0];
    //         if (targetRes.LearnosityAttemptHistory.length != 0) {
    //           var allCompleted = targetRes.LearnosityAttemptHistory.length;
    //           targetRes.LearnosityAttemptHistory.map((item) => {
    //             if (!item.IsCompleted) {
    //               allCompleted = allCompleted - 1;
    //             }
    //           });
    //           console.log(allCompleted, "history completed");
    //           if (allCompleted != 0) {
    //             //have history
    //             if (!isInappBrowser) {
    //               window.document
    //                 .getElementById("studygroup_back")
    //                 ?.classList.remove("dim-button");
    //             }
    //             window.document
    //               .getElementById("assessment_tab")
    //               ?.classList.remove("dim-button");
    //             window.document
    //               .getElementById("result_tab")
    //               ?.classList.remove("dim-button");

    //             window.document
    //               .getElementById("as_tab")
    //               ?.classList.remove("dim-button");

    //             var checkItemScriptIsReady = setInterval(() => {
    //               if (window.LearnosityItems) {
    //                 clearInterval(checkItemScriptIsReady);
    //                 console.log(
    //                   "[checkItemScriptIsReady]  Find window.LearnosityItems"
    //                 );

    //                 getLearnosityObject(true);
    //               }
    //             }, 1000);
    //           } else {
    //             var checkItemScriptIsReady = setInterval(() => {
    //               if (window.LearnosityItems) {
    //                 console.log(
    //                   "[checkItemScriptIsReady]  Find window.LearnosityItems"
    //                 );
    //                 clearInterval(checkItemScriptIsReady);
    //                 getLearnosityObject(false);
    //               }
    //             }, 1000);
    //           }
    //         } else {
    //           var checkItemScriptIsReady = setInterval(() => {
    //             if (window.LearnosityItems) {
    //               clearInterval(checkItemScriptIsReady);
    //               console.log(
    //                 "[checkItemScriptIsReady]  Find window.LearnosityItems"
    //               );

    //               getLearnosityObject(false);
    //             }
    //           }, 1000);
    //         }
    //       }
    //     }
    //   );
    // } else {
    if (!isInappBrowser) {
      variables = {
        ResourceId: props.match.params.resId,
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
        IncludeAssignmentRecord: false,
      };
    } else {
      variables = {
        ResourceId: inappData.resourceId,
        UserId: userData?.UserId,
        IncludeAssignmentRecord: false,
      };
    }
    await StudentAttemptyHistory(variables, false).then((data) => {
      console.log(data);
      if (data.user.SelfLearnAttemptHistory.length != 0) {
        var allCompleted = data.user.SelfLearnAttemptHistory.length;
        data.user.SelfLearnAttemptHistory.map((item) => {
          if (!item.IsCompleted) {
            allCompleted = allCompleted - 1;
          }
        });
        console.log(allCompleted, "history completed");
        if (allCompleted != 0) {
          //have history
          activityLayoutTab({ disable: false, isInappBrowser });
          setisDoneBefore(true);
          setloading(false);
          return;
        }
      }
      var checkItemScriptIsReady = setInterval(() => {
        if (window.LearnosityItems) {
          clearInterval(checkItemScriptIsReady);
          console.log("[checkItemScriptIsReady]  Find window.LearnosityItems");

          getLearnosityObject(false);
        }
      }, 1000);
    });
    // }
  };

  const getLearnosityObject = () => {
    // if (standalone) {
    //   console.log("[standalone] getLearnosityObject");
    //   let dataSet;
    //   submitAssessmentResult = null;
    //   const callback = (data, error) => {
    //     console.log(data);
    //     let _learnosityObj = activity
    //       ? data.attemptSelfLearn
    //       : data.attemptAssignment;
    //     console.log(_learnosityObj);
    //     console.log(haveDoneBefore);
    //     // const {
    //     //   [attemptSelfLearn ? attemptSelfLearn : attemptAssignment]: {},
    //     // } = data || {};

    //     if (userType == "S") {
    //       if (haveDoneBefore) {
    //         if (_learnosityObj == "redoBlocked") {
    //           setloading(false);
    //           setisFinish(true);
    //           setLeaveAlert(false);
    //           // setisRedoBlock(true);
    //         } else {
    //           setloading(false);
    //           setisFinish(true);
    //           setLeaveAlert(false);
    //           // setisRedoBlock(false);
    //         }
    //       } else {
    //         // setLearnosityObject(JSON.parse(_learnosityObj));
    //         LearnositySession_id = JSON.parse(_learnosityObj).request
    //           .session_id;
    //         console.log(
    //           JSON.parse(_learnosityObj).request.session_id,
    //           "=-=-----=-=-="
    //         );
    //         setLeaveAlert(true);
    //         setloading(false);
    //         setTimeout(
    //           () => startLearnosityInit(JSON.parse(_learnosityObj), true),
    //           100
    //         );
    //       }
    //     }
    //     if (userType == "T") {
    //       if (_learnosityObj == "failed") {
    //         setloading(false);
    //         setinitReady(true);
    //         if (!isInappBrowser) {
    //           // window.document.getElementById("studygroup_back") &&
    //           window.document
    //             .getElementById("studygroup_back")
    //             ?.classList.remove("dim-button");
    //         }
    //         // window.document.getElementById("assessment_tab") &&
    //         window.document
    //           .getElementById("assessment_tab")
    //           ?.classList.remove("dim-button");
    //         // window.document.getElementById("result_tab") &&
    //         window.document
    //           .getElementById("result_tab")
    //           ?.classList.remove("dim-button");

    //         // window.document.getElementById("answer_tab") &&
    //         window.document
    //           .getElementById("as_tab")
    //           ?.classList.remove("dim-button");

    //         return;
    //       }
    //       // setLearnosityObject(JSON.parse(_learnosityObj));
    //       LearnositySession_id = JSON.parse(_learnosityObj).request.session_id;
    //       console.log(
    //         JSON.parse(_learnosityObj).request.session_id,
    //         "=-=-----=-=-="
    //       );
    //       setloading(false);
    //       startLearnosityInit(JSON.parse(_learnosityObj), true);
    //     }
    //   };
    //   if (activity) {
    //     dataSet = {
    //       Lang: lang == "en-US" ? "EN" : "TC",
    //       ResourceId: props.match.params.resId,
    //       SchoolYearId: effectiveSchoolYear.SchoolYearId,
    //     };
    //     AssessmentGetLearnosityObjectMutation(dataSet, callback);
    //   } else {
    //     dataSet = {
    //       Lang: lang == "en-US" ? "EN" : "TC",
    //       ResourceAssignResId: params[1],
    //     };
    //     AttemptAssignmentMutation(dataSet, callback);
    //   }
    // } else {
    let dataSet;
    submitAssessmentResult = null;
    dataSet = {
      Lang: lang == "en-US" ? "EN" : "TC",
      ResourceId: !isInappBrowser
        ? props.match.params.resId
        : props.inappData.resourceId,
      SchoolYearId: effectiveSchoolYear.SchoolYearId,
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };
    const result = (callback) => {
      console.log(callback, "result");
      console.log(callback.attemptSelfLearn);
      LearnositySession_id = JSON.parse(callback.attemptSelfLearn).request
        .session_id;
      console.log(
        JSON.parse(callback.attemptSelfLearn).request.session_id,
        "=-=-----=-=-="
      );
      setloading(false);
      startLearnosityInit(JSON.parse(callback.attemptSelfLearn), true);
      // }
    };

    AssessmentGetLearnosityObjectMutation(dataSet, result);
    // }
  };

  const submit = (itemIndex) => {
    config.mode == "dev" && console.log("[learnosity test:submit]");

    activityLayoutTab({ disable: true, isInappBrowser });

    console.log(LearnositySession_id);
    submitAssessment();
  };

  const startLearnosityInit = (object, LeaveAlert) => {
    console.log("[Start init learnosity]");
    var callbacks = {
      errorListener: errorListener,
      readyListener: () => readyListener(),
    };
    console.log(LeaveAlert);
    if (LeaveAlert) {
      // if (standalone) {
      // } else {
      try {
        itemApp = window.LearnosityItems.init(object, callbacks);
        console.log(itemApp, window.LearnosityItems);
        itemApp.on("test:start", () => {
          const { resourceData, bookData } = props;
          var _userType = "";
          if (isInappBrowser) {
            _userType = userType;
          } else {
            _userType = JSON.parse(localStorage.getItem("userData")).UserType;
          }

          console.log(resourceData, bookData);

          try {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Start",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Book Series:" +
                bookData.BookSeriesName[0].BookSeriesName +
                ", Book:" +
                bookData.Books[0].BookName[0].BookName +
                ", Chapter:NoData" +
                ", Role:" +
                _userType +
                ", From:Supplementary"
            );
          } catch (e) {
            console.log("GA Error");
          }
        });
        itemApp.on("test:submit", submit);

        itemApp.on("test:finished:submit", (itemIndex) => {
          config.mode == "dev" &&
            console.log("[learnosity test:finished:submit]");
          itemApp.reset();
          props.history.push(that.state.backPath);
        });

        itemApp.on("item:changed", (itemIndex) => {
          scroll.scrollToTop();
        });

        itemApp.on("test:panel:show", (itemIndex) => {
          scroll.scrollToTop();
        });

        itemApp.on("test:submit:success", (itemIndex) => {
          setLeaveAlert(false);
          config.mode == "dev" &&
            console.log("[learnosity test:submit:success]");

          setinitReady(false);
          var myCheck = setInterval(() => {
            if (!submitAssessmentResult && submited != "DONE") {
              console.log(submited, "lopping");
              return;
            }
            clearInterval(myCheck);
            submited = "WAITE";
            props.history.push(
              props.location.pathname.replace("/start", "/result")
            );
          });
        });
      } catch (e) {
        console.log("trycatch", e);
      }
      // }
    }
  };

  const readyListener = async () => {
    await handleHints();
    await handleDistractor();
    setinitReady(true);
    activityLayoutTab({ disable: false, isInappBrowser });
  };

  const errorListener = (e) => {
    // Adds a listener to all error codes.
    console.log("Error Code ", e.code);
    console.log("Error Message ", e.msg);
    console.log("Error Detail ", e.detail);
    console.log(props.match.url + "/start", props.location.pathname);
  };

  const renderDistractor = (id, content) => {
    console.log(id, content);
    var template;
    if (document.getElementById(id + "_distractor")) {
      fadeIn(document.getElementById(id + "_distractor"), 500);
      document.getElementById(id + "_distractor").innerHTML = content;
    } else {
      template = document.createElement("div");
      template.id = id + "_distractor";
      template.className = "distractor-rationale alert";
      template.innerHTML = content;
      document.getElementById(id).append(document.createElement("br"));
      document.getElementById(id).append(template);
    }
    // renderMath() Renders all Latex or MathML elements on the page with MathJax.
    itemApp.questionsApp().renderMath();
  };

  const removeDistractor = (id) => {
    fadeOut(document.getElementById(id + "_distractor"), 500);
  };

  const fadeIn = (el, time) => {
    if (el.style.opacity == 1) return;
    el.style.display = "block";

    var last = +new Date();
    var tick = () => {
      el.style.opacity = +el.style.opacity + (new Date() - last) / time;
      last = +new Date();

      if (+el.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
          setTimeout(tick, 16);
      }
    };

    tick();
  };

  const fadeOut = (elem, ms) => {
    if (!elem) return;
    if (elem.style.opacity == 0) return;
    if (removeStart) return;
    if (ms) {
      setremoveStart(true);
      var opacity = 1;
      var timer = setInterval(() => {
        opacity -= 50 / ms;
        if (opacity <= 0) {
          clearInterval(timer);
          elem.style.display = "none";
          opacity = 0;
          setremoveStart(false);
        }
        elem.style.opacity = opacity;
      }, 50);
    } else {
      elem.style.opacity = 0;
    }
  };

  const handleDistractor = async () => {
    let questions = itemApp.questions();
    console.log(questions);
    // var that = this;
    Object.keys(questions).map((index) => {
      questions[index].on("validated", () => {
        var outputHTML = "";
        var map, qid;
        try {
          const {
            resourceData,

            bookData,
          } = props;

          gaTrack(
            "send",
            "event",
            "Assessment",
            "Check answer",
            "Question:" +
              questions[index].getQuestion().response_id +
              ", Assessment:" +
              resourceData.ResourceLangs[0].ResourceName +
              ", Resource ID:" +
              resourceData.IngestionId +
              ", Book Series:" +
              bookData.BookSeriesName[0].BookSeriesName +
              ", Book:" +
              bookData.Books[0].BookName[0].BookName +
              ", Chapter:" +
              (bookData.Books && bookData.Books.length > 0
                ? bookData.Books[0].Units[0].UnitName[0].UnitName
                : "NoData") +
              ", Role:" +
              userType +
              ", From:Supplementary"
          );
        } catch (e) {
          console.log("Check answer GA Error", e);
        }

        if (
          questions[index].mapValidationMetadata(
            "distractor_rationale_response_level"
          ) != false
        ) {
          map = questions[index].mapValidationMetadata(
            "distractor_rationale_response_level"
          );
          var distractArr = [];
          var correctArr = [];
          var wrongArr = [];
          console.log(map.incorrect);
          map.incorrect.map((data, i) => {
            //  Each item in the `map.incorrect` array is an object that contains a `value` property that
            //    holds the response value; an `index` property that refers to the shared index of both the
            //  response area and the metadata; and a `metadata` property containing the metadata value.
            var distractor = data.metadata;
            distractArr.push(data);
            wrongArr.push(data.index);
          });
          map.correct.map((data, i) => {
            var distractor = data.metadata;
            distractArr.push(data);
            correctArr.push(data.index);
          });
          if (distractArr.length > 0) {
            distractArr.sort(function (a, b) {
              return parseFloat(a.index) - parseFloat(b.index);
            });
            distractArr.map((data, i) => {
              console.log(data, correctArr, "!!!!!!!!!!!!!!!!!");
              if (correctArr.indexOf(data.index) != -1) {
                outputHTML +=
                  '<div class="alert" style="margin-bottom:0px; background-color: #71cf1f; color: #000000; border-color: #387800;"><ul><li>' +
                  data.metadata +
                  "</li></ul></div>";
              } else {
                outputHTML +=
                  '<div class="alert alert-danger" style="margin-bottom:0px;"><ul><li>' +
                  data.metadata +
                  "</li></ul></div>";
              }
            });
          }
        } else if (
          questions[index].getMetadata({ itemApp }).distractor_rationale
        ) {
          outputHTML = questions[index].getMetadata({
            itemApp,
          }).distractor_rationale;
        }

        if (!outputHTML) {
          outputHTML = "Have you answered all possible responses?";
        }
        qid = questions[index].getQuestion().response_id;
        renderDistractor(qid, outputHTML);
      });
      questions[index].on("changed", () => {
        removeDistractor(questions[index].getQuestion().response_id);
      });
    });

    // callback();
  };

  const renderHint = (e) => {
    var question_id = e.target.id.slice(0, e.target.id.indexOf("__button"));
    const { t } = props;
    console.log(question_id, "on click ID");

    var metadata = getMetadata({ key: question_id, itemApp });
    var hints = metadata.hints ? metadata.hints : [];
    console.log(metadata, hints);
    console.log(question_id);

    try {
      const { resourceData, bookData } = props;
      // const userType = JSON.parse(localStorage.getItem("userData")).UserType;

      gaTrack(
        "send",
        "event",
        "Assessment",
        "Hints",
        "Question:" +
          question_id +
          ", Assessment:" +
          resourceData.ResourceLangs[0].ResourceName +
          ", Resource ID:" +
          resourceData.IngestionId +
          ", Book Series:" +
          bookData.BookSeriesName[0].BookSeriesName +
          ", Book:" +
          bookData.Books[0].BookName[0].BookName +
          ", Chapter:" +
          (bookData.Books && bookData.Books.length > 0
            ? bookData.Books[0].Units[0].UnitName[0].UnitName
            : "NoData") +
          ", Role:" +
          userType +
          ", From:Supplementary"
      );
    } catch (e) {
      console.log("Check answer GA Error");
    }

    if (document.getElementById(question_id + "_hints")) {
      document.getElementById(question_id + "_hints").innerHTML = "";
    } else {
      console.log(document.getElementById(question_id + "_hints"));
    }
    console.log(metadata, hints);
    console.log(question_id);
    // check how many times the hint button has been clicked..
    var hintsClicked = document
      .getElementById(question_id)
      .getAttribute("hintsClicked");
    if (hintsClicked == undefined) {
      document.getElementById(question_id).setAttribute("hintsClicked", 1);
    } else if (parseInt(hintsClicked) < hints.length) {
      document
        .getElementById(question_id)
        .setAttribute("hintsClicked", parseInt(hintsClicked) + 1);
    }
    console.log(
      hintsClicked,
      document.getElementById(question_id).getAttribute("hintsClicked")
    );
    // Add the hint(s) from questions metadata and render into the div#hints element
    for (
      var i = 0;
      i < document.getElementById(question_id).getAttribute("hintsClicked");
      i++
    ) {
      //hintsElem.addClass('alert alert-warning').append($.parseHTML(hints[i]));
      if (document.getElementById(question_id + "_hints")) {
        document.getElementById(question_id + "_hints").innerHTML +=
          '<div class="hits-item" style="opacity: 0;" id="' +
          question_id +
          "_hint_" +
          i +
          '"><div>' +
          hints[i] +
          "</div></div>";
      } else {
        var template = document.createElement("div");
        template.id = question_id + "_hints";
        template.className = "distractor-rationale alert hits-box";
        template.innerHTML =
          '<div class="hits-item" style="opacity: 0;" id="' +
          (question_id + "_hint_" + i) +
          '"><div>' +
          hints[i] +
          "</div></div>";

        document
          .getElementById(question_id)
          .closest("div.row")
          .append(template);
        // Render any LaTeX that might have been in the hint
      }
    }

    console.log(
      document.getElementById(question_id + "__button"),
      question_id + "__button"
    );
    document.getElementById(question_id + "__button").innerHTML =
      t("Hint") +
      " (" +
      (hints.length -
        parseInt(
          document.getElementById(question_id).getAttribute("hintsClicked")
        )) +
      " " +
      t("left") +
      ") ";
    try {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, question_id + "_hints"]);
    } catch {
      (e) => console.log(e);
    }

    setTimeout(() => {
      for (
        var i = 0;
        i < document.getElementById(question_id).getAttribute("hintsClicked");
        i++
      ) {
        console.log(document.getElementById(question_id + "_hint_" + i));
        document.getElementById(question_id + "_hint_" + i).style.opacity = "1";
      }
    }, 100);
  };

  const handleHints = () => {
    // logic for hints starts
    const { t } = props;
    var metadata = getMetadata({ itemApp });
    var question_ids = getObjectKeys({ obj: metadata });
    // Render a button next to each question for users to see a hint (if available)
    console.log(metadata, question_ids, "[]");
    // for that question
    for (var i = 0; i < question_ids.length; i++) {
      var id = question_ids[i];
      var metadata = getMetadata({ key: id, itemApp });
      var hints = metadata.hints;
      console.log(i + "[" + id + "]", hints, metadata);
      if (hints != undefined && hints.length > 0) {
        var btnHint = document.createElement("div");
        var btn = document.createElement("button");
        console.log(question_ids[i], id);
        btn.onclick = (e) => renderHint(e);
        btn.innerText = t("Hint");
        btn.className = "lrn_btn hits-button";
        btn.id = id + "__button";
        btnHint.className = "col-xs-12";
        btnHint.append(btn);
        /*
        btnHint.innerHTML =
          '<button type="button" class="btn btn-default btn-sm"' +
          id +
          ` onclick=${()=>this.renderHint(id)}`+">Hint</button>";*/
        document.getElementById(id).closest("div.row").append(btnHint);
      }
    }
    // logic for hints end
  };

  const submitAssessment = () => {
    submited = "SUBMITTING";
    config.mode == "dev" &&
      console.log(
        "[learnosity Start Call Finish Mutation]",
        LearnositySession_id
      );
    // if (standalone) {
    //   if (activity) {
    //     var value;
    //     if (!isInappBrowser) {
    //       value = {
    //         Lang: localStorage.getItem("i18nextLng") == "en-US" ? "EN" : "TC",
    //         ResourceId: props.match.params.resId.split("&")[0],
    //         SessionId: LearnositySession_id,
    //         SchoolYearId: effectiveSchoolYear.SchoolYearId,
    //       };
    //     } else {
    //       value = {
    //         Lang:
    //           localStorage.getItem("i18nextLng-inapp") == "en-US" ? "EN" : "TC",
    //         ResourceId: localStorage.getItem("in-app-browser-data-resourceid"),
    //         SessionId: LearnositySession_id,
    //         SchoolYearId: localStorage.getItem("effectiveSchoolYear"),
    //       };
    //     }
    //     const result = (e) => {
    //       try {
    //         if (standalone) {
    //           try {
    //             gaTrack(
    //               "send",
    //               "event",
    //               "Assessment",
    //               "Submit",
    //               "Assessment:" +
    //                 resourceData.ResourceLangs[0].ResourceName +
    //                 ", Resource ID:" +
    //                 resourceData.IngestionId +
    //                 ", Book Series:" +
    //                 bookData.BookSeriesName[0].BookSeriesName +
    //                 ", Book:NoData" +
    //                 ", Chapter:NoData" +
    //                 ", Role:" +
    //                 userType
    //             );
    //           } catch (e) {
    //             console.log("GA Error");
    //           }
    //         }
    //       } catch (e) {
    //         console.log("Check answer GA Error");
    //       }
    //       console.log(
    //         "[learnosity CompleteSelfLearnMutation]",
    //         e,
    //         JSON.parse(e.completeSelfLearn)
    //       );
    //       submitAssessmentResult = JSON.parse(e.completeSelfLearn).reportObj;
    //       submited = "DONE";
    //       console.log("[Tags data]", JSON.parse(e.completeSelfLearn).tagData);
    //     };
    //     CompleteSelfLearnMutation(value, result);
    //   } else {
    //     const value = {
    //       Lang: lang == "en-US" ? "EN" : "TC",
    //       ResourceAssignResId: resourceData.assign_id,
    //       SessionId: LearnositySession_id,
    //     };
    //     console.log(value, "value");
    //     const result = (e) => {
    //       console.log(
    //         "[learnosity CompleteAssignmentMutation]",
    //         e,
    //         JSON.parse(e.completeAssignment)
    //       );
    //       try {
    //         gaTrack(
    //           "send",
    //           "event",
    //           "Assessment",
    //           "Submit",
    //           "Assessment:" +
    //             resourceData.ResourceLangs[0].ResourceName +
    //             ", Resource ID:" +
    //             resourceData.IngestionId +
    //             ", Book Series:" +
    //             bookData.BookSeriesName[0].BookSeriesName +
    //             ", Book:NoData" +
    //             ", Chapter:NoData" +
    //             ", Role:" +
    //             userType
    //         );
    //       } catch (e) {
    //         console.log("GA Error");
    //       }
    //       submitAssessmentResult = JSON.parse(e.completeAssignment).reportObj;
    //       submited = "DONE";
    //       console.log("[Tags data]", JSON.parse(e.completeAssignment).tagData);
    //     };
    //     CompleteAssignmentMutation(value, result);
    //   }
    // } else {
    let value = {
      Lang: lang == "en-US" ? "EN" : "TC",
      ResourceId: !isInappBrowser
        ? props.match.params.resId.split("&")[0]
        : props.inappData.resourceId,
      SessionId: LearnositySession_id,
      SchoolYearId: effectiveSchoolYear.SchoolYearId,
    };
    if (!B2C) {
      value = {
        ...value,
        StudyGroupId: props.location.state?.group_id || "",
      };
    }

    console.log(value, "value");
    const result = (e) => {
      try {
        const { resourceData, bookData } = props;
console.log(bookData);
        gaTrack(
          "send",
          "event",
          "Assessment",
          "Submit",
          "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:NoData" +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            bookData.Books[0].BookName[0].BookName +
            ", Chapter:" +
            (bookData.Books && bookData.Books.length > 0
              ? bookData.Books[0].Units[0].UnitName[0].UnitTitle
              : "NoData") +
            ", Role:" +
            userType +
            ", From:Supplementary"
        );
      } catch (e) {
        console.log(e);
        console.log("Check answer GA Error");
      }
      console.log(
        "[learnosity CompleteSelfLearnMutation]",
        e,
        JSON.parse(e.completeSelfLearn)
      );

      submitAssessmentResult = JSON.parse(e.completeSelfLearn).reportObj;
      submited = "DONE";
      console.log("[Tags data]", JSON.parse(e.completeSelfLearn).tagData);
    };
    CompleteSelfLearnMutation(value, result);
    // }
  };

  return (
    <div className="assessment-tab" id="assessment-tab">
      <Prompt
        when={LeaveAlert}
        message={t("Are you sure you want to leave?")}
      />
      {loading || !isReadyToRender ? (
        ""
      ) : isDoneBefore ? (
        <div className="submited-box">
          <div className="submited-icon">
            <i className="icon mdi mdi-check-circle"></i>
          </div>
          <div className="submited-text">
            <h3>{t("You have completed the assessment.​")}</h3>
            {/* <h5>
              {t("Good! the assessment is done already. You may do it again.")}
            </h5> */}
          </div>
          <div className="submited-btn">
            <button
              className="btn btn-primary"
              onClick={() => {
                try {
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click view my result",
                    "Assessment:" +
                      resourceData.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      resourceData.IngestionId +
                      ", Level:NoData" +
                      ", Book Series:" +
                      bookData.BookSeriesName[0].BookSeriesName +
                      ", Book:NoData" +
                      ", Chapter:NoData" +
                      ", Role:" +
                      userType
                  );
                } catch (e) {
                  console.log("Check answer GA Error");
                }
                if (!isInappBrowser) {
                  return props.history.push(
                    props.location.pathname.replace("/start", "/result")
                  );
                }

                // if (isInappBrowser && standalone) {
                //   props.history.push(
                //     "/" +
                //       (entry === "assignment" ? "assignment" : "activity") +
                //       "/" +
                //       match.params.resId +
                //       "/result"
                //   );
                // }
                // if (isInappBrowser && !standalone) {
                if (activity) {
                  return props.history.push(
                    props.location.pathname.replace("/start", "/result")
                  );
                }
                return props.history.push("/assessment=api/result");
                // }
              }}
            >
              {t("View Records​")}
            </button>

            <button
              className="btn btn-secondary"
              onClick={() => {
                try {
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Redo",
                    "Assessment:" +
                      resourceData.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      resourceData.IngestionId +
                      ", Book Series:" +
                      bookData.BookSeriesName[0].BookSeriesName +
                      ", Book:NoData" +
                      ", Chapter:NoData" +
                      ", Role:" +
                      userType
                  );
                } catch (e) {
                  console.log("Check answer GA Error", e);
                }
                activityLayoutTab({ disable: true, isInappBrowser });
                setinitReady(false);
                setisDoneBefore(false);
                window.LearnosityItems.reset();
                setTimeout(() => getLearnosityObject(), 100);
              }}
            >
              {t("Reassess")}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              height: `${!initReady ? "100px" : "0px"}`,
            }}
          >
            <div
              className="learnosity_loading"
              style={{
                display: `${initReady ? "none" : "block"}`,
                // position: "absolute",
                width: "100%",
                // maxWidth:""
              }}
            >
              <div className="splash-container">
                <div className="loader mx-auto" />
              </div>
            </div>
          </div>
          <div
            style={{
              visibility: `${initReady ? "visible" : "hidden"}`,
              height: `${initReady ? "auto" : "0px"}`,
            }}
          >
            <div id="learnosity_assess"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default index;
