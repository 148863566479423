import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import { useDispatch, useSelector } from "react-redux";
import successIcon from "../../../assets/img/system/check-circle-24-px.svg";
import { toast } from "react-toastify";
import environment from "../../../Environment";
import moment from "moment";
import Loading from "../../Loading/Loading";
// import "../../PopUpView/Page/StudyGroup/Assignment_ready_to_remove/index.scss";
import "../../../Layouts/ClassViewLayout/Body/Assignment/index.scss";
import ResourceIconMapping from "../../Feature/Function/ResourceIconMapping";
import GetDueDateStyle from "../../Feature/Function/GetDueDateStyle";
import ReadResourceAssignmentResourceMutation from "../../Feature/Function/ReadResourceAssignmentResourceMutation";
import "./ViewAssignmentDetails.scss";
import ImageLightBox from "../../Feature/ImageLightBox";
import VidoeLightBox from "../../Feature/VideoLightBox";
import MessageLightBox from "../../Feature/MessageLightBox";
import { isAndroid, isIOS } from "react-device-detect";
import CircleCloseButton from "../../Feature/CircleCloseButton";
import { Trans } from "react-i18next";
import { config } from "../../../Config/config";
import peopleIcon from "../../../assets/img/people-24-px.svg";
import infoRedIcon from "../../../assets/img/info-red-24px.svg";
import FullScreenModal from "../../Feature/FullScreenModal";
import new_assignment from "../../../assets/img/new-assignment.svg";
import DeleteAssignmentMutation from "../../Feature/FullScreenModal/CreateEditAssignment/DeleteAssignmentMutation";
import warning_amber from "../../../assets/img/warning-amber-24-px.svg";

const ViewAssignmentDetailsQuery = graphql`
  query ViewAssignmentDetailsQuery(
    $ResourceAssignId: String!
    $FilterCurrentuser: Boolean
    $SchoolYearId: String!
    $SharingId: String!
  ) {
    resourceSharing(SharingId: $SharingId){
      id
                SharingId
                SharingDate
                Deleted
                SchoolYearId
                NotifyStudent
                EnterDate
                EnterBy
                LastUpdatedDate
                LastUpdateBy
                LastUpdatedBy
                RelatedResourcesConfig{
                  id
                  ResourceId
                  SchoolYearId
                  StudyGroupId
                  ConfigName
                  ConfigValue
                  EnterDate
                  EnterBy
                  LastUpdatedDate
                  LastUpdatedBy
                  Resource{
                    ResourceId
                    ResourceLangs {
                      Lang
                      ResourceName
                    }
                    BookSeriesId
                    BookSeries {
                      BookSeriesId
                      BookSeriesName {
                        Lang
                        BookSeriesName
                      }
                    }
                  }
                }

    }
    resourceAssignment(ResourceAssignId: $ResourceAssignId) {
      ResourceAssignId
      AssignmentName
      LastUpdateDate
      DueDate
      AssignmentDescription
      CreateByUser {
        UserId
        UserName
        UserDisplayName {
          UserName
          Lang
        }
      }
      CreateDate
      BookSeries {
        BookSeriesId
        BookSeriesName {
          Lang
          BookSeriesName
        }
      }

      Resources {
        ResourceAssignResId

        DisplaySequence
        Resource {
          IngestionId
          ResourceLangs {
            ResourceName
            Lang
          }
          ResourceType
          Linkage
          ResourceAccessUrl
          FileSource
          ResourceId
        }
        ResourceId
        ResourceAssignTargetStatus {
          UserId
          Status
        }
        ResourceAssignResAttrs {
          AttrName
          AttrValue
        }
      }

      AssignmentTargetStatus {
        Status
        StatusTime
      }
      AssignmentTargets(FilterCurrentUser: $FilterCurrentuser, SchoolYearId: $SchoolYearId) {
        ResourceAssignId
        UserId
        StudyGroupId
        StudyGroup {
          StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
            UserId
          }
          StudyGroupName {
            StudyGroupId
            StudyGroupName
            Lang
          }
        }
        User {
          UserDisplayName {
            UserName
            Lang
          }
          BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
            ClassNumber
            StudyGroup {
              BookSeries {
                BookSeriesId
              }
              StudyGroupName {
                Lang
                StudyGroupName
              }
            }
          }
        }
      }
    }
  }
`;

const ViewAssignmentDetailsGetEPSTokenQuery = graphql`
  query ViewAssignmentDetailsGetEPSTokenQuery($ResourceId: [String]!) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
      }
    }
  }
`;
var browserRef = null;

export default (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const notification_handbookData = useSelector(
    (state) => state.NotificationListReducer.handbookData
  );
  const sg_handbookData = useSelector(
    (state) => state.StudyGroupHandbookReducer.handbookData
  );
  const sg_handbook_totalCount = useSelector(
    (state) => state.StudyGroupHandbookReducer.TotalNumberOfRecord
  );

  const handbookData = useSelector(
    (state) => state.HandbookReducer.handbookData
  );
  const handbook_totalCount = useSelector(
    (state) => state.HandbookReducer.TotalNumberOfRecord
  );
  const resCenterAssignedRelatedData = useSelector(
    (state) => state.ResCenterAssignedRelatedReducer.resCenterAssignedRelated
  );
  const resCenterAssignedRelatedData_totalCount = useSelector(
    (state) => state.ResCenterAssignedRelatedReducer.TotalNumberOfRecord
  );
  const notification_handbookData_totalCount = useSelector(
    (state) => state.NotificationListReducer.TotalNumberOfRecord
  );

  const { t, resourceAssignId, onCloseButton, onDeleteButton } = props;

  const userData = useSelector((state) => state.UserReducer.user);
  const lang = useSelector((state) => state.SystemReducer.lang);

  const role = userData?.UserType;
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  //for assignment details
  const [assignmentData, setassignmentData] = useState(null);
  // const [resourcesList, setResourcesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [views, setviews] = useState("");
  const [numberOfUser, setnumberOfUser] = useState(0);
  const [resourceId, setresourceId] = useState(0);
  const [resourceName, setresourceName] = useState("");

  // for open resource
  const [fetchingEPSResourceToken, setfetchingEPSResourceToken] =
    useState(false);
  //edit mode
  const [isEdit, setisEdit] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isEditAssignModal, setisEditAssignModal] = useState(false);

  //handle open resources
  const [messageLightBoxOpen, setMessageLightBoxOpen] = useState(false);
  const [imageLightBoxOpen, setImageLightBoxOpen] = useState(null);
  const [videoLightBoxOpen, setVideoLightBoxOpen] = useState(null);

  useEffect(() => {
    // if (lang == "en-US") {
    //   moment.locale("en-US");
    // } else {
    //   moment.locale("zh-HK");
    // }
    fetchQueryGetAssignmentDetails();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setisModalOpen(true);
      setisEditAssignModal(true);
    } else {
      setisModalOpen(false);
      setisEditAssignModal(false);
    }
  }, [isEdit]);

  const fetchQueryGetAssignmentDetails = () => {
    setLoading(true);
    console.log("[fetchQueryGetAssignmentDetails] Start...");
    fetchAssignmentDetailsQuery().then((data) => {
      console.log(data);
      const { resourceAssignment } = data || {};
      let temp_resourceAssignment = JSON.parse(
        JSON.stringify(resourceAssignment)
      );
      temp_resourceAssignment.Resources =
        temp_resourceAssignment.Resources.sort((a, b) => {
          return a.DisplaySequence - b.DisplaySequence;
        });
      // temp_resourceAssignment.ResourcesList = sorted;
      setassignmentData(temp_resourceAssignment);
      console.log(temp_resourceAssignment, "temp_resourceAssignment");

      setLoading(false);
      renderTheViews(resourceAssignment);
    });
  };

  const onDelete = () => {
    var data = {
      ResourceAssignIds: [assignmentData.ResourceAssignId],
    };
    const callback = (data, error) => {
      console.log(data);
      if (data.deleteAssignment == true) {
        console.log("[Delect assignment success]");
        onCloseButton();
        //incase data is null
        let check_notification_handbook = notification_handbookData
          ? notification_handbookData.slice(0, 5).filter((item, index) => {
              return item.ResourceAssignId == assignmentData.ResourceAssignId;
            })
          : [];
        let check_sg_handbook = sg_handbookData
          ? sg_handbookData.filter((item, index) => {
              return item.ResourceAssignId == assignmentData.ResourceAssignId;
            })
          : [];
        let check_handbook = handbookData
          ? handbookData.filter((item, index) => {
              return item.ResourceAssignId == assignmentData.ResourceAssignId;
            })
          : [];
        let check_resCenterAssignedRelatedData = resCenterAssignedRelatedData
          ? resCenterAssignedRelatedData.filter((item, index) => {
              return (
                item.AssignmentTargets[0].ResourceAssignId ==
                assignmentData.ResourceAssignId
              );
            })
          : [];

        console.log(check_sg_handbook, check_handbook);
        if (check_notification_handbook != 0) {
          dispatch({
            type: "SET_NOTIFICATION_LIST_TOTAL_ITEMS",
            TotalNumberOfRecord: notification_handbookData_totalCount - 1,
          });
        }
        if (check_sg_handbook.length != 0) {
          dispatch({
            type: "SET_STUDYGROUP_HANDBOOK_TOTAL_ITEMS",
            TotalNumberOfRecord: sg_handbook_totalCount - 1,
          });
        }
        if (check_handbook.length != 0) {
          dispatch({
            type: "SET_HANDBOOK_TOTAL_ITEMS",
            TotalNumberOfRecord: handbook_totalCount - 1,
          });
        }
        if (check_resCenterAssignedRelatedData.length != 0) {
          dispatch({
            type: "SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS",
            TotalNumberOfRecord: resCenterAssignedRelatedData_totalCount - 1,
          });
        }

        toast(
          <div className="notify-box">
            <div className="notify-title">
              <div className="notify-icon">
                <img src={successIcon} alt="" />
              </div>
              <div className="notify-text">
                <p> {t("The assignment is deleted.")}</p>
              </div>
            </div>
          </div>,
          {
            position: "top-center",
          }
        );
      } else {
        console.log("[Delect assignment fail]");
        onCloseButton();
      }
    };
    DeleteAssignmentMutation(data, callback);
  };

  const renderAssignee = (targets) => {
    // var bookSeriesData = BookSeries;
   
    console.log(targets);
    var current_target_classes = targets
      .filter((item, index) => {
        return item.User == null;
      })
      .sort((a, b) => {
        return a.StudyGroup.StudyGroupName[
          a.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
        ].StudyGroupName.localeCompare(
          b.StudyGroup.StudyGroupName[
            b.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName
        );
      });
    var current_target_users = targets.filter((item, index) => {
      return item.StudyGroup == null;
    });
    var tempUsers = [],
      userObj = {};
    current_target_users.map((item, index) => {
      var matchGroup = item.User.BelongsToStudyGroup.map((i) => {
        return i.StudyGroup.BookSeries.BookSeriesId;
      }).indexOf(assignmentData.BookSeries.BookSeriesId);
      if (matchGroup != -1) {
        userObj = {
          ...item,
          UserGroup: item.User.BelongsToStudyGroup[matchGroup],
        };
        delete userObj.BelongsToStudyGroup;
      } else {
        userObj = { ...item };
      }
      tempUsers.push(userObj);
    });
    current_target_users = tempUsers;

    if (role == "T") {
      //For teacher

      return (
        <>
          <span className="notification-item-body">
            {current_target_classes.map((obj, i) => {
              if (i == 0) {
                return (
                  <Trans key={i} i18nKey="ClassTitle" count={1}>
                    {{
                      class:
                        obj.StudyGroup.StudyGroupName[
                          lang == obj.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                        ].StudyGroupName,
                    }}
                  </Trans>
                );
              } else {
                return (
                  <React.Fragment key={i}>
                    {", "}
                    <Trans i18nKey="ClassTitle" count={1} key={i}>
                      {{
                        class:
                          obj.StudyGroup.StudyGroupName[
                            lang == obj.StudyGroup.StudyGroupName[0].Lang
                              ? 0
                              : 1
                          ].StudyGroupName,
                      }}
                    </Trans>
                  </React.Fragment>
                );
              }
            })}
            {current_target_classes.length != 0 &&
              current_target_users.length != 0 &&
              ", "}
            {current_target_users.map((u, i) => {
              if (i == 0)
                return (
                  u.UserGroup.StudyGroup.StudyGroupName[
                    lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang
                      ? 0
                      : 1
                  ].StudyGroupName +
                  "-" +
                  u.UserGroup.ClassNumber +
                  " " +
                  u.User.UserDisplayName[
                    u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                  ].UserName
                );
              else
                return (
                  ", " +
                  u.UserGroup.StudyGroup.StudyGroupName[
                    lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang
                      ? 0
                      : 1
                  ].StudyGroupName +
                  "-" +
                  u.UserGroup.ClassNumber +
                  " " +
                  u.User.UserDisplayName[
                    u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                  ].UserName
                );
            })}
          </span>
        </>
      );
    }
    // return "";

    if (role == "S") {
      //For Student
      if (current_target_classes.length != 0) {
        //show class
        return (
          <Trans i18nKey="ClassTitle" count={1}>
            {{
              class:
                current_target_classes[0].StudyGroup.StudyGroupName[0]
                  .StudyGroupName,
            }}
          </Trans>
        );
      } else {
        //show user
 
        return (
          <>
            {current_target_users[0].UserGroup.StudyGroup.StudyGroupName[
              lang ==
              current_target_users[0].UserGroup.StudyGroup.StudyGroupName[0]
                .Lang
                ? 0
                : 1
            ].StudyGroupName +
              "-" +
              current_target_users[0].UserGroup.ClassNumber +
              " " +
              current_target_users[0].User.UserDisplayName[
                current_target_users[0].User.UserDisplayName[0].Lang == lang
                  ? 0
                  : 1
              ].UserName}
          </>
        );
      }
      // return "";
    }
  };

  const fetchAssignmentDetailsQuery = () => {
    const variables = {
      ResourceAssignId: resourceAssignId,
      SharingId:resourceAssignId,
      FilterCurrentuser: role == "T" ? false : true,
      SchoolYearId: selectedSchoolYear?.SchoolYearId,
    };

    return fetchQuery(environment, ViewAssignmentDetailsQuery, variables);
  };
  const renderTheViews = (obj) => {
    var userIds = [];

    obj.AssignmentTargets.map((record, index) => {
      if (!record.UserId) {
        if (!record.StudyGroup) return;
        if (!record.StudyGroup.StudyGroupUser) return;
        var _userids = record.StudyGroup.StudyGroupUser.map((i) => {
          return i.UserId;
        });
        userIds = [...userIds, ..._userids];
      } else {
        userIds.push(record.UserId);
      }
    });
    userIds = [...new Set(userIds)];
    if (userIds.indexOf(userData.UserId) != -1) {
      //it will show the views for teacher only, so no need to check is student or not
      userIds.splice(userIds.indexOf(userData.UserId), 1);
    }

    setviews(obj.AssignmentTargetStatus.length + " / " + userIds.length);
    setnumberOfUser(userIds.length);
  };

  const numberOfRes = (obj) => {
    if (obj.ResourceAssignTargetStatus.length == 0) {
      return "0" + " / " + numberOfUser;
    } else {
      return obj.ResourceAssignTargetStatus.length + " / " + numberOfUser;
    }
  };

  const getEPSToken = (resId, options, callback) => {
    setfetchingEPSResourceToken(true);
    var value = {
      ResourceId: resId,
    };
    try {
      fetchQuery(
        environment,
        ViewAssignmentDetailsGetEPSTokenQuery,
        value
      ).then((data) => {
        console.log(data);
        if (options.platform == "Web") {
          forWebAction(data, options, callback);
        }
        if (options.platform == "App") {
          // this.forAppAction(data, options, callback);
          callback(data);
        }
      });
    } catch (e) {
      setfetchingEPSResourceToken(false);
      setLoading(false);
      console.log(e);
      window.alert(e.message);
    }
  };
  const forWebAction = (data, options, callback) => {
    try {
      switch (options.restype) {
        case "IMAGE":
          if (options.actionType == "download") {
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            a.download = options.actionType;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            setfetchingEPSResourceToken(false);
          } else {
            console.log("open");
            callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
            // var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
          }
          break;
        default:
          if (options.actionType == "download") {
            console.log("download");
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            a.download = options.actionType;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            console.log(a);
            a.click();
            a.remove(); //afterwards we remove the element again
          } else {
            console.log("open");
            var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
            window.open(url, "_blank");
          }
          setfetchingEPSResourceToken(false);
          break;
      }
    } catch (e) {
      setfetchingEPSResourceToken(false);
      console.log("Featch EPS doanload error", e);
      window.alert(e.message);
    }
  };
  const handleVideoBox = (data) => {
    console.log(data);
    setVideoLightBoxOpen(
      data
        ? {
            type: data.type,
            videoPath: data.Linkage,
            isOpen: true,
          }
        : null
    );
  };

  const handleImageBox = (id, path) => {
    setImageLightBoxOpen(id ? { name: id, image: path } : id);
  };

  const checkDeviceToOpenOrDownload = (obj, action) => {
    const { Resource } = obj;
    console.log(obj);
    if (role == "S" && action == "open") {
      if (
        obj.ResourceAssignTargetStatus.map((i) => {
          return i.UserId;
        }).indexOf(userData.UserId) == -1
      ) {
        const variables = {
          ResourceAssignResId: obj.ResourceAssignResId,
        };
        ReadResourceAssignmentResourceMutation(variables, (data) => {
          if (data.readResourceAssignmentResource == "true") {
            console.log("success");

            const tempList = assignmentData.Resources;

            const dataSet = {
              UserId: userData.UserId,
              Status: "Read",
            };
            const filterdList = tempList.map((cobj) => {
              if (cobj.ResourceAssignResId === obj.ResourceAssignResId) {
                cobj.ResourceAssignTargetStatus.push(dataSet);
              }

              return cobj;
            });

            const finalSet = {
              ...assignmentData,
              Resources: filterdList,
            };
            console.log(finalSet);

            setassignmentData(finalSet);
          }
        });
      }
    }
    console.log(assignmentData.BookSeries);

    //if learnosity
    if (Resource.ResourceType == "LEARNOSITY") {
      gaTrack(
        "send",
        "event",
        "Assignment",
        "Open assessment",
        "Assessment:" +
          Resource.ResourceLangs[
            obj.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
          ].ResourceName +
          ", Resource ID:" +
          obj.Resource.IngestionId +
          ", Role:" +
          userData.UserType
      );
    } else {
      gaTrack(
        "send",
        "event",
        "Assignment",
        "Open resource",
        "Resource:" +
          Resource.ResourceLangs[
            obj.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
          ].ResourceName +
          ", Resource ID:" +
          obj.Resource.IngestionId +
          ", Book Series:" +
          assignmentData.BookSeries.BookSeriesName[
            assignmentData.BookSeries.BookSeriesName[0].Lang == lang ? 0 : 1
          ].BookSeriesName +
          ", Role:" +
          userData.UserType
      );
    }

    // for web only
    if (!window.cordova) {
      console.log(Resource);
      switch (Resource.ResourceType) {
        case "WEB_LINK":
        case "VIDEO":
        case "DOCUMENT":
        case "AUDIO":
        case "OTHER":
          if (Resource.FileSource == "eps") {
            // eps download or open
            getEPSToken(Resource.ResourceId, {
              actionType: action == "open" ? "open" : "download",
              restype: Resource.ResourceType,
              platform: "Web",
            });
          } else {
            window.open(Resource.ResourceAccessUrl, "_blank");
          }
          break;
        case "EBOOK4":
          window.open(Resource.ResourceAccessUrl, "_blank");
          break;
        case "IMAGE":
          if (Resource.FileSource == "eps") {
            // eps download or open
            getEPSToken(
              Resource.ResourceId,
              {
                actionType: action == "open" ? "open" : "download",
                restype: Resource.ResourceType,
                platform: "Web",
              },
              (path) => {
                //imagebox
                setImageLightBoxOpen({
                  id: Resource.ResourceId,
                  image: path,
                  obj: obj,
                });
                setfetchingEPSResourceToken(false);
              }
            );
          } else {
            window.open(Resource.ResourceAccessUrl, "_blank");
          }
          break;
        case "LEARNOSITY":
          const newURL =
            (window.location.pathname.indexOf("/user_contingency") != -1
              ? "/user_contingency"
              : "") +
            "/assignment/" +
            Resource.ResourceId +
            "&" +
            obj.ResourceAssignResId +
            "&" +
            assignmentData.ResourceAssignId +
            "/start";
          window.open(newURL, "_blank");

          break;
        case "YOUTUBE":
          handleVideoBox({ ...Resource, type: "youtube", obj: Resource });
          break;
        case "VIMEO":
          handleVideoBox({ ...Resource, type: "vimeo", obj: Resource });
          console.log({ ...Resource, type: "vimeo", obj: Resource });
          break;

        default:
          if (Resource.FileSource == "eps") {
            // eps download or open
            getEPSToken(Resource.ResourceId, {
              actionType: action == "open" ? "open" : "download",
              restype: Resource.ResourceType,
              platform: "Web",
            });
          } else {
            window.open(Resource.ResourceAccessUrl, "_blank");
          }
          break;
      }
    }
    // for app ios and android
    if (window.cordova) {
      if (isAndroid || isIOS) {
        switch (Resource.ResourceType) {
          case "EBOOK4":
            window.open(Resource.ResourceAccessUrl, "_system");
            break;

          case "IMAGE":
            if (Resource.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                Resource.ResourceId,
                {
                  actionType: action == "open" ? "open" : "download",
                  restype: Resource.ResourceType,
                  platform: "App",
                },
                (path) => {
                  //imagebox
                  if (action == "download") {
                    window.open(
                      path.resourceSearch.Resources[0].ResourceAccessUrl,
                      "_system"
                    );
                    setLoading(false);
                  } else {
                    setImageLightBoxOpen({
                      id: Resource.ResourceId,
                      image: path.resourceSearch.Resources[0].ResourceAccessUrl,
                      obj: obj,
                    });
                    setfetchingEPSResourceToken(false);
                  }
                }
              );
            } else {
              window.open(Resource.ResourceAccessUrl, "_system");
            }
            break;
          case "WEB_LINK":
            browserRef = window.cordova.InAppBrowser.open(
              Resource.Linkage,
              "_blank",
              `hidenavigationbuttons=no,location=no,hideurlbar=no,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=no,toolbar=yes,footer=yes,closebuttoncaption=${t(
                "Close"
              )}`
            );
            break;
          case "YOUTUBE":
            handleVideoBox({ ...Resource, type: "youtube", obj: Resource });
            break;
          case "VIMEO":
            handleVideoBox({ ...Resource, type: "vimeo", obj: Resource });
            break;
          case "DOCUMENT":
            if (Resource.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                Resource.ResourceId,
                {
                  platform: "App",
                },
                (path) => {
                  //success call back
                  window.open(
                    path.resourceSearch.Resources[0].ResourceAccessUrl,
                    "_system"
                  );
                }
              );
            } else {
              window.open(Resource.ResourceAccessUrl, "_system");
            }
            break;
          case "LEARNOSITY":
            handleAppLearnosity(obj);
            gaTrack(
              "send",
              "event",
              "Assignment",
              "Open assessment",
              "Assessment:" +
                Resource.ResourceLangs[
                  obj.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
                ].ResourceName +
                ", Resource ID:" +
                obj.ResourceId +
                ", Role:" +
                role
            );
            break;
          case "INTERACTIVE":
            const SessionId = localStorage.getItem("SessionId");
            const interactivePath =
              config.openResourcesApi +
              `&resid=${Resource.ResourceId}&sessionid=${SessionId}`;
            window.open(interactivePath, "_system");
            break;
          default:
            if (Resource.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                Resource.ResourceId,
                {
                  actionType: "open",
                  restype: Resource.ResourceType,
                  platform: "App",
                },
                (path) => {
                  //success call back
                  // this.AppDownloader(data);
                  window.open(
                    path.resourceSearch.Resources[0].ResourceAccessUrl,
                    "_system"
                  );
                }
              );
            } else {
              window.open(Resource.ResourceAccessUrl, "_system");
            }
            break;
        }
      }
    }
  };

  const handleAppLearnosity = (sitem) => {
    console.log("[Open assessment] App - Inapp browser");

    setLoading(true);

    var backText =
      localStorage.getItem("i18nextLng") == "en-US" ? "Close" : "關閉";
    var assessmentName =
      sitem.Resource.ResourceLangs[
        localStorage.getItem("i18nextLng") ==
        sitem.Resource.ResourceLangs[0].Lang
          ? 0
          : 1
      ].ResourceName.trim();

    var sessionId = localStorage.getItem("SessionId");

    const tokenJson = {
      SessionId: sessionId,
      from: "App",
      Lang: localStorage.getItem("i18nextLng"),
      userData: JSON.stringify(userData),
      userId: JSON.parse(localStorage.getItem("userData")).UserId,
      ResourceId: sitem.ResourceId,
    };

    var tokenJsonToString = JSON.stringify(tokenJson);
    console.log(tokenJson, assessmentName.trim());

    const pageContentUrl =
      config.InAppBrowserViewDetialsOpenAssessmentURL.start +
      "/" +
      sitem.Resource.ResourceId +
      "&" +
      sitem.ResourceAssignResId +
      "&" +
      assignmentData.ResourceAssignId +
      "/start";

    console.log(pageContentUrl, "open");
    browserRef = window.cordova.InAppBrowser.open(
      pageContentUrl,
      "_blank",
      "closebuttoncaption=" +
        backText +
        ",hidenavigationbuttons=yes,location=no,hideurlbar=yes,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=yes,toolbar=no"
    );
    browserRef.addEventListener("exit", function (e) {
      browserRef.close();
      browserRef = null;
    });
    browserRef.addEventListener("loadstop", function (e) {
      browserRef.executeScript({
        code:
          '\
          localStorage.setItem("from","' +
          tokenJson.from +
          '");\
          localStorage.setItem("SessionId","' +
          tokenJson.SessionId +
          '");\
            localStorage.setItem("i18nextLng-inapp","' +
          tokenJson.Lang +
          '");\
            localStorage.setItem("in-app-browser-data-userid","' +
          tokenJson.userId +
          '");\
        var body = document.querySelector("body");\
        var menuBar = document.createElement("div");\
        var back = document.createElement("div");\
        var title = document.createElement("div");\
        var callBackButton = document.createElement("div");\
        back.innerHTML = "' +
          backText +
          '";\
        title.innerHTML = "' +
          assessmentName +
          '";\
        menuBar.appendChild(back);\
        menuBar.appendChild(title);\
        menuBar.appendChild(callBackButton);\
        callBackButton.id = "app-message-receiver";\
        callBackButton.onclick= function(){\
          localStorage.setItem("close",true);\
          var leaveText = "";\
          if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
          {\
            leaveText = "Are you sure to leave?"\
          }\
          else {\
            leaveText = "確定離開?"\
          }\
          var r = confirm(leaveText);\
          if (r == true) {\
            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "openResource",data:localStorage.getItem("callbackdata")}));\
          } \
        };\
        back.onclick= function(){\
            localStorage.setItem("close",true);\
            var leaveText = "";\
            if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
            {\
              leaveText = "Are you sure to leave?"\
            }\
            else {\
              leaveText = "確定離開?"\
            }\
            var r = confirm(leaveText);\
            if (r == true) {\
                webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));\
            } \
        };\
        menuBar.classList.add("inapp-browser-menu");\
        back.classList.add("inapp-browser-menu-back");\
        title.classList.add("inapp-browser-menu-title");\
        body.appendChild(menuBar);\
        ',
      });

      browserRef.insertCSS({
        code: "\
              .inapp-browser-menu {\
                  position: fixed;\
                  top: 0;\
                  right: 0;\
                  left: 0;\
                  z-index: 10001;\
                  padding: 10px;\
                  height: 45px;\
                  background-color: #fff;\
                  border-bottom: 1px solid #dddddd;\
                  display: flex;\
                  justify-content: center;}\
              .inapp-browser-menu-back {\
                  position: absolute;\
                  left: 25px;}\
              .inapp-browser-menu-title{\
              }\
          ",
      });
      browserRef.show();
      setLoading(false);
    });

    browserRef.addEventListener("message", function (params) {
      if (params.data.action === "closeIAB") {
        browserRef.close();
        browserRef = null;
      }
      if (params.data.action === "openResource") {
        browserRef.close();
        browserRef = null;
        OpenFollowUpResource(params.data.data);
      }
    });
    setLoading(false);
  };

  const handleAppLearnosityCallback = (sitem) => {
    console.log("[Open assessment] App - Inapp browser");

    setLoading(true);

    var backText =
      localStorage.getItem("i18nextLng") == "en-US" ? "Close" : "關閉";
    var assessmentName =
      sitem.Resource.ResourceLangs[
        localStorage.getItem("i18nextLng") ==
        sitem.Resource.ResourceLangs[0].Lang
          ? 0
          : 1
      ].ResourceName.trim();

    var sessionId = localStorage.getItem("SessionId");

    const tokenJson = {
      SessionId: sessionId,
      from: "App",
      Lang: localStorage.getItem("i18nextLng"),
      userData: JSON.stringify(userData),
      userId: JSON.parse(localStorage.getItem("userData")).UserId,
      ResourceId: sitem.Resource.ResourceId,
    };

    var tokenJsonToString = JSON.stringify(tokenJson);
    console.log(tokenJson, assessmentName.trim());

    const pageContentUrl =
      config.InAppBrowserOpenAssessmentURL.start +
      "/" +
      sitem.Resource.ResourceId +
      "/start";

    console.log(pageContentUrl, "open");
    browserRef = window.cordova.InAppBrowser.open(
      pageContentUrl,
      "_blank",
      "closebuttoncaption=" +
        backText +
        ",hidenavigationbuttons=yes,location=no,hideurlbar=yes,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=yes,toolbar=no"
    );
    browserRef.addEventListener("exit", function (e) {
      browserRef.close();
      browserRef = null;
    });
    browserRef.addEventListener("loadstop", function (e) {
      browserRef.executeScript({
        code:
          '\
          localStorage.setItem("from","' +
          tokenJson.from +
          '");\
          localStorage.setItem("SessionId","' +
          tokenJson.SessionId +
          '");\
            localStorage.setItem("i18nextLng-inapp","' +
          tokenJson.Lang +
          '");\
            localStorage.setItem("in-app-browser-data-userid","' +
          tokenJson.userId +
          '");\
        var body = document.querySelector("body");\
        var menuBar = document.createElement("div");\
        var back = document.createElement("div");\
        var title = document.createElement("div");\
        var callBackButton = document.createElement("div");\
        back.innerHTML = "' +
          backText +
          '";\
        title.innerHTML = "' +
          assessmentName +
          '";\
        menuBar.appendChild(back);\
        menuBar.appendChild(title);\
        menuBar.appendChild(callBackButton);\
        callBackButton.id = "app-message-receiver";\
        callBackButton.onclick= function(){\
          localStorage.setItem("close",true);\
          var leaveText = "";\
          if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
          {\
            leaveText = "Are you sure to leave?"\
          }\
          else {\
            leaveText = "確定離開?"\
          }\
          var r = confirm(leaveText);\
          if (r == true) {\
            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "openResource",data:localStorage.getItem("callbackdata")}));\
          } \
        };\
        back.onclick= function(){\
            localStorage.setItem("close",true);\
            var leaveText = "";\
            if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
            {\
              leaveText = "Are you sure to leave?"\
            }\
            else {\
              leaveText = "確定離開?"\
            }\
            var r = confirm(leaveText);\
            if (r == true) {\
                webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));\
            } \
        };\
        menuBar.classList.add("inapp-browser-menu");\
        back.classList.add("inapp-browser-menu-back");\
        title.classList.add("inapp-browser-menu-title");\
        body.appendChild(menuBar);\
        ',
      });

      browserRef.insertCSS({
        code: "\
              .inapp-browser-menu {\
                  position: fixed;\
                  top: 0;\
                  right: 0;\
                  left: 0;\
                  z-index: 10001;\
                  padding: 10px;\
                  height: 45px;\
                  background-color: #fff;\
                  border-bottom: 1px solid #dddddd;\
                  display: flex;\
                  justify-content: center;}\
              .inapp-browser-menu-back {\
                  position: absolute;\
                  left: 25px;}\
              .inapp-browser-menu-title{\
              }\
          ",
      });
      browserRef.show();
      setLoading(false);
    });

    browserRef.addEventListener("message", function (params) {
      if (params.data.action === "closeIAB") {
        browserRef.close();
        browserRef = null;
      }
      if (params.data.action === "openResource") {
        browserRef.close();
        browserRef = null;
        OpenFollowUpResource(params.data.data);
      }
    });
    setLoading(false);
  };

  const OpenFollowUpResource = (data) => {
    console.log(data);
    var objectData = JSON.parse(data);
    objectData.ResourceId = objectData.Resource.ResourceId;
    console.log(objectData);
    setTimeout(() => {
      switch (objectData.Resource.ResourceType) {
        //open in in app, need to close first then open
        case "LEARNOSITY":
          handleAppLearnosityCallback(objectData);
          break;
          case "DOCUMENT":
            getEPSTokenForDownload(objectData.ResourceId, false, (callback) => {
              var FilePath =
                callback.resourceSearch.Resources[0].ResourceAccessUrl;
              window.open(FilePath, "_system");
            });
            break;
          case "INTERACTIVE":
            const SessionId = localStorage.getItem("SessionId");
            const interactivePath =
              config.openResourcesApi +
              `&resid=${objectData.ResourceId}&sessionid=${SessionId}`;
            window.open(interactivePath, "_system");
            break;
        case "EBOOK4":
          //call app
          break;
        case "WORKSHEET":
        case "TEACHER_GUIDE":
        case "IACTIVITY":

        case "OPE":
        case "WEB_LINK":
        case "PACK":
        case "AUDIO":
        case "VIDEO":
        default:
          window.open(objectData.Resource.ResourceAccessUrl, "_system");
          break;
      }
    }, 500);
  };


  const addLineBreaks = (val) =>
    val.split("\n").map((text, index) => (
      <div className="assign_content" key={`${text}-${index}`}>
        {text}
        <br />
      </div>
    ));

  const handleAssignmentIsView = (obj) => {
    if (
      obj.ResourceAssignTargetStatus.map((i) => {
        return i.UserId;
      }).indexOf(userData.UserId) != -1
    ) {
      return (
        <img
          src={new_assignment}
          style={{
            opacity: "0",
          }}
        ></img>
      );
    } else {
      return (
        <img
          src={new_assignment}
          style={{
            opacity: "1",
          }}
        ></img>
      );
    }
  };
  const checkIsAssesssment = () => {
    const findLearn = assignmentData.Resources.filter(
      (obj) => obj.Resource.ResourceType === "LEARNOSITY"
    );

    if (findLearn.length === 0 || findLearn === null) {
      return t(
        "This will remove the record of read, view and result in assessment."
      );
    } else {
      return (
        <div className="check_is_Assesssment">
          {t(
            "This will remove the record of read, view and result in assessment."
          )}
          <br />
          {
            <div className="check_is_Assesssment_warning">
              <img src={warning_amber} />{" "}
              {t(
                `Caution Result of assessment in this assignment will be deleted.`
              )}
            </div>
          }
        </div>
      );
    }
  };

  const getExpiryMessage = (role) => {
    if (role == "S") {
      return t("You can not open the resource, as the assignment is overdue");
    }
    return t("After the due date, student can not open resource");
  };
  const getExpiryResourceStyle = (dueDate, role) => {
    let classes = ["assign_resource_list", "expiry_resource"].join(" ");
    if (role == "S") {
      if (moment(moment().format("YYYY-MM-DD")).diff(dueDate) > 0) {
        return classes;
      }
    }
    return "assign_resource_list";
  };

  const checkIsRedo = (res) => {
    var findLearn = [];
    findLearn = res.filter((obj) => obj.Resource.ResourceType === "LEARNOSITY");
    console.log(findLearn);
    if (findLearn.length === 0 || findLearn === null) {
      return false;
    } else {
      return true;
    }
  };

  const checkRedoStatus = (res) => {
    console.log(res);
    var _temp = res;
    const resFilterAsses = _temp.filter(
      (obj) => obj.ResourceAssignResAttrs.length > 0
    );

    if (
      resFilterAsses[0].ResourceAssignResAttrs.map((i) => i.AttrValue).indexOf(
        "true"
      ) !== -1
    ) {
      return t("Oned");
    } else {
      return t("Offed");
    }
  };

  return (
    <div className="assign_form_details">
      {loading == true && <Loading />}
      <CircleCloseButton onCloseButton={onCloseButton} design="design_assign" />

      <div className="assig_assignee">{assignmentData?.AssignmentName}</div>
      <div className="two_sides">
        <div className="group_to_message">
          <div className="assign_seperate">
            <div className="assign_grey_title ">{t("To")}</div>
            <div className="assign_black_data ">
              {loading == false &&
                assignmentData &&
                renderAssignee(assignmentData?.AssignmentTargets)}
            </div>
          </div>

          {assignmentData?.AssignmentDescription != "" && (
            <div className="assign_seperate">
              <div className="assign_grey_title">{t("Message")} </div>
              <div className="assign_black_data">
                {assignmentData &&
                  addLineBreaks(assignmentData.AssignmentDescription)}
              </div>
            </div>
          )}

          {assignmentData?.DueDate != null &&
            moment(moment().format("YYYY-MM-DD")).diff(
              assignmentData?.DueDate
            ) > 0 && (
              <div className="assign_seperate">
                <div className="assign_expiry_msg">
                  <span className="info_icon">
                    <img src={infoRedIcon}></img>
                  </span>
                  {getExpiryMessage(role)}
                </div>
              </div>
            )}
        </div>
        <div className="group_date_views">
          <div
            className={
              role == "S" && assignmentData?.DueDate == null
                ? `seperate_line no_border `
                : `seperate_line`
            }
          ></div>
          <div className="grouped">
            {assignmentData?.DueDate != null && loading == false && (
              <div className="assign_seperate due_date">
                <div>
                  <div className="assign_grey_title "> {t("Due Date")} </div>
                  <div
                    className="assign_black_data"
                    style={GetDueDateStyle(assignmentData?.DueDate, true)}
                  >
                    {moment(assignmentData?.DueDate).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            )}
            {loading == false && checkIsRedo(assignmentData.Resources) && (
              <div className="assign_redo_assessment">
                {t("Redo")}
                <div className="redo_status">
                  {checkRedoStatus(assignmentData.Resources)}
                </div>
              </div>
            )}

            {role == "T" ? (
              <div className="assign_all_views">
                {t("Read")}
                <div
                  className="views_number"
                  onClick={() => {
                    setresourceId(0);
                    // let userTypeGA = JSON.parse(
                    //   localStorage.getItem("userData")
                    // ).UserType;
                    gaTrack(
                      "send",
                      "event",
                      "Assignment",
                      "View read status",
                      "Role:" + role
                    );
                    setMessageLightBoxOpen(!messageLightBoxOpen);
                  }}
                >
                  <span className="people_icn">
                    <img src={peopleIcon}></img>
                  </span>
                  <span className="views_number_sub">{views}</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* only exit in mobile field  */}
      {role == "T" ? (
        <div
          className="assign_all_views moblie"
          onClick={() => {
            setresourceId(0);
            // let userTypeGA = JSON.parse(localStorage.getItem("userData"))
            //   .UserType;
            gaTrack(
              "send",
              "event",
              "Assignment",
              "View read status",
              "Role:" + role
            );
            setMessageLightBoxOpen(!messageLightBoxOpen);
          }}
        >
          {t("Read")}
          <div className="views_number">
            <span className="people_icn">
              <img src={peopleIcon}></img>
            </span>
            <span className="views_number_sub">{views}</span>
          </div>
        </div>
      ) : (
        ""
      )}
      {loading == false && checkIsRedo(assignmentData.Resources) && (
        <div className="assign_redo_assessment moblie">
          {t("Redo")}
          <div className="redo_status">
            {checkRedoStatus(assignmentData.Resources)}
          </div>
        </div>
      )}
      {assignmentData?.DueDate != null && loading == false && (
        <div className="assign_seperate moblie">
          <div className="assign_grey_title "> {t("Due Date")} </div>
          <div
            className="assign_black_data"
            style={GetDueDateStyle(assignmentData?.DueDate)}
          >
            {moment(assignmentData?.DueDate).format("YYYY-MM-DD")}
          </div>
        </div>
      )}
      {/* only exit in mobile field  */}

      <div className="resource_view_between">
        <div className={"assign_resources"}>{t("Resource")}</div>
        {role == "T" ? (
          <div className="assign_resource_views">{t("View")}</div>
        ) : (
          ""
        )}
      </div>
      {assignmentData?.Resources.map((obj) => (
        <div
          className={getExpiryResourceStyle(assignmentData?.DueDate, role)}
          key={obj.ResourceAssignResId}
        >
          <div
            className="assign_resource_group"
            onClick={() => checkDeviceToOpenOrDownload(obj, "open")}
          >
            <div className="red_dot_students">
              {role == "S" && handleAssignmentIsView(obj)}
            </div>
            <div
              className="assign_resource_list_image"
              style={{
                backgroundImage: `url(${ResourceIconMapping(
                  obj.Resource.ResourceType,
                  "small",
                  obj.Resource.Linkage
                )})`,
              }}
            ></div>
            <div className="assign_resource_list_name">
              {
                obj.Resource.ResourceLangs[
                  obj.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
                ].ResourceName
              }
              {!window.cordova && obj.Resource.ResourceType == "LEARNOSITY" && (
                <i className="icon mdi mdi-open-in-new" />
              )}
            </div>
          </div>

          {role == "T" ? (
            <div
              className="assign_number_of_res"
              onClick={() => {
                setresourceId(obj.ResourceId);
                setresourceName(
                  obj.Resource.ResourceLangs[
                    obj.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
                  ].ResourceName
                );
                // let userTypeGA = JSON.parse(localStorage.getItem("userData"))
                //   .UserType;
                gaTrack(
                  "send",
                  "event",
                  "Assignment",
                  "View resource view status",
                  "Resource:" +
                    obj.Resource.ResourceLangs[
                      obj.Resource.ResourceLangs[0].Lang == lang ? 0 : 1
                    ].ResourceName +
                    ", Resource ID:" +
                    obj.Resource.IngestionId +
                    ", Book Series:" +
                    assignmentData.BookSeries.BookSeriesName[
                      assignmentData.BookSeries.BookSeriesName[0].Lang == lang
                        ? 0
                        : 1
                    ].BookSeriesName +
                    ", Role:" +
                    role
                );
                setMessageLightBoxOpen(!messageLightBoxOpen);
              }}
            >
              <span className="people_icn">
                <img src={peopleIcon}></img>
              </span>
              <span className="views_number_sub">{numberOfRes(obj)}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      ))}

      <div className="line-separater"></div>
      <div className="assign_from_title">{t("From")}</div>

      <div className="assign_create_user">
        {assignmentData &&
          assignmentData.CreateByUser?.UserDisplayName[
            lang == assignmentData.CreateByUser.UserDisplayName[0].Lang ? 0 : 1
          ].UserName}
        <br />
        {assignmentData &&
          t("Assign Date") +
            ": " +
            moment(assignmentData?.CreateDate).format("YYYY-MM-DD")}
        <br />
        {assignmentData &&
          !moment(assignmentData?.CreateDate).isSame(
            assignmentData?.LastUpdateDate
          ) &&
          t("Last modified") +
            ": " +
            moment(assignmentData?.LastUpdateDate).format("YYYY-MM-DD")}
      </div>
      {role == "T" && assignmentData?.CreateByUser?.UserId == userData?.UserId && (
        <div className="assign_action">
          <button
            className="assigment_delete"
            onClick={() => {
              gaTrack(
                "send",
                "event",
                "Assignment",
                "Click delete",
                "Book Series:" +
                  assignmentData.BookSeries.BookSeriesName[
                    assignmentData.BookSeries.BookSeriesName[0].Lang == lang
                      ? 0
                      : 1
                  ].BookSeriesName +
                  ", Role:" +
                  role
              );
              onDeleteButton({
                confirmBtnClassName: "btn btn-danger",
                cancelBtnClassName: "btn btn-secondary",
                title: t("Confirm to delete the assignment?"),
                message: () => checkIsAssesssment(),
                onClose: () => {
                  console.log("[ConfirmDelete]");
                  gaTrack(
                    "send",
                    "event",
                    "Delete assignment",
                    "Click confirm",
                    "Book Series:" +
                      assignmentData.BookSeries.BookSeriesName[
                        assignmentData.BookSeries.BookSeriesName[0].Lang == lang
                          ? 0
                          : 1
                      ].BookSeriesName +
                      ", Role:" +
                      role
                  );
                  onDelete();
                },
              });
            }}
          >
            <i className="icon mdi mdi-delete"></i>
            <span>{t("Delete")}</span>
          </button>
          <button
            className="assigment_edit btn btn-primary"
            onClick={() => {
              console.log("[Assignment View Details] Edit Mode start");
              gaTrack(
                "send",
                "event",
                "Assignment",
                "Click edit",
                "Book Series:" +
                  assignmentData.BookSeries.BookSeriesName[
                    assignmentData.BookSeries.BookSeriesName[0].Lang == lang
                      ? 0
                      : 1
                  ].BookSeriesName +
                  ", Role:" +
                  role
              );
              setisEdit(true);
            }}
          >
            <i className="icon mdi mdi-edit"></i>
            <span>{t("Edit")}</span>
          </button>
        </div>
      )}
      <VidoeLightBox
        handleVideoBox={handleVideoBox}
        VideoLightBoxOpenState={videoLightBoxOpen}
      />
      <ImageLightBox
        isOpen={imageLightBoxOpen ? true : false}
        handleImageBox={handleImageBox}
        ImageLightBoxOpenState={imageLightBoxOpen}
        downloadImage={() =>
          checkDeviceToOpenOrDownload(imageLightBoxOpen.obj, "download")
        }
      />

      <MessageLightBox
        isOpen={messageLightBoxOpen}
        type={"AssignmentStudents"}
        assignmentName={assignmentData?.AssignmentName}
        resourceId={resourceId}
        resourceAssignId={resourceAssignId}
        resourceName={resourceName}
        t={t}
        onClose={() => setMessageLightBoxOpen(!messageLightBoxOpen)}
      />
      <FullScreenModal
        type="editAssign"
        isModalOpen={isModalOpen}
        // assignStudyGroup={assignStudyGroup}
        isOpen={isEditAssignModal}
        onConfirm={() => {
          console.log("[View Details] done and close");
          setisEdit(false);
          console.log("[View Details] update");
          fetchQueryGetAssignmentDetails();
        }}
        onClose={() => {
          setisEdit(false);
          // setisEditAssignModal(false);
        }}
        // clearAssignment={() => {
        //   setisEditAssignModal(false), setselectAssign([]), setisAssign(false);
        // }}
        // assignmentList={resourcesList}
        // studyGroupId={studyGroupId}
        assignmentData={
          // resourceCenterStructureList.length > 0 &&
          // resourceCenterStructureList[0].book.BookSeriesName[
          //   resourceCenterStructureList[0].book.BookSeriesName[0].Lang === lang
          //     ? 0
          //     : 1
          // ].BookSeriesName
          assignmentData
        }
        t={t}
      />
    </div>
  );
};
