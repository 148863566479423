import React, { useState, useEffect } from "react";
import Loading from "../../../Loading/Loading";
import ViewableByStudentsTable from "./ViewableByStudentsTable";
import RelatedAssignmentTabel from "./RelatedAssignmentTabel";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "../../../../Environment";
import { Trans } from "react-i18next";
import "./index.scss";

const AssignedStudentsInfoGetNameQuery = graphql`
  query AssignedStudentsInfoGetNameQuery($StudyGroupId: String!) {
    studyGroup(StudyGroupId: $StudyGroupId) {
      StudyGroupName {
        StudyGroupName
        Lang
      }
    }
  }
`;

export default (props) => {
  const {
    onClose,
    t,
    studyGroupId,
    resData,
    resDataForGa,
    studyGroupData,
  } = props;
  const lang = localStorage.getItem("i18nextLng");
  const UserType = JSON.parse(localStorage.getItem("userData")).UserType;
  const [tabName, setTabName] = useState("view-tab");
  const [studyGroupName, setstudyGroupName] = useState("");
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const variables = {
      StudyGroupId: studyGroupId,
    };
    fetchQuery(environment, AssignedStudentsInfoGetNameQuery, variables).then(
      (data) => {
        const { studyGroup } = data || {};
        setstudyGroupName(
          studyGroup.StudyGroupName[
            studyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName
        );
      }
    );
  }, []);

  return (
    <div id="assigned-students-info">
      <div className="modal-header modal-header-colored icon-header">
        <h3 className="modal-title">
          <Trans i18nKey="resources name for students in class" count={2}>
            {{
              resName: resData.resName,
              studyGroupName: studyGroupName,
            }}
          </Trans>
        </h3>
      </div>
      <div className="modal-body border-top border-bottom">
        <div className="assigned-viewable-student">
          <ul>
            <li
              onClick={() => {
                setTabName(`view-tab`);
                gaTrack(
                  "send",
                  "event",
                  resDataForGa.comeFrom == "selfAssessment"
                    ? "Assessment assigned popup"
                    : "Resource assigned popup",
                  "Change tab",
                  "Tab:" +
                    "Assigned to students" +
                    (resDataForGa.comeFrom == "selfAssessment"
                      ? ", Assessment:"
                      : ", Resource:") +
                    resData.resName +
                    ", Resource ID:" +
                    resData.resIngestionId +
                    ", Book Series:" +
                    studyGroupData.BookSeries.BookSeriesName[0].BookSeriesName +
                    (resDataForGa.comeFrom == "selfAssessment"
                      ? ""
                      : ", View:" + resDataForGa.listBy) +
                    ", Resource Level:" +
                    resDataForGa.resLevel +
                    ", Resource Page:" +
                    resDataForGa.currentPage +
                    ", Class Level:" +
                    studyGroupData.Level.LevelName[0].LevelName +
                    ", Role:" +
                    UserType
                );
              }}
            >
              <div className={`${tabName == "view-tab" ? "active" : ""} `}>
                {t("Assigned to student")}
              </div>
            </li>
            <li
              onClick={() => {
                setTabName(`related-tab`);
                gaTrack(
                  "send",
                  "event",
                  resDataForGa.comeFrom == "selfAssessment"
                    ? "Assessment assigned popup"
                    : "Resource assigned popup",
                  "Change tab",
                  "Tab:" +
                    "Related Assignment" +
                    (resDataForGa.comeFrom == "selfAssessment"
                      ? ", Assessment:"
                      : ", Resource:") +
                    resData.resName +
                    ", Resource ID:" +
                    resData.resIngestionId +
                    ", Book Series:" +
                    studyGroupData.BookSeries.BookSeriesName[0].BookSeriesName +
                    (resDataForGa.comeFrom == "selfAssessment"
                      ? ""
                      : ", View:" + resDataForGa.listBy) +
                    ", Resource Level:" +
                    resDataForGa.resLevel +
                    ", Resource Page:" +
                    resDataForGa.currentPage +
                    ", Class Level:" +
                    studyGroupData.Level.LevelName[0].LevelName +
                    ", Role:" +
                    UserType
                );
              }}
            >
              <div className={`${tabName == "related-tab" ? "active" : ""} `}>
                {t("Related Assignment")}
              </div>
            </li>
          </ul>
        </div>

        <div className="assigned-scroll">
          {tabName === "view-tab" && (
            <div className="assigned-students-list">
              <ViewableByStudentsTable
                t={t}
                studyGroupId={studyGroupId}
                resData={resData}
              />
            </div>
          )}
          {tabName === "related-tab" && (
            <div className="related-assignment-list">
              <RelatedAssignmentTabel
                t={t}
                studyGroupId={studyGroupId}
                resData={resData}
                studyGroupData={studyGroupData}
                resDataForGa={resDataForGa}
                onClose={onClose}
              />
            </div>
          )}
        </div>
      </div>

      <div className="modal-footer">
        <div className="modal-btn-right">
          <button
            className="btn btn-primary"
            onClick={() => {
              onClose();
            }}
          >
            {t("Close")}
          </button>
        </div>
      </div>
      <button
        type="button"
        className="react-modal-close normal"
        title="Close"
        onClick={() => {
          onClose();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};
