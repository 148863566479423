import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import classes from "../../../../assets/img/class.svg";
import users from "../../../../assets/img/student.svg";

const SelectStudentGetStudyGroupClassQuery = graphql`
  query SelectStudentGetStudyGroupClassQuery(
    $SchoolCode: String!
    $SchoolYearId: String!
    $BookSeriesId: [String]
    $FilterOnboarded: Boolean!
    $Keywords: [String]
  ) {
    school(SchoolCode: $SchoolCode) {
      Schools {
        StudyGroups(
          SchoolYearId: $SchoolYearId
          BookSeriesId: $BookSeriesId
          FilterOnboarded: $FilterOnboarded
          Keyword: $Keywords
        ) {
          StudyGroupId
          StudyGroupName {
            Lang
            StudyGroupName
          }
        }
      }
    }
  }
`;

const SelectStudentGetStudyGroupUserQuery = graphql`
  query SelectStudentGetStudyGroupUserQuery(
    $SchoolCode: String!
    $SchoolYearId: String!
    $BookSeriesId: [String]
    $FilterOnboarded: Boolean!
    $UserType: UserTypeEnumType!
    $Keyword: String
  ) {
    school(SchoolCode: $SchoolCode) {
      Schools {
        StudyGroups(
          SchoolYearId: $SchoolYearId
          BookSeriesId: $BookSeriesId
          FilterOnboarded: $FilterOnboarded
        ) {
          StudyGroupId
          StudyGroupName {
            Lang
            StudyGroupName
          }
          StudyGroupUser(
            SchoolYearId: $SchoolYearId
            UserType: $UserType
            Keyword: $Keyword
          ) {
            UserId
            ClassNumber
            User {
              UserName
            }
            StudyGroupId
          }
        }
      }
    }
  }
`;
export default (props) => {
  const {
    assignmentList,
    studyGroupId,
    t,
    handleSelectChange,
    classListValue,
    bookSeriesName,
  } = props;

  const [isEnter, setisEnter] = useState(false);
  const UserType = JSON.parse(localStorage.getItem("userData")).UserType;
  const [inputData, setInputData] = useState("");
  const [selectStudyGroupName, setSelectStudyGroupName] = useState([]);
  const [selectUserName, setSelectUserName] = useState([]);
  const [selected, setSelected] = useState(classListValue);
  const schoolCode = JSON.parse(localStorage.getItem("userData")).School
    .SchoolCode;
  const selectedSchoolYear = JSON.parse(
    localStorage.getItem("selectedSchoolYear")
  );
  const lang = localStorage.getItem("i18nextLng");
  const bookSeries = assignmentList.reduce((acc, current) => {
    const x = acc.find((i) => i.BookSeriesId === current.BookSeriesId);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  console.log(bookSeries);

  const detectGaTrack = (which) => {
    gaTrack(
      "send",
      "event",
      "Create assignment",
      which,
      "Book Series:" + bookSeriesName + ", Role:" + UserType
    );
  };

  useEffect(() => {
    setSelected(classListValue);
  }, [classListValue]);

  useEffect(() => {
    if (isEnter == true) {
      if (selected !== null) {
        const lastItem = selected[selected.length - 1];
        const finded =
          lastItem.type == "users"
            ? "Add student (Keyboard)"
            : "Add class (Keyboard)";
        detectGaTrack(finded);
        setisEnter(false);
      }
    }
    if (selected == null || selected.length === 0) {
      setSelectStudyGroupName([]);
      setSelectUserName([]);
      gaTrack(
        "send",
        "event",
        "Create assignment",
        "Remove all assignee",
        "Book Series:" + bookSeriesName + ", Role:" + UserType
      );
    }
  }, [selected]);

  const compare = (a, b) => {
    const labelA = a.label;
    const labelB = b.label;

    let comparison = 0;
    if (labelA > labelB) {
      comparison = 1;
    } else if (labelA < labelB) {
      comparison = -1;
    }
    return comparison;
  };

  const fetchStudyGroupList = (input) => {
    const variables = {
      SchoolCode: schoolCode,
      StudyGroupId: studyGroupId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      FilterOnboarded: false,
      BookSeriesId: bookSeries[0].BookSeriesId,
      Keywords: [input],
    };

    fetchQuery(
      environment,
      SelectStudentGetStudyGroupClassQuery,
      variables
    ).then((data) => {
      console.log(data);

      const { school } = data || {};
      const studyGroupData = school.Schools[0].StudyGroups.reduce(
        (acc, current) => {
          const studyGroupName =
            current.StudyGroupName[
              current.StudyGroupName[0].Lang == lang ? 0 : 1
            ].StudyGroupName;
          acc.push({
            studyGroupId: current.StudyGroupId,
            label: studyGroupName,
            value: current.StudyGroupId,
            type: "studyGroup",
            image: classes,
          });
          return acc;
        },
        []
      );

      studyGroupData.sort(compare);
      const combined = selectStudyGroupName.concat(studyGroupData);
      const filterdId = combined.reduce((acc, current) => {
        const x = acc.find((i) => i.studyGroupId === current.studyGroupId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      console.log(filterdId);
      setSelectStudyGroupName(filterdId);
    });
  };

  const fetchUser = (input) => {
    console.log(input);
    const variables = {
      SchoolCode: schoolCode,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      FilterOnboarded: false,
      BookSeriesId: bookSeries[0].BookSeriesId,
      UserType: "S",
      Keyword: input,
    };
    fetchQuery(
      environment,
      SelectStudentGetStudyGroupUserQuery,
      variables
    ).then((data) => {
      console.log(data);

      const { school } = data || {};
      const fianlUser = school.Schools[0].StudyGroups.reduce((acc, current) => {
        const { StudyGroupUser } = current;
        if (StudyGroupUser === null) {
          return acc;
        } else {
          const studyGroupName =
            current.StudyGroupName[
              current.StudyGroupName[0].Lang == lang ? 0 : 1
            ].StudyGroupName;

          const userResuce = StudyGroupUser.reduce((result, data) => {
            result.push({
              userId: data.UserId,
              label:
                studyGroupName +
                "-" +
                data.ClassNumber +
                "-" +
                data.User.UserName,
              value: data.UserId,
              type: "users",
              image: users,
            });
            return result;
          }, []);
          console.log(userResuce);
          return acc.concat(userResuce);
        }
      }, []);
      const fianlUserSlice = fianlUser.slice(0, 50);
      fianlUserSlice.sort(compare);

      setSelectUserName(selectStudyGroupName.concat(fianlUserSlice));
    });
  };

  const handleInputChange = (newValue, e) => {
    if (newValue === "") {
      setSelectStudyGroupName([]);
      setSelectUserName([]);
    }

    if (newValue.length > 0 && newValue.length <= 1) {
      fetchStudyGroupList(newValue);
    } else if (newValue.length > 1) {
      fetchUser(newValue);
    }
    const inputValue = newValue.replace(/\W/g, "");
    setInputData(inputValue);
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px 0px",
            border: "none",
            width: "100%",
            backgroundColor: "transparent",
          }}
          onClick={() => {
            const whichName =
              props.data.type == "users" ? "Add student" : "Add class";
            gaTrack(
              "send",
              "event",
              "Create assignment",
              whichName,
              "Book Series:" + bookSeriesName + ", Role:" + UserType
            );
          }}
        >
          <img
            width="20"
            height="20"
            style={{ borderRadius: "50%", marginRight: "5px" }}
            src={props.data.image}
          />
          {props.data.label}
        </button>
      </components.Option>
    );
  };
  const MultiValueContainer = (props) => (
    <components.MultiValueContainer {...props}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          const whichName =
            props.data.type == "users" ? "Remove student" : "Remove class";
          gaTrack(
            "send",
            "event",
            "Create assignment",
            whichName,
            "Book Series:" + bookSeriesName + ", Role:" + UserType
          );
        }}
      >
        <img
          width="20"
          height="20"
          style={{ borderRadius: "50%" }}
          src={props.data.image}
        />
        {props.children}
      </div>
    </components.MultiValueContainer>
  );

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setisEnter(true);
    }
    if (e.keyCode === 8) {
      if (selected !== null) {
        const lastItem = selected[selected.length - 1];
        if (lastItem !== null) {
          const finded =
            lastItem.type == "users"
              ? "Remove student (Keyboard)"
              : "Remove class (Keyboard)";
          detectGaTrack(finded);
        }
      }
    }
  };

  return (
    <Select
      isMulti
      isSearchable
      value={selected}
      name="label"
      options={inputData.length === 1 ? selectStudyGroupName : selectUserName}
      className="basic-multi-select"
      classNamePrefix="select"
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      defaultValue={selectStudyGroupName}
      placeholder={t("Please type to search class or student…")}
      noOptionsMessage={() => {
        return <div>{t("No Option")}</div>;
      }}
      components={{
        Option,
        MultiValueContainer,
        IndicatorSeparator: null,
      }}
      onKeyDown={onKeyDown}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
    />
  );
};
