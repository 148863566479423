import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import icon from "../../assets/img/character.svg";

import CircularProgressChart from "../Chart/CircularProgressChart";
import CustomLineChart from "../Chart/CustomLineChart";
import "./index.scss";

// import goldMedal from "../../assets/img/gold-medal.svg";
// import silverMedal from "../../assets/img/silver.svg";
// import bronzeMedal from "../../assets/img/bronze.svg";
// import GetSingleResultBySessionId from "../../Services/api/GraphQL/Query/GetSingleResultBySessionId";
import Loading from "../Loading/Loading";
import { renderMedalImage } from "../../Services/Common/ScoreMedalMapping";

const index = ({ t, seletedHist, attempHistList }, props) => {
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [isLoading, setisLoading] = useState(true);
  const [ActiveResultData, setActiveResultData] = useState({});
  const [Last4TimesAttempts, setLast4TimesAttempts] = useState(null);

  const lineChart = useRef(null);

  useEffect(() => {
    if (seletedHist) {
      if (!Last4TimesAttempts) {
        let last4Attempts = [];
        attempHistList.reverse().map((obj, i) => {
          if (i < 4) {
            last4Attempts.push(obj);
          }
        });
        console.log(attempHistList, "Last4TimesAttempts");
        setLast4TimesAttempts(last4Attempts.reverse());
      }
      setActiveResultData(seletedHist);

      setisLoading(false);
    }
  }, [seletedHist]);

  // const getReportObjectByAttemptSessionId = async (id) => {
  //   let variables = {
  //     SessionId: id,
  //   };

  //   await GetSingleResultBySessionId(variables).then((data) => {
  //     console.log(data);

  //     if (!data.learnositySelfLearnResult) {
  //       console.log("Faill to get Data : learnositySelfLearnResult null");
  //     }
  //     setActiveResultData(data.learnositySelfLearnResult);
  //     setisLoading(false);
  //   });
  // };

  // const renderMedalImage = (score) => {
  //   let medal = goldMedal;
  //   if (score <= 79) medal = bronzeMedal;
  //   if (score <= 89 && score >= 80) medal = silverMedal;
  //   return <img src={medal}></img>;
  // };

  const renderLineChart = () => {
    const chartData = Last4TimesAttempts.map((o) => {
      return o.Score == "0"
        ? 0
        : Math.round((parseInt(o.Score) / parseInt(o.MaxScore)) * 100);
    });
    const chartLabel = Last4TimesAttempts.map((o) => {
      let arrayString = o.AttemptDate.split(" ");
      // arrayString[0] = arrayString[0].replace("-", "/");
      console.log(arrayString);
      return arrayString;
    });
    return (
      <CustomLineChart
        // width={lineChart.current.offsetWidth}
        data={{
          labels: chartLabel,
          datasets: [
            {
              fill: false,
              borderColor: "#14b2a6",
              data: chartData,
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0,0,0,0)",
                },
                ticks: {
                  padding: 10,
                  fontColor: "#003057",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 25,
                  padding: 10,
                  fontColor: "#003057",
                },
                gridLines: {
                  color: "rgba(0,0,0,0)",
                },
              },
            ],
          },
        }}
        // width={LineChartstate.width}
        // height={170}
      />
    );
  };

  return (
    <div className="my-score this">
      {/* top left image */}
      <div className="c-img">
        <img src={icon} />
        <div className="po-size">
          <div className="title">{t("My Score")}</div>
        </div>
      </div>

      {/* score card */}
      <div className="my-score-card">
        {/* my score chart */}
        <div className="my-score-chart">
          {isLoading ? (
            <div className="box-loading">
              <Loading type="inpage" />
            </div>
          ) : (
            <CircularProgressChart
              radius={100}
              progress={
                ActiveResultData.Score == "0"
                  ? 0
                  : Math.round(
                      (parseInt(ActiveResultData.Score) /
                        parseInt(ActiveResultData.MaxScore)) *
                        100
                    )
              }
              strokeWidth={10}
              strokeColor="#003057"
              trackStrokeColor="#67c5c9"
              strokeLinecap="round"
              trackStrokeWidth={10}
              counterClockwise
            >
              <div className="progress-chart-center">
                {renderMedalImage(
                  ActiveResultData.Score == null
                    ? ActiveResultData.Score
                    : ActiveResultData.Score == "0"
                    ? 0
                    : Math.round(
                        (parseInt(ActiveResultData.Score) /
                          parseInt(ActiveResultData.MaxScore)) *
                          100
                      )
                )}
                <div className="title">{t("This Attempt")}</div>
                <div className="progress">
                  {ActiveResultData.Score == null ? (
                    "---"
                  ) : (
                    <>
                      {ActiveResultData.Score == "0"
                        ? ActiveResultData.Score
                        : Math.round(
                            (parseInt(ActiveResultData.Score) /
                              parseInt(ActiveResultData.MaxScore)) *
                              100
                          )}
                      <span>%</span>
                    </>
                  )}
                </div>
              </div>
            </CircularProgressChart>
          )}
        </div>
        <div className="line-border"></div>
        {/* pass score chart */}
        <div className="pass-chart">
          <div className="line-title">{t("Previous Attempts")}</div>
          {isLoading ? (
            <div className="box-loading">
              <Loading type="inpage" />
            </div>
          ) : (
            <div className="chart" ref={lineChart}>
              {lineChart && renderLineChart()}
            </div>
          )}
        </div>
      </div>
      <div className="message">
        *{t("The score may have a 5-minute delay.​")}
      </div>
    </div>
  );
};

export default index;
