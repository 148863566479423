import React, { useState, useEffect, useRef } from "react";
import "./NotificationPopUpConfirmation.scss";
const NotificationPopUpConfirmation = (props) => {
  const {
    setShowConfirmDeletePopUp,
    showConfirmDeletePopUp,
    notificationResourceInfo,
    deleteNotification,
    t,
  } = props;

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_popupref.current !== null &&
        !notification_popupref.current.contains(e.target)
      ) {
        setShowConfirmDeletePopUp(false);
      }
    };
    if (showConfirmDeletePopUp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showConfirmDeletePopUp]);
  const notification_popupref = useRef(null);

  return (
    <div className="view_notification__confirmpopup-overlay view_notification__confirmpopup-overlay-confirmation">
      <div
        className="view_notification__confirmpopup"
        ref={notification_popupref}
      >
        <label
          className="view_notification__confirmpopup-close-popup"
          onClick={() => setShowConfirmDeletePopUp(false)}
        >
          &nbsp;
        </label>
        <h3 className="view_notification__confirmpopup-heading">
          {/* {t("Do you confirm to delete this notification")} */}
          {t("Are you sure you want to delete this notification")}
        </h3>
        <span className="view_notification__confirmpopup-border"></span>
        <div className="view_notification__confirmpopup-bottom">
          <button
            className="buttons btns view_notification__confirmpopup-cancel-btn"
            onClick={() => setShowConfirmDeletePopUp(false)}
          >
            {t("Cancel")}
          </button>
          <button
            className="buttons btns view_notification__confirmpopup-save-btn"
            onClick={() => {
              deleteNotification(notificationResourceInfo.SharingId);
              setShowConfirmDeletePopUp(false);
            }}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopUpConfirmation;
