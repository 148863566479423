import React, { Component } from "react";
import Modal from "react-modal";
//import Carousel, { Modal, ModalGateway } from "react-images";
import "./index.scss";

const customStyles = {
  content: {
    maxWidth: "100%",
    background: "transparent",
    border: "none",
    boxShadow: "none",
    height: "100%",
  },
};
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageLightBoxOpenState: props.ImageLightBoxOpenState,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Re-run the filter whenever the list array or filter text change.
    // Note we need to store prevPropsList and prevFilterText to detect changes.

    if (props.ImageLightBoxOpenState !== state.ImageLightBoxOpenState) {
      return {
        ImageLightBoxOpenState: props.ImageLightBoxOpenState,
      };
    } else {
      return null;
    }
  }

  toggleModal = () => {
    this.props.handleImageBox(null);
  };
  render() {
    const { ImageLightBoxOpenState, isOpen, downloadImage } = this.props;
    if (!ImageLightBoxOpenState) {
      return "";
    }
    console.log(ImageLightBoxOpenState, "ImageLightBoxOpenState 47");
    return (
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => this.toggleModal()}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div id="image" className="light-box">
          <div className="light-box-top-tool">
            {downloadImage != null && (
              <button onClick={() => downloadImage(ImageLightBoxOpenState.obj)}>
                <i className="icon mdi mdi-download " />
              </button>
            )}
            <button onClick={() => this.toggleModal()}>
              <i className="icon mdi mdi-close" />
            </button>
          </div>
          <div className="light-box-body image">
            <img src={ImageLightBoxOpenState.image} />
          </div>
        </div>
      </Modal>
    );
    /*
    return (
      <div>
        <ModalGateway>
          {isOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel views={[{src:ImageLightBoxOpenState.image}]} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
    */
  }
}

export default index;
