import * as types from "./types";

const initState = {
    askForInstall: false,
    askForInstallToolData: null,
};
const OpenInstallModalReducer = (state = initState, action) => {
    console.log("[OpenInstallModalReducer]", state, action);
    switch (action.type) {
        case types.OpenInstallModalReducer.SET_ASK_FOR_INSTALL:
            return {
                ...state,
                askForInstall: action.askForInstall,
            };
        case types.OpenInstallModalReducer.SET_ASK_FOR_INSTALL_TOOL_DATA:
            return {
                ...state,
                askForInstallToolData: action.askForInstallToolData,
            };
        default:
            return state;
    }
};

export default OpenInstallModalReducer;
