/**
 * @generated SignedSource<<bd21b21a107716ad7dd6d7da70b524a2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type StandAloneAssignmentQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  SchoolYearId: string,
|};
export type StandAloneAssignmentQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +ResourceType: ?string,
      +TagData: ?string,
      +RelatedId: ?string,
      +ResourceId: ?string,
      +IngestionId: ?string,
      +Book: ?{|
        +BookName: ?$ReadOnlyArray<?{|
          +BookName: ?string,
          +Lang: ?string,
        |}>,
        +Units: ?$ReadOnlyArray<?{|
          +UnitName: ?$ReadOnlyArray<?{|
            +UnitName: ?string,
            +Lang: ?string,
            +UnitTitle: ?string,
          |}>,
        |}>,
      |},
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +ResourceName: ?string,
      |}>,
      +AssignementInvloved: ?$ReadOnlyArray<?{|
        +AssignmentName: ?string,
        +ResourceAssignId: ?string,
        +BookSeriesId: ?string,
      |}>,
    |}>,
  |},
|};
export type StandAloneAssignmentQuery = {|
  variables: StandAloneAssignmentQuery$variables,
  response: StandAloneAssignmentQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "SchoolYearId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagData",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "unitLang",
  "kind": "LinkedField",
  "name": "UnitName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UnitName",
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UnitTitle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AssignmentName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAssignId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StandAloneAssignmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Book",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "unit",
                    "kind": "LinkedField",
                    "name": "Units",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "resourceAssignment",
                "kind": "LinkedField",
                "name": "AssignementInvloved",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StandAloneAssignmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Book",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "unit",
                    "kind": "LinkedField",
                    "name": "Units",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "resourceAssignment",
                "kind": "LinkedField",
                "name": "AssignementInvloved",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v2/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70d209fa442b61800233c4b083f69789",
    "id": null,
    "metadata": {},
    "name": "StandAloneAssignmentQuery",
    "operationKind": "query",
    "text": "query StandAloneAssignmentQuery(\n  $ResourceId: [String]!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      BookSeriesId\n      ResourceType\n      TagData\n      RelatedId\n      ResourceId\n      IngestionId\n      Book {\n        BookName {\n          BookName\n          Lang\n        }\n        Units {\n          UnitName {\n            UnitName\n            Lang\n            UnitTitle\n          }\n          id\n        }\n        id\n      }\n      ResourceLangs {\n        Lang\n        ResourceName\n      }\n      AssignementInvloved(SchoolYearId: $SchoolYearId) {\n        AssignmentName\n        ResourceAssignId\n        BookSeriesId\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4e1f24aee8ceac497e6ba86bcf07884c";

module.exports = ((node/*: any*/)/*: Query<
  StandAloneAssignmentQuery$variables,
  StandAloneAssignmentQuery$data,
>*/);
