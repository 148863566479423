import React, { useState } from 'react';
import "./PecLoginBanner.scss";
//import bannerLanding from "../../../assets/img/webbanner_V8.jpg";
import bannerCard from "../../../assets/img/boxbg1.png";
import bannerCard2 from "../../../assets/img/boxbg2.jpg";
import bannerCard3 from "../../../assets/img/boxbg3.jpg";
import { NavLink } from "react-router-dom";
import { config } from "../../../Config/config";
import { Visibility } from "semantic-ui-react";
import useGATracking from "../../../hooks/useGATracking";
import {isIOS} from "react-device-detect";

       
const PecLoginBanner = (props) => {
  const { t, logoCount,openpopupHandler } = props;

  const { sendGaEvent } = useGATracking();

  return (
    <React.Fragment>     
       
      <div className="banner-container">     
        {/* <img class="banner-image" src={!window.cordova?bannerLanding:"https://"+window.location.hostname+'/'+bannerLanding} alt="pearson-logo" /> */}
        <div class="banner-context">
        <div class="welcome-text">{t("Welcome to")}</div>
        <div class="p-eng-text">{t("Pearson English Connect")}</div>
        <div class="banner-btn-group">
        <div class="">
          {config.tempLogin == "yes" ? (
            <NavLink
              to="/login"
              
            >
              <button className="btn btn-warning banner-btn">{t("Login")}</button>
            </NavLink>
          ) : window.cordova ? (
            <NavLink
              to="/login"
               
            >
              <button className="btn btn-warning banner-btn">{t("Login")}</button>
            </NavLink>
          ) : (
            <button
              className="btn btn-warning banner-btn"
              onClick={() => {
                // i18n.changeLanguage("zh-HK");
                if (!window.cordova) {
                  window.gtag('event', 'Landing page - Login', {
                    'Position': 'Top bar'
                  });
                } else {
                  sendGaEvent('Landing page - Login', {
                    'Position': 'Top bar'
                  });
                }
                //gaTrack("send", "event", "Landing page", "Login", "Main area");
                var IESlang = "en-US";
                var IESlang2 = "en-US";
               // alert(localStorage.getItem("i18nextLng-inapp"));
                if (localStorage.getItem("i18nextLng")) {
                  IESlang2 = localStorage.getItem("i18nextLng");
                  if (localStorage.getItem("i18nextLng") != "en-US") {
                    IESlang = "zh_TW";
                  }
                }
                var dummyVar = "test";
                //alert(window.location.hostname);
                let currentHost = "https:%2F%2F" + window.location.hostname;
                let IESurl =
                  config.IESURL +
                  "?lang=" +
                  IESlang +
                  "&back_to=" +
                  config.IESBACKURL +
                  "%3Flang%3D" +
                  IESlang2 +
                  "%26back_to%3D" +
                  currentHost;
                if (window.cordova) {
                  window.open(IESurl, "_system");
                } else {
                 window.open(IESurl, "_self");
                }
              }}
            >
              {t("Login")}
            </button>
          )}
        </div>       
        <div class="banner-badge">
        <a href="https://apps.apple.com/us/app/pearson-english-connect-hk/id6448390998" target="_blank">
              <button type="button" class="cls_applestore" alt="Apple Store"></button>         
          </a>
        <a href='https://play.google.com/store/apps/details?id=com.pearson.pec' target="_blank">
        <button type="button" class="cls_playstore" alt="Play Store"></button>          
          </a>
        <a href="javascript:;" id="qr-code">
          <button onClick={openpopupHandler} class="cls_grcodestore" alt="QR Code"></button>        
          </a>
        </div>
        </div>
        </div>
      </div>
      <div className="banner-below-container">
        <div class="upper-text">
          {t("Pearson English Connect - the new way to teach English")}
        </div>
        <div class="below-text">
          {t(
            "A game-changing platform, giving you the ultimate flexibility to teach English effectively in the ‘new’ hybrid classroom in a highly communicative and interactive way."
          )}
        </div>
      </div>
      <div className="promise-container">
        <div class="promise-upper-text">{t("Our promise to you")}</div>
        <div class="promise-below-text">
          {t(
            "At Pearson, we are committed to providing the best customer experience and support possible for our community members."
          )}
        </div>
        <div class="promise-main-container">
          <div class="promise-card-container">
            <img
              class="banner-image-card"
              src={isIOS ? `https://${window.location.hostname}/assets/images/boxbg1.png` : bannerCard}
              alt="pearson-logo"
            />
            <div class="promise-card-1">{t("Easy-to-use platform")}</div>
            <div class="promise-card-border"></div>
            <div class="promise-card-2">
              {t(
                "Pearson English Connect has been designed with usability at its heart. It is easy to access and use."
              )}
            </div>
          </div>
          <div class="promise-card-container">
            <img
              class="banner-image-card"
              src={isIOS ? `https://${window.location.hostname}/assets/images/boxbg2.jpg` : bannerCard2}
              alt="pearson-logo"
            />
            <div class="promise-card-1">{t("Onboarding")}</div>
            <div class="promise-card-border"></div>
            <div class="promise-card-2">
              {t(
                "Our top priority is getting your teaching staff on to the platform with ease. Our customer service experts run onboarding webinars to set you up for success."
              )}
            </div>
          </div>
          <div class="promise-card-container"
          onClick={logoCount}>
            <img
              class="banner-image-card"
              src={isIOS ? `https://${window.location.hostname}/assets/images/boxbg3.jpg` : bannerCard3}
              alt="pearson-logo"
            />
            <div class="promise-card-1">{t("Ongoing tech support")}</div>
            <div class="promise-card-border"></div>
            <div class="promise-card-2">
              {t(
                "Our highly skilled technical support teams are on hand to provide you with immediate support."
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PecLoginBanner;
