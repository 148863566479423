import React, { useState, useEffect } from "react";
// import NavLink from "react-router-dom/NavLink";
import { useSelector, useDispatch } from "react-redux";
// import Footer from "../../../Footer/Footer";
// import environment from "../../../../Environment";
// import { fetchQuery, graphql } from "relay-runtime";
// import Loading from "../../Components/Loading/Loading";
import Loading from "../../Components/Loading/PEC/Loading";
import B2CAssessmentInfoQuery from "../../Services/api/GraphQL/Query/B2CAssessmentInfo";
import ActivityLayoutGetAssessmentName from "../../Services/api/GraphQL/Query/ActivityLayoutGetAssessmentName";
import NavBarPrizm from "../../Components/NavBar/Dashboard/PEC/NavBarPrizm";
import NavBarLearnosity from "../../Components/NavBar/Dashboard/PEC/NavBarLearnosity";
import DashboardStudyGroup from "../../Services/api/GraphQL/Query/DashboardStudyGroup";
import "./index.scss";
import B2Bcontent from "./B2Bcontent/B2Bcontent";
import B2Ccontent from "./B2Ccontent/B2Ccontent";
import PrizmContent from "./PrizmContent/PrizmContent";
//import useIdleTime from "../../hooks/useIdleTime";
const index = (props) => {
  const { B2C, t } = props;
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  //const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  const dispatch = useDispatch();
  const [bookData, setbookData] = useState([]);
  const [loadingAc, setloadingAc] = useState(true);
  const [resourceData, setresourceData] = useState([]);
  const [StudentClassId, setStudentClassId] = useState("");
  const userData = useSelector((state) => state.UserReducer.user);
  const [intervalId, setintervalId] = useState("");
  const role = userData?.UserType;
  //var timerIdleActivity = useIdleTime(120);
  const [isReadyToRender, setisReadyToRender] = useState(false);
  const [doPrizm, setdoPrizm] = useState(props.match.params.bookNunitId.split("&")[0] == "prizm" ? true : false);
  useEffect(() => {

    //console.log("working")
    if (role == "T") getAssessmentName();
    if (role == "S") {
      const variables = {
        UserId: userData.UserId,
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        FilterOnboarded: false,
      };
      DashboardStudyGroup(variables, role).then((data) => {
        const {
          user: { BelongsToStudyGroup = [{}] },
        } = data || {};

        let bookseries = [];
        BelongsToStudyGroup.map((obj, i) => {
          if (
            bookseries
              .map((b) => b.BookSeriesId)
              .indexOf(obj.StudyGroup.BookSeries.BookSeriesId) == -1
          ) {
            bookseries.push(obj.StudyGroup.BookSeries);
          }
        });
        let filterList = bookseries.filter(
          (o) => o.IsB2CBookSeries == false && o.IngestionId != null
        );
        console.log("suresh", filterList);
        let defaultGroup = BelongsToStudyGroup.filter((obj) => {
          return (
            (obj.StudyGroup.BookSeries.IngestionId != null ||
              obj.StudyGroup.BookSeries.IngestionId != "") &&
            obj.StudyGroup.BookSeries.BookSeriesId == props.match.params.id
          );
        });
        setStudentClassId(defaultGroup[0].StudyGroupId);
      });
    }

    

  
     
  },[])

  useEffect(()=> {
    
    console.log("Index",props.location.pathname.indexOf("/start"))
    if(props.match.params.bookNunitId.split("&")[0] == "prizm" && 
    props.location.pathname.indexOf("/start")!=-1 && role == "S"){
      const test = setInterval(() => {

        var time = new Date (); 
        console.log ("timer", time.getHours () + ":" + time.getMinutes () + ":" + time.getSeconds ()); 
         
        const iframeRef = document.getElementById('iframe_id').contentWindow;
        iframeRef.postMessage({
                type: 'SAVE_BTN', payload:
                    {saveBtn: true}
            }, '*');
    
        
    }, 30000);
    setintervalId(test)
    console.log("timeId", test)
    // return function stopTimer() {
    //   clearInterval(test)
    // }
    
    }

  },[])
  useEffect(() => {
    if (StudentClassId != "") getAssessmentName();
  }, [StudentClassId]);

  const reActivateAutoSave = () => {
        if(props.match.params.bookNunitId.split("&")[0] == "prizm" && 
        props.location.pathname.indexOf("/start")!=-1){
        const test = setInterval(() => {

        var time = new Date (); 
        console.log ("timer", time.getHours () + ":" + time.getMinutes () + ":" + time.getSeconds ()); 

        const iframeRef = document.getElementById('iframe_id').contentWindow;
        iframeRef.postMessage({
        type: 'SAVE_BTN', payload:
        {saveBtn: true}
        }, '*');


        }, 30000);
        setintervalId(test)
        console.log("timeId", test)
        // return function stopTimer() {
        //   clearInterval(test)
        // }

        }
    
  }
  const getAssessmentName = () => {
    const resId = props.match.params.resId;
    var localStudyGroupId = "";
    if (role == "S") {
      localStudyGroupId = StudentClassId;
    } else {
      localStudyGroupId = props.location.state?.group_id || "";
    }
    const variables = {
      UserId: userData.UserId,
      ResourceId: [resId],
      BookSeriesId: props.match.params.id,
      BookId: props.match.params.bookNunitId.split("&")[1],
      UnitId: props.match.params.bookNunitId.split("&")[1],
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      StudyGroupId: localStudyGroupId,
    };
    console.log("HERE IS variables:", variables)
     ActivityLayoutGetAssessmentName(variables, role).then((data) => {
      setbookData(data.user.AdoptedBookSeries[0]);
      setresourceData(data.resourceSearch.Resources?.at(0));
      setisReadyToRender(true);
      setloadingAc(false);
    });
  };

  if (loadingAc) {
    return <Loading />;
  }

  return (
    <>
    {doPrizm && (
        <NavBarPrizm
          {...props}
          prizmBookId={props.match.params.bookNunitId.split("&")[1]}
          resourceData={resourceData}
          bookData={bookData}
          intervalId={intervalId}
         reActivateAutoSave={reActivateAutoSave}
          //setShowLoader={setShowLoader}
        />
      )}
      {!doPrizm && (
        <NavBarLearnosity
          {...props}
          prizmBookId={props.match.params.bookNunitId.split("&")[1]}
          resourceData={resourceData}
          bookData={bookData}
        />
      )}
      <div className={`be-container-prizm ${props.location.pathname.toLowerCase().indexOf("/result") == -1 && !doPrizm ? "report-box-main-section":""}`}>
        {/* <div className=""> */}
        {doPrizm && (
          <PrizmContent
            {...props}
            resourceData={resourceData}
            bookData={bookData}
            prizmBookId={props.match.params.bookNunitId.split("&")[1]}
            isReadyToRender={isReadyToRender}
            intervalId={intervalId}
          />
        )}

        {!B2C && !doPrizm && (
          <B2Bcontent
            {...props}
            resourceData={resourceData}
            bookData={bookData}
            isReadyToRender={isReadyToRender}
          />
        )}
       
        {/* </div> */}
      </div>
      
      </>
    
    
  
  );
 
};

export default index;