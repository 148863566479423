import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import environment from "../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { config } from "../../../../../../Config/config";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
// import bell from "../../../../../../assets/img/suggestion-icon.svg";
// import image from "../../../../../../assets/img/file_type/image.svg";
// import video from "../../../../../../assets/img/file_type/video.svg";
// import pdf from "../../../../../../assets/img/file_type/pdf.svg";
// import exercise from "../../../../../../assets/img/file_type/exercise.svg";
// import openIcon from "../../../../../../assets/img/open-in-new-24-px.svg";
// import ppt from "../../../../../../assets/img/file_type/ppt.svg";
// import Loading from "../../../../../Loading/Loading";
import Loading from "../../../../../Loading/PEC/Loading";
// import ProgressBar from "../../../../../Feature/ProgressBar";
import Suggestion from "../Suggection";
import FocusSubfocus from "../FocusSubfocus";
import monent from "moment";
import { activityLayoutTab } from "../../../../../../Services/Common/common";
import useEncryption from "../../../../../../hooks/useEncryption";
import "../AllStudentResult/question.scss";
import "./index.scss";
// var reportScript;
var reportsApp;

const SingleResultStudentReportHistoryQuery = graphql`
  query SingleResultStudentReportHistoryQuery(
    $UserId: String!
    $ResourceId: String!
    $Lang: LangEnumType!
    $Credential: String
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(
        ResourceId: $ResourceId
        IncludeAssignmentRecord: true
      ) {
        IsCompleted
        AttemptDate
        LearnositySessionResult(Lang: $Lang, Credential: $Credential)
      }
    }
  }
`;

const SingleResultStudentReportQuery = graphql`
  query SingleResultStudentReportQuery(
    $SessionId: String!
    $Lang: LangEnumType!
    $Credential: String
  ) {
    learnositySelfLearnResult(SessionId: $SessionId) {
      AttemptSessionId
      AttemptDate
      IsCompleted
      CompletedDate
      UserId
      ResourceId
      LearnositySessionResult(Lang: $Lang, Credential: $Credential)
      UserDetail {
        UserDisplayName {
          Lang
          UserName
        }
      }
    }
  }
`;

const index = (props) => {
  const {
    B2C,
    t,
    resourceData,
    bookData,
    isInappBrowser,
    standalone,
    inappData,
    activity,
    selectedHistory,
    changeclick
  } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = localStorage.getItem("i18nextLng");
  console.log("locationsuresh", props.location.state)
  const [loading, setloading] = useState(true);
  const [HistoryArray, setHistoryArray] = useState(null);
  const [studentDisplayName, setstudentDisplayName] = useState(null);
  const [openState, setopenState] = useState(["Suggestion"]);
  const [itemTagsData, setitemTagsData] = useState(null);
  // const [reportLoading, setreportLoading] = useState(true);
  const [reportObject, setreportObject] = useState(null);
  const [reportData, setreportData] = useState(null);
  const [questionsData, setquestionsData] = useState(null);
  const [question, setquestion] = useState(null);
  const [correctCount, setcorrectCount] = useState("");
  const [studentScoreInfo, setstudentScoreInfo]=useState({"score":0, "maxScore":0})
  const [studentScoreInfoLoader, setstudentScoreInfoLoader]=useState(true)
  
  const [attemptDate, setattemptDate] = useState("");

  // const [selectedHistory, setselectedHistory] = useState(props.selectedHistory);
  const [defauleAttemptDate, setdefauleAttemptDate] = useState(null);
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre")?decryptToLocal(window.localStorage.getItem("userCre")):null;
  const studentResult = useSelector((state) => state.StudentResultReducer);
  console.log("HERE IS STUDENT RESULT:", studentResult)
  console.log("Properties", props.location.state?.classGroupName)
  // HistoryArray: null,
  // loading: true,
  // studentDisplayName: null,
  // openState: ["Suggestion"],
  // itemTagsData: null,
  // reportLoading: true,
  // reportData: null,
  // questionsData: null,

  useEffect(() => {
    console.log("[checkReportScriptIsReady]", props.action);

    switch (props.action) {
      case "in-page":
        if (selectedHistory != null) {
          //get object by  key
          getReportObjectByAttemptSessionId(selectedHistory.AttemptSessionId);
        }
        break;

      case "page":
        if (props.standalone) {
          if (props.location.state.session_id) {
            // const userType = localStorage.getItem("role");
            // if (!props.isInappBrowser) {
            //   window.document
            //     .getElementById("studygroup_back")
            //     ?.classList.add("dim-button");
            // }
            // window.document
            //   .getElementById("assessment_tab")
            //   ?.classList.add("dim-button");
            // window.document
            //   .getElementById("result_tab")
            //   ?.classList.add("dim-button");
            // if (userType == "T") {
            //   window.document
            //     .getElementById("answer_tab")
            //     ?.classList.add("dim-button");
            //   window.document
            //     .getElementById("back_result")
            //     ?.classList.add("dim-button");
            // }

            getReportBySessionId();
          } else {
            console.log("no session id", props.location);
          }
        } else {
          console.log("HERE IS USERID:",studentResult.user_id)
          console.log("HERE IS STATE:", studentResult)
          if (window.cordova && studentResult && studentResult.userId && studentResult.session_id) {
          //   if (!studentResult.userId && !props.location.state) {
          //     console.log("NOTED!!! no user id:", studentResult);
          //     return props.history.push(
          //         "/a/studygroup/" +
          //         props.match.params.id +
          //         "/assessment/" +
          //         props.match.params.bookNunitId +
          //         "/" +
          //         props.match.params.resId +
          //         "/result"
          //     );
          //   }
          //   if (!studentResult.session_id) {
          //     console.log("NOTED!!! no session id:", studentResult);
          //     return props.history.push(
          //         "/a/studygroup/" +
          //         props.match.params.id +
          //         "/assessment/" +
          //         props.match.params.bookNunitId +
          //         "/" +
          //         props.match.params.resId +
          //         "/result"
          //     );
          //   }
            console.log("HERE IS PASS THROUGH")
            window.document
                .getElementById("studygroup_back")
                ?.classList.add("dim-button");
            window.document
                .getElementById("assessment_tab")
                ?.classList.add("dim-button");
            window.document
                .getElementById("result_tab")
                ?.classList.add("dim-button");
            if (userType == "T") {
              window.document
                  .getElementById("answer_tab")
                  ?.classList.add("dim-button");
              window.document
                  .getElementById("back_result")
                  ?.classList.add("dim-button");
            }
            console.log("HERE IS PASS THROUGH 2")
            getReportBySessionId();
            console.log("HERE IS PASS THROUGH 3")
            return;
          }

          if (!props.match.params.userId) {
            console.log("NOTED!!!!no user id");
            return props.history.push(
              "/a/studygroup/" +
                props.match.params.id +
                "/assessment/" +
                props.match.params.bookNunitId +
                "/" +
                props.match.params.resId +
                "/result"
            );
          }
          if (!props.location.state) {
            console.log("NOTED!!!!no state");
            return props.history.push(
              "/a/studygroup/" +
                props.match.params.id +
                "/assessment/" +
                props.match.params.bookNunitId +
                "/" +
                props.match.params.resId +
                "/result"
            );
          }
          if (props.location.state.session_id) {
            // const userType = localStorage.getItem("role");
            if (!props.isInappBrowser) {
              window.document
                .getElementById("studygroup_back")
                ?.classList.add("dim-button");
            }
            window.document
              .getElementById("assessment_tab")
              ?.classList.add("dim-button");
            window.document
              .getElementById("result_tab")
              ?.classList.add("dim-button");
            if (userType == "T") {
              window.document
                .getElementById("answer_tab")
                ?.classList.add("dim-button");
              window.document
                .getElementById("back_result")
                ?.classList.add("dim-button");
            }

            getReportBySessionId();
          } else {
            console.log("no session id", props.location);
          }
        }

        break;
    }
  }, []);

  useEffect(() => {
    if(props.changeclick!=0){
      console.log(props, "componentWillReceiveProps");
      if (props.selectedHistory != null) {
       // setselectedHistory(props.selectedHistory);
        getReportObjectByAttemptSessionId(props.selectedHistory.AttemptSessionId);
      }
    }
   
  }, [props.changeclick]);

  // const handleOpenTab = (id) => {
  //   // const { openState } = this.state;
  //   let newOpenState = [...openState];
  //   console.log(openState.indexOf(id));
  //   if (openState.indexOf(id) == -1) {
  //     newOpenState.push(id);
  //     document.getElementById(id).style.height =
  //       document.getElementById(id).children.length *
  //         document.getElementById(id).children[0].offsetHeight +
  //       "px";
  //     // this.setState({
  //     //   openState: newOpenState,
  //     // });
  //     setopenState(newOpenState);
  //   } else {
  //     document.getElementById(id).style.height = 0;
  //     newOpenState.splice(openState.indexOf(id), 1);
  //     // this.setState({
  //     //   openState: newOpenState,
  //     // });
  //     setopenState(newOpenState);
  //   }
  // };

  const errorListener = (e) => {
    console.log(e);
    //window.location.reload();
    switch (e.code) {
      case 10025:
        break;
    }
  };

  const readyListener = () => {
    // const { B2C } = this.props;
    console.log("[window.LearnosityReports readyListener] Done");
    var report = reportsApp.getReport("session-detail");
    window.testing = report;
    // var that = this;

    report.on("load:data", (data) => {
      console.log("this item has been loaded with the following data: ", data);
      //get question data [start]
      var e = data.items;

      console.log(e);
      var newArray = [];
      e.map((item, index) => {
        newArray.push({
          name: item.item_reference,
          data: item,
        });
      });
      console.log(newArray);
      //get question data [end]
      setquestion(newArray);
      setreportData(data);
      // that.setState({
      //   // reportLoading: false,
      //   question: newArray,
      //   reportData: data,
      // });
      renderQuestionsBar(newArray);
      // const userType = localStorage.getItem("role");
      activityLayoutTab({ disable: false, isInappBrowser });
    });
  };

  const getReportObjectByAttemptSessionId = (id) => {
    let variables = {
      SessionId: id,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(environment, SingleResultStudentReportQuery, variables).then(
      (data) => {
        console.log(data);

        if (!data.learnositySelfLearnResult) {
          console.log("Faill to get Data : learnositySelfLearnResult null");
        }
        var objectData = JSON.parse(
          data.learnositySelfLearnResult.LearnositySessionResult
        );

        setstudentDisplayName(userData.UserDisplayName);
        setloading(false);
        // this.setState({
        //   loading: false,
        // });
        getReport(objectData);
      }
    );
  };

  const renderQuestionsBar = (question) => {
    let key = question;
    let questions = [];
    let correct = 0,
      max_score = 0;
    key.map((obj, index) => {
      console.log(obj, "obj");
      questions.push(obj.data);
      if (obj.data.score) {
        correct = correct + obj.data.score;
      }
      max_score = max_score + obj.data.max_score;
    });
    console.log("Suresh",correct,max_score)
    setstudentScoreInfo({"score":correct, "maxScore":max_score})
    correct = (correct / max_score) * 100;
    console.log(questions, "questions", correct);
    // this.setState({
    //   questionsData: questions,
    //   correctCount: Math.round(correct),
    // });
    
    setquestionsData(questions);
    setcorrectCount(Math.round(correct));
    setstudentScoreInfoLoader(false);
  };

  const renderListRow = (HistoryArray) => {
    // const { t } = this.props;
    const temp1 =  HistoryArray.map((item, index) => {
      if (index === 0) {
        return (
          <option key={index} value={index} selected={index === defauleAttemptDate && true}>
            {t("Last Attempt")}
          </option>
        );
      }
      return (
        <Moment
          key={index}
          value={index}
          element="option"
          selected={index === defauleAttemptDate && true}
          format="YYYY-MM-DD HH:mm"
        >
          {item.AttemptDate}
        </Moment>
      );
    });
    return temp1;
  };

  const getStudentHistoryList = (userid, resid, callback) => {
    let variables = {
      UserId: userid,
      ResourceId: resid,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(
      environment,
      SingleResultStudentReportHistoryQuery,
      variables
    ).then((data) => {
      
      var listArray = [...data.user.SelfLearnAttemptHistory];
      var templistArray = [...data.user.SelfLearnAttemptHistory];
      listArray.sort(function (a, b) {
        return new monent(a.AttemptDate) - new monent(b.AttemptDate);
      });
      var _defauleAttemptDate = listArray.length - 1;
      if (window.cordova) {
        if (studentResult.attemptDate) {
          const temF = listArray.find(el => el.AttemptDate === studentResult.attemptDate);
          listArray.reverse()
              .forEach((i,index) => {
                if(i.AttemptDate === studentResult.attemptDate){
                  _defauleAttemptDate = index
                }
              })
        } else {
          if (props.location.state) {
            if (props.location.state.attemptDate) {
              const temF = listArray.find(el => el.AttemptDate === props.location.state.attemptDate);
              listArray.reverse()
                  .forEach((i,index) => {
                    if(i.AttemptDate === props.location.state.attemptDate){
                      _defauleAttemptDate = index
                    }
                  })
            }
          }
        }
      } else {
        if (props.location.state) {
          if (props.location.state.attemptDate) {
            const temF = listArray.find(el => el.AttemptDate === props.location.state.attemptDate);
            listArray.reverse()
              .forEach((i,index) => {
              if(i.AttemptDate === props.location.state.attemptDate){
                _defauleAttemptDate = index
              }
              })
          }
        }
      }
      setHistoryArray(templistArray);
      setdefauleAttemptDate(_defauleAttemptDate);
      console.log(listArray, _defauleAttemptDate);
      callback();
    });
  };

  const getReportBySessionId = () => {
    let variables;

    variables = {
      SessionId: props.location.state? props.location.state.session_id : studentResult.session_id,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(environment, SingleResultStudentReportQuery, variables).then(
      (data) => {
        config.mode == "dev" && console.log(data);
        setreportObject(
          JSON.parse(data.learnositySelfLearnResult.LearnositySessionResult)
        );
        setattemptDate(data.learnositySelfLearnResult.AttemptDate);
        setstudentDisplayName(
          data.learnositySelfLearnResult.UserDetail.UserDisplayName
        );
        setloading(false);
        console.log(
          JSON.parse(data.learnositySelfLearnResult.LearnositySessionResult)
        );
        getStudentHistoryList(
          data.learnositySelfLearnResult.UserId,
          data.learnositySelfLearnResult.ResourceId,
          () => {
            getReport(
              JSON.parse(data.learnositySelfLearnResult.LearnositySessionResult)
            );
          }
        );
      }
    );
  };
  const getScoreAndAverageClass = (scoreVal, scoreType) => {

    if (scoreVal === "") {

      return "";

    } else {
      if (scoreType == "grade") {
        if (scoreVal <= 20) {
          return "count-red";
        } else {
          return "count-green";
        }
      }

      else if (scoreType == "score") {

        if (scoreVal <= 20) {

          return "student-progressbar-lowest";

        } else if (scoreVal > 20 && scoreVal <= 40) {

          return "student-progressbar-low";

        } else if (scoreVal > 40 && scoreVal <= 60) {

          return "student-progressbar-medium";

        } else if (scoreVal > 60 && scoreVal <= 80) {

          return "student-progressbar-high";

        } else if (scoreVal > 80) {

          return "student-progressbar-highest";

        } else {

          return "";

        }

      } else if (scoreType == "large") {

        if (scoreVal <= 20) {

          return "student-progressbar-highest1-lowest";

        } else if (scoreVal > 20 && scoreVal <= 40) {

          return "student-progressbar-highest1-low";

        } else if (scoreVal > 40 && scoreVal <= 60) {

          return "student-progressbar-highest1-medium";

        } else if (scoreVal > 60 && scoreVal <= 80) {

          return "student-progressbar-highest1-high";

        } else if (scoreVal > 80) {

          return "student-progressbar-highest1-highest";

        } else {

          return "";

        }

      } else if (scoreType == "average") {

        if (scoreVal <= 20) {

          return "progress-linebar-lowest";

        } else if (scoreVal > 20 && scoreVal <= 40) {

          return "progress-linebar-low";

        } else if (scoreVal > 40 && scoreVal <= 60) {

          return "progress-linebar-medium";

        } else if (scoreVal > 60 && scoreVal <= 80) {

          return "progress-linebar-high";

        } else if (scoreVal > 80) {

          return "progress-linebar-highest";

        } else {

          return "";

        }

      } else if (scoreType == "defaultAverageTop") {

        return "default-progress-linebar";

      } else if (scoreType == "defaultAverageBottom") {

        return "default-progress-linebar1";

      } else if (scoreType == "largescroll") {

        if (scoreVal <= 20) {

          return "large-progressbar-lowest";

        } else if (scoreVal > 20 && scoreVal <= 40) {

          return "large-progressbar-low";

        } else if (scoreVal > 40 && scoreVal <= 60) {

          return "large-progressbar-medium";

        } else if (scoreVal > 60 && scoreVal <= 80) {

          return "large-progressbar-high";

        } else if (scoreVal > 80) {

          return "large-progressbar-highest";

        } else {

          return "";

        }

      }

    }

  };
  const getScoreAndAverageClassbk = (scoreVal, scoreType) => {
    if (scoreVal === "") {
      return "";
    } else {
      if (scoreType == "grade") {
        if (scoreVal <= 20) {
          return "count-red";
        } else {
          return "count-green";
        }
      }
      else if (scoreType == "score") {
        if (scoreVal <= 20) {
          return "student-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "student-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "student-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "student-progressbar-high";
        } else if (scoreVal > 80) {
          return "student-progressbar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "average") {
        if (scoreVal <= 20) {
          return "progress-linebar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "progress-linebar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "progress-linebar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "progress-linebar-high";
        } else if (scoreVal > 80) {
          return "progress-linebar-highest";
        } else {
          return "";
        }
      }
    }
  };
  const getReport = (object) => {
    // this.setState({
    //   reportData: null,
    //   questionsData: null,
    // });
    setreportData(null);
    setquestionsData(null);
    var callbacks = {
      errorListener: errorListener,
      readyListener: readyListener,
    };
    var checkReportScriptIsReady = setInterval(() => {
      if (window.LearnosityReports) {
        console.log(
          "[checkReportScriptIsReady]  Find window.LearnosityReportssss"
        );
        clearInterval(checkReportScriptIsReady);
        if (object) {
          console.log(object);
          // this.setState({
          //   itemTagsData: object.tagData,
          // });
          setitemTagsData(object.tagData);
          console.log("suresh",object.reportObj)
          reportsApp = window.LearnosityReports.init(
            object.reportObj,
            callbacks
          );
        } else {
          console.log("suresh",
            JSON.parse(props.selectedHistory.LearnositySessionResult)
          );
          setitemTagsData(
            JSON.parse(props.selectedHistory.LearnositySessionResult).tagData
          );
          // this.setState({
          //   itemTagsData: JSON.parse(
          //     this.props.selectedHistory.LearnositySessionResult
          //   ).tagData,
          // });

          reportsApp = window.LearnosityReports.init(
            JSON.parse(props.selectedHistory.LearnositySessionResult).reportObj,
            callbacks
          );
        }
      }
    }, 1000);
  };

  return (
    <>
      <div
        className={`report-box report-box-main-section learnetic-section`}
      >
        {props.location.state?.questNumber > 0  && !loading &&  (
        <div className="result-menu-QP" style={{ marginTop: "-10px" }}> 
        
        <span className="result_text" onClick={() => { 
          
          // console.log("location", props.location.state)
          // let backURLQP = props.location.pathname.slice(0, props.location.pathname.lastIndexOf('/'));
          
          // console.log("backURLQp", backURLQP)
          
          //  props.history.push({pathname:backURLQP+"/q/"+props.location.state?.questNumber,
          //  state: { selectOptions: props.location.state?.passData, questions: props.location.state?.questionslength }});
          dispatch({
            type: "SET_SELECTED_TYPE",
            selectOptions: {
                selectedStudyGroupId:props.location.state.classGroup,
                selectedStudyGroupName:props.location.state?.qpClassGroup,
                selectedReportType: "",
                selectedAssessType: "",
                setselectedResAssignId:""
            },
          });
          if (window.cordova) {
            props.history.push(window.location.hash.substring(1, window.location.hash.lastIndexOf('/')));
            return;
          }

          props.history.push(props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1));
     
    
 
          
        } }> Result </span>
      
        <div className="gap">
          <i className="icon mdi mdi-chevron-right"></i>
        </div>
        {/* <div className="active">{"Q" + questNumber}</div> */}

        {props.location.state?.questNumber > 0 && (
          <div className="qno_text" onClick={() => { 
          
            console.log("location", props.location.state)
            let backURLQP = props.location.pathname.slice(0, props.location.pathname.lastIndexOf('/'));
          
            console.log("backURLQp", backURLQP)
             
             props.history.push({pathname:backURLQP+"/q/"+props.location.state?.questNumber,
             state: { selectOptions: props.location.state?.passData, questions: props.location.state?.questionslength }});
  
             //props.history.push(backURLQP+"/q/"+props.location.state?.questNumber);
            
          } }>{"Item " + props.location.state.questNumber}</div>
        )}
        
        <div className="gap">
          <i className="icon mdi mdi-chevron-right"></i>
        </div>
        <div className="active">{studentDisplayName &&
          studentDisplayName[
            lang == studentDisplayName[0].Lang ? 0 : 1
          ].UserName.trim().length == 0
            ? studentDisplayName[0].UserName
            : studentDisplayName[lang == studentDisplayName[0].Lang ? 0 : 1]
                .UserName}</div>
        
        
        
        
        
        
        
        
       </div> )}
        
        
       {props.location.state?.questNumber > 0 && userType == "T"  && !loading ? (
        <div className="backText"><span  onClick={() => {
          if (window.cordova) {
            props.history.goBack();
          } else {
            console.log("location", props.location.state)
            let backURLQP = props.location.pathname.slice(0, props.location.pathname.lastIndexOf('/'));
            
            console.log("backURLQp", backURLQP)
            
             props.history.push({pathname:backURLQP+"/q/"+props.location.state?.questNumber,
             state: { selectOptions: props.location.state?.passData, questions: props.location.state?.questionslength }});
  
          
          }
        } } className="back_btn_qp"> {"<"} Back </span></div>
       ) : ( userType == "T" && <div className="backText"><span  onClick={() => {
        if (window.cordova) {
          props.history.goBack();
        } else {
          console.log("My", props.location.state)
          dispatch({
            type: "SET_SELECTED_TYPE",
            selectOptions: {
                selectedStudyGroupId:props.location.state.classGroup,
                selectedStudyGroupName:props.location.state.classGroupName,
                selectedReportType: "",
                selectedAssessType: "",
                setselectedResAssignId:""
            },
          });
          props.history.push(props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1));
        }
      } } className="back_btn_qp"> {"<"} Back </span></div>)}
      
      
      
      
      
      {!loading && (
      <h3 className="student_name_heading">
        {studentDisplayName &&
            studentDisplayName[
              lang == studentDisplayName[0].Lang ? 0 : 1
            ].UserName.trim().length == 0
              ? studentDisplayName[0].UserName
              : studentDisplayName[lang == studentDisplayName[0].Lang ? 0 : 1]
                  .UserName}
          
          </h3>)}
        {/* <h5 className="student_class_name">{`${selectedStudyGroupName.includes('Class') ? '' : t("Class")}  ${selectedStudyGroupName}`}</h5> */}
        <div className="student_top_section">
         
         <div className="student_top_section_left">
           {!loading && correctCount !== "" ? (
             <>
           <label className="student_top_section_left_score">{t("Score")}</label>
           <div className="student_top_section_progressbar">
             <div
               key={'qwe11'}
               className={`student-progressbar-highest1 ${getScoreAndAverageClass(correctCount, "large")}`}
               role="progressbar"
               aria-valuenow={correctCount}
               aria-valuemin="0"
               aria-valuemax="100"
               style={{ "--value": correctCount }}
             ></div>
           </div></> ):(<Loading type="normal" />)}
         </div>
         <div className="student_top_section_right">
          {!loading &&questionsData ? (
            <div className="student_top_section_right_question">
            {questionsData &&
            questionsData.map((obj, index) => {
            return <div className="student_top_section_right_question_section">
            <div class="student_top_section_box">  <label className="student_top_section_question_no">{t("Item")+ " " + obj.item_number}</label></div>
            <div class="student_top_section_box"><div className={`dot ${
            obj.attempted == false
            ? "question_hyphen_arrow"
            : obj.score == obj.max_score
            ? "question_tick_arrow"
            : obj.score
            ? "question_half_tick_arrow"
            : "question_cross_tick_arrow"
            }`}></div></div></div>
            })}
            </div>) :
            (<Loading type="normal" />)}
          </div>
          <div className="student_bottom_section_updated">
<div className="student_bottom_section_updated_details">
<label className="student_bottom_section_updated_name">{t("Full score of exercise")}</label>
{!loading && !studentScoreInfoLoader ?(

<h3 className="student_bottom_section_updated_count count-black">{studentScoreInfo.maxScore}</h3> ):(<Loading type="normal" />)}
</div>
<div className="student_bottom_section_updated_details">
<label className="student_bottom_section_updated_name">{t("Your Score")}</label>
{!loading && !studentScoreInfoLoader? (

<h3 className={`student_bottom_section_updated_count ${getScoreAndAverageClass(correctCount, "grade")}`}>{studentScoreInfo.score}</h3> ):(<Loading type="normal" />)}

</div>
</div>

</div>

 
<div className="type-row">
            {/* {this.renderDummyData()} */}
            {question != null && itemTagsData != null && (
              <FocusSubfocus
                {...props}
                openState={openState}
                questions={question}
                tagsData={itemTagsData}
                resourceData={resourceData}
                changeclick={changeclick}
                // studyGroupData={studyGroupData}
                // B2C={B2C}
                // standalone={standalone}
                // resData={resData}
                // handleOpenTab={this.handleOpenTab}
              />
            )}
              <Suggestion
              {...props}
              bookData={bookData}
              reportData={reportData}
              itemTagsData={itemTagsData}
              resourceData={resourceData}
              inappData={inappData}
              activity={activity}
              action="teacher_view_student"
              />
              <br />
            <div className="all-result-row parent__container" style={{ marginLeft: "10px", marginRight: "10px" }}>
            <section className="suggestion__container">
            <details open className="suggestion">
            <summary className="suggestion__label">
            {t("Exercise")}
            </summary>

            <div id="session-detail"></div>
            </details>
            </section>

            </div>
          </div>
        </div>
    
    </>
  );
};

export default index;

// class _index extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       HistoryArray: null,
//       loading: true,
//       studentDisplayName: null,
//       openState: ["Suggestion"],
//       itemTagsData: null,
//       reportLoading: true,
//       reportData: null,
//       questionsData: null,
//     };
//   }

//   handleOpenTab = (id) => {
//     const { openState } = this.state;
//     var newOpenState = [...openState];
//     console.log(openState.indexOf(id));
//     if (openState.indexOf(id) == -1) {
//       newOpenState.push(id);
//       document.getElementById(id).style.height =
//         document.getElementById(id).children.length *
//           document.getElementById(id).children[0].offsetHeight +
//         "px";
//       this.setState({
//         openState: newOpenState,
//       });
//     } else {
//       document.getElementById(id).style.height = 0;
//       newOpenState.splice(openState.indexOf(id), 1);
//       this.setState({
//         openState: newOpenState,
//       });
//     }
//   };

//   componentWillMount() {}

//   componentWillUnmount() {}

//   errorListener = (e) => {
//     console.log(e);
//     //window.location.reload();
//     switch (e.code) {
//       case 10025:
//         break;
//     }
//   };

//   readyListener = () => {
//     const { B2C } = this.props;
//     console.log("[window.LearnosityReports readyListener] Done");
//     var report = reportsApp.getReport("session-detail");
//     window.testing = report;
//     var that = this;

//     report.on("load:data", (data) => {
//       console.log("this item has been loaded with the following data: ", data);
//       //get question data [start]
//       var e = data.items;

//       console.log(e);
//       var newArray = [];
//       e.map((item, index) => {
//         newArray.push({
//           name: item.item_reference,
//           data: item,
//         });
//       });
//       console.log(newArray);
//       //get question data [end]

//       that.setState({
//         // reportLoading: false,
//         question: newArray,
//         reportData: data,
//       });
//       that.renderQuestionsBar(newArray);
//       const userType = localStorage.getItem("role");
//       if (!that.props.isInappBrowser) {
//         window.document
//           .getElementById("studygroup_back")
//           ?.classList.remove("dim-button");
//       }
//       window.document
//         .getElementById("assessment_tab")
//         ?.classList.remove("dim-button");
//       window.document
//         .getElementById("result_tab")
//         ?.classList.remove("dim-button");
//       if (userType == "teacher" && !B2C) {
//         window.document
//           .getElementById("answer_tab")
//           ?.classList.remove("dim-button");
//         window.document
//           .getElementById("back_result")
//           ?.classList.remove("dim-button");
//       }
//     });
//   };

//   componentWillReceiveProps(props) {
//     console.log(props, "componentWillReceiveProps");
//     if (props.selectedHistory != null) {
//       this.setState({
//         selectedHistory: props.selectedHistory,
//       });
//       this.getReportObjectByAttemptSessionId(
//         props.selectedHistory.AttemptSessionId
//       );
//     }
//   }

//   componentDidMount() {
//     //ga
//     // gaTrack("send", "pageview", this.props.location.pathname);
//     console.log("[checkReportScriptIsReady]", this.props.action);

//     switch (this.props.action) {
//       case "in-page":
//         if (this.props.selectedHistory != null) {
//           //get object by  key
//           this.getReportObjectByAttemptSessionId(
//             this.props.selectedHistory.AttemptSessionId
//           );
//         }
//         break;

//       case "page":
//         if (this.props.standalone) {
//           if (this.props.location.state.session_id) {
//             const userType = localStorage.getItem("role");
//             if (!this.props.isInappBrowser) {
//               window.document
//                 .getElementById("studygroup_back")
//                 ?.classList.add("dim-button");
//             }
//             window.document
//               .getElementById("assessment_tab")
//               ?.classList.add("dim-button");
//             window.document
//               .getElementById("result_tab")
//               ?.classList.add("dim-button");
//             if (userType == "teacher") {
//               window.document
//                 .getElementById("answer_tab")
//                 ?.classList.add("dim-button");
//               window.document
//                 .getElementById("back_result")
//                 ?.classList.add("dim-button");
//             }

//             this.getReportBySessionId();
//           } else {
//             console.log("no session id", this.props.location);
//           }
//         } else {
//           if (
//             // !this.props.match.params.questionId &&
//             !this.props.match.params.userId
//           ) {
//             return this.props.history.push(
//               "/a/studygroup/" +
//                 this.props.match.params.id +
//                 "/assessment/" +
//                 this.props.match.params.bookNunitId +
//                 "/" +
//                 this.props.match.params.resId +
//                 "/result"
//             );
//           }
//           if (!this.props.location.state) {
//             return this.props.history.push(
//               "/a/studygroup/" +
//                 this.props.match.params.id +
//                 "/assessment/" +
//                 this.props.match.params.bookNunitId +
//                 "/" +
//                 this.props.match.params.resId +
//                 "/result"
//             );
//           }
//           if (this.props.location.state.session_id) {
//             const userType = localStorage.getItem("role");
//             if (!this.props.isInappBrowser) {
//               window.document
//                 .getElementById("studygroup_back")
//                 ?.classList.add("dim-button");
//             }
//             window.document
//               .getElementById("assessment_tab")
//               ?.classList.add("dim-button");
//             window.document
//               .getElementById("result_tab")
//               ?.classList.add("dim-button");
//             if (userType == "teacher") {
//               window.document
//                 .getElementById("answer_tab")
//                 ?.classList.add("dim-button");
//               window.document
//                 .getElementById("back_result")
//                 ?.classList.add("dim-button");
//             }

//             this.getReportBySessionId();
//           } else {
//             console.log("no session id", this.props.location);
//           }
//         }

//         break;
//     }

//     //var reportsApp = window.LearnosityReports.init(this.props.selectedHistory.LearnositySessionResult);
//   }

//   getReportObjectByAttemptSessionId = (id) => {
//     var variables;
//     if (!this.props.isInappBrowser) {
//       variables = {
//         SessionId: id,
//         Lang: localStorage.getItem("i18nextLng") == "en-US" ? "EN" : "TC",
//       };
//     } else {
//       variables = {
//         SessionId: id,
//         Lang: localStorage.getItem("i18nextLng-inapp") == "en-US" ? "EN" : "TC",
//       };
//     }
//     fetchQuery(environment, SingleResultStudentReportQuery, variables).then(
//       (data) => {
//         console.log(data);

//         if (!data.learnositySelfLearnResult) {
//           console.log("Faill to get Data : learnositySelfLearnResult null");
//         }
//         var objectData = JSON.parse(
//           data.learnositySelfLearnResult.LearnositySessionResult
//         );
//         this.setState({
//           loading: false,
//         });
//         this.getReport(objectData);
//       }
//     );
//   };

//   renderQuestionsBar = (question) => {
//     // if (
//     //   this.props.location.state == undefined ||
//     //   this.props.location.state == ""
//     // ) {
//     //   this.props.history.push(
//     //     "/a/studygroup/" +
//     //       this.props.match.params.id +
//     //       "/assessment" +
//     //       "/" +
//     //       this.props.match.params.bookNunitId +
//     //       "/" +
//     //       this.props.match.params.resId +
//     //       "/result"
//     //   );
//     // }
//     var key = question;
//     var questions = [];
//     var correct = 0,
//       max_score = 0;
//     key.map((obj, index) => {
//       console.log(obj, "obj");
//       questions.push(obj.data);
//       if (obj.data.score) {
//         correct = correct + obj.data.score;
//       }
//       max_score = max_score + obj.data.max_score;
//     });
//     correct = (correct / max_score) * 100;
//     console.log(questions, "questions", correct);
//     this.setState({
//       questionsData: questions,
//       correctCount: Math.round(correct),
//     });
//   };

//   renderListRow = (HistoryArray) => {
//     const { t } = this.props;
//     return HistoryArray.map((item, index) => {
//       if (index == HistoryArray.length - 1) {
//         return (
//           <option key={index} value={index}>
//             {t("Last Attempt")}
//           </option>
//         );
//       }
//       return (
//         <Moment
//           key={index}
//           value={index}
//           element="option"
//           format="YYYY-MM-DD HH:mm"
//         >
//           {item.AttemptDate}
//         </Moment>
//         /*
//         <option key={index} value={index}>
//           {index == HistoryArray.length - 1 ? (
//             "Last Attempt"
//           ) : (

//           )}
//         </option>
//         */
//       );
//     });
//   };

//   getStudentHistoryList = (userid, resid, callback) => {
//     var variables;
//     if (!this.props.isInappBrowser) {
//       variables = {
//         UserId: userid,
//         ResourceId: resid,
//         Lang: localStorage.getItem("i18nextLng") == "en-US" ? "EN" : "TC",
//       };
//     } else {
//       variables = {
//         UserId: userid,
//         ResourceId: resid,
//         Lang: localStorage.getItem("i18nextLng-inapp") == "en-US" ? "EN" : "TC",
//       };
//     }

//     fetchQuery(
//       environment,
//       SingleResultStudentReportHistoryQuery,
//       variables
//     ).then((data) => {
//       console.log(data);
//       var listArray = [...data.user.SelfLearnAttemptHistory];
//       listArray.sort(function (a, b) {
//         return new monent(a.AttemptDate) - new monent(b.AttemptDate);
//       });
//       var defauleAttemptDate = listArray.length - 1;
//       if (this.props.location.state) {
//         if (this.props.location.state.attemptDate) {
//           defauleAttemptDate = listArray
//             .map((i) => {
//               return i.AttemptDate;
//             })
//             .indexOf(this.props.location.state.attemptDate);
//         }
//       }
//       this.setState({
//         HistoryArray: listArray,
//         defauleAttemptDate: defauleAttemptDate,
//       });
//       console.log(listArray, defauleAttemptDate);
//       callback();
//     });
//   };

//   getReportBySessionId = () => {
//     var variables;
//     if (!this.props.isInappBrowser) {
//       variables = {
//         SessionId: this.props.location.state.session_id,
//         Lang: localStorage.getItem("i18nextLng") == "en-US" ? "EN" : "TC",
//       };
//     } else {
//       variables = {
//         SessionId: this.props.location.state.session_id,
//         Lang: localStorage.getItem("i18nextLng-inapp") == "en-US" ? "EN" : "TC",
//       };
//     }
//     fetchQuery(environment, SingleResultStudentReportQuery, variables).then(
//       (data) => {
//         config.mode == "dev" && console.log(data);
//         this.setState({
//           reportObject: JSON.parse(
//             data.learnositySelfLearnResult.LearnositySessionResult
//           ),
//           attemptDate: data.learnositySelfLearnResult.AttemptDate,
//           studentDisplayName:
//             data.learnositySelfLearnResult.UserDetail.UserDisplayName,
//           loading: false,
//         });
//         console.log(
//           JSON.parse(data.learnositySelfLearnResult.LearnositySessionResult)
//         );
//         this.getStudentHistoryList(
//           data.learnositySelfLearnResult.UserId,
//           data.learnositySelfLearnResult.ResourceId,
//           () => {
//             this.getReport(
//               JSON.parse(data.learnositySelfLearnResult.LearnositySessionResult)
//             );
//           }
//         );
//       }
//     );
//   };

//   getReport = (object) => {
//     this.setState({
//       reportData: null,
//       questionsData: null,
//     });
//     var callbacks = {
//       errorListener: this.errorListener,
//       readyListener: this.readyListener,
//     };
//     var checkReportScriptIsReady = setInterval(() => {
//       if (window.LearnosityReports) {
//         console.log(
//           "[checkReportScriptIsReady]  Find window.LearnosityReports"
//         );
//         clearInterval(checkReportScriptIsReady);
//         if (object) {
//           console.log(object);
//           this.setState({
//             itemTagsData: object.tagData,
//           });
//           reportsApp = window.LearnosityReports.init(
//             object.reportObj,
//             callbacks
//           );
//         } else {
//           console.log(
//             JSON.parse(this.props.selectedHistory.LearnositySessionResult)
//           );
//           this.setState({
//             itemTagsData: JSON.parse(
//               this.props.selectedHistory.LearnositySessionResult
//             ).tagData,
//           });

//           reportsApp = window.LearnosityReports.init(
//             JSON.parse(this.props.selectedHistory.LearnositySessionResult)
//               .reportObj,
//             callbacks
//           );
//         }
//       }
//     }, 1000);
//   };

//   render() {
//     const {
//       attemptDate,
//       HistoryArray,
//       loading,
//       questionsData,
//       correctCount,
//       studentDisplayName,
//       openState,
//       itemTagsData,
//       reportLoading,
//       reportData,
//       question,

//       defauleAttemptDate,
//     } = this.state;
//     const lang = localStorage.getItem("i18nextLng");
//     const userType = localStorage.getItem("role");
//     const UserDisplayName = this.props.isInappBrowser
//       ? [
//           {
//             Lang: "en-US",
//             UserName: localStorage.getItem("in-app-browser-data-username-en"),
//           },
//           {
//             Lang: "zh-HK",
//             UserName: localStorage.getItem("in-app-browser-data-username-ct"),
//           },
//         ]
//       : JSON.parse(localStorage.getItem("userData")).UserDisplayName;
//     console.log(
//       this.props.selectedHistory,
//       loading,
//       studentDisplayName,
//       reportData,
//       this.props.action,
//       UserDisplayName
//     );
//     const {
//       t,
//       resourceData,
//       studyGroupData,
//       resourceIdGa,
//       resourceIngestionIdGa,
//       B2C,
//       resData,
//       standalone,
//       resourceId,
//     } = this.props;
//     console.log(this.props);
//     console.log(studentDisplayName);
//     return (
//       <>
//         {this.props.action == "page" && !loading && (
//           <div className="result-menu" style={{ marginTop: "0" }}>
//             <NavLink
//               id={"back_result"}
//               exact
//               to={
//                 standalone === true
//                   ? this.props.location.pathname.replace(
//                       "/" + this.props.location.state.userId,
//                       ""
//                     )
//                   : this.props.location.pathname.replace(
//                       "/" + this.props.match.params.userId,
//                       ""
//                     )
//               }
//             >
//               {t("Result")}
//             </NavLink>
//             <div className="gap">
//               <i className="icon mdi mdi-chevron-right"></i>
//             </div>
//             <div className="active">
//               {studentDisplayName &&
//               studentDisplayName[
//                 lang == studentDisplayName[0].Lang ? 0 : 1
//               ].UserName.trim().length == 0
//                 ? studentDisplayName[0].UserName
//                 : studentDisplayName[lang == studentDisplayName[0].Lang ? 0 : 1]
//                     .UserName}
//             </div>
//           </div>
//         )}
//         {loading && (
//           <div className="splash-container">
//             <div className="loader mx-auto" />
//           </div>
//         )}
//         {!loading && (
//           <div className={this.props.action == "in-page" ? "" : "report-box"}>
//             {this.props.action == "page" && !loading && (
//               <>
//                 <div className="filter-row">
//                   <div className="user-report">
//                     <h3>
//                       {studentDisplayName &&
//                       studentDisplayName[
//                         lang == studentDisplayName[0].Lang ? 0 : 1
//                       ].UserName.trim().length == 0
//                         ? studentDisplayName[0].UserName
//                         : studentDisplayName[
//                             lang == studentDisplayName[0].Lang ? 0 : 1
//                           ].UserName}
//                     </h3>
//                     <h5>
//                       <Moment format="YYYY-MM-DD HH:mm">{attemptDate}</Moment>
//                     </h5>
//                   </div>
//                   {HistoryArray && (
//                     <select
//                       className="user-last-attemptDate"
//                       defaultValue={defauleAttemptDate}
//                       onChange={(e) => {
//                         var gqString = "Change attempt";
//                         try {
//                           // userType == "teacher" ? "T" : "S";
//                           // if (!B2C) {
//                           // var book =
//                           //     studyGroupData.BookSeries.Books &&
//                           //     studyGroupData.BookSeries.Books.length > 0
//                           //       ? studyGroupData.BookSeries.Books[0]
//                           //           .BookName[0].BookName
//                           //       : "NoData",
//                           //   chapter =
//                           //     studyGroupData.BookSeries.Books &&
//                           //     studyGroupData.BookSeries.Books.length > 0
//                           //       ? studyGroupData.BookSeries.Books[0].Units[0]
//                           //           .UnitName[0].UnitName
//                           //       : "NoData";
//                           // }
//                           if (!standalone) {
//                             gaTrack(
//                               "send",
//                               "event",
//                               "Assessment student performance",
//                               gqString,
//                               "Attempt:" +
//                                 HistoryArray[e.target.value].AttemptDate +
//                                 ", Assessment:" +
//                                 resourceData.ResourceLangs[0].ResourceName +
//                                 ", Resource ID:" +
//                                 resourceData.IngestionId +
//                                 ", Level:" +
//                                 (B2C
//                                   ? "NoData"
//                                   : bookData.Books[0].PearsonLevel.LevelName[0]
//                                       .LevelName) +
//                                 ", Book Series:" +
//                                 bookData.BookSeriesName[0].BookSeriesName +
//                                 ", Book:" +
//                                 bookData.Books[0].BookName[0].BookName +
//                                 ", Chapter:" +
//                                 (bookData.Books && bookData.Books.length > 0
//                                   ? bookData.Books[0].Units[0].UnitName[0]
//                                       .UnitName
//                                   : "NoData") +
//                                 ", Role:" +
//                                 userType +
//                                 `, From:${B2C ? "Supplementary" : "My Class"}`
//                             );
//                           } else {
//                             gaTrack(
//                               "send",
//                               "event",
//                               "Assessment student performance",
//                               gqString,
//                               "Attempt:" +
//                                 HistoryArray[e.target.value].AttemptDate +
//                                 ", Assessment:" +
//                                 resourceData.ResourceLangs[0].ResourceName +
//                                 ", Resource ID:" +
//                                 resourceData.IngestionId +
//                                 ", Level:" +
//                                 "NoData" +
//                                 ", Book Series:" +
//                                 bookData.BookSeriesName[0].BookSeriesName +
//                                 ", Book:" +
//                                 "NoData" +
//                                 ", Chapter:" +
//                                 "NoData" +
//                                 ", Role:" +
//                                 userType
//                             );
//                           }
//                         } catch (e) {
//                           console.log(e, "Check answer GA Error");
//                         }
//                         this.setState({
//                           attemptDate: HistoryArray[e.target.value].AttemptDate,
//                         });
//                         this.getReport(
//                           JSON.parse(
//                             HistoryArray[e.target.value].LearnositySessionResult
//                           )
//                         );
//                       }}
//                     >
//                       {this.renderListRow(HistoryArray)}
//                     </select>
//                   )}
//                 </div>

//                 <div className="questions-bar">
//                   {questionsData ? (
//                     <table>
//                       <thead>
//                         <tr>
//                           <th>{t("Student")}</th>
//                           <th>{t("Score")}</th>
//                           {questionsData &&
//                             questionsData.map((obj, index) => {
//                               return (
//                                 <th key={index}>{"Q" + obj.item_number}</th>
//                               );
//                             })}
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <tr>
//                           <td>
//                             {studentDisplayName &&
//                             studentDisplayName[
//                               lang == studentDisplayName[0].Lang ? 0 : 1
//                             ].UserName.trim().length == 0
//                               ? studentDisplayName[0].UserName
//                               : studentDisplayName[
//                                   lang == studentDisplayName[0].Lang ? 0 : 1
//                                 ].UserName}
//                           </td>
//                           <td>{correctCount}%</td>
//                           {questionsData &&
//                             questionsData.map((obj, index) => {
//                               return (
//                                 <td key={index}>
//                                   <div
//                                     className={`dot ${
//                                       obj.attempted == false
//                                         ? "grey"
//                                         : obj.score == obj.max_score
//                                         ? "green"
//                                         : obj.score
//                                         ? "yellow"
//                                         : "red"
//                                     }`}
//                                   ></div>
//                                 </td>
//                               );
//                             })}
//                         </tr>
//                       </tbody>
//                     </table>
//                   ) : (
//                     <Loading type="normal" />
//                   )}
//                 </div>
//               </>
//             )}
//             {this.props.action == "in-page" && !loading && (
//               <div className="questions-bar">
//                 {questionsData ? (
//                   <table>
//                     <thead>
//                       <tr>
//                         <th>{t("Student")}</th>
//                         <th>{t("Score")}</th>
//                         {questionsData &&
//                           questionsData.map((obj, index) => {
//                             return <th key={index}>{"Q" + obj.item_number}</th>;
//                           })}
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td>
//                           {UserDisplayName &&
//                           UserDisplayName[
//                             lang == UserDisplayName[0].Lang ? 0 : 1
//                           ].UserName.trim().length == 0
//                             ? UserDisplayName[0].UserName
//                             : UserDisplayName[
//                                 lang == UserDisplayName[0].Lang ? 0 : 1
//                               ].UserName}
//                         </td>
//                         <td>{correctCount}%</td>
//                         {questionsData &&
//                           questionsData.map((obj, index) => {
//                             return (
//                               <td key={index}>
//                                 <div
//                                   className={`dot ${
//                                     obj.attempted == false
//                                       ? "grey"
//                                       : obj.score == obj.max_score
//                                       ? "green"
//                                       : obj.score
//                                       ? "yellow"
//                                       : "red"
//                                   }`}
//                                 ></div>
//                               </td>
//                             );
//                           })}
//                       </tr>
//                     </tbody>
//                   </table>
//                 ) : (
//                   <Loading type="normal" />
//                 )}
//               </div>
//             )}
//             <div id="sessions-summary"></div>
//             {
//               <div className="type-row">
//                 {/* {this.renderDummyData()} */}
//                 {question != null && itemTagsData != null && (
//                   <FocusSubfocus
//                     {...this.props}
//                     openState={openState}
//                     questions={question}
//                     tagsData={itemTagsData}
//                     resourceData={resourceData}
//                     studyGroupData={studyGroupData}
//                     B2C={B2C}
//                     standalone={standalone}
//                     resData={resData}
//                     // handleOpenTab={this.handleOpenTab}
//                   />
//                 )}
//               </div>
//             }
//             {/* <div className="type-row">{this.renderDummyData()}</div> */}
//             {/* <Suggestion
//               {...this.props}
//               reportData={reportData}
//               itemTagsData={itemTagsData}
//               action="teacher_view_student"
//             /> */}

//             <Suggestion
//               {...this.props}
//               resourceId={resourceId}
//               resourceIdGa={resourceIdGa}
//               reportData={reportData}
//               itemTagsData={itemTagsData}
//               standalone={standalone}
//               resData={resData}
//               action="teacher_view_student"
//             />

//             <br />
//             <div id="session-detail"></div>
//           </div>
//         )}
//       </>
//     );
//   }
// }

// export default index;
