import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import Moment from "react-moment";
import monent from "moment";

import MyScoreCard from "../../../../../Components/MyScoreCard";
import MyRankingCard from "../../../../../Components/MyRankingCard";
import ActivityLALOCard from "../../../../../Components/ActivityLALOCard";
import GetB2CAttemptHistory from "../../../../../Services/api/GraphQL/Query/GetB2CAttemptHistory";

import emptybanner from "../../../../../assets/img/bird.svg";
// import hisIcon from "../../../../../assets/img/group-18.svg";
import "./index.scss";

const index = (props) => {
  const {
    resourceData,
    bookData,
    isInappBrowser,
    t,
    inappData,
    activity,
    standalone,
  } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);

  const [isLoading, setisLoading] = useState(true);
  const [attempHistList, setattempHistList] = useState([]);
  const [seletedHist, setseletedHist] = useState(null);

  const [tagData, settagData] = useState(null);
  const [suggestionMapping, setsuggestionMapping] = useState([]);
  const [LearnosityReportStatistic, setLearnosityReportStatistic] =
    useState(null);
  const [LearnosityTagHierarchyRef, setLearnosityTagHierarchyRef] =
    useState(null);
  const [IsEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    getAttempHis();
  }, []);

  const getAttempHis = async () => {
    setisLoading(true);
    try {
      //  get user attempt his list
      let variables = {
        UserId: userData.UserId,
        Lang: lang == "en-US" ? "EN" : "TC",
      };
      if (!isInappBrowser) {
        variables = {
          ...variables,
          ResourceId: props.match.params.resId,
          ResourceIds: [props.match.params.resId],
        };
      } else {
        variables = {
          ...variables,
          ResourceId: inappData.resourceId,
          ResourceIds: [inappData.resourceId],
        };
      }
      await GetB2CAttemptHistory(variables).then((data) => {
        console.log(data);
        let listArray = [...data.user.SelfLearnAttemptHistory];
        listArray.sort(function (a, b) {
          return new monent(a.AttemptDate) - new monent(b.AttemptDate);
        });
        console.log(listArray);
        if (listArray.length > 0) {
          setattempHistList(listArray);
          setseletedHist(listArray[listArray.length - 1]);
          if (data.resourceSearch.Resources[0].TagData != null) {
            settagData(JSON.parse(data.resourceSearch.Resources[0].TagData));
          }
          setLearnosityTagHierarchyRef(
            data.resourceSearch.Resources[0].LearnosityTagHierarchyRef
          );
          setsuggestionMapping(
            data.resourceSearch.Resources[0].LearnositySuggestionMapping
          );
          setLearnosityReportStatistic(
            data.resourceSearch.Resources[0].LearnosityReportStatistic == ""
              ? null
              : data.resourceSearch.Resources[0].LearnosityReportStatistic
          );
        } else {
          setIsEmpty(true);
        }
        setisLoading(false);
      });
    } catch (error) {
      setisLoading(false);
      console.log("[AnalysisAndSuggestion] errors...", error);
    }
  };

  return (
    <div id="as-tag" className="">
      {IsEmpty && !isLoading && (
        <div className="content-row">
          <div className="no-history">
            <div className="model-answer-box-no-permission">
              <div className="model-answer-image">
                <img src={emptybanner} />
              </div>
              <div className="model-answer-text">
                <h3>{t("Please complete the assessment first.​")}</h3>
                {/* <h5>
                  {t(
                    "Your result can be reviewed after you have finished the assessment."
                  )}
                </h5> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {!IsEmpty && !isLoading && (
        <>
          {/* attempHist  removed in Analysis and Suggestion */}
          {
            // <div className="action-row">
            //   <div className="attempt-selection">
            //     <select
            //       className=""
            //       onChange={(e) => {
            //         try {
            //           gaTrack(
            //             "send",
            //             "event",
            //             "Assessment",
            //             "Change result attempt",
            //             "Attempt:" +
            //               attempHistList[e.target.value].AttemptDate +
            //               ", Assessment:" +
            //               resourceData.ResourceLangs[0].ResourceName +
            //               ", Resource ID:" +
            //               resourceData.IngestionId +
            //               ", Level:NoData" +
            //               ", Book Series:" +
            //               bookData.BookSeriesName[0].BookSeriesName +
            //               ", Book:" +
            //               bookData.Books[0].BookName[0].BookName +
            //               ", Chapter:" +
            //               (bookData.Books && bookData.Books.length > 0
            //                 ? bookData.Books[0].Units[0].UnitName[0].UnitName
            //                 : "NoData") +
            //               ", Role:" +
            //               userType +
            //               `, From:Supplementary`
            //           );
            //         } catch (error) {
            //           console.log(error);
            //         }
            //         setseletedHist(attempHistList[e.target.value]);
            //         console.log(attempHistList[e.target.value]);
            //       }}
            //     >
            //       {attempHistList.map((o, i) => {
            //         return (
            //           <Moment
            //             key={i}
            //             value={i}
            //             element="option"
            //             format="YYYY-MM-DD HH:mm"
            //           >
            //             {o.AttemptDate}
            //           </Moment>
            //         );
            //       })}
            //     </select>
            //     <div className="border"></div>
            //   </div>
            //   <img src={hisIcon} />
            // </div>
          }
          {/* my score and ranking */}
          <div className="content-row">
            {/* my score */}
            <MyScoreCard
              t={props.t}
              seletedHist={seletedHist}
              attempHistList={attempHistList}
            />
            {/* ranking */}
            <MyRankingCard {...props} />
          </div>
          <div className="content-row">
            {/* LA/LO */}
            <ActivityLALOCard
              {...props}
              activity={activity}
              standalone={standalone}
              reload={getAttempHis}
              tagData={tagData}
              seletedHist={seletedHist}
              LearnosityTagHierarchyRef={LearnosityTagHierarchyRef}
              suggestionMapping={suggestionMapping}
              LearnosityReportStatistic={LearnosityReportStatistic}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default index;
