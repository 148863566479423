/**
 * @generated SignedSource<<6d2e7493583e87c62827174f8d793e04>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ViewAllNotificationPECSubjectListQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type ViewAllNotificationPECSubjectListQuery$data = {|
  +user: ?{|
    +AssignmentBookSeriesList: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +DisplaySequence: ?number,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +BookSeriesName: ?string,
      |}>,
      +AdoptedBooks: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FilePath: ?string,
        |},
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FilePath: ?string,
        |},
      |}>,
    |}>,
  |},
|};
export type ViewAllNotificationPECSubjectListQuery = {|
  variables: ViewAllNotificationPECSubjectListQuery$variables,
  response: ViewAllNotificationPECSubjectListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FilePath",
  "storageKey": null
},
v11 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "systemFile",
    "kind": "LinkedField",
    "name": "BookImage",
    "plural": false,
    "selections": [
      (v10/*: any*/)
    ],
    "storageKey": null
  }
],
v12 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "systemFile",
    "kind": "LinkedField",
    "name": "BookImage",
    "plural": false,
    "selections": [
      (v10/*: any*/),
      (v7/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewAllNotificationPECSubjectListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AssignmentBookSeriesList",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "AdoptedBooks",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ViewAllNotificationPECSubjectListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AssignmentBookSeriesList",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "AdoptedBooks",
                "plural": true,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bdc893414edb27d24713c7b6685dd97",
    "id": null,
    "metadata": {},
    "name": "ViewAllNotificationPECSubjectListQuery",
    "operationKind": "query",
    "text": "query ViewAllNotificationPECSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {\n      BookSeriesId\n      DisplaySequence\n      BookSeriesName {\n        BookSeriesId\n        BookSeriesName\n      }\n      AdoptedBooks(SchoolYearId: $SchoolYearId) {\n        id\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FilePath\n          id\n        }\n      }\n      Books {\n        id\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FilePath\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4d9eaf7bf970ca59f8ae68f986c8f961";

module.exports = ((node/*: any*/)/*: Query<
  ViewAllNotificationPECSubjectListQuery$variables,
  ViewAllNotificationPECSubjectListQuery$data,
>*/);
