import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const StudentAttemptyHistorySelfLearnQuery = graphql`
  query StudentAttemptyHistorySelfLearnQuery(
    $UserId: String!
    $ResourceId: String!
    $IncludeAssignmentRecord: Boolean
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(
        ResourceId: $ResourceId
        IncludeAssignmentRecord: $IncludeAssignmentRecord
      ) {
        ResourceId
        IsCompleted
        AttemptSessionId
        AttemptDate
        UserId
      }
    }
  }
`;

const StudentAttemptyHistoryAssignmentQuery = graphql`
  query StudentAttemptyHistoryAssignmentQuery(
    $UserId: String!
    $ResourceAssignId: String!
  ) {
    resourceAssignment(ResourceAssignId: $ResourceAssignId) {
      Resources {
        ResourceId
        ResourceAssignResId
        LearnosityAttemptHistory(UserId: $UserId) {
          ResourceId
          IsCompleted
          AttemptSessionId
          AttemptDate
          UserId
        }
        LearnosityAttemptLastHistory {
          ResourceId
          LearnositySessionRawResult
          UserDetail {
            UserName
            UserDisplayName {
              UserName
              Lang
            }
          }
        }
        LearnosityAttemptFirstHistory {
          ResourceId
          LearnositySessionRawResult
          UserDetail {
            UserName
            UserDisplayName {
              UserName
              Lang
            }
          }
        }
      }
    }
  }
`;
const StudentAttemptyHistory = (variables, isAssignment) => {
  return fetchQuery(
    environment,
    !isAssignment
      ? StudentAttemptyHistorySelfLearnQuery
      : StudentAttemptyHistoryAssignmentQuery,
    variables
  );
};

export default StudentAttemptyHistory;
