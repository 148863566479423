/**
 * @generated SignedSource<<279a77d545e670702502330b457f6a19>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReadResourceAssignmentResourceMutation$variables = {|
  ResourceAssignResId: string,
|};
export type ReadResourceAssignmentResourceMutation$data = {|
  +readResourceAssignmentResource: ?string,
|};
export type ReadResourceAssignmentResourceMutation = {|
  variables: ReadResourceAssignmentResourceMutation$variables,
  response: ReadResourceAssignmentResourceMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourceAssignResId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceAssignResId",
        "variableName": "ResourceAssignResId"
      }
    ],
    "kind": "ScalarField",
    "name": "readResourceAssignmentResource",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReadResourceAssignmentResourceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReadResourceAssignmentResourceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5c4ec73de494a3819a9a22ed6c8fd948",
    "id": null,
    "metadata": {},
    "name": "ReadResourceAssignmentResourceMutation",
    "operationKind": "mutation",
    "text": "mutation ReadResourceAssignmentResourceMutation(\n  $ResourceAssignResId: String!\n) {\n  readResourceAssignmentResource(ResourceAssignResId: $ResourceAssignResId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "197da7723387725bfe81d3ebdc505613";

module.exports = ((node/*: any*/)/*: Mutation<
  ReadResourceAssignmentResourceMutation$variables,
  ReadResourceAssignmentResourceMutation$data,
>*/);
