import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import checked from "../../../../assets/img/check-24-px.svg";
import environment from "../../../../Environment";
import Loading from "../../../Loading/Loading";

const ViewableByStudentsTableGetListQuery = graphql`
  query ViewableByStudentsTableGetListQuery(
    $StudyGroupId: String!
    $SchoolYearId: String!
    $UserType: UserTypeEnumType!
    $ResourceId: String!
  ) {
    studyGroup(StudyGroupId: $StudyGroupId) {
      StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType) {
        ClassNumber
        User {
          UserId
          UserDisplayName {
            Lang
            UserName
          }
        }
        AssignedForResource(
          ResourceId: $ResourceId
          SchoolYearId: $SchoolYearId
        )
      }
    }
  }
`;

const ViewableByStudentsTableIsViewQuery = graphql`
  query ViewableByStudentsTableIsViewQuery(
    $ResourceId: [String!]
    $StudyGroupId: String!
    $SchoolYearId: String!
  ) {
    resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {
      Resources {
        AssignementInvloved(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
        ) {
          Resources {
            ResourceId
            ResourceAssignTargetStatus {
              UserId
              Status
            }
          }
        }
      }
    }
  }
`;

export default (props) => {
  const { t, resData, studyGroupId } = props;
  const schoolYearId = JSON.parse(localStorage.getItem("effectiveSchoolYear"))
    .SchoolYearId;
  const lang = localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(true);
  const [user, setuser] = useState([]);
  const [orderBy, setOrderBy] = useState("STUDENTS_LIST");
  const [order, setOrder] = useState("ASC"); //ASC   DESC

  const GetStudentsList = () => {
    const variables = {
      StudyGroupId: studyGroupId,
      SchoolYearId: schoolYearId,
      UserType: "S",
      ResourceId: resData.resId,
    };
    return fetchQuery(
      environment,
      ViewableByStudentsTableGetListQuery,
      variables
    );
  };
  const GetStudentsIsViewList = () => {
    const variables = {
      ResourceId: resData.resId,
      SchoolYearId: schoolYearId,
      StudyGroupId: studyGroupId,
    };
    return fetchQuery(
      environment,
      ViewableByStudentsTableIsViewQuery,
      variables
    );
  };

  useEffect(() => {
    GetStudentsList().then((data) => {
      console.log(data);
      const { studyGroup } = data || {};
      const userList = studyGroup.StudyGroupUser.reduce(
        (acc, { User, AssignedForResource, ClassNumber }) => {
          const userName =
            User.UserDisplayName[User.UserDisplayName[0].Lang == lang ? 0 : 1]
              .UserName;

          acc.push({
            userId: User.UserId,
            userName,
            AssignedForResource,
            ClassNumber,
          });

          return acc;
        },
        []
      );
      // setuser(userList);
      GetStudentsIsViewList().then((data) => {
        console.log(data);
        const {
          resourceSearch: { Resources = [{}] },
        } = data || {};

        const findSameIdList = Resources[0].AssignementInvloved.reduce(
          (acc, current, i) => {
            const fitlerd = current.Resources.filter((resobj, i) => {
              return resobj.ResourceId === resData.resId;
            });

            acc.push(...fitlerd);

            return acc;
          },
          []
        );

        const uniqueData = findSameIdList.reduce(
          (acc, { ResourceAssignTargetStatus }, i) => {
            if (ResourceAssignTargetStatus.length > 0) {
              //find all data and push it to allStatusList array
              const allStatusList = ResourceAssignTargetStatus.reduce(
                (targetList, { UserId, Status }) => {
                  targetList.push({
                    UserId,
                    Status,
                  });

                  return targetList;
                },
                []
              );

              //remove duplicates userId
              const uniqueList = allStatusList.reduce((accd, current) => {
                const x = accd.find((item) => item.UserId === current.UserId);
                if (!x) {
                  return accd.concat([current]);
                } else {
                  return accd;
                }
              }, []);

              acc.push(...uniqueList);
            }
            return acc;
          },
          []
        );
        const finalList = userList.reduce((acc, current) => {
          // find users who is read the resources
          if (
            uniqueData.map((obj) => obj.UserId).indexOf(current.userId) !== -1
          ) {
            acc.push({
              ...current,
              Status: "Read",
            });
          } else {
            acc.push({
              ...current,
              Status: "",
            });
          }

          return acc;
        }, []);

        console.log(finalList);
        setuser(finalList);
        setLoading(false);
      });
    });
  }, []);

  const SortByData = (type) => {
    if (orderBy === "STUDENTS_LIST") {
      const sorted = user.sort((a, b) => {
        return type === "DESC"
          ? b.ClassNumber - a.ClassNumber
          : a.ClassNumber - b.ClassNumber;
      });
      setuser(sorted);
      setOrder(type === "DESC" ? "DESC" : "ASC");
    }
    if (orderBy === "ASSIGNED_LIST") {
      const sorted = user.sort((a, b) => {
        return type === "DESC"
          ? ("" + a.AssignedForResource).localeCompare(b.AssignedForResource)
          : ("" + b.AssignedForResource).localeCompare(a.AssignedForResource);
      });
      setuser(sorted);
      setOrder(type === "DESC" ? "DESC" : "ASC");
    }
    // if (orderBy === "VIEW_LIST") {
    //   const sorted = user.sort((a, b) => {
    //     return type === "DESC"
    //       ? ("" + a.Status).localeCompare(b.Status)
    //       : ("" + b.Status).localeCompare(a.Status);
    //   });
    //   console.log(sorted);
    //   setuser(sorted);

    //   setOrder(type === "DESC" ? "DESC" : "ASC");
    // }
  };

  return loading == true ? (
    <Loading type={"inpage"} />
  ) : (
    <table className="table">
      <thead>
        <tr className="tab">
          <th
            className="students-list"
            onClick={() => {
              setOrderBy("STUDENTS_LIST");
              if (orderBy == "STUDENTS_LIST") {
                setOrder(order == "DESC" ? "ASC" : "DESC");
                SortByData(order == "DESC" ? "ASC" : "DESC");
              }
            }}
          >
            <div>
              {t("Student") + " "}
              {orderBy === "STUDENTS_LIST" ? (
                order === "DESC" ? (
                  <i className="icon mdi mdi-long-arrow-down"></i>
                ) : (
                  <i className="icon mdi mdi-long-arrow-up"></i>
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            className="isAssigned-list"
            onClick={() => {
              setOrderBy("ASSIGNED_LIST");
              if (orderBy == "ASSIGNED_LIST") {
                setOrder(order == "DESC" ? "ASC" : "DESC");
                SortByData(order == "DESC" ? "ASC" : "DESC");
              }
            }}
          >
            <div>
              {t("Assigned") + " "}
              {orderBy === "ASSIGNED_LIST" ? (
                order === "DESC" ? (
                  <i className="icon mdi mdi-long-arrow-down"></i>
                ) : (
                  <i className="icon mdi mdi-long-arrow-up"></i>
                )
              ) : (
                ""
              )}
            </div>
          </th>
          {/* <th
            className="isViewable-list"
            onClick={() => {
              setOrderBy("VIEW_LIST");
              if (orderBy == "VIEW_LIST") {
                setOrder(order == "DESC" ? "ASC" : "DESC");
                SortByData(order == "DESC" ? "ASC" : "DESC");
              }
            }}
          >
            <div>
              {t("View-able") + " "}
              {orderBy === "VIEW_LIST" ? (
                order === "DESC" ? (
                  <i className="icon mdi mdi-long-arrow-down"></i>
                ) : (
                  <i className="icon mdi mdi-long-arrow-up"></i>
                )
              ) : (
                ""
              )}
            </div>
          </th> */}
        </tr>
      </thead>
      <tbody>
        {user &&
          user.map((obj, index) => {
            return (
              <tr key={index}>
                <td className="assigned_students_table_list">
                  <div className="assigned_students_table_classnumber">
                    {obj.ClassNumber + " "}
                  </div>
                  <div>{obj.userName}</div>
                </td>
                <td>
                  {obj.AssignedForResource == true ? (
                    <img src={checked} />
                  ) : (
                    <div></div>
                  )}
                </td>
                {/* <td>
                  {obj.Status == "Read" ? <img src={checked} /> : <div></div>}
                </td> */}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
