import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const UnitListGetSelfLearnConfigQuery = graphql`
  query UnitListGetSelfLearnConfigQuery(
    $ResourceId: [String]!
    $StudyGroupId: [String]!
    $SchoolYearId: String!
  ) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        SelfLearnConfiguration(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
        ) {
          ConfigName
          ConfigValue
          StudyGroupId
          ResourceId
        }
      }
    }
  }
`;
const UnitListGetSelfLearnConfig = (variables) => {
  return fetchQuery(environment, UnitListGetSelfLearnConfigQuery, variables);
};

export default UnitListGetSelfLearnConfig;
