import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../Components/Loading/PEC/Loading";
import NotificationDetails from "./NotificationDetails";
import { bookseries_favorites } from "../../../Actions";
import NotificationPopUp from "./NotificationPopUp";
import GetNotificationData from "../../../Components/Feature/Function/GetNotificationData";
import moment from "moment";
const NotificationDropDown = (props) => {
  const dispatch = useDispatch();
  const [noticationLoading, setNoticationLoading] = useState(false);
  const {
    likeIcon,
    likedIcon,
    setSelectClass,
    selectClass,
    setShowClass,
    setShowClassData,
    toggleCheckResource,
    t,
    class_dropdownRef,
    favBookData,
    favouriteLoading,
    studyGroupList,
    showClass,
    showClassData,
    activeClass,
    favBookLevel,
    activeMenu,
    BookId,
    favBookSeriesData,
    setFavValue,
    favStudyGroupList,
    role,
    notificationPopupOverlay,
    setNotificationPopupOverlay,
    clickable
  } = props;
  const [selectGroupClass, setSelectGroupClass] = useState(
    selectClass.StudyGroupName
  );
  const [bookClassId, setbookClassId] = useState("");
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const [notificationsDetails, setNotificationsDetails] = useState([]);
  const [notificationsCount, setnotificationsCount] = useState(0);
  const [showNotificatioPopUp, setShowNotificatioPopUp] = useState(false);
  const [notificationTitle, setnotificationTitle] = useState("");
  const [notificationResourceInfo, setnotificationResourceInfo] = useState({});
  const [selectClassNotify, setselectClassNotify] = useState("");
  const [favouriteLoadingId, setFavouriteLoadingId] = useState('');

  const [deleteupdate, setdeleteupdate] = useState(false);
  useEffect(()=>{
    setShowClass(false)
  },[])

  useEffect(() => {
    var selectedLevelForBook = favBookLevel.filter(
      (o) => o.BSId == favBookData.BookId
    );
    var bookLevelId = "";
    if (selectedLevelForBook.length > 0) {
      var favStudyGroupListArg = studyGroupList.filter((o) =>
        selectedLevelForBook[0].StructureId.includes(o.StudyGroupId)
      );
      if (favStudyGroupListArg.length > 0) {
        bookLevelId = favStudyGroupListArg[0].StudyGroupId;
        setSelectGroupClass(
          favStudyGroupListArg[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
            .StudyGroupName
        );
      } else {
        bookLevelId = studyGroupList[0].StudyGroupId;
        studyGroupList.length > 0 ??
          setSelectGroupClass(
            studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
              .StudyGroupName
          );
      }
    } else {
      bookLevelId = studyGroupList[0].StudyGroupId;
      studyGroupList.length > 0 ??
        setSelectGroupClass(
          studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
            .StudyGroupName
        );
    }
    setselectClassNotify(parseInt(bookLevelId));
    handleAssignmentList(parseInt(bookLevelId), parseInt(BookId));
  }, [BookId]); //selectClass

  // useEffect(() => {
  //   if (deleteupdate) {
  //     handleAssignmentList(parseInt(selectClassNotify), parseInt(BookId));
  //   }
  // }, [deleteupdate]);
  
  
  //selectClass
  // notification details starts here
  // BookId:"780", StudyGroupId: "27689"
  const handleAssignmentList = (StudyGroupId, BookId) => {
    setNoticationLoading(true);
    var favBookSeriesId = [];
    var preferencesObj = bookseries_favorites(userData.Preferences);
    var variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      StudyGroupId: StudyGroupId,
      BookId: BookId,
      OrderBy: "LAST_MODIFY_DATE",
      PageNo: 1,
      PageSize: 10,
    };
    GetNotificationData(variables, props.role).then((data) => {
      // dispatch({
      //   type: "SET_NOTIFICATION_LIST",
      //   handbookData:
      //     data.user[props.role == "S" ? "NotificationList" : "NotificationList"]
      //       .NotificationList || [],
      // });

      var notifyInfo = data.user.NotificationList.TotalNumberOfRecord;
      setnotificationsCount(notifyInfo);
      setNotificationsDetails(data.user.NotificationList.NotificationList);
      setNoticationLoading(false);
    });
  };
  // notification details ends here
  const renderBody = (targets) => {
    if (targets.AssignmentOrSharing == "Sharing") {
      return (
        <div className="favourite-notification-main"
        onClick={() => {
          toggleNotificationPopUp(
            "yes",
            targets.SharingObj.RelatedResourcesConfig[0].Resource
              .ResourceLangs[
              targets.SharingObj.RelatedResourcesConfig[0].Resource
                .ResourceLangs[0].Lang == lang
                ? 0
                : 1
            ].ResourceName,
            targets.SharingObj
          );
        }}>
          <label
            className="favourite-notification-title"
            // onClick={() => {
            //   toggleNotificationPopUp(
            //     "yes",
            //     targets.SharingObj.RelatedResourcesConfig[0].Resource
            //       .ResourceLangs[
            //       targets.SharingObj.RelatedResourcesConfig[0].Resource
            //         .ResourceLangs[0].Lang == lang
            //         ? 0
            //         : 1
            //     ].ResourceName,
            //     targets.SharingObj
            //   );
            // }}
          >
            {/* {props.intro} */}
            {targets.SharingObj.RelatedResourcesConfig[0].Resource
              .ResourceLangs[
              targets.SharingObj.RelatedResourcesConfig[0].Resource
                .ResourceLangs[0].Lang == lang
                ? 0
                : 1
            ].ResourceName + " - "}
          </label>
          <label className="favourite-notification-details">
            {
              targets.SharingObj.EnterByUser.UserDisplayName[
                targets.SharingObj.EnterByUser.UserDisplayName[0].Lang == lang
                  ? 0
                  : 1
              ].UserName
            }{" "}
            &nbsp;&nbsp;
            {moment(targets.SharingObj.SharingDate).format("YYYY-MM-DD")}
          </label>
          <span className="favourite-notification-lines"></span>
        </div>
      );
    }
  };
  const toggleNotificationPopUp = (val, msg, resourceObj) => {
    if (val == "yes") {
      setnotificationTitle(msg);
      setnotificationResourceInfo(resourceObj);
      setShowNotificatioPopUp(true);
      setNotificationPopupOverlay(true);
    } else if (val == "no") {
      setShowNotificatioPopUp(false);
      setNotificationPopupOverlay(false);
      //setdeleteupdate(true);
    } else if (val == "delete") {
      setShowNotificatioPopUp(false);
      setNotificationPopupOverlay(false);
      handleAssignmentList(parseInt(selectClassNotify), parseInt(BookId));
      //setdeleteupdate(true);
    } else {
    }
  };
  return (
    <>
      {showNotificatioPopUp && (
        <NotificationPopUp
            {...props}
          toggleNotificationPopUp={toggleNotificationPopUp}
          selectClass={selectClass}
          notificationTitle={notificationTitle}
          HandbookPopupFlag={false}
          notificationResourceInfo={notificationResourceInfo}
          showNotificatioPopUp={showNotificatioPopUp}
          setShowNotificatioPopUp={setShowNotificatioPopUp}
          t={t}
          notificationPopupOverlay={notificationPopupOverlay}
          setNotificationPopupOverlay={setNotificationPopupOverlay}
          isDashboard={true}
        />
      )}
      <div className="favourite-book-notification">
        <div className="notification-area notification-area-teacher">
          <label className="notification-label-name">
            {t("View notifications for")}
          </label>
          {props.role == "T" ? (
            <div>
            <span
              className="notification-year-drop-down notification-year-drop-down-teacher"
              onClick={() => {
                //updateLevels(favBookData.BookId);
                setShowClass(!showClass);
                setShowClassData([BookId]);
              }}
              ref={class_dropdownRef}
            >
              {/* {`Class ${
              props.selectClass.StudyGroupId == favBookData.BookId
                ? selectClass.StudyGroupName
                : studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                    .StudyGroupName
            }`} */}
              {`Class ${selectGroupClass ? selectGroupClass : selectClass.StudyGroupName
                }`}

              {/* {`Class ${selectClass[0].StudyGroupName}`} */}
             
            </span>
            {props.role == "T" && showClass && showClassData.includes(BookId) && (
            <div className="Component1 Component1-teacher">
              <div className="main-container">
                <div
                  onClick={() => setFavValue(1)}
                  className={`static-tab-dropdown-one1 static-tab-dropdown-all ${activeClass == "all" ? "active-tab-dropdown1" : ""
                    }`}
                >
                  {t("All")}
                </div>
                <div
                  onClick={() => setFavValue(0)}
                  className={`static-tab-dropdown-one1 static-tab-dropdown-favourite ${activeClass == "favourite" ? "active-tab-dropdown1" : ""
                    }`}
                >
                  {t("Favourites")}
                </div>
              </div>
              {activeClass == "all" &&
                studyGroupList.map((eleClass) => {
                  var selectedLevelForBook = favBookLevel.filter(
                    (o) =>
                      o.BSId == BookId &&
                      o.SchoolYear == selectedSchoolYear.SchoolYearId
                  );

                  var heartIcon = false;
                  if (selectedLevelForBook.length > 0) {
                    if (
                      selectedLevelForBook[0].StructureId.includes(
                        eleClass.StudyGroupId
                      )
                    ) {
                      heartIcon = true;
                    }
                  }

                  return (
                    <div className="main-class-container1">
                      <div
                        className="class-rows class-rows1"
                        onClick={() => {
                          setbookClassId(eleClass.StudyGroupId);
                          // setSelectClass([
                          //   {
                          //     StudyGroupName:
                          //       eleClass.StudyGroupName[
                          //         eleClass.StudyGroupName[0].Lang == lang
                          //           ? 0
                          //           : 1
                          //       ].StudyGroupName,
                          //     StudyGroupId: eleClass.StudyGroupId,
                          //   },
                          // ]);
                          setSelectGroupClass(
                            eleClass.StudyGroupName[
                              eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                            ].StudyGroupName
                          );
                          // setNoticationLoading(true);
                          // setTimeout(() => {
                          //   setNoticationLoading(false);
                          // }, 1300);
                          handleAssignmentList(
                            parseInt(eleClass.StudyGroupId),
                            parseInt(BookId)
                          );
                          setShowClass(false);
                          setShowClassData([]);
                        }}
                      >
                        {`${t("Class")} ${eleClass.StudyGroupName[
                            eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                          }`}
                      </div>

                      {!heartIcon ? (
                        <div className="like-rows like-rows1">
                          {favouriteLoading && favouriteLoadingId == eleClass.StudyGroupId ? <Loading type={`favourite`} /> :
                          <img
                            src={likeIcon}
                            onClick={(e) => {
                              setFavouriteLoadingId(eleClass.StudyGroupId)
                              toggleCheckResource(
                                e,
                                BookId,
                                eleClass.StudyGroupId,
                                "yes",
                                eleClass.StudyGroupName[
                                  eleClass.StudyGroupName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StudyGroupName
                              );
                            }}
                          /> }
                        </div>
                      ) : (
                        <div className="like-rows like-rows1">
                          {favouriteLoading && favouriteLoadingId == eleClass.StudyGroupId  ? <Loading type={`favourite`} /> : <img
                            src={likedIcon}
                            onClick={(e) => {
                              setFavouriteLoadingId(eleClass.StudyGroupId)
                              toggleCheckResource(
                                e,
                                BookId,
                                eleClass.StudyGroupId,
                                "no",
                                eleClass.StudyGroupName[
                                  eleClass.StudyGroupName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StudyGroupName
                              );
                            }}
                          />}
                        </div>
                      )}
                    </div>
                  );
                })}

              {activeClass == "favourite" && favStudyGroupList.length <= 0 ? (
                <label className="empty-favourite-mesaage">
                  {t("No favourite classes added yet.")}
                </label>
              ) : (
                ""
              )}
              {activeClass == "favourite" &&
                favStudyGroupList.map((eleClass) => {
                  return (
                    <div className="main-class-container1">
                      <div
                        className="class-rows class-rows1"
                        onClick={() => {
                          setbookClassId(eleClass.StudyGroupId);
                          // setSelectClass([
                          //   {
                          //     StudyGroupName:
                          //       eleClass.StudyGroupName[
                          //         eleClass.StudyGroupName[0].Lang == lang
                          //           ? 0
                          //           : 1
                          //       ].StudyGroupName,
                          //     StudyGroupId: eleClass.StudyGroupId,
                          //   },
                          // ]);
                          setSelectGroupClass(
                            eleClass.StudyGroupName[
                              eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                            ].StudyGroupName
                          );
                          // setNoticationLoading(true);
                          // setTimeout(() => {
                          //   setNoticationLoading(false);
                          // }, 1300);
                          handleAssignmentList(
                            parseInt(eleClass.StudyGroupId),
                            parseInt(BookId)
                          );
                          setShowClass(false);
                          setShowClassData([]);
                        }}
                      >
                        {`${t("Class")} ${eleClass.StudyGroupName[
                            eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                          }`}
                      </div>

                      <div className="like-rows like-rows1">
                        <img
                          src={likedIcon}
                          onClick={(e) => {
                            toggleCheckResource(
                              e,
                              BookId,
                              eleClass.StudyGroupId,
                              "no",
                              eleClass.StudyGroupName[
                                eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                              ].StudyGroupName
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
            </div>
          ) : (
            ""
          )}
          
        </div>
      </div>
      <div className="favourite-book-notification-details favourite-book-notification-details-scroll-bar">
        <div className="single-favourite-notification">
          {/* <NotificationDetails
            toggleNotificationPopUp={toggleNotificationPopUp}
            favBookData={favBookData}
            activeMenu={props.activeMenu}
            BookId={favBookData.BookId}
            favBookSeriesData={favBookSeriesData}
            role={props.role}
            noticationLoading={noticationLoading}
            setNoticationLoading={setNoticationLoading}
            selectClass={props.selectClass}
          /> */}
          {noticationLoading ? (
            <Loading type={`normal`} />
          ) : notificationsCount > 0 ? (
            notificationsDetails.map((el) => {
              return <> {renderBody(el)}</>;
            })
          ) : (
            <p className="no-notification-message">
              {/* {t("There is no notification")} */}
              {t("No notifications")}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationDropDown;