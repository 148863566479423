import React, { Component } from "react";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <button
        className={this.props.className}
        id={this.props.id}
        type={this.props.type}
        onClick={this.props.onClick ? this.props.onClick : () => ""}
        data-tut={this.props.datatut}
      >
        {this.props.content ? this.props.content : "Null"}
      </button>
    );
  }
}

export default index;
