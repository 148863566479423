/**
 * @generated SignedSource<<94e080352067cb4a092d67b8a0f7244b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type TopicListingOptionsQuery$variables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
|};
export type TopicListingOptionsQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesName: ?string,
        +Lang: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +BookName: ?string,
          +Lang: ?string,
        |}>,
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +HierarchyReference: ?string,
          +DisplaySequence: ?string,
          +HierarchyReferenceLabelEn: ?string,
          +HierarchyReferenceLabelChi: ?string,
        |}>,
      |}>,
    |}>,
  |},
|};
export type TopicListingOptionsQuery = {|
  variables: TopicListingOptionsQuery$variables,
  response: TopicListingOptionsQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReference",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelEn",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelChi",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicListingOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "kind": "LinkedField",
                    "name": "AdoptedTagHierarchy",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TopicListingOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "kind": "LinkedField",
                    "name": "AdoptedTagHierarchy",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45cf6e594ac2f11233b562a55d7aa0d1",
    "id": null,
    "metadata": {},
    "name": "TopicListingOptionsQuery",
    "operationKind": "query",
    "text": "query TopicListingOptionsQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesId\n      BookSeriesName {\n        BookSeriesName\n        Lang\n      }\n      Books(BookId: $BookId) {\n        BookId\n        BookName {\n          BookName\n          Lang\n        }\n        AdoptedTagHierarchy {\n          HierarchyReference\n          DisplaySequence\n          HierarchyReferenceLabelEn\n          HierarchyReferenceLabelChi\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e6c1c56097d7cb03b321e4d9881c680d";

module.exports = ((node/*: any*/)/*: Query<
  TopicListingOptionsQuery$variables,
  TopicListingOptionsQuery$data,
>*/);
