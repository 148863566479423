/**
 * @generated SignedSource<<52f7926fa149de605f4e4eb9d96f3ac3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ViewableByStudentsTableIsViewQuery$variables = {|
  ResourceId?: ?$ReadOnlyArray<string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type ViewableByStudentsTableIsViewQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +AssignementInvloved: ?$ReadOnlyArray<?{|
        +Resources: ?$ReadOnlyArray<?{|
          +ResourceId: ?string,
          +ResourceAssignTargetStatus: ?$ReadOnlyArray<?{|
            +UserId: ?string,
            +Status: ?string,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
export type ViewableByStudentsTableIsViewQuery = {|
  variables: ViewableByStudentsTableIsViewQuery$variables,
  response: ViewableByStudentsTableIsViewQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = [
  {
    "kind": "Literal",
    "name": "PageSize",
    "value": 9999
  },
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ResourceAssignmentResourceTargetStatus",
  "kind": "LinkedField",
  "name": "ResourceAssignTargetStatus",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewableByStudentsTableIsViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "resourceAssignment",
                "kind": "LinkedField",
                "name": "AssignementInvloved",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceAssignmentResource",
                    "kind": "LinkedField",
                    "name": "Resources",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ViewableByStudentsTableIsViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "resourceAssignment",
                "kind": "LinkedField",
                "name": "AssignementInvloved",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceAssignmentResource",
                    "kind": "LinkedField",
                    "name": "Resources",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a10c99ab1573c6d1ffdc83d8a3d9ff61",
    "id": null,
    "metadata": {},
    "name": "ViewableByStudentsTableIsViewQuery",
    "operationKind": "query",
    "text": "query ViewableByStudentsTableIsViewQuery(\n  $ResourceId: [String!]\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {\n    Resources {\n      AssignementInvloved(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        Resources {\n          ResourceId\n          ResourceAssignTargetStatus {\n            UserId\n            Status\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "721e45aa3ce85aea4bae084a02e82c8d";

module.exports = ((node/*: any*/)/*: Query<
  ViewableByStudentsTableIsViewQuery$variables,
  ViewableByStudentsTableIsViewQuery$data,
>*/);
