//MySchoolBookSeriesData
import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const MySchoolBookSeriesDataTeacherCheckingQuery = graphql`
  query MySchoolBookSeriesDataTeacherCheckingQuery(
    $SchoolCode: String!
    $SchoolYearId: String!
  ) {
    school(SchoolCode: $SchoolCode) {
      Schools {
        StudyGroups(FilterOnboarded: true, SchoolYearId: $SchoolYearId) {
          BookSeries {
            BookSeriesId
            IsB2CBookSeries
            IngestionId
          }
        }
      }
    }
  }
`;

const MySchoolBookSeriesDataStudentCheckingQuery = graphql`
  query MySchoolBookSeriesDataStudentCheckingQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
        StudyGroup {
          BookSeries {
            BookSeriesId
            IsB2CBookSeries
            IngestionId
          }
        }
      }
    }
  }
`;

// const MySchoolBookSeriesDataTeacherQuery = graphql`
//   query MySchoolBookSeriesDataTeacherQuery(
//     $SchoolCode: String!
//     $SchoolYearId: String!
//   ) {
//     school(SchoolCode: $SchoolCode) {
//       Schools {
//         StudyGroups(FilterOnboarded: true, SchoolYearId: $SchoolYearId) {
//           BookSeries {
//             BookSeriesId
//             IsB2CBookSeries
//             IngestionId
//             BookSeriesName {
//               Lang
//               BookSeriesName
//             }
//             BookSeriesImage {
//               FilePath
//             }
//             EBookLinkage {
//               UsefulToolsLangs {
//                 Lang
//                 ToolsName
//               }
//               WebURL
//               WebURLChi
//               IOSAppId
//               IOSAppURL
//               IOSAppURLChi
//               IOSAppName
//               AndroidAppId
//               AndroidAppURL
//               AndroidAppURLChi
//               WebSupported
//               TabletSupported
//               MobileSupported
//               PCSupported
//               ToolsIconFile {
//                 FilePath
//                 FileName
//               }
//             }
//             MasterWebLinkage {
//               UsefulToolsLangs {
//                 Lang
//                 ToolsName
//               }
//               WebURL
//               WebURLChi
//               IOSAppId
//               IOSAppURL
//               IOSAppURLChi
//               IOSAppName
//               AndroidAppId
//               AndroidAppURL
//               AndroidAppURLChi
//               WebSupported
//               TabletSupported
//               MobileSupported
//               PCSupported
//               ToolsIconFile {
//                 FilePath
//                 FileName
//               }
//             }
//             DwbLinkage {
//               UsefulToolsLangs {
//                 Lang
//                 ToolsName
//               }
//               WebURL
//               WebURLChi
//               IOSAppId
//               IOSAppURL
//               IOSAppURLChi
//               IOSAppName
//               AndroidAppId
//               AndroidAppURL
//               AndroidAppURLChi
//               WebSupported
//               TabletSupported
//               MobileSupported
//               PCSupported
//               ToolsIconFile {
//                 FilePath
//                 FileName
//               }
//             }
//             UsefulTools {
//               id
//               ToolsCode
//               ToolsType
//               ApplicableRole
//               ToolsIconFile {
//                 FileName
//                 FilePath
//                 LastUpdateDate
//               }
//               UsefulToolsLangs {
//                 Lang
//                 ToolsName
//                 Description
//                 UsefulToolsId
//               }
//               WebURL
//               WebURLChi
//               IOSAppId
//               IOSAppURL
//               IOSAppURLChi
//               IOSAppName
//               Subject {
//                 SubjectCode
//                 SubjectName {
//                   Lang
//                   SubjectName
//                 }
//                 DisplaySequence
//                 BookSeries {
//                   BookSeriesName {
//                     Lang
//                     BookSeriesName
//                   }
//                 }
//               }
//               SubjectId
//               AndroidAppId
//               AndroidAppURL
//               AndroidAppURLChi
//               WebSupported
//               TabletSupported
//               MobileSupported
//               PCSupported
//               ApplicableRole
//               EnterDate
//               LastUpdateDate
//               DisplaySequence
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const MySchoolBookSeriesDataQuery = graphql`
  query MySchoolBookSeriesDataQuery($UserId: String!) {
    user(UserId: $UserId) {
      UserId
      AdoptedBookSeries {
        BookSeriesId
        IsB2CBookSeries
        IngestionId
        DisplaySequence
        BookSeriesName {
          Lang
          BookSeriesName
        }
        BookSeriesImage {
          FilePath
        }
        Books {
          id
          BookId
          BookName {
            Lang
            BookName
          }
          BookImage{
            FilePath
          }
          PearsonLevelId
          PearsonLevel {
            PearsonLevelId
            LevelName {
              PearsonLevelId
              Lang
              LevelName
            }
          }
        }
        UsefulTools {
          id
          ToolsCode
          ToolsType
          ApplicableRole
          ToolsIconFile {
            FileName
            FilePath
            LastUpdateDate
          }
          UsefulToolsLangs {
            Lang
            ToolsName
            Description
            UsefulToolsId
          }
          WebURL
          WebURLChi
          IOSAppId
          IOSAppURL
          IOSAppURLChi
          IOSAppName
          Subject {
            SubjectCode
            SubjectName {
              Lang
              SubjectName
            }
            DisplaySequence
            BookSeries {
              BookSeriesName {
                Lang
                BookSeriesName
              }
            }
          }
          SubjectId
          AndroidAppId
          AndroidAppURL
          AndroidAppURLChi
          WebSupported
          TabletSupported
          MobileSupported
          PCSupported
          ApplicableRole
          EnterDate
          LastUpdateDate
          DisplaySequence
        }
      }
    }
  }
`;

const MySchoolBookSeriesData = (variables, checkIsEmpty, role) => {
  return fetchQuery(
    environment,
    !checkIsEmpty
      ? MySchoolBookSeriesDataQuery
      : role == "T"
      ? MySchoolBookSeriesDataTeacherCheckingQuery
      : MySchoolBookSeriesDataStudentCheckingQuery,
    variables
  );
};

export default MySchoolBookSeriesData;
