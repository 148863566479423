import React, { useEffect, useState } from "react";
import "./NavBarPrizm.scss";
import { useDispatch, useSelector } from "react-redux";
import {isAndroid, isIOS} from "react-device-detect";
import useIosOpenPrizm from "../../../../hooks/useIosOpenPrizm";
import useEncryption from "../../../../hooks/useEncryption";
// import useIdleTime from "../../../../hooks/useIdleTime";
const NavBarPrizm = (props) => {
  console.log(props);
  const { resourceData, bookData, prizmBookId, t, showLoader, setShowLoader,intervalId,reActivateAutoSave } = props;
  console.log("book", bookData);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  console.log("Siren",selectOptions)
  const selectedSchoolYear = useSelector(
      (state) => state.SystemReducer.selectedSchoolYear
  );
  const dispatch = useDispatch();
  const [currentBook, setcurrentBook] = useState([]);
  const {iosOpenPrizm} = useIosOpenPrizm();
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;
  //var timerIdleActivity = useIdleTime(60);

  // useEffect(() => {
  //   if(timerIdleActivity== 0){
  //     //clearInterval(intervalId)
  //     onClickResource();
  //   }
  // },[timerIdleActivity])


  useEffect(() => {
    console.log("CurrentLoc",props.location.pathname.indexOf("/start"))
    //alert("coming")
    var listArray = bookData?.Books.filter((o) => o.BookId == prizmBookId);
    console.log("kkkk", prizmBookId, listArray);
    setcurrentBook(listArray);
  }, []);
  const doOpenExercise = () => {
    var sarg = props.match.params.id;
    if (isIOS) {
      iosOpenPrizm(props, resourceData.ResourceLangs[0]?.ResourceName, resourceData.ResourceId, "",  selectedSchoolYear.SchoolYearId, encrypt(userCredential), {BookSeriesId:sarg});
      return;
    }
    props.history.push({
      pathname:
        "/a/studygroup/" +
        sarg +
        "/assessment/" +
        "prizm&" +
        prizmBookId +
        "/" +
        resourceData.ResourceId +
        "/start",
      state: {
        backURL: "Result",
        previewURL: "",
        group_id: props.location.state.group_id,
        leftMenu: "",
        bookVal: prizmBookId,
      },
    });
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {
        selectedStudyGroupId: selectOptions.selectedStudyGroupId,
        selectedStudyGroupName: selectOptions.selectedStudyGroupName,
        selectedResourceName:selectOptions.selectedResourceName,
        selectecResourceTab:selectOptions.selectecResourceTab,
        selectedToggle: selectOptions.selectedToggle,
        backpathRes:selectOptions?.backpathRes?selectOptions.backpathRes:"SDL"
      },
    });
  };

  const doOpenResult = () => {
    //console.log("test")
    props.history.push({
      pathname:
        "/a/studygroup/" +
        props.match.params.id +
        "/assessment/" +
        "prizm&" +
        prizmBookId +
        "/" +
        resourceData.ResourceId +
        "/result",
      state: {
        group_id: selectOptions.selectedStudyGroupId,
        leftMenu: "",
        bookVal: prizmBookId,
      },
    });
    //alert(tabClassId);
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {
        selectedStudyGroupId: selectOptions.selectedStudyGroupId,
        selectedStudyGroupName: selectOptions.selectedStudyGroupName,
        selectedResourceName:selectOptions.selectedResourceName,
        selectecResourceTab:selectOptions.selectecResourceTab,
        selectedToggle: selectOptions.selectedToggle,
        setselectedResAssignId: "",
        selectedReportType: "",
        selectedAssessType: "",
        firstLevel: "", //activeFetchId,
        secondLevel: "", //bredCrumRes[1],
        thirdLevel: "", //bredCrumRes[2],
        backpathRes:selectOptions.backpathRes
      },
    });
  };

  const onClickResource = () => {
    if(props.location.pathname.indexOf("/start") != -1){
      console.log("Stop", intervalId)
      clearInterval(intervalId)
    }
    
    console.log("CurrentLoc",props.location.pathname.indexOf("/start"))

    if (window.cordova) {
      if((selectOptions.backpathRes == "Dashboard" || !selectOptions.backpathRes)){
        console.log("selectOptions.backpathRes:", selectOptions.backpathRes)
        props.history.push("/m");
      }else {
        props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");
        dispatch({
          type: "SET_SELECTED_BOOK",
          selectOptions: {
            selectedBookId: currentBook[0].BookId,
            selectedBookName: currentBook[0].BookName[0].BookName,
            selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
            selectedBookSeriesId: bookData.BookSeriesId,
            selectedResourceName:selectOptions.selectedResourceName,
            selectedClassLevelId: selectOptions.selectedStudyGroupId,
            selectedClassLevelName: selectOptions.selectedStudyGroupName,
            selectecResourceTab:selectOptions.selectecResourceTab,
            seletctedTabSDL:selectOptions.seletctedTabSDL,
            selectedToggle: selectOptions.selectedToggle,
          },
        });
      }
      return;
    }
      
    if((selectOptions.backpathRes == "Dashboard" || !selectOptions.backpathRes) && props.location.pathname.indexOf("/start") != -1){
      props.history.push("/m");
    }else {
      props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");

      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: currentBook[0].BookId,
          selectedBookName: currentBook[0].BookName[0].BookName,
          selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
          selectedBookSeriesId: bookData.BookSeriesId,
          selectedResourceName:selectOptions.selectedResourceName,
          selectedClassLevelId: selectOptions.selectedStudyGroupId,
          selectedClassLevelName: selectOptions.selectedStudyGroupName,
          selectecResourceTab:selectOptions.selectecResourceTab,
          seletctedTabSDL:selectOptions.seletctedTabSDL,
          selectedToggle: selectOptions.selectedToggle,

        },
      });
    }
    
 
  };

  const onClickResourceBK = () => {
    if (props.location.state.backURL == "Result") {
      props.history.push({
        pathname:
          "/a/studygroup/" +
          props.match.params.id +
          "/assessment/" +
          "prizm&" +
          prizmBookId +
          "/" +
          resourceData.ResourceId +
          "/result",
        state: {
          group_id: selectOptions.selectedStudyGroupId,
          leftMenu: "",
          bookVal: prizmBookId,
        },
      });
      //alert(tabClassId);

      dispatch({
        type: "SET_SELECTED_TYPE",
        selectOptions: {
          selectedStudyGroupId: selectOptions.selectedStudyGroupId,
          selectedStudyGroupName: selectOptions.selectedStudyGroupName,
          setselectedResAssignId: "",
          selectedReportType: "",
          selectedAssessType: "",
          firstLevel: "", //activeFetchId,
          secondLevel: "", //bredCrumRes[1],
          thirdLevel: "", //bredCrumRes[2],
        },
      });
    } else {
      props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");

      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: currentBook[0].BookId,
          selectedBookName: currentBook[0].BookName[0].BookName,
          selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
          selectedBookSeriesId: bookData.BookSeriesId,
          selectedClassLevelId: selectOptions.selectedStudyGroupId,
          selectedClassLevelName: selectOptions.selectedStudyGroupName,
        },
      });
    }
  };
  
  

  return (
    <>
    <div className="main__iframe-navigation">
      <h3 className="main__iframe-heading">
        {" "}
        {
          resourceData.ResourceLangs[
            resourceData.ResourceLangs[0].Lang == lang ? 0 : 1
          ].ResourceName
        }
        {/* {timerIdleActivity} */}
      </h3>
      <div className="main__iframe-righ-section">
        {/* <button className="main__iframe-result-btn">Student's Result</button> */}
        {props.location.pathname.indexOf("/result") != -1 ? (
          <button
            class="btns buttons main__iframe-result-btn"
            onClick={() => doOpenExercise()}
          >
            <span class="mdi-open-in-new-sdl-preview-active"></span>
            {/* {t("Preview Excercise")} */}
            {t("Preview exercise")}
          </button>
        ) : UserType == "T" ? (
          <button
            class="btns buttons main__iframe-result-btn"
            onClick={() => doOpenResult()}
          >
            <span class="mdi-open-in-new-sdl-student-active"></span>
            {/* {t("Student's Result")} */}
            {t("Class results")}
          </button>
        ) : (
          ""
        )}

        {/* {true && (
          <button class="btns buttons main__iframe-result-btn">
            <span class="mdi-open-in-new-sdl-modal-preview-active"></span>Model
            Answer
          </button>
        )} */}

        <button
          className="btns buttons main__iframe-close-btn"
          onClick={() => onClickResource()}
        >
          <span class="mdi-open-in-new-sdl-close"></span>
          {t("Close")}
        </button>
      </div>
    </div>
    </>
  );
};

export default NavBarPrizm;