import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function index() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    gaTrack("send", "pageview", pathname);
  }, [pathname]);

  return null;
}
