//Main
import React, { useState, useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
// var NavLink = require("react-router-dom").NavLink;
import i18n from "../../i18n";
//Component
// import Carousel from "./Carousel/carousel";
// import Banner from "./Banner/banner";
// import UserThink from "./UserThink/UserThink";
import LoginBanner from "./LoginBanner/LoginBanner";
import Footer from "../Footer/Footer";
import { config } from "../../Config/config";
import { isMobileOnly } from "react-device-detect";
import useConfig from "../../hooks/useConfig";
import "./MainIndex.scss";

//Css
//import languageIcon from '../../assets/img/system/language-24px.svg';
//import loginIcon from '../../assets/img/system/person-24px.svg'
// import React from "react";

const MainIndex = (props) => {
  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const { t } = props;
  const [loading, setloading] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(props.isLoggedIn);
  const [clickLogoCount, setClickLogoCount] = useState(0);
  const { setShowConfigBox, ConfigModal } = useConfig();
  const [isOpen, setIsOpen] = useState(false);
  const [isclose, setIsclose] = useState(true);
  const openpopupHandler = () => {
      setIsOpen(true);
      setIsclose(false);
  };
  const closepoupHandler = () => {
      setIsclose(true);
      setIsOpen(false);
  };
  const logoCountIncre = () => {
    if (clickLogoCount < 10){
      setClickLogoCount(prev => prev+1);
      return;
    }
    if (!window.cordova) {
      console.log("not cordova");
      return;
    }
    setShowConfigBox(true);
    setClickLogoCount(0);
  }
  useEffect(() => {
    setisLoggedIn(props.isLoggedIn);
  }, [props.isLoggedIn]);
  useEffect(() => {
    setloading(false);
  }, []);
  const [RightpanelOpen, setRightOpen] = useState(false);
  const openrightpanelHandler = () => {
      setRightOpen(true);
    };
    const closeRightPanelHandler = () => {
      setRightOpen(false);
    }
    const handleChildClick = (e) => {
      e.stopPropagation();  
    }
    
  if (userData) return <Redirect to="/m/myschool" />;
  return (    
    <div className="be-container">
     {isOpen && <div class="overlay-container" onClick={closepoupHandler} style={{display: isOpen ? 'block' : 'none'}}>
        <div class="overlay">
            <div class="overlay-box" onClick={handleChildClick}>
            <div class="close-btn" onClick={closepoupHandler}><span></span></div>
                <div class="overlay-title">Download PEC on your device</div>               
                <div class="overlay-qr">
                    <a href="https://apps.apple.com/us/app/pearson-english-connect-hk/id6448390998" target="_blank" class="overlay-qr-link">
                        <div class="qr-code-group">
                        <span class="app-icon" ></span>
                            <img class="qr-img" src="https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=https://apps.apple.com/us/app/pearson-english-connect-hk/id6448390998&choe=UTF-8&chld=M|3" alt="" />
                        </div>
                        <div class="overlay-qr-title">
                         <button type="button" class="cls_applestore" alt="Apple Store"></button>  
                         </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.pearson.pec" target="_blank" class="overlay-qr-link">
                        <div class="qr-code-group">
                            <span class="app-icon" ></span>
                            <img class="qr-img" src="https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=https://play.google.com/store/apps/details?id=com.pearson.pec&choe=UTF-8&chld=M|3" alt="" />
                        </div>
                        <div class="overlay-qr-title">
                          <button type="button" class="cls_playstore" alt="Play Store"></button>   
                        </div>
                    </a>
                </div>                    
            </div>
        </div>
    </div> }   
    <div id="right-panel-overlay" onClick={closeRightPanelHandler} className={RightpanelOpen ? 'open' : ''}>
        <div id="right-panel" className={RightpanelOpen ? 'open' : ''}>           
             <span id="right-panel-close" onClick={closeRightPanelHandler}>Close</span>         
            <div id="right-panel-content" onClick={handleChildClick}>
                <h1 class="panel-title">Failed to sign into Pearson application?</h1>
                <p class="panel-underline-subtitle">iOS system:</p>
                <p>On your iPad:</p>
                <ul>
                    <li>You may refer to <a href="https://support.apple.com/en-us/HT212063" target="_blank" class="link">https://support.apple.com/en-us/HT212063</a></li>
                </ul>
                <p>On your iPhone or iPod touch:</p>
                <ul>
                    <li>You may refer to <a href="https://support.apple.com/en-us/HT201330" target="_blank" class="link">https://support.apple.com/en-us/HT201330</a></li>
                </ul>
                <p class="panel-underline-subtitle">Android system:</p>
                <ul>
                    <li>You may refer to <a href="https://support.google.com/android/answer/9079646?hl=en-GB" target="_blank" class="link">https://support.google.com/android/answer/9079646?hl=en-GB</a></li>
                </ul>
                <p class="panel-underline-subtitle">In Chrome:</p>
                <ol>
                    <li>On your computer, open Chrome.</li>
                    <li>At the top right corner, click More.</li>
                    <li>Click More tools and then Clear browsing data.</li>
                    <li>At the top of the page, choose a time range. To delete everything, select 'All time'.</li>
                    <li>Check the boxes for 'Cookies and other site data' and 'Cached images and files'.</li>
                    <li>Click Clear data.</li>
                </ol>

            </div>
            
        </div>
    </div>    
      <div className="main-header">      
        <nav
          id="main"
          className="navbar navbar-expand fixed-top class-nav-bar md-whiteframe-4dp"
        >
          <div className="main_nav_container">
            <div className="be-navbar-header class-nb-exit">
              <span
                className={
                  config.pickhHostVal == "PEC"
                    ? `navbar-brand-pec`
                    : `navbar-brand`
                }
                href=""
                onClick={() => {
                  gaTrack(
                    "send",
                    "event",
                    "General",
                    "Click top logo",
                    "Role:" + role.UserType
                  );
                }}
              />
            </div>
            {config.pickhHostVal == "PEC" && !isMobileOnly && (
              <div className="pear-eng-con-text">Pearson English Connect</div>
            )}
            <div className="class-nb-title" />
            <div className="class-nb-buttons">
              <div
                className="nav navbar-nav float-right be-user-nav"
                id="be-navbar-collapse"
              >
                <ul className="navbar-nav">
                  <li className="nav-item test">
                  <button
                    type="button"
                    className="link-button nav-link" onClick={() => {
                      openrightpanelHandler()
                    }}>
                       <i className="icon mdi mdi-help" />
                        {t("Help")}
                    </button>
                    {window.cordova ? (
                      <NavLink
                        to="/login"
                        onClick={() => {
                          gaTrack(
                            "send",
                            "event",
                            "Landing page",
                            "Login",
                            "Top bar"
                          );
                        }}
                      >
                        <button type="button" className="link-button nav-link">
                          <i className="icon mdi mdi-account-o" />
                          {t("Login")}
                        </button>
                      </NavLink>
                    ) : (
                      <button
                        type="button"
                        className="link-button nav-link"
                        onClick={() => {
                          // i18n.changeLanguage("zh-HK");
                          gaTrack(
                            "send",
                            "event",
                            "Landing page",
                            "Login",
                            "Main area"
                          );

                          var IESlang = "en-US";
                          var IESlang2 = "en-US";
                          if (localStorage.getItem("i18nextLng-inapp")) {
                            IESlang2 = localStorage.getItem("i18nextLng-inapp");
                            if (
                              localStorage.getItem("i18nextLng-inapp") !=
                              "en-US"
                            ) {
                              IESlang = "zh_TW";
                            }
                          }

                          let currentHost =
                            "https:%2F%2F" + window.location.hostname;
                          let IESurl =
                            config.IESURL +
                            "?lang=" +
                            IESlang +
                            "&back_to=" +
                            config.IESBACKURL +
                            "%3Flang%3D" +
                            IESlang2 +
                            "%26back_to%3D" +
                            currentHost;
                          window.open(IESurl, "_self");
                        }}
                      >
                        <i className="icon mdi mdi-account-o" />
                        {t("Login")}
                      </button>
                    )
                    }
                   
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {!loading ? (
        <div className="be-content card-pb mainindex before-login-banner">
          {/*
      <Carousel />
      <Banner />
      <UserThink />
      <LoginBanner />
      <Footer />
      */}
          <LoginBanner {...props} t={t} logoCount={logoCountIncre} openpopupHandler={openpopupHandler}/>
          <ConfigModal />
        </div>
      ) : (
        <div className="splash-container">
          <div className="loader mx-auto" />
        </div>
      )}
      <Footer />
    </div>  
  );
};

export default MainIndex;

// class _MainIndex extends Component {
//   _isMounted = false;
//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: true,
//       isLoggedIn: props.isLoggedIn,
//     };
//   }

//   componentWillReceiveProps(props) {
//     this.setState({ isLoggedIn: props.isLoggedIn });
//   }

//   componentWillMount() {
//     this.setState({ loading: true });
//     this._isMounted = false;
//   }

//   componentDidMount() {
//     this._isMounted = true;
//     if (this._isMounted) {
//       this.setState({ loading: false });
//     }

//     window.scrollTo(0, 0);
//   }

//   render() {
//     if (
//       localStorage.getItem("accessToken") &&
//       localStorage.getItem("userData")
//     ) {
//       return <Redirect to="/m/myschool" />;
//     } else {
//       const { loading } = this.state;
//       const { t } = this.props;
//       const UserData = JSON.parse(localStorage.getItem("userData"));
//       return (
//         <div className="be-container">
//           <div className="main-header">
//             <nav
//               id="main"
//               className="navbar navbar-expand fixed-top class-nav-bar md-whiteframe-4dp"
//             >
//               <div className="main_nav_container">
//                 <div className="be-navbar-header class-nb-exit">
//                   <span
//                     className="navbar-brand"
//                     href=""
//                     onClick={() => {
//                       gaTrack(
//                         "send",
//                         "event",
//                         "General",
//                         "Click top logo",
//                         "Role:" + UserData.UserType
//                       );
//                     }}
//                   />
//                 </div>
//                 <div className="class-nb-title" />
//                 <div className="class-nb-buttons">
//                   <div
//                     className="nav navbar-nav float-right be-user-nav"
//                     id="be-navbar-collapse"
//                   >
//                     <ul className="navbar-nav">
//                       <li className="nav-item">
//                         {localStorage.getItem("i18nextLng") == "en-US" ? (
//                           <button
//                             type="button"
//                             className="link-button nav-link"
//                             onClick={() => {
//                               i18n.changeLanguage("zh-HK");
//                               gaTrack(
//                                 "send",
//                                 "event",
//                                 "Landing page",
//                                 "Change language",
//                                 "Language:Chinese"
//                               );
//                             }}
//                           >
//                             <i className="icon mdi mdi-globe-alt" />
//                             {t("Chinese")}
//                           </button>
//                         ) : (
//                           <button
//                             type="button"
//                             className="link-button nav-link"
//                             onClick={() => {
//                               i18n.changeLanguage("en-US");
//                               gaTrack(
//                                 "send",
//                                 "event",
//                                 "Landing page",
//                                 "Change language",
//                                 "Language:English"
//                               );
//                             }}
//                           >
//                             <i className="icon mdi mdi-globe-alt" />
//                             {t("English")}
//                           </button>
//                         )}
//                       </li>
//                       <li className="nav-item">
//                         <NavLink
//                           to="/login"
//                           onClick={() => {
//                             gaTrack(
//                               "send",
//                               "event",
//                               "Landing page",
//                               "Login",
//                               "Top bar"
//                             );
//                           }}
//                         >
//                           <button
//                             type="button"
//                             className="link-button nav-link"
//                           >
//                             <i className="" />
//                             {t("Login")}
//                           </button>
//                         </NavLink>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </nav>
//           </div>

//           {!loading ? (
//             <div className="be-content card-pb mainindex">
//               {/*
// 							<Carousel />
// 							<Banner />
// 							<UserThink />
// 							<LoginBanner />
// 							<Footer />
// 							*/}
//               <LoginBanner {...this.props} t={t} />
//             </div>
//           ) : (
//             <div className="splash-container">
//               <div className="loader mx-auto" />
//             </div>
//           )}
//           <Footer />
//         </div>
//       );
//     }
//   }
// }

// export default MainIndex;
