const useInAppBrowser = () => {

    const CORDOVA_OPEN_TARGET_SETTING = '_blank';
    const CORDOVA_REDIRECT_TARGET_SETTING = '_self';
    const CORDOVA_SYSTEM_BROWSER_TARGET_SETTING = "_system";
    //Android
    const IN_APP_BROWSER_ANDROID_SETTING = 'hidenavigationbuttons=yes';
    const IN_APP_BROWSER_ANDROID_SETTING2 = 'hideurlbar=yes';
    //iOS
    const IN_APP_BROWSER_IOS_SETTING = 'location=no';

    const openLink = (targetUrl) => {
        //Not cordova
        if (!window.cordova) {
            return window.open(targetUrl, "_blank");
        }

        //InAppBrowser params for Android
        if (window.cordova.platformId === "android") {
            return cordova.InAppBrowser.open(
                targetUrl,
                CORDOVA_OPEN_TARGET_SETTING,
                IN_APP_BROWSER_ANDROID_SETTING,
                IN_APP_BROWSER_ANDROID_SETTING2
            );
        }

        //InAppBrowser params for iOS
        return cordova.InAppBrowser.open(
            targetUrl,
            CORDOVA_OPEN_TARGET_SETTING,
            IN_APP_BROWSER_IOS_SETTING
        );
    }

    const redirectLink = (targetUrl) => {
        //Not cordova
        if (!window.cordova) {
            return window.open(targetUrl, "_system");
        }

        //InAppBrowser params for Android
        if (window.cordova.platformId === "android") {
            return cordova.InAppBrowser.open(
                targetUrl,
                CORDOVA_REDIRECT_TARGET_SETTING,
                IN_APP_BROWSER_ANDROID_SETTING,
                IN_APP_BROWSER_ANDROID_SETTING2
            );
        }

        //InAppBrowser params for iOS
        return cordova.InAppBrowser.open(
            targetUrl,
            CORDOVA_REDIRECT_TARGET_SETTING,
            IN_APP_BROWSER_IOS_SETTING
        );
    }

    const systemBrowserOpenLink = (targetUrl) => {
        //Not cordova
        if (!window.cordova) {
            return window.open(targetUrl, "_system");
        }

        //InAppBrowser params for Android
        if (window.cordova.platformId === "android") {
            return cordova.InAppBrowser.open(
                targetUrl,
                CORDOVA_SYSTEM_BROWSER_TARGET_SETTING,
                IN_APP_BROWSER_ANDROID_SETTING,
                IN_APP_BROWSER_ANDROID_SETTING2
            );
        }

        //InAppBrowser params for iOS
        return cordova.InAppBrowser.open(
            targetUrl,
            CORDOVA_SYSTEM_BROWSER_TARGET_SETTING,
            IN_APP_BROWSER_IOS_SETTING
        );
    }

    return { openLink, redirectLink, systemBrowserOpenLink };
}

export default useInAppBrowser;