/**
 * @generated SignedSource<<5f4ef3f58ac7f592b438770a338cbefe>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type OnBoardingMutation$variables = {|
  SchoolYearId: string,
  StudyGroupIds: $ReadOnlyArray<?string>,
|};
export type OnBoardingMutation$data = {|
  +teacherOnboard: ?string,
|};
export type OnBoardingMutation = {|
  variables: OnBoardingMutation$variables,
  response: OnBoardingMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "SchoolYearId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "StudyGroupIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupIds",
        "variableName": "StudyGroupIds"
      }
    ],
    "kind": "ScalarField",
    "name": "teacherOnboard",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnBoardingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnBoardingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f57df9dcbcc86ea09be1e72d819906c7",
    "id": null,
    "metadata": {},
    "name": "OnBoardingMutation",
    "operationKind": "mutation",
    "text": "mutation OnBoardingMutation(\n  $SchoolYearId: String!\n  $StudyGroupIds: [String]!\n) {\n  teacherOnboard(SchoolYearId: $SchoolYearId, StudyGroupIds: $StudyGroupIds)\n}\n"
  }
};
})();

(node/*: any*/).hash = "2389804da26d4df6d2cbc2184b5a1369";

module.exports = ((node/*: any*/)/*: Mutation<
  OnBoardingMutation$variables,
  OnBoardingMutation$data,
>*/);
