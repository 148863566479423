/**
 * @generated SignedSource<<f8a4b847ba986689bfe286195416b8dd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GetB2CAttemptHistoryQuery$variables = {|
  UserId: string,
  ResourceId: string,
  ResourceIds?: ?$ReadOnlyArray<string>,
|};
export type GetB2CAttemptHistoryQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +IsCompleted: ?string,
      +AttemptSessionId: ?string,
      +AttemptDate: ?string,
      +UserId: ?string,
      +Score: ?string,
      +MaxScore: ?string,
      +LearnositySessionRawResult: ?string,
    |}>,
  |},
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +TagData: ?string,
      +LearnosityReportStatistic: ?string,
      +LearnosityTagHierarchyRef: ?string,
      +LearnositySuggestionMapping: ?$ReadOnlyArray<?{|
        +TagName: ?string,
        +TagObjectiveName: ?string,
        +Criteria: ?string,
        +ConditionRule: ?string,
        +TagType: ?string,
        +MappedResources: ?$ReadOnlyArray<?{|
          +DisplayOrder: ?string,
          +Resource: ?{|
            +IngestionId: ?string,
            +ResourceId: ?string,
            +RelatedId: ?string,
            +ResourceDownloadUrl: ?string,
            +ResourceFileNameIfAny: ?string,
            +ResourceAccessUrl: ?string,
            +ResourceType: ?string,
            +Linkage: ?string,
            +FileSource: ?string,
            +Downloadable: ?string,
            +ResourceLangs: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +ResourceName: ?string,
            |}>,
          |},
        |}>,
      |}>,
    |}>,
  |},
|};
export type GetB2CAttemptHistoryQuery = {|
  variables: GetB2CAttemptHistoryQuery$variables,
  response: GetB2CAttemptHistoryQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "kind": "LinkedField",
  "name": "SelfLearnAttemptHistory",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IsCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptSessionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptDate",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "MaxScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LearnositySessionRawResult",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceIds"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagData",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnosityReportStatistic",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnosityTagHierarchyRef",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagObjectiveName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Criteria",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ConditionRule",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplayOrder",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceDownloadUrl",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceFileNameIfAny",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Linkage",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileSource",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Downloadable",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetB2CAttemptHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "resourceLearnositySuggestionMapping",
                "kind": "LinkedField",
                "name": "LearnositySuggestionMapping",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMappingResource",
                    "kind": "LinkedField",
                    "name": "MappedResources",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resource",
                        "kind": "LinkedField",
                        "name": "Resource",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v5/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetB2CAttemptHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "resourceLearnositySuggestionMapping",
                "kind": "LinkedField",
                "name": "LearnositySuggestionMapping",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMappingResource",
                    "kind": "LinkedField",
                    "name": "MappedResources",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resource",
                        "kind": "LinkedField",
                        "name": "Resource",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v5/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v27/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v27/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84a7451f53cb3d0fe9005dcc07bc04a0",
    "id": null,
    "metadata": {},
    "name": "GetB2CAttemptHistoryQuery",
    "operationKind": "query",
    "text": "query GetB2CAttemptHistoryQuery(\n  $UserId: String!\n  $ResourceId: String!\n  $ResourceIds: [String!]\n) {\n  user(UserId: $UserId) {\n    UserId\n    SelfLearnAttemptHistory(ResourceId: $ResourceId) {\n      ResourceId\n      IsCompleted\n      AttemptSessionId\n      AttemptDate\n      UserId\n      Score\n      MaxScore\n      LearnositySessionRawResult\n    }\n    id\n  }\n  resourceSearch(ResourceId: $ResourceIds) {\n    Resources {\n      TagData\n      LearnosityReportStatistic\n      LearnosityTagHierarchyRef\n      LearnositySuggestionMapping {\n        TagName\n        TagObjectiveName\n        Criteria\n        ConditionRule\n        TagType\n        MappedResources {\n          DisplayOrder\n          Resource {\n            IngestionId\n            ResourceId\n            RelatedId\n            ResourceDownloadUrl\n            ResourceFileNameIfAny\n            ResourceAccessUrl\n            ResourceType\n            Linkage\n            FileSource\n            Downloadable\n            ResourceLangs {\n              Lang\n              ResourceName\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "07b478a111f5543b04283cff5224ae4a";

module.exports = ((node/*: any*/)/*: Query<
  GetB2CAttemptHistoryQuery$variables,
  GetB2CAttemptHistoryQuery$data,
>*/);
