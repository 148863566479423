import React from "react";
import goldMedal from "../../assets/img/gold-medal.svg";
import silverMedal from "../../assets/img/silver.svg";
import bronzeMedal from "../../assets/img/bronze.svg";
import redMedal from "../../assets/img/red.svg";
import greenMedal from "../../assets/img/green.svg";
import emptyMedal from "../../assets/img/empty.svg";

export const renderMedalImage = (score) => {
  let medal = goldMedal;
  if (score == null) return <img src={emptyMedal}></img>;
  if (score <= 49) medal = redMedal;
  if (score <= 69 && score >= 50) medal = greenMedal;
  if (score <= 79 && score >= 70) medal = bronzeMedal;
  if (score <= 89 && score >= 80) medal = silverMedal;

  return <img src={medal}></img>;
};
