import React, {useState} from "react";
import Button from "../../Components/Feature/Button";
import {config} from "../../Config/config";
import logo from "../../assets/img/pearson-logo.svg";
import useInAppBrowser from "../../hooks/useInAppBrowser";

const LoginCard = (props) => {

    const {formRef, isLoginFailed, onFormSubmit, t, isSAMDown, onClickOpenLink,
        showUserNameInvalidMsg, setShowUserNameInvalidMsg, showPasswordInvalidMsg, setShowPasswordInvalidMsg} = props;
    const { openLink } = useInAppBrowser();

    return (
        <div className="logincard">
            <div className="card-header">
                <a style={{cursor: "pointer"}} onClick={() => props.history.push("/")}>
                    <img
                        className="logo-img"
                        src={logo}
                        alt="logo"
                        width="{conf.logoWidth}"
                        height="54"
                    />
                </a>
            </div>
            <span className="splash-description">
                  <h3>{t("Login to your account")}</h3>
                </span>
            <div className="card-body">
                <form
                    className="login-form"
                    id="verifyForm"
                    ref={formRef}
                    onSubmit={onFormSubmit}
                >
                    <div className="form__group">
                        <input
                            id="userName"
                            type="text"
                            autoFocus
                            name="text"
                            className={
                                showUserNameInvalidMsg ? "form-control error" : "form-control "
                            }
                            autoCapitalize="none"
                            autoComplete="off"
                            placeholder={t("Username")}
                            onChange={(e) => {
                                setShowUserNameInvalidMsg(false);
                            }}
                        />

                        {showUserNameInvalidMsg && (
                            <div className="error-text">
                                <span className="mdi mdi-info-outline"/>

                                {t("Please enter username")}
                            </div>
                        )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <input
                            className={
                                showPasswordInvalidMsg ? "form-control error" : "form-control "
                            }
                            id="passvalue"
                            type="password"
                            autoComplete="off"
                            placeholder={t("Password")}
                            onChange={(e) => {
                                setShowPasswordInvalidMsg(false);
                            }}
                        />
                        {showPasswordInvalidMsg && (
                            <div className="error-text">
                                <span className="mdi mdi-info-outline"/>

                                {t("Please enter password")}
                            </div>
                        )}
                    </div>

                    {isLoginFailed ? (
                        <div className="form-group">
                            <p style={{color: "red"}}>
                                {t(
                                    "Sorry, we didn't recognize the username or password you entered. Please try again."
                                )}
                            </p>
                        </div>
                    ) : (
                        ""
                    )}
                    {isSAMDown && (
                        <div className="form-group">
                            <p style={{color: "red"}}>
                                {t("Sorry, can not access server. Please try again.")}
                            </p>
                        </div>
                    )}
                    <div className="form-group">
                        <div>
                            <Button
                                className="btn btn-primary btn-xl btn-block"
                                id="login-button"
                                type={"submit"}
                                content={t("Login")}
                            />
                        </div>
                    </div>
                    {/*<div className="new-platform">*/}
                    {/*    <div className="border-line"/>*/}
                    {/*    <span>{t("New to the platform?")}</span>*/}
                    {/*</div>*/}
                    <div className="form-group">
                        {/*<div>*/}
                        {/*    <a*/}
                        {/*        onClick={() =>*/}
                        {/*            onClickOpenLink(*/}
                        {/*                localStorage.getItem("i18nextLng") === "en-US"*/}
                        {/*                    ? config.createAccountURL*/}
                        {/*                    : config.createAccountURL,*/}
                        {/*                "CreateAccount"*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*        // target="_blank"*/}
                        {/*    >*/}
                        {/*        <Button*/}
                        {/*            type="button"*/}
                        {/*            className="btn btn-xl btn-block btn-secondary create-user"*/}
                        {/*            content={*/}
                        {/*                <>*/}
                        {/*                    {t("Create your account") + " "}*/}
                        {/*                    <i className="icon mdi mdi-open-in-new"/>*/}
                        {/*                </>*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <div className="forgetpassword cc">
                            <a
                                // target="_blank"
                                onClick={() => openLink(config.resetPasswordURL)}
                            >
                                {t("Forget your password?")}
                            </a>
                        </div>
                        {!window.cordova && (
                            <div className="recaptchapolicy">
                                This site is protected by reCAPTCHA and the Google
                                <a
                                    onClick={() =>
                                        openLink("https://policies.google.com/privacy")
                                    }
                                >
                                    {" "}
                                    Privacy Policy
                                </a>{" "}
                                and
                                <a
                                    onClick={() =>
                                        openLink("https://policies.google.com/terms")
                                    }
                                >
                                    {" "}
                                    Terms of Service
                                </a>{" "}
                                apply.
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginCard;