/**
 * @generated SignedSource<<bcf471625eed5ff0e9d81caedb25a22c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type TopicContentsQuery$variables = {|
  HierarchyReference: string,
  BookId: string,
  UserId: string,
|};
export type TopicContentsQuery$data = {|
  +learnosityTagHierarchyStructure: ?$ReadOnlyArray<?{|
    +DisplaySequence: ?string,
    +HierarchyReferenceLabelEn: ?string,
    +HierarchyReferenceLabelChi: ?string,
    +HierarchyReference: ?string,
    +RelatedTagType: ?$ReadOnlyArray<?{|
      +Tags: ?$ReadOnlyArray<?{|
        +TagType: ?string,
        +TagName: ?string,
        +TagLongName: ?string,
        +TagDisplayNameEn: ?string,
        +TagDisplayNameChi: ?string,
        +DisplaySequence: ?string,
      |}>,
    |}>,
    +AdoptedLearnosityResource: ?$ReadOnlyArray<?{|
      +LearnosityReferenceId: ?string,
      +Level1TagType: ?string,
      +Level1TagName: ?string,
      +Level2TagType: ?string,
      +Level2TagName: ?string,
      +RelatedResource: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
          +CompletedDate: ?string,
          +Score: ?string,
          +MaxScore: ?string,
          +IsCompleted: ?string,
        |}>,
      |}>,
    |}>,
  |}>,
|};
export type TopicContentsQuery = {|
  variables: TopicContentsQuery$variables,
  response: TopicContentsQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "HierarchyReference"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "HierarchyReference",
    "variableName": "HierarchyReference"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelEn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReferenceLabelChi",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HierarchyReference",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagLongName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagDisplayNameEn",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagDisplayNameChi",
  "storageKey": null
},
v13 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnosityReferenceId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level1TagType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level1TagName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level2TagType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level2TagName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "IncludeAssignmentRecord",
      "value": false
    },
    {
      "kind": "Variable",
      "name": "UserId",
      "variableName": "UserId"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "kind": "LinkedField",
  "name": "SelfLearnAttemptHistory",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "CompletedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "MaxScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IsCompleted",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "kind": "LinkedField",
        "name": "learnosityTagHierarchyStructure",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "kind": "LinkedField",
            "name": "RelatedTagType",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagType",
                "kind": "LinkedField",
                "name": "Tags",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "LearnosityActivityTagInfo",
            "kind": "LinkedField",
            "name": "AdoptedLearnosityResource",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "resource",
                "kind": "LinkedField",
                "name": "RelatedResource",
                "plural": true,
                "selections": [
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TopicContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LearnosityTagHierarchy",
        "kind": "LinkedField",
        "name": "learnosityTagHierarchyStructure",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnosityTagHierarchyTagTypeType",
            "kind": "LinkedField",
            "name": "RelatedTagType",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnosityTagHierarchyTagType",
                "kind": "LinkedField",
                "name": "Tags",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v4/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "LearnosityActivityTagInfo",
            "kind": "LinkedField",
            "name": "AdoptedLearnosityResource",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "resource",
                "kind": "LinkedField",
                "name": "RelatedResource",
                "plural": true,
                "selections": [
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd24fa1b745f409c8b3de4af447dffec",
    "id": null,
    "metadata": {},
    "name": "TopicContentsQuery",
    "operationKind": "query",
    "text": "query TopicContentsQuery(\n  $HierarchyReference: String!\n  $BookId: String!\n  $UserId: String!\n) {\n  learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {\n    DisplaySequence\n    HierarchyReferenceLabelEn\n    HierarchyReferenceLabelChi\n    HierarchyReference\n    RelatedTagType {\n      Tags {\n        TagType\n        TagName\n        TagLongName\n        TagDisplayNameEn\n        TagDisplayNameChi\n        DisplaySequence\n        id\n      }\n      id\n    }\n    AdoptedLearnosityResource(BookId: $BookId) {\n      LearnosityReferenceId\n      Level1TagType\n      Level1TagName\n      Level2TagType\n      Level2TagName\n      RelatedResource(BookId: $BookId) {\n        ResourceId\n        SelfLearnAttemptHistory(UserId: $UserId, IncludeAssignmentRecord: false) {\n          CompletedDate\n          Score\n          MaxScore\n          IsCompleted\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "566a2ace548db4703397b52acf245e8d";

module.exports = ((node/*: any*/)/*: Query<
  TopicContentsQuery$variables,
  TopicContentsQuery$data,
>*/);
