import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ResourceIconMapping from "../../Function/ResourceIconMapping";
// import "./DndAssignment.scss";

export default (props) => {
  const { assignmentList, lang, onChange, bookSeriesName } = props;
  const UserType = JSON.parse(localStorage.getItem("userData")).UserType;
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    reorder(result.source.index, result.destination.index);
  };

  const reorder = (startIndex, endIndex) => {
    const result = assignmentList.slice();
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    gaTrack(
      "send",
      "event",
      "Create assignment",
      "Reorder resource",
      "Book Series:" + bookSeriesName + ", Role:" + UserType
    );
    onChange(result);
  };

  const handleDeleteAssignment = (resourceId) => {
    const updatedAssignmentList = assignmentList.filter(
      (assignment) => assignment.ResourceId !== resourceId
    );
    onChange(updatedAssignmentList);
  };
  console.log(assignmentList);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "white" : "",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="assignment-container"
          >
            {assignmentList.map((assignment, index) => (
              <Draggable
                key={assignment.ResourceId}
                draggableId={assignment.ResourceId}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className="assignment-item"
                  >
                    <i className="icon mdi mdi-menu"></i>
                    <div
                      className="image_dnd"
                      style={{
                        backgroundImage: `url(${ResourceIconMapping(
                          assignment.ResourceType,
                          "small",
                          assignment.Linkage
                        )})`,
                      }}
                    ></div>
                    <span>
                      {
                        assignment.ResourceLangs[
                          assignment.ResourceLangs[0].Lang == lang ? 0 : 1
                        ].ResourceName
                      }
                    </span>
                    {assignmentList.length > 1 ? (
                      <i
                        className="icon mdi mdi-delete"
                        onClick={() => {
                          handleDeleteAssignment(assignment.ResourceId),
                            gaTrack(
                              "send",
                              "event",
                              "Create assignment",
                              "Remove resource",
                              "Book Series:" +
                                bookSeriesName +
                                ", Resource:" +
                                assignment.ResourceLangs[
                                  assignment.ResourceLangs[0].Lang == lang
                                    ? 0
                                    : 1
                                ].ResourceName +
                                ", Role:" +
                                UserType
                            );
                        }}
                      ></i>
                    ) : null}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
