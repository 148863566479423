import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const ModelAnswerTabObjectQuery = graphql`
  query ModelAnswerTabObjectQuery($ResourceId: [String]!, $Lang: LangEnumType) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        LearnosityModelAnswer(Lang: $Lang)
      }
    }
  }
`;

const ModelAnswerTabObject = (variables) => {
  return fetchQuery(environment, ModelAnswerTabObjectQuery, variables);
};

export default ModelAnswerTabObject;
