import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const StandAloneAssignmentQuery = graphql`
  query StandAloneAssignmentQuery(
    $ResourceId: [String]!
    $SchoolYearId: String!
  ) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        BookSeriesId
        ResourceType
        TagData
        RelatedId
        ResourceId
        IngestionId
        Book {
          BookName {
            BookName
            Lang
          }
          Units {
            UnitName {
              UnitName
              Lang
              UnitTitle
            }
          }
        }
        ResourceLangs {
          Lang
          ResourceName
        }
        AssignementInvloved(SchoolYearId: $SchoolYearId) {
          AssignmentName
          ResourceAssignId
          BookSeriesId
        }
      }
    }
  }
`;

const StandAloneAssignment = (variables) => {
  return fetchQuery(environment, StandAloneAssignmentQuery, variables);
};

export default StandAloneAssignment;
