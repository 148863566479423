import React, { useState } from "react";
import newAssignmentIcon from "../../../assets/img/new-assignment.svg";
import { Trans } from "react-i18next";
import ReadResourceAssignmentMutation from "../../Feature/Function/ReadResourceAssignmentMutation";
import GetDueDateStyle from "../../Feature/Function/GetDueDateStyle";
import moment from "moment";

const HandBookNoData = (props) => {
  const { t } = props;

  return (
    <>
      <div className="no-notification notification__handbook-no-notification">
        <div className="handbook-notification-text">
          {/* {t("There is no data")} */}
          {t("No notifications")}
        </div>
      </div>
    </>
  );
};

export default HandBookNoData;
