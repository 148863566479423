/**
 * @generated SignedSource<<ee2a4c07000266506d080f3a49eafcce>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type QuestionViewAllStudentsResultQuery$variables = {|
  StudyGroupId: string,
  SchoolYearId: string,
  ResourceId: $ReadOnlyArray<?string>,
  IncludeAssignmentRecord?: ?boolean,
|};
export type QuestionViewAllStudentsResultQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +SelfLearnLastAttemptHistoryByStudyGroup: ?$ReadOnlyArray<?{|
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
      +SelfLearnFirstAttemptHistoryByStudyGroup: ?$ReadOnlyArray<?{|
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
    |}>,
  |},
|};
export type QuestionViewAllStudentsResultQuery = {|
  variables: QuestionViewAllStudentsResultQuery$variables,
  response: QuestionViewAllStudentsResultQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "IncludeAssignmentRecord"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "IncludeAssignmentRecord",
    "variableName": "IncludeAssignmentRecord"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnositySessionRawResult",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "userLang",
  "kind": "LinkedField",
  "name": "UserDisplayName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "user",
    "kind": "LinkedField",
    "name": "UserDetail",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "user",
    "kind": "LinkedField",
    "name": "UserDetail",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v10/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionViewAllStudentsResultQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "SelfLearnLastAttemptHistoryByStudyGroup",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "SelfLearnFirstAttemptHistoryByStudyGroup",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuestionViewAllStudentsResultQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "SelfLearnLastAttemptHistoryByStudyGroup",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "SelfLearnFirstAttemptHistoryByStudyGroup",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e152364ae1944341cba24c8ad684e65",
    "id": null,
    "metadata": {},
    "name": "QuestionViewAllStudentsResultQuery",
    "operationKind": "query",
    "text": "query QuestionViewAllStudentsResultQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n  $ResourceId: [String]!\n  $IncludeAssignmentRecord: Boolean\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      SelfLearnLastAttemptHistoryByStudyGroup(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      SelfLearnFirstAttemptHistoryByStudyGroup(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "44753e32eb0efec235eda687b127cd4b";

module.exports = ((node/*: any*/)/*: Query<
  QuestionViewAllStudentsResultQuery$variables,
  QuestionViewAllStudentsResultQuery$data,
>*/);
