/**
 * @generated SignedSource<<10eaf48b81f67ea9430275abdd2fda75>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAssignmentMutation$variables = {|
  ResourceAssignIds: $ReadOnlyArray<?string>,
|};
export type DeleteAssignmentMutation$data = {|
  +deleteAssignment: ?boolean,
|};
export type DeleteAssignmentMutation = {|
  variables: DeleteAssignmentMutation$variables,
  response: DeleteAssignmentMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourceAssignIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceAssignIds",
        "variableName": "ResourceAssignIds"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteAssignment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAssignmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAssignmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f45b2fc703fd4071189b431eeeace75d",
    "id": null,
    "metadata": {},
    "name": "DeleteAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAssignmentMutation(\n  $ResourceAssignIds: [String]!\n) {\n  deleteAssignment(ResourceAssignIds: $ResourceAssignIds)\n}\n"
  }
};
})();

(node/*: any*/).hash = "c1b1d1f49053d3358215ac08bdf76de0";

module.exports = ((node/*: any*/)/*: Mutation<
  DeleteAssignmentMutation$variables,
  DeleteAssignmentMutation$data,
>*/);
