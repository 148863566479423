import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const ActivityLayoutGetAssessmentNameTeacherQuery = graphql`
  query ActivityLayoutGetAssessmentNameTeacherQuery(
    $ResourceId: [String]!
    $UserId: String!
    $BookSeriesId: String!
    $BookId: String
    $UnitId: String
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesId
        BookSeriesName {
          Lang
          BookSeriesName
        }
        Books(BookId: $BookId) {
          BookId
          BookName {
            Lang
            BookName
          }
          PearsonLevel {
            LevelName {
              Lang
              LevelName
            }
          }
          Units(UnitId: $UnitId) {
            UnitId
            UnitName {
              Lang
              UnitName
              UnitTitle
            }
          }
        }
      }
    }
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        RelatedId
        ResourceId
        IngestionId
        ResourceLangs {
          Lang
          ResourceName
        }
      }
    }
  }
`;

const ActivityLayoutGetAssessmentNameStudentQuery = graphql`
  query ActivityLayoutGetAssessmentNameStudentQuery(
    $ResourceId: [String]!
    $UserId: String!
    $BookSeriesId: String!
    $BookId: String
    $UnitId: String
    $StudyGroupId: String
    $SchoolYearId: String
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesId
        BookSeriesName {
          Lang
          BookSeriesName
        }
        Books(BookId: $BookId) {
          BookId
          BookName {
            Lang
            BookName
          }
          PearsonLevel {
            LevelName {
              Lang
              LevelName
            }
          }
          Units(UnitId: $UnitId) {
            UnitId
            UnitName {
              Lang
              UnitName
              UnitTitle
            }
          }
        }
      }
    }
    resourceSearch(ResourceId: $ResourceId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {
      Resources {
        RelatedId
        ResourceId
        IngestionId
        ResourceLangs {
          Lang
          ResourceName
        }
      }
    }
  }
`;
const ActivityLayoutGetAssessmentName = (variables, role) => {

  if (role == "T") {
    return fetchQuery(
      environment,
      ActivityLayoutGetAssessmentNameTeacherQuery,
      variables
    );
  }else {
    return fetchQuery(
      environment,
      ActivityLayoutGetAssessmentNameStudentQuery,
      variables
    );
  }
 
};

export default ActivityLayoutGetAssessmentName;
