import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetUserDataQuery = graphql`
query GetUserDataQuery($UserId: String!, $SchoolYearId: String!) {
  user(UserId: $UserId) {
    UserId
    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
      SchoolYearId
      ClassNumber
      StudyGroupId
      StudyGroup {
        StudyGroupId
        Level {
          PearsonLevelId
          LevelName {
            Lang
            LevelName
            PearsonLevelId
          }
          PearsonLevelId
          DisplaySequence
        }
        StudyGroupName {
          StudyGroupName
          Lang
        }
        StudyGroupCode
      }
    }
    UserName
    UserType
    UserDisplayName {
      Lang
      UserName
    }
    Preferences {
      PreferenceName
      PreferenceValue
      SchoolYearId
    }
    AdoptedSchoolYear {
      SchoolYearId
      SchoolYearName
      IsEffective
      EffectiveStartDate
      EffectiveEndDate
    }
    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {
      PreferenceName
      PreferenceValue
      SchoolYearId
    }
    School {
      SchoolCode
      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
        BookSeriesId
        LeapBookSeriesId
        IngestionId
      }
    }
  }
}
`;

const GetUserData = (callback, from) => {
  try {
    const variables = {
      UserId:
        // from == "App"
        localStorage.getItem("in-app-browser-data-userid") ||
        JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: JSON.parse(localStorage.getItem("effectiveSchoolYear"))
        .SchoolYearId,
    };

    fetchQuery(environment, GetUserDataQuery, variables).then((data) => {
        return callback(JSON.stringify(data.user));
    });
  } catch (e) {
    console.log("GetUserData Test Error");
  }

  return null;
};

export default GetUserData;
