import React, { Component, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n";
import { config } from "../../../Config/config";
import profileIcon from "../../../assets/img/person_24px.svg";
// import newAssignmentIcon from "../../../assets/img/new-assignment.svg";
import GetNotificationData from "../../Feature/Function/GetNotificationData";
import GetNotificationCount from "../../Feature/Function/GetNotificationCount";
// import Loading from "./../../Loading/Loading";
// import ViewAllNotification from "./ViewAllNotification";
import FullScreenModal from "../../Feature/FullScreenModal";
import NotificationItem from "./NotificationItem";
// import { useStateValue } from "../../../StateProvider/StateProvider";.
import MySchoolBookSeriesData from "../../../Services/api/GraphQL/Query/MySchoolBookSeriesData";

import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const UserGetSchoolBookSeriesListQuery = graphql`
  query UserGetSchoolBookSeriesListQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries {
        BookSeriesId
        IsB2CBookSeries
      }
      School {
        SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
          BookSeriesId
          LeapBookSeriesId
          IngestionId
        }
      }
    }
  }
`;

const User = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const notiCount = useSelector(
    (state) => state.UserReducer.notification_count
  );
  const notificationData = useSelector(
    (state) => state.NotificationListReducer.handbookData
  );

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  console.log(props.history, "propshistory");
  console.log("test");
  // const notificationData_TotalNumberOfRecord = useSelector(
  //   (state) => state.NotificationListReducer.TotalNumberOfRecord
  // );
  const lang = useSelector((state) => state.SystemReducer.lang);

  const notification_dropdownRef = useRef(null);
  const help_dropdownRef = useRef(null);
  const setting_dropdownRef = useRef(null);
  const user_dropdownRef = useRef(null);
  const { pathname } = useLocation();
  // const [lang, setlang] = useState(lang);
  // const userDisplay = JSON.parse(localStorage.getItem("userData"));
  const role = userData?.UserType;
  const { t } = props;
  // console.log(props);
  const [openSetting, setopenSetting] = useState(false);
  const [openUser, setopenUser] = useState(false);
  const [openHelp, setopenHelp] = useState(false);
  const [openNotification, setopenNotification] = useState(false);
  // const [notificationData, setnotificationData] = useState([]);
  // const [notificationUnreadCount, setnotificationUnreadCount] = useState(
  //   notiCount
  // );
  const [notificationLoading, setnotificationLoading] = useState(true);
  const [isOpenViewAll, setisOpenViewAll] = useState(false);

  const [assignmentDetailsData, setAssignmentDetailsData] = useState({
    isOpen: false,
    resId: 0,
    assignmentName: "",
  });
  const [isModalOpen, setisModalOpen] = useState(false);

  // const [{ notification_count }, dispatch] = useStateValue();
  // useEffect(() => {
  //   set
  // }, [lang])
  useEffect(() => {
    if (role == "S") updateBellCount();
  }, [pathname, openNotification]);
  // useEffect(() => {
  //   setnotificationUnreadCount(notiCount);
  // }, [notiCount]);

  useEffect(() => {
    window.setnotificationUnreadCount = updateBellCount;
  }, []);

  useEffect(() => {
    if (isOpenViewAll || assignmentDetailsData.isOpen) {
      setisModalOpen(true);
    } else {
      setisModalOpen(false);
    }
  }, [isOpenViewAll, assignmentDetailsData]);
  useEffect(() => {
    // updateBellCount();
    const pageClickEvent = (e) => {
      if (
        notification_dropdownRef.current !== null &&
        !notification_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("Notification");
      }
    };
    if (openNotification) {
      gaTrack(
        "send",
        "event",
        "General",
        "Open notification menu",
        "Role:" + userData.UserType
      );
      handleOpenNotification();
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openNotification]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_dropdownRef.current !== null &&
        !notification_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("Help");
      }
    };
    if (openHelp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openHelp]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_dropdownRef.current !== null &&
        !notification_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("Setting");
      }
    };
    if (openSetting) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openSetting]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_dropdownRef.current !== null &&
        !notification_dropdownRef.current.contains(e.target)
      ) {
        handleOpenDropdownMenu("User");
      }
    };
    if (openUser) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openUser]);

  // let variabless = {
  //   UserId: userData.UserId,
  //   SchoolYearId: selectedSchoolYear.SchoolYearId,
  // };
  // console.log(userData.UserId, selectedSchoolYear.SchoolYearId, "userid");
  // if (role == "T") {
  //   variabless = {
  //     SchoolCode: userData.School.SchoolCode,
  //     SchoolYearId: selectedSchoolYear.SchoolYearId,
  //   };
  // }
  const [bookSeriesData, setbookSeriesData] = useState("");
  const [bookSerisLoading, setbookSerisLoading] = useState(true);

  useEffect(() => {
    if (role == "T") {
      myBookData(selectedSchoolYear.SchoolYearId);
    }
  }, [selectedSchoolYear]);

  useEffect(() => {
    if (role == "S") {
      setbookSerisLoading(false);
    }
  }, []);

  const myBookData = (SchoolYear) => {
    // let variabless = {
    //   SchoolCode: userData.School.SchoolCode,
    //   SchoolYearId: SchoolYear,
    // };
    // MySchoolBookSeriesData(variabless, true, role).then((data) => {
    //   setbookSeriesData(data);
    //   setbookSerisLoading(false);
    // });

    var values = {
      SchoolYearId: SchoolYear,
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
    };

    console.log(values, "valuesdata");

    fetchQuery(environment, UserGetSchoolBookSeriesListQuery, values).then(
      (data) => {
        var listArray = [];
        data.user.School.SchoolAdoptedBookSeries.map((obj, index) => {
          if (obj.IngestionId == null || obj.IngestionId == "") return;
          listArray.push(obj);
        });
        listArray.sort(function (a, b) {
          return a.DisplaySequence - b.DisplaySequence;
        });
        console.log(listArray, "listArrayuser");
        setbookSeriesData(listArray);
        setbookSerisLoading(false);
      }
    );
  };
  console.log(bookSeriesData, "renderdatas");

  // const handelBlurClose = (e) => {
  //   if (openHelp || openSetting || openUser || openNotification) {
  //     var currentTarget = e.currentTarget;

  //     console.log(
  //       "component officially blurred",
  //       currentTarget.contains(document.activeElement),
  //       document.activeElement
  //     );
  //     if (!currentTarget.contains(document.activeElement)) {
  //       setTimeout(() => {
  //         props.closeAll();
  //       }, 100);
  //     }
  //   }
  // };

  const handleOpenDropdownMenu = (type) => {
    switch (type) {
      case "Notification":
        setopenNotification(!openNotification);
        break;
      case "Setting":
        setopenSetting(!openSetting);
        break;
      case "Help":
        setopenHelp(!openHelp);
        break;
      case "User":
        setopenUser(!openUser);
        break;
      default:
        break;
    }
  };

  const updateBellCount = () => {
    GetNotificationCount({
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
        .SchoolYearId,
    }).then((data, error) => {
      console.log("[GetNotificationCount]", data);
      const {
        user: { NotificationCount = 0 },
      } = data || {};
      dispatch({
        type: "UPDATE_NOTIFICATION_COUNT",
        count: parseInt(NotificationCount),
      });

      // setnotificationUnreadCount(parseInt(NotificationCount));
    });
  };

  const handleOpenNotification = () => {
    // if (isOpen) {
    setnotificationLoading(true);
    var variables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
        .SchoolYearId,
      OrderBy: "LAST_MODIFY_DATE",
      PageSize: 10,
    };
    //tets
    GetNotificationData(variables, role).then((data) => {
      console.log("[GetNotificationData data]", data);
      // console.log(userObj.AssignmentBelongsTo.ResourceAssignments);
      console.log("testing");
      if (role == "S") {
        dispatch({
          type: "SET_NOTIFICATION_LIST",
          handbookData: data.user.AssignmentBelongsTo.ResourceAssignments || [],
        });
        dispatch({
          type: "UPDATE_NOTIFICATION_COUNT",
          count: 0,
        });
        dispatch({
          type: "SET_NOTIFICATION_LIST_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.AssignmentBelongsTo.TotalNumberOfRecord || [],
        });
        // setnotificationData(
        //   data.user.AssignmentBelongsTo.ResourceAssignments || []
        // );
      } else {
        dispatch({
          type: "SET_NOTIFICATION_LIST",
          handbookData: data.user.AssignmentCreated.ResourceAssignments || [],
        });
        dispatch({
          type: "SET_NOTIFICATION_LIST_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.AssignmentCreated.TotalNumberOfRecord || [],
        });

        // setnotificationData(
        //   data.user.AssignmentCreated.ResourceAssignments || []
        // );
      }

      // setnotificationUnreadCount(10);
      setnotificationLoading(false);
    });
    // this.props.handleOpenNotification();
    // }
  };
  if (props.n != -1) {
    return (
      <div className="class-nb-buttons lang">
        <div
          className="nav navbar-nav float-right be-user-nav "
          id="be-navbar-collapse"
        >
          <ul className="navbar-nav">
            {localStorage.getItem("i18nextLng") == "en-US" ? (
              <li>
                <button
                  title={t("Chinese")}
                  type="button"
                  className="drop-menu-item"
                  onClick={() => {
                    i18n.changeLanguage("zh-HK");
                    // props.handleOpenSetting();
                    gaTrack(
                      "send",
                      "event",
                      "General",
                      "Change language",
                      "Language:Chinese, Role:" + userData.UserType
                    );
                    //setTimeout(() => this.props.handleOpenSetting(), 100);
                  }}
                >
                  <i className="icon mdi mdi-globe menu-icon" />
                  {t("Chinese")}
                </button>
              </li>
            ) : (
              <li>
                <button
                  title={t("English")}
                  type="button"
                  className="drop-menu-item"
                  onClick={() => {
                    i18n.changeLanguage("en-US");
                    // props.handleOpenSetting();
                    gaTrack(
                      "send",
                      "event",
                      "General",
                      "Change language",
                      "Language:English, Role:" + userData.UserType
                    );
                  }}
                >
                  <i className="icon mdi mdi-globe menu-icon" />
                  {t("English")}
                </button>
              </li>
            )}

            {/* {localStorage.getItem("i18nextLng") == "en-US" ? (
              <li className="nav-item">
                <a>
                  <button
                    type="button"
                    onClick={() => i18n.changeLanguage("zh-HK")}
                    className="link-button nav-link"
                  >
                    {t("Chinese")}
                  </button>
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <a>
                  <button
                    type="button"
                    onClick={() => i18n.changeLanguage("en-US")}
                    className="link-button nav-link"
                  >
                    {t("English")}
                  </button>
                </a>
              </li>
            )} */}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      {bookSerisLoading ? (
        <div className="notification_loading">
          <div className="loader mx-auto"></div>
        </div>
      ) : (
        <div className="class-nb-buttons dash-nav" tabIndex="1">
          <ul
            className="nav navbar-nav float-right be-icons-nav"
            // onBlur={(e) => handelBlurClose(e)}
          >
            {/* <DashHandbook {...this.props} NotiyF="true"/>      */}
            {/* Notification starts here */}
            <li
              className={`nav-item dropdown ${openNotification ? "show" : ""}`}
            >
              <button
                title={t("Notification")}
                type="button"
                className="link-button nav-link dropdown-toggle"
                onClick={() => {
                  if (!openNotification) {
                    //alert("open notification button clicked");
                  }
                  handleOpenDropdownMenu("Notification");
                }}
              >
                {/* Notification */}
                <span className="icon mdi mdi-notifications-active" />
                {notiCount != 0 && (
                  <div className="Rectangle-5">
                    {notiCount > 99 ? "99+" : notiCount}
                  </div>
                )}
              </button>
              {/* Notification window starts here */}
              <div
                id="nav"
                className={`dropdown-menu be-notifications notification   ${
                  openNotification ? "show" : ""
                } ${
                  notificationLoading || notificationData.length == 0
                    ? "noti-loading"
                    : ""
                }`}
                aria-labelledby="dropdownSettings"
                ref={notification_dropdownRef}
                // style={{
                //   right: "-110px",
                //   borderRadius: "9px",
                //   maxHeight: "720px",
                //   minWidth: "340px",
                //   boxShadow:
                //     "0 10px 20px rgba(0,0,0,0.19), 0 1px 6px rgba(0,0,0,0.23)",
                // }}
              >
                <div
                  className="topnav"
                  // style={{
                  //   borderTopLeftRadius: "7px",
                  //   borderTopRightRadius: "7px",
                  // }}
                >
                  <a className="active">{t("Latest")}</a>
                </div>

                <div className="tab-body">
                  {notificationLoading && (
                    <div className="notification_loading">
                      <div className="loader mx-auto"></div>
                    </div>
                  )}
                  {!notificationLoading && (
                    <NotificationItem
                      from={"Notificaiton menu"}
                      notificationData={notificationData}
                      t={t}
                      handleAssignmentDetails={(data) => {
                        document.querySelector("[data-modal]").dataset.modal =
                          parseInt(
                            document.querySelector("[data-modal]").dataset.modal
                          ) + 1;
                        setAssignmentDetailsData({
                          isOpen: data.isOpen,
                          resId: data.resId,
                          AssignmentName: data.AssignmentName,
                        });
                      }}
                    />
                  )}
                  {!notificationLoading && notificationData.length == 0 && (
                    <div
                      className="no-notification"
                      // style={{
                      //   marginTop: "58px",
                      //   marginBottom: "58px",
                      //   textAlign: "center",
                      // }}
                    >
                      <span className="icon mdi mdi-notifications-active no-notification-img-div" />
                      <div className="no-notification-text">
                        {t("There is no notification yet.")}
                      </div>
                    </div>
                  )}
                </div>
                {!notificationLoading && notificationData.length != 0 && (
                  <div className="tab-footer">
                    <div className="notification-view-all">
                      <div
                        className="button-bg"
                        onClick={() => {
                          //  alert("view all clicked")
                          // this.setState({
                          //   viewAllNotification: true,
                          // });
                          gaTrack(
                            "send",
                            "event",
                            "Assignment",
                            "View all list",
                            "Role:" + userData.UserType
                          );
                          setisOpenViewAll(!isOpenViewAll);
                        }}
                      >
                        {t("View All")}
                      </div>
                    </div>
                  </div>
                )}

                {/* )} */}
              </div>
              {/* Notification window ends here */}
              <div
                id="iconbutton"
                className={`menu-overlay ${openNotification ? "show" : ""}`}
                onClick={() => {
                  handleOpenDropdownMenu("Notification");
                }}
              />
            </li>
            {/* Notification ends here */}
            <li
              className={`nav-item dropdown ${openHelp ? "show" : ""}`}
              // onBlur={(e) => handelBlurClose(e)}
            >
              <button
                title={t("Help")}
                type="button"
                className="link-button nav-link dropdown-toggle"
                onClick={() => {
                  if (!openHelp) {
                    gaTrack(
                      "send",
                      "event",
                      "General",
                      "Open help menu",
                      "Role:" + userData.UserType
                    );
                  }
                  handleOpenDropdownMenu("Help");
                }}
              >
                <span className="icon mdi mdi-help" />
              </button>
              <ul
                id="nav"
                className={`dropdown-menu be-notifications ${
                  openHelp ? "show" : ""
                }`}
                aria-labelledby="dropdownSettings"
                styles="width:auto;"
                ref={help_dropdownRef}
              >
                <li>
                  <button
                    title={t("Help")}
                    type="button"
                    className="drop-menu-item"
                    onClick={() => {
                      if (window.cordova) {
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Click help menu item",
                          "Item:Help, " + "Role:" + userData.UserType
                        );
                        window.open(config.helpPage, "_system");
                      } else {
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Click help menu item",
                          "Item:Help, " + "Role:" + userData.UserType
                        );
                        window.open(config.helpPage, "_blank");
                      }
                    }}
                  >
                    <i className="icon mdi mdi-help menu-icon" />
                    {t("Help")}
                  </button>
                </li>
                <li>
                  <button
                    title={t("Download Pearson Apps")}
                    type="button"
                    className="drop-menu-item"
                    onClick={() => {
                      if (window.cordova) {
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Click help menu item",
                          "Item:Download Pearson Apps, " +
                            "Role:" +
                            userData.UserType
                        );
                        window.open(config.installPageURL, "_system");
                      } else {
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Click help menu item",
                          "Item:Download Pearson Apps, " +
                            "Role:" +
                            userData.UserType
                        );
                        window.open(config.installPageURL, "_blank");
                      }
                    }}
                  >
                    <i className="icon mdi mdi-download menu-icon" />
                    {t("Download Pearson Apps")}
                  </button>
                </li>
                <li>
                  <button
                    title={t("Digital Support")}
                    type="button"
                    className="drop-menu-item"
                    onClick={() => {
                      if (window.cordova) {
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Click help menu item",
                          "Item:Digital Support, " + "Role:" + userData.UserType
                        );
                        lang == "en-US"
                          ? window.open(config.digitalSupport, "_system")
                          : window.open(config.digitalSupporthz, "_system");
                      } else {
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Click help menu item",
                          "Item:Digital Support, " + "Role:" + userData.UserType
                        );
                        lang == "en-US"
                          ? window.open(config.digitalSupport, "_blank")
                          : window.open(config.digitalSupporthz, "_blank");
                      }
                    }}
                  >
                    <i className="icon mdi mdi-comment-alt-text menu-icon" />
                    {t("Digital Support")}
                  </button>
                </li>
              </ul>
              <div
                id="iconbutton"
                className={`menu-overlay ${openHelp ? "show" : ""}`}
                onClick={() => {
                  handleOpenDropdownMenu("Help");
                }}
              />
            </li>
            <li
              className={`nav-item dropdown ${openSetting ? "show" : ""}`}
              // onBlur={(e) => handelBlurClose(e)}
            >
              <button
                title={t("Setting")}
                type="button"
                className="link-button nav-link dropdown-toggle"
                onClick={() => {
                  if (!openSetting) {
                    gaTrack(
                      "send",
                      "event",
                      "General",
                      "Open setting menu",
                      "Role:" + userData.UserType
                    );
                  }
                  handleOpenDropdownMenu("Setting");
                }}
              >
                <span className="icon mdi mdi-settings" />
              </button>
              <ul
                id="nav"
                className={`dropdown-menu be-notifications ${
                  openSetting ? "show" : ""
                }`}
                aria-labelledby="dropdownSettings"
                styles="width:auto;"
                ref={setting_dropdownRef}
              >
                {lang == "en-US" ? (
                  <li>
                    <button
                      title={t("Chinese")}
                      type="button"
                      className="drop-menu-item"
                      onClick={() => {
                        // i18n.changeLanguage("zh-HK");
                        dispatch({
                          type: "SET_LANG",
                          lang: "zh-HK",
                        });
                        // props.handleOpenSetting();
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Change language",
                          "Language:Chinese, Role:" + userData.UserType
                        );
                        //setTimeout(() => this.props.handleOpenSetting(), 100);
                      }}
                    >
                      <i className="icon mdi mdi-globe menu-icon" />
                      {t("Chinese")}
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      title={t("English")}
                      type="button"
                      className="drop-menu-item"
                      onClick={() => {
                        // i18n.changeLanguage("en-US");
                        dispatch({
                          type: "SET_LANG",
                          lang: "en-US",
                        });
                        // props.handleOpenSetting();
                        gaTrack(
                          "send",
                          "event",
                          "General",
                          "Change language",
                          "Language:English, Role:" + userData.UserType
                        );
                      }}
                    >
                      <i className="icon mdi mdi-globe menu-icon" />
                      {t("English")}
                    </button>
                  </li>
                )}
                {console.log(userData.AdoptedSchoolYear.length, "tatti")}
                {console.log(bookSeriesData, "mseriesdata")}
                {/* {role == "T" && bookSeriesData.length >
                  0 &&
                userData.AdoptedSchoolYear.length != 0 ? (
                  <li>
                    <button
                      title={t("Manage my books and classes")}
                      type="button"
                      className="drop-menu-item"
                      onClick={() => {
                        if (window.cordova) {
                          gaTrack(
                            "send",
                            "event",
                            "General",
                            "Click setting menu item",
                            "Item:Manage my books and classes, " +
                              "Role:" +
                              userData.UserType
                          );
                          if (bookSeriesData !== "") {
                            if (userData.AdoptedSchoolYear.length > 1) {
                              props.history.push({
                                pathname: "/onboarding",
                                state: { step: 2 },
                              });
                            } else if (
                              bookSeriesData.school.Schools[0].StudyGroups
                                .length > 0
                            ) {
                              props.history.push({
                                pathname: "/onboarding",
                                state: { step: 3 },
                              });
                            } else {
                              props.history.push({
                                pathname: "/onboarding",
                              });
                            }
                          }
                        } else {
                          gaTrack(
                            "send",
                            "event",
                            "General",
                            "Click setting menu item",
                            "Item:Manage my books and classes, " +
                              "Role:" +
                              userData.UserType
                          );
                          if (bookSeriesData !== "") {
                            if (
                              bookSeriesData
                                .length > 0 &&
                              userData.AdoptedSchoolYear.length > 1
                            ) {
                              props.history.push({
                                pathname: "/onboarding",
                                state: { step: 2 },
                              });
                            } else if (
                              bookSeriesData
                                .length > 0
                            ) {
                              props.history.push({
                                pathname: "/onboarding",
                                state: { step: 3 },
                              });
                            } else {
                              props.history.push({
                                pathname: "/onboarding",
                              });
                            }
                          }
                        }
                      }}
                    >
                      <i className="icon mdi mdi-settings menu-icon" />
                      {t("Manage my books and classes")}
                    </button>
                  </li>
                ) : (
                  ""
                )} */}
              </ul>
              <div
                id="iconbutton"
                className={`menu-overlay ${openSetting ? "show" : ""}`}
                onClick={() => {
                  handleOpenDropdownMenu("Setting");
                }}
              />
            </li>
            <li
              className={`nav-item dropdown ${openUser ? "show" : ""}`}
              // onBlur={(e) => handelBlurClose(e)}
            >
              <button
                title={t("Profile")}
                type="button"
                className="link-button nav-link dropdown-toggle"
                onClick={() => {
                  if (!openUser) {
                    gaTrack(
                      "send",
                      "event",
                      "General",
                      "Open profile menu",
                      "Role:" + userData.UserType
                    );
                  }
                  handleOpenDropdownMenu("User");
                }}
              >
                <span className="icon mdi mdi-account" />
                {/*<span className="icon mdi mdi-account" />*/}
              </button>
              <ul
                id="nav"
                className={`dropdown-menu be-notifications profile ${
                  openUser ? "show" : ""
                }`}
                ref={user_dropdownRef}
                //style={{ right: '60px' }}
              >
                <li className="title">
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      cursor: "default",
                    }}
                  >
                    {" "}
                    {t("Hi") +
                      ", " +
                      (localStorage.getItem("i18nextLng") == "en-US"
                        ? userData.UserDisplayName[
                            userData.UserDisplayName[0].Lang == "en-US" ? 0 : 1
                          ].UserName
                        : userData.UserDisplayName[
                            userData.UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
                          ].UserName != ""
                        ? userData.UserDisplayName[
                            userData.UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
                          ].UserName
                        : userData.UserDisplayName[
                            userData.UserDisplayName[0].Lang == "en-US" ? 0 : 1
                          ].UserName)}
                  </div>
                </li>
                <li>
                  <button
                    title={t("Logout")}
                    type="button"
                    className="drop-menu-item"
                    onClick={() => {
                      props.logout();

                      gaTrack(
                        "send",
                        "event",
                        "General",
                        "Logout",
                        "Role:" + userData.UserType
                      );
                    }}
                  >
                    <i className="icon mdi mdi-lock-open menu-icon" />
                    {t("Logout")}
                  </button>
                </li>
              </ul>
              <div
                id="iconbutton"
                className={`menu-overlay ${openUser ? "show" : ""}`}
                onClick={() => handleOpenDropdownMenu("User")}
              />
            </li>
          </ul>

          <FullScreenModal
            type="viewAllNotification"
            isOpen={isOpenViewAll}
            onClose={() => setisOpenViewAll(!isOpenViewAll)}
            isModalOpen={isModalOpen}
            setAssignmentDetailsData={setAssignmentDetailsData}
            t={t}
          />
          <FullScreenModal
            type="viewAssignmentDetails"
            isOpen={assignmentDetailsData.isOpen}
            isModalOpen={isModalOpen}
            onClose={() => {
              setAssignmentDetailsData({
                isOpen: false,
                resId: 0,
                AssignmentName: "",
              });
            }}
            t={t}
            resourceAssignId={assignmentDetailsData.resId}
          />
        </div>
      )}
    </>
  );
};

export default User;
