import * as types from "./types";
const initState = {
  handbookData: [],
  TotalNumberOfRecord: 0,
};
const StudyGroupHandbookReducer = (state = initState, action) => {
  console.log("[StudyGroupHandbookReducer]", state, action);
  switch (action.type) {
    case types.StudyGroupHandbookReducer.SET_STUDYGROUP_HANDBOOK:
      return {
        ...state,
        handbookData: action.handbookData,
      };
    case types.StudyGroupHandbookReducer.SET_STUDYGROUP_HANDBOOK_TOTAL_ITEMS:
      return {
        ...state,
        TotalNumberOfRecord: action.TotalNumberOfRecord,
      };
    default:
      return state;
  }
};

export default StudyGroupHandbookReducer;
