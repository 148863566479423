import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetLearnositySuggestionMappingQuery = graphql`
  query GetLearnositySuggestionMappingQuery($ResourceId: [String]!) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        LearnositySuggestionMapping {
          TagName
          TagObjectiveName
          Criteria
          ConditionRule
          TagType
          MappedResources {
            DisplayOrder
            Resource {
              IngestionId
              ResourceId
              RelatedId
              ResourceDownloadUrl
              ResourceFileNameIfAny
              ResourceAccessUrl
              ResourceType
              Linkage
              FileSource
              Downloadable
              ResourceLangs {
                Lang
                ResourceName
              }
            }
          }
        }
      }
    }
  }
`;

const GetLearnositySuggestionMapping = (variables) => {
  return fetchQuery(
    environment,
    GetLearnositySuggestionMappingQuery,
    variables
  );
};

export default GetLearnositySuggestionMapping;
