export const activityLayoutTab = ({ disable, isInappBrowser }) => {
  // handle the enable and disable activity layout menu tab
  if (!disable) {
    console.log("[activityLayoutTab] enable");
    if (!isInappBrowser) {
      window.document.getElementById("studygroup_back") &&
        window.document
          .getElementById("studygroup_back")
          ?.classList.remove("dim-button");
    }
    window.document.getElementById("assessment_tab") &&
      window.document
        .getElementById("assessment_tab")
        ?.classList.remove("dim-button");
    window.document.getElementById("result_tab") &&
      window.document
        .getElementById("result_tab")
        ?.classList.remove("dim-button");
    window.document.getElementById("as_tab") &&
      window.document.getElementById("as_tab")?.classList.remove("dim-button");

    window.document.getElementById("answer_tab") &&
      window.document
        .getElementById("answer_tab")
        ?.classList.remove("dim-button");
  } else {
    console.log("[activityLayoutTab] disable");
    if (!isInappBrowser) {
      window.document.getElementById("studygroup_back") &&
        window.document
          .getElementById("studygroup_back")
          .classList.add("dim-button");
    }

    window.document.getElementById("assessment_tab") &&
      window.document
        .getElementById("assessment_tab")
        .classList.add("dim-button");
    window.document.getElementById("result_tab") &&
      window.document.getElementById("result_tab").classList.add("dim-button");

    window.document.getElementById("as_tab") &&
      window.document.getElementById("as_tab").classList.add("dim-button");

    window.document.getElementById("answer_tab") &&
      window.document.getElementById("answer_tab").classList.add("dim-button");
  }
};
export const groupBy = (list, key) =>
  list.reduce((groups, item) => {
    const group = groups[item[key]] || [];
    group.push(item);
    groups[item[key]] = group;
    return groups;
  }, {});
// export const groupBy = (collection, property) => {
//   var i = 0,
//     val,
//     index,
//     values = [],
//     result = [];
//   for (; i < collection.length; i++) {
//     val = collection[i][property];
//     index = values.indexOf(val);
//     if (index > -1) result[index].push(collection[i]);
//     else {
//       values.push(val);
//       result.push([collection[i]]);
//     }
//   }
//   return result;
// };
