/**
 * @generated SignedSource<<5ab89f30e64d0760deb09d755a221926>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type SingleResultStudentReportPrizmQuery$variables = {|
  SessionId: string,
  Lang: LangEnumType,
  Credential?: ?string,
|};
export type SingleResultStudentReportPrizmQuery$data = {|
  +learnositySelfLearnResult: ?{|
    +AttemptSessionId: ?string,
    +AttemptDate: ?string,
    +IsCompleted: ?string,
    +CompletedDate: ?string,
    +UserId: ?string,
    +ResourceId: ?string,
    +LearnositySessionResult: ?string,
    +UserDetail: ?{|
      +UserDisplayName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +UserName: ?string,
      |}>,
    |},
  |},
|};
export type SingleResultStudentReportPrizmQuery = {|
  variables: SingleResultStudentReportPrizmQuery$variables,
  response: SingleResultStudentReportPrizmQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Credential"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SessionId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "SessionId",
    "variableName": "SessionId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AttemptSessionId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AttemptDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsCompleted",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "CompletedDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "Credential",
      "variableName": "Credential"
    },
    {
      "kind": "Variable",
      "name": "Lang",
      "variableName": "Lang"
    }
  ],
  "kind": "ScalarField",
  "name": "LearnositySessionResult",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "userLang",
  "kind": "LinkedField",
  "name": "UserDisplayName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UserName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleResultStudentReportPrizmQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
        "kind": "LinkedField",
        "name": "learnositySelfLearnResult",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "UserDetail",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SingleResultStudentReportPrizmQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
        "kind": "LinkedField",
        "name": "learnositySelfLearnResult",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "UserDetail",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf64792f2654002e509cbcc9d2c06eec",
    "id": null,
    "metadata": {},
    "name": "SingleResultStudentReportPrizmQuery",
    "operationKind": "query",
    "text": "query SingleResultStudentReportPrizmQuery(\n  $SessionId: String!\n  $Lang: LangEnumType!\n  $Credential: String\n) {\n  learnositySelfLearnResult(SessionId: $SessionId) {\n    AttemptSessionId\n    AttemptDate\n    IsCompleted\n    CompletedDate\n    UserId\n    ResourceId\n    LearnositySessionResult(Lang: $Lang, Credential: $Credential)\n    UserDetail {\n      UserDisplayName {\n        Lang\n        UserName\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e28a59997aa85c817b419c7781816010";

module.exports = ((node/*: any*/)/*: Query<
  SingleResultStudentReportPrizmQuery$variables,
  SingleResultStudentReportPrizmQuery$data,
>*/);
