import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";

import AssessmentTab from "../../../Components/PopUpView/Page/AssessmentLayout/AssessmentTab";
import ResultTab from "../../../Components/PopUpView/Page/AssessmentLayout/ResultTab";

import ModelAnswerTab from "../../../Components/PopUpView/Page/AssessmentLayout/ModelAnswerTab";
import QuestionView from "../../../Components/PopUpView/Page/AssessmentLayout/ResultTab/AllStudentResult/QuestionView";
import SingleResult from "../../../Components/PopUpView/Page/AssessmentLayout/ResultTab/SingleResult";
//New Layout Components
//import ResultTabNew from "../../../Components/PopUpView/Page/LearnosityLayout/ResultTab";
//import SingleResultNew from "../../../Components/PopUpView/Page/LearnosityLayout/ResultTab/SingleResult";

import "./index.scss";
// const initState = {
//   assessment: false,
//   result: false,
//   answer: false,
// };
//this is learnosity
const B2Bcontent = (props) => {
  const { bookData, resourceData, t, isReadyToRender, B2C } = props;
  
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  
  // const [isReadyToRender, setisReadyToRender] = useState(false);
  // const [initReady, setinitReady] = useState(initState);
  return (
    <div className="card ani-card learnosity" id="assessment">
      {/* {props.location.pathname.toLowerCase().indexOf("/result") == -1 && <div id="assessment" className="sub-menu">
        <div className="menu-list">
          <ul>
            <li>
              <NavLink
                id={"assessment_tab"}
                exact
                onClick={() => {
                  if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                    window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                  }
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:Assessment, " +
                      "Role:" +
                      userType +
                      `, From:${B2C ? "Supplementary" : "My School"}`
                  );
                }}
                to={props.match.url + "/start"}
              >
                {t("Assessment")}
              </NavLink>
            </li>
            {userType == "T" && !B2C && (
              <li>
                <NavLink
                  id={"answer_tab"}
                  exact
                  onClick={() => {
                    if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                      window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                    }
                    gaTrack(
                      "send",
                      "event",
                      "Assessment",
                      "Click tab",
                      "Tab:Model Answer, " + "Role:" + userType
                    );
                  }}
                  to={props.match.url + "/answer"}
                >
                  {t("Model Answers")}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                id={"result_tab"}
                to={props.match.url + "/result"}
                onClick={() => {
                  if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                    window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                  }
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:Result, " +
                      "Role:" +
                      userType +
                      `, From:${B2C ? "Supplementary" : "My School"}`
                  );
                }}
              >
                {userType == "S" || (userType == "T" && B2C)
                  ? t("My Result")
                  : t("Result")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      } */}
      <div
        className="body"
        style={
          {
            // paddingTop: "10px"
          }
        }
      >
        {B2C && (
          <Switch>
            <Route
              exact
              path="/c/myresview/:id/assessment/:bookNunitId/:resId/start"
              component={(p) => (
                <AssessmentTab
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />
            <Route
              exact
              path="/c/myresview/:id/assessment/:bookNunitId/:resId/result"
              component={(p) => (
                <ResultTab
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />
            <Redirect to="/c/myresview/:id/assessment/:bookNunitId/:resId/start" />
          </Switch>
        )}
        {userType == "T" && !B2C && (
          <Switch>
            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/start"
              component={(p) => (
                <AssessmentTab
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />
            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/answer"
              component={(p) => (
                <ModelAnswerTab t={t} {...p} isInappBrowser={false} />
              )}
            />
            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/result"
              component={(p) => (
                // <SingleResults />
                <ResultTab
                  t={t}
                  {...p}
                  // resourceIdGa={resourceIdGa}
                  // resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  bookData={bookData}
                  isInappBrowser={false}
                  B2C={B2C}
                />
              )}
            />

            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/result/q/:questionId"
              component={(p) => (
                <QuestionView
                  resourceData={resourceData}
                  bookData={bookData}
                  //   studyGroupData={studyGroupData}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  //   resourceIdGa={resourceIdGa}
                  B2C={B2C}
                  t={t}
                  {...p}
                  isInappBrowser={false}
                />
              )}
            />

            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/result/:userId"
              component={(p) => (
                <SingleResult
                  t={t}
                  {...p}
                  action={"page"}
                  //   resourceId={resourceIdGa}
                  isInappBrowser={false}
                  //   studyGroupData={studyGroupData}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  //   resourceIdGa={resourceIdGa}
                  bookData={bookData}
                  resourceData={resourceData}
                />
              )}
            />
            <Redirect to="/a/studygroup/:id/assessment/:bookNunitId/:resId/start" />
          </Switch>
        )}
        {userType == "S" && !B2C && (
          <Switch>
            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/start"
              component={(p) => (
                <AssessmentTab
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />

            <Route
              exact
              path="/a/studygroup/:id/assessment/:bookNunitId/:resId/result"
              component={(p) => (
                <ResultTab
                  t={t}
                  isInappBrowser={false}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                />
              )}
            />

            <Redirect to="/a/studygroup/:id/assessment/:bookNunitId/:resId/start" />
          </Switch>
        )}
      </div>
    </div>
  );
};

export default B2Bcontent;
