/**
 * @generated SignedSource<<d73eb6a2ff69bb988d1987bcb7d3bb5d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginPlatformEnumType = "WEB" | "MOBILE" | "TABLET" | "%future added value";
export type PwdTypeEnumType = "IAMKey" | "PASSWORD" | "%future added value";
export type LoginSAMMutation$variables = {|
  userName: string,
  password: string,
  platform: LoginPlatformEnumType,
  passwordType: PwdTypeEnumType,
|};
export type LoginSAMMutation$data = {|
  +login: ?{|
    +SAMAssessToken: ?string,
    +SAMRefreshToken: ?string,
    +SAMSsoToken: ?string,
    +SessionId: ?string,
    +SAMExpiresIn: ?string,
    +User: ?{|
      +UserId: ?string,
      +UserType: ?string,
      +AdoptedSchoolYear: ?$ReadOnlyArray<?{|
        +SchoolYearId: ?string,
        +SchoolYearName: ?string,
        +IsEffective: ?string,
        +EffectiveStartDate: ?string,
        +EffectiveEndDate: ?string,
      |}>,
    |},
  |},
|};
export type LoginSAMMutation = {|
  variables: LoginSAMMutation$variables,
  response: LoginSAMMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "passwordType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userName"
},
v4 = [
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "passwordType",
    "variableName": "passwordType"
  },
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform"
  },
  {
    "kind": "Variable",
    "name": "userName",
    "variableName": "userName"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMAssessToken",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMRefreshToken",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMSsoToken",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SessionId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMExpiresIn",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsEffective",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EffectiveStartDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EffectiveEndDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginSAMMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "userLoginSession",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "schoolYear",
                "kind": "LinkedField",
                "name": "AdoptedSchoolYear",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoginSAMMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "userLoginSession",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "schoolYear",
                "kind": "LinkedField",
                "name": "AdoptedSchoolYear",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe85da8f3fb016411da4705d9e93963b",
    "id": null,
    "metadata": {},
    "name": "LoginSAMMutation",
    "operationKind": "mutation",
    "text": "mutation LoginSAMMutation(\n  $userName: String!\n  $password: String!\n  $platform: LoginPlatformEnumType!\n  $passwordType: PwdTypeEnumType!\n) {\n  login(userName: $userName, password: $password, platform: $platform, passwordType: $passwordType) {\n    SAMAssessToken\n    SAMRefreshToken\n    SAMSsoToken\n    SessionId\n    SAMExpiresIn\n    User {\n      UserId\n      UserType\n      AdoptedSchoolYear {\n        SchoolYearId\n        SchoolYearName\n        IsEffective\n        EffectiveStartDate\n        EffectiveEndDate\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "83ec923195c938894d337712f840a5d4";

module.exports = ((node/*: any*/)/*: Mutation<
  LoginSAMMutation$variables,
  LoginSAMMutation$data,
>*/);
