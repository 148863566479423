import { config } from "../../Config/config";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "../../Environment";
import {
  USER_DETAILS_QUERY
} from "../../constants/GraphQLQuery";
const initGATag = async () => {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','G-6QG0XCJHS1')



  
  const data = JSON.parse(localStorage.getItem('userData'))
  
  if (data == null) {
    console.log('logout')
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-6QG0XCJHS1');
    gtag('set', 'user_properties', {
        user_Device: window.cordova ? "App" : "Web",
    });

    // gaTrack("set", "js", new Date());
    // gaTrack("set", "config", 'G-6QG0XCJHS1');
    // gaTrack("set", "user_properties", {
    //   user_Device: window.cordova ? "App" : "Web"
    // });
  } else {

    var adoptedFilterEffectiveID = []
    const EffectiveID = JSON.parse(localStorage.getItem("effectiveSchoolYear")).SchoolYearId;
    var schlID = JSON.parse(localStorage.getItem("effectiveSchoolYear")).SchoolYearId;
    if(data.AdoptedSchoolYear.length > 0){
      adoptedFilterEffectiveID = JSON.parse(localStorage.getItem("userData")).AdoptedSchoolYear.filter(data => data.SchoolYearId === EffectiveID);
   }
   
   if(adoptedFilterEffectiveID.length>0){
     schlID = JSON.parse(localStorage.getItem("effectiveSchoolYear"))?.SchoolYearId
   } else {
     const schData = JSON.parse(localStorage.getItem("schoolYearData"))
     const schDataIsEffective = schData.filter(data => data?.IsEffective == 'true')
     const currYear = schDataIsEffective[0]?.SchoolYearName.split('-')
     const currYearFirst = +currYear[0]-1
     const currYearSecond = +currYear[0]
     const yearDtls = schData.filter(data => data?.SchoolYearName == currYearFirst+"-"+currYearSecond)
     if(yearDtls.length>0){
       schlID = yearDtls[0]?.SchoolYearId
     }
   }
   var userLevel=''
    var userClass=''
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
   var variables = {
    SchoolYearId: schlID,
    UserId: JSON.parse(localStorage.getItem("userData")).UserId,
  };

    
      
      if(data.UserType == 'S'){
        var userFetchedData = await fetchQuery(
          environment,
          USER_DETAILS_QUERY,
          variables
        ).then((GAUserData) => {
          return GAUserData
          
        }).catch((error) => {
          console.log("Error", error)
        });
        if(userFetchedData.user.BelongsToStudyGroup?.length>0){
          userLevel = parseInt(userFetchedData.user.BelongsToStudyGroup[0]?.StudyGroup?.StudyGroupName[0]?.StudyGroupName)
          userClass = userFetchedData.user.BelongsToStudyGroup[0]?.StudyGroup?.StudyGroupName[0]?.StudyGroupName
        }
       
      }
      //  window.gtag('js', new Date());
      //  window.gtag('config', 'G-6QG0XCJHS1', {  'user_id': data?.UserId });
       window.gtag('set', {'user_properties': {
          user_Device: window.cordova ? "App" : "Web", user_School: data?.School?.SchoolCode, user_Role: data.UserType, user_Class: data.UserType == 'S' ? userClass : '', user_Level: data.UserType == 'S' ? userLevel : ''
      }});
       
     
  }
};

export default initGATag;
