import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import monent from 'moment';

const GetAllSchoolYearsQuery = graphql`
  query GetAllSchoolYearsQuery {
    schoolYears {
      SchoolYearId
      SchoolYearName
      IsEffective
      EffectiveStartDate
      EffectiveEndDate
    }
  }
`;

const GetAllSchoolYears = (callback) => {
  try {
    fetchQuery(environment, GetAllSchoolYearsQuery).then((data) => {
      console.log(data);
      localStorage.setItem("schoolYearData", JSON.stringify(data.schoolYears));
      var schoolYearList = [];
      data.schoolYears.map((obj) => {
        schoolYearList.push(obj);
      });

      schoolYearList.sort(function (a, b) {
        return new monent(b.EffectiveStartDate) - new monent(a.EffectiveStartDate);
      });

      return callback(schoolYearList);
    });
  } catch (e) {
    console.log("GetYear Error");
  }

  return null;
};

export default GetAllSchoolYears;
