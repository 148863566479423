import useGraphQL from "./useGraphQL";

const useGATracking = () => {

    const {mutation} = useGraphQL();

    const mutationQ = `mutation SendGaEvent($EventName: String!\n $EventDetail: String!) {
         sendGaEvent(EventName: $EventName, EventDetail: $EventDetail) 
    }`;

    const sendGaEvent = async (eventName, eventDetails) => {
        const eventDetailsStr = JSON.stringify(eventDetails);

        const response = await mutation(mutationQ, {EventName: eventName, EventDetail: eventDetailsStr});
        return response;
    }

    return {sendGaEvent};

}

export default useGATracking;