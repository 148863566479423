/**
 * @generated SignedSource<<9b58be2074dc41bf4d227fbcb060e57b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReadResourceAssignmentMutation$variables = {|
  ResourceAssignId: string,
|};
export type ReadResourceAssignmentMutation$data = {|
  +readResourceAssignment: ?string,
|};
export type ReadResourceAssignmentMutation = {|
  variables: ReadResourceAssignmentMutation$variables,
  response: ReadResourceAssignmentMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourceAssignId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceAssignId",
        "variableName": "ResourceAssignId"
      }
    ],
    "kind": "ScalarField",
    "name": "readResourceAssignment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReadResourceAssignmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReadResourceAssignmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "715f176ac9d27f131fb5bc6f4cd4bc0c",
    "id": null,
    "metadata": {},
    "name": "ReadResourceAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation ReadResourceAssignmentMutation(\n  $ResourceAssignId: String!\n) {\n  readResourceAssignment(ResourceAssignId: $ResourceAssignId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "ae6caebd911727b8f2425e0088bdf7cf";

module.exports = ((node/*: any*/)/*: Mutation<
  ReadResourceAssignmentMutation$variables,
  ReadResourceAssignmentMutation$data,
>*/);
