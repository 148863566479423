import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "../../Components/Feature/ProgressBar";
import icon from "../../assets/img/group-161.svg";
import "./index.scss";
import { suggestionTypeIconMapping } from "../../Services/Common/ResourcesTypeIcon";
import Loading from "../Loading/Loading";
import GetLearnosityTagHierarchyRef from "../../Services/api/GraphQL/Query/GetLearnosityTagHierarchyRef";
import GetEPSToken from "../../Services/api/GraphQL/Query/GetEPSToken";
import VidoeLightBox from "../../Components/Feature/VideoLightBox";
import ImageLightBox from "../../Components/Feature/ImageLightBox";
import MessageLightBox from "../../Components/Feature/MessageLightBox";
import { isSafari } from "react-device-detect";
import RawResultEmpty from "./RawResultEmpty";

const messageBoxInitState = {
  isOpen: false,
  title: "",
  message: "",
  onConfirm: () => {},
  onCancel: () => {},
  customButton: null,
};

const ActivityLALOCard = (props) => {
  const {
    t,
    LearnosityTagHierarchyRef,
    suggestionMapping,
    seletedHist,
    tagData,
    LearnosityReportStatistic,
    standalone,
    bookData,
    reload,
  } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [rawResult, setrawResult] = useState(
    JSON.parse(seletedHist.LearnositySessionRawResult)
    // null
  );
  const [averageData, setaverageData] = useState(
    LearnosityReportStatistic ? JSON.parse(LearnosityReportStatistic) : null
  );
  const [isLoading, setisLoading] = useState(true);
  const [laloData, setlaloData] = useState([]);
  // const [rawResult, setrawResult] = useState({});

  const [fetchingEPSResourceToken, setfetchingEPSResourceToken] =
    useState(false);
  const [VideoLightBoxOpenState, setVideoLightBoxOpenState] = useState(null);
  const [ImageLightBoxOpenState, setImageLightBoxOpenState] = useState(null);
  const [messageLightboxData, setmessageLightboxData] =
    useState(messageBoxInitState);
  useEffect(() => {
    console.log("[tagData]", tagData);
    console.log("[rawResult]", rawResult);
    console.log("[LearnosityTagHierarchyRef]", LearnosityTagHierarchyRef);
    console.log("[suggestionMapping]", suggestionMapping);
    console.log("[averageData]", averageData);
    //Test
    if (LearnosityTagHierarchyRef == null || LearnosityTagHierarchyRef == "") {
      //if no LearnosityTagHierarchyRef mapped
      setlaloData([]);
      setisLoading(false);
      return;
    }
    try {
      handleLearnosityTagHierarchy();
    } catch (error) {
      console.log(error);
      setlaloData([]);
      setisLoading(false);
    }
  }, []);

  const handleLearnosityTagHierarchy = async () => {
    let variables = {
      HierarchyReference: LearnosityTagHierarchyRef,
    };
    await GetLearnosityTagHierarchyRef(variables).then((data) => {
      console.log(data);
      if (!data.learnosityTagHierarchyStructure) {
        throw "Faill to get learnosityTagHierarchyStructure, return null";
      }
      if (data.learnosityTagHierarchyStructure.length == 0) {
        throw "Faill to get learnosityTagHierarchyStructure, return empty array";
      }

      let preSetHierarchy = [],
        _HierarchyData = [],
        focusAndSubFocus = [];
      data.learnosityTagHierarchyStructure.map((obj, index) => {
        obj.RelatedTagType.map((robj, rindex) => {
          preSetHierarchy.push(robj);
        });
      });

      preSetHierarchy.map((obj, index) => {
        if (!obj.ParentTagType) {
          _HierarchyData.push({
            ...obj,
          });
        }
      });

      preSetHierarchy.map((obj, index) => {
        if (obj.ParentTagType) {
          _HierarchyData.map((hobj, hindex) => {
            if (
              obj.ParentTagType.HierarchyTagTypesId == hobj.HierarchyTagTypesId
            ) {
              _HierarchyData[hindex].Childs = [{ ...obj }];
            }
          });
        }
      });
      console.log(_HierarchyData);

      let questionsWithTags = [];
      setrawResult(rawResult);
      if (rawResult == null) {
        console.log("rawResult == null");

        setlaloData(focusAndSubFocus);
        setisLoading(false);
        return;
      }
      rawResult.items.map((o, i) => {
        let tags = {};
        if (
          tagData
            .map((i) => {
              return i.reference;
            })
            .indexOf(o.reference) != -1
        ) {
          tags =
            tagData[
              tagData
                .map((i) => {
                  return i.reference;
                })
                .indexOf(o.reference)
            ].tags;
        }
        questionsWithTags.push({
          ...o,
          tags: tags,
        });
      });
      console.log("[questionsWithTags]", questionsWithTags);
      _HierarchyData.map((hobj, hindex) => {
        if (hobj.ParentTagType == null) {
          // top level
          questionsWithTags.map((q, qi) => {
            q.tags[hobj.TagType].map((tobj, ti) => {
              if (focusAndSubFocus.map((a) => a.LA.Title).indexOf(tobj) == -1) {
                focusAndSubFocus.push({
                  LA: {
                    Title: tobj,
                    LongTitle:
                      hobj.Tags[
                        hobj.Tags.map((i) => {
                          return i.TagName;
                        }).indexOf(tobj)
                      ].TagLongName,
                    TagType: hobj.TagType,
                    Description: hobj.Description,
                  },
                  LO: [],
                });
              }
            });
          });
        }
        console.log("[focusAndSubFocus] i=" + hindex, focusAndSubFocus);
        if (focusAndSubFocus.length == 0) {
          throw "Faill to map with questionsWithTags";
        }

        //LO
        let LO = [];
        _HierarchyData.map((hobj, hindex) => {
          var topLevel = hobj.TagType;
          hobj.Childs &&
            hobj.Childs.map((cobj, cindex) => {
              questionsWithTags.map((qobj, qindex) => {
                if (qobj.tags[cobj.TagType]) {
                  qobj.tags[cobj.TagType].map((tobj, tindex) => {
                    if (qobj.tags[topLevel]) {
                      qobj.tags[topLevel].map((tlobj, tlindex) => {
                        // console.log("tobj", tobj);
                        if (
                          LO.map((i) => {
                            return i.Title;
                          }).indexOf(tobj) == -1
                        ) {
                          // console.log(
                          //   cobj.Tags[
                          //     cobj.Tags.map((i) => {
                          //       return i.TagName;
                          //     }).indexOf(tobj)
                          //   ]
                          // );
                          LO.push({
                            ParentTitle: tlobj,
                            ParentTagType: hobj.TagType,
                            Title: tobj,
                            TagType: cobj.TagType,
                            LongName:
                              cobj.Tags[
                                cobj.Tags.map((i) => {
                                  return i.TagName;
                                }).indexOf(tobj)
                              ].TagLongName,
                            Description: cobj.Description,
                          });
                        }
                      });
                    }
                  });
                }
              });
            });
        });
        LO.map((sobj, sindex) => {
          //   let LO_Longname = "Null";
          //   if (
          //     suggestionMapping
          //       .map((i) => {
          //         return i.TagName;
          //       })
          //       .indexOf(sobj.Title) != -1
          //   ) {
          //     LO_Longname =
          //       suggestionMapping[
          //         suggestionMapping
          //           .map((i) => {
          //             return i.TagName;
          //           })
          //           .indexOf(sobj.Title)
          //       ].TagObjectiveName;
          //   } else {
          //     LO_Longname = "";
          //   }
          focusAndSubFocus[
            focusAndSubFocus.map((a) => a.LA.Title).indexOf(sobj.ParentTitle)
          ].LO.push(sobj);
        });
      });

      focusAndSubFocus.map((fobj, findex) => {
        let la_score = 0,
          la_max_score = 0;

        fobj.LO.map((sobj, sindex) => {
          let lo_score = 0,
            lo_max_score = 0,
            lo_avg_score = 0,
            lo_avg_max_score = 0;
          if (averageData) {
            if (averageData.activityTagsAvgScore[sobj.TagType]) {
              const temp = averageData.activityTagsAvgScore[sobj.TagType];
              console.log(
                "match averageData.activityTagsAvgScore[sobj.TagType]",
                temp
              );
              if (temp[sobj.Title]) {
                const lo_avg = temp[sobj.Title];
                lo_avg_score = lo_avg.TotalScore;
                lo_avg_max_score = lo_avg.MaxScore;
              }
            }
          } else {
            lo_avg_score = null;
            lo_avg_max_score = null;
          }
          questionsWithTags.map((qobj, qindex) => {
            if (qobj.tags[sobj.TagType]) {
              if (qobj.tags[sobj.TagType].indexOf(sobj.Title) != -1) {
                la_score = la_score + qobj.scoring.score;
                la_max_score = la_max_score + qobj.scoring.max_score;

                lo_max_score = lo_max_score + qobj.scoring.max_score;
                lo_score = lo_score + qobj.scoring.score;
              }
            }
          });

          focusAndSubFocus[findex].LO[sindex].max_score = lo_max_score;
          focusAndSubFocus[findex].LO[sindex].score = lo_score;

          focusAndSubFocus[findex].LO[sindex].avg_max_score = lo_avg_max_score;
          focusAndSubFocus[findex].LO[sindex].avg_score = lo_avg_score;

          //handle suggestion
          let lo_score_percent =
            lo_score == 0
              ? 0
              : Math.round((parseInt(lo_score) / parseInt(lo_max_score)) * 100);
          let mappedResources = [];
          // const findMatchTagNameIndex = suggestionMapping
          //   .map((r) => r.TagName)
          //   .indexOf(sobj.Title);

          // if (findMatchTagNameIndex != -1) {
          //   const suggestion = suggestionMapping[findMatchTagNameIndex];

          // }
          suggestionMapping.map((suggestion, sgi) => {
            if (suggestion.TagName == sobj.Title) {
              console.log(
                suggestion,
                lo_score_percent,
                lo_score_percent < parseInt(suggestion.Criteria)
              );
              switch (suggestion.ConditionRule) {
                case "<":
                  if (lo_score_percent < parseInt(suggestion.Criteria)) {
                    mappedResources = [
                      ...mappedResources,
                      ...suggestion.MappedResources,
                    ];
                  }
                  break;

                case "=":
                  if (lo_score_percent == parseInt(suggestion.Criteria)) {
                    mappedResources = [
                      ...mappedResources,
                      ...suggestion.MappedResources,
                    ];
                  }
                  break;

                case ">=":
                  if (lo_score_percent >= parseInt(suggestion.Criteria)) {
                    mappedResources = [
                      ...mappedResources,
                      ...suggestion.MappedResources,
                    ];
                  }
                  break;

                case ">":
                  if (lo_score_percent > parseInt(suggestion.Criteria)) {
                    mappedResources = [
                      ...mappedResources,
                      ...suggestion.MappedResources,
                    ];
                  }
                  break;

                case "<=":
                  if (lo_score_percent <= parseInt(suggestion.Criteria)) {
                    mappedResources = [
                      ...mappedResources,
                      ...suggestion.MappedResources,
                    ];
                  }
                  break;
              }
            }
          });
          focusAndSubFocus[findex].LO[sindex].suggestions = mappedResources;
        });

        focusAndSubFocus[findex].LO.sort(function (a, b) {
          return a.Title.localeCompare(b.Title);
        });
        focusAndSubFocus[findex].max_score = la_max_score;
        focusAndSubFocus[findex].score = la_score;
      });

      console.log("[focusAndSubFocus] final", focusAndSubFocus);
      setlaloData(focusAndSubFocus);
      setisLoading(false);
    });
  };

  const getEPSTokenForDownload = (resId, options, callback) => {
    setfetchingEPSResourceToken(true);
    var value = {
      ResourceId: [resId],
    };
    try {
      GetEPSToken(value).then((data) => {
        console.log(data);
        if (options.platform == "Web") {
          forWebAction(data, options, callback);
        }
        if (options.platform == "App") {
          callback(data);
          setfetchingEPSResourceToken(false);
        }
      });
    } catch (e) {
      setfetchingEPSResourceToken(false);
      console.log(e);
      window.alert(e.message);
    }
  };

  const forWebAction = (data, options, callback) => {
    if (!isSafari) {
      try {
        switch (options.restype) {
          case "IMAGE":
            if (options.actionType == "download") {
              var a = document.createElement("a");
              a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
              a.target = "_blank";
              a.download = options.actionType;
              document.body.appendChild(a);
              a.click();
              a.remove();
            } else {
              console.log("open");
              callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
            }
            setfetchingEPSResourceToken(false);
            break;
          default:
            console.log("download");
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            document.body.appendChild(a);
            console.log(a);
            a.click();
            a.remove();

            setfetchingEPSResourceToken(false);
            break;
        }
      } catch (e) {
        setfetchingEPSResourceToken(false);
        console.log("Featch EPS doanload error", e);
        window.alert(e.message);
      }
    } else {
      let res = data.resourceSearch.Resources[0];
      let url = res.ResourceAccessUrl;

      // this.setState({
      setfetchingEPSResourceToken(false);
      setmessageLightboxData({
        isOpen: true,
        title:
          res.ResourceLangs[lang == res.ResourceLangs.Lang ? 0 : 1]
            .ResourceName,
        message: "",
        onConfirm: () => {
          setmessageLightboxData(messageBoxInitState);
        },
        onCancel: () => {
          setmessageLightboxData(messageBoxInitState);
        },
        customButton: () => {
          return (
            <a
              className="btn btn-primary"
              onClick={() => {
                setmessageLightboxData(messageBoxInitState);
              }}
              href={url}
              target="_blank"
            >
              {t("Open")}
            </a>
          );
        },
      });
    }
  };

  const openResource = (data) => {
    let appReceiver = document.getElementById("app-message-receiver");
    console.log(appReceiver);
    try {
      gaTrack(
        "send",
        "event",
        "Assessment",
        "Open resource",
        "Resource:" +
          data.Resource.ResourceLangs[0].ResourceName +
          ", Resource ID:" +
          data.Resource.IngestionId +
          ", Book Series:" +
          bookData.BookSeriesName[0].BookSeriesName +
          ", Role:" +
          userType
      );
    } catch (e) {
      console.log(e, "Check answer GA Error");
    }
    console.log(data);
    if (appReceiver) {
      switch (data.Resource.ResourceType) {
        case "IMAGE":
        case "YOUTUBE":
        case "VIMEO":
          console.log("[appReceiver] Find - but open as light box");
          break;
        case "VIDEO":
        case "AUDIO":
        case "WORKSHEET":
        case "TEACHER_GUIDE":
        case "IACTIVITY":
        case "INTERACTIVE":
        case "OPE":
        case "WEB_LINK":
        case "LEARNOSITY":
        case "DOCUMENT":
        case "PACK":
        default:
          localStorage.setItem("callbackdata", JSON.stringify(data));
          console.log("[appReceiver] Find - start passing data");
          return appReceiver.click();
      }
    }
    console.log("[appReceiver] Not Find - start open as web");
    //No EPS 'EBOOK4','IACTIVITY','ILESSON','OPE','LEARNOSITY'
    //'DOCUMENT','WORKSHEET','TEACHER_GUIDE','AUDIO','VIDEO','WEB_LINK','INTERACTIVE','IMAGE','PACK'
    switch (data.Resource.ResourceType) {
      case "WEB_LINK":
      case "VIDEO":
      case "DOCUMENT":
      case "WORKSHEET":
      case "TEACHER_GUIDE":
      case "AUDIO":
      case "PACK":
      case "INTERACTIVE":
        if (data.Resource.FileSource == "eps") {
          getEPSTokenForDownload(
            data.Resource.ResourceId,
            {
              actionType: "open",
              restype: data.Resource.ResourceType,
              platform: "Web",
            },
            (path) => {
              //new tab
              window.open(path, "_blank");
            }
          );
        } else {
          window.open(data.Resource.ResourceAccessUrl, "_blank");
        }
        break;
      case "IMAGE":
        if (data.Resource.FileSource == "eps") {
          console.log("[image] eps");
          // eps download or open
          getEPSTokenForDownload(
            data.Resource.ResourceId,
            {
              actionType: "open",
              restype: data.Resource.ResourceType,
              platform: "Web",
            },
            (path) => {
              //imagebox
              setImageLightBoxOpenState({
                id: data.Resource.ResourceId,
                image: path,
                obj: data,
              });
              setfetchingEPSResourceToken(false);
            }
          );
        } else {
          console.log("[image] not eps");
          setImageLightBoxOpenState({
            id: data.Resource.ResourceId,
            image: data.Resource.ResourceAccessUrl,
            obj: data,
          });
          setfetchingEPSResourceToken(false);
        }

        break;
      case "EBOOK4":
      case "IACTIVITY":
      case "OPE":
        window.open(data.Resource.ResourceAccessUrl, "_blank");
        break;
      case "LEARNOSITY":
        console.log(props.location, props.match);
        let newURL;
        dispatch({
          type: "SET_FROM",
          layout: "b2c",
        });
        if (standalone) {
          newURL = window.location.pathname.replace(
            props.match.url + "/as",
            "/activity/" + data.Resource.ResourceId + "/start"
          );
        } else {
          newURL =
            `/c/myresview/` +
            props.match.params.id +
            "/" +
            props.match.params.book +
            "/assessments/" +
            props.match.params.subid +
            "/" +
            data.Resource.ResourceId +
            "/start";
        }

        console.log(newURL);
        window.open(newURL, "_blank");
        break;
      case "YOUTUBE":
        handleVideoBox({
          ...data.Resource,
          type: "youtube",
          obj: data.Resource,
        });
        break;
      case "VIMEO":
        handleVideoBox({
          ...data.Resource,
          type: "vimeo",
          obj: data.Resource,
        });
        break;
      default:
        window.open(data.Resource.ResourceAccessUrl, "_blank");
        break;
    }
  };

  const downloadResource = (data) => {
    var appReceiver = document.getElementById("app-message-receiver");
    console.log(appReceiver);
    console.log(data);
    if (appReceiver) {
      switch (data.Resource.ResourceType) {
        case "IMAGE":
          if (data.Resource.FileSource == "eps") {
            getEPSTokenForDownload(
              data.Resource.ResourceId,
              {
                actionType: "open",
                restype: data.Resource.ResourceType,
                platform: "App",
              },
              (path) => {
                //new tab
                var newdata = { ...data };
                newdata.Resource.ResourceAccessUrl =
                  path.resourceSearch.Resources[0].ResourceAccessUrl; //replace the latest token url
                localStorage.setItem("callbackdata", JSON.stringify(newdata));
                console.log("[appReceiver] Find - start passing data");
                return appReceiver.click();
              }
            );
          } else {
            window.open(data.Resource.ResourceAccessUrl, "_blank");
          }
          break;
      }
    } else {
      if (data.Resource.FileSource == "eps") {
        getEPSTokenForDownload(
          data.Resource.ResourceId,
          {
            actionType: "open",
            restype: data.Resource.ResourceType,
            platform: "Web",
          },
          (path) => {
            window.open(path, "_blank");
          }
        );
      } else {
        window.open(data.Resource.ResourceAccessUrl, "_blank");
      }
    }
  };

  const handleVideoBox = (data) => {
    console.log(data);
    setVideoLightBoxOpenState(
      data ? { type: data.type, videoPath: data.Linkage, isOpen: true } : null
    );
  };

  const handleImageBox = (id, path) => {
    setImageLightBoxOpenState(id ? { name: id, image: path } : id);
  };

  if (isLoading)
    return (
      <div style={{ margin: "20px auto" }}>
        <Loading type={"normal"} />
      </div>
    );
  if (laloData.length == 0 && rawResult != null && !isLoading) {
    return "";
  }
  if (rawResult == null && !isLoading) {
    return <RawResultEmpty t={t} reload={reload} />;
  }
  if (laloData.length == 1 && !isLoading)
    return (
      <div className="my-score lalo">
        {fetchingEPSResourceToken && <Loading />}
        <VidoeLightBox
          handleVideoBox={handleVideoBox}
          VideoLightBoxOpenState={VideoLightBoxOpenState}
        />
        <ImageLightBox
          isOpen={ImageLightBoxOpenState ? true : false}
          handleImageBox={handleImageBox}
          ImageLightBoxOpenState={ImageLightBoxOpenState}
          downloadImage={(data) => downloadResource(data)}
        />
        <MessageLightBox
          isOpen={messageLightboxData.isOpen}
          type="messageBox"
          title={messageLightboxData.title}
          message={messageLightboxData.message}
          onClose={() => {
            messageLightboxData.onCancel();
          }}
          customButton={() => {
            if (messageLightboxData.customButton == null) return null;
            return messageLightboxData.customButton();
          }}
        />
        <div>
          {/* top left image */}
          <div className="c-img">
            <img src={icon} />
            <div className="po-size">
              <div className="title">
                {t("Analysis & Follow-up (by Learning Objectives)")}
              </div>
            </div>
          </div>
          {/* LALO card */}
          <div className="my-score-card">
            <div className="note">
              <div>
                <span className="dot green"></span>
                {t("OtherUsersPerformance​")}
              </div>
              <div>
                <span className="dot blue"></span>
                {t("myp")}
              </div>
            </div>
            {laloData[0].LO.map((o, i) => {
              return (
                <div className="lo" key={i}>
                  <div className="title">
                    <span className="dot">
                      <div>{i + 1}</div>
                    </span>
                    {o.LongName}
                  </div>
                  <div className="lo-body">
                    <div className="info">
                      <div className="progress-box">
                        <ProgressBar
                          percentage={
                            o.score == 0
                              ? 0
                              : Math.round(
                                  (parseInt(o.score) / parseInt(o.max_score)) *
                                    100
                                )
                          }
                        />
                        <div className="text">
                          {o.score == 0
                            ? 0
                            : Math.round(
                                (parseInt(o.score) / parseInt(o.max_score)) *
                                  100
                              )}
                          %
                        </div>
                      </div>
                      <div className="progress-box avg">
                        <ProgressBar
                          percentage={
                            o.avg_score != null
                              ? o.avg_score == 0
                                ? 0
                                : Math.round(
                                    (parseInt(o.avg_score) /
                                      parseInt(o.avg_max_score)) *
                                      100
                                  )
                              : 0
                          }
                        />
                        <div className="text">
                          {o.avg_score != null
                            ? o.avg_score == 0
                              ? 0
                              : Math.round(
                                  (parseInt(o.avg_score) /
                                    parseInt(o.avg_max_score)) *
                                    100
                                )
                            : "-"}
                          %
                        </div>
                      </div>
                    </div>
                    <div className="suggestions">
                      <ul>
                        {o.suggestions.map((suggestion, si) => {
                          let resourceType = "";
                          switch (
                            suggestion.Resource.ResourceType // just temp ,  will be chage
                          ) {
                            case "YOUTUBE":
                            case "VIMEO":
                            case "VIDEO":
                              resourceType = "video";
                              break;
                            case "LEARNOSITY":
                            default:
                              resourceType = "activity";
                              break;
                          }
                          return (
                            <li
                              key={"sug=" + si}
                              onClick={() => openResource(suggestion)}
                            >
                              <div className="suggestion-title">
                                <img
                                  src={suggestionTypeIconMapping(resourceType)}
                                />
                                {
                                  suggestion.Resource.ResourceLangs[
                                    suggestion.Resource.ResourceLangs[0].Lang ==
                                    lang
                                      ? 0
                                      : 1
                                  ].ResourceName
                                }
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  if (laloData.length > 1 && !isLoading)
    // more then one la layout
    return (
      <div className="my-score lalo">
        {fetchingEPSResourceToken && <Loading />}
        <VidoeLightBox
          handleVideoBox={handleVideoBox}
          VideoLightBoxOpenState={VideoLightBoxOpenState}
        />
        <ImageLightBox
          isOpen={ImageLightBoxOpenState ? true : false}
          handleImageBox={handleImageBox}
          ImageLightBoxOpenState={ImageLightBoxOpenState}
          downloadImage={(data) => downloadResource(data)}
        />
        <MessageLightBox
          isOpen={messageLightboxData.isOpen}
          type="messageBox"
          title={messageLightboxData.title}
          message={messageLightboxData.message}
          onClose={() => {
            messageLightboxData.onCancel();
          }}
          customButton={() => {
            if (messageLightboxData.customButton == null) return null;
            return messageLightboxData.customButton();
          }}
        />
        {laloData.map((laobj, i) => {
          return (
            <div key={i}>
              {i == 0 && (
                <>
                  {/* top left image */}
                  <div className="c-img">
                    <img src={icon} />
                    <div className="po-size">
                      <div className="title">
                        {t("Analysis & Follow-up (by Learning Objectives)")}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* LALO card */}
              <div className="my-score-card">
                {i == 0 && (
                  <div className="note">
                    <div>
                      <span className="dot green"></span>
                      {t("OtherUsersPerformance​")}
                    </div>
                    <div>
                      <span className="dot blue"></span>
                      {t("myp")}
                    </div>
                  </div>
                )}
                <div className="title">{laobj.LA.LongTitle}</div>
                {laobj.LO.map((lo, li) => {
                  return (
                    <div className="lo" key={i + "" + li}>
                      <div className="title">
                        <span className="dot">
                          <div>{li + 1}</div>
                        </span>
                        {lo.LongName}
                      </div>
                      <div>
                        <div className="info">
                          <div className="progress-box">
                            <ProgressBar
                              percentage={
                                lo.score == 0
                                  ? 0
                                  : Math.round(
                                      (parseInt(lo.score) /
                                        parseInt(lo.max_score)) *
                                        100
                                    )
                              }
                            />
                            <div className="text">
                              {lo.score == 0
                                ? 0
                                : Math.round(
                                    (parseInt(lo.score) /
                                      parseInt(lo.max_score)) *
                                      100
                                  )}
                              %
                            </div>
                          </div>
                          <div className="progress-box avg">
                            <ProgressBar
                              percentage={
                                lo.avg_score != null
                                  ? lo.avg_score == 0
                                    ? 0
                                    : Math.round(
                                        (parseInt(lo.avg_score) /
                                          parseInt(lo.avg_max_score)) *
                                          100
                                      )
                                  : 0
                              }
                            />
                            <div className="text">
                              {lo.avg_score != null
                                ? lo.avg_score == 0
                                  ? 0
                                  : Math.round(
                                      (parseInt(lo.avg_score) /
                                        parseInt(lo.avg_max_score)) *
                                        100
                                    )
                                : "-"}
                              %
                            </div>
                          </div>
                        </div>
                        <div className="suggestions">
                          <ul>
                            {lo.suggestions.map((suggestion, si) => {
                              let resourceType = "";
                              switch (
                                suggestion.Resource.ResourceType // just temp ,  will be chage
                              ) {
                                case "YOUTUBE":
                                case "VIMEO":
                                case "VIDEO":
                                  resourceType = "video";
                                  break;
                                case "LEARNOSITY":
                                default:
                                  resourceType = "activity";
                                  break;
                              }
                              return (
                                <li
                                  key={"sug=" + si}
                                  onClick={() => openResource(suggestion)}
                                >
                                  <div className="suggestion-title">
                                    <img
                                      src={suggestionTypeIconMapping(
                                        resourceType
                                      )}
                                    />
                                    {
                                      suggestion.Resource.ResourceLangs[
                                        suggestion.Resource.ResourceLangs[0]
                                          .Lang == lang
                                          ? 0
                                          : 1
                                      ].ResourceName
                                    }
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
};

export default ActivityLALOCard;
