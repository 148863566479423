import React, { Component, useState, useEffect } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import  from "react-router-dom";
import Footer from "../Footer/Footer";
import AssessmentTab from "../PopUpView/Page/AssessmentLayout/AssessmentTab";
import ResultTab from "../PopUpView/Page/AssessmentLayout/ResultTab";
import QuestionView from "../PopUpView/Page/AssessmentLayout/ResultTab/AllStudentResult/QuestionView";
import SingleResult from "../PopUpView/Page/AssessmentLayout/ResultTab/SingleResult";
import ModelAnswerTab from "../PopUpView/Page/AssessmentLayout/ModelAnswerTab";
import "../../Layouts/ClassViewLayout/index.scss";
import "../PopUpView/Page/Assessment/index.scss";
// import i18n from "../../i18n";

import B2CContent from "./B2CContent";
import B2CAssessmentInfoQuery from "../../Services/api/GraphQL/Query/B2CAssessmentInfo";
import ActivityLayoutGetAssessmentName from "../../Services/api/GraphQL/Query/ActivityLayoutGetAssessmentName";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const index = (props) => {
  let isSubscribed = true;
  const { t } = props;
  // let query = useQuery();
  // const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [loading, setloading] = useState(true);
  const [bookData, setbookData] = useState(null);
  const [resourceData, setresourceData] = useState(null);
  const [isReadyToRender, setisReadyToRender] = useState(false);
  const [B2C, setB2C] = useState(false);
  const [inappData, setinappData] = useState(null);

  useEffect(() => {
    const inappData = JSON.parse(localStorage.getItem("inappData"));
    setinappData(inappData);
    console.log("inappData", inappData);
    if (inappData.b2c != "true") {
      getAssessmentName(inappData);
    } else {
      getB2CData(inappData);
    }
    return () => (isSubscribed = false);
  }, []);

  const getB2CData = async (inappData) => {
    const variables = {
      ResourceId: [inappData.resourceId],
      BookSeriesId: inappData.bookseriesId,
      UserId: userData?.UserId,
      BookId: inappData.bookId,
      UnitId: inappData.unitId,
    };
    await B2CAssessmentInfoQuery(variables).then((data) => {
      console.log(data);
      if (!isSubscribed) return;
      setB2C(true);
      setbookData(data.user.AdoptedBookSeries[0]);
      setresourceData(data.resourceSearch.Resources[0]);
      setloading(false);
      setisReadyToRender(true);
    });
  };

  const getAssessmentName = async (inappData) => {
    // const resId = props.match.params.resId;
    const variables = {
      UserId: userData?.UserId,
      ResourceId: [inappData.resourceId],
      BookSeriesId: inappData.bookseriesId,
      BookId: inappData.bookId,
      UnitId: inappData.unitId,
    };
    await ActivityLayoutGetAssessmentName(variables).then((data) => {
      console.log(data);
      if (!isSubscribed) return;
      setB2C(false);
      setbookData(data.user.AdoptedBookSeries[0]);
      setresourceData(data.resourceSearch.Resources[0]);
      setloading(false);
      setisReadyToRender(true);
    });
  };

  if (loading || !isReadyToRender) return <div></div>;
  return (
    <div className="be-wrapper be-fixed-sidebar pop_up_view for-inapp">
      <div className="be-container">
        <div
          id={"class_body"}
          className={
            "be-content card-pb bg-color site-wrapper " + (B2C ? "b2c" : "b2b")
          }
        >
          {!B2C && (
            <div
              id="a"
              className="dashboard-body"
              style={{ paddingTop: "5px" }}
            >
              <div className="row">
                <div className="card ani-card learnosity api" id="assessment">
                  <div className="sub-menu">
                    <div className="menu-list">
                      <ul>
                        <li>
                          <div
                            id={"assessment_tab"}
                            className={`navlink ${
                              props.location.pathname.indexOf("/start") != -1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                                window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                              }
                              props.history.push("/assessment=api/start");
                            }}
                          >
                            {lang == "en-US" ? "Assessment" : "評估"}
                          </div>
                        </li>
                        {userType == "T" && !B2C && (
                          <li>
                            <div
                              id={"answer_tab"}
                              className={`navlink ${
                                props.location.pathname.indexOf("/answer") != -1
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                props.history.push("/assessment=api/answer");
                              }}
                            >
                              {lang == "en-US" ? "Model Answers" : "參考答案"}
                            </div>
                          </li>
                        )}
                        <li>
                          <div
                            id={"result_tab"}
                            className={`navlink ${
                              props.location.pathname.indexOf("/result") != -1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                                window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                              }
                              props.history.push("/assessment=api/result");
                            }}
                          >
                            {userType == "S" || (userType == "T" && B2C)
                              ? t("My Result")
                              : t("Result")}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="body">
                    <Switch>
                      <Route
                        exact
                        path="/assessment=api/start"
                        component={(p) => (
                          <AssessmentTab
                            t={t}
                            isInappBrowser={true}
                            bookData={bookData}
                            //   resourceIdGa={resourceIdGa}
                            //   resourceIngestionIdGa={resourceIngestionIdGa}
                            resourceData={resourceData}
                            //   studyGroupData={studyGroupData}
                            B2C={B2C}
                            {...p}
                            isReadyToRender={isReadyToRender}
                            inappData={inappData}
                          />
                        )}
                      />
                      {userType == "T" && (
                        <Route
                          exact
                          path="/assessment=api/answer"
                          component={(p) => (
                            <ModelAnswerTab
                              t={t}
                              isInappBrowser={true}
                              bookData={bookData}
                              //   resourceIdGa={resourceIdGa}
                              //   resourceIngestionIdGa={resourceIngestionIdGa}
                              resourceData={resourceData}
                              //   studyGroupData={studyGroupData}
                              B2C={B2C}
                              {...p}
                              isReadyToRender={isReadyToRender}
                              inappData={inappData}
                            />
                          )}
                        />
                      )}
                      <Route
                        exact
                        path="/assessment=api/result"
                        component={(p) => (
                          <ResultTab
                            t={t}
                            isInappBrowser={true}
                            bookData={bookData}
                            //   resourceIdGa={resourceIdGa}
                            //   resourceIngestionIdGa={resourceIngestionIdGa}
                            resourceData={resourceData}
                            //   studyGroupData={studyGroupData}
                            B2C={B2C}
                            {...p}
                            isReadyToRender={isReadyToRender}
                            inappData={inappData}
                          />
                        )}
                      />
                      {userType == "T" && (
                        <Route
                          exact
                          path="/assessment=api/result/q/:questionId"
                          component={(p) => (
                            <QuestionView
                              t={t}
                              isInappBrowser={true}
                              bookData={bookData}
                              //   resourceIdGa={resourceIdGa}
                              //   resourceIngestionIdGa={resourceIngestionIdGa}
                              resourceData={resourceData}
                              //   studyGroupData={studyGroupData}
                              B2C={B2C}
                              {...p}
                              isReadyToRender={isReadyToRender}
                              inappData={inappData}
                            />
                          )}
                        />
                      )}

                      <Route
                        exact
                        path="/assessment=api/result/:userId"
                        component={(p) => (
                          <SingleResult
                            t={t}
                            isInappBrowser={true}
                            bookData={bookData}
                            //   resourceIdGa={resourceIdGa}
                            //   resourceIngestionIdGa={resourceIngestionIdGa}
                            resourceData={resourceData}
                            //   studyGroupData={studyGroupData}
                            B2C={B2C}
                            {...p}
                            isReadyToRender={isReadyToRender}
                            inappData={inappData}
                            action={"page"}
                          />
                        )}
                      />
                      <Redirect to="/assessment=api/start" />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          )}
          {B2C && (
            <B2CContent
              {...props}
              bookData={bookData}
              resourceData={resourceData}
              isReadyToRender={isReadyToRender}
              B2C={B2C}
              inappData={inappData}
            />
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default index;
