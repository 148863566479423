/**
 * @generated SignedSource<<de8bb292957619c1e9c61e0d3c8f25e9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ExternalPlatformEnumType = "EBook" | "MasterWebsite" | "DWB" | "%future added value";
export type GetHawkKeyQuery$variables = {|
  platform: ExternalPlatformEnumType,
|};
export type GetHawkKeyQuery$data = {|
  +hawkKey: ?string,
|};
export type GetHawkKeyQuery = {|
  variables: GetHawkKeyQuery$variables,
  response: GetHawkKeyQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "platform"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "platform",
        "variableName": "platform"
      }
    ],
    "kind": "ScalarField",
    "name": "hawkKey",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetHawkKeyQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetHawkKeyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a350d098b862a43bfa836608cb02da83",
    "id": null,
    "metadata": {},
    "name": "GetHawkKeyQuery",
    "operationKind": "query",
    "text": "query GetHawkKeyQuery(\n  $platform: ExternalPlatformEnumType!\n) {\n  hawkKey(platform: $platform)\n}\n"
  }
};
})();

(node/*: any*/).hash = "f632c65169fd866507b15da954b13d87";

module.exports = ((node/*: any*/)/*: Query<
  GetHawkKeyQuery$variables,
  GetHawkKeyQuery$data,
>*/);
