import * as types from "./types";
const initState = {
  resCenterAssignedRelated: [],
  TotalNumberOfRecord: 0,
};
const ResCenterAssignedRelatedReducer = (state = initState, action) => {
  console.log("[resCenterAssignedRelatedReducer]", state, action);
  switch (action.type) {
    case types.ResCenterAssignedRelatedReducer.SET_RESCENTERASSIGNEDRELATED:
      return {
        ...state,
        resCenterAssignedRelated: action.resCenterAssignedRelated,
      };
    case types.ResCenterAssignedRelatedReducer
      .SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS:
      return {
        ...state,
        TotalNumberOfRecord: action.TotalNumberOfRecord,
      };
    default:
      return state;
  }
};

export default ResCenterAssignedRelatedReducer;
