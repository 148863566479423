/**
 * @generated SignedSource<<9e7ed4e1e6b6cbc0d9861579976bf161>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReadResourceSharingMutation$variables = {|
  SharingId: string,
  SchoolYearId: string,
|};
export type ReadResourceSharingMutation$data = {|
  +readResourceSharing: ?string,
|};
export type ReadResourceSharingMutation = {|
  variables: ReadResourceSharingMutation$variables,
  response: ReadResourceSharingMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SharingId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId"
      },
      {
        "kind": "Variable",
        "name": "SharingId",
        "variableName": "SharingId"
      }
    ],
    "kind": "ScalarField",
    "name": "readResourceSharing",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReadResourceSharingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReadResourceSharingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7779d060848e474ba40f1c9e5bc09afb",
    "id": null,
    "metadata": {},
    "name": "ReadResourceSharingMutation",
    "operationKind": "mutation",
    "text": "mutation ReadResourceSharingMutation(\n  $SharingId: String!\n  $SchoolYearId: String!\n) {\n  readResourceSharing(SharingId: $SharingId, SchoolYearId: $SchoolYearId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "41f8c9c317e7684852fd1e1e371c2de9";

module.exports = ((node/*: any*/)/*: Mutation<
  ReadResourceSharingMutation$variables,
  ReadResourceSharingMutation$data,
>*/);
