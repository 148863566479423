import { commitMutation, graphql } from "react-relay";
import environment from "../../../Environment";

const mutation = graphql`
  mutation ReadResourceMutation(
      $StructureId: String!
      $ResourceId: String!
      $SchoolYearId: String!
    ) {
    readResource(
      StructureId: $StructureId
      ResourceId:$ResourceId
      SchoolYearId:$SchoolYearId
      )
  }
`;

export default function ReadResourceMutation(data, action) {
  const variables = {
    StructureId: data.StructureId,
    ResourceId: data.ResourceId,
    SchoolYearId: data.SchoolYearId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
