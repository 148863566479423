import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const ResourcesSearchCheckAccessRightQuery = graphql`
  query ResourcesSearchCheckAccessRightQuery($IngestionId: [String]!) {
    resourceSearch(IngestionId: $IngestionId) {
      Resources {
        ResourceId
        ResourceType
        IngestionId
        BookSeriesId
        BookId
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
        Linkage
        FileSource
        ResourceLangs {
          ResourceName
          Lang
        }
      }
    }
  }
`;
const ResourcesSearchCheckAccessRightStudentQuery = graphql`
  query ResourcesSearchCheckAccessRightStudentQuery($IngestionId: [String]!,$StudyGroupId: String!
    $SchoolYearId: String!) {
    resourceSearch(IngestionId: $IngestionId,StudyGroupId: $StudyGroupId
      SchoolYearId: $SchoolYearId) {
      Resources {
        ResourceId
        ResourceType
        IngestionId
        BookSeriesId
        BookId
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
        Linkage
        FileSource
        ResourceLangs {
          ResourceName
          Lang
        }
      }
    }
  }
`;
const ResourcesSearchCheckAccessRight = (variables, role="") => {

  if(role !=""){
    if (role == "T") {
      return fetchQuery(environment, ResourcesSearchCheckAccessRightQuery, variables);
    } else {
      return fetchQuery(environment, ResourcesSearchCheckAccessRightStudentQuery, variables);
    }
  }else {
    return fetchQuery(
      environment,
      ResourcesSearchCheckAccessRightQuery,
      variables
    );
  }
};

export default ResourcesSearchCheckAccessRight;
