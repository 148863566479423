import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetEPSTokenQuery = graphql`
  query GetEPSTokenQuery($ResourceId: [String]!) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
        ResourceLangs {
          Lang
          ResourceName
        }
      }
    }
  }
`;

const GetEPSToken = (variables, role) => {
  return fetchQuery(environment, GetEPSTokenQuery, variables);
};

export default GetEPSToken;
