import React, { Component } from "react";
import "./index.scss";
import Logo_new from "../../assets/img/pearson-logo.svg";
import errorBanner from "../../assets/img/davide-bonnazi-capturing-curiosity-rgb.png";
import { NavLink } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/Dashboard/NavBar";
import i18n from "../../i18n";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorState: props.match.params.errorCode,
    };
  }
  render() {
    const { errorState } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        {
          //<NavBar afterLogout={this.props.afterLogout} t={this.props.t} />
        }
        <nav
          id="main"
          className="navbar navbar-expand fixed-top class-nav-bar md-whiteframe-4dp"
        >
          <div className="be-navbar-header class-nb-exit">
            <span className="navbar-brand" href="" />
          </div>
          <div className="class-nb-title" />
          <div className="class-nb-buttons">
            <div
              className="nav navbar-nav float-right be-user-nav"
              id="be-navbar-collapse"
            >
              {!this.props.authentication && (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a>
                      {localStorage.getItem("i18nextLng") == "en-US" ? (
                        <button
                          type="button"
                          className="link-button nav-link"
                          onClick={() => i18n.changeLanguage("zh-HK")}
                        >
                          {t("Chinese")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="link-button nav-link"
                          onClick={() => i18n.changeLanguage("en-US")}
                        >
                          {t("English")}
                        </button>
                      )}
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/login">
                      <button type="button" className="link-button nav-link">
                        {t("Login")}
                      </button>
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
        <section className="dashboard">
          <div className="bg">
            <div className="container">
              <div className="row">
                <div className="image_box  col-sm-6 ">
                  <img src={errorBanner} />
                </div>
                <div className="text_box col-sm-6 ">
                  <div className="text_body">
                    <div className="title">
                      {t("Oops! Something went wrong.")}
                    </div>
                    <div className="subtitle">
                      {t(
                        "Sorry, we can't find the page you are finding. Please go back or go to our home page."
                      )}
                    </div>
                    <div className="error_code">#{errorState}</div>
                    <div className="go_to_home">
                      <NavLink to={"/"}>
                        <button className="btn btn-warning btn-elg">
                          {t("Go to")}{" "}
                          {this.props.authentication
                            ? t("dashboard")
                            : t("home page")}
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}

export default index;
