import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const GetNotificationCountQuery = graphql`
  query GetNotificationCountQuery($UserId: String!, $SchoolYearId: String!) {
    user(UserId: $UserId) {
      NotificationCount(SchoolYearId: $SchoolYearId)
    }
  }
`;

const GetNotificationCount = (variables) => {
  return fetchQuery(environment, GetNotificationCountQuery, variables);
};

export default GetNotificationCount;
