/**
 * @generated SignedSource<<296371afe3bf281a806f59334aac6944>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type StudentAttemptyHistoryAssignmentQuery$variables = {|
  UserId: string,
  ResourceAssignId: string,
|};
export type StudentAttemptyHistoryAssignmentQuery$data = {|
  +resourceAssignment: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +ResourceAssignResId: ?string,
      +LearnosityAttemptHistory: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +IsCompleted: ?string,
        +AttemptSessionId: ?string,
        +AttemptDate: ?string,
        +UserId: ?string,
      |}>,
      +LearnosityAttemptLastHistory: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
      +LearnosityAttemptFirstHistory: ?$ReadOnlyArray<?{|
        +ResourceId: ?string,
        +LearnositySessionRawResult: ?string,
        +UserDetail: ?{|
          +UserName: ?string,
          +UserDisplayName: ?$ReadOnlyArray<?{|
            +UserName: ?string,
            +Lang: ?string,
          |}>,
        |},
      |}>,
    |}>,
  |},
|};
export type StudentAttemptyHistoryAssignmentQuery = {|
  variables: StudentAttemptyHistoryAssignmentQuery$variables,
  response: StudentAttemptyHistoryAssignmentQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceAssignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ResourceAssignId",
    "variableName": "ResourceAssignId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAssignResId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "UserId",
      "variableName": "UserId"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "kind": "LinkedField",
  "name": "LearnosityAttemptHistory",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IsCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptSessionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UserId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnositySessionRawResult",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "userLang",
  "kind": "LinkedField",
  "name": "UserDisplayName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v3/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "user",
    "kind": "LinkedField",
    "name": "UserDetail",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "user",
    "kind": "LinkedField",
    "name": "UserDetail",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v10/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentAttemptyHistoryAssignmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "resourceAssignment",
        "kind": "LinkedField",
        "name": "resourceAssignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceAssignmentResource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "LearnosityAttemptLastHistory",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "LearnosityAttemptFirstHistory",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StudentAttemptyHistoryAssignmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "resourceAssignment",
        "kind": "LinkedField",
        "name": "resourceAssignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceAssignmentResource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "LearnosityAttemptLastHistory",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
                "kind": "LinkedField",
                "name": "LearnosityAttemptFirstHistory",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96bf92417f12197df48e8f1d9777b7c1",
    "id": null,
    "metadata": {},
    "name": "StudentAttemptyHistoryAssignmentQuery",
    "operationKind": "query",
    "text": "query StudentAttemptyHistoryAssignmentQuery(\n  $UserId: String!\n  $ResourceAssignId: String!\n) {\n  resourceAssignment(ResourceAssignId: $ResourceAssignId) {\n    Resources {\n      ResourceId\n      ResourceAssignResId\n      LearnosityAttemptHistory(UserId: $UserId) {\n        ResourceId\n        IsCompleted\n        AttemptSessionId\n        AttemptDate\n        UserId\n      }\n      LearnosityAttemptLastHistory {\n        ResourceId\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      LearnosityAttemptFirstHistory {\n        ResourceId\n        LearnositySessionRawResult\n        UserDetail {\n          UserName\n          UserDisplayName {\n            UserName\n            Lang\n          }\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9873b92e11f994ce5ad0c513f94534a3";

module.exports = ((node/*: any*/)/*: Query<
  StudentAttemptyHistoryAssignmentQuery$variables,
  StudentAttemptyHistoryAssignmentQuery$data,
>*/);
