import * as types from "./types";
const initState = {
  session_id: null,
  attemptDate: null,
  userId: null,
};
const StudentResultReducer = (state = initState, action) => {
  switch (action.type) {
    case types.StudentResultReducer.SET_STUDENT_RESULT:
      return {
        ...state,
        session_id: action.session_id,
        attemptDate: action.attemptDate,
        userId: action.userId,
      };
    default:
      return state;
  }
};

export default StudentResultReducer;
