import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation DeleteNotificationMutation(
    $SharingId: String!
  ) {
    deleteUserNotification(SharingId: $SharingId)
  }
`;
export default function DeleteNotificationMutation(SharingId, action) {
  var variables = {
    SharingId : SharingId
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
