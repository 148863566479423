import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { config } from "./Config/config";
import "whatwg-fetch";
import { Router } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./Reducers";

import history from "./history";
import ScrollToTop from "./Components/Feature/ScrollToTop";
import "bootstrap";
import "font-awesome/css/font-awesome.css";
//Components
//css

import "./assets/css/link.css";
import "./assets/css/loading.css";
import "./assets/lib/custom-icon/custom-icon.scss";
import "./assets/lib/material-design-icons/css/material-design-iconic-font.scss";
import initGA from "./Services/GA";
import initGATag from "./Services/GATag";
import initRecaptcha from "./Services/Recaptcha";
var appReady = true;
var dpURL = null;
const handleOpenURL = (url) => {
  console.log("received url: " + url);
  localStorage.setItem("dp_URL", url);
  if (window.handleAppSSO) {
    window.handleAppSSO();
    //console
  }
};
const startApp = () => {
  const store = createStore(rootReducer);
  // const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  if (appReady) {
    console.log("[App] StartApp...");
    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop />
          <App dpURL={dpURL} />
        </Router>
      </Provider>,
      document.getElementById("root")
    );

    if (window.cordova) {
      // window.FirebasePlugin.setAnalyticsCollectionEnabled(false);
      // window.FirebasePlugin.setPerformanceCollectionEnabled(false);
      // window.FirebasePlugin.setCrashlyticsCollectionEnabled(false);
      window.navigator.splashscreen.hide();
      gaTrack("set", "dimension3", "App");
    } else {
      gaTrack("set", "dimension3", "Web");
    }
  }
};

// const fireBase = (callback) => {
//   window.FirebasePlugin.hasPermission(function (hasPermission) {
//     console.log("Permission is " + (hasPermission ? "granted" : "denied"));
//     if (!hasPermission) {
//       window.FirebasePlugin.grantPermission(function (hasPermission) {
//         console.log("Permission was " + (hasPermission ? "granted" : "denied"));
//         callback();
//       });
//     } else {
//       callback();
//       window.FirebasePlugin.getAPNSToken(
//         function (apnsToken) {
//           console.log("apnsToken", apnsToken);
//         },
//         function (error) {
//           console.error("apnsToken", error);
//         }
//       );
//       window.FirebasePlugin.onMessageReceived(
//         function (message) {
//           console.log("Message type: " + message.messageType);
//           if (message.messageType === "notification") {
//             console.log("Notification message received");
//             if (message.tap) {
//               console.log("Tapped in " + message.tap);
//             }
//           }
//           //console.dir(notification);
//         },
//         function (error) {
//           console.error(error);
//         }
//       );
//     }
//   });
// };

const onApplicationDidLaunchFromLink = (eventData) => {
  console.log("[onApplicationDidLaunchFromLink] ", eventData);
  window.ULink = eventData;
  if (window.handleReactPush) {
    window.handleReactPush(eventData);
  }
};

const initApp = async () => {
  if (window.cordova) {
    console.log("[App] Cordova find, render as app version!");

    document.addEventListener(
      "deviceready",
      async () => {
        window.handleOpenURL = handleOpenURL;
        if (typeof universalLinks != "undefined")
          universalLinks.subscribe(
            "launchedAppFromLink",
            onApplicationDidLaunchFromLink
          );
        //window.open = cordova.InAppBrowser.open;
        console.log("[Init] initGA Start...");
        await initGA();
        // await initGATag();
        console.log("[Init] initGA Done...");
        // fireBase(startApp);
        startApp();
      },
      false
    );
  } else {
    console.log("[App] Cordova not find, render as web version!");
    appReady = true;
    if (appReady == true) {
      console.log("[Init] initGA Start...");
      await initGA();
      await initGATag();
      console.log("[Init] initGA Done...");
      console.log("[Init] initRecaptcha Start...");
      await initRecaptcha();
      console.log("[Init] initRecaptcha Done...");
      startApp();
    }
  }
};

initApp();
