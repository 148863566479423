import React from "react";
import { Col, Row } from 'antd';
import { Typography } from 'antd';
import "../index.scss"

const { Text } = Typography;
const LogoSelectCard = ({el, onSelectClick}) => {
    return (
        <Row align={"middle"} onTouchStart={(e)=>onSelectClick(el.BookSeriesId)} on>
            <Col span={10}>
                <img
                    className="select-box-logo-image"
                    alt="logo2"
                    src={`https:${el?.BookSeriesImage?.FilePath}`}
                />
            </Col>
            <Col className={"logo-select-bookname"} span={14} style={{wordWrap:"break-word"}}>
                {/*<Text>{el?.BookSeriesName[0]?.BookSeriesName}</Text>*/}
                {el?.BookSeriesName[0]?.BookSeriesName}
            </Col>
        </Row>
    )
}

export default LogoSelectCard;