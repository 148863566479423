import video from "../../assets/img/B2C/icon-video-link.svg";
import activity from "../../assets/img/B2C/icon-exercise.svg";
import audio_b from "../../assets/img/B2C/icon-audio.svg";
import html_b from "../../assets/img/B2C/icon-web-resource.svg";

export const suggestionTypeIconMapping = (type) => {
  switch (type) {
    case "VIDEO":
    case "YOUTUBE":
    case "VIMEO":
      return video;
    case "AUDIO":
      return audio_b;

    case "LEARNOSITY":
      return activity;

    case "PACK":
    case "OPE":
    case "ILESSON":
    case "IACTIVITY":
    case "WEB_LINK":
    case "EBOOK4":
    case "DOCUMENT":
    case "INTERACTIVE":
    case "IMAGE":
    case "OTHER":
    default:
      return html_b;
  }
};
