const mode = window.cordova ? "App" : "Web"; //   dev/stg/qa/pro/poc
const device = window.cordova ? "App" : "Web"; //Web / App
//const mode = "dev";
//const device = "App";
const hostVal = window.location.hostname;
// future server setup we need this
const autoSwitch = () => {
  switch (window.location.hostname) {
    case "23.101.12.11":
      return "http://23.101.12.11/api";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/api/";
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/api/";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/api/";
    case "iteach2.dev.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/api/";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/api/";
    case "longmanplus.qa.pearson.com.hk":
      return "https://longmanplus.qa.pearson.com.hk/api/";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/api/";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/api/";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/api/";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/api/";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/api/";
      case "peclocal.pearson.com.hk":
        return "http://peclocal.pearson.com.hk:4000/api/";
    case "back":
      return "http://localhost/api/";
    case "localhost":
      return (
        "http://" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/api/"
      );
    default:
      if (window.cordova) {
        //is app

        if (mode == "pro") return "https://longmanplus.pearson.com.hk/api/";
        if (mode == "qa") return "https://longmanplus.qa.pearson.com.hk/api/";
        if (mode == "stg") return "https://iteach2.stg.pearson.com.hk/api/";
        if (mode == "dev") return "https://pec.dev.pearson.com.hk/api/";
        if (mode == "poc")
          return "https://longmanplus-p2-poc.pearson.com.hk/api/";
      } else {
        console.log("[No hostname matched] autoMatch");
        return "https://pec.dev.pearson.com.hk/api/";
      }
  }
};

const autoSwitchIAM = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "localhost":
      return (
        "http://" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/iamlogin"
      );
    // return "https://iam-stage.pearson.com/auth/json/realms/pearson/authenticate";
    case "23.101.12.11":
      return "https://iam.pearson.com/auth/json/realms/pearson/authenticate";
    case "longmanplus.pearson.com.hk":
      return "https://iam.pearson.com/auth/json/realms/pearson/authenticate";
    case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "pec.pearson.com.hk":
    case "localhost":
      return "https://iam-stage.pearson.com/auth/json/realms/pearson/authenticate";

    default:
      if (window.cordova) {
        //is app

        if (mode == "pro")
          return "https://iam.pearson.com/auth/json/realms/pearson/authenticate";
        if (mode == "qa" || mode == "stg" || mode == "dev" || mode == "poc")
          return "https://iam-stage.pearson.com/auth/json/realms/pearson/authenticate";
      } else {
        console.log("[No hostname matched] autoSwitchIAM");
        break;
      }
  }
};

const autoSwitchIAMSSOLogin = () => {
  switch (window.location.hostname) {
    case "23.101.12.11":
      return "https://iam.pearson.com/auth/oauth2/tokeninfo?access_token=";
    case "longmanplus.pearson.com.hk":
      return "https://iam.pearson.com/auth/oauth2/tokeninfo?access_token=";
    case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pec.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "localhost":
      return "https://iam-stage.pearson.com/auth/oauth2/tokeninfo?access_token=";
    default:
      if (window.cordova) {
        //is app

        if (mode == "pro")
          return "https://iam.pearson.com/auth/oauth2/tokeninfo?access_token=";
        return "https://iam-stage.pearson.com/auth/oauth2/tokeninfo?access_token=";
      } else {
        console.log("[No hostname matched] autoSwitchIAMSSOLogin");
        break;
      }
  }
};

const autoSwitchIAMLogout = () => {
  switch (window.location.hostname) {
    case "23.101.12.11":
      return "https://iam.pearson.com/auth/json/sessions/?_action=logout";
    case "longmanplus.pearson.com.hk":
      return "https://iam.pearson.com/auth/json/sessions/?_action=logout";
    case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pec.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
      return "https://iam-stage.pearson.com/auth/json/sessions/?_action=logout";
    case "localhost":
      return (
        "http://" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/iamlogout"
      );
    default:
      if (window.cordova) {
        //is app

        if (mode == "pro")
          return "https://iam.pearson.com/auth/json/sessions/?_action=logout";
        return "https://iam-stage.pearson.com/auth/json/sessions/?_action=logout";
      } else {
        console.log("[No hostname matched] autoSwitchIAMLogout");
        break;
      }
  }
};

const autoSwitchIAMSSOLogout = () => {
  switch (window.location.hostname) {
    case "23.101.12.11":
      return "https://iam.pearson.com/auth/json/sessions/?_action=logout";
    case "longmanplus.pearson.com.hk":
      return "https://iam.pearson.com/auth/json/sessions/?_action=logout";
    case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pec.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "localhost":
      return "https://iam-stage.pearson.com/auth/json/sessions/?_action=logout";
    default:
      if (window.cordova) {
        //is app

        if (mode == "pro")
          return "https://iam.pearson.com/auth/json/sessions/?_action=logout";
        return "https://iam-stage.pearson.com/auth/json/sessions/?_action=logout";
      } else {
        console.log("[No hostname matched] autoSwitchIAMSSOLogout");
        break;
      }
  }
};

const autoSwitchLanguageUrl = () => {
  switch (window.location.hostname) {
    case "23.101.12.11":
      return "https://23.101.12.11/assets/config/";
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/assets/config/";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/assets/config/";
    case "iteach2.dev.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/assets/config/";
    case "longmanplus.qa.pearson.com.hk":
      return "https://longmanplus.qa.pearson.com.hk/assets/config/";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/assets/config/";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/assets/config/";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/assets/config/";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/assets/config/";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/assets/config/";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/assets/config/";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/assets/config/";
    case "peclocal.pearson.com.hk":
      return "http://peclocal.pearson.com.hk:4000/assets/config/";
    case "localhost":
      return (
        "http://" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/assets/config/"
      );
    default:
      //only will affect web,  app will handle in i18.js

      if (mode == "pro")
        return "https://longmanplus.pearson.com.hk/assets/config/";
      if (mode == "qa")
        return "https://longmanplus.qa.pearson.com.hk/assets/config/";
      if (mode == "stg")
        return "https://iteach2.stg.pearson.com.hk/assets/config/";
      if (mode == "dev")
        return "https://iteach2.dev.pearson.com.hk/assets/config/";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/assets/config/";
  }
};

const autoSwitchInstallPageUrl = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/install";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/install";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/install";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/install";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/install";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/install/";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/install/";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/install/";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/install/";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/install/";
      case "peclocal.pearson.com.hk":
        return "http://peclocal.pearson.com.hk:4000/install/";
    default:
      if (mode == "pro") return "https://longmanplus.pearson.com.hk/install";
      if (mode == "qa") return "https://longmanplus.qa.pearson.com.hk/install";
      if (mode == "stg") return "https://iteach2.stg.pearson.com.hk/install";
      if (mode == "dev") return "https://iteach2.dev.pearson.com.hk/install";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/install";
  }
};

const autoSwitchPearsonComUrl = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://leap.ilongman.com/josso/iam/index.do?act=authenticate2&code=";
    case "iteach2.dev.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "longmanplus.demo.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
    case "pec.pearson.com.hk":
      case "peclocal.pearson.com.hk":
     return "https://leap.beta.ilongman.com/josso/iam/index.do?act=authenticate2&code=";
    default:
      return mode == "pro"
        ? "https://leap.ilongman.com/josso/iam/index.do?act=authenticate2&code="
        : "https://leap.beta.ilongman.com/josso/iam/index.do?act=authenticate2&code=";
  }
};

const autoSwitchHelpPage = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/help";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/help";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/help";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/help";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/help/";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/help/";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/help/";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/help/";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/help/";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/help/";
      case "peclocal.pearson.com.hk":
        return "http://peclocal.pearson.com.hk:4000/help/";
    default:
      if (mode == "pro") return "https://longmanplus.pearson.com.hk/help";
      if (mode == "qa") return "https://longmanplus.qa.pearson.com.hk/help";
      if (mode == "stg") return "https://iteach2.stg.pearson.com.hk/help";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/help";
      if (mode == "dev") return "https://iteach2.dev.pearson.com.hk/help";
  }
};

// const autoSwitchDigitalSupporthz = () => {
//   switch (window.location.hostname) {
//     case "longmanplus.pearson.com.hk":
//       return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "iteach2.dev.pearson.com.hk":
//     case "longmanplus.qa.pearson.com.hk":
//       return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "iteach2.stg.pearson.com.hk":
//       return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "longmanplus.demo.pearson.com.hk":
//       return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "longmanplus-p2-poc.pearson.com.hk":
//       return "https://longmanplus-p2-poc.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "pec.dev.pearson.com.hk":
//       return "https://pec.dev.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "pec.qa.pearson.com.hk":
//       return "https://pec.qa.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "pec.stg.pearson.com.hk":
//       return "https://pec.stg.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "pecdemo.pearson.com.hk":
//       return "https://pecdemo.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     case "pec.pearson.com.hk":
//       return "https://pec.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//     default:
//       if (mode == "pro")
//         return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//       if (mode == "qa")
//         return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//       if (mode == "stg")
//         return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//       if (mode == "poc")
//         return "https://longmanplus-p2-poc.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//       if (mode == "dev")
//         return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
//   }
// };
const autoSwitchDigitalSupporthz = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "iteach2.stg.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "longmanplus.demo.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.dev.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.qa.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.stg.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pecdemo.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
      case "peclocal.pearson.com.hk":
        return "https://register.pearson.com.hk/faq/?lang=en";
    default:
      if (mode == "pro")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "qa")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "stg")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "poc")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "dev")
        return "https://register.pearson.com.hk/faq/?lang=en";
  }
};

const autoSwitchDigitalSupport = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "iteach2.stg.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "longmanplus.demo.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.dev.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.qa.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.stg.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pecdemo.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
    case "pec.pearson.com.hk":
      return "https://register.pearson.com.hk/faq/?lang=en";
      case "peclocal.pearson.com.hk":
        return "https://register.pearson.com.hk/faq/?lang=en";
    default:
      if (mode == "pro")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "qa")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "stg")
        return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "poc")
      return "https://register.pearson.com.hk/faq/?lang=en";
      if (mode == "dev")
        return "https://register.pearson.com.hk/faq/?lang=en";
  }
};

// const autoSwitchDigitalSupport = () => {
//   switch (window.location.hostname) {
//     case "longmanplus.pearson.com.hk":
//       return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "iteach2.dev.pearson.com.hk":
//     case "longmanplus.qa.pearson.com.hk":
//       return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "iteach2.stg.pearson.com.hk":
//       return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "longmanplus.demo.pearson.com.hk":
//       return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "longmanplus-p2-poc.pearson.com.hk":
//       return "https://longmanplus-p2-poc.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "pec.dev.pearson.com.hk":
//       return "https://pec.dev.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "pec.qa.pearson.com.hk":
//       return "https://pec.qa.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "pec.stg.pearson.com.hk":
//       return "https://pec.stg.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "pecdemo.pearson.com.hk":
//       return "https://pecdemo.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     case "pec.pearson.com.hk":
//       return "https://pec.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//     default:
//       if (mode == "pro")
//         return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//       if (mode == "qa")
//         return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//       if (mode == "stg")
//         return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//       if (mode == "poc")
//         return "https://longmanplus-p2-poc.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//       if (mode == "dev")
//         return "https://www.pearson.com.hk/en_GB/about-us/contact-us/itfaq.html";
//   }
// };

const autoSwitchManageBook = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "iteach2.stg.pearson.com.hk":
      return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "longmanplus.demo.pearson.com.hk":
      return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
     
    default:
      if (mode == "pro")
        return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
      if (mode == "qa")
        return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
      if (mode == "stg")
        return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
      if (mode == "dev")
        return "https://www.pearson.com.hk/zh_HK/about-us/contact-us/itfaq.html";
  }
};

const autoSwitchInAppBrowserAssessmentPage = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/assessment=api/entry/q?";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/assessment=api/entry/q?";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/assessment=api/entry/q?";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/assessment=api/entry/q?";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/assessment=api/entry/q?";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/assessment=api/entry/q?";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/assessment=api/entry/q?";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/assessment=api/entry/q?";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/assessment=api/entry/q?";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/assessment=api/entry/q?";
    default:
      if (mode == "pro")
        return "https://longmanplus.pearson.com.hk/assessment=api/entry/q?";
      if (mode == "qa")
        return "https://longmanplus.qa.pearson.com.hk/assessment=api/entry/q?";
      if (mode == "stg")
        return "https://iteach2.stg.pearson.com.hk/assessment=api/entry/q?";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/assessment=api/entry/q?";
      if (mode == "dev")
        return "https://iteach2.dev.pearson.com.hk/assessment=api/entry/q?";
  }
};

const autonSwitchOpenResources = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
    default:
      if (mode == "pro")
        return "https://longmanplus.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
      if (mode == "qa")
        return "https://longmanplus.qa.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
      if (mode == "stg")
        return "https://iteach2.stg.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
      if (mode == "dev")
        return "https://iteach2.dev.pearson.com.hk/app/sso?action=OPEN_RESOURCE";
  }
};
const InAppBrowserViewDetialsOpenAssessment = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/assignment";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/assignment";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/assignment";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/assignment";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/assignment";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/assignment";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/assignment";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/assignment";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/assignment";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/assignment";
    default:
      if (mode == "pro") return "https://longmanplus.pearson.com.hk/assignment";
      if (mode == "qa")
        return "https://longmanplus.qa.pearson.com.hk/assignment";
      if (mode == "stg") return "https://iteach2.stg.pearson.com.hk/assignment";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/assignment";
      if (mode == "dev") return "https://iteach2.dev.pearson.com.hk/assignment";
  }
};

const InAppBrowserOpenAssessmentURL = () => {
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
      return "https://longmanplus.pearson.com.hk/activity";
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
      return "https://iteach2.dev.pearson.com.hk/activity";
    case "iteach2.stg.pearson.com.hk":
      return "https://iteach2.stg.pearson.com.hk/activity";
    case "longmanplus.demo.pearson.com.hk":
      return "https://longmanplus.demo.pearson.com.hk/activity";
    case "longmanplus-p2-poc.pearson.com.hk":
      return "https://longmanplus-p2-poc.pearson.com.hk/activity";
    case "pec.dev.pearson.com.hk":
      return "https://pec.dev.pearson.com.hk/activity";
    case "pec.qa.pearson.com.hk":
      return "https://pec.qa.pearson.com.hk/activity";
    case "pec.stg.pearson.com.hk":
      return "https://pec.stg.pearson.com.hk/activity";
    case "pec.pearson.com.hk":
      return "https://pec.pearson.com.hk/activity";
    case "pecdemo.pearson.com.hk":
      return "https://pecdemo.pearson.com.hk/activity";
    default:
      if (mode == "pro") return "https://longmanplus.pearson.com.hk/activity";
      if (mode == "qa") return "https://longmanplus.qa.pearson.com.hk/activity";
      if (mode == "stg") return "https://iteach2.stg.pearson.com.hk/activity";
      if (mode == "poc")
        return "https://longmanplus-p2-poc.pearson.com.hk/activity";
      if (mode == "dev") return "https://iteach2.dev.pearson.com.hk/activity";
  }
};

const createGCURL = () => {
  let redirctURL = `https://${window.location.hostname}`;
  let registerURL = "";
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      registerURL =
        "https://register.pearson.com.hk/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
      break;
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
        registerURL =
        "https://register.pprod4.ilongman.com/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
      break;
    case "localhost":
      registerURL =
        "https://register.pprod4.ilongman.com/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";

      redirctURL = "http://localhost:4000/user_contingency/app/sso?";
      break;
    default:
      registerURL =
        "https://register.pprod4.ilongman.com/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
      switch (mode) {
        case "pro":
          registerURL =
            "https://register.pearson.com.hk/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
          redirctURL = "https://longmanplus.pearson.com.hk";
          break;
        case "qa":
          redirctURL = "https://pec.qa.pearson.com.hk";
          break;
        case "stg":
          redirctURL = "https://pec.stg.pearson.com.hk";
          break;
        case "dev":
          redirctURL = "https://pec.dev.pearson.com.hk";
          break;
        case "poc":
          redirctURL = "https://longmanplus-p2-poc.pearson.com.hk";
          break;
        default:
          redirctURL = "https://pec.dev.pearson.com.hk";
          break;
      }
      break;
  }
  return {
    registerURL: registerURL,
    redirctURL: redirctURL + "/app/sso?",
  };
};

const createAcountURLQRCode = () => {
  let redirctURL = `https://${window.location.hostname}`;
  let registerURL = "";
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      registerURL =
        "https://register.pearson.com.hk/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
      break;
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
      registerURL =
        "https://register.pprod4.ilongman.com/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
      break;
    case "localhost":
      registerURL =
        "https://register.pprod4.ilongman.com/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";

      redirctURL = "http://localhost:4000/user_contingency/app/sso?";
      break;
    default:
      registerURL =
        "https://register.pprod4.ilongman.com/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
      switch (mode) {
        case "pro":
          registerURL =
            "https://register.pearson.com.hk/longmanplusres-reg.php?p={pin}&returnURL={returnURL}";
          redirctURL = "https://longmanplus.pearson.com.hk";
          break;
        case "qa":
          redirctURL = "https://longmanplus.qa.pearson.com.hk";
          break;
        case "stg":
          redirctURL = "https://iteach2.stg.pearson.com.hk";
          break;
        case "dev":
          redirctURL = "https://iteach2.dev.pearson.com.hk";
          break;
        case "poc":
          redirctURL = "https://longmanplus-p2-poc.pearson.com.hk";
          break;
        default:
          redirctURL = "https://iteach2.dev.pearson.com.hk";
          break;
      }
      break;
  }
  return {
    registerURL: registerURL,
    redirctURL: redirctURL + "/app/sso?",
  };
};

const getPrizmRefreshURL = () => {
  let url = "https://iesregister.beta4.ilongman.com/oauth_refresh.php";
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      url = "https://register.pearson.com.hk/oauth_refresh.php";
      break;
    default:
      switch (mode) {
        case "pro":
          url = "https://register.pearson.com.hk/oauth_refresh.php";
          break;

        default:
          break;
      }
      break;
  }
  return url;
};

const createIESURL = () => {
  let url = "https://iesregister.beta4.ilongman.com/ieslogin.php";
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      url = "https://register.pearson.com.hk/ieslogin.php";
      break;
    // case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "localhost":
      break;
    default:
      switch (mode) {
        case "pro":
          url = "https://register.pearson.com.hk/ieslogin.php";
          break;

        default:
          break;
      }
      break;
  }
  return url;
};

const createIESBACKURL = () => {
  let url = "https:%2F%2Fiesregister.beta4.ilongman.com%2Fieschecksession.php";
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      url = "https:%2F%2Fregister.pearson.com.hk%2Fieschecksession.php";
      break;
    // case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "localhost":
      break;
    default:
      switch (mode) {
        case "pro":
          url = "https:%2F%2Fregister.pearson.com.hk%2Fieschecksession.php";
          break;

        default:
          break;
      }
      break;
  }
  return url;
};

const createAccountURL = () => {
  let url = "https://register.pprod4.ilongman.com/login.php";
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      url = "https://register.pearson.com.hk/login.php";
      break;
    // case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "localhost":
      break;
    default:
      switch (mode) {
        case "pro":
          url = "https://register.pearson.com.hk/login.php";
          break;

        default:
          break;
      }
      break;
  }
  return url;
};

const resetPassword = () => {
  //console.log("testing");
  let url = "https://iesregister.beta4.ilongman.com/forgot-password.php";
  
  switch (window.location.hostname) {
    case "longmanplus.pearson.com.hk":
    case "pec.pearson.com.hk":
      url = "https://register.pearson.com.hk/forgot-password.php";
      break;
    // case "longmanplus.demo.pearson.com.hk":
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
    case "localhost":
      break;
    default:
      switch (mode) {
        case "pro":
          url = "https://register.pearson.com.hk/forgot-password.php";
          break;
        default:
          break;
      }
      break;
  }
  return url;
};

const getPrizmHiddenIFrame = () => {
  return "https://" + window.location.hostname + "/api/resource/get-prizm-player.html"
}

const pickhHost = () => {
  let val = "PEC";
  switch (window.location.hostname) {
    case "iteach2.dev.pearson.com.hk":
    case "longmanplus.qa.pearson.com.hk":
    case "iteach2.stg.pearson.com.hk":
    case "longmanplus-p2-poc.pearson.com.hk":
    case "longmanplus.pearson.com.hk":
      val = "Longmanplus";
      break;
    case "pec.dev.pearson.com.hk":
    case "pec.qa.pearson.com.hk":
    case "pec.stg.pearson.com.hk":
    case "pec.pearson.com.hk":
    case "pecdemo.pearson.com.hk":
      case "peclocal.pearson.com.hk":
      val = "PEC";
      break;
    default:
      break;
  }

  return val;
  // return "PEC";
};

const pickLogin = () => {
  return "no";
};

const system_config = {
  grqphQl_url: autoSwitch(),
  languageFileUrl: autoSwitchLanguageUrl(),
  forgerock_url: autoSwitchIAM(),
  forgerock_logout_url: autoSwitchIAMLogout(),
  forgerock_sso_url: autoSwitchIAMSSOLogin(),
  forgerock_sso_logout_url: autoSwitchIAMSSOLogout(),
  InAppBrowserAssessmentURL: autoSwitchInAppBrowserAssessmentPage(),
  pickhHostVal: pickhHost(),
  tempLogin: pickLogin(),
  prizmRefreshURL: getPrizmRefreshURL(),
  prizmHiddenIframeURL: getPrizmHiddenIFrame(),
  InAppBrowserViewDetialsOpenAssessmentURL: {
    start: InAppBrowserViewDetialsOpenAssessment(),
  },
  InAppBrowserOpenAssessmentURL: {
    start: InAppBrowserOpenAssessmentURL(),
  },
  openResourcesApi: autonSwitchOpenResources(),
  createAccountURLQRCode: createAcountURLQRCode(),
  createAccountURL: createAccountURL(),
  playStore: "https://play.google.com/store/apps/details?id=",
  playStoreChi: "&hl=zh_HK",
  playStoreEng: "&hl=en_US",
  AppStore: "itms-apps://itunes.apple.com/hk/app/id",
  AppStoreChi: "?l=zh",
  AppStoreEng: "?l=en",
  installPageURL: autoSwitchInstallPageUrl(),
  digitalSupport: autoSwitchDigitalSupport(),
  digitalSupporthz: autoSwitchDigitalSupporthz(),
  manageBook: autoSwitchManageBook(),
  //dev http://iteach2.dev.pearson.com.hk/api/s
  //local http://localhost:3010/api/
  //Staging http://iteach2.stg.pearson.com.hk/api/
  mode: mode,
  device: device, // Web / App
  recaptcharKey: "6LeXhZ0UAAAAAMeIcz45c3jORAm8iNL-feLvvuVD",
  // googleAnalytics: "UA-52750440-22",
  googleAnalytics: "G-6QG0XCJHS1",
  pearsonComURL: autoSwitchPearsonComUrl(),
  helpPage: autoSwitchHelpPage(),
  IESURL: createIESURL(),
  createGCURL: createGCURL(),
  IESBACKURL: createIESBACKURL(),
  userTypeList: ["1", "2"],
  resetPasswordURL: resetPassword(),
  hidden: {
    labelDigital: [
      "JS_Chi_Classicalreading",
      "SS_NSS_Econ3_C",
      "SS_NSS_Econ3_E",
      "SS_NSS_BAFS3_E",
      "SS_NSS_BAFS3_C",
      "SS_CHIN_2E",
    ],
    labellistening: ["cep_3e", "js_listeninglab_LEFL"],
    hideAssignment: ["Pri_Eng_PLET2E", "Edge_2E_t"],
    showProgress: ["Pri_Eng_PLET2E", "Edge_2E_t"],
    labelSDL: ["Pri_Eng_PLET2E", "Edge_2E_t"],
    labelgrammar: ["js_eltedge_edge", "js_eltspark_spark"],
    prizmRecording :['Edge_2E_1BU5_Speak_SpeakEx','Edge_2E_1AU1_Vocab_Study',
  'Edge_2E_1AU1_Speak_SpeakEx','Edge_2E_1AU2_Vocab_Study','Edge_2E_1AU2_Speak_SpeakEx','Edge_2E_1AU3_Vocab_Study'
,'Edge_2E_1AU3_Speak_SpeakEx','Edge_2E_1AU4_Vocab_Study','Edge_2E_1AU4_Speak_SpeakEx','Edge_2E_2AU1_Vocab_Study','Edge_2E_2AU1_Speak_SpeakEx',
'Edge_2E_2AU2_Vocab_Study','Edge_2E_2AU2_Speak_SpeakEx','Edge_2E_2AU3_Vocab_Study','Edge_2E_2AU3_Speak_SpeakEx',
'Edge_2E_2AU4_Vocab_Study','Edge_2E_2AU4_Speak_SpeakEx','Edge_2E_3AU1_Vocab_Study','Edge_2E_3AU1_Speak_SpeakEx','Edge_2E_3AU2_Vocab_Study','Edge_2E_3AU2_Speak_SpeakEx','Edge_2E_3AU3_Vocab_Study',
'Edge_2E_3AU3_Speak_SpeakEx','Edge_2E_3AU4_Vocab_Study','Edge_2E_3AU4_Vocab_Study','Edge_2E_1BU5_LS_SpeakEx'],
    rcenter: [
      "js_listeninglab_LEFL",
      "JSMIA_C",
      "pri_MIA_c",
      "JSMIA_E",
      "pri_lmn_music",
      "JS_Chi_Classicalreading",
      "js_eltedge_edge",
      "js_eltspark_spark",
      "cep_3e",
      "SS_NSS_BAFS3_E",
      "SS_NSS_BAFS3_C",
      "SS_CHIN_2E",
    ],
    assessment: ["pri_pcec", "Pri_Eng_PLET2E", "Edge_2E_t"],
    rcenterassessment: [
      "JSMIA_C",
      "pri_MIA_c",
      "JSMIA_E",
      "pri_lmn_music",
      "pri_pcec",
      "JS_Chi_Classicalreading",
      "js_eltedge_edge",
      "js_eltspark_spark",
      "cep_3e",
    ],
    //for B2C hide the resources center
    b2cRcenter: ["PriMath_Diag"],
  },
};

export const config = system_config;
