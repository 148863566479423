import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import environment from "../../../../../../../Environment";
import { config } from "../../../../../../../Config/config";
import { fetchQuery, graphql } from "relay-runtime";
import "./index.scss";
import { Element, animateScroll as scroll } from "react-scroll";
import Loading from "../../../../../../Loading/Loading";
import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import StudentAttemptyHistory from "../../../../../../../Services/api/GraphQL/Query/StudentAttemptyHistory";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const QuestionViewSingleQuestionQuery = graphql`
  query QuestionViewSingleQuestionQuery(
    $ResourceId: [String]!
    $Lang: LangEnumType
  ) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        LearnosityModelAnswer(Lang: $Lang)
      }
    }
  }
`;

const QuestionViewGetStudyGroupListQuery = graphql`
  query QuestionViewGetStudyGroupListQuery(
    $StudyGroupId: String!
    $SchoolYearId: String!
  ) {
    studyGroup(StudyGroupId: $StudyGroupId) {
      StudyGroupName {
        Lang
        StudyGroupName
      }
      StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
        StudyGroupId
        StudyGroupName {
          Lang
          StudyGroupName
        }
      }
    }
  }
`;

const QuestionViewAllStudentsResultQuery = graphql`
  query QuestionViewAllStudentsResultQuery(
    $StudyGroupId: String!
    $SchoolYearId: String!
    $ResourceId: [String]!
    $IncludeAssignmentRecord: Boolean
  ) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        SelfLearnLastAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          UserDetail {
            UserName
            UserDisplayName {
              UserName
              Lang
            }
          }
        }
        SelfLearnFirstAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          UserDetail {
            UserName
            UserDisplayName {
              UserName
              Lang
            }
          }
        }
      }
    }
  }
`;

let itemApp;
export default (props) => {
  const {
    isInappBrowser,
    t,
    resourceData,
    bookData,
    standalone,
    location,
    B2C,
  } = props;

  const { i18n } = useTranslation();
  i18n.reloadResources();
  const { state: { selectOptions, questions } = {} } = location;
  console.log("sureshtest", selectOptions)
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const [questNumber, setquestNumber] = useState(null);
  // const [loading, setloading] = useState(true);
  // const [selectedStudyGroupId, setselectedStudyGroupId] = useState(null);
  const [studentsResponsesloading, setstudentsResponsesloading] =
    useState(false);
  const [studentsRecord, setstudentsRecord] = useState([]);
  const [studentsResult, setstudentsResult] = useState([]);
  const [studyGroupList, setstudyGroupList] = useState([]);
  const [learnosityObject, setlearnosityObject] = useState(null);
  const [learnositySession_id, setLearnositySession_id] = useState(null);
  const [totalQuestionLength, settotalQuestionLength] = useState(questions);
  const [initReady, setinitReady] = useState(false);
  const [questionData, setquestionData] = useState(null);
  const [selectedIndex, setselectedIndex] = useState(0);
  const selectOptionsContext = useSelector(
      (state) => state.EntryOfAssessmentReducer.selectOptions
  );

  let resourceId;
  if (isInappBrowser && !standalone) {
    resourceId = props.inappData.resourceId;
  } else {
    resourceId = props.match.params.resId.split("&")[0];
  }

  useEffect(() => {
    // const userType = localStorage.getItem("role");
    if (!isInappBrowser) {
      window.document
        .getElementById("studygroup_back")
        ?.classList.add("dim-button");
    }
    window.document
      .getElementById("assessment_tab")
      ?.classList.add("dim-button");
    window.document.getElementById("result_tab")?.classList.add("dim-button");
    if (userType == "teacher") {
      window.document.getElementById("answer_tab")?.classList.add("dim-button");
      window.document
        .getElementById("back_result")
        ?.classList.add("dim-button");
    }
    let urlParams = props.match.params.questionId;
    config.mode == "dev" && console.log(urlParams, "Q ID!");
    console.log(urlParams, "urlParams");
    setquestNumber(urlParams);

    getAllStudentsResult();
  }, []);

  useEffect(() => {
    return () => {
      console.log("resetlearn");
      window.LearnosityItems.reset();
    };
  }, []);

  const getAllStudentsResult = () => {
    //console.log("Merge")
    console.log("selectedOptionContext: ", selectOptionsContext);
    try {
      var variables;
      if (
          !selectOptions ||
        selectOptions.selectedAssessType == "" || 
        selectOptions.selectedAssessType === "All Result" ||
        selectOptions.selectedAssessType === "Self Assessment"
      ) {
        variables = {
          StudyGroupId: !selectOptions? selectOptionsContext.selectedStudyGroupId: selectOptions.selectedStudyGroupId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
          ResourceId: [resourceId],
          IncludeAssignmentRecord: false
            //selectOptions.selectedAssessType === "All Result" ? true : false,
        };

        fetchQuery(
          environment,
          QuestionViewAllStudentsResultQuery,
          variables
        ).then((data) => {
          console.log(data);

          const {
            resourceSearch: {
              Resources: [
                {
                  SelfLearnLastAttemptHistoryByStudyGroup,
                  SelfLearnFirstAttemptHistoryByStudyGroup,
                },
              ] = [{}],
            } = {},
          } = data || {};
          if (!selectOptions) {
            HandleResultWhichLastAttempt(
                selectOptionsContext.selectedReportType ===
                "SelfLearnLastAttemptHistoryByStudyGroup"
                    ? SelfLearnLastAttemptHistoryByStudyGroup
                    : SelfLearnFirstAttemptHistoryByStudyGroup
            );
          } else {
            HandleResultWhichLastAttempt(
                selectOptions.selectedReportType ===
                "SelfLearnLastAttemptHistoryByStudyGroup"
                    ? SelfLearnLastAttemptHistoryByStudyGroup
                    : SelfLearnFirstAttemptHistoryByStudyGroup
            );
          }

          // HandleResultWhichLastAttempt(
          //   selectOptions.selectedReportType ===
          //     "SelfLearnLastAttemptHistoryByStudyGroup"
          //     ? SelfLearnLastAttemptHistoryByStudyGroup
          //     : SelfLearnFirstAttemptHistoryByStudyGroup
          // );
        });
      } else {
        variables = {
          UserId: userData?.UserId,
          ResourceAssignId: selectOptions.selectedResAssignId,
        };
        console.log(selectOptions.selectedResAssignId);
        StudentAttemptyHistory(variables, true).then((data) => {
          console.log(data);
          const { resourceAssignment: { Resources } = {} } = data || {};

          const reportLastAttemptList = Resources.filter((data) => {
            return data.ResourceId === resourceId;
          });
          const {
            LearnosityAttemptLastHistory,
            LearnosityAttemptFirstHistory,
          } = reportLastAttemptList[0];
          console.log(LearnosityAttemptLastHistory);
          HandleResultWhichLastAttempt(
            selectOptions.selectedReportType === "LearnosityAttemptLastHistory"
              ? LearnosityAttemptLastHistory
              : LearnosityAttemptFirstHistory
          );
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const HandleResultWhichLastAttempt = (reportLastHistory) => {
    var userName;
    console.log(reportLastHistory);

    var studentsResult = [];
    if (reportLastHistory.length == 0) {
      setstudentsRecord([]);
      setstudentsResult([]);
      setstudentsResponsesloading(false);
      return;
    }

    const userdetails = reportLastHistory.map((obj) => {
      return obj.UserDetail;
    });
    console.log(userdetails);

    reportLastHistory.map((obj, i) => {
      const parsed = JSON.parse(obj.LearnositySessionRawResult);
      console.log(parsed);

      if (
        userdetails
          .map((aoj) => {
            return aoj.UserName;
          })
          .indexOf(parsed.user_id) !== -1
      ) {
        userName =
          userdetails[
            userdetails
              .map((aoj) => {
                return aoj.UserName;
              })
              .indexOf(parsed.user_id)
          ].UserDisplayName;
      }
      studentsResult.push({
        userId: parsed.user_id,
        userName: userName,
        items: parsed.items,
        responses: parsed.responses,
        session_id: parsed.session_id,
      });
    });

    setstudentsResult(studentsResult);
    console.log(studentsResult);
    getItemAppFromQuery(studentsResult);
  };
  // const getStudyGroupList = (id) => {
  //   const lang = localStorage.getItem("i18nextLng");
  //   try {
  //     var variables;
  //     if (isInappBrowser) {
  //       variables = {
  //         StudyGroupId: localStorage.getItem(
  //           "in-app-browser-data-study-group-id"
  //         ),
  //         SchoolYearId: localStorage.getItem("selectedSchoolYear"),
  //       };
  //     } else {
  //       variables = {
  //         StudyGroupId: id ? id : props.match.params.id,
  //         SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
  //           .SchoolYearId,
  //       };
  //     }
  //     fetchQuery(
  //       environment,
  //       QuestionViewGetStudyGroupListQuery,
  //       variables
  //     ).then((data) => {
  //       console.log(data);
  //       if (!data.studyGroup) {
  //         console.log("Faill to get Data : studyGroup null");
  //         return props.history.push("/m/myschool");
  //       }
  //       var studyGroupList = [];
  //       var preSelectectId = "";
  //       if (props.isInappBrowser) {
  //         preSelectectId = localStorage.getItem(
  //           "in-app-browser-data-study-group-id"
  //         );
  //       } else {
  //         preSelectectId = props.match.params.id;
  //       }
  //       studyGroupList.push({
  //         StudyGroupId: preSelectectId,
  //         StudyGroupName: data.studyGroup.StudyGroupName,
  //       });
  //       data.studyGroup.StudyGroupInSameLevel.map((obj, index) => {
  //         studyGroupList.push({
  //           StudyGroupId: obj.StudyGroupId,
  //           StudyGroupName: obj.StudyGroupName,
  //         });
  //       });
  //       studyGroupList.sort(function (a, b) {
  //         return a.StudyGroupName[
  //           a.StudyGroupName[0].Lang == lang ? 0 : 1
  //         ].StudyGroupName.localeCompare(
  //           b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
  //             .StudyGroupName
  //         );
  //       });
  //       console.log(studyGroupList);

  //       setselectedStudyGroupId(preSelectectId);
  //       setstudyGroupList(studyGroupList);
  //       setloading(false);
  //     });
  //     if (!standalone) {
  //       getAllStudentsResult();
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     window.alert("Error: " + e.message);
  //   }
  // };

  const getItemAppFromQuery = (studentsResult) => {
    let variables = {
      ResourceId: [resourceId],
      Lang: lang == "en-US" ? "EN" : "TC",
    };

    fetchQuery(environment, QuestionViewSingleQuestionQuery, variables).then(
      (data) => {
        config.mode == "dev" && console.log(data);
        if (data.resourceSearch.Resources.length == 0) {
          config.mode == "dev" && console.log("[Error] Resources Not find");
          return;
        }
        let object = JSON.parse(
          data.resourceSearch.Resources[0].LearnosityModelAnswer
        );
        //object.request.config.navigation = { scroll_to_top: false };

        setlearnosityObject(object);
        console.log(object);
        setLearnositySession_id(
          JSON.parse(data.resourceSearch.Resources[0].LearnosityModelAnswer)
            .request.session_id
        );

        config.mode == "dev" &&
          console.log(
            JSON.parse(data.resourceSearch.Resources[0].LearnosityModelAnswer)
          );
        startLearnosityInit(object, studentsResult);
      }
    );
  };
  const readyListener = () => {
    config.mode == "dev" &&
      console.log(
        "Learnosity Items API is ready",
        itemApp.questions(),
        Object.keys(itemApp.questions()).length,
        itemApp.items()
      );

    if (parseInt(props.match.params.questionId) - 1 == 0) {
      config.mode == "dev" && console.log("[no need items().goto()]");

      // settotalQuestionLength(Object.keys(itemApp.questions()).length);
      setinitReady(true);
      const userType = localStorage.getItem("role");
      if (!isInappBrowser) {
        window.document
          .getElementById("studygroup_back")
          ?.classList.remove("dim-button");
      }
      window.document
        .getElementById("assessment_tab")
        ?.classList.remove("dim-button");
      window.document
        .getElementById("result_tab")
        ?.classList.remove("dim-button");
      if (userType == "teacher") {
        window.document
          .getElementById("back_result")
          ?.classList.remove("dim-button");
        window.document
          .getElementById("answer_tab")
          ?.classList.remove("dim-button");
      }
      return;
    }
    config.mode == "dev" &&
      console.log("[items().goto()] :", props.match.params.questionId - 1);
    itemApp.items().goto(parseInt(props.match.params.questionId) - 1);

    // settotalQuestionLength(Object.keys(itemApp.questions()).length);
    setinitReady(true);

    const userType = localStorage.getItem("role");
    if (!isInappBrowser) {
      window.document
        .getElementById("studygroup_back")
        ?.classList.remove("dim-button");
    }
    window.document
      .getElementById("assessment_tab")
      ?.classList.remove("dim-button");
    window.document
      .getElementById("result_tab")
      ?.classList.remove("dim-button");
    if (userType == "teacher") {
      window.document
        .getElementById("back_result")
        ?.classList.remove("dim-button");
      window.document
        .getElementById("answer_tab")
        ?.classList.remove("dim-button");
    }
  };

  const errorListener = (e) => {
    // Adds a listener to all error codes.
    config.mode == "dev" && console.log("Error Code ", e.code);
    config.mode == "dev" && console.log("Error Message ", e.msg);
    config.mode == "dev" && console.log("Error Detail ", e.detail);
    if (window.location.pathname.indexOf("/result/q/") != -1) {
      // window.location.reload();
    }
    //window.location.reload();
    //this.startLearnosityInit(this.state.LearnosityObject)
  };

  const startLearnosityInit = (obj, studentsResult) => {
    var callbacks = {
      errorListener: errorListener,
      readyListener: readyListener,
    };
    // var that = this;
    try {
      console.log(obj);
      itemApp = window.LearnosityItems.init(obj, callbacks);

      console.log(itemApp);
      itemApp.on("item:changed", function (itemIndex) {
       // alert("coming")
        //alert(itemIndex)
        scroll.scrollToTop();
        console.log(itemIndex, itemApp.getCurrentItem());

        const itemData = itemApp.getCurrentItem();

        setquestionData(itemData);
        setselectedIndex(itemIndex);
        setquestNumber(itemIndex + 1);

        // that.handelStudentsResult();
        handelStudentsResult(studentsResult, itemData, itemIndex);
      });
    } catch (e) {
      console.log(e, "ERRRRRORRRR");
    }
  };
  console.log(questionData, selectedIndex, questNumber, totalQuestionLength);

  const questionOptions = () => {
    var questionsArray = [];
    console.log(totalQuestionLength);
    for (var i = 0; i < totalQuestionLength; i++) {
      // console.log(totalQuestionLength, i);
      // questionsArray.push({ name: "Q" + (i + 1), id: i });
      questionsArray.push({ name: "Item " + (i + 1), id: i });
    }
    return questionsArray;
  };

  // const handleSelectedStudyGroupChange = (e) => {
  //   console.log(e.target.value);
  //   setstudentsResponsesloading(true);
  //   setselectedStudyGroupId(e.target.value);

  //   getAllStudentsResult(e.target.value);
  // };

  const handelStudentsResult = (
    studentsResult,
    questionData,
    selectedIndex
  ) => {
    setstudentsResponsesloading(true);
    let studentsRecord = [];
    console.log(selectedIndex);
    console.log("QuestionData",studentsResult);
    console.log("QuestionData", questionData);

    // var questionType = []
    // questionData.questions.map((qobj, j) => {
    //   if(!questionType.includes(qobj.type)){
    //     questionType.push(qobj.type)
    //   }
    // })
    // var questionType = "Single";
    // if(questionType.length > 1){
    //   questionType = "Mixed";
    // }
  



    studentsResult.map((obj, i) => {
      //push user studentsRecord.push[{userId: studentsResult.user_id}]

      var answeredOptions = [];
      studentsRecord.push({
        userId: obj.userId,
        userName: obj.userName,
        session_id: obj.session_id,
        userResponse:""
      });

      if(questionData.questions.length > 1){
        obj.items.map((sobj, j) => {
          //find the current question
          studentsRecord[i].questionType = "Mixed";
          if (sobj.scoring.attempted == true) {
            if (sobj.scoring.max_score == sobj.scoring.score) {
              studentsRecord[i].isCorrect = "green";
              studentsRecord[i].userResponse = t("View student response");
            } else if (
              sobj.scoring.max_score !== sobj.scoring.score &&
              sobj.scoring.score !== 0
            ) {
              studentsRecord[i].isCorrect = "yellow";
              studentsRecord[i].userResponse = t("View student response");
            } else {
              studentsRecord[i].isCorrect = "red";
              studentsRecord[i].userResponse = t("View student response");
            }
          } else {
            studentsRecord[i].isCorrect = "grey";
            studentsRecord[i].userResponse =
              "- " + t("Not Submmited") + " -";
          }
            
          
        });
      }else {
        obj.items.map((sobj, j) => {
          //find the current question
          if (selectedIndex == j) {
            studentsRecord[i].questionType = obj.responses[j].question_type;
            switch (obj.responses[j].question_type) {
              case "mcq":
                if (sobj.scoring.attempted == true) {
                  if (sobj.scoring.max_score == sobj.scoring.score) {
                    studentsRecord[i].isCorrect = "green";
                    // questionData.questions[0].options.map((eobj) => {
                    //   if (obj.responses[j].response.value[0] == eobj.value) {
                    //     answeredOptions += eobj.label+",";
                    //     studentsRecord[i].userResponseN = answeredOptions
                    //     studentsRecord[i].userResponse = eobj.label;
                    //   }
                    // });
  
                  }else if (sobj.scoring.score != 0){
                    studentsRecord[i].isCorrect = "yellow";
                  } else {
                    studentsRecord[i].isCorrect = "red";
                    // questionData.questions[0].options.map((eobj) => {
                    //   if (obj.responses[j].response.value[0] == eobj.value) {
                    //     studentsRecord[i].userResponse = eobj.label;
                    //     answeredOptions += eobj.label+",";
                    //     studentsRecord[i].userResponseN = answeredOptions;
                    //   }
                    // });
                  }
                  questionData.questions[0].options.map((eobj) => {
                    //console.log("Test",eobj.label)
                    //console.log("answer",eobj.value);
                    //console.log("values",obj.responses[j].response.value )
                        if(obj.responses[j].response.value.indexOf(eobj.value) !== -1){
                         // console.log("True",eobj.label)
                          answeredOptions.push(eobj.label)
                        //answeredOptions += eobj.label+",";
                        
                        //studentsRecord[i].userResponse = eobj.label;
                      }
                    });
                    studentsRecord[i].userResponse = answeredOptions.join(',');
  
  
                } else {
                  studentsRecord[i].isCorrect = "grey";
                  studentsRecord[i].userResponse =
                    "- " + t("Not Submmited") + " -";
                }
                break;
  
              default:
                if (sobj.scoring.attempted == true) {
                  if (sobj.scoring.max_score == sobj.scoring.score) {
                    studentsRecord[i].isCorrect = "green";
                    studentsRecord[i].userResponse = t("View student response");
                  } else if (
                    sobj.scoring.max_score !== sobj.scoring.score &&
                    sobj.scoring.score !== 0
                  ) {
                    studentsRecord[i].isCorrect = "yellow";
                    studentsRecord[i].userResponse = t("View student response");
                  } else {
                    studentsRecord[i].isCorrect = "red";
                    studentsRecord[i].userResponse = t("View student response");
                  }
                } else {
                  studentsRecord[i].isCorrect = "grey";
                  studentsRecord[i].userResponse =
                    "- " + t("Not Submmited") + " -";
                }
                break;
            }
          }
        });
      }
      
      
    });
    console.log("Suresh",studentsRecord);
    setstudentsRecord(studentsRecord);
    setstudentsResponsesloading(false);
  };
  const mapCorrectColor = (color) => {
    switch (color) {
      case "yellow":
        return <div className="question_half_tick_arrow"></div>;
      case "red":
        return <div className="question_cross_tick_arrow"></div>;
      case "green":
        return <div className="question_tick_arrow"></div>;
      case "grey":
        return <div className="question_hyphen_arrow"></div>;
    }
  };

  const mapUserResponse = (obj) => {
    try {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    } catch (e) {
      console.log(e, "cant not get MathJax.Hub.Queue");
    }
    console.log(obj);

    switch (obj.questionType) {
      case "mcq":
        const checkisImg = obj.userResponse.indexOf("<img") !== -1;

        var userResponseDiv = document.createElement("div");
        userResponseDiv.innerHTML = obj.userResponse;

        if (obj.isCorrect == "red") {
          return (
            <div className={"responese red"}>
              {checkisImg == true
                ? parse(obj.userResponse)
                : userResponseDiv.innerText}
            </div>
          );
        } else if (obj.isCorrect == "grey") {
          return (
            <div className={"responese grey"}>
              {checkisImg == true
                ? parse(obj.userResponse)
                : userResponseDiv.innerText}
            </div>
          );
        } else {
          return (
            <div className={"responese none"}>
              {checkisImg == true
                ? parse(obj.userResponse)
                : userResponseDiv.innerText}
            </div>
          );
        }

      default:
        if (obj.isCorrect == "grey") {
          return <div className={"responese grey"}>{obj.userResponse}</div>;
        } else {
          return (
            <div
              className="responese clickDetail"
              onClick={() => {
                var redirctPath = "";
                if (isInappBrowser && !standalone) {
                  redirctPath = "/assessment=api/result/" + obj.userId;
                } else {
                  if (!standalone) {
                    redirctPath =
                      "/a/studygroup/" +
                      props.match.params.id +
                      "/assessment/" +
                      props.match.params.bookNunitId +
                      "/" +
                      props.match.params.resId +
                      "/result/" +
                      obj.userId;
                  } else {
                    redirctPath =
                      "/assignment/" +
                      props.match.params.resId +
                      "/result/" +
                      obj.userId;
                  }
                }
                props.history.push({
                  pathname: redirctPath,
                  state: {
                    session_id: obj.session_id,
                    userId: obj.userId,
                    questNumber:questNumber,
                    passData : selectOptions,
                    questionslength: questions
                  },
                });
              }}
            >
              {obj.userResponse}
              <i className="icon mdi mdi-arrow-right" />
            </div>
          );
        }
    }
  };
  return (
    <>
      <Element name="question"></Element>
      <div className="result-menu" style={{ marginTop: "0" }}>
        <NavLink
          id={"back_result"}
          exact
          to={props.location.pathname.replace(
            "/q/" + props.match.params.questionId,
            ""
          )}
        >
          {t("Result")}
        </NavLink>
        <div className="gap">
          <i className="icon mdi mdi-chevron-right"></i>
        </div>
        {/* <div className="active">{"Q" + questNumber}</div> */}
        <div className="active">{"Item " + questNumber}</div>
      </div>
      {!initReady && (
        <div className="learnosity_loading" style={{ display: "block" }}>
          <div className="splash-container">
            <div className="loader mx-auto" />
          </div>
        </div>
      )}
      <div className={"filter-row question-view"}>
        <div className="left">
          {initReady && (
            <>
              <button
                className="btn btn-secondary"
                type="button"
                disabled={selectedIndex == 0 ? true : false}
                onClick={() => {
                  var gqString = "Click previous question";
                  try {
                    // var userType = "";
                    // if (isInappBrowser) {
                    //   userType =
                    //     localStorage.getItem("role") == "teacher" ? "T" : "S";
                    // } else {
                    //   userType = JSON.parse(localStorage.getItem("userData"))
                    //     .UserType;
                    // }

                    if (!standalone) {
                      gaTrack(
                        "send",
                        "event",
                        "Assessment question performance",
                        gqString,
                        "Question:" +
                          (selectedIndex + 1) +
                          ", Assessment:" +
                          resourceData.ResourceLangs[0].ResourceName +
                          ", Resource ID:" +
                          resourceData.IngestionId +
                          ", Level:" +
                          (B2C
                            ? "NoData"
                            : bookData.Books[0].PearsonLevel.LevelName[0]
                                .LevelName) +
                          ", Book Series:" +
                          bookData.BookSeriesName[0].BookSeriesName +
                          ", Book:" +
                          bookData.Books[0].BookName[0].BookName +
                          ", Chapter:" +
                          (bookData.Books && bookData.Books.length > 0
                            ? bookData.Books[0].Units[0].UnitName[0].UnitName
                            : "NoData") +
                          ", Role:" +
                          userType
                      );
                    }
                  } catch (e) {
                    console.log(e, "Check answer GA Error");
                  }

                  itemApp.items().goto(parseInt(selectedIndex) - 1);
                }}
              >
                <i className="icon mdi mdi-caret-left"></i>
                {t("Previous Question")}
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                disabled={
                  parseInt(selectedIndex) + 1 == totalQuestionLength
                    ? true
                    : false
                }
                onClick={() => {
                  var gqString = "Click next question";
                  try {
                    // var userType = "";
                    // if (isInappBrowser) {
                    //   userType =
                    //     localStorage.getItem("role") == "teacher" ? "T" : "S";
                    // } else {
                    //   userType = JSON.parse(localStorage.getItem("userData"))
                    //     .UserType;
                    // }
                    if (!standalone) {
                      gaTrack(
                        "send",
                        "event",
                        "Assessment question performance",
                        gqString,
                        "Question:" +
                          (selectedIndex + 1) +
                          ", Assessment:" +
                          resourceData.ResourceLangs[0].ResourceName +
                          ", Resource ID:" +
                          resourceData.IngestionId +
                          ", Level:" +
                          (B2C
                            ? "NoData"
                            : bookData.Books[0].PearsonLevel.LevelName[0]
                                .LevelName) +
                          ", Book Series:" +
                          bookData.BookSeriesName[0].BookSeriesName +
                          ", Book:" +
                          bookData.Books[0].BookName[0].BookName +
                          ", Chapter:" +
                          (bookData.Books && bookData.Books.length > 0
                            ? bookData.Books[0].Units[0].UnitName[0].UnitName
                            : "NoData") +
                          ", Role:" +
                          userType
                      );
                    }
                  } catch (e) {
                    console.log(e, "Check answer GA Error");
                  }

                  itemApp.items().goto(parseInt(selectedIndex) + 1);
                }}
              >
                {t("Next Question")}{" "}
                <i className="icon mdi mdi-caret-right"></i>
              </button>
            </>
          )}
        </div>
        <div className="right">
          {initReady && (
            <>
              <select
                value={selectedIndex}
                onChange={(e) => {
                  console.log(e.target.value);
                  var gqString = "Jump to question";
                  try {
                    // var userType = "";
                    // if (isInappBrowser) {
                    //   userType =
                    //     localStorage.getItem("role") == "teacher" ? "T" : "S";
                    // } else {
                    //   userType = JSON.parse(localStorage.getItem("userData"))
                    //     .UserType;
                    // }
                    if (!standalone) {
                      // gaTrack(
                      //   "send",
                      //   "event",
                      //   "Assessment question performance",
                      //   gqString,
                      //   "Question:" +
                      //     (selectedIndex + 1) +
                      //     ", Assessment:" +
                      //     resourceData.ResourceLangs[0].ResourceName +
                      //     ", Resource ID:" +
                      //     resourceData.IngestionId +
                      //     ", Level:" +
                      //     (B2C
                      //       ? "NoData"
                      //       : bookData.Books[0].PearsonLevel.LevelName[0]
                      //           .LevelName) +
                      //     ", Book Series:" +
                      //     bookData.BookSeriesName[0].BookSeriesName +
                      //     ", Book:" +
                      //     bookData.Books[0].BookName[0].BookName +
                      //     ", Chapter:" +
                      //     (bookData.Books && bookData.Books.length > 0
                      //       ? bookData.Books[0].Units[0].UnitName[0].UnitName
                      //       : "NoData") +
                      //     ", Role:" +
                      //     userType
                      // );
                    }
                  } catch (e) {
                    console.log(e, "Check answer GA Error");
                  }

                  setselectedIndex(e.target.value);
                  itemApp.items().goto(parseInt(e.target.value));
                  console.log(itemApp.items());
                }}
              >
                {questionOptions().map((item, index) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {/* <select
                defaultValue={selectedStudyGroupId}
                onChange={(e) => handleSelectedStudyGroupChange(e)}
              >
                {studyGroupList.map((obj, index) => {
                  return (
                    <option key={index} value={obj.StudyGroupId}>
                      {
                        obj.StudyGroupName[
                          obj.StudyGroupName[0].Lang == lang ? 0 : 1
                        ].StudyGroupName
                      }
                    </option>
                  );
                })}
              </select> */}
            </>
          )}
        </div>
      </div>
      <div
        className="question-box"
        style={{
          display: `${initReady ? "block" : "none"}`,
          position: `${initReady ? "relative" : "absolute"}`,
        }}
      >
        <div id="learnosity_assess" style={{ margin: "0" }}></div>
      </div>

      {studentsResponsesloading ? (
        <Loading type="normal" />
      ) : (
        <div className="user-list">
          {initReady && (
            <table className="user-list-table">
              <thead>
                <tr>
                  <th>{t("Student")}</th>
                  <th className={"correct"} style={{ width: 85 }}>
                  {t("Result")}
                  </th>
                  <th>{t("Responese")}</th>
                </tr>
              </thead>

              {studentsRecord.map((obj, i) => (
                <tbody key={i}>
                  <tr>
                    <td
                      className="title"
                      onClick={() => {
                        var redirctPath = "";
                        // if (isInappBrowser && !standalone) {
                        //   redirctPath = "/assessment=api/result/" + obj.userId;
                        // } else {
                          if (!standalone) {
                            console.log("HERE is standalone");
                            redirctPath =
                              "/a/studygroup/" +
                              props.match.params.id +
                              "/assessment/" +
                              props.match.params.bookNunitId +
                              "/" +
                              props.match.params.resId +
                              "/result/" +
                              obj.userId;
                            console.log("redirctPath:", redirctPath);
                          } else {
                            console.log("HERE is not standalone");
                            redirctPath =
                              "/assignment/" +
                              props.match.params.resId +
                              "/result/" +
                              obj.userId;
                            console.log("redirctPath:", redirctPath);
                          }
                        // }
                        props.history.push({
                          pathname: redirctPath,
                          state: {
                            session_id: obj.session_id,
                            userId: obj.userId,
                            questNumber:questNumber,
                            passData : selectOptions,
                            questionslength: questions
                          },
                        });
                      }}
                    >
                      {
                        obj.userName[obj.userName[0].Lang == lang ? 0 : 1]
                          .UserName
                      }
                    </td>
                    <td className="questions_icon_center">{mapCorrectColor(obj.isCorrect)}</td>
                    <td>{mapUserResponse(obj)}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          )}
        </div>
      )}
    </>
  );
};

// class index extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       questNumber: null,
//       // fakeArrayData:
//       //   this.props.match.params.questionId % 2 == 0
//       //     ? [true, true, false, true, false]
//       //     : [false, false, false, true, false],
//       loading: true,
//       initReady: false,
//       selectedStudyGroupId: null,
//       studentsResponsesloading: false,
//     };
//   }

//   componentDidMount() {
//     //ga

//     const userType = localStorage.getItem("role");
//     if (!this.props.isInappBrowser) {
//       window.document
//         .getElementById("studygroup_back")
//         ?.classList.add("dim-button");
//     }
//     window.document
//       .getElementById("assessment_tab")
//       ?.classList.add("dim-button");
//     window.document.getElementById("result_tab")?.classList.add("dim-button");
//     if (userType == "teacher") {
//       window.document.getElementById("answer_tab")?.classList.add("dim-button");
//       window.document
//         .getElementById("back_result")
//         ?.classList.add("dim-button");
//     }
//     var urlParams = this.props.match.params.questionId;
//     config.mode == "dev" && console.log(urlParams, "Q ID!");
//     this.setState({
//       questNumber: urlParams,
//     });
//     if (this.props.isInappBrowser) {
//       this.getStudyGroupList(
//         localStorage.getItem("in-app-browser-data-study-group-id")
//       );
//     } else {
//       this.getStudyGroupList(this.props.match.params.id);
//     }
//   }

//   getAllStudentsResult = (id) => {
//     try {
//       var variables;
//       if (this.props.isInappBrowser) {
//         variables = {
//           StudyGroupId: localStorage.getItem(
//             "in-app-browser-data-study-group-id"
//           ),
//           SchoolYearId: localStorage.getItem("selectedSchoolYear"),
//           ResourceId: [localStorage.getItem("in-app-browser-data-resourceid")],
//         };
//       } else {
//         variables = {
//           StudyGroupId: id ? id : this.props.match.params.id,
//           SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
//             .SchoolYearId,
//           ResourceId: [this.props.match.params.resId],
//         };
//       }
//       fetchQuery(
//         environment,
//         QuestionViewAllStudentsResultQuery,
//         variables
//       ).then((data) => {
//         console.log(data);

//         const {
//           resourceSearch: {
//             Resources: [{ SelfLearnLastAttemptHistoryByStudyGroup }] = [{}],
//           } = {},
//         } = data || {};

//         var userName;
//         console.log(SelfLearnLastAttemptHistoryByStudyGroup);

//         var studentsResult = [];
//         if (SelfLearnLastAttemptHistoryByStudyGroup.length == 0) {
//           return this.setState({
//             studentsResponsesloading: false,
//             studentsRecord: [],
//             studentsResult: [],
//           });
//         }

//         const userdetails = SelfLearnLastAttemptHistoryByStudyGroup.map(
//           (obj) => {
//             return obj.UserDetail;
//           }
//         );
//         console.log(userdetails);

//         SelfLearnLastAttemptHistoryByStudyGroup.map((obj, i) => {
//           const parsed = JSON.parse(obj.LearnositySessionRawResult);
//           console.log(parsed);

//           if (
//             userdetails
//               .map((aoj) => {
//                 return aoj.UserName;
//               })
//               .indexOf(parsed.user_id) !== -1
//           ) {
//             userName =
//               userdetails[
//                 userdetails
//                   .map((aoj) => {
//                     return aoj.UserName;
//                   })
//                   .indexOf(parsed.user_id)
//               ].UserDisplayName;
//           }
//           studentsResult.push({
//             userId: parsed.user_id,
//             userName: userName,
//             items: parsed.items,
//             responses: parsed.responses,
//             session_id: parsed.session_id,
//           });
//         });

//         this.setState({
//           studentsResult: studentsResult,
//         });
//         console.log(studentsResult);
//         this.getItemAppFromQuery();
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   getStudyGroupList = (id) => {
//     // const { studyGroupId , standalone } = this.props;
//     const lang = localStorage.getItem("i18nextLng");
//     try {
//       var variables;
//       if (this.props.isInappBrowser) {
//         variables = {
//           StudyGroupId: localStorage.getItem(
//             "in-app-browser-data-study-group-id"
//           ),
//           SchoolYearId: localStorage.getItem("selectedSchoolYear"),
//         };
//       } else {
//         variables = {
//           StudyGroupId: id ? id : this.props.match.params.id,
//           SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
//             .SchoolYearId,
//         };
//       }
//       fetchQuery(
//         environment,
//         QuestionViewGetStudyGroupListQuery,
//         variables
//       ).then((data) => {
//         console.log(data);
//         if (!data.studyGroup) {
//           console.log("Faill to get Data : studyGroup null");
//           return this.props.history.push("/m/myschool");
//         }
//         var studyGroupList = [];
//         var preSelectectId = "";
//         if (this.props.isInappBrowser) {
//           preSelectectId = localStorage.getItem(
//             "in-app-browser-data-study-group-id"
//           );
//         } else {
//           preSelectectId = this.props.match.params.id;
//         }
//         studyGroupList.push({
//           StudyGroupId: preSelectectId,
//           StudyGroupName: data.studyGroup.StudyGroupName,
//         });
//         data.studyGroup.StudyGroupInSameLevel.map((obj, index) => {
//           studyGroupList.push({
//             StudyGroupId: obj.StudyGroupId,
//             StudyGroupName: obj.StudyGroupName,
//           });
//         });
//         studyGroupList.sort(function (a, b) {
//           return a.StudyGroupName[
//             a.StudyGroupName[0].Lang == lang ? 0 : 1
//           ].StudyGroupName.localeCompare(
//             b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
//               .StudyGroupName
//           );
//         });
//         console.log(studyGroupList);
//         this.setState({
//           studyGroupList: studyGroupList,
//           selectedStudyGroupId: preSelectectId,
//           loading: false,
//         });
//       });
//       this.getAllStudentsResult();
//     } catch (e) {
//       console.log(e);
//       window.alert("Error: " + e.message);
//     }
//   };

//   getItemAppFromQuery = () => {
//     var lang;
//     var variables;
//     if (!this.props.isInappBrowser) {
//       lang = localStorage.getItem("i18nextLng") == "en-US" ? "EN" : "TC";
//       variables = {
//         ResourceId: [this.props.match.params.resId],
//         Lang: lang,
//       };
//     } else {
//       lang = localStorage.getItem("i18nextLng-inapp") == "en-US" ? "EN" : "TC";
//       variables = {
//         ResourceId: [localStorage.getItem("in-app-browser-data-resourceid")],
//         Lang: lang,
//       };
//     }

//     fetchQuery(environment, QuestionViewSingleQuestionQuery, variables).then(
//       (data) => {
//         config.mode == "dev" && console.log(data);
//         if (data.resourceSearch.Resources.length == 0) {
//           config.mode == "dev" && console.log("[Error] Resources Not find");
//           return;
//         }
//         var object = JSON.parse(
//           data.resourceSearch.Resources[0].LearnosityModelAnswer
//         );
//         //object.request.config.navigation = { scroll_to_top: false };
//         this.setState({
//           LearnosityObject: object,
//           LearnositySession_id: JSON.parse(
//             data.resourceSearch.Resources[0].LearnosityModelAnswer
//           ).request.session_id,
//           loading: false,
//         });
//         config.mode == "dev" &&
//           console.log(
//             JSON.parse(data.resourceSearch.Resources[0].LearnosityModelAnswer)
//           );

//         this.startLearnosityInit();
//       }
//     );
//   };

//   componentWillUnmount() {
//     window.LearnosityItems.reset();
//   }

//   readyListener = () => {
//     config.mode == "dev" &&
//       console.log(
//         "Learnosity Items API is ready",
//         itemApp.questions(),
//         Object.keys(itemApp.questions()).length,
//         itemApp.items()
//       );

//     if (parseInt(this.props.match.params.questionId) - 1 == 0) {
//       config.mode == "dev" && console.log("[no need items().goto()]");
//       this.setState({
//         totalQuestionLength: Object.keys(itemApp.questions()).length,
//         initReady: true,
//       });
//       const userType = localStorage.getItem("role");
//       if (!this.props.isInappBrowser) {
//         window.document
//           .getElementById("studygroup_back")
//           ?.classList.remove("dim-button");
//       }
//       window.document
//         .getElementById("assessment_tab")
//         ?.classList.remove("dim-button");
//       window.document
//         .getElementById("result_tab")
//         ?.classList.remove("dim-button");
//       if (userType == "teacher") {
//         window.document
//           .getElementById("back_result")
//           ?.classList.remove("dim-button");
//         window.document
//           .getElementById("answer_tab")
//           ?.classList.remove("dim-button");
//       }
//       return;
//     }
//     config.mode == "dev" &&
//       console.log("[items().goto()] :", this.props.match.params.questionId - 1);
//     itemApp.items().goto(parseInt(this.props.match.params.questionId) - 1);
//     this.setState({
//       totalQuestionLength: Object.keys(itemApp.questions()).length,
//       initReady: true,
//     });
//     const userType = localStorage.getItem("role");
//     if (!this.props.isInappBrowser) {
//       window.document
//         .getElementById("studygroup_back")
//         ?.classList.remove("dim-button");
//     }
//     window.document
//       .getElementById("assessment_tab")
//       ?.classList.remove("dim-button");
//     window.document
//       .getElementById("result_tab")
//       ?.classList.remove("dim-button");
//     if (userType == "teacher") {
//       window.document
//         .getElementById("back_result")
//         ?.classList.remove("dim-button");
//       window.document
//         .getElementById("answer_tab")
//         ?.classList.remove("dim-button");
//     }
//   };

//   errorListener = (e) => {
//     // Adds a listener to all error codes.
//     config.mode == "dev" && console.log("Error Code ", e.code);
//     config.mode == "dev" && console.log("Error Message ", e.msg);
//     config.mode == "dev" && console.log("Error Detail ", e.detail);
//     if (window.location.pathname.indexOf("/result/q/") != -1) {
//       window.location.reload();
//     }
//     //window.location.reload();
//     //this.startLearnosityInit(this.state.LearnosityObject)
//   };

//   startLearnosityInit = () => {
//     var callbacks = {
//       errorListener: this.errorListener,
//       readyListener: this.readyListener,
//     };
//     var that = this;
//     try {
//       itemApp = window.LearnosityItems.init(
//         this.state.LearnosityObject,
//         callbacks
//       );

//       itemApp.on("item:changed", function (itemIndex) {
//         scroll.scrollToTop();
//         console.log(itemIndex, itemApp.getCurrentItem());

//         const itemData = itemApp.getCurrentItem();

//         that.setState({
//           questionData: itemData,
//           selectedIndex: itemIndex,
//           questNumber: itemIndex + 1,
//         });

//         //window.scrollTo(0, 0), 100);
//         /*
//         if (that.props.match.params.questionId != itemIndex+1) {
//           that.props.history.replace(
//             that.props.location.pathname.replace(
//               that.props.match.params.questionId,
//               ""
//             ) + (parseInt(itemIndex)+1)
//           );
//         }*/
//         that.handelStudentsResult();
//       });
//     } catch (e) {
//       console.log(e, "ERRRRRORRRR");
//     }
//   };

//   questionOptions = () => {
//     const { totalQuestionLength } = this.state;
//     var questionsArray = [];
//     for (var i = 0; i < totalQuestionLength; i++) {
//       // console.log(totalQuestionLength, i);
//       questionsArray.push({ name: "Q" + (i + 1), id: i });
//     }
//     return questionsArray;
//   };

//   handleSelectedStudyGroupChange = (e) => {
//     console.log(e.target.value);

//     this.setState({
//       selectedStudyGroupId: e.target.value,
//       studentsResponsesloading: true,
//     });
//     this.getAllStudentsResult(e.target.value);
//   };

//   handelStudentsResult = () => {
//     const { questionData, selectedIndex, studentsResult } = this.state;
//     this.setState({ studentsResponsesloading: true });
//     const { t } = this.props;
//     var studentsRecord = [];

//     console.log(studentsResult);
//     console.log(questionData);
//     studentsResult.map((obj, i) => {
//       //push user studentsRecord.push[{userId: studentsResult.user_id}]
//       studentsRecord.push({
//         userId: obj.userId,
//         userName: obj.userName,
//         session_id: obj.session_id,
//       });

//       obj.items.map((sobj, j) => {
//         //find the current question
//         if (selectedIndex == j) {
//           studentsRecord[i].questionType = obj.responses[j].question_type;
//           switch (obj.responses[j].question_type) {
//             case "mcq":
//               if (sobj.scoring.attempted == true) {
//                 if (sobj.scoring.max_score == sobj.scoring.score) {
//                   studentsRecord[i].isCorrect = "green";
//                   questionData.questions[0].options.map((eobj) => {
//                     if (obj.responses[j].response.value[0] == eobj.value) {
//                       studentsRecord[i].userResponse = eobj.label;
//                     }
//                   });
//                 } else {
//                   studentsRecord[i].isCorrect = "red";
//                   questionData.questions[0].options.map((eobj) => {
//                     if (obj.responses[j].response.value[0] == eobj.value) {
//                       studentsRecord[i].userResponse = eobj.label;
//                     }
//                   });
//                 }
//               } else {
//                 studentsRecord[i].isCorrect = "grey";
//                 studentsRecord[i].userResponse =
//                   "- " + t("Not Submmited") + " -";
//               }
//               break;

//             // case "clozeformula":
//             //   if (sobj.scoring.attempted == true) {
//             //     if (sobj.scoring.max_score == sobj.scoring.score) {
//             //       studentsRecord[i].isCorrect = "green";
//             //       if (
//             //         sobj.response_ids
//             //           .map((obj1) => {
//             //             return obj1;
//             //           })
//             //           .indexOf(obj.responses[j].response_id) !== -1
//             //       ) {
//             //         studentsRecord[i].userResponse = obj.responses[
//             //           j
//             //         ].response.value.map((val) => {
//             //           return val;
//             //         });
//             //       }
//             //     } else if (
//             //       sobj.scoring.max_score !== sobj.scoring.score &&
//             //       sobj.scoring.score !== 0
//             //     ) {
//             //       studentsRecord[i].isCorrect = "yellow";
//             //       studentsRecord[i].userResponse = t("View student response");
//             //       questionData.questions[0].validation.valid_response.value.map(
//             //         (vobj, vi) => {
//             //           if (vi == 0) studentsRecord[i].userResponse = [];
//             //           if (
//             //             obj.responses[j].response.value[vi] == vobj[0].value
//             //           ) {
//             //             studentsRecord[i].userResponse.push({
//             //               ans: obj.responses[j].response.value[vi],
//             //               isCorrected: true,
//             //             });
//             //           } else if (
//             //             obj.responses[j].response.value[vi] !== vobj[0].value
//             //           ) {
//             //             studentsRecord[i].userResponse.push({
//             //               ans: obj.responses[j].response.value[vi],
//             //               isCorrected: false,
//             //             });
//             //           }
//             //         }
//             //       );
//             //     } else {
//             //       studentsRecord[i].isCorrect = "red";
//             //       studentsRecord[i].userResponse = t("View student response");
//             //     }
//             //   } else {
//             //     studentsRecord[i].isCorrect = "grey";
//             //     studentsRecord[i].userResponse =
//             //       "- " + t("Not Submmited") + " -";
//             //   }
//             //   break;
//             default:
//               if (sobj.scoring.attempted == true) {
//                 if (sobj.scoring.max_score == sobj.scoring.score) {
//                   studentsRecord[i].isCorrect = "green";
//                   studentsRecord[i].userResponse = t("View student response");
//                 } else if (
//                   sobj.scoring.max_score !== sobj.scoring.score &&
//                   sobj.scoring.score !== 0
//                 ) {
//                   studentsRecord[i].isCorrect = "yellow";
//                   studentsRecord[i].userResponse = t("View student response");
//                 } else {
//                   studentsRecord[i].isCorrect = "red";
//                   studentsRecord[i].userResponse = t("View student response");
//                 }
//               } else {
//                 studentsRecord[i].isCorrect = "grey";
//                 studentsRecord[i].userResponse =
//                   "- " + t("Not Submmited") + " -";
//               }
//               break;
//           }
//         }
//       });
//     });
//     console.log(studentsRecord);
//     this.setState({
//       studentsRecord: studentsRecord,
//       studentsResponsesloading: false,
//     });
//   };

//   mapCorrectColor = (color) => {
//     switch (color) {
//       case "yellow":
//         return <div className="score-mark yellow"></div>;
//       case "red":
//         return <div className="score-mark red"></div>;
//       case "green":
//         return <div className="score-mark green"></div>;
//       case "grey":
//         return <div className="score-mark grey"></div>;
//     }
//   };

//   mapUserResponse = (obj) => {
//     const { t } = this.props;
//     try {
//       MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
//     } catch (e) {
//       console.log(e, "cant not get MathJax.Hub.Queue");
//     }
//     console.log(obj);

//     switch (obj.questionType) {
//       case "mcq":
//         const checkisImg = obj.userResponse.indexOf("<img") !== -1;

//         var userResponseDiv = document.createElement("div");
//         userResponseDiv.innerHTML = obj.userResponse;

//         if (obj.isCorrect == "red") {
//           return (
//             <div className={"responese red"}>
//               {checkisImg == true
//                 ? parse(obj.userResponse)
//                 : userResponseDiv.innerText}
//             </div>
//           );
//         } else if (obj.isCorrect == "grey") {
//           return (
//             <div className={"responese grey"}>
//               {checkisImg == true
//                 ? parse(obj.userResponse)
//                 : userResponseDiv.innerText}
//             </div>
//           );
//         } else {
//           return (
//             <div className={"responese none"}>
//               {checkisImg == true
//                 ? parse(obj.userResponse)
//                 : userResponseDiv.innerText}
//             </div>
//           );
//         }

//       // case "clozeformula":
//       //   if (obj.isCorrect == "grey") {
//       //     return <div className="responese grey">{obj.userResponse}</div>;
//       //   } else if (obj.isCorrect == "green") {
//       //     console.log(obj.userResponse);
//       //     if (!obj.userResponse) {
//       //       return (
//       //         <div
//       //           className="responese clickDetail"
//       //           onClick={() => {
//       //             var redirctPath = "";
//       //             if (this.props.isInappBrowser) {
//       //               redirctPath = "/assessment=api/result/" + obj.userId;
//       //             } else {
//       //               redirctPath =
//       //                 "/a/studygroup/" +
//       //                 this.props.match.params.id +
//       //                 "/assessment/" +
//       //                 this.props.match.params.bookNunitId +
//       //                 "/" +
//       //                 this.props.match.params.resId +
//       //                 "/result/" +
//       //                 obj.userId;
//       //             }
//       //             this.props.history.push({
//       //               pathname: redirctPath,
//       //               state: { session_id: obj.session_id },
//       //             });
//       //           }}
//       //         >
//       //           {t("View student response")}
//       //           <i className="icon mdi mdi-arrow-right" />
//       //         </div>
//       //       );
//       //     } else {
//       //       return (
//       //         <div className={"responese none "}>
//       //           {obj.userResponse &&
//       //             obj.userResponse.map((sobj, i) => {
//       //               return (
//       //                 <div key={i} className="responese none">
//       //                   {sobj}
//       //                 </div>
//       //               );
//       //             })}
//       //         </div>
//       //       );
//       //     }
//       //   } else if (obj.isCorrect == "red") {
//       //     return (
//       //       <div
//       //         className="responese clickDetail"
//       //         onClick={() => {
//       //           var redirctPath = "";
//       //           if (this.props.isInappBrowser) {
//       //             redirctPath = "/assessment=api/result/" + obj.userId;
//       //           } else {
//       //             redirctPath =
//       //               "/a/studygroup/" +
//       //               this.props.match.params.id +
//       //               "/assessment/" +
//       //               this.props.match.params.bookNunitId +
//       //               "/" +
//       //               this.props.match.params.resId +
//       //               "/result/" +
//       //               obj.userId;
//       //           }
//       //           this.props.history.push({
//       //             pathname: redirctPath,
//       //             state: { session_id: obj.session_id },
//       //           });
//       //         }}
//       //       >
//       //         {t("View student response")}
//       //         <i className="icon mdi mdi-arrow-right" />
//       //       </div>
//       //     );
//       //   } else {
//       //     if (!obj.userResponse) {
//       //       return (
//       //         <div
//       //           className="responese clickDetail"
//       //           onClick={() => {
//       //             var redirctPath = "";
//       //             if (this.props.isInappBrowser) {
//       //               redirctPath = "/assessment=api/result/" + obj.userId;
//       //             } else {
//       //               redirctPath =
//       //                 "/a/studygroup/" +
//       //                 this.props.match.params.id +
//       //                 "/assessment/" +
//       //                 this.props.match.params.bookNunitId +
//       //                 "/" +
//       //                 this.props.match.params.resId +
//       //                 "/result/" +
//       //                 obj.userId;
//       //             }
//       //             this.props.history.push({
//       //               pathname: redirctPath,
//       //               state: { session_id: obj.session_id },
//       //             });
//       //           }}
//       //         >
//       //           {t("View student response")}
//       //           <i className="icon mdi mdi-arrow-right" />
//       //         </div>
//       //       );
//       //     } else {
//       //       return (
//       //         <div className="responese">
//       //           {obj.userResponse &&
//       //             obj.userResponse.map((seobj, i) => {
//       //               if (seobj.isCorrected == true) {
//       //                 return (
//       //                   <div key={i} className="responese none">
//       //                     {seobj.ans}
//       //                   </div>
//       //                 );
//       //               }
//       //               return (
//       //                 <div key={i} className="responese red">
//       //                   {seobj.ans}
//       //                 </div>
//       //               );
//       //             })}
//       //         </div>
//       //       );
//       //     }
//       //   }

//       default:
//         if (obj.isCorrect == "grey") {
//           return <div className={"responese grey"}>{obj.userResponse}</div>;
//         } else {
//           return (
//             <div
//               className="responese clickDetail"
//               onClick={() => {
//                 var redirctPath = "";
//                 if (this.props.isInappBrowser) {
//                   redirctPath = "/assessment=api/result/" + obj.userId;
//                 } else {
//                   redirctPath =
//                     "/a/studygroup/" +
//                     this.props.match.params.id +
//                     "/assessment/" +
//                     this.props.match.params.bookNunitId +
//                     "/" +
//                     this.props.match.params.resId +
//                     "/result/" +
//                     obj.userId;
//                 }
//                 this.props.history.push({
//                   pathname: redirctPath,
//                   state: { session_id: obj.session_id },
//                 });
//               }}
//             >
//               {obj.userResponse}
//               <i className="icon mdi mdi-arrow-right" />
//             </div>
//           );
//         }
//     }
//   };

//   render() {
//     const {
//       questNumber,
//       selectedIndex,
//       totalQuestionLength,
//       initReady,
//       questionData,
//       studyGroupList,
//       selectedStudyGroupId,
//       studentsResponsesloading,
//       studentsRecord,
//     } = this.state;
//     console.log(studentsRecord);
//     const { t } = this.props;
//     console.log(questionData, this.props);
//     const lang = localStorage.getItem("i18nextLng");
//     return (
//       <>
//         <Element name="question"></Element>
//         <div className="result-menu" style={{ marginTop: "0" }}>
//           <NavLink
//             id={"back_result"}
//             exact
//             to={this.props.location.pathname.replace(
//               "/q/" + this.props.match.params.questionId,
//               ""
//             )}
//           >
//             {t("Result")}
//           </NavLink>
//           <div className="gap">
//             <i className="icon mdi mdi-chevron-right"></i>
//           </div>
//           <div className="active">{"Q" + questNumber}</div>
//         </div>
//         {!initReady && (
//           <div className="learnosity_loading" style={{ display: "block" }}>
//             <div className="splash-container">
//               <div className="loader mx-auto" />
//             </div>
//           </div>
//         )}
//         <div className={"filter-row question-view"}>
//           <div className="left">
//             {initReady && (
//               <>
//                 <button
//                   className="btn btn-secondary"
//                   type="button"
//                   disabled={selectedIndex == 0 ? true : false}
//                   onClick={() => {
//                     var gqString = "Click previous question";
//                     try {
//                       const {
//                         resourceData,
//                         studyGroupData,
//                         resourceIdGa,
//                         resourceIngestionIdGa,
//                         standalone,
//                       } = this.props;
//                       var userType = "";
//                       if (this.props.isInappBrowser) {
//                         userType =
//                           localStorage.getItem("role") == "teacher" ? "T" : "S";
//                       } else {
//                         userType = JSON.parse(localStorage.getItem("userData"))
//                           .UserType;
//                       }

//                       if (!standalone) {
//                         var book =
//                             studyGroupData.BookSeries.Books &&
//                             studyGroupData.BookSeries.Books.length > 0
//                               ? studyGroupData.BookSeries.Books[0].BookName[0]
//                                   .BookName
//                               : "NoData",
//                           chapter =
//                             studyGroupData.BookSeries.Books &&
//                             studyGroupData.BookSeries.Books.length > 0
//                               ? studyGroupData.BookSeries.Books[0].Units[0]
//                                   .UnitName[0].UnitName
//                               : "NoData";

//                         gaTrack(
//                           "send",
//                           "event",
//                           "Assessment question performance",
//                           gqString,
//                           "Question:" +
//                             (selectedIndex + 1) +
//                             ", Assessment:" +
//                             resourceData[0].ResourceName +
//                             ", Resource ID:" +
//                             resourceIngestionIdGa +
//                             ", Level:" +
//                             studyGroupData.Level.LevelName[0].LevelName +
//                             ", Book Series:" +
//                             studyGroupData.BookSeries.BookSeriesName[0]
//                               .BookSeriesName +
//                             ", Book:" +
//                             book +
//                             ", Chapter:" +
//                             chapter +
//                             ", Role:" +
//                             userType
//                         );
//                       }
//                     } catch (e) {
//                       console.log(e, "Check answer GA Error");
//                     }
//                     itemApp.items().goto(parseInt(selectedIndex) - 1);
//                   }}
//                 >
//                   <i className="icon mdi mdi-caret-left"></i>
//                   {t("Previous Question")}
//                 </button>
//                 <button
//                   className="btn btn-secondary"
//                   type="button"
//                   disabled={
//                     parseInt(selectedIndex) + 1 == totalQuestionLength
//                       ? true
//                       : false
//                   }
//                   onClick={() => {
//                     var gqString = "Click next question";
//                     try {
//                       const {
//                         resourceData,
//                         studyGroupData,
//                         resourceIdGa,
//                         resourceIngestionIdGa,
//                         standalone,
//                       } = this.props;
//                       var userType = "";
//                       if (this.props.isInappBrowser) {
//                         userType =
//                           localStorage.getItem("role") == "teacher" ? "T" : "S";
//                       } else {
//                         userType = JSON.parse(localStorage.getItem("userData"))
//                           .UserType;
//                       }
//                       if (!standalone) {
//                         var book =
//                             studyGroupData.BookSeries.Books &&
//                             studyGroupData.BookSeries.Books.length > 0
//                               ? studyGroupData.BookSeries.Books[0].BookName[0]
//                                   .BookName
//                               : "NoData",
//                           chapter =
//                             studyGroupData.BookSeries.Books &&
//                             studyGroupData.BookSeries.Books.length > 0
//                               ? studyGroupData.BookSeries.Books[0].Units[0]
//                                   .UnitName[0].UnitName
//                               : "NoData";
//                         gaTrack(
//                           "send",
//                           "event",
//                           "Assessment question performance",
//                           gqString,
//                           "Question:" +
//                             (selectedIndex + 1) +
//                             ", Assessment:" +
//                             resourceData[0].ResourceName +
//                             ", Resource ID:" +
//                             resourceIngestionIdGa +
//                             ", Level:" +
//                             studyGroupData.Level.LevelName[0].LevelName +
//                             ", Book Series:" +
//                             studyGroupData.BookSeries.BookSeriesName[0]
//                               .BookSeriesName +
//                             ", Book:" +
//                             book +
//                             ", Chapter:" +
//                             chapter +
//                             ", Role:" +
//                             userType
//                         );
//                       }
//                     } catch (e) {
//                       console.log(e, "Check answer GA Error");
//                     }
//                     itemApp.items().goto(parseInt(selectedIndex) + 1);
//                   }}
//                 >
//                   {t("Next Question")}{" "}
//                   <i className="icon mdi mdi-caret-right"></i>
//                 </button>
//               </>
//             )}
//           </div>
//           <div className="right">
//             {initReady && (
//               <>
//                 <select
//                   value={selectedIndex}
//                   onChange={(e) => {
//                     console.log(e.target.value);
//                     var gqString = "Jump to question";
//                     try {
//                       const {
//                         resourceData,
//                         studyGroupData,
//                         resourceIngestionIdGa,
//                       } = this.props;
//                       var userType = "";
//                       if (this.props.isInappBrowser) {
//                         userType =
//                           localStorage.getItem("role") == "teacher" ? "T" : "S";
//                       } else {
//                         userType = JSON.parse(localStorage.getItem("userData"))
//                           .UserType;
//                       }
//                       if (!standalone) {
//                         var book =
//                             studyGroupData.BookSeries.Books &&
//                             studyGroupData.BookSeries.Books.length > 0
//                               ? studyGroupData.BookSeries.Books[0].BookName[0]
//                                   .BookName
//                               : "NoData",
//                           chapter =
//                             studyGroupData.BookSeries.Books &&
//                             studyGroupData.BookSeries.Books.length > 0
//                               ? studyGroupData.BookSeries.Books[0].Units[0]
//                                   .UnitName[0].UnitName
//                               : "NoData";
//                         gaTrack(
//                           "send",
//                           "event",
//                           "Assessment question performance",
//                           gqString,
//                           "Question:" +
//                             (selectedIndex + 1) +
//                             ", Assessment:" +
//                             resourceData[0].ResourceName +
//                             ", Resource ID:" +
//                             resourceIngestionIdGa +
//                             ", Level:" +
//                             studyGroupData.Level.LevelName[0].LevelName +
//                             ", Book Series:" +
//                             studyGroupData.BookSeries.BookSeriesName[0]
//                               .BookSeriesName +
//                             ", Book:" +
//                             book +
//                             ", Chapter:" +
//                             chapter +
//                             ", Role:" +
//                             userType
//                         );
//                       }
//                     } catch (e) {
//                       console.log(e, "Check answer GA Error");
//                     }
//                     this.setState({
//                       selectedIndex: e.target.value,
//                     });
//                     itemApp.items().goto(parseInt(e.target.value));
//                   }}
//                 >
//                   {this.questionOptions().map((item, index) => {
//                     return (
//                       <option key={item.id} value={item.id}>
//                         {item.name}
//                       </option>
//                     );
//                   })}
//                 </select>
//                 <select
//                   defaultValue={selectedStudyGroupId}
//                   onChange={(e) => this.handleSelectedStudyGroupChange(e)}
//                 >
//                   {studyGroupList.map((obj, index) => {
//                     return (
//                       <option key={index} value={obj.StudyGroupId}>
//                         {
//                           obj.StudyGroupName[
//                             obj.StudyGroupName[0].Lang == lang ? 0 : 1
//                           ].StudyGroupName
//                         }
//                       </option>
//                     );
//                   })}
//                 </select>
//               </>
//             )}
//           </div>
//         </div>
//         <div
//           className="question-box"
//           style={{
//             display: `${initReady ? "block" : "none"}`,
//             position: `${initReady ? "relative" : "absolute"}`,
//           }}
//         >
//           <div id="learnosity_assess" style={{ margin: "0" }}></div>
//         </div>

//         {studentsResponsesloading ? (
//           <Loading type="normal" />
//         ) : (
//           <div className="user-list">
//             {initReady && (
//               <table className="user-list-table">
//                 <thead>
//                   <tr>
//                     <th>{t("Student")}</th>
//                     <th className={"correct"} style={{ width: 85 }}>
//                       {t("Correct")}
//                     </th>
//                     <th>{t("Responese")}</th>
//                   </tr>
//                 </thead>

//                 {studentsRecord.map((obj, i) => (
//                   <tbody key={i}>
//                     <tr>
//                       <td
//                         className="title"
//                         onClick={() => {
//                           var redirctPath = "";
//                           if (this.props.isInappBrowser) {
//                             redirctPath =
//                               "/assessment=api/result/" + obj.userId;
//                           } else {
//                             redirctPath =
//                               "/a/studygroup/" +
//                               this.props.match.params.id +
//                               "/assessment/" +
//                               this.props.match.params.bookNunitId +
//                               "/" +
//                               this.props.match.params.resId +
//                               "/result/" +
//                               obj.userId;
//                           }
//                           this.props.history.push({
//                             pathname: redirctPath,
//                             state: { session_id: obj.session_id },
//                           });
//                         }}
//                       >
//                         {
//                           obj.userName[obj.userName[0].Lang == lang ? 0 : 1]
//                             .UserName
//                         }
//                       </td>
//                       <td>{this.mapCorrectColor(obj.isCorrect)}</td>
//                       <td>{this.mapUserResponse(obj)}</td>
//                     </tr>
//                   </tbody>
//                 ))}
//               </table>
//             )}
//           </div>
//         )}
//       </>
//     );
//   }
// }

// export default index;
