/**
 * @generated SignedSource<<563600030efe9edf605b95694fafef80>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type StudentAttemptyHistorySelfLearnQuery$variables = {|
  UserId: string,
  ResourceId: string,
  IncludeAssignmentRecord?: ?boolean,
|};
export type StudentAttemptyHistorySelfLearnQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +SelfLearnAttemptHistory: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +IsCompleted: ?string,
      +AttemptSessionId: ?string,
      +AttemptDate: ?string,
      +UserId: ?string,
    |}>,
  |},
|};
export type StudentAttemptyHistorySelfLearnQuery = {|
  variables: StudentAttemptyHistorySelfLearnQuery$variables,
  response: StudentAttemptyHistorySelfLearnQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "IncludeAssignmentRecord"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "IncludeAssignmentRecord",
      "variableName": "IncludeAssignmentRecord"
    },
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId"
    }
  ],
  "concreteType": "ResourceSelflearnLearnosityAttemptHistType",
  "kind": "LinkedField",
  "name": "SelfLearnAttemptHistory",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IsCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptSessionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AttemptDate",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentAttemptyHistorySelfLearnQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StudentAttemptyHistorySelfLearnQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49fe18bf87d9f4d5b8de1b60505c24fb",
    "id": null,
    "metadata": {},
    "name": "StudentAttemptyHistorySelfLearnQuery",
    "operationKind": "query",
    "text": "query StudentAttemptyHistorySelfLearnQuery(\n  $UserId: String!\n  $ResourceId: String!\n  $IncludeAssignmentRecord: Boolean\n) {\n  user(UserId: $UserId) {\n    UserId\n    SelfLearnAttemptHistory(ResourceId: $ResourceId, IncludeAssignmentRecord: $IncludeAssignmentRecord) {\n      ResourceId\n      IsCompleted\n      AttemptSessionId\n      AttemptDate\n      UserId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6d53797af05389cf41366f73167c54e9";

module.exports = ((node/*: any*/)/*: Query<
  StudentAttemptyHistorySelfLearnQuery$variables,
  StudentAttemptyHistorySelfLearnQuery$data,
>*/);
