/**
 * @generated SignedSource<<6662bfb478f1c73b4bec8b8607c16ae9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UserGetSchoolBookSeriesListQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type UserGetSchoolBookSeriesListQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +IsB2CBookSeries: ?boolean,
    |}>,
    +School: ?{|
      +SchoolAdoptedBookSeries: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +LeapBookSeriesId: ?string,
        +IngestionId: ?string,
      |}>,
    |},
  |},
|};
export type UserGetSchoolBookSeriesListQuery = {|
  variables: UserGetSchoolBookSeriesListQuery$variables,
  response: UserGetSchoolBookSeriesListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsB2CBookSeries",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LeapBookSeriesId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserGetSchoolBookSeriesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "bookSeries",
                "kind": "LinkedField",
                "name": "SchoolAdoptedBookSeries",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserGetSchoolBookSeriesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "bookSeries",
                "kind": "LinkedField",
                "name": "SchoolAdoptedBookSeries",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94d9768219a92b50f23210de6e3a550c",
    "id": null,
    "metadata": {},
    "name": "UserGetSchoolBookSeriesListQuery",
    "operationKind": "query",
    "text": "query UserGetSchoolBookSeriesListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries {\n      BookSeriesId\n      IsB2CBookSeries\n      id\n    }\n    School {\n      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {\n        BookSeriesId\n        LeapBookSeriesId\n        IngestionId\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "512c81e0d8efd544653c9d9761cc55fb";

module.exports = ((node/*: any*/)/*: Query<
  UserGetSchoolBookSeriesListQuery$variables,
  UserGetSchoolBookSeriesListQuery$data,
>*/);
