import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
// import { Redirect, Route, Switch, matchPath } from "react-router-dom";
import banner from "../../../assets/img/B2C/banner-green.jpg";
import Loading from "../../../Components/Loading/Loading";
import Footer from "../../../Components/Footer/Footer";
import B2CBody from "./B2CBody";
import Level from "./Level";
import Subjects from "./Subject";
import MyResources from "../../../Services/api/GraphQL/Query/MyResources";
// import { render } from "enzyme";
function index(props) {
  const { t, B2C } = props;
  // const lang = useSelector((state) => state.SystemReducer.lang);
  const [reportTagId, setreportTagId] = useState(
    props.location.pathname.split("/")[3]
  );
  const [SubjectName, setSubjectName] = useState(null);
  const [LevelName, setLevelName] = useState(null);
  const [subjectLoading, setsubjectLoading] = useState(true);
  const [reportTagsArray, setreportTagsArray] = useState([]);
  const [rlevelId, setrlevelId] = useState(
    props.location.pathname.split("/")[4]
  );

  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const effectiveSchoolYear = useSelector(
    (state) => state.SystemReducer.effectiveSchoolYear
  );
  useEffect(() => {
    getResourceList();
  }, []);

  const getResourceList = () => {
    var variables;
    variables = {
      UserId: userData.UserId,
      SchoolYearId: effectiveSchoolYear.SchoolYearId,
    };
    MyResources(variables).then((data) => {
      let tagList = data.user.AdoptedTagHierarchy.filter(
        (o) => o.ForReport == "Y"
      );

      tagList.sort(function (a, b) {
        var seq1 = parseInt(a.DisplaySequence),
          seq2 = parseInt(b.DisplaySequence);
        if (seq1 < seq2) return -1;
        if (seq1 > seq2) return 1;
        return 0;
      });
      setreportTagsArray(tagList);
      var subjectIndex = tagList.findIndex(
        (pobj) => pobj.HierarchyReference == reportTagId
      );

      let subjectName = tagList[subjectIndex].HierarchyReferenceLabelEn;
      let subjectWithLevels = tagList[subjectIndex];
      var levelIndex = pickHighestLevel(subjectWithLevels.AdoptedPearsonLevel);
      setLevelName(
        subjectWithLevels.AdoptedPearsonLevel[levelIndex].LevelName[0].LevelName
      );
      setSubjectName(subjectName);
    });
    setsubjectLoading(false);
  };

  const showLevelAnalysis = (levelId, levelName) => {
    var subjectIndex = reportTagsArray.findIndex(
      (pobj) => pobj.HierarchyReference == reportTagId
    );

    let subjectName = reportTagsArray[subjectIndex].HierarchyReferenceLabelEn;
    gaTrack(
      "send",
      "event",
      "Supplementary",
      "Change performance level in report",
      "Subject:" + subjectName + ", Level:" + levelName + ", Role:" + role
    );
    setLevelName(levelName);
    setSubjectName(subjectName);
    setrlevelId(levelId);
  };

  const pickHighestLevel = (adoptedPearsonLevel) => {
    let maxvalue = Math.max.apply(
      Math,
      adoptedPearsonLevel.map(function (o) {
        return o.TransferSequence;
      })
    );
    return adoptedPearsonLevel.findIndex(
      (pobj) => pobj.TransferSequence == maxvalue
    );
  };

  const showSubjectReport = (tagId) => {
    var subjectIndex = reportTagsArray.findIndex(
      (pobj) => pobj.HierarchyReference == tagId
    );
    let subjectWithLevels = reportTagsArray[subjectIndex];
    var levelIndex = pickHighestLevel(subjectWithLevels.AdoptedPearsonLevel);
    let subjectName = reportTagsArray[subjectIndex].HierarchyReferenceLabelEn;
    gaTrack(
      "send",
      "event",
      "Supplementary",
      "Click tab in performance",
      "Subject:" +
        subjectName +
        ", Level:" +
        subjectWithLevels.AdoptedPearsonLevel[levelIndex].LevelName[0]
          .LevelName +
        ", Role:" +
        role
    );
    setLevelName(
      subjectWithLevels.AdoptedPearsonLevel[levelIndex].LevelName[0].LevelName
    );
    setSubjectName(subjectName);
    setrlevelId(
      subjectWithLevels.AdoptedPearsonLevel[levelIndex].PearsonLevelId
    );
    setreportTagId(tagId);
    props.history.push(
      "/r/myresreport/" +
        tagId +
        "/" +
        subjectWithLevels.AdoptedPearsonLevel[levelIndex].PearsonLevelId
    );
  };

  if (subjectLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div
        className="top-section"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="content-center">
          <div>{t("My Performance Overview")}</div>
        </div>
        {/* menu */}
        <div className="menu assessment ">
          <Subjects
            t={t}
            {...props}
            reportTagsArray={reportTagsArray}
            reportTagId={reportTagId}
            showSubjectReport={showSubjectReport}
          />
        </div>
      </div>
      <div className="body">
        <div className="b2c_assessments">
          <div className="options-row">
            <div className="order-selection">
              <Level
                t={t}
                {...props}
                reportTagsArray={reportTagsArray}
                reportTagId={reportTagId}
                rlevelId={rlevelId}
                showLevelAnalysis={showLevelAnalysis}
              />
              <div className="border"></div>
            </div>
          </div>
          <B2CBody
            t={t}
            {...props}
            levelId={rlevelId}
            subjectId={reportTagId}
            levelName={LevelName}
            subjectName={SubjectName}
          />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default index;
