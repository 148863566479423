import React, { Component } from "react";
import { connect } from "react-redux";

import environment from "../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import NavBar from "../NavBar/Dashboard/NavBar";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
import onBoardingBanner from "../../assets/img/banner/welcome-image.png";
import onBoardingFinishBanner from "../../assets/img/banner/welcome-finish-image.png";
import GetAllSchoolYears from "../Feature/Function/GetAllSchoolYears";
import MessageLightBox from "../Feature/MessageLightBox";
import { Trans } from "react-i18next";
import UpdateUserPreferencesMutation from "../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import OnBoardingMutation from "./OnBoardingMutation";
import { Redirect } from "react-router-dom";
import DOMPurify from 'dompurify';
import monent from "moment";
import "./index.scss";
import { onboarding_addtional_res_filter } from "../../Actions";

const OnBoardingGetSchoolBookSeriesListQuery = graphql`
  query OnBoardingGetSchoolBookSeriesListQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      School {
        SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
          BookSeriesId
          LeapBookSeriesId
          IngestionId
          BookSeriesName {
            Lang
            BookSeriesName
          }
          Books {
            BookName {
              Lang
              BookName
            }
            PearsonLevelId
            PearsonLevel {
              PearsonLevelId
              LevelName {
                PearsonLevelId
                Lang
                LevelName
              }
            }
          }
          DisplaySequence
        }
      }
    }
  }
`;

const OnBoardingGetSchoolAdoptionQuery = graphql`
  query OnBoardingGetSchoolAdoptionQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      School {
        SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {
          BookSeriesId
          LeapBookSeriesId
          IngestionId
          BookSeriesName {
            Lang
            BookSeriesName
          }
          DisplaySequence
        }
      }
    }
  }
`;

const OnBoardingGetBookSeriesStudyGroupListQuery = graphql`
  query OnBoardingGetBookSeriesStudyGroupListQuery(
    $UserId: String!
    $SchoolYearId: String!
    $BookSeriesId: [String!]!
    $FilterOnboarded: Boolean!
  ) {
    user(UserId: $UserId) {
      School {
        StudyGroups(
          SchoolYearId: $SchoolYearId
          BookSeriesId: $BookSeriesId
          FilterOnboarded: $FilterOnboarded
        ) {
          StudyGroupId
          StudyGroupName {
            Lang
            StudyGroupName
          }
          BookSeries {
            BookSeriesId
          }
          Level {
            PearsonLevelId
            LevelName {
              LevelName
              Lang
            }
            DisplaySequence
          }
        }
      }
    }
  }
`;

const OnBoardingTeacherStudyGroupQuery = graphql`
  query OnBoardingTeacherStudyGroupQuery(
    $UserId: String!
    $SchoolYearId: String!
    $FilterOnboarded: Boolean!
  ) {
    user(UserId: $UserId) {
      School {
        StudyGroups(
          SchoolYearId: $SchoolYearId
          FilterOnboarded: $FilterOnboarded
        ) {
          StudyGroupId

          BookSeries {
            BookSeriesId
            IngestionId
          }
        }
      }
    }
  }
`;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      openTabStatus: [],
      isOpen: false,
      AdoptedSchoolYear: [],
      AdoptedBookSeries: null,
      selectedBookSeries: [],
      GetBookSeriesStudyGroupLoading: true,
      GetAdditionalResourceLoading: false,
      selectedStudyGroup: [],
      selectedResourceData: [],
      currentSelectedResourceData: [],
      tempResourceData: [],
      additionalResourceData: [],
      resourcePopupData: [],
      lightBoxData: null,
      isResourceOpen: false,
    };
  }

  componentDidMount() {
    //ga
    // gaTrack("send", "pageview", "/onboarding");
    //
    if (this.props.history.location.state) {
      if (this.props.history.location.state.step == 3) {
        this.setState({
          step: 3,
          isComeFromEdit: true,
        });

        this.getSchoolYearList();
        // this.GetBookSeriesStudyGroup()
        //next
      } else if (this.props.history.location.state.step == 2) {
        this.setState({
          step: 2,
          isComeFromEdit: true,
        });

        this.getSchoolYearList();
        // this.GetBookSeriesStudyGroup()
        //next
      }
    } else {
      console.log("Get School year list");
      this.getSchoolYearList();
    }
    let AdoptedYear = [];

    JSON.parse(localStorage.getItem("userData")).AdoptedSchoolYear.map((x) => {
      var values = {
        SchoolYearId: x.SchoolYearId,
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      };
      fetchQuery(
        environment,
        OnBoardingGetSchoolBookSeriesListQuery,
        values
      ).then((data) => {
        console.log(data, "lisarraydata");
        var listArray = [];
        data.user.School.SchoolAdoptedBookSeries.map((obj, index) => {
          if (obj.IngestionId == null || obj.IngestionId == "") return;
          listArray.push(obj);
        });
        listArray.sort(function (a, b) {
          return a.DisplaySequence - b.DisplaySequence;
        });
        if (listArray.length > 0) {
          AdoptedYear.push(x);
          console.log(x);
        }
      });
    });

    console.log(AdoptedYear);
    console.log(
      AdoptedYear.sort(function (a, b) {
        return a.SchoolYearId - b.SchoolYearId;
      })
    );
    AdoptedYear.sort(function (a, b) {
      return a.SchoolYearId - b.SchoolYearId;
    });

    this.setState({ AdoptedSchoolYear: AdoptedYear });

    console.log(AdoptedYear, this.state.AdoptedSchoolYear);
  }

  getTeacherAdoptedBookSeries = () => {
    console.log(this.state.selectedSchoolYear);

    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.AdoptedSchoolYear.sort(function (a, b) {
        return a.SchoolYearId - b.SchoolYearId;
      });
    var vairables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      // SchoolYearId: JSON.parse(localStorage.getItem("effectiveSchoolYear"))
      //   .SchoolYearId,
      SchoolYearId: userData
        .AdoptedSchoolYear[this.state.selectedSchoolYear].SchoolYearId,
      FilterOnboarded: true,
    };
    fetchQuery(environment, OnBoardingTeacherStudyGroupQuery, vairables).then(
      (data) => {
        console.log(data);
        const { user: { School } = {} } = data || {};
        const allBooksSeries = School.StudyGroups.reduce((result, cuurent) => {
          if (cuurent.BookSeries.IngestionId !== null) {
            result.push({
              selectedBookSeries: cuurent.BookSeries.BookSeriesId,
              selectedStudyGroupID: cuurent.StudyGroupId,
            });
          }

          return result;
        }, []);
        console.log(allBooksSeries);
        if (allBooksSeries.length == 0) {
          //return this.props.history.push("/m/toolbox");
        }
        var bookSeriresIds = allBooksSeries.map((item) => {
          return item.selectedBookSeries;
        });

        var bookSeriresIdsUnique = Array.from(new Set(bookSeriresIds));

        var studyGroupIds = allBooksSeries.map((item) => {
          return item.selectedStudyGroupID;
        });

        var studyGroupIdsUnique = Array.from(new Set(studyGroupIds));

        this.setState({
          selectedBookSeries: bookSeriresIdsUnique,
          selectedStudyGroup: studyGroupIdsUnique,
        });
        this.GetBookSeriesStudyGroup();
      }
    );
  };

  getSchoolYearList = () => {
    //only need this year and future year
    try {
      var schoolYearList;
      GetAllSchoolYears((list) => {
        schoolYearList = list.sort(function (a, b) {
          return (
            new monent(b.EffectiveStartDate) - new monent(a.EffectiveStartDate)
          );
        });
        console.log(schoolYearList, "schoolYearList");
        console.log(schoolYearList.slice(0, 2));
        schoolYearList = schoolYearList.slice(0, 2);
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        schoolYearList.map((obj, index) => {
          if (new Date(obj.EffectiveEndDate) < now) {
            console.log(obj.EffectiveEndDate, now);
            schoolYearList.splice(index, 1);
          }
        });
        const isLargeNumber = (element) =>
          element.SchoolYearId == this.props.selectedYear.SchoolYearId;

        console.log(
          JSON.parse(
            localStorage.getItem("userData")
          ).AdoptedSchoolYear.findIndex(isLargeNumber)
        );
        console.log("Text");
        var userData = JSON.parse(DOMPurify.sanitize(localStorage.getItem("userData")));
      userData.AdoptedSchoolYear.sort(function (a, b) {
        return a.SchoolYearId - b.SchoolYearId;
      });
        this.setState({
          schoolYearList: schoolYearList,
          selectedSchoolYear:
            userData.AdoptedSchoolYear.length >= 1
              ? userData.AdoptedSchoolYear.findIndex(isLargeNumber)
              : 0,
        });

        console.log(this.state.selectedSchoolYear, this.state.schoolYearList);
        if (schoolYearList.length > 0) {
          if (userData.AdoptedSchoolYear.length > 0) {
            this.getSchoolAdoptedBookSeries(
              this.props.selectedYear.SchoolYearId
            );
            console.log("ifdata");
          } else {
            console.log("elsedata");

            this.getSchoolAdoptedBookSeries(schoolYearList[0].SchoolYearId);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  onClickTitle = (value) => {
    const { openTabStatus } = this.state;
    var temp = openTabStatus;
    if (openTabStatus.indexOf(value) == -1) {
      temp.push(value);
    } else {
      temp.splice(openTabStatus.indexOf(value), 1);
    }

    this.setState({
      openTabStatus: temp,
    });
  };

  getProgressClass = (current, val) => {
    if (current == val) {
      return "line active";
    } else if (current < val) {
      return "line done";
    } else if (current > val) {
      return "line inactive";
    } else {
      return "";
    }
  };

  onClickAction = (step, action) => {
    const {
      selectedBookSeries,
      selectedStudyGroup,
      EachBookSeriesGroupByLevel,
    } = this.state;
    const { t } = this.props;
    const lang = localStorage.getItem("i18nextLng");
    switch (step) {
      case 1:
        window.scrollTo(0, 0);
        this.setState({ step: 2 });
        gaTrack(
          "send",
          "event",
          "Onboarding",
          "Onboarding next",
          "Step:" + step
        );
        break;

      case 2:
        if (action == "back") {
          window.scrollTo(0, 0);
          gaTrack(
            "send",
            "event",
            "Onboarding",
            "Onboarding backward",
            "Step:" + step
          );
          this.setState({ step: step - 1 });
        } else {
          if (selectedBookSeries.length == 0) {
            this.setState({
              isOpen: true,
              lightBoxData: {
                title: t("Choose your book"),
                message: t(
                  "Please select at least one book which you will teach."
                ),
                // onConfirm: this.setUpLater,
                onClose: () =>
                  this.setState({ isOpen: false, lightBoxData: null }),
                type: "messageBox",
                customButton: () => {
                  return (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        this.setState({ isOpen: false, lightBoxData: null })
                      }
                    >
                      {t("OK")}
                    </button>
                  );
                },
              },
            });
            console.log("[selectedBookSeries] 0");
          } else {
            gaTrack(
              "send",
              "event",
              "Onboarding",
              "Onboarding next",
              "Step:" + (step - 1)
            );
            this.setState({ step: step + 1 });
            window.scrollTo(0, 0);
            this.GetBookSeriesStudyGroup();
          }
        }
        break;
      case 3:
        window.scrollTo(0, 0);
        if (action == "back") {
          gaTrack(
            "send",
            "event",
            "Onboarding",
            "Onboarding backward",
            "Step:" + step
          );
          this.setState({ step: step - 1 });
        } else {
          //Check if selectedStudyGroup ==0 or some book Series selected 0 class
          var emptyClassesEachBookSeries = [];
          selectedBookSeries.map((obj, index) => {
            var count = 0;
            EachBookSeriesGroupByLevel[
              EachBookSeriesGroupByLevel.map((obj, index) => {
                return obj.BookSeries.BookSeriesId;
              }).indexOf(obj)
            ].Level.map((lobj, lindex) => {
              lobj.StudyGroups.map((sobj, sindex) => {
                if (selectedStudyGroup.indexOf(sobj.StudyGroupId) != -1) {
                  count = count + 1;
                }
              });
            });
            if (count == 0) {
              emptyClassesEachBookSeries.push({
                BookSeriesId: obj,
              });
            }
          });
          console.log(emptyClassesEachBookSeries, "emptyClassesEachBookSeries");
          if (emptyClassesEachBookSeries.length != 0) {
            var EmptyName = "";
            emptyClassesEachBookSeries.map((eobj, index) => {
              if (index == 0) {
                EmptyName =
                  EachBookSeriesGroupByLevel[
                    EachBookSeriesGroupByLevel.map((obj, index) => {
                      return obj.BookSeries.BookSeriesId;
                    }).indexOf(eobj.BookSeriesId)
                  ].BookSeries.BookSeriesName[
                    EachBookSeriesGroupByLevel[
                      EachBookSeriesGroupByLevel.map((obj, index) => {
                        return obj.BookSeries.BookSeriesId;
                      }).indexOf(eobj.BookSeriesId)
                    ].BookSeries.BookSeriesName[0].Lang == lang
                      ? 0
                      : 1
                  ].BookSeriesName;
              } else {
                EmptyName =
                  EmptyName +
                  ", " +
                  EachBookSeriesGroupByLevel[
                    EachBookSeriesGroupByLevel.map((obj, index) => {
                      return obj.BookSeries.BookSeriesId;
                    }).indexOf(eobj.BookSeriesId)
                  ].BookSeries.BookSeriesName[
                    EachBookSeriesGroupByLevel[
                      EachBookSeriesGroupByLevel.map((obj, index) => {
                        return obj.BookSeries.BookSeriesId;
                      }).indexOf(eobj.BookSeriesId)
                    ].BookSeries.BookSeriesName[0].Lang == lang
                      ? 0
                      : 1
                  ].BookSeriesName;
              }
            });
            this.setState({
              isOpen: true,
              lightBoxData: {
                title: t("Choose your class"),
                message: () => (
                  <Trans i18nKey="Please select class for" count={1}>
                    {{
                      bookseries: EmptyName,
                    }}
                  </Trans>
                ),

                // onConfirm: this.setUpLater,
                onClose: () =>
                  this.setState({ isOpen: false, lightBoxData: null }),
                type: "messageBox",
                customButton: () => {
                  return (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        this.setState({ isOpen: false, lightBoxData: null })
                      }
                    >
                      {t("OK")}
                    </button>
                  );
                },
              },
            });
          } else {
            gaTrack(
              "send",
              "event",
              "Onboarding",
              "Onboarding next",
              "Step:" + step
            );
            this.setState({ step: step + 1 });
          }
          this.setState({
            selectedResourceData: this.getSelectedResourceData(),
            tempResourceData: this.getSelectedResourceData(),
            resourcePopupData: this.getPopupData(EachBookSeriesGroupByLevel),
          });
        }
        break;
      case 4:
        window.scrollTo(0, 0);
        if (action == "back") {
          gaTrack(
            "send",
            "event",
            "Onboarding",
            "Onboarding backward",
            "Step:" + step
          );
          this.setState({ step: step - 1 });
        } else {
          gaTrack(
            "send",
            "event",
            "Onboarding",
            "Onboarding next",
            "Step:" + step
          );
          this.setState({ step: step + 1 });
        }
        break;
      case 5:
        window.scrollTo(0, 0);
        if (action == "back") {
          gaTrack(
            "send",
            "event",
            "Onboarding",
            "Onboarding backward",
            "Step:" + step
          );
          this.setState({ step: step - 1 });
        } else {
          gaTrack(
            "send",
            "event",
            "Onboarding",
            "Onboarding next",
            "Step:" + step
          );
          this.submitSelectedOption();
        }
        break;
      case 6:
        window.scrollTo(0, 0);
        this.props.history.push("/m/dashboard");
        break;
    }
  };
  setUpLater = (isCallByFinish) => {
    var userData = JSON.parse(localStorage.getItem("userData"));

    var result = userData.PreferencesWithYear.filter(obj => {
      return obj.PreferenceName === "ONBOARDING_SKIP"
    })

    console.log(result, this.props.selectedYear.SchoolYearId)

    if (
      userData.PreferencesWithYear.map((obj) => {
        return obj.PreferenceName;
      }).indexOf("ONBOARDING_SKIP") !== -1 && result[0].PreferenceValue=="true" && result[0].SchoolYearId == this.props.selectedYear.SchoolYearId
    ) {
      console.log("[Have Skip record]");
      if (!isCallByFinish) return this.props.history.push("/m/myschool");
    } else {
      // var schoolYearId = JSON.parse(
      //   localStorage.getItem("effectiveSchoolYear")
      // ).SchoolYearId;

      var schoolYearId = this.props.selectedYear.SchoolYearId

      console.log(schoolYearId);

      var data = {
        SchoolYearId: schoolYearId,
        PreferenceName: "ONBOARDING_SKIP",
        PreferenceValue: "true",
      };

      const callback = (result) => {
        var userData = JSON.parse(localStorage.getItem("userData"));
        gaTrack(
          "send",
          "event",
          "Onboarding",
          "Setup Later",
          "Role:" + userData.UserType
        );
        console.log(result);

        userData.Preferences = result.modifyUserPreference.Preferences;

        userData.PreferencesWithYear =
          result.modifyUserPreference.PreferencesWithYear;
        localStorage.setItem("userData", JSON.stringify(userData));
        if (!isCallByFinish) this.props.history.push("/m/myschool");
      };

      UpdateUserPreferencesMutation(data, callback);
    }
  };

  GetBookSeriesStudyGroup = () => {
    this.setState({
      GetBookSeriesStudyGroupLoading: true,
    });
    const {
      step,
      selectedSchoolYear,
      schoolYearList,
      selectedBookSeries,
      AdoptedBookSeries,
    } = this.state;
    const userData = JSON.parse(localStorage.getItem("userData"));

          userData.AdoptedSchoolYear.sort(function (a, b) {
        return a.SchoolYearId - b.SchoolYearId;
      });
    console.log(schoolYearList, selectedSchoolYear);
    // console.log(
    //   "[GetBookSeriesStudyGroup] ",
    //   userData,
    //   schoolYearList[selectedSchoolYear].SchoolYearId
    // );
    const lang = localStorage.getItem("i18nextLng");

    try {
      var value = {
        SchoolYearId:
          userData.AdoptedSchoolYear.length > 0
            ? userData.AdoptedSchoolYear[selectedSchoolYear].SchoolYearId
            : schoolYearList[selectedSchoolYear].SchoolYearId,
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
        FilterOnboarded: false,
        BookSeriesId: selectedBookSeries,
      };
      console.log(value, "onboardingvalue");
      fetchQuery(
        environment,
        OnBoardingGetBookSeriesStudyGroupListQuery,
        value
      ).then((data) => {
        console.log(data);
        var studyGroupsData = JSON.parse(
          JSON.stringify(data.user.School.StudyGroups)
        );
        console.log(studyGroupsData);
        // studyGroupsData = dummyData;
        var EachBookSeriesGroupByLevel = [];
        selectedBookSeries.map((obj, index) => {
          var mapBookSeriesData =
            AdoptedBookSeries[
              AdoptedBookSeries.map((bobj) => {
                return bobj.BookSeriesId;
              }).indexOf(obj)
            ];
          console.log(mapBookSeriesData);
          EachBookSeriesGroupByLevel.push({ BookSeries: mapBookSeriesData });
        });

        EachBookSeriesGroupByLevel.map((obj, index) => {
          //   if (index == 2) return;
          console.log(obj, "EachBookSeriesGroupByLevel");
          var temp = [];
          studyGroupsData.map((dobj, dindex) => {
            console.log("studyGroupsData", dobj, obj.BookSeries.BookSeriesId);
            if (dobj.BookSeries.BookSeriesId == obj.BookSeries.BookSeriesId) {
              temp.push(dobj);
              console.log("temp push group", dobj);
            }
          });

          console.log("temp", temp);

          var groupByLevel = [];
          temp.map((tobj, tindex) => {
            console.log(
              "temp map",
              tobj,
              tindex,
              groupByLevel
                .map((l) => {
                  return l.PearsonLevelId;
                })
                .indexOf(tobj.Level.PearsonLevelId)
            );
            if (
              groupByLevel
                .map((l) => {
                  return l.PearsonLevelId;
                })
                .indexOf(tobj.Level.PearsonLevelId) == -1
            ) {
              groupByLevel.push(tobj.Level);
            }
          });
          console.log(groupByLevel, "groupByLevel");
          EachBookSeriesGroupByLevel[index].Level = [...groupByLevel];
        });
        console.log(EachBookSeriesGroupByLevel);
        var autoOpenLevelTab = [];
        EachBookSeriesGroupByLevel.map((obj, index) => {
          obj.Level &&
            obj.Level.map((lobj, lindex) => {
              autoOpenLevelTab.push(
                lobj.PearsonLevelId + "_" + obj.BookSeries.BookSeriesId
              );
              var temp = [];
              studyGroupsData.map((dobj, dindex) => {
                if (lobj.PearsonLevelId == dobj.Level.PearsonLevelId) {
                  if (
                    dobj.BookSeries.BookSeriesId == obj.BookSeries.BookSeriesId
                  )
                    temp.push(dobj);
                }
              });
              temp.sort(function (a, b) {
                return a.StudyGroupName[
                  a.StudyGroupName[0].Lang == lang ? 0 : 1
                ].StudyGroupName.localeCompare(
                  b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
                    .StudyGroupName
                );
              });
              EachBookSeriesGroupByLevel[index].Level[lindex].StudyGroups = [
                ...temp,
              ];
            });
          EachBookSeriesGroupByLevel[index].Level &&
            EachBookSeriesGroupByLevel[index].Level.sort(function (a, b) {
              return a.DisplaySequence - b.DisplaySequence;
            });
        });
        console.log(EachBookSeriesGroupByLevel);
        if (EachBookSeriesGroupByLevel.length > 0) {
          this.setState({
            GetBookSeriesStudyGroupLoading: false,
            EachBookSeriesGroupByLevel: EachBookSeriesGroupByLevel,
            ActiveBookSeriesTab:
              EachBookSeriesGroupByLevel[0].BookSeries.BookSeriesId,
            openTabStatus: autoOpenLevelTab,
          });
        } else {
          this.setState({ step: 2 });
        }
      });
    } catch (e) {
      console.log("[GetBookSeriesStudyGroup] ", e);
    }
  };

  getSchoolAdoptedBookSeries = (schoolYear) => {
    console.log("[getSchoolAdoptedBookSeries] ", schoolYear);
    this.setState({
      AdoptedBookSeriesLoading: true,
    });
    try {
      var value = {
        SchoolYearId: schoolYear,
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      };
      console.log(value, "yearvalue");
      fetchQuery(
        environment,
        OnBoardingGetSchoolBookSeriesListQuery,
        value
      ).then((data) => {
        console.log(data, "lisarraydata");
        var listArray = [];
        data.user.School.SchoolAdoptedBookSeries.map((obj, index) => {
          if (obj.IngestionId == null || obj.IngestionId == "") return;
          listArray.push(obj);
        });
        listArray.sort(function (a, b) {
          return a.DisplaySequence - b.DisplaySequence;
        });

        console.log(listArray, "listArray");

        if (listArray.length == 0) {
          this.setState({
            step: 2,
            isComeFromEdit: true,
          });
          return this.props.history.push("/m/myschool");
        }
        this.setState({
          AdoptedBookSeries: listArray,
          AdoptedBookSeriesLoading: false,
        });
        console.log(this.state.AdoptedBookSeries, "listArray");

        if (this.state.isComeFromEdit) {
          this.getTeacherAdoptedBookSeries();
        }
      });
    } catch (e) {
      console.log("[getSchoolAdoptedBookSeries] ", e);
    }
  };

  onCheckBookSeries = (e) => {
    const { selectedBookSeries } = this.state;
    var newselectedBookSeries = [...selectedBookSeries];
    if (e.target.checked) {
      //   newselectedBookSeries = selectedBookSeries;
      newselectedBookSeries.push(e.target.value);
    } else {
      //   newselectedBookSeries = selectedBookSeries;
      newselectedBookSeries.splice(
        newselectedBookSeries.indexOf(e.target.value),
        1
      );
    }
    console.log(newselectedBookSeries, "selectedbookseries");
    this.setState({
      selectedBookSeries: newselectedBookSeries,
    });
  };

  onCheckLevel = (e, id) => {
    const {
      selectedStudyGroup,
      EachBookSeriesGroupByLevel,
      ActiveBookSeriesTab,
    } = this.state;
    var newselectedStudyGroup = [];
    if (e.target.checked) {
      newselectedStudyGroup = selectedStudyGroup;
      console.log(
        EachBookSeriesGroupByLevel[
          EachBookSeriesGroupByLevel.map((l) => {
            return l.BookSeries.BookSeriesId;
          }).indexOf(ActiveBookSeriesTab)
        ]
      );
      EachBookSeriesGroupByLevel[
        EachBookSeriesGroupByLevel.map((l) => {
          return l.BookSeries.BookSeriesId;
        }).indexOf(ActiveBookSeriesTab)
      ].Level.map((lobj, i) => {
        console.log(lobj, id);
        if (lobj.PearsonLevelId == id) {
          lobj.StudyGroups &&
            lobj.StudyGroups.map((g) => {
              if (selectedStudyGroup.indexOf(g.StudyGroupId) == -1)
                newselectedStudyGroup.push(g.StudyGroupId);
            });
        }
      });
      //   newselectedStudyGroup.push(e.target.value);
    } else {
      newselectedStudyGroup = selectedStudyGroup;

      EachBookSeriesGroupByLevel[
        EachBookSeriesGroupByLevel.map((l) => {
          return l.BookSeries.BookSeriesId;
        }).indexOf(ActiveBookSeriesTab)
      ].Level.map((e, i) => {
        if (e.PearsonLevelId == id) {
          e.StudyGroups.map((g) => {
            if (newselectedStudyGroup.indexOf(g.StudyGroupId) != -1) {
              newselectedStudyGroup.splice(
                newselectedStudyGroup.indexOf(g.StudyGroupId),
                1
              );
            }
          });
        }
      });
    }
    console.log(newselectedStudyGroup);

    this.setState({
      selectedStudyGroup: newselectedStudyGroup,
      selectedResourceData: this.getSelectedResourceData(),
      tempResourceData: this.getSelectedResourceData(),
      resourcePopupData: this.getPopupData(EachBookSeriesGroupByLevel),
    });
  };

  onCheckStudyGroup = (e) => {
    const { selectedStudyGroup, EachBookSeriesGroupByLevel } = this.state;
    var newselectedStudyGroup = selectedStudyGroup;
    if (e.target.checked) {
      newselectedStudyGroup.push(e.target.value);
    } else {
      newselectedStudyGroup.splice(
        newselectedStudyGroup.indexOf(e.target.value),
        1
      );
    }
    console.log(newselectedStudyGroup, "newselectedStudyGroup");
    this.setState({
      selectedStudyGroup: newselectedStudyGroup,
      selectedResourceData: this.getSelectedResourceData(),
      tempResourceData: this.getSelectedResourceData(),
      resourcePopupData: this.getPopupData(EachBookSeriesGroupByLevel),
    });
  };

  checkLevelIsChecked = (id) => {
    console.log("checkLevelIsChecked");
    var result = false,
      indeterminate = false;
    const {
      selectedStudyGroup,
      EachBookSeriesGroupByLevel,
      ActiveBookSeriesTab,
    } = this.state;
    EachBookSeriesGroupByLevel[
      EachBookSeriesGroupByLevel.map((bk) => {
        return bk.BookSeries.BookSeriesId;
      }).indexOf(ActiveBookSeriesTab)
    ].Level.map((lobj, lindex) => {
      if (lobj.PearsonLevelId == id) {
        var count = 0;
        lobj.StudyGroups.map((sobj, sindex) => {
          if (selectedStudyGroup.indexOf(sobj.StudyGroupId) != -1) {
            count = count + 1;
          }
        });
        console.log("checkLevelIsChecked", count, lobj.StudyGroups.length);
        if (count == lobj.StudyGroups.length) {
          indeterminate = false;

          result = true;
        } else {
          if (count > 0) {
            indeterminate = true;
          } else {
            indeterminate = false;
          }
          result = false;
        }
      }
    });
    var check = setInterval(() => {
      if (
        document.getElementById(id + "_" + ActiveBookSeriesTab + "_checkbox")
      ) {
        clearInterval(check);
        document.getElementById(
          id + "_" + ActiveBookSeriesTab + "_checkbox"
        ).indeterminate = indeterminate;
      }
    }, 10);
    console.log(result);
    return result;
  };

  countSelectedStudentByBookSeries = (bookSeriesId) => {
    const {
      selectedStudyGroup,
      EachBookSeriesGroupByLevel,
      ActiveBookSeriesTab,
    } = this.state;
    var count = 0;
    EachBookSeriesGroupByLevel[
      EachBookSeriesGroupByLevel.map((b) => {
        return b.BookSeries.BookSeriesId;
      }).indexOf(bookSeriesId)
    ].Level.map((lobj, lindex) => {
      lobj.StudyGroups.map((sobj, sindex) => {
        if (selectedStudyGroup.indexOf(sobj.StudyGroupId) != -1) {
          count = count + 1;
        }
      });
    });
    return count;
  };

  getUserPref = () => {
    //const { selectedBookSeries } = this.state;
    var userData = JSON.parse(localStorage.getItem("userData"));
    var existingPreferences = userData.PreferencesWithYear;
    let preferencesUser = onboarding_addtional_res_filter(existingPreferences);
    var newAddtionalRes = [];
    if (preferencesUser.length > 0) {
      var prefVal = JSON.parse(preferencesUser[0].PreferenceValue);
      var robj = {};
      prefVal.forEach((bobj) => {
        if (typeof bobj.resourceValues != "undefined") {
          for (var i = 0; i < bobj.resourceValues.length; i++) {
            robj = {
              bookSeriesId: bobj.BookseriesId,
              resourceData: bobj.resourceValues[i].resourceData,
              levelId: bobj.resourceValues[i].levelId,
            };
            newAddtionalRes.push(robj);
          }
        }
      });
    }
    return newAddtionalRes;
  };

  getSelectedResourceData = () => {
    const { currentSelectedResourceData, selectedBookSeries } = this.state;
    if (currentSelectedResourceData.length == 0) {
      var existingResource = this.getUserPref();
      var filteredArray = existingResource.filter(function (itm) {
        return selectedBookSeries.indexOf(itm.bookSeriesId) > -1;
      });
    } else {
      var filteredArray = currentSelectedResourceData.filter(function (itm) {
        return selectedBookSeries.indexOf(itm.bookSeriesId) > -1;
      });
    }
    return filteredArray;
  };

  getSelectedResourceData = () => {
    const {
      currentSelectedResourceData,
      selectedBookSeries,
      EachBookSeriesGroupByLevel,
    } = this.state;
    if (currentSelectedResourceData.length == 0) {
      var existingResource = this.getUserPref();
      var filteredArray = existingResource.filter(function (itm) {
        return selectedBookSeries.indexOf(itm.bookSeriesId) > -1;
      });
    } else {
      var filteredArray = currentSelectedResourceData.filter(function (itm) {
        return selectedBookSeries.indexOf(itm.bookSeriesId) > -1;
      });
    }
    var defaultRes = this.getDefaultSelectedResourceData(
      EachBookSeriesGroupByLevel
    );

    var resourceDataOut = filteredArray.filter(function (objFromA) {
      return !defaultRes.find(function (objFromB) {
        return objFromA.resourceData === objFromB.resourceData;
      });
    });
    return resourceDataOut;
  };

  renderStudyGroup = (bobj) => {
    const { selectedStudyGroup } = this.state;
    const lang = localStorage.getItem("i18nextLng");
    var count = 0;

    return bobj.Level.map((lobj, lindex) => {
      return lobj.StudyGroups.map((sobj, sindex) => {
        if (selectedStudyGroup.indexOf(sobj.StudyGroupId) != -1) {
          if (count == 0) {
            count = count + 1;
            return (
              <span key={sindex + "_" + lindex}>
                <Trans i18nKey="ClassTitle" count={1}>
                  {{
                    class:
                      sobj.StudyGroupName[
                        sobj.StudyGroupName[0].Lang == lang ? 0 : 1
                      ].StudyGroupName,
                  }}
                </Trans>
              </span>
            );
            // return sobj.StudyGroupName[
            //   sobj.StudyGroupName[0].Lang == lang ? 0 : 1
            // ].StudyGroupName;
          }

          return (
            <span key={sindex + "_" + lindex}>
              ,{" "}
              <Trans i18nKey="ClassTitle" count={1}>
                {{
                  class:
                    sobj.StudyGroupName[
                      sobj.StudyGroupName[0].Lang == lang ? 0 : 1
                    ].StudyGroupName,
                }}
              </Trans>
            </span>
          );
          // return (
          //   ", " +
          //   sobj.StudyGroupName[sobj.StudyGroupName[0].Lang == lang ? 0 : 1]
          //     .StudyGroupName
          // );
        }
      });
    });
  };

  getDefaultSelectedResourceData = (EachBookSeriesGroupByLevelParam) => {
    const { selectedStudyGroup } = this.state;
    const lang = localStorage.getItem("i18nextLng");
    var count = 0;
    var resourceInfo = "";
    var additionalResourceData = "";
    var additionalResourceArray = [];
    EachBookSeriesGroupByLevelParam.map((bobj, bindex) => {
      resourceInfo =
        bobj.BookSeries.BookSeriesName[
          bobj.BookSeries.BookSeriesName[0].Lang == lang ? 0 : 1
        ].BookSeriesName;
      bobj.Level.map((lobj, lindex) => {
        count = 0;
        lobj.StudyGroups.map((sobj, sindex) => {
          if (selectedStudyGroup.indexOf(sobj.StudyGroupId) != -1) {
            if (count == 0) {
              count = count + 1;
              additionalResourceData =
                resourceInfo +
                " - " +
                lobj.LevelName[lobj.LevelName[0].Lang == lang ? 0 : 1]
                  .LevelName;
              // additionalResourceArray.push(additionalResourceData);
              var obj = {
                bookSeriesId: bobj.BookSeries.BookSeriesId,
                resourceData: additionalResourceData,
                levelId: lobj.PearsonLevelId,
              };
              additionalResourceArray.push(obj);
            }
          }
        });
      });
    });
    return additionalResourceArray;
  };

  getPopupData = (EachBookSeriesGroupByLevelParam) => {
    const { selectedBookSeries, AdoptedBookSeries } = this.state;
    const lang = localStorage.getItem("i18nextLng");
    var filteredArray = AdoptedBookSeries.filter(function (itm) {
      return selectedBookSeries.indexOf(itm.BookSeriesId) > -1;
    });

    var resourceData = [];
    var resourceInfo;
    var additionalResourceData;
    var levels = [];
    filteredArray.map((bobj, index) => {
      resourceInfo =
        bobj.BookSeriesName[bobj.BookSeriesName[0].Lang == lang ? 0 : 1]
          .BookSeriesName;
      levels = [];
      bobj.Books.map((bookObj, bookIndex) => {
        if (bookObj.PearsonLevelId) {
          if (!levels.includes(bookObj.PearsonLevelId)) {
            additionalResourceData =
              resourceInfo +
              " - " +
              bookObj.PearsonLevel.LevelName[
                bookObj.PearsonLevel.LevelName[0].Lang == lang ? 0 : 1
              ].LevelName;
            var obj = {
              bookSeriesId: bobj.BookSeriesId,
              resourceData: additionalResourceData,
              levelId: bookObj.PearsonLevelId,
            };
            levels.push(bookObj.PearsonLevelId);
            resourceData.push(obj);
          }
        }
      });
    });

    var defaultData = this.getDefaultSelectedResourceData(
      EachBookSeriesGroupByLevelParam
    );

    var resourceDataOut = resourceData.filter(function (objFromA) {
      return !defaultData.find(function (objFromB) {
        return objFromA.resourceData === objFromB.resourceData;
      });
    });
    return resourceDataOut;
  };

  checkPopup = () => {
    const { resourcePopupData } = this.state;
    console.log(resourcePopupData);
    if (resourcePopupData.length > 0) {
      return true;
    }
    return false;
  };

  checkAdditionalResource = () => {
    const { selectedResourceData } = this.state;
    if (selectedResourceData.length > 0) {
      return true;
    }
    return false;
  };

  renderAdditionalResource = () => {
    const { EachBookSeriesGroupByLevel, selectedResourceData } = this.state;
    const lang = localStorage.getItem("i18nextLng");
    const resourceGroupByBookSeries = this.groupByBookSeries(
      selectedResourceData,
      "bookSeriesId"
    );

    return (
      <ul>
        {selectedResourceData.map((bobj, bindex) => {
          return (
            <li key={bindex}>
              <div style={{ display: "inline-flex" }}>
                <i className="icon mdi mdi-check"></i>
                <p>{bobj.resourceData}</p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  renderSelectedStudyGroupGroupByBookSeries = () => {
    const { EachBookSeriesGroupByLevel } = this.state;
    const lang = localStorage.getItem("i18nextLng");
    return (
      <ul>
        {EachBookSeriesGroupByLevel.map((bobj, bindex) => {
          return (
            <li key={bindex}>
              <div style={{ display: "inline-flex" }}>
                <i className="icon mdi mdi-check"></i>
                <p>
                  {" "}
                  {
                    bobj.BookSeries.BookSeriesName[
                      bobj.BookSeries.BookSeriesName[0].Lang == lang ? 0 : 1
                    ].BookSeriesName
                  }{" "}
                  - {this.renderStudyGroup(bobj)}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  groupByBookSeries = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  submitSelectedOption = () => {
    this.setState({
      submitLoading: true,
    });
    const {
      selectedStudyGroup,
      step,
      selectedBookSeries,
      EachBookSeriesGroupByLevel,
      selectedResourceData,
    } = this.state;
    var submitStudyGroupIds = [];
    selectedBookSeries.map((obj, index) => {
      EachBookSeriesGroupByLevel[
        EachBookSeriesGroupByLevel.map((j) => {
          return j.BookSeries.BookSeriesId;
        }).indexOf(obj)
      ].Level.map((lobj, lindex) => {
        lobj.StudyGroups.map((sobj, sindex) => {
          if (selectedStudyGroup.indexOf(sobj.StudyGroupId) != -1) {
            submitStudyGroupIds.push(sobj.StudyGroupId);
          }
        });
      });
    });
    console.log(submitStudyGroupIds, "submitStudyGroupIds");
    // var SchoolYearId = JSON.parse(
    //   localStorage.getItem("effectiveSchoolYear")
    // ).SchoolYearId;
    let userData = JSON.parse(localStorage.getItem("userData"));
      userData.AdoptedSchoolYear.sort(function (a, b) {
        return a.SchoolYearId - b.SchoolYearId;
      });
    console.log(this.state.selectedSchoolYear, "selectedschoolyear");
    var SchoolYearId =
      userData.AdoptedSchoolYear.length > 0
        ? userData.AdoptedSchoolYear[
            this.state.selectedSchoolYear
          ].SchoolYearId
        : this.state.schoolYearList[this.state.selectedSchoolYear].SchoolYearId;
    var data = {
      SchoolYearId: SchoolYearId,
      StudyGroupIds: submitStudyGroupIds,
    };
    // return;
    const resourceGroupByBookSeries = this.groupByBookSeries(
      selectedResourceData,
      "bookSeriesId"
    );
    //Additional Resource Data
    const keysbook = Object.keys(resourceGroupByBookSeries);
    var resourceDBData = [];
    keysbook.forEach((element, index, array) => {
      var levels = [];
      var resourceDataVal = [];
      var obj = {};
      var levelArr = resourceGroupByBookSeries[element];
      levelArr.map((bobj, bindex) => {
        levels.push(bobj.levelId);
        var obj = {
          bookSeriesId: element,
          resourceData: bobj.resourceData,
          levelId: bobj.levelId,
        };
        resourceDataVal.push(obj);
      });
      obj = {
        BookseriesId: element,
        LevelIds: levels,
        resourceValues: resourceDataVal,
      };
      resourceDBData.push(obj);
    });
    //console.log(resourceDBData);
    //resourceDBData = [];
    var additionalResData = {
      SchoolYearId: SchoolYearId,
      PreferenceName: "ONBOARDING_ADDITIONAL_RESOURCE",
      PreferenceValue: JSON.stringify(resourceDBData),
    };
    //Additonal Resource End
    const callback = (result) => {
      console.log(result);
      if (result.teacherOnboard == '{"result":"success"}') {
        this.setUpLater(true);
        this.setState({ step: step + 1, submitLoading: false });
      } else {
        this.setState({ submitLoading: false });
        console.log("[submitSelectedOption] error", result);
      }

      //   this.props.history.push("/m/myschool");
    };

    OnBoardingMutation(data, callback);
    var OnboardingData = {
      SchoolYearId: SchoolYearId,
      PreferenceName: "ONBOARDING_SKIP",
      PreferenceValue: "true",
    };
    //UpdateUserPreferencesMutation(OnboardingData, userpreferenceCallback);
    
    const userpreferenceCallback = (result) => {
      console.log("new call" + result);
      var userData = JSON.parse(localStorage.getItem("userData"));
      userData.Preferences = result.modifyUserPreference.Preferences;
      userData.PreferencesWithYear =
        result.modifyUserPreference.PreferencesWithYear;
      localStorage.setItem("userData", JSON.stringify(userData));
    };

    UpdateUserPreferencesMutation(additionalResData, userpreferenceCallback);
  };

  render() {
    const lang = localStorage.getItem("i18nextLng");
    const { t, afterLogout } = this.props;
    console.log(this.state.AdoptedSchoolYear.length);

    console.log(this.props.selectedYear, "selectedtatti");
    const {
      step,
      schoolYearList,
      selectedSchoolYear,
      selectedSchoolYearId,
      openTabStatus,
      isOpen,
      AdoptedBookSeries,
      selectedBookSeries,
      GetBookSeriesStudyGroupLoading,
      GetAdditionalResourceLoading,
      selectedResourceData,
      tempResourceData,
      additionalResourceData,
      resourcePopupData,
      EachBookSeriesGroupByLevel,
      ActiveBookSeriesTab,
      selectedStudyGroup,
      submitLoading,
      AdoptedBookSeriesLoading,
      isComeFromEdit,
      lightBoxData,
      isResourceOpen,
      AdoptedSchoolYear
    } = this.state;
    // if (
    //   !localStorage.getItem("accessToken") ||
    //   !localStorage.getItem("ssoToken") ||
    //   !localStorage.getItem("userData") ||
    //   !localStorage.getItem("SessionId")
    // ) {
    //   return <Redirect to="/" />;
    // }
    // if (localStorage.getItem("role") == "student") {
    //   return <Redirect to="/" />;
    // }
  AdoptedSchoolYear.sort(function (a, b) {
      return a.SchoolYearId - b.SchoolYearId;
    });

  console.log(AdoptedSchoolYear)
    const UserData = JSON.parse(localStorage.getItem("userData"));
    UserData.AdoptedSchoolYear.sort(function (a, b) {
        return a.SchoolYearId - b.SchoolYearId;
      });
    return (
      <div className="be-wrapper be-fixed-sidebar onboarding">
        <NavBar
          afterLogout={afterLogout}
          t={t}
          isAssessmentView={false}
          backTitle={null}
          logoNotClickable={true}
          showRigthButton={true}
        />
        {selectedSchoolYear !== undefined && (
        <div className="be-container">
          {lightBoxData && (
            <MessageLightBox
              title={lightBoxData.title}
              message={lightBoxData.message}
              message2={lightBoxData.message2}
              isOpen={isOpen}
              onConfirm={lightBoxData.onConfirm}
              onClose={lightBoxData.onClose}
              type={lightBoxData.type}
              customButton={lightBoxData.customButton}
              t={t}
            />
          )}
          {submitLoading && <Loading />}
          <div
            className={`main-content container-fluid  ${
              step != 1 && step != 6 ? "started" : "ft-screen"
            }`}
          >
            {step == 1 && (
              <div className="card mb-3 onBoardingCard step1">
                <div className="card-body">
                  <div className="onBoardingTitle">
                    <h4>
                      <p className="card-text-no-title text-center">
                        {t("Welcome!")}
                      </p>
                    </h4>
                  </div>
                  <img
                    className="card-img-top"
                    style={{ marginBottom: "20px" }}
                    src={onBoardingBanner}
                  />
                  <p className="question text-center">
                    {t("Please choose the books or classes you teach.")}
                    <br />
                    {t("You will see the associated resources after set-up.")}
                  </p>
                  <div className="text-center">
                    <button
                      className="btn btn-warning btn-xl"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.onClickAction(step, "next");
                      }}
                    >
                      {t("Start")}
                    </button>
                  </div>
                  {/* <button
                      className="btn setup-later btn-secondary btn-xl"
                      style={{ width: "100%", marginTop:"4px" }}
                    onClick={() => {
                      this.setState({
                        isOpen: true,
                        lightBoxData: {
                          title: t("Setup Later"),
                          message: t(
                            "You need to set up your books and classes in order to use the associated resources."
                          ),
                          message2: t(
                            "You can change your settings later in Manage my books and classes"
                          ),
                          onConfirm: this.setUpLater,
                          onClose: () =>
                            this.setState({
                              isOpen: false,
                              lightBoxData: null,
                            }),
                          type: "messageBox",
                        },
                      });
                    }}
                  >
                    {t("Skip")}
                  </button> */}
                  <br />
                </div>
              </div>
            )}

            {[2, 3, 4, 5].indexOf(step) != -1 && (
              <div className="card mb-3 onBoardingCard step234">
                <div className="card-body">
                  <div className="onBoardingTitle">
                    <div className="step-bar">
                      <div
                        className={`item ${
                          step == 2 ? "active" : step > 2 ? "done" : ""
                        }`}
                      >
                        <div className="circle">
                          {step > 2 ? (
                            <i className="icon mdi mdi-check"></i>
                          ) : (
                            "1"
                          )}
                        </div>
                        <div className="title">{t("Choose Book")}</div>
                      </div>
                      <div className={this.getProgressClass(2, step)}>
                        <div className="progress"></div>
                      </div>

                      <div
                        className={`item ${
                          step == 3 ? "active" : step > 3 ? "done" : ""
                        }`}
                      >
                        <div className="circle">
                          {step > 3 ? (
                            <i className="icon mdi mdi-check"></i>
                          ) : (
                            "2"
                          )}
                        </div>
                        <div className="title">{t("Choose Class")}</div>
                      </div>
                      <div className={this.getProgressClass(3, step)}>
                        <div className="progress"></div>
                      </div>

                      <div
                        className={`item ${
                          step == 4 ? "active" : step > 4 ? "done" : ""
                        }`}
                      >
                        <div className="circle">
                          {step > 4 ? (
                            <i className="icon mdi mdi-check"></i>
                          ) : (
                            "3"
                          )}
                        </div>
                        <div className="title">{t("Additional Resource")}</div>
                      </div>
                      <div className={this.getProgressClass(4, step)}>
                        <div className="progress"></div>
                      </div>
                      <div
                        className={`item ${
                          step == 5 ? "active" : step > 5 ? "done" : ""
                        }`}
                      >
                        <div className="circle">
                          {step > 5 ? (
                            <i className="icon mdi mdi-check"></i>
                          ) : (
                            "4"
                          )}
                        </div>
                        <div className="title">{t("Confirm")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="stepborder"></div>
                  {step == 2 && (
                    <div className="content">
                      <div className="form-group">
                        <label className="form-label">
                          {t("Please select the school year.")}
                        </label>
                        {console.log(selectedSchoolYear)}
                        {schoolYearList && (
                          <select
                            className="form-control"
                            value={selectedSchoolYear}
                            // disabled={isComeFromEdit}
                            onChange={(e) => {
                              this.setState({
                                selectedSchoolYear: e.target.value,
                              });
                              {
                                console.log(e.target.value);
                              }

                              // this.props.dispatch({
                              //   type: "SET_COSTOM_SCHOOL_YEAR",
                              //   selectedSchoolYear:
                              //     UserData.AdoptedSchoolYear[e.target.value],
                              // });
                              this.getSchoolAdoptedBookSeries(
                                UserData.AdoptedSchoolYear.length > 0
                                  ? UserData.AdoptedSchoolYear[e.target.value]
                                      .SchoolYearId
                                  : this.state.schoolYearList[e.target.value]
                                      .SchoolYearId
                              );
                            }}
                          >
                            {this.state.AdoptedSchoolYear.length > 0
                              ? AdoptedSchoolYear.map(
                                  (obj, index) => (
                                    <option
                                      yearid={obj.SchoolYearId}
                                      value={index}
                                      key={index}
                                    >
                                      {console.log(obj, index)}
                                      {obj.SchoolYearName}
                                      {/* {this.props.selectedYear.SchoolYearName} */}
                                    </option>
                                  )
                                )
                              : schoolYearList.map((obj, index) => {
                                  return (
                                    <option
                                      yearid={obj.SchoolYearId}
                                      value={index}
                                      key={index}
                                    >
                                      {obj.SchoolYearName}
                                    </option>
                                  );
                                })}
                          </select>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          {t(
                            "Which books do you use? (You can choose more than one.)​"
                          )}
                        </label>
                        <div className="mt-1">
                          {AdoptedBookSeries &&
                            !AdoptedBookSeriesLoading &&
                            AdoptedBookSeries.map((obj, index) => {
                              return (
                                <label
                                  key={obj.BookSeriesId}
                                  className="custom-control custom-control-sm custom-checkbox"
                                >
                                  <input
                                    id={obj.BookSeriesId}
                                    type="checkbox"
                                    className="custom-control-input"
                                    value={obj.BookSeriesId}
                                    onChange={(e) => this.onCheckBookSeries(e)}
                                    checked={
                                      selectedBookSeries.indexOf(
                                        obj.BookSeriesId
                                      ) != -1
                                        ? true
                                        : false
                                    }
                                  />
                                  <span
                                    className="custom-control-label"
                                    id={obj.BookSeriesId}
                                  />
                                  {
                                    obj.BookSeriesName[
                                      obj.BookSeriesName[0].Lang == lang ? 0 : 1
                                    ].BookSeriesName
                                  }
                                </label>
                              );
                            })}
                          {AdoptedBookSeriesLoading && (
                            <Loading type="normal" />
                          )}
                        </div>
                        <label className="form-label">
                          {t("If none of your books is shown above, please press 'Skip'.")}
                        </label>
                      </div>
                    </div>
                  )}
                  {step == 3 &&
                    (GetBookSeriesStudyGroupLoading ? (
                      <div className="content">
                        <Loading type="normal" />
                      </div>
                    ) : (
                      <div className="content">
                        <div className="form-group">
                          <label className="form-label">
                            <Trans
                              i18nKey="Please select the classes you would teach in"
                              count={1}
                            >
                              {{
                                year:
                                  UserData.AdoptedSchoolYear.length > 0
                                    ? UserData.AdoptedSchoolYear[
                                        selectedSchoolYear
                                      ].SchoolYearName
                                    : schoolYearList[selectedSchoolYear]
                                        .SchoolYearName,
                              }}
                            </Trans>
                          </label>
                          <p>
                            {t(
                              "*Please choose the level(s) and class(es) for a specific book if you use more than one book."
                            )}
                          </p>
                        </div>
                        <div className="bookseries-tab">
                          <ul>
                            {EachBookSeriesGroupByLevel &&
                              EachBookSeriesGroupByLevel.map((obj, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      this.setState({
                                        ActiveBookSeriesTab:
                                          obj.BookSeries.BookSeriesId,
                                      })
                                    }
                                  >
                                    <div
                                      className={`${
                                        ActiveBookSeriesTab.indexOf(
                                          obj.BookSeries.BookSeriesId
                                        ) != -1
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {obj.BookSeries.BookSeriesName[
                                        obj.BookSeries.BookSeriesName[0].Lang ==
                                        lang
                                          ? 0
                                          : 1
                                      ].BookSeriesName +
                                        " (" +
                                        this.countSelectedStudentByBookSeries(
                                          obj.BookSeries.BookSeriesId
                                        ) +
                                        ")"}
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="classes-list">
                          {EachBookSeriesGroupByLevel &&
                            EachBookSeriesGroupByLevel.map((obj, index) => {
                              if (
                                obj.BookSeries.BookSeriesId ==
                                ActiveBookSeriesTab
                              ) {
                                return obj.Level.map((lobj, lindex) => {
                                  return (
                                    <section
                                      id={
                                        lobj.PearsonLevelId +
                                        "_" +
                                        obj.BookSeries.BookSeriesId
                                      }
                                      key={
                                        lobj.PearsonLevelId +
                                        "_" +
                                        obj.BookSeries.BookSeriesId
                                      }
                                      className="level-row"
                                    >
                                      <table>
                                        <thead>
                                          <tr
                                            className="tab"
                                            onClick={() =>
                                              this.onClickTitle(
                                                lobj.PearsonLevelId +
                                                  "_" +
                                                  obj.BookSeries.BookSeriesId
                                              )
                                            }
                                          >
                                            <th className="handle">
                                              <div className="tab">
                                                <i
                                                  className={`icon mdi mdi-chevron-right ${
                                                    openTabStatus.indexOf(
                                                      lobj.PearsonLevelId +
                                                        "_" +
                                                        obj.BookSeries
                                                          .BookSeriesId
                                                    ) != -1
                                                      ? "open"
                                                      : "close"
                                                  }`}
                                                ></i>
                                              </div>
                                            </th>
                                            <th className="level-title">
                                              <label className="custom-control custom-control-sm custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id={
                                                    lobj.PearsonLevelId +
                                                    "_" +
                                                    obj.BookSeries
                                                      .BookSeriesId +
                                                    "_checkbox"
                                                  }
                                                  value={lobj.PearsonLevelId}
                                                  checked={this.checkLevelIsChecked(
                                                    lobj.PearsonLevelId,
                                                    selectedStudyGroup
                                                  )}
                                                  onChange={(e) =>
                                                    this.onCheckLevel(
                                                      e,
                                                      lobj.PearsonLevelId
                                                    )
                                                  }
                                                />
                                                <span
                                                  className="custom-control-label"
                                                  id="checkC2"
                                                />
                                                {
                                                  lobj.LevelName[
                                                    lobj.LevelName[0].Lang ==
                                                    lang
                                                      ? 0
                                                      : 1
                                                  ].LevelName
                                                }
                                              </label>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          // style="display: table-row-group;"
                                          style={{
                                            display: `${
                                              openTabStatus.indexOf(
                                                lobj.PearsonLevelId +
                                                  "_" +
                                                  obj.BookSeries.BookSeriesId
                                              ) != -1
                                                ? "table-row-group"
                                                : "none"
                                            }`,
                                          }}
                                        >
                                          <tr>
                                            <td colSpan={2}>
                                              <div className="class-row">
                                                {lobj.StudyGroups.map(
                                                  (sobj, sindex) => {
                                                    return (
                                                      <div
                                                        className="form-check form-check-inline"
                                                        key={sobj.StudyGroupId}
                                                      >
                                                        <label className="custom-control custom-control-sm custom-checkbox">
                                                          <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            value={
                                                              sobj.StudyGroupId
                                                            }
                                                            checked={
                                                              selectedStudyGroup.indexOf(
                                                                sobj.StudyGroupId
                                                              ) != -1
                                                                ? true
                                                                : false
                                                            }
                                                            onChange={(e) =>
                                                              this.onCheckStudyGroup(
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <span
                                                            className="custom-control-label"
                                                            id="checkC2"
                                                          />
                                                          <Trans
                                                            i18nKey="ClassTitle"
                                                            count={1}
                                                          >
                                                            {{
                                                              class:
                                                                sobj
                                                                  .StudyGroupName[
                                                                  sobj
                                                                    .StudyGroupName[0]
                                                                    .Lang ==
                                                                  lang
                                                                    ? 0
                                                                    : 1
                                                                ]
                                                                  .StudyGroupName,
                                                            }}
                                                          </Trans>
                                                        </label>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </section>
                                  );
                                });
                              }
                            })}
                        </div>
                      </div>
                    ))}
                  {step == 4 &&
                    (GetAdditionalResourceLoading ? (
                      <div className="content">
                        <Loading type="normal" />
                      </div>
                    ) : (
                      <div className="content">
                        <div className="form-group">
                          <label className="form-label">
                            {/* <Trans
                              i18nKey="Please select your additional resource for"
                              count={1}
                            >
                              {{
                                year: schoolYearList[selectedSchoolYear]
                                  .SchoolYearName,
                              }}
                            </Trans> */}
                            {t("You can add resources for other level(s).")}
                          </label>
                        </div>

                        <div className="finial-result-list">
                          {this.renderAdditionalResource()}

                          <button
                            disabled={this.checkPopup() ? false : true}
                            className={`btn btn-secondary additonalResBtn ${
                              this.checkPopup() ? "" : "disabledBtn"
                            }`}
                            onClick={() => {
                              if (this.checkPopup()) {
                                var userData = JSON.parse(
                                  localStorage.getItem("userData")
                                );
                                gaTrack(
                                  "send",
                                  "event",
                                  "Onboarding",
                                  "Select additional resource",
                                  "Role:" + userData.UserType
                                );

                                var mainSelection = [...selectedResourceData];
                                this.setState({
                                  isResourceOpen: !isResourceOpen,
                                  tempResourceData: mainSelection,
                                });
                              }
                            }}
                          >
                            {t("Select additonal resources ...")}
                          </button>
                          {!this.checkPopup() && (
                            <p>
                              <span className="redcolor">*</span>
                              {t(
                                "You have selected all resources. Click ‘Next’.​"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  {step == 5 && (
                    <div className="content">
                      <div className="form-group">
                        <label className="form-label">
                          <Trans
                            i18nKey="Here are list of your class for"
                            count={1}
                          >
                            {{
                              year:
                              UserData.AdoptedSchoolYear.length > 0
                                ? UserData.AdoptedSchoolYear[
                                    selectedSchoolYear
                                  ].SchoolYearName
                                : schoolYearList[selectedSchoolYear]
                                    .SchoolYearName,
                            }}
                          </Trans>
                        </label>
                        <p>
                          {t(
                            "*You can manage the books, classes and resources that you use anytime in 'Manage my books and classes'"
                          )}
                        </p>
                      </div>
                      <div className="finial-result-list">
                        {this.renderSelectedStudyGroupGroupByBookSeries()}
                      </div>
                      {this.checkAdditionalResource() && (
                        <div>
                          <div className="form-group">
                            <label className="form-label">
                              <Trans
                                i18nKey="Your additional resources for"
                                count={1}
                              >
                                {{
                              year:
                              UserData.AdoptedSchoolYear.length > 0
                                ? UserData.AdoptedSchoolYear[
                                    selectedSchoolYear
                                  ].SchoolYearName
                                : schoolYearList[selectedSchoolYear]
                                    .SchoolYearName,
                            }}
                              </Trans>
                            </label>
                          </div>
                          <div className="finial-result-list">
                            {this.renderAdditionalResource()}
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  )}

                  {[2, 3, 4, 5].indexOf(step) != -1 && (
                    <div className="bottom">
                      {[3, 4, 5].indexOf(step) != -1 && (
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            this.onClickAction(step, "back");
                          }}
                        >
                          {"< " + t("Back")}
                        </button>
                      )}
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickAction(step, "next");
                        }}
                        // disabled={
                        //   step == 2
                        //     ? selectedBookSeries.length == 0
                        //       ? true
                        //       : false
                        //     : false
                        // }
                      >
                        {step == 5 ? t("Confirm") : t("Next") + " >"}
                      </button>
                      <button
                        className="setup-later btn btn-secondary"
                        style={{ float: "right" }}
                        onClick={() =>
                          this.setState({
                            isOpen: true,
                            lightBoxData: {
                              title: t("Setup Later"),
                              message: t(
                                "You need to set up your books and classes in order to use the associated resources."
                              ),
                              message2: t(
                                "You can change your settings later in Manage my books and classes"
                              ),
                              onConfirm: this.setUpLater,
                              onClose: () =>
                                this.setState({
                                  isOpen: false,
                                  lightBoxData: null,
                                }),
                              type: "messageBox",
                            },
                          })
                        }
                      >
                        {t("Skip")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {step == 6 && (
              <div className="card mb-3 onBoardingCard finish">
                <div className="card-body">
                  <div className="onBoardingTitle">
                    <h4>
                      <p className="card-text-no-title text-center">
                        {t("Setup is completed!")}
                      </p>
                    </h4>
                  </div>
                  <img
                    className="card-img-top"
                    style={{ marginBottom: "20px" }}
                    src={onBoardingFinishBanner}
                  />
                  <p className="question text-center">
                    {t("Please click 'Finish' to explore our platform.")}
                  </p>
                  <div className="text-center">
                    <button
                      className="btn btn-primary btn-xl"
                      style={{ width: "100%" }}
                      onClick={() => {
                        console.log(EachBookSeriesGroupByLevel);
                        var bookseriesString = "",
                          studyGroupString = "";
                        var count = 0;
                        EachBookSeriesGroupByLevel.map((eobj, index) => {
                          if (index == 0) {
                            bookseriesString =
                              bookseriesString +
                              eobj.BookSeries.BookSeriesName[0].BookSeriesName;
                          } else {
                            bookseriesString =
                              bookseriesString +
                              "|" +
                              eobj.BookSeries.BookSeriesName[0].BookSeriesName;
                          }

                          eobj.Level.map((lobj, lindex) => {
                            lobj.StudyGroups.map((sobj, sindex) => {
                              if (
                                selectedStudyGroup.indexOf(sobj.StudyGroupId) !=
                                -1
                              ) {
                                if (count == 0) {
                                  count = count + 1;

                                  studyGroupString =
                                    studyGroupString +
                                    sobj.StudyGroupName[0].StudyGroupName;
                                } else {
                                  studyGroupString =
                                    studyGroupString +
                                    "|" +
                                    sobj.StudyGroupName[
                                      sobj.StudyGroupName[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StudyGroupName;
                                }
                              }
                            });
                          });
                        });
                        console.log(bookseriesString, studyGroupString);
                        gaTrack(
                          "send",
                          "event",
                          "Onboarding",
                          "Setup Finish",
                          "BookSeries:[" +
                            bookseriesString +
                            "], Selected Class:[" +
                            studyGroupString +
                            "], Role:" +
                            UserData.UserType
                        );

                        this.onClickAction(step);
                        // this.setState({ step: 2 });
                      }}
                    >
                      {t("Finish")}
                    </button>
                  </div>

                  <br />
                </div>
              </div>
            )}
          </div>
          <Footer />
          <MessageLightBox
            isOpen={isResourceOpen}
            type={"AdditionalResource"}
            resourceData={resourcePopupData}
            selectedResourceData={selectedResourceData}
            checkfun={(val) => {
              console.log(tempResourceData);
              console.log(val);
              var tindex = tempResourceData.findIndex(
                (x) => x.resourceData === val
              );

              if (tindex != -1) {
                return true;
              }
              return false;
            }}
            okBtn={() => {
              var userData = JSON.parse(localStorage.getItem("userData"));
              // console.log(tempResourceData);
              // console.log(selectedResourceData);
              var selectedResbk = [...tempResourceData];

              var onlyBookValus = [];

              for (var i = 0; i < selectedResbk.length; i++) {
                onlyBookValus.push(selectedResbk[i].resourceData);
              }

              var bookst = onlyBookValus.join();
              var books = bookst.replace(/,/g, "|");
              gaTrack(
                "send",
                "event",
                "Onboarding",
                "Confirm additonal resource",
                "Book:[" + books + "], Role:" + userData.UserType
              );
              this.setState({
                selectedResourceData: selectedResbk,
                currentSelectedResourceData: selectedResbk,
                tempResourceData: [],
                isResourceOpen: !isResourceOpen,
              });
            }}
            changefun={(e) => {
              
              var tempDataArr = [...tempResourceData];
              var str = e.target.value;
              var res = str.split("*");
              if (e.target.checked) {
                var obj = {
                  bookSeriesId: res[0],
                  levelId: res[1],
                  resourceData: res[2],
                };
                tempDataArr.push(obj);
              } else {
                var tindex = tempDataArr.findIndex(
                  (x) => x.resourceData === res[2]
                );
                if (tindex != -1) {
                  tempDataArr.splice(tindex, 1);
                }
              }
              console.log(tempDataArr);
              this.setState({ tempResourceData: tempDataArr });
            }}
            t={t}
            onClose={() => {
              this.setState({
                isResourceOpen: !isResourceOpen,
              });
            }}
          />
        </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedYear: state.SystemReducer.selectedSchoolYear,
});

export default connect(mapStateToProps)(index);
