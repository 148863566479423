/**
 * @generated SignedSource<<9c8d0aa6181269716255def02ccca098>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateAssignmentMutation$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  BookSeriesId: string,
  UserId?: ?$ReadOnlyArray<?string>,
  SchoolYearId?: ?string,
  StudyGroupId?: ?$ReadOnlyArray<?string>,
  AssignmentName: string,
  AssignmentDescription?: ?string,
  DueDate?: ?string,
  AllowRedo?: ?boolean,
|};
export type CreateAssignmentMutation$data = {|
  +createAssignment: ?{|
    +ResultCode: ?number,
    +ResultDescription: ?string,
  |},
|};
export type CreateAssignmentMutation = {|
  variables: CreateAssignmentMutation$variables,
  response: CreateAssignmentMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "AllowRedo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "AssignmentDescription"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "AssignmentName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "DueDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "AllowRedo",
        "variableName": "AllowRedo"
      },
      {
        "kind": "Variable",
        "name": "AssignmentDescription",
        "variableName": "AssignmentDescription"
      },
      {
        "kind": "Variable",
        "name": "AssignmentName",
        "variableName": "AssignmentName"
      },
      {
        "kind": "Variable",
        "name": "BookSeriesId",
        "variableName": "BookSeriesId"
      },
      {
        "kind": "Variable",
        "name": "DueDate",
        "variableName": "DueDate"
      },
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupId",
        "variableName": "StudyGroupId"
      },
      {
        "kind": "Variable",
        "name": "UserId",
        "variableName": "UserId"
      }
    ],
    "concreteType": "CreateAssignmentResultType",
    "kind": "LinkedField",
    "name": "createAssignment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ResultCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ResultDescription",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAssignmentMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateAssignmentMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "fe1f2f84666bd3ba997ed71c9458b266",
    "id": null,
    "metadata": {},
    "name": "CreateAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAssignmentMutation(\n  $ResourceId: [String]!\n  $BookSeriesId: String!\n  $UserId: [String]\n  $SchoolYearId: String\n  $StudyGroupId: [String]\n  $AssignmentName: String!\n  $AssignmentDescription: String\n  $DueDate: String\n  $AllowRedo: Boolean\n) {\n  createAssignment(ResourceId: $ResourceId, BookSeriesId: $BookSeriesId, UserId: $UserId, SchoolYearId: $SchoolYearId, StudyGroupId: $StudyGroupId, AssignmentName: $AssignmentName, AssignmentDescription: $AssignmentDescription, DueDate: $DueDate, AllowRedo: $AllowRedo) {\n    ResultCode\n    ResultDescription\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4e213ad8844f7a17aa25ddcfbfeb5e84";

module.exports = ((node/*: any*/)/*: Mutation<
  CreateAssignmentMutation$variables,
  CreateAssignmentMutation$data,
>*/);
