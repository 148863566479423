import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import "./ViewStudentAttemptHistoryPopUp.scss";
import sorting_arrow_black from "../../../assets/img/pec/all-images/sorting-arrow.png";
import sorting_arrow_black_asc from "../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc.png";
import sorting_arrow_black_desc from "../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc.png";
import tickIcon from "../../../assets/img/pec/all-images/icon-tick.svg"
import Loading from "../../../Components/Loading/PEC/Loading";
const ViewStudentAttemptHistoryPopUp = (props) => {
  const {
    setShowAttempt,
    showAttempt,
    attemptStudentInfo,
    classListId,
    resourceNamePop,
    selectedClass,
    t,
  } = props;

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('desc');
  const [studentData, setStudentData] = useState([]);
  const [studentLoading, setStudentLoading] = useState(false)
  const [studentDataOld, setStudentDataOld] = useState([
    {id: 1, name: 'Student 3A01', isViewed: true},
    {id: 2, name: 'Student 3A02', isViewed: false},
    {id: 3, name: 'Student 3A03', isViewed: true},
    {id: 4, name: 'Student 3A04', isViewed: false},
    {id: 5, name: 'Student 3A05', isViewed: true},
    {id: 6, name: 'Student 3A06', isViewed: true},
    {id: 7, name: 'Student 3A07', isViewed: false},
    {id: 8, name: 'Student 3A08', isViewed: true},
    {id: 9, name: 'Student 3A09', isViewed: true},
    {id: 10, name: 'Student 3A10', isViewed: false},
    {id: 11, name: 'Student 3A11', isViewed: true},
    {id: 12, name: 'Student 3A12', isViewed: false},
    {id: 13, name: 'Student 3A13', isViewed: true},
    {id: 14, name: 'Student 3A14', isViewed: false},
    {id: 15, name: 'Student 3A15', isViewed: true},
    {id: 16, name: 'Student 3A16', isViewed: true},
    {id: 17, name: 'Student 3A17', isViewed: false},
    {id: 18, name: 'Student 3A018', isViewed: false},
    {id: 19, name: 'Student 3A19', isViewed: true},
    {id: 20, name: 'Student 3A20', isViewed: true},
  ])


  const ViewStudentAttemptHistoryPopUpGetStudentReportQuery = graphql`
    query ViewStudentAttemptHistoryPopUpGetStudentReportQuery(
      $StudyGroupId: String!
      $SchoolYearId: String!
    ) {
      studyGroup(StudyGroupId: $StudyGroupId) {
        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
          ClassNumber
          User {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
          }
        }
      }
      
    }
  `;

  const getStudentList = () => {
    var variables = {
      StudyGroupId: classListId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
    };
    return fetchQuery(
      environment,
      ViewStudentAttemptHistoryPopUpGetStudentReportQuery,
      variables
    );
  };
  useEffect(() => {
    setStudentLoading(true)
    getStudentList().then((data) => {
      
      const {
        studyGroup: { StudyGroupUser = [{}] },
      } = data || {};

      var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
      var studentViewedData = []
      if (StudyGroupUserTemp.length > 0) {
        StudyGroupUserTemp.map((obj, index) => {
          var matchId = attemptStudentInfo
            .map((i) => {
              return i.UserId;
            })
            .indexOf(obj.User.UserId);
          if (matchId != -1) {
           var studentObj = {"id":  obj.ClassNumber, "name": obj.User.UserDisplayName[0].UserName, "isViewed": true}
           studentViewedData.push(studentObj);
          } else {
            var studentObj = {"id":  obj.ClassNumber, "name": obj.User.UserDisplayName[0].UserName, "isViewed": false}
            studentViewedData.push(studentObj);
            
          }
        });
      }
      console.log("yup",studentViewedData)
      setStudentLoading(false)
      setStudentData(studentViewedData)
      console.log("good", StudyGroupUserTemp)

    });
    const pageClickEvent = (e) => {
      if (
        notification_popupref.current !== null &&
        !notification_popupref.current.contains(e.target)
      ) {
        setShowAttempt(false);
      }
    };
    if (showAttempt) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showAttempt]);
  const notification_popupref = useRef(null);
  const [notifyStudent, setNotifyStudent] = useState(false);

  const validateNotifyStudents = (e) => {
    if (e.target.checked) {
      setNotifyStudent(true);
    } else {
      setNotifyStudent(false);
    }
  };

  return (
    <div className="view_student_attempt__popup-overlay">
      <div className="view_student_attempt__popup" ref={notification_popupref}>
        <div className="view_student_attempt__popup-top-section">
        <label
          className="view_student_attempt__popup-close-popup"
          onClick={() => setShowAttempt(false)}
        >
          &nbsp;
        </label>
        <h3 className="view_student_attempt__popup-heading"> {resourceNamePop}</h3>
        </div>
        <h4 className="view_student_attempt__popup-heading2"> {selectedClass}</h4>
        {studentLoading ? <Loading type={`normal`}/> : 
        <table class="main__section-student-attemp-details">
          <thead>
            <tr class="main__section-student-attemp-details-bottom-row">
              <th class="main__section-student-attemp-details-bottomheading-primary main__section-student-attemp-details-bottomheading-primary-width"
                onClick={() => {
                  setSortColumn('No')
                  const sortedData = studentData.sort((a,b) => sortOrder=='asc' ? b.id - a.id : a.id - b.id);
                  setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                  setStudentData(sortedData)
                }}>{t("No")} <img
                  src={sortColumn != 'No' ? sorting_arrow_black : sortOrder == 'asc' ? sorting_arrow_black_asc : sorting_arrow_black_desc}
                  alt="sorting"
                  className="table-sorting-arrow table-sorting-arrow-attempt-history"
                />
              </th>
              <th class="main__section-student-attemp-details-bottomheading-primary" onClick={() => {
                setSortColumn('Student Name')
                const sortedData = studentData.sort(function(a, b){                   
                  if(sortOrder=='asc'){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                  } else {
                    if(a.name < b.name) { return 1; }
                    if(a.name > b.name) { return -1; }
                    return 0;
                  }
                })
                setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                setStudentData(sortedData)
              }}>{t("Student Name")}<img
                  src={sortColumn != 'Student Name' ? sorting_arrow_black : sortOrder == 'asc' ? sorting_arrow_black_asc : sorting_arrow_black_desc}
                  alt="sorting"
                  className="table-sorting-arrow table-sorting-arrow-attempt-history"
                /></th>
              <th class="main__section-student-attemp-details-bottomheading-primary main__section-student-attemp-details-bottomheading-primary-width" onClick={() => {
                setSortColumn('Viewed')
                const sortedData = studentData.sort(function(a, b){                   
                  if(sortOrder=='asc'){
                    if(a.isViewed < b.isViewed) { return -1; }
                    if(a.isViewed > b.isViewed) { return 1; }
                    return 0;
                  } else {
                    if(a.isViewed < b.isViewed) { return 1; }
                    if(a.isViewed > b.isViewed) { return -1; }
                    return 0;
                  }
                })
                setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                setStudentData(sortedData)
              }}>{t("Viewed")}<img
                  src={sortColumn != 'Viewed' ? sorting_arrow_black : sortOrder == 'asc' ? sorting_arrow_black_asc : sorting_arrow_black_desc}
                  alt="sorting"
                  className="table-sorting-arrow table-sorting-arrow-attempt-history"
                /></th>
            </tr>
          </thead>
          {
            studentData.map(data=>{
              return <tr class="main__section-student-attemp-details-down-row">
              <td class="main__section-student-attemp-details-data">{data.id}</td>
              <td class="main__section-student-attemp-details-data">{data.name}</td>
              <td class="main__section-student-attemp-details-data">
                {data.isViewed && <div className="view_student_attempt__popup-img"><img
                alt="logo Image"
                className="main__section-unit-grid-tick-image"
                src={tickIcon}
              /></div>}
              </td>
            </tr>
            })
          }
        </table>
        } 
      </div>
    </div>
  );
};

export default ViewStudentAttemptHistoryPopUp;
