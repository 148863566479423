const CryptoJS = require("crypto-js");

const useEncryption = () => {

    const SECRET_KEY = CryptoJS.enc.Utf8.parse('en423csj2g80snv6');
    const DELIMITER = '|=|';

    const encrypt = (password) => {
        let date = new Date();
        date.setMinutes(0, 0, 0);
        const timestamp = date.getTime();
        const passwordStr = CryptoJS.enc.Utf8.parse('PecHK' + DELIMITER+ password + DELIMITER + password.slice(-5) + DELIMITER + timestamp);
        const ciphertext = CryptoJS.AES.encrypt(passwordStr, SECRET_KEY, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7})
        return ciphertext.toString();
    }

    const encryptToLocal = (password) => {
        const passwordStr = CryptoJS.enc.Utf8.parse(password);
        const ciphertext = CryptoJS.AES.encrypt(passwordStr, SECRET_KEY, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7})
        return ciphertext.toString();
    }

    const decryptToLocal = (password) => {
        const decrypted = CryptoJS.AES.decrypt(password, SECRET_KEY, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7})
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    return {encrypt, encryptToLocal, decryptToLocal};
}
export default useEncryption;