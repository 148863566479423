import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import moment from "moment";
import Loading from "../Loading/Loading";
import { Trans } from "react-i18next";
import "./AdditionalResource.scss";

export default (props) => {
  console.log(props);
  const { t, resourceData, changefun, checkfun, selectedResourceData } = props;
  const selectedSchoolYear = localStorage.getItem("selectedSchoolYear");

  const role = localStorage.getItem("role");
  const lang = localStorage.getItem("i18nextLng");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const checkResource = (val, resources) => {
    return resources.includes(val);
  };
  const checkResource1 = () => {
    return true;
  };

  return (
    <section className="student-list">
      {loading == true ? (
        <Loading type="normal" />
      ) : (
        <table className="table resource-list-table">
          <thead>
            <tr>
              <th className="book-name-label">{t("Book")}</th>
            </tr>
          </thead>
          <tbody>
            {resourceData.map((rec, index) => {
              return (
                <tr key={index}>
                  <td className="book-name">
                    <div className="form-check form-check-inline" key={index}>
                      <label className="custom-control  custom-control-sm custom-checkbox additionalres ">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          value={
                            rec.bookSeriesId +
                            "*" +
                            rec.levelId +
                            "*" +
                            rec.resourceData
                          }
                          checked={checkfun(rec.resourceData)}
                          onChange={(e) => {
                            changefun(e);
                          }}
                        />
                        <span
                          className="custom-control-label "
                          id="checkC9"
                        ></span>
                        {rec.resourceData}
                      </label>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
};
