import {
  isMobile,
  isBrowser,
  isTablet,
  isMobileOnly,
  osName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera,
} from "react-device-detect";

export const DeviceChecking = () => {
  if (
    !window.cordova &&
    (isBrowser ||
      isChrome ||
      isFirefox ||
      isSafari ||
      isOpera ||
      isIE ||
      isEdge ||
      navigator.userAgent.match(
        /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
      ))
  ) {
    return "WEB";
  }
  if (window.cordova) {
    if (isAndroid) {
      return "ANDROID_APP";
    }
    if (isIOS) {
      return "IOS_APP";
    }
  }
  return "WEB";
};
