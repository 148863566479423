/**
 * @generated SignedSource<<ac2312871c92be6938f11f7a9fdafb5c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ActivityLayoutGetAssessmentNameTeacherQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  UserId: string,
  BookSeriesId: string,
  BookId?: ?string,
  UnitId?: ?string,
|};
export type ActivityLayoutGetAssessmentNameTeacherQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +PearsonLevel: ?{|
          +LevelName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +LevelName: ?string,
          |}>,
        |},
        +Units: ?$ReadOnlyArray<?{|
          +UnitId: ?string,
          +UnitName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +UnitName: ?string,
            +UnitTitle: ?string,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +RelatedId: ?string,
      +ResourceId: ?string,
      +IngestionId: ?string,
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +ResourceName: ?string,
      |}>,
    |}>,
  |},
|};
export type ActivityLayoutGetAssessmentNameTeacherQuery = {|
  variables: ActivityLayoutGetAssessmentNameTeacherQuery$variables,
  response: ActivityLayoutGetAssessmentNameTeacherQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UnitId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "kind": "LinkedField",
  "name": "LevelName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LevelName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "UnitId",
    "variableName": "UnitId"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UnitId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "unitLang",
  "kind": "LinkedField",
  "name": "UnitName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UnitName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UnitTitle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityLayoutGetAssessmentNameTeacherQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "PearsonLevel",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v14/*: any*/),
                    "concreteType": "unit",
                    "kind": "LinkedField",
                    "name": "Units",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActivityLayoutGetAssessmentNameTeacherQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "PearsonLevel",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v14/*: any*/),
                    "concreteType": "unit",
                    "kind": "LinkedField",
                    "name": "Units",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a1d4da0f70285979bc184e9af7cf88e",
    "id": null,
    "metadata": {},
    "name": "ActivityLayoutGetAssessmentNameTeacherQuery",
    "operationKind": "query",
    "text": "query ActivityLayoutGetAssessmentNameTeacherQuery(\n  $ResourceId: [String]!\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String\n  $UnitId: String\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesId\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      Books(BookId: $BookId) {\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        PearsonLevel {\n          LevelName {\n            Lang\n            LevelName\n          }\n          id\n        }\n        Units(UnitId: $UnitId) {\n          UnitId\n          UnitName {\n            Lang\n            UnitName\n            UnitTitle\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      RelatedId\n      ResourceId\n      IngestionId\n      ResourceLangs {\n        Lang\n        ResourceName\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f2194f6e7bef0dcdbfc9c837bf88bb1b";

module.exports = ((node/*: any*/)/*: Query<
  ActivityLayoutGetAssessmentNameTeacherQuery$variables,
  ActivityLayoutGetAssessmentNameTeacherQuery$data,
>*/);
