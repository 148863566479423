import React from "react";
import Loading from "../../../Loading/Loading";
import { Trans } from "react-i18next";
import "./index.scss";
import ViewAssignmentByStudents from "../../../NavBar/Dashboard/ViewAssignmentByStudents.js";
import ViewAssignmentResourceByStudents from "../../../NavBar/Dashboard/ViewAssignmentResourceByStudents.js";
export default (props) => {
  const {
    isOpen,
    onClose,
    assignmentName,
    resourceId,
    resourceAssignId,
    resourceName,
    t,
  } = props;

  return (
    <div id="assignment_views" className="fullscreen_modal_container">
      <div className="modal-header assignment-student-head">
        <button
          type="button"
          className="react-modal-close normal"
          title="Close"
          onClick={() => onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1"
            viewBox="0 0 24 24"
          >
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
          </svg>
        </button>
        <div className="asignment-title">
          {resourceId ? (
            <Trans i18nKey="student viewed resource" count={2}>
              {{
                resourceName: resourceName,
                assignmentName: assignmentName,
              }}
            </Trans>
          ) : (
            <p>
              {t("Student have read")} {assignmentName}
            </p>
          )}
        </div>
      </div>
      <div className="modal-body assignment-student-list border-top border-bottom">
        {resourceId ? (
          <ViewAssignmentResourceByStudents
            t={t}
            resourceId={resourceId}
            resourceAssignId={resourceAssignId}
          />
        ) : (
          <ViewAssignmentByStudents t={t} resourceAssignId={resourceAssignId} />
        )}
      </div>
      <div className="modal-footer">
        <div className="modal-btn-right">
          <button
            // style={{ marginTop: "20px" }}
            className="btn btn-primary"
            onClick={() => onClose()}
          >
            {t("Close")}
          </button>
        </div>
      </div>
    </div>
  );
};
