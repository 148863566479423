/**
 * @generated SignedSource<<c378a3b965ee1035334a26dd01c1bc5a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type SelectStudentGetStudyGroupClassQuery$variables = {|
  SchoolCode: string,
  SchoolYearId: string,
  BookSeriesId?: ?$ReadOnlyArray<?string>,
  FilterOnboarded: boolean,
  Keywords?: ?$ReadOnlyArray<?string>,
|};
export type SelectStudentGetStudyGroupClassQuery$data = {|
  +school: ?{|
    +Schools: ?$ReadOnlyArray<?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
      |}>,
    |}>,
  |},
|};
export type SelectStudentGetStudyGroupClassQuery = {|
  variables: SelectStudentGetStudyGroupClassQuery$variables,
  response: SelectStudentGetStudyGroupClassQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "FilterOnboarded"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Keywords"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "SchoolCode",
    "variableName": "SchoolCode"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  },
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded"
  },
  {
    "kind": "Variable",
    "name": "Keyword",
    "variableName": "Keywords"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectStudentGetStudyGroupClassQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "schoolSearchResult",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "Schools",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectStudentGetStudyGroupClassQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "schoolSearchResult",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "Schools",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a28479cd7a298c909affd9278f573995",
    "id": null,
    "metadata": {},
    "name": "SelectStudentGetStudyGroupClassQuery",
    "operationKind": "query",
    "text": "query SelectStudentGetStudyGroupClassQuery(\n  $SchoolCode: String!\n  $SchoolYearId: String!\n  $BookSeriesId: [String]\n  $FilterOnboarded: Boolean!\n  $Keywords: [String]\n) {\n  school(SchoolCode: $SchoolCode) {\n    Schools {\n      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded, Keyword: $Keywords) {\n        StudyGroupId\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5630997e603c1fad0b030f95a70ac955";

module.exports = ((node/*: any*/)/*: Query<
  SelectStudentGetStudyGroupClassQuery$variables,
  SelectStudentGetStudyGroupClassQuery$data,
>*/);
