import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";
import banner from "../../assets/img/B2C/banner-green.jpg";
import Loading from "../../Components/Loading/Loading";
import NavBar from "../../Components/NavBar/Dashboard/NavBar";
import "./indexsupp.scss";
import Footer from "../../Components/Footer/Footer";
import B2CContent from "./B2CContent";
function ReportLayout(props) {
  const { t, B2C } = props;
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [navBarTitle, setnavBarTitle] = useState([
    { Lang: "en-US", pageTitle: "" },
    { Lang: "zh-HK", pageTitle: "" },
  ]);
  const [backPath, setbackPath] = useState(null);
  const [backTitle, setbackTitle] = useState(null);
  useEffect(() => {
    dispatch({
      type: "SET_LAYOUT",
      info: {
        B2C: B2C,
      },
      layout: "supplementary-report",
    });
  }, []);

  return (
    <div className="be-wrapper be-fixed-sidebar pop_up_view">
      <NavBar
        afterLogout={afterLogout}
        t={t}
        pageTitle={navBarTitle}
        backTitle={backTitle}
        backPath={backPath}
      />
      <div className={"b2c theme_9394"} id="report_body">
        {/* banner */}
        <B2CContent
          {...props}
          t={t}
          // setPageTitle={setPageTitle}
          // pageTitle={navBarTitle}
          B2C={true}
        />
      </div>
    </div>
  );
}

export default ReportLayout;
