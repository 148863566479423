/**
 * @generated SignedSource<<5f3c37c96c601bc2eeca4f4ae4806eb0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UserTypeEnumType = "T" | "S" | "P" | "%future added value";
export type SelectStudentGetStudyGroupUserQuery$variables = {|
  SchoolCode: string,
  SchoolYearId: string,
  BookSeriesId?: ?$ReadOnlyArray<?string>,
  FilterOnboarded: boolean,
  UserType: UserTypeEnumType,
  Keyword?: ?string,
|};
export type SelectStudentGetStudyGroupUserQuery$data = {|
  +school: ?{|
    +Schools: ?$ReadOnlyArray<?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
        +StudyGroupUser: ?$ReadOnlyArray<?{|
          +UserId: ?string,
          +ClassNumber: ?string,
          +User: ?{|
            +UserName: ?string,
          |},
          +StudyGroupId: ?string,
        |}>,
      |}>,
    |}>,
  |},
|};
export type SelectStudentGetStudyGroupUserQuery = {|
  variables: SelectStudentGetStudyGroupUserQuery$variables,
  response: SelectStudentGetStudyGroupUserQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "FilterOnboarded"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Keyword"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserType"
},
v6 = [
  {
    "kind": "Variable",
    "name": "SchoolCode",
    "variableName": "SchoolCode"
  }
],
v7 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId"
},
v8 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  },
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded"
  },
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "Keyword",
    "variableName": "Keyword"
  },
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "UserType",
    "variableName": "UserType"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ClassNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectStudentGetStudyGroupUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "schoolSearchResult",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "Schools",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "kind": "LinkedField",
                    "name": "StudyGroupUser",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "User",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectStudentGetStudyGroupUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "schoolSearchResult",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "Schools",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "studyGroupUserCustomType",
                    "kind": "LinkedField",
                    "name": "StudyGroupUser",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "User",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e47b8e4a7e0bae6e455ce83b67b34184",
    "id": null,
    "metadata": {},
    "name": "SelectStudentGetStudyGroupUserQuery",
    "operationKind": "query",
    "text": "query SelectStudentGetStudyGroupUserQuery(\n  $SchoolCode: String!\n  $SchoolYearId: String!\n  $BookSeriesId: [String]\n  $FilterOnboarded: Boolean!\n  $UserType: UserTypeEnumType!\n  $Keyword: String\n) {\n  school(SchoolCode: $SchoolCode) {\n    Schools {\n      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType, Keyword: $Keyword) {\n          UserId\n          ClassNumber\n          User {\n            UserName\n            id\n          }\n          StudyGroupId\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0815330ab874bfb7c4d63c78eb91fcb8";

module.exports = ((node/*: any*/)/*: Query<
  SelectStudentGetStudyGroupUserQuery$variables,
  SelectStudentGetStudyGroupUserQuery$data,
>*/);
