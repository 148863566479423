/**
 * @generated SignedSource<<c6d17688ceabbbdd087153599a26ada4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GetSmartClassTokenQuery$variables = {||};
export type GetSmartClassTokenQuery$data = {|
  +smartClassSSOToken: ?string,
|};
export type GetSmartClassTokenQuery = {|
  variables: GetSmartClassTokenQuery$variables,
  response: GetSmartClassTokenQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "smartClassSSOToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetSmartClassTokenQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetSmartClassTokenQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e837b48d8a0a6db73d255ac07924c008",
    "id": null,
    "metadata": {},
    "name": "GetSmartClassTokenQuery",
    "operationKind": "query",
    "text": "query GetSmartClassTokenQuery {\n  smartClassSSOToken\n}\n"
  }
};
})();

(node/*: any*/).hash = "79e431607f3ec5e605c279781dfdd5fd";

module.exports = ((node/*: any*/)/*: Query<
  GetSmartClassTokenQuery$variables,
  GetSmartClassTokenQuery$data,
>*/);
