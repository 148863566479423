/**
 * @generated SignedSource<<9181cbef3e5936f6010c3f07d791c1d6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserPreferencesMutation$variables = {|
  SchoolYearId: string,
  PreferenceName: string,
  PreferenceValue: string,
|};
export type UpdateUserPreferencesMutation$data = {|
  +modifyUserPreference: ?{|
    +UserId: ?string,
    +Preferences: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
    +PreferencesWithYear: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
  |},
|};
export type UpdateUserPreferencesMutation = {|
  variables: UpdateUserPreferencesMutation$variables,
  response: UpdateUserPreferencesMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "PreferenceName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "PreferenceValue"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v3 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "PreferenceName",
    "variableName": "PreferenceName"
  },
  {
    "kind": "Variable",
    "name": "PreferenceValue",
    "variableName": "PreferenceValue"
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PreferenceName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PreferenceValue",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearId",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = [
  (v3/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserPreferencesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "modifyUserPreference",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": "PreferencesWithYear",
            "args": (v10/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateUserPreferencesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "modifyUserPreference",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": "PreferencesWithYear",
            "args": (v10/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f397670e017e6bd77818c4ac6926262",
    "id": null,
    "metadata": {},
    "name": "UpdateUserPreferencesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserPreferencesMutation(\n  $SchoolYearId: String!\n  $PreferenceName: String!\n  $PreferenceValue: String!\n) {\n  modifyUserPreference(SchoolYearId: $SchoolYearId, PreferenceName: $PreferenceName, PreferenceValue: $PreferenceValue) {\n    UserId\n    Preferences {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "48ab0b7e84f033cbca358de592ab6cf9";

module.exports = ((node/*: any*/)/*: Mutation<
  UpdateUserPreferencesMutation$variables,
  UpdateUserPreferencesMutation$data,
>*/);
