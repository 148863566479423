//Main
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//Component

//Css
import "./LoginBanner.scss";
//Images
import LoginBannerImg from "../../../assets/img/banner/group-2@3x.png";
import { config } from "../../../Config/config";
import PecLoginBanner from "./PecLoginBanner";
class LoginBanner extends Component {

  constructor(props) {
    super(props);


  }
  onClickIES = () => {
    //console.log("test");
    if (window.cordova) {
      window.open(link, "_system");
    } else {
      let currentHost = "https://" + window.location.hostname;
      let IESurl = config.IESURL + "?lang=" + localStorage.getItem("i18nextLng-inapp") + "&back_to=https://iesregister.beta4.ilongman.com/ieschecksession.php?lang=" + localStorage.getItem("i18nextLng-inapp") + "&back_to=" + currentHost;
      window.open(IESurl, "_blank");

    }
  };

  render() {
    const { t } = this.props;
    console.log(t, "test");
    const lang = localStorage.getItem("i18nextLng");
    return (
      <>
        { (config.pickhHostVal == "PEC") ?
          <PecLoginBanner 
          {...this.props}
          />
        :
        <div id="row" className="row-blue tablet_login_banner">
          <div className="card  index-content-card-blue">
            <div className="card-body content_box web">
              <div className="login_banner_text_box">
                <div className="text_body">
                  <h1>{t("Welcome to Longman Plus!")}</h1>
                  <p>
                    {t(
                      "In Longman Plus, you may access your e-Books, companion websites and other resources from a variety of devices, including tablets and mobiles. Start teaching and learning anywhere and anytime!"
                    )}
                  </p>
                  {(config.tempLogin == "yes") ?
                    <NavLink
                      to="/login"
                      onClick={() => {
                        // i18n.changeLanguage("zh-HK");
                        gaTrack(
                          "send",
                          "event",
                          "Landing page",
                          "Login",
                          "Main area"
                        );
                      }}
                    >
                      <button className="btn btn-warning">{t("Login")}</button>
                    </NavLink> : (
                      <button className="btn btn-warning" onClick={() => {
                        // i18n.changeLanguage("zh-HK");
                        gaTrack(
                          "send",
                          "event",
                          "Landing page",
                          "Login",
                          "Main area"
                        );
                        if (window.cordova) {
                          window.open(link, "_system");
                        } else {

                          var IESlang = "en-US";
                          var IESlang2 = "en-US";
                          if (localStorage.getItem("i18nextLng-inapp")) {
                            IESlang2 = localStorage.getItem("i18nextLng-inapp");
                            if (localStorage.getItem("i18nextLng-inapp") != 'en-US') {
                              IESlang = "zh_TW";
                            }
                          }
                          var dummyVar = "test";
                          //alert(window.location.hostname);
                          let currentHost = "https:%2F%2F" + window.location.hostname;
                          let IESurl = config.IESURL + "?lang=" + IESlang + "&back_to=" + config.IESBACKURL + "%3Flang%3D" + IESlang2 + "%26back_to%3D" + currentHost;
                          window.open(IESurl, "_self");

                        }

                      }}>{t("Login")}</button>)}


                  <p>
                    {t(
                      "If your school is not using Longman Plus yet, please don't hesitate to"
                    )}{" "}
                    <a
                      onClick={() => {
                        gaTrack(
                          "send",
                          "event",
                          "Landing page",
                          "Contact us",
                          "Main area"
                        );
                        var action = "_blank",
                          path = [
                            "https://www.pearson.com.hk/en_GB/about-us/contact-us/products-services-enquiries.html",

                            "https://www.pearson.com.hk/zh_HK/about-us/contact-us/products-services-enquiries.html",
                          ];
                        if (window.cordova) {
                          action = "_system";
                        }
                        window.open(path[lang == "en-US" ? 0 : 1], action);
                      }}
                    >
                      {t("contact us.")}
                    </a>
                  </p>
                </div>
                <img
                  className="max-height515"
                  src={LoginBannerImg}
                  alt="Login Banner"
                />
              </div>
              {/*<img className="max-height560" src={LoginBannerImg} alt="Login Banner" /><img className="max-height480" src={LoginBannerImg} alt="Login Banner" />
							<div className="login-now-section">
								<h3 className="text-center"> If you want to try our platform,please contact us. </h3>
								<NavLink
									to="/login"
									onClick={() => {
										if (window.cordova) {
											window.ga.trackEvent('Landing page', 'Login', 'Ending banner CTA');
										} else {
											ReactGA.event({
												category: 'Landing page',
												action: 'Login',
												label: 'Ending banner CTA'
											});
										}
									}}
								>
									<button type="button" className="btn btn-primary ">
										{' '}
										Login Now!
									</button>
								</NavLink>
								</div>*/}
            </div>

            <div
              className="card-body content_box tablet"
              style={{ backgroundImage: `url(${LoginBannerImg})` }}
            >
              <div className="login_banner_text_box">
                <div className="text_body">
                  <h1>{t("Welcome to Longman Plus!")}</h1>
                  <p>
                    {t(
                      "In Longman Plus, you may access your e-Books, companion websites and other resources from a variety of devices, including tablets and mobiles. Start teaching and learning anywhere and anytime!"
                    )}
                  </p>
                  {(config.tempLogin == "yes") ?
                    <NavLink
                      to="/login"
                      onClick={() => {
                        // i18n.changeLanguage("zh-HK");
                        gaTrack(
                          "send",
                          "event",
                          "Landing page",
                          "Login",
                          "Main area"
                        );
                      }}
                    >
                      <button className="btn btn-warning">{t("Login")}</button>
                    </NavLink> : (
                      <button className="btn btn-warning" onClick={() => {
                        // i18n.changeLanguage("zh-HK");
                        gaTrack(
                          "send",
                          "event",
                          "Landing page",
                          "Login",
                          "Main area"
                        );

                        var IESlang = "en-US";
                        var IESlang2 = "en-US";
                        if (localStorage.getItem("i18nextLng-inapp")) {
                          IESlang2 = localStorage.getItem("i18nextLng-inapp");
                          if (localStorage.getItem("i18nextLng-inapp") != 'en-US') {
                            IESlang = "zh_TW";
                          }
                        }
                        var dummyVar = "test";
                        //alert(window.location.hostname);
                        let currentHost = "https:%2F%2F" + window.location.hostname;
                        let IESurl = config.IESURL + "?lang=" + IESlang + "&back_to=" + config.IESBACKURL + "%3Flang%3D" + IESlang2 + "%26back_to%3D" + currentHost;
                        window.open(IESurl, "_self");



                      }}>{t("Login")}</button>)}

                  <p>
                    {t(
                      "If your school is not using Longman Plus yet, please don't hesitate to"
                    )}{" "}
                    <a
                      onClick={() => {
                        gaTrack(
                          "send",
                          "event",
                          "Landing page",
                          "Contact us",
                          "Main area"
                        );
                        var action = "_blank",
                          path = [
                            "https://www.pearson.com.hk/en_GB/about-us/contact-us/products-services-enquiries.html",

                            "https://www.pearson.com.hk/zh_HK/about-us/contact-us/products-services-enquiries.html",
                          ];
                        if (window.cordova) {
                          action = "_system";
                        }
                        window.open(path[lang == "en-US" ? 0 : 1], action);
                      }}
                    >
                      {t("contact us.")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="card-body content_box mobile">
              <div className="login_banner_text_box">
                <div className="text_body">
                  <h1>{t("Welcome to Longman Plus!")}</h1>
                  <p>
                    {t(
                      "In Longman Plus, you may access your e-Books, companion websites and other resources from a variety of devices, including tablets and mobiles. Start teaching and learning anywhere and anytime!"
                    )}
                  </p>

                  <img
                    className="max-height560"
                    src={LoginBannerImg}
                    alt="Login Banner"
                  />
                </div>
                {(config.tempLogin == "yes") ?
                  <NavLink
                    to="/login"
                    onClick={() => {
                      // i18n.changeLanguage("zh-HK");
                      gaTrack(
                        "send",
                        "event",
                        "Landing page",
                        "Login",
                        "Main area"
                      );
                    }}
                  >
                    <button className="btn btn-warning">{t("Login")}</button>
                  </NavLink> : (
                    <button className="btn btn-warning" onClick={() => {
                      // i18n.changeLanguage("zh-HK");
                      gaTrack(
                        "send",
                        "event",
                        "Landing page",
                        "Login",
                        "Main area"
                      );
                      if (window.cordova) {
                        window.open(link, "_system");
                      } else {

                        var IESlang = "en-US";
                        var IESlang2 = "en-US";
                        if (localStorage.getItem("i18nextLng-inapp")) {
                          IESlang2 = localStorage.getItem("i18nextLng-inapp");
                          if (localStorage.getItem("i18nextLng-inapp") != 'en-US') {
                            IESlang = "zh_TW";
                          }
                        }
                        var dummyVar = "test";
                        //alert(window.location.hostname);
                        let currentHost = "https:%2F%2F" + window.location.hostname;
                        let IESurl = config.IESURL + "?lang=" + IESlang + "&back_to=" + config.IESBACKURL + "%3Flang%3D" + IESlang2 + "%26back_to%3D" + currentHost;
                        window.open(IESurl, "_self");

                      }

                    }}>{t("Login")}</button>)}

                <p>
                  {t(
                    "If your school is not using Longman Plus yet, please don't hesitate to"
                  )}{" "}
                  <a
                    onClick={() => {
                      gaTrack(
                        "send",
                        "event",
                        "Landing page",
                        "Contact us",
                        "Main area"
                      );
                      var action = "_blank",
                        path = [
                          "https://www.pearson.com.hk/en_GB/about-us/contact-us/products-services-enquiries.html",

                          "https://www.pearson.com.hk/zh_HK/about-us/contact-us/products-services-enquiries.html",
                        ];
                      if (window.cordova) {
                        action = "_system";
                      }
                      window.open(path[lang == "en-US" ? 0 : 1], action);
                    }}
                  >
                    {t("contact us.")}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
  }
      </>
    );
  }
}

export default LoginBanner;
