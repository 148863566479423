import React from "react";
import { useSelector } from "react-redux";

const B2CAssessmentCard = (props) => {
  const { t } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);

  const userData = useSelector((state) => state.UserReducer.user);
  const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const handleScore = () => {
    let total = 0;
    // let latestScore = 0;
    let history = [];
    props.item.RelatedResource.map((obj, i) => {
      if (i >= 1) return; // now only support get the first resource
      if (obj.SelfLearnAttemptHistory.length != 0) {
        let _latestScore = 0;
        let sortByDate = [
          ...obj.SelfLearnAttemptHistory.filter(
            (a) => a.IsCompleted == "Y" && a.Score != null
          ),
        ].sort((a, b) => {
          return new Date(b.CompletedDate) - new Date(a.CompletedDate);
        });
        // console.log(sortByDate);
        _latestScore = Math.round(
          (sortByDate[0].Score / sortByDate[0].MaxScore) * 100
        );
        history.push(_latestScore);
        // latestScore = _latestScore;
        total = total + _latestScore;
      } else {
        total = -1;
      }
    });

    if (total == -1) {
      return "---";
    }
    total = total / history.length;
    return total;
  };
  return (
    <li
      onClick={() => {
        gaTrack(
          "send",
          "event",
          "Supplementary",
          "Open topic",
          "Topic:" +
            props.item.Level2TagName +
            ", Book:" +
            layoutInfo.book.BookName[0].BookName +
            ", Book Series:" +
            layoutInfo.title[0].BookSeriesName +
            ", Sorting:" +
            props.selected +
            ", Role:" +
            userData?.UserType
        );
        props.history.push(
          `${props.history.location.pathname}/${
            // props.item.RelatedResource[0].ResourceId +
            // "+" +
            props.item.HierarchyReference +
            "+" +
            props.item.Level1TagType +
            "+" +
            props.item.Level1TagName +
            "+" +
            props.item.Level2TagType +
            "+" +
            props.item.Level2TagName
          }`
        );
      }}
    >
      <div className="card">
        <div>
          {lang == "en-US"
            ? props.item.TagDisplayNameEn || props.item.TagLongName
            : props.item.TagDisplayNameChi || props.item.TagLongName}
        </div>
      </div>
      <div className="score-lable">
        <div className="lable">
          {t("Last Score")} : <div className="score">{handleScore()}</div>
        </div>
      </div>
    </li>
  );
};

export default B2CAssessmentCard;
