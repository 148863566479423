import * as types from "./types";
const initState = {
  data: null, // to save LearnosityReportStatistic
};
const ActivityReportReducer = (state = initState, action) => {
  console.log("[ActivityReportReducer]", state, action);
  switch (action.type) {
    case types.ActivityReportReducer.SET_REPORT_DATA:
      return {
        ...state,
        data: action.data,
      };
    // case types.ActivityReportReducer.SET_INFO:
    //   return {
    //     ...state,
    //     info: action.info,
    //   };
    default:
      return state;
  }
};

export default ActivityReportReducer;
