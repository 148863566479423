import React, { useState} from "react";
import "./StudyNotes.scss"
import {Button, Col, Image, Row} from "antd";
import history from "../../../history";

const StudyNotes = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [picture, setPicture] = useState("!@");
    const pecLogo = "https://pecdemo.pearson.com.hk/kelvin-test-pec-app/img/pec/pearson-logo.png"
    const edgeImage = "https://pecdemo.pearson.com.hk/kelvin-test-pec-app/img/pec/EDGE2E_PEC.png"
    const onLogoClick = () => {
        history.push("/m");
    }
    const cameraOptions = {
        destinationType: 0
    }
    const getPicture = () => {
        setIsLoading(true);
        try {
            navigator.camera.getPicture(onGetPictureSuccess, onGetPictureError, cameraOptions);
        } catch (e) {
            console.error(e);
            setIsLoading(true);
        }
        setIsLoading(false);
    }
    const onGetPictureSuccess = (imageUrl) => {
        setIsLoading(true);
        setPicture(imageUrl);
        console.log(imageUrl);
        setIsLoading(false)
    }
    const onGetPictureError = (err) => {
        console.log(err);
    }

    return (
        <div className={"study-notes-container"}>
            {isLoading ?
                <div className="splash-container">
                    <div className="loader mx-auto"/>
                </div>
                :
                <div>
                    <Row style={{padding:5, backgroundImage: `url(${edgeImage})`}}>
                        <Image style={{paddingTop:`env(safe-area-inset-top)`}} preview={false} src={pecLogo} onClick={onLogoClick}/>
                    </Row>
                    <Row style={{padding:5}}>
                        <Col span={24}>
                            <Row style={{marginTop:10, marginBottom:10}}>
                                <Button onClick={getPicture}>take a picture now</Button>
                                { picture && <Image src={`data:image/png;base64,${picture}`}/> }
                            </Row>
                            { picture &&
                                <Row style={{marginTop:10, marginBottom:10}}>
                                    <Button onClick={onLogoClick}>Submit</Button>
                                </Row>
                            }
                        </Col>
                    </Row>
                </div>}
        </div>

    )
}

export default StudyNotes;