/**
 * @generated SignedSource<<0c62b7f03b5c45985768aecb9cecf4d8>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AssignedStudentsInfoGetNameQuery$variables = {|
  StudyGroupId: string,
|};
export type AssignedStudentsInfoGetNameQuery$data = {|
  +studyGroup: ?{|
    +StudyGroupName: ?$ReadOnlyArray<?{|
      +StudyGroupName: ?string,
      +Lang: ?string,
    |}>,
  |},
|};
export type AssignedStudentsInfoGetNameQuery = {|
  variables: AssignedStudentsInfoGetNameQuery$variables,
  response: AssignedStudentsInfoGetNameQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "StudyGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignedStudentsInfoGetNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "kind": "LinkedField",
        "name": "studyGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignedStudentsInfoGetNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StudyGroup",
        "kind": "LinkedField",
        "name": "studyGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d643533a09346ab2ad5741ec89185ed1",
    "id": null,
    "metadata": {},
    "name": "AssignedStudentsInfoGetNameQuery",
    "operationKind": "query",
    "text": "query AssignedStudentsInfoGetNameQuery(\n  $StudyGroupId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupName {\n      StudyGroupName\n      Lang\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0a765595afa9fbdc7720771930ff74b5";

module.exports = ((node/*: any*/)/*: Query<
  AssignedStudentsInfoGetNameQuery$variables,
  AssignedStudentsInfoGetNameQuery$data,
>*/);
