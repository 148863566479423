/**
 * @generated SignedSource<<7f92ea790cf87dc81b75b25532e401a1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery$variables = {|
  ResourcesId: $ReadOnlyArray<?string>,
|};
export type FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnosityTagHierarchyRef: ?string,
      +LearnositySuggestionMapping: ?$ReadOnlyArray<?{|
        +TagName: ?string,
        +TagObjectiveName: ?string,
        +Criteria: ?string,
        +ConditionRule: ?string,
        +TagType: ?string,
      |}>,
    |}>,
  |},
|};
export type FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery = {|
  variables: FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery$variables,
  response: FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourcesId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourcesId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LearnosityTagHierarchyRef",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLearnositySuggestionMapping",
  "kind": "LinkedField",
  "name": "LearnositySuggestionMapping",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "TagName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "TagObjectiveName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Criteria",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ConditionRule",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "TagType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b3ba24c700bae02b66e35ca24814ad8",
    "id": null,
    "metadata": {},
    "name": "FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery",
    "operationKind": "query",
    "text": "query FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery(\n  $ResourcesId: [String]!\n) {\n  resourceSearch(ResourceId: $ResourcesId) {\n    Resources {\n      LearnosityTagHierarchyRef\n      LearnositySuggestionMapping {\n        TagName\n        TagObjectiveName\n        Criteria\n        ConditionRule\n        TagType\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "06548371a9b0886278a4c3b2217ee9c8";

module.exports = ((node/*: any*/)/*: Query<
  FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery$variables,
  FocusSubfocusGetResourceLearnosityTagHierarchyNameQuery$data,
>*/);
