import * as types from "./types";
const initState = {
  user: null,
  user_preferences: null,
  notification_count: "",
  user_rcenter_view_preferences: "Group",
  userCredentials:null,
};
const UserReducer = (state = initState, action) => {
  console.log("[UserReducer]", state, action);
  switch (action.type) {
    case types.UserReducer.SET_USER:
      if (!action.user) {
        localStorage.removeItem("userData");
        localStorage.removeItem("SessionId");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("ssoToken");
        localStorage.removeItem("effectiveSchoolYear");
        localStorage.removeItem("selectedSchoolYear");
        localStorage.removeItem("role");
        return initState;
      }
      return {
        ...state,
        user: action.user,
      };
    case types.UserReducer.SET_USER_PREFERENCES:
      return {
        ...state,
        user_preferences: action.user_preferences,
      };
    case types.UserReducer.UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        notification_count: action.count,
      };

    case types.UserReducer.SET_USER_RCENTER_VIEW_PREFERENCES:
      return {
        ...state,
        user_rcenter_view_preferences: action.user_rcenter_view_preferences,
      };
    case types.UserReducer.SET_USER_CREDENTIALS:
      return {
        ...state,
        userCredentials: action.userCredentials,
      }
    default:
      return state;
  }
};

export default UserReducer;
