/**
 * @generated SignedSource<<a6070583ae37209cb7b332444b5db4d0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditAssignmentMutation$variables = {|
  ResourceAssignId: string,
  AssignmentName?: ?string,
  AssignmentDescription?: ?string,
  DueDate?: ?string,
  AllowRedo?: ?boolean,
|};
export type EditAssignmentMutation$data = {|
  +editAssignment: ?boolean,
|};
export type EditAssignmentMutation = {|
  variables: EditAssignmentMutation$variables,
  response: EditAssignmentMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "AllowRedo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "AssignmentDescription"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "AssignmentName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "DueDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceAssignId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "AllowRedo",
        "variableName": "AllowRedo"
      },
      {
        "kind": "Variable",
        "name": "AssignmentDescription",
        "variableName": "AssignmentDescription"
      },
      {
        "kind": "Variable",
        "name": "AssignmentName",
        "variableName": "AssignmentName"
      },
      {
        "kind": "Variable",
        "name": "DueDate",
        "variableName": "DueDate"
      },
      {
        "kind": "Variable",
        "name": "ResourceAssignId",
        "variableName": "ResourceAssignId"
      }
    ],
    "kind": "ScalarField",
    "name": "editAssignment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAssignmentMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditAssignmentMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "915c3331cc44c444adf3ae482d57153f",
    "id": null,
    "metadata": {},
    "name": "EditAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation EditAssignmentMutation(\n  $ResourceAssignId: String!\n  $AssignmentName: String\n  $AssignmentDescription: String\n  $DueDate: String\n  $AllowRedo: Boolean\n) {\n  editAssignment(ResourceAssignId: $ResourceAssignId, AssignmentName: $AssignmentName, AssignmentDescription: $AssignmentDescription, DueDate: $DueDate, AllowRedo: $AllowRedo)\n}\n"
  }
};
})();

(node/*: any*/).hash = "fe84a3fd2206e20ad48da9481b0b15d7";

module.exports = ((node/*: any*/)/*: Mutation<
  EditAssignmentMutation$variables,
  EditAssignmentMutation$data,
>*/);
