import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation ReadResourceSharingMutation(
    $SharingId: String!
    $SchoolYearId: String!
  ) {
    readResourceSharing(SharingId: $SharingId, SchoolYearId: $SchoolYearId)
  }
`;
export default function ReadResourceSharingMutation(data, action) {
  
    var variables = {
        SharingId : data.SharingId,
        SchoolYearId: data.SchoolYearId,
    };
  
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
