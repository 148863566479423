import React, { useState, useEffect, useRef } from "react";
import "./ViewNotificationAlreadySetPopUp.scss";
const ViewNotificationPopUp = (props) => {
  const { setShowNotificationPopUp, showNotificationPopUp, t } = props;

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_popupref.current !== null &&
        !notification_popupref.current.contains(e.target)
      ) {
        setShowNotificationPopUp(false);
      }
    };
    if (showNotificationPopUp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showNotificationPopUp]);
  const notification_popupref = useRef(null);

  return (
    <div className="view_notification__popup-overlay">
      <div className="view_notification__popup" ref={notification_popupref}>
        <label
          className="view_notification__popup-close-popups"
          // onClick={() => setShowNotificationPopUp(false)}
        >
          &nbsp;
        </label>
        <h3 className="view_notification__popup-heading">
          {t(
            "Please refresh page to view the latest setting of this resource"
          )}
        </h3>
        <span className="view_notification__popup-border"></span>
        <div className="view_notification__popup-bottom">
          {/* <button
            className="buttons btns view_notification__popup-cancel-btn"
            onClick={() => setShowNotificationPopUp(false)}
          >
            {t("Cancel")}
          </button> */}
          <button
            className="buttons btns view_notification__popup-save-btn"
            onClick={() => {
              setShowNotificationPopUp(false);
            }}
          >
            {t("OK")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewNotificationPopUp;
