import React, { useEffect, useState } from "react";
import "./NavBarPrizm.scss";
import { useDispatch, useSelector } from "react-redux";
import {isAndroid, isIOS} from "react-device-detect";
import useIosOpenPrizm from "../../../../hooks/useIosOpenPrizm";
import useEncryption from "../../../../hooks/useEncryption";

const NavBarLearnosity = (props) => {
  console.log(props);
  const { resourceData, bookData, prizmBookId, t, showLoader, setShowLoader } = props;
  console.log("book", bookData);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  console.log("ss", selectOptions)
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const dispatch = useDispatch();
  const [currentBook, setcurrentBook] = useState([]);
  const { iosOpenPrizm } = useIosOpenPrizm();
  const { encrypt, decryptToLocal } = useEncryption();
  const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;


  useEffect(() => {
    console.log("CurrentLoc", props.location.pathname.indexOf("/start"))
    console.log('UserType',UserType)
    //alert("coming")
    var listArray = bookData.Books.filter((o) => o.BookId == prizmBookId);
    console.log("kkkk", prizmBookId, listArray);
    setcurrentBook(listArray);
  }, []);
  const doOpenResult = () => {
    //console.log("test")
    props.history.push({
      pathname:
        "/a/studygroup/" +
        props.match.params.id +
        "/assessment/" +
        "qs&" +
        prizmBookId +
        "/" +
        resourceData.ResourceId +
        "/Result",
      state: {
        group_id: selectOptions.selectedStudyGroupId,
        leftMenu: "",
        bookVal: prizmBookId,
      },
    });
    //alert(tabClassId);

    if(props.location.pathname.indexOf("/start") != -1){

    }else {
      dispatch({
        type: "SET_SELECTED_TYPE",
        selectOptions: {
          selectedStudyGroupId: selectOptions.selectedStudyGroupId,
          selectedStudyGroupName: selectOptions.selectedStudyGroupName,
          selectedResourceName:selectOptions.selectedResourceName,
          selectecResourceTab:selectOptions.selectecResourceTab,
          selectedToggle: selectOptions.selectedToggle,
          setselectedResAssignId: "",
          selectedReportType: "",
          selectedAssessType: "",
          firstLevel: "", //activeFetchId,
          secondLevel: "", //bredCrumRes[1],
          thirdLevel: "", //bredCrumRes[2],
          backpathRes:selectOptions.backpathRes
        },
      });
    }
    
  };
  const doOpenExercise = () => {
    var sarg = props.match.params.id;

                        props.history.push({
                            pathname:
                                "/a/studygroup/" +
                                sarg +
                                "/assessment/" +
                                "qs&" +
                                prizmBookId +
                                "/" +
                                resourceData.ResourceId +
                                "/start",
                            state: {
                                backURL: selectOptions.backpathRes,
                                previewURL: "",
                                group_id: selectOptions.selectedStudyGroupId,
                                leftMenu: "",
                                bookVal: prizmBookId,
                            },
                        });
                        dispatch({
                          type: "SET_SELECTED_TYPE",
                          selectOptions: {
                            selectedStudyGroupId: selectOptions.selectedStudyGroupId,
                            selectedStudyGroupName: selectOptions.selectedStudyGroupName,
                            selectedResourceName:selectOptions.selectedResourceName,
                            selectecResourceTab:selectOptions.selectecResourceTab,
                            selectedToggle: selectOptions.selectedToggle,
                            setselectedResAssignId: "",
                            selectedReportType: "",
                            selectedAssessType: "",
                            firstLevel: "", //activeFetchId,
                            secondLevel: "", //bredCrumRes[1],
                            thirdLevel: "", //bredCrumRes[2],
                            backpathRes:selectOptions.backpathRes
                          },
                        });
        };
        const doOpenModelAnswer = () => {
          var sarg = props.match.params.id;
      
                              props.history.push({
                                  pathname:
                                      "/a/studygroup/" +
                                      sarg +
                                      "/assessment/" +
                                      "qs&" +
                                      prizmBookId +
                                      "/" +
                                      resourceData.ResourceId +
                                      "/answer",
                                  state: {
                                      backURL: selectOptions.backpathRes,
                                      previewURL: "",
                                      group_id: selectOptions.selectedStudyGroupId,
                                      leftMenu: "",
                                      bookVal: prizmBookId,
                                  },
                              });
                              dispatch({
                                type: "SET_SELECTED_TYPE",
                                selectOptions: {
                                  selectedStudyGroupId: selectOptions.selectedStudyGroupId,
                                  selectedStudyGroupName: selectOptions.selectedStudyGroupName,
                                  selectedResourceName:selectOptions.selectedResourceName,
                                  selectecResourceTab:selectOptions.selectecResourceTab,
                                  selectedToggle: selectOptions.selectedToggle,
                                  setselectedResAssignId: "",
                                  selectedReportType: "",
                                  selectedAssessType: "",
                                  firstLevel: "", //activeFetchId,
                                  secondLevel: "", //bredCrumRes[1],
                                  thirdLevel: "", //bredCrumRes[2],
                                  backpathRes:selectOptions.backpathRes
                                },
                              });
              };

  




  const onClickResource = () => {
    console.log("CurrentLoc", props.location.pathname.indexOf("/start"))
    console.log("select", selectOptions)
    console.log("seeme",props.location.state)

    if (window.cordova) {
      if((selectOptions.backpathRes == "Dashboard" || !selectOptions.backpathRes)){
        props.history.push("/m");
      }else {
        props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");
        dispatch({
          type: "SET_SELECTED_BOOK",
          selectOptions: {
            selectedBookId: currentBook[0].BookId,
            selectedBookName: currentBook[0].BookName[0].BookName,
            selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
            selectedBookSeriesId: bookData.BookSeriesId,
            selectedResourceName:selectOptions.selectedResourceName,
            selectedClassLevelId: selectOptions.selectedStudyGroupId,
            selectedClassLevelName: selectOptions.selectedStudyGroupName,
            selectecResourceTab:selectOptions.selectecResourceTab,
            seletctedTabSDL:selectOptions.seletctedTabSDL,
            selectedToggle: selectOptions.selectedToggle,

          },
        });
      }
      return;
    }

    if ((selectOptions.backpathRes == "Dashboard" || !selectOptions.backpathRes) && 
    props.location.pathname.indexOf("/start") != -1) {
      props.history.push("/m");

    } else {
      props.history.push("/s/studygroup/" + bookData.BookSeriesId + "/rcenter");

      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: currentBook[0].BookId,
          selectedBookName: currentBook[0].BookName[0].BookName,
          selectedBookSeriesName: bookData.BookSeriesName[0].BookSeriesName,
          selectedBookSeriesId: bookData.BookSeriesId,
          selectedClassLevelId: selectOptions.selectedStudyGroupId,
          selectedClassLevelName: selectOptions.selectedStudyGroupName,
        },
      });
    }


  };

  
  const showStudentReportButton =() => {
    //It should not student result Page  /Result
    //It should be individual result Page /Result/
    if(UserType == "S"){
      return false;
    }
    if(props.location.pathname.indexOf("/Result") != -1){
        if(props.location.pathname.indexOf("/Result/") != -1)
        return true;
        else return false
    } else if(props.location.pathname.indexOf("/result") != -1){
      if(props.location.pathname.indexOf("/result/") != -1)
      return true;
      else return false
    }
    return true;
  }
  
  const showPreviewExerciseButton =() => {
    if(UserType == "S"){
      return false;
    }
    if(props.location.pathname.indexOf("/Result/") != -1 || props.location.pathname.indexOf("/result/") != -1 || props.location.pathname.indexOf("/start") != -1){
      return false
    }
    return true
  }
  
  const showModelAnswerButton =() => {
    if(UserType == "S"){
      return false;
    }
    if(props.location.pathname.indexOf("/answer") == -1){
      return true
    }
    return false
  }

  return (
    <div className="main__iframe-navigation">
      <h3 className="main__iframe-heading">
        {" "}
        {
          resourceData.ResourceLangs[
            resourceData.ResourceLangs[0].Lang == lang ? 0 : 1
          ].ResourceName
        }
      </h3>
      <div className="main__iframe-righ-section">
      { showStudentReportButton() ? (
            <>
            <button
              className="btns buttons main__iframe-result-btn main__iframe-result-btn-gap"
              onClick={() => doOpenResult()}
            >
              <span className="mdi-open-in-new-sdl-student-active"></span>
              {/* Student's Results */}
              {t("Class results")}
            </button>
             
            </>
          ) : ""        
      }
      {
          // props.location.pathname.indexOf("/start") == -1 &&  props.location.pathname.indexOf("/Result") != -1 ? (
            showPreviewExerciseButton() ? (
            <>
            <button className="btns buttons main__iframe-result-btn main__iframe-result-btn-gap" 
             onClick={() => doOpenExercise()}
            ><span className="mdi-open-in-new-sdl-preview-active"></span>{t("Preview exercise")}</button>
            </>
          ) : ""        
        }
        {
          // props.location.pathname.indexOf("/start") != -1 || props.location.pathname.indexOf("/Result") != -1 ? (
            showModelAnswerButton() ? (
            <>
            <button className="btns buttons main__iframe-result-btn main__iframe-result-btn-gap" 
             onClick={() => doOpenModelAnswer()}
            ><span className="mdi-open-in-new-sdl-modal-preview-active"></span>{t("Model Answer")}
            </button>
            </>
          ) : ""        
        }


        {/* { UserType == "T" &&
          props.location.pathname.indexOf("/Result") == -1 ? ( */}
        

        <button
          className="btns buttons main__iframe-close-btn"
          onClick={() => onClickResource()}
        >
          <span className="mdi-open-in-new-sdl-close"></span>
          {t("Close")}
        </button>
      </div>
    </div>
  );
};

export default NavBarLearnosity;
