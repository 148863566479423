/**
 * @generated SignedSource<<21ccab2d3fb85f86252d35fa5909fad8>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteNotificationMutation$variables = {|
  SharingId: string,
|};
export type DeleteNotificationMutation$data = {|
  +deleteUserNotification: ?boolean,
|};
export type DeleteNotificationMutation = {|
  variables: DeleteNotificationMutation$variables,
  response: DeleteNotificationMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "SharingId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "SharingId",
        "variableName": "SharingId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteUserNotification",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteNotificationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteNotificationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c7d00c693dbfe6ced0e847c2fd4896c1",
    "id": null,
    "metadata": {},
    "name": "DeleteNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteNotificationMutation(\n  $SharingId: String!\n) {\n  deleteUserNotification(SharingId: $SharingId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "ee48e4897f7c0723d588caaeed479c6b";

module.exports = ((node/*: any*/)/*: Mutation<
  DeleteNotificationMutation$variables,
  DeleteNotificationMutation$data,
>*/);
