// "[UserReducer]"
const SET_USER = "SET_USER";
const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
const SET_USER_RCENTER_VIEW_PREFERENCES = "SET_USER_RCENTER_VIEW_PREFERENCES";
const SET_USER_CREDENTIALS = "SET_USER_CREDENTIALS";
export const UserReducer = {
  SET_USER,
  SET_USER_PREFERENCES,
  UPDATE_NOTIFICATION_COUNT,
  SET_USER_RCENTER_VIEW_PREFERENCES,
  SET_USER_CREDENTIALS,
};

// "[SystemReducer]"
const SET_LANG = "SET_LANG";
const SET_SCHOOL_YEAR = "SET_SCHOOL_YEAR";
const SET_COSTOM_SCHOOL_YEAR = "SET_COSTOM_SCHOOL_YEAR";
const SET_PLATFROM = "SET_PLATFROM";

export const SystemReducer = {
  SET_LANG,
  SET_SCHOOL_YEAR,
  SET_PLATFROM,
  SET_COSTOM_SCHOOL_YEAR,
};

// "[HandbookReducer]"
const SET_HANDBOOK = "SET_HANDBOOK";
const SET_HANDBOOK_TOTAL_ITEMS = "SET_HANDBOOK_TOTAL_ITEMS";
export const HandbookReducer = {
  SET_HANDBOOK,
  SET_HANDBOOK_TOTAL_ITEMS,
};

// "[NotificationListReducer]"
const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
const SET_NOTIFICATION_LIST_TOTAL_ITEMS = "SET_NOTIFICATION_LIST_TOTAL_ITEMS";
export const NotificationListReducer = {
  SET_NOTIFICATION_LIST,
  SET_NOTIFICATION_LIST_TOTAL_ITEMS,
};

// "[StudyGroupHandbookReducer]"
const SET_STUDYGROUP_HANDBOOK = "SET_STUDYGROUP_HANDBOOK";
const SET_STUDYGROUP_HANDBOOK_TOTAL_ITEMS =
  "SET_STUDYGROUP_HANDBOOK_TOTAL_ITEMS";
export const StudyGroupHandbookReducer = {
  SET_STUDYGROUP_HANDBOOK,
  SET_STUDYGROUP_HANDBOOK_TOTAL_ITEMS,
};

// "[ResCenterAssignedRelatedReducer]"
const SET_RESCENTERASSIGNEDRELATED = "SET_RESCENTERASSIGNEDRELATED";
const SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS =
  "SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS";
export const ResCenterAssignedRelatedReducer = {
  SET_RESCENTERASSIGNEDRELATED,
  SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS,
};

// "[ResCenterListReloadReducer]"
const SET_RESCENTER_RELOAD = "SET_RESCENTER_RELOAD";
export const ResCenterListReloadReducer = {
  SET_RESCENTER_RELOAD,
};

// EntryOfAssessmentReducer
const SET_ENTRY_ASSESSMENT = "SET_ENTRY_ASSESSMENT";
const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
const SET_RES_ID = "SET_RES_ID";
const SET_SHOWALL_TOGGLE = "SET_SHOWALL_TOGGLE";
export const EntryOfAssessmentReducer = {
  SET_ENTRY_ASSESSMENT,
  SET_SELECTED_TYPE,
  SET_RES_ID,
  SET_SHOWALL_TOGGLE,
};

// EntryOfResourceReducer
const SET_ENTRY_RESOURCE = "SET_ENTRY_RESOURCE";
const SET_SELECTED_BOOK = "SET_SELECTED_BOOK";
export const EntryOfResourceReducer = {
  SET_ENTRY_RESOURCE,
  SET_SELECTED_BOOK,
};

//CommonNavBarReducer
const SET_LAYOUT = "SET_LAYOUT";
const SET_INFO = "SET_INFO";
export const CommonNavBarReducer = {
  SET_LAYOUT,
  SET_INFO,
};

//B2bB2clayout
const SET_FROM = "SET_FROM";
export const LayoutIdentifyReducer = {
  SET_FROM,
};

//ActivityReportReducer
const SET_REPORT_DATA = "SET_REPORT_DATA";
export const ActivityReportReducer = {
  SET_REPORT_DATA,
};

const SET_STUDENT_RESULT = "SET_STUDENT_RESULT";
export const StudentResultReducer = {
    SET_STUDENT_RESULT,
}

const SET_ASK_FOR_INSTALL = "SET_ASK_FOR_INSTALL";
const SET_ASK_FOR_INSTALL_TOOL_DATA = "SET_ASK_FOR_INSTALL_TOOL_DATA";
export const OpenInstallModalReducer = {
    SET_ASK_FOR_INSTALL,
    SET_ASK_FOR_INSTALL_TOOL_DATA,
}