/**
 * @generated SignedSource<<277427a15f258ec5db4f662eb0bd8b57>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginPlatformEnumType = "WEB" | "MOBILE" | "TABLET" | "%future added value";
export type LoginHawkMutation$variables = {|
  UserKey: string,
  platform: LoginPlatformEnumType,
|};
export type LoginHawkMutation$data = {|
  +loginHawk: ?{|
    +SAMAssessToken: ?string,
    +SAMRefreshToken: ?string,
    +SAMSsoToken: ?string,
    +SessionId: ?string,
    +SAMExpiresIn: ?string,
    +User: ?{|
      +UserId: ?string,
      +UserType: ?string,
    |},
  |},
|};
export type LoginHawkMutation = {|
  variables: LoginHawkMutation$variables,
  response: LoginHawkMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "UserKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "platform"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserKey",
    "variableName": "UserKey"
  },
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMAssessToken",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMRefreshToken",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMSsoToken",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SessionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SAMExpiresIn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginHawkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userLoginSession",
        "kind": "LinkedField",
        "name": "loginHawk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginHawkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userLoginSession",
        "kind": "LinkedField",
        "name": "loginHawk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f22fe2d1575c814407478f0c6a08ab8b",
    "id": null,
    "metadata": {},
    "name": "LoginHawkMutation",
    "operationKind": "mutation",
    "text": "mutation LoginHawkMutation(\n  $UserKey: String!\n  $platform: LoginPlatformEnumType!\n) {\n  loginHawk(UserKey: $UserKey, platform: $platform) {\n    SAMAssessToken\n    SAMRefreshToken\n    SAMSsoToken\n    SessionId\n    SAMExpiresIn\n    User {\n      UserId\n      UserType\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c49429da1593fc6ad42ebdaafa0a8197";

module.exports = ((node/*: any*/)/*: Mutation<
  LoginHawkMutation$variables,
  LoginHawkMutation$data,
>*/);
