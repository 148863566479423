import React, { useEffect, useState } from "react";
import likeIcon from "../../../assets/img/Vector.svg";
import likedIcon from "../../../assets/img/Vector.png";
import hoverIcon from "../../../assets/img/vector-hover.png";
import icon from "../../../assets/img/Group 99.png";
import MessageBox from "../../Feature/MessageBox";
import { handbook_level } from "../../../Actions";
import UpdateUserPreferencesMutation from "../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import { useDispatch, useSelector } from "react-redux";
import "./handbookSelectPopUp.scss";

const HandBookSelectPopUp = (props) => {
  const {
    ableClasses,
    favLevelList,
    setfavLevelList,
    doSelectFunc,
    myLevelFav,
    subjectId,
    levelFavTab,
    favHandbookLevelIds,
    setfavHandbookLevelIds,
    role,
    settabOptLevel,
    selectBookValue,
  } = props;
  const Modal = ({ children }) => <div className="Modal">{children}</div>;
  const lang =
    localStorage.getItem("i18nextLng") ||
    localStorage.getItem("i18nextLng-inapp");
  const [filterFavButtonStateDropdown, setFilterFavButtonStateDropdown] =
    useState(levelFavTab);
  const [filterlikeItem, setFilterlikeItem] = useState({});
  const { t } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const [over, setOver] = useState(-1);
  const [myFavHandbookLevel, setmyFavHandbookLevel] = useState(myLevelFav);

  useEffect(() => {
    const updatedFav = [...new Set(myFavHandbookLevel)];
    const updatedFavIds = { ...favHandbookLevelIds };
    updatedFav.forEach((id) => {
      updatedFavIds[id] = true;
    });
    setfavHandbookLevelIds((prev) => ({ ...prev, ...updatedFavIds }));
  }, [myFavHandbookLevel]);

  const saveFavouritesLevel = (bookSeriesId, val, bookSeriesNamearg) => {
    // to add or remove gatrack start here
    gaTrack(
      "send",
      "event",
      "General",
      val == "yes" ? "Favourite class add" : "Favourite class remove",
      `Book Series:${selectBookValue}, Class:${bookSeriesNamearg}, Location:Handbook, Role:${userData.UserType}`
    );
    // to add or remove gatrack end here

    setfavHandbookLevelIds((prev) => ({
      ...prev,
      [bookSeriesId]: val === "yes" ? true : false,
    }));

    //console
    //const val = true ? 'yes': 'no'
    var preferencesObj = handbook_level(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        let updateBookList = [];

        if (preferencesArr.length > 0) {
          preferencesArr[0].BSId.forEach(function (id, index) {
            updateBookList.push(id);
          });

          updateBookList.push(bookSeriesId);
          var obj = {
            BSId: updateBookList,
          };
        } else {
          var preferencesArr = [];
          let favoritesBook = [];
          favoritesBook.push(bookSeriesId);
          var obj = {
            BSId: favoritesBook,
          };
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        let updateBookList1 = preferencesArr[0].BSId;
        const indx = updateBookList1.findIndex((v) => v == bookSeriesId);
        updateBookList1.splice(indx, indx >= 0 ? 1 : 0);
        var obj = {
          BSId: updateBookList1,
        };
      }
      preferencesArr[0] = obj;
    } else {
      var preferencesArr = [];
      let favoritesBook = [];
      favoritesBook.push(bookSeriesId);
      var obj = {
        BSId: favoritesBook,
      };
    }

    var updatePrefrenceArr = [];
    updatePrefrenceArr.push(obj);
    var data = {
      PreferenceName: "HANDBOOK_LEVEL",
      PreferenceValue: JSON.stringify(updatePrefrenceArr),
    };

    const callback = (result) => {
      console.log(result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
    };
    UpdateUserPreferencesMutation(data, callback);
    setmyFavHandbookLevel(obj.BSId);

    var latestStructureId = obj.BSId;
    var favlevelup = ableClasses.filter((obj) => {
      return latestStructureId.includes(obj.StudyGroupId);
    });
    setfavLevelList(favlevelup);
  };

  const setFavValueFuncDropdown = (curVal) => {
    var btnVal = "";
    //allow toggle once start here
    if (filterFavButtonStateDropdown != curVal) return;
    //allow toggle once ends here
    // toggle favourite and all gatrack start here
    gaTrack(
      "send",
      "event",
      "General",
      "Switch class favourite list",
      `Book Series:${selectBookValue}, Location:Handbook, Switch to:${
        filterFavButtonStateDropdown == 1 ? "All" : "Favourite"
      }, Role:${userData.UserType}`
    );
    // toggle favourite and all gatrack ends here
    if (filterFavButtonStateDropdown == 1) {
      setFilterFavButtonStateDropdown(0);
      settabOptLevel(0);
      btnVal = 0;
    } else {
      // favourite gatrack start here
      gaTrack(
        "send",
        "event",
        "General",
        "Open class favourite list",
        `Book Series:${selectBookValue}, Location:Handbook, Role:${userData.UserType}`
      );
      // favourite gatrack ends here
      setFilterFavButtonStateDropdown(1);
      settabOptLevel(1);
      btnVal = 1;
    }
    var obj = {
      tabSelect: btnVal,
    };
    var updatePrefrenceArr = [];
    updatePrefrenceArr.push(obj);

    var data = {
      PreferenceName: "HANDBOOK_LEVEL_TAB",
      PreferenceValue: JSON.stringify(updatePrefrenceArr),
    };
    const callback = (result) => {
      console.log(result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
    };
    UpdateUserPreferencesMutation(data, callback);
  };

  const setFavIconDropdownFunc = (index) => {
    setFilterlikeItem((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const setClickedClassFunc = () => {
    alert("class clicked");
  };

  return (
    <div className={"component-new-select"}>
      {role != "student" && (
        <div className="main-container">
          <div
            className={`static-tab-dropdown ${
              filterFavButtonStateDropdown === 0 ? "active-tab-dropdown" : ""
            }`}
            onClick={() => setFavValueFuncDropdown(1)}
          >
            {t("All")}
          </div>
          <div
            className={`static-tab-dropdown ${
              filterFavButtonStateDropdown === 1 ? "active-tab-dropdown" : ""
            }`}
            onClick={() => setFavValueFuncDropdown(0)}
          >
            {t("Favourite")}
          </div>
        </div>
      )}

      {filterFavButtonStateDropdown == 0 ? (
        <div>
          {ableClasses.length > 0 && (
            <div
              className="please-select-row"
              onClick={(e) => doSelectFunc(e, "", "All Classes")}
            >
              {t("All Classes")}
            </div>
          )}
          {ableClasses &&
            ableClasses.map((obj, index) => {
              if (obj.BookSeries.BookSeriesId == subjectId) {
                return (
                  <div className="main-class-container">
                    <div
                      className="class-row class-row1"
                      onClick={(e) =>
                        doSelectFunc(
                          e,
                          obj.StudyGroupId,
                          obj.StudyGroupName[
                            obj.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                        )
                      }
                    >
                      {t("Class")}{" "}
                      {
                        obj.StudyGroupName[
                          obj.StudyGroupName[0].Lang == lang ? 0 : 1
                        ].StudyGroupName
                      }
                    </div>
                    {role != "student" &&
                      (favHandbookLevelIds[obj.StudyGroupId] ? (
                        <div className="like-row like-row1">
                          <img
                            src={likedIcon}
                            onClick={() => {
                              //setFavIconDropdownFunc(1);
                              saveFavouritesLevel(
                                obj.StudyGroupId,
                                "no",
                                obj.StudyGroupName[
                                  obj.StudyGroupName[0].Lang == lang ? 0 : 1
                                ].StudyGroupName
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <div className="like-row like-row1">
                          <img
                            src={over == index ? hoverIcon : likeIcon}
                            onClick={() => {
                              //setFavIconDropdownFunc(1);
                              saveFavouritesLevel(
                                obj.StudyGroupId,
                                "yes",
                                obj.StudyGroupName[
                                  obj.StudyGroupName[0].Lang == lang ? 0 : 1
                                ].StudyGroupName
                              );
                            }}
                            onMouseOver={() => setOver(index)}
                            onMouseOut={() => setOver(-1)}
                          />
                        </div>
                      ))}
                  </div>
                );
              }
            })}
        </div>
      ) : (
        <div>
          {favLevelList.length > 0 && (
            <div className="please-select-row">{t("All classes")}</div>
          )}
          {favLevelList.length > 0 ? (
            favLevelList.map((obj, index) => {
              if (obj.BookSeries.BookSeriesId == subjectId) {
                return (
                  <div className="main-class-container">
                    <div
                      className="class-row class-row1"
                      onClick={(e) =>
                        doSelectFunc(
                          e,
                          obj.StudyGroupId,
                          obj.StudyGroupName[
                            obj.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                        )
                      }
                    >
                      {t("Class")}{" "}
                      {
                        obj.StudyGroupName[
                          obj.StudyGroupName[0].Lang == lang ? 0 : 1
                        ].StudyGroupName
                      }
                    </div>
                    {role != "student" &&
                      (favHandbookLevelIds[obj.StudyGroupId] ? (
                        <div className="like-row like-row1">
                          <img
                            src={likedIcon}
                            onClick={() => {
                              //setFavIconDropdownFunc(1);
                              saveFavouritesLevel(
                                obj.StudyGroupId,
                                "no",
                                obj.StudyGroupName[
                                  obj.StudyGroupName[0].Lang == lang ? 0 : 1
                                ].StudyGroupName
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <div className="like-row like-row1">
                          <img
                            src={over == index ? hoverIcon : likeIcon}
                            onClick={() => {
                              //setFavIconDropdownFunc(1);
                              saveFavouritesLevel(
                                obj.StudyGroupId,
                                "yes",
                                obj.StudyGroupName[
                                  obj.StudyGroupName[0].Lang == lang ? 0 : 1
                                ].StudyGroupName
                              );
                            }}
                            onMouseOver={() => setOver(index)}
                            onMouseOut={() => setOver(-1)}
                          />
                        </div>
                      ))}
                  </div>
                );
              }
            })
          ) : (
            <MessageBox
              id={"StudyGroupAssignment"}
              src={icon}
              message={`${t("No favourite classes added yet.")}`}
              // message2={`${t("resource first.")}`}
            />
          )}
        </div>
      )}
    </div>
  );

  const App = () => {
    const [showModal, setShowModal] = useState(false);
    const handleKeyup = (e) => e.keyCode === 27 && setShowModal(false);
    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
      if (showModal) window.addEventListener("keyup", handleKeyup);
      return () => window.removeEventListener("keyup", handleKeyup);
    });

    return (
      <div onClick={toggleModal} className="App">
        View information
        {showModal && (
          <Modal>
            <Component />
          </Modal>
        )}
      </div>
    );
  };
};

// ReactDOM.render(<App />, root);

export default HandBookSelectPopUp;
