import { commitMutation, graphql } from "react-relay";
import environment from "../../../Environment";

const mutation = graphql`
  mutation LoginHawkMutation(
    $UserKey: String!
    $platform: LoginPlatformEnumType!
  ) {
    loginHawk(UserKey: $UserKey, platform: $platform) {
      SAMAssessToken
      SAMRefreshToken
      SAMSsoToken
      SessionId
      SAMExpiresIn
      User {
        UserId
        UserType
      }
    }
  }
`;

export default function LoginHawkMutation(UserKey, platform, action) {
  const variables = {
    UserKey: UserKey,
    platform: platform,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
