import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import B2CAssessmentCard from "../../../../Components/B2CAssessmentCard";
import TopicContents from "../../../../Services/api/GraphQL/Query/TopicContents";
import TopicListingOptions from "../../../../Services/api/GraphQL/Query/TopicListingOptions";
import { groupBy } from "../../../../Services/Common/common";
import "./index.scss";
const B2CAssessments = (props) => {
  const { t } = props;
  const userData = useSelector((state) => state.UserReducer.user);

  const lang = useSelector((state) => state.SystemReducer.lang);
  const [isLoading, setisLoading] = useState(true);

  const [selectOptions, setselectOptions] = useState([]);
  const [selected, setselected] = useState(null);

  const [contents, setcontents] = useState({});

  useEffect(() => {
    //get select options
    const init = async () => {
      console.log(props);
      await getSelections();
    };
    init();
    //set default select option

    //group as different grouping
  }, []);

  useEffect(() => {
    if (selected != null) {
      console.log("On change Featch Contents", selected);
      if (selected != "ALL") {
        getTopicContents();
      } else {
        if (contents["ALL"].child == null) {
          let keys = Object.keys(contents);
          let haveNullChild = null;
          keys.map((o, i) => {
            if (o == "ALL") return;
            if (contents[o].child == null) {
              console.log("NULL child ", o);
              haveNullChild = o;
            }
          });
          if (haveNullChild != null) {
            getTopicContents(haveNullChild);
          } else {
            console.log("Combia");
            let _newContents = contents;
            let topics = [];
            Object.keys(_newContents).map((k, ki) => {
              if (k == "ALL") return;
              _newContents[k].child.map((cc, ci) => {
                topics = topics.concat(cc.child);
              });
            });

            //remove dui
            let _uniqueTopics = [];
            topics.map((t, i) => {
              if (
                _uniqueTopics
                  .map((r) => r.RelatedResource[0].ResourceId)
                  .indexOf(t.RelatedResource[0].ResourceId) == -1
              ) {
                _uniqueTopics.push(t);
              }
            });
            _newContents["ALL"].child = [{ child: _uniqueTopics }];
            setcontents(_newContents);
            setisLoading(false);
          }
        } else {
          setisLoading(false);
        }
      }
    }
  }, [selected]);

  const getTopicContents = async (value) => {
    if (contents[value || selected].child == null) {
      let variables = {
        UserId: userData?.UserId,
        BookId: props.match.params.book,
        HierarchyReference: value || selected,
      };
      await TopicContents(variables).then((data) => {
        console.log(data, contents);
        const relayedtagType =
          data.learnosityTagHierarchyStructure[0]?.RelatedTagType || [];
        const allResources =
          data.learnosityTagHierarchyStructure[0]?.AdoptedLearnosityResource ||
          [];

        //pre tags use for mapping
        let tags = [];
        relayedtagType.map((obj) => {
          let _child = [];
          obj.Tags.map((cobj) => _child.push(cobj));
          tags = [...tags, ..._child];
        });
        console.log(tags);
        //pre tags done

        //get all resources under AdoptedLearnosityResource to map topic and title
        let _preContents = [];
        let _level1Group = groupBy(allResources, "Level1TagName");
        console.log("level 1", _level1Group);
        Object.keys(_level1Group).map((obj, i) => {
          // console.log(obj, tags);
          let matchTagsInfo = tags.filter((t) => t.TagName == obj);
          console.log(matchTagsInfo, "matchTagsInfo");
          matchTagsInfo.map((mt, mi) => {
            _preContents.push({
              ...mt,
              DisplaySequence: parseInt(mt.DisplaySequence),
              child: [],
            });
          });

          _preContents = _preContents.sort(
            (a, b) => a.DisplaySequence - b.DisplaySequence
          );
          console.log(_preContents);
          let _level2Group = groupBy(_level1Group[obj], "Level2TagName");
          console.log("level 2", _level2Group);
          Object.keys(_level2Group).map((sobj, si) => {
            let matchTagsInfo = tags.filter(
              (t) =>
                t.TagName == sobj && obj == _level2Group[sobj][0].Level1TagName
            );
            // if (obj == _level2Group[sobj][0].Level1TagName) {
            matchTagsInfo.map((mt, mi) => {
              // _preContents.map((_preChil, _preI) => {
              let relayResources = [];
              // _level2Group[sobj].map(
              // (k, ki) =>
              // (
              relayResources = _level2Group[sobj][0].RelatedResource;
              //   relayResources = relayResources.concat(
              //   relayResources.push(
              //     k.RelatedResource.filter((a) => a.IsCompleted == "Y")
              //   )
              // )
              // )
              // );
              _preContents[
                _preContents.map((_) => _.TagName).indexOf(obj)
              ].child.push({
                ...mt,
                HierarchyReference: value || selected,
                Level1TagType: _level2Group[sobj][0].Level1TagType,
                Level1TagName: _level2Group[sobj][0].Level1TagName,
                Level2TagType: _level2Group[sobj][0].Level2TagType,
                Level2TagName: _level2Group[sobj][0].Level2TagName,
                RelatedResource: relayResources,
                DisplaySequence: parseInt(mt.DisplaySequence),
              });
              // console.log(
              //   {
              //     ...mt,
              //     HierarchyReference: value || selected,
              //     Level1TagType: _level2Group[sobj][0].Level1TagType,
              //     Level1TagName: _level2Group[sobj][0].Level1TagName,
              //     Level2TagType: _level2Group[sobj][0].Level2TagType,
              //     Level2TagName: _level2Group[sobj][0].Level2TagName,
              //     RelatedResource: relayResources,
              //     DisplaySequence: parseInt(mt.DisplaySequence),
              //   },
              //   "after push"
              // );
              // });
            });
            // }
          });
          _preContents.map((_, _i) => {
            _preContents[_i].child = _.child.sort(
              (a, b) => a.DisplaySequence - b.DisplaySequence
            );
          });
        });
        console.log("_preContents", _preContents);

        let _newContents = contents;
        // get level 1 name
        // let _level1name = {
        //   en:tags.filter((o)=>o.TagType==)
        // };
        _newContents[value || selected] = {
          ...contents[value || selected],
          child: _preContents,
        };
        console.log(_newContents, "_newContents");
        if (selected == "ALL") {
          //combia all topics
          console.log("Combia");
          let topics = [];
          Object.keys(_newContents).map((k, ki) => {
            if (k == "ALL") return;
            _newContents[k].child.map((cc, ci) => {
              topics = topics.concat(cc.child);
            });
          });
          //remove dui
          let _uniqueTopics = [];
          topics.map((t, i) => {
            if (
              _uniqueTopics
                .map((r) => r.RelatedResource[0].ResourceId)
                .indexOf(t.RelatedResource[0].ResourceId) == -1
            ) {
              _uniqueTopics.push(t);
            }
          });
          _newContents["ALL"].child = [{ child: _uniqueTopics }];
          setcontents(_newContents);
          setisLoading(false);
        } else {
          setcontents(_newContents);
          setisLoading(false);
        }
      });
    }
  };

  const getSelections = async () => {
    let variables = {
      UserId: userData?.UserId,
      BookId: props.match.params.book,
      BookSeriesId: props.match.params.id,
    };
    await TopicListingOptions(variables).then((data) => {
      console.log(data);

      let options =
        data.user.AdoptedBookSeries[0]?.Books[0]?.AdoptedTagHierarchy.map(
          (obj, i) => {
            return {
              ...obj,
              id: obj.HierarchyReference,
              DisplaySequence: parseInt(obj.DisplaySequence),
            };
          }
        ) || [];
      if (options.length == 0) return props.history.replace("/m/myresview");
      options.push({
        id: "ALL",
        HierarchyReferenceLabelEn: "Show All ​",
        HierarchyReferenceLabelChi: "展示全部",
        DisplaySequence: 999,
      });
      options = options.sort((a, b) => a.DisplaySequence - b.DisplaySequence);
      console.log(options);

      let _contents = {};
      options.map((obj, i) => {
        _contents[obj.id] = {
          HierarchyReferenceLabelEn: obj.HierarchyReferenceLabelEn,
          HierarchyReferenceLabelChi: obj.HierarchyReferenceLabelChi,
          DisplaySequence: obj.DisplaySequence,
          // childs: [],
        };
      });
      console.log(_contents);
      setcontents(_contents);

      setselectOptions(options);
      setselected(options[0].id);
    });
  };

  return (
    <div className="b2c_assessments">
      <div className="options-row">
        <div className="order-selection">
          <select
            className=""
            defaultValue={selected}
            onChange={
              (e) => {
                contents[e.target.value].child == null && setisLoading(true);
                setselected(e.target.value);
              }
              //   this.props.onClickHistory(
              //     HistoryArray[e.target.value],
              //     e.target.value
              //   )
            }
          >
            {selectOptions.map((obj, i) => {
              const splitTitle = (
                lang == "en-US"
                  ? obj.HierarchyReferenceLabelEn
                  : obj.HierarchyReferenceLabelChi
              ).split(";");
              return (
                <option key={i} value={obj.id}>
                  {splitTitle.length == 1 ? splitTitle[0] : splitTitle[1]}
                </option>
              );
            })}
            {/* {this.renderListRow(HistoryArray)} */}
          </select>
          <div className="border"></div>
        </div>
      </div>
      {!isLoading &&
        contents[selected].child.map((e, c) => {
          console.log(contents[selected].child);
          return (
            <React.Fragment key={c}>
              <div className="line"></div>
              <div className="group-row" key={c}>
                {selected != "ALL" && (
                  <div className="title-block">
                    <div>
                      {lang == "en-US"
                        ? e.TagDisplayNameEn || e.TagLongName
                        : e.TagDisplayNameChi || e.TagLongName}
                    </div>
                  </div>
                )}
                <div className="list-block">
                  <ul>
                    {e.child.map((a, i) => {
                      return (
                        <B2CAssessmentCard
                          key={i}
                          t={t}
                          {...props}
                          item={a}
                          selected={selected}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default B2CAssessments;
