import {NavLink} from "react-router-dom";
import React from "react";

const LoginNavBar = ({ t, lang}) => {
    return (
        <nav className={"login-nav"}>
            <div className="top-bar">
                <div className="pull-left">
                    <NavLink to={"/"} className="backToHome">
                        <i className="icon mdi mdi-arrow-left" /> {t("Back to home")}
                    </NavLink>
                </div>

            </div>
        </nav>
    )
}
export default LoginNavBar;
//export default withRouter(LoginNavBar);