import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";

// import { render } from "enzyme";
function index(props) {
  const { t, reportTagId, reportTagsArray, rlevelId, showLevelAnalysis } =
    props;
  const lang = useSelector((state) => state.SystemReducer.lang);

  const renderLevels = () => {
    console.log("new" + rlevelId);
    if (reportTagsArray.length > 0) {
      var objIndex = reportTagsArray.findIndex(
        (pobj) => pobj.HierarchyReference == reportTagId
      );

      if (objIndex != -1) {
        var subjectWithLevels = reportTagsArray[objIndex];
        let levelIdLocal = rlevelId;
        return (
          <select
            onChange={(e) => {
              const filterd = subjectWithLevels.AdoptedPearsonLevel.filter(
                (o) => o.PearsonLevelId === e.target.value
              );
              showLevelAnalysis(
                e.target.value,
                filterd[0].LevelName[0].LevelName
              );
            }}
            className=""
            value={levelIdLocal}
            defaultValue={levelIdLocal}
          >
            {subjectWithLevels.AdoptedPearsonLevel.map(function (obj, index) {
              return (
                <option key={index} value={obj.PearsonLevelId}>
                  {
                    obj.LevelName[obj.LevelName[0].Lang == lang ? 0 : 1]
                      .LevelName
                  }
                </option>
              );
            })}
          </select>
        );
      }
    }
  };
  return <React.Fragment>{renderLevels()}</React.Fragment>;
}

export default index;
