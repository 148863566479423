import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { isEmpty } from "lodash";
import { config } from "./Config/config";
import { withRouter } from "react-router-dom";
import history from "./history";

function fetchQuery(operation, variables, cacheConfig, uploadables, props) {
  //Config
  var SessionId = localStorage.getItem("SessionId");
  var baseurl = config.grqphQl_url;
  console.log("Suresh",baseurl);
  let body;

  if (!isEmpty(uploadables)) {
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.");
    }

    const formData = new FormData();
    formData.append("query", operation.text);
    formData.append("variables", JSON.stringify(variables));

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    body = formData;
  } else {
    body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }
  return window
    .fetch(baseurl, {
      method: "POST",
      headers: {
        "X-Api-Key": SessionId ? SessionId : "",
        "Content-Type": "application/json",
      },
      body,
    })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (!response.ok) {
        switch (response.status) {
          case 401:
            localStorage.removeItem("SessionId");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("ssoToken");

            const UserData = JSON.parse(localStorage.getItem("userData"));

            gaTrack(
              "send",
              "event",
              "General",
              "Timeout logout",
              "Role:" + UserData.UserType
            );
            localStorage.removeItem("userData");
            //Window.afterLogout();
            //history.push("/");
            // location.reload();
            break;

          case 500:
            console.log(response);
            history.push("/errorcode=500");
            return response;
        }
        return response;
      } else {
        return response;
      }
    })
    .catch((err) => {
      console.log("error:", err);
    });
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default withRouter(environment);
