import React, { useState, useEffect, useRef } from "react";
import StudentList from "./StudentList";
import Chart from "./Chart";
// import ProgressBar from "../../../../../Feature/ProgressBar";
import Suggestion from "../Suggection";
import FocusSubfocus from "../FocusSubfocus";
import Loading from "../../../../../Loading/PEC/Loading";
import environment from "../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { useDispatch, useSelector } from "react-redux";
import StandAloneAssignment from "../../../../../../Services/api/GraphQL/Query/StandAloneAssignment";
import ExportDataToXlsx from "../../../../../../Services/Common/ExportDataToXlsx";
import download_excel from "../../../../../../assets/img/download-24-px.svg";
import likedIcon from "../../../../../../assets/img/pec/all-images/icon-heart-grey-small.png";
import likeIcon from "../../../../../../assets/img/pec/all-images/icon-heart-outline-grey-small.png";
import "./question.scss";
import UpdateUserPreferencesMutation from "../../../../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import { dashboard_book_level_favorites } from "../../../../../../Actions";

const AllStudentResultTeacherStudyGroupQuery = graphql`
  query AllStudentResultTeacherStudyGroupQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      School {
        StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: true) {
          StudyGroupId
          BookSeries {
            BookSeriesId
            IngestionId
          }
          StudyGroupName {
            Lang
            StudyGroupName
          }
          StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
            StudyGroupId
            StudyGroupName {
              Lang
              StudyGroupName
            }
          }
        }
      }
    }
  }
`;

const AllStudentResultGetAssignementNameQuery = graphql`
  query AllStudentResultGetAssignementNameQuery(
    $ResourceAssignId: String!
    $SchoolYearId: String!
  ) {
    resourceAssignment(ResourceAssignId: $ResourceAssignId) {
      Resources {
        ResourceId
        ResourceAssignId
        ResourceAssignResId
        LearnosityAttemptHistory {
          LearnositySessionRawResult
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
              UserId
              ClassNumber
              StudyGroup {
                StudyGroupName {
                  Lang
                  StudyGroupName
                }
              }
            }
          }
        }
        LearnosityAttemptLastHistory {
          LearnositySessionRawResult
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
              UserId
              ClassNumber
              StudyGroup {
                StudyGroupName {
                  Lang
                  StudyGroupName
                }
              }
            }
          }
        }
        LearnosityAttemptFirstHistory {
          LearnositySessionRawResult
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
              UserId
              ClassNumber
              StudyGroup {
                StudyGroupName {
                  Lang
                  StudyGroupName
                }
              }
            }
          }
        }
      }
      AssignmentTargets(FilterCurrentUser: false) {
        ResourceAssignId
        UserId
        User {
          UserDisplayName {
            Lang
            UserName
          }
          BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
            UserId
            ClassNumber
            StudyGroup {
              StudyGroupName {
                Lang
                StudyGroupName
              }
            }
          }
        }
        StudyGroup {
          StudyGroupId
          StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
            StudyGroupId
            StudyGroupName {
              Lang
              StudyGroupName
            }
          }
          StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
            UserId
            ClassNumber
            User {
              UserDisplayName {
                UserName
                Lang
              }
            }
          }
          StudyGroupName {
            Lang
            StudyGroupName
          }
        }
      }
    }
  }
`;

function index(props) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const isInappBrowser = props.isInappBrowser;

  const {
    resourceData,
    bookData,
    t,
    // resourceIdGa,
    // resourceIngestionIdGa,
    B2C,
    standalone,
    activity,
    // resData,
  } = props;
  console.log(bookData, resourceData, props);

  const entry = useSelector(
    (state) => state.EntryOfAssessmentReducer.entryPoints
  );
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );

  console.log("selections", selectOptions)
  
  
  const lastFistType =
    entry === "assignment"
      ? "LearnosityAttemptLastHistory"
      : "SelfLearnLastAttemptHistoryByStudyGroup";

  let resAssignId;
  if (standalone) {
    resAssignId = props.match.params.resId.split("&")[2];
  } else if (isInappBrowser && !standalone) {
    resAssignId = props.inappData.assignId;
  } else if (!isInappBrowser && !standalone) {
    //alert("coming");
    //resAssignId = props.match.params.resId;
    resAssignId = props.match.params.resId.split("&")[1];
  }
  

  let resourceId;
  if (isInappBrowser && !standalone) {
    resourceId = props.inappData.resourceId;
  } else {
    resourceId = props.match.params.resId.split("&")[0];
  }
  // favourite/unfavourite, score distribution code starts here
  const [sdlActiveClass, setSdlActiveClass] = useState("all");
  const [clickable, setClickable] = useState(false);
  const [classFlag, setClassFlag] = useState(0);
  const [favouriteValue, setFavouriteValue] = useState(0);
  const [favStudyGroupList, setfavStudyGroupList] = useState([]);
  const [favBookLevel, setfavBookLevel] = useState([]);
  const [showClassDropdown, setShowClassDropdown] = useState(false);
  const [showOverallDropdown, setShowOverallDropdown] = useState(false);

  const [classNameLoadings, setClassNameLoadings] = useState(true);
  const [studentListing, setStudentListing] = useState(false);
  //selectGroupClass ? selectGroupClass : selectClass.StudyGroupName
  const [selectGroupClass, setSelectGroupClass] = useState("");
  const [selectClass, setSelectClass] = useState([
    {
      StudyGroupName: "",
      StudyGroupId: "",
    },
  ]);
  const [ClassId, setClassId] = useState(userType == "T" ? "" : "");
  const classseries_dropdownRef = useRef(null);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        classseries_dropdownRef.current !== null &&
        !classseries_dropdownRef.current.contains(e.target) &&
        !document.querySelector(".Component1").contains(e.target)
      ) {
        setShowClassDropdown(false);
      }
    };
    if (showClassDropdown) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showClassDropdown]);

  const appendClass = () => {
    if (selectGroupClass && selectGroupClass.length <= 2) {
      return "Class";
    } else if (selectClass && selectClass.StudyGroupName) {
      return "Class";
    }
  };
  
  // favourite/unfavourite, score distribution code ends here
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  const [favouriteLoadingId, setFavouriteLoadingId] = useState('');

  const [studyGroupList, setstudyGroupList] = useState([]);
  const [openState, setopenState] = useState([]);
  const [questions, setquestions] = useState(null);
  const [tagsData, settagsData] = useState(null);
  const [charts, setcharts] = useState(null);
  const [loading, setloading] = useState(true);
  const [selectedStudyGroupId, setselectedStudyGroupId] = useState(null);

  const [selectedStudyGroupName, setselectedStudyGroupName] = useState("");
  const [bookPrizm, setbookPrizm] = useState(
    props.match.params.bookNunitId.split("&")[1]
  );
  const [showAssignpop, setshowAssignpop] = useState(true);
  const [selectedResAssignId, setselectedResAssignId] = useState(resAssignId);
  const [selectedReportType, setselectedReportType] = useState(lastFistType); //SelfLearnFirstAttemptHistoryByStudyGroup /  SelfLearnLastAttemptHistoryByStudyGroup
  const [selectedAssessType, setselectedAssessType] = useState("");
  const [assignmentList, setassignmentList] = useState({
    forSelectName: [],
    TagData: "",
  });
  const [assignmentData, setassignmentData] = useState({
    records: [],
    AssignmentTargets: [],
  });

  const [reportInfo, setreportInfo] = useState({
    dataLists: [],
    TagData: null,
  });

  useEffect(() => {
    //alert("coming")
    if (entry === "selfAssessment") {
      setselectedAssessType("All Result");
    } else if (entry === "assignment") {
      setselectedAssessType("Assignment");
    }
    if (
      selectOptions.setselectedResAssignId !== "" &&
      selectOptions.selectedStudyGroupId !== "" &&
      selectOptions.selectedReportType !== "" &&
      selectOptions.selectedAssessType !== ""
    ) {
      setselectedStudyGroupId(selectOptions.selectedStudyGroupId);
      setselectedResAssignId(selectOptions.selectedResAssignId);
      setselectedReportType(selectOptions.selectedReportType);
      setselectedAssessType(selectOptions.selectedAssessType);
    }
    setloading(true);
    getStudyGroupListAssign();
  }, []);

  const SaveSelectOptions = () => {
    console.log(studyGroupList);
    // dispatch({
    //   type: "SET_SELECTED_TYPE",
    //   selectOptions: {
    //     selectedAssessType: selectedAssessType,
    //     selectedStudyGroupId: selectedStudyGroupId,
    //     selectedResAssignId: selectedResAssignId,
    //     selectedReportType: selectedReportType,
    //   },
    // });
  };

  const getAssignmentStudyId = () => {
    var bookseriesId = bookData.BookSeriesId;
    let variables = {
      UserId: userData?.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
    };

    fetchQuery(
      environment,
      AllStudentResultTeacherStudyGroupQuery,
      variables
    ).then((data) => {
      console.log(data);
      console.log(bookseriesId);
      const { user } = data || {};

      const matchStudyGroup = user.School.StudyGroups.filter((data) => {
        return data.BookSeries.BookSeriesId === bookseriesId;
      });
      console.log(matchStudyGroup);
      const matchStudyGroupUnique = matchStudyGroup.reduce(
        (acc, current, i) => {
          acc.push({
            StudyGroupId: current.StudyGroupId,
            StudyGroupName: current.StudyGroupName,
          });

          // const inSameLevelList = current.StudyGroupInSameLevel.reduce(
          //   (acd, current) => {
          //     acd.push({
          //       StudyGroupId: current.StudyGroupId,
          //       StudyGroupName: current.StudyGroupName,
          //     });
          //     return acd;
          //   },
          //   []
          // );
          // let combined = acc.concat([...inSameLevelList]);
          return acc;
        },
        []
      );
      matchStudyGroupUnique.sort(function (a, b) {
        return a.StudyGroupName[
          a.StudyGroupName[0].Lang == lang ? 0 : 1
        ].StudyGroupName.localeCompare(
          b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
            .StudyGroupName
        );
      });
      console.log(matchStudyGroupUnique);
      const filteredArr = matchStudyGroupUnique.reduce((acc, current) => {
        const x = acc.find(
          (item) => item.StudyGroupId === current.StudyGroupId
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      console.log(filteredArr);
      // shoud not be happened
      if (filteredArr.length === 0) {
        return props.history.push("/m/myschool");
      } else {
        setstudyGroupList(filteredArr);
        //Book Favourite Selection

        if (userType == "T") {
          var favbooklevelvar = [];
          var preferencesObjB = dashboard_book_level_favorites(
            userData.Preferences
          );

          console.log("testtt", preferencesArrB);
          if (preferencesObjB.length > 0) {
            var preferencesArrB = JSON.parse(
              preferencesObjB[0].PreferenceValue
            );
            let filterList = preferencesArrB.filter(
              (o) =>
                o.BSId == bookPrizm &&
                o.SchoolYear == selectedSchoolYear.SchoolYearId
            );
            if (filterList.length > 0) {
              var obj = {
                BSId: bookPrizm,
                StructureId: filterList[0].StructureId,
              };
              favbooklevelvar.push(obj);
            }
            setfavBookLevel(favbooklevelvar);
          }
          var bookLevelId = "";
          var favStudyGroupListArr = [];
          var selectedLevelForBook = favbooklevelvar.filter(
            // (o) => o.BSId == selectedBookId
            (o) => o.BSId == bookPrizm
          );
          if (selectedLevelForBook.length > 0) {
            favStudyGroupListArr = filteredArr.filter((o) =>
              selectedLevelForBook[0].StructureId.includes(o.StudyGroupId)
            );
          }

          if (selectOptions.selectedStudyGroupId) {
            // alert(selectOptions.selectedStudyGroupId);
            setselectedStudyGroupId(selectOptions.selectedStudyGroupId);
            setselectedStudyGroupName(selectOptions.selectedStudyGroupName);
            setClassId(selectOptions.selectedStudyGroupId);
            setClassNameLoadings(false);
            setSelectGroupClass(selectOptions.selectedStudyGroupName);
            setSelectClass([
              {
                StudyGroupName: selectOptions.selectedStudyGroupName,
                StudyGroupId: selectOptions.selectedStudyGroupId,
              },
            ]);
          } else {
            if (favStudyGroupListArr.length > 0) {
              setselectedStudyGroupId(favStudyGroupListArr[0].StudyGroupId);
              setselectedStudyGroupName(
                favStudyGroupListArr[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                  .StudyGroupName
              );
              setClassId(favStudyGroupListArr[0].StudyGroupId);
              setClassNameLoadings(false);
              setSelectGroupClass(
                favStudyGroupListArr[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                  .StudyGroupName
              );
              setSelectClass([
                {
                  StudyGroupName:
                    favStudyGroupListArr[0].StudyGroupName[
                      [0].Lang == lang ? 0 : 1
                    ].StudyGroupName,
                  StudyGroupId: favStudyGroupListArr[0].StudyGroupId,
                },
              ]);
            } else {
              setselectedStudyGroupId(filteredArr[0].StudyGroupId);
              setselectedStudyGroupName(
                filteredArr[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                  .StudyGroupName
              );
              setClassId(filteredArr[0].StudyGroupId);
              setClassNameLoadings(false);
              setSelectGroupClass(
                filteredArr[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                  .StudyGroupName
              );
              setSelectClass([
                {
                  StudyGroupName:
                    filteredArr[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                      .StudyGroupName,
                  StudyGroupId: filteredArr[0].StudyGroupId,
                },
              ]);
            }
          }
          setfavStudyGroupList(favStudyGroupListArr);
        }
      }
    });
  };
  const getStudyGroupListAssign = () => {
    let variables;

    // if (isInappBrowser && !standalone) {
    //   variables = {
    //     ResourceId: [resourceId],
    //     SchoolYearId: localStorage.getItem("selectedSchoolYear"),
    //   };
    // } else {
    variables = {
      ResourceId: [resourceId],
      SchoolYearId: selectedSchoolYear.SchoolYearId,
    };
    // }

    StandAloneAssignment(variables).then((data) => {
      console.log(data);
      const { resourceSearch } = data || {};
      const bookseriesId = resourceSearch.Resources[0].BookSeriesId;
      const assignmentList =
        resourceSearch.Resources[0].AssignementInvloved.reduce(
          (acc, current) => {
            const { AssignmentName, ResourceAssignId } = current;
            acc.push({
              AssignmentName: AssignmentName,
              ResourceAssignId: ResourceAssignId,
            });
            return acc;
          },
          []
        );

      const tagData = JSON.parse(resourceSearch.Resources[0].TagData);
      console.log(tagData);
      getAssignmentStudyId(bookseriesId);
          if(assignmentList.length==0){
            setshowAssignpop(false);
          }
      setassignmentList({
        forSelectName: assignmentList,
        TagData: tagData,
      });
      if (resAssignId) {
        HandleSelectAssignmentName(resAssignId);
      } else {
        setloading(false);
      }
    });
  };

  useEffect(()=>{
    console.log("selectedResAssignId",selectedResAssignId);
    if(selectedResAssignId){
      HandleSelectAssignmentName(selectedResAssignId);
    }
  },[selectedResAssignId]);

  const HandleSelectAssignmentName = (resAssignId) => {
    let variables;
    variables = {
      ResourceAssignId: resAssignId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
    };

    fetchQuery(
      environment,
      AllStudentResultGetAssignementNameQuery,
      variables
    ).then((data) => {
      const {
        resourceAssignment: { Resources = [{}], AssignmentTargets = [{}] },
      } = data || {};
      console.log(Resources);
      const findSameRes = Resources.filter((data) => {
        return data.ResourceId === resourceId;
      });
      console.log(findSameRes);
      setassignmentData({
        records: findSameRes,
        AssignmentTargets: AssignmentTargets,
      });

      setloading(false);
    });
  };

  const handleOpenTab = (id, type) => {
    var newOpenState = [...openState];
    if (openState.indexOf(id) == -1) {
      try {
        if (type == "suggestion") {
          if (!standalone) {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Expand suggestion",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                (B2C ? "NoData" : bookData.Level.LevelName[0].LevelName) +
                ", Book Series:" +
                (B2C
                  ? bookData.BookSeriesName[0].BookSeriesName
                  : bookData.BookSeries.BookSeriesName[0].BookSeriesName) +
                ", Book:" +
                (B2C ? bookData.Books[0].BookName[0].BookName : book) +
                ", Chapter:" +
                (B2C ? "NoData" : chapter) +
                ", Role:" +
                userType +
                `, From:${B2C ? "Supplementary" : "My School"}`
            );
          } else {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Expand suggestion",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                "NoData" +
                ", Book Series:" +
                (B2C
                  ? bookData.BookSeriesName[0].BookSeriesName
                  : bookData.BookSeries.BookSeriesName[0].BookSeriesName) +
                ", Book:" +
                "NoData" +
                ", Chapter:" +
                "NoData" +
                ", Role:" +
                userType
            );
          }
        } else {
          if (!standalone) {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Expand learning objective",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                (B2C ? "NoData" : bookData.Level.LevelName[0].LevelName) +
                ", Book Series:" +
                (B2C
                  ? bookData.BookSeriesName[0].BookSeriesName
                  : bookData.BookSeries.BookSeriesName[0].BookSeriesName) +
                ", Book:" +
                (B2C ? bookData.Books[0].BookName[0].BookName : book) +
                ", Chapter:" +
                (B2C ? "NoData" : chapter) +
                ", Role:" +
                userType +
                `, From:${B2C ? "Supplementary" : "My School"}`
            );
          } else {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Expand learning objective",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                "NoData" +
                ", Book Series:" +
                (B2C
                  ? bookData.BookSeriesName[0].BookSeriesName
                  : bookData.BookSeries.BookSeriesName[0].BookSeriesName) +
                ", Book:" +
                "NoData" +
                ", Chapter:" +
                "NoData" +
                ", Role:" +
                userType
            );
          }
        }
      } catch (error) {
        console.log("[GA]", error);
      }
      newOpenState.push(id);
      var height = 0;
      for (var i = 0; i < document.getElementById(id).children.length; i++) {
        height = height + document.getElementById(id).children[i].offsetHeight;
      }
      document.getElementById(id).style.height = height + "px";
      setopenState(newOpenState);
    } else {
      try {
        if (type == "suggestion") {
          if (!standalone) {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Collapse suggestion",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                (B2C
                  ? "NoData"
                  : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
                ", Book Series:" +
                bookData.BookSeriesName[0].BookSeriesName +
                ", Book:" +
                bookData.Books[0].BookName[0].BookName +
                ", Chapter:" +
                (bookData.Books && bookData.Books.length > 0
                  ? bookData.Books[0].Units[0].UnitName[0].UnitName
                  : "NoData") +
                ", Role:" +
                userType +
                `, From:${B2C ? "Supplementary" : "My School"}`
            );
          } else {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Collapse suggestion",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                "NoData" +
                ", Book Series:" +
                bookData.BookSeriesName[0].BookSeriesName +
                ", Book:" +
                "NoData" +
                ", Chapter:" +
                "NoData" +
                ", Role:" +
                userType
            );
          }
        } else {
          if (!standalone) {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Collapse learning objective",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                (B2C
                  ? "NoData"
                  : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
                ", Book Series:" +
                bookData.BookSeriesName[0].BookSeriesName +
                ", Book:" +
                bookData.Books[0].BookName[0].BookName +
                ", Chapter:" +
                (bookData.Books && bookData.Books.length > 0
                  ? bookData.Books[0].Units[0].UnitName[0].UnitName
                  : "NoData") +
                ", Role:" +
                userType +
                `, From:${B2C ? "Supplementary" : "My School"}`
            );
          } else {
            gaTrack(
              "send",
              "event",
              "Assessment",
              "Collapse learning objective",
              "Assessment:" +
                resourceData.ResourceLangs[0].ResourceName +
                ", Resource ID:" +
                resourceData.IngestionId +
                ", Level:" +
                "NoData" +
                ", Book Series:" +
                bookData.BookSeriesName[0].BookSeriesName +
                ", Book:" +
                "NoData" +
                ", Chapter:" +
                "NoData" +
                ", Role:" +
                userType
            );
          }
        }
      } catch (error) {
        console.log("[GA]", error);
      }

      document.getElementById(id).style.height = 0;
      newOpenState.splice(openState.indexOf(id), 1);
      setopenState(newOpenState);
    }
  };

  const handleReportData = (array, charts, tagsData, _tempInfo) => {
    setcharts(charts);
    setquestions(array);
    settagsData(tagsData);
    setreportInfo({
      dataLists: _tempInfo.dataLists,
      TagData: _tempInfo.TagData,
    });
    //SaveSelectOptions();
    if (!isInappBrowser) {
      window.document
        .getElementById("studygroup_back")
        ?.classList.remove("dim-button");
    }
    // window.document
    //   .getElementById("assessment_tab")
    //   ?.classList.remove("dim-button");
    // window.document.getElementById("result_tab").classList.remove("dim-button");
    // if (userType == "T") {
    //   window.document
    //     .getElementById("answer_tab")
    //     ?.classList.remove("dim-button");
    // }
  };

  const onClickQuestion = (item) => {
    try {
      if (!standalone) {
        gaTrack(
          "send",
          "event",
          "Assessment",
          "Click question performance",
          "Question:" +
            item +
            ", Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            (B2C
              ? "NoData"
              : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            bookData.Books[0].BookName[0].BookName +
            ", Chapter:" +
            (bookData.Books && bookData.Books.length > 0
              ? bookData.Books[0].Units[0].UnitName[0].UnitName
              : "NoData") +
            ", Role:" +
            userType
        );
      } else {
        gaTrack(
          "send",
          "event",
          "Assessment",
          "Click question performance",
          "Question:" +
            item +
            ", Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            "NoData" +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            "NoData" +
            ", Chapter:" +
            "NoData" +
            ", Role:" +
            userType
        );
      }
    } catch (error) {
      console.log("[GA]", error);
    }

    var clickedSetting = {"selectedAssessType": selectedAssessType,
                      "selectedStudyGroupId": selectedStudyGroupId, 
                      "selectedReportType": selectedReportType,
                    }
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {
        selectedStudyGroupId: selectedStudyGroupId,
        selectedStudyGroupName: selectedStudyGroupName,
       // selectedStudyGroupName:props.location.state ? props.location.state.classGroupName : selectOptions.selectedStudyGroupName,
        selectedReportType: selectedReportType,
        selectedAssessType: selectedAssessType,
        setselectedResAssignId:""
      },
    });

    props.history.push({
      pathname: props.location.pathname + "/q/" + item,
      state: { selectOptions: clickedSetting, questions: questions.length, qpClassGroup: selectedStudyGroupName},
    });
  };
  const FindReportType = () => {
    if (
      selectedReportType == "LearnosityAttemptLastHistory" &&
      (selectedAssessType == "All Result" ||
        selectedAssessType == "Self Assessment")
    ) {
      setselectedReportType("SelfLearnLastAttemptHistoryByStudyGroup");
    } else if (
      selectedReportType == "SelfLearnLastAttemptHistoryByStudyGroup" &&
      selectedAssessType == "Assignment"
    ) {
      setselectedReportType("LearnosityAttemptLastHistory");
    } else if (
      selectedReportType == "SelfLearnFirstAttemptHistoryByStudyGroup" &&
      selectedAssessType == "Assignment"
    ) {
      setselectedReportType("LearnosityAttemptFirstHistory");
    } else if (
      selectedReportType == "LearnosityAttemptFirstHistory" &&
      (selectedAssessType == "All Result" ||
        selectedAssessType == "Self Assessment")
    ) {
      setselectedReportType("SelfLearnFirstAttemptHistoryByStudyGroup");
    } else {
      return selectedReportType;
    }
  };

  console.log(loading, charts, questions, tagsData);

  const ExportQuestionsRow = () => {
    const basicRows = [
      t("Class"),
      t("Name"),
      t("Class no."),
      t("Completed Date"),
      t("Score"),
    ];

    let questionsRows = [];

    for (let i = 0; i < questions.length; i++) {
      questionsRows.push(t(`Q`) + (i + 1));
    }
    console.log(questionsRows);

    const finalRow = basicRows.concat(questionsRows);
    console.log(finalRow);
    return finalRow;
  };
  const HandelReportExcell = () => {
    try {
      if (!standalone) {
        gaTrack(
          "send",
          "event",
          "Assessment",
          "Export report",
          "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            (B2C
              ? "NoData"
              : bookData.Books[0].PearsonLevel.LevelName[0].LevelName) +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            bookData.Books[0].BookName[0].BookName +
            ", Chapter:" +
            (bookData.Books && bookData.Books.length > 0
              ? bookData.Books[0].Units[0].UnitName[0].UnitName
              : "NoData") +
            ", Role:" +
            userType +
            ", Type:" +
            "Class"
        );
      } else {
        gaTrack(
          "send",
          "event",
          "Assessment",
          "Export report",
          "Assessment:" +
            resourceData.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            resourceData.IngestionId +
            ", Level:" +
            "NoData" +
            ", Book Series:" +
            bookData.BookSeriesName[0].BookSeriesName +
            ", Book:" +
            "NoData" +
            ", Chapter:" +
            "NoData" +
            ", Role:" +
            userType +
            ", Type:" +
            "Assignment"
        );
      }
    } catch (error) {
      console.log(error);
    }

    console.log(selectedAssessType, assignmentList);
    let teacherReportTableData = [],
      attemptsData = [],
      questionCount = 0;
    console.log(reportInfo);
    const type =
      selectedAssessType === "Assignment"
        ? "LearnosityAttemptHistory"
        : "SelfLearnAttemptHistoryByStudyGroup";
    reportInfo.dataLists[0][type].map((attm, index) => {
      var json_data = JSON.parse(attm.LearnositySessionRawResult);
      console.log(json_data);
      questionCount = json_data.items.length;
      let _temp;
      if (selectedAssessType === "Assignment") {
        _temp =
          attm.UserDetail.BelongsToStudyGroup[0].StudyGroup.StudyGroupName;
      }

      attemptsData.push({
        StudyGroupName:
          selectedAssessType === "Assignment"
            ? _temp[_temp[0].Lang === lang ? 0 : 1].StudyGroupName
            : "",
        ClassNumber: attm.UserDetail.BelongsToStudyGroup[0].ClassNumber,
        UserId: attm.UserDetail.UserId,
        user_id: json_data.user_id,
        UserDisplayName: attm.UserDetail.UserDisplayName,
        AttemptDate: attm.AttemptDate,
        AttemptHistory: {
          session_id: json_data.session_id,
          QuestionsData: [...json_data.items],
          score: json_data.score,
          max_score: json_data.max_score,
        },
      });
    });
    console.log(attemptsData);

    let resName,
      bookName,
      unitName,
      findSameRecord,
      whichClass,
      maxScored,
      studyName;

    resName =
      resourceData.ResourceLangs[
        resourceData.ResourceLangs[0].Lang == lang ? 0 : 1
      ].ResourceName;
    if (entry === "selfAssessment") {
      bookName = bookData.Books[0].BookName[0].BookName;
      unitName = bookData.Books[0].Units[0].UnitName[0].UnitName;
    }

    if (selectedAssessType === "Assignment") {
      findSameRecord = assignmentList.forSelectName.filter((obj) => {
        return obj.ResourceAssignId === selectedResAssignId;
      });
    } else {
      whichClass = studyGroupList.filter((data) => {
        return data.StudyGroupId === selectedStudyGroupId;
      });
      studyName =
        whichClass[0].StudyGroupName[
          whichClass[0].StudyGroupName[0].Lang === lang ? 0 : 1
        ].StudyGroupName;
    }
    console.log(teacherReportTableData);

    const studentsList = attemptsData.reduce((acc, current) => {
      let _tempRes = [];

      console.log(loading, charts, questions, tagsData);
      const {
        StudyGroupName,
        ClassNumber,
        UserDisplayName,
        AttemptDate,
        AttemptHistory,
      } = current;

      if (AttemptHistory.QuestionsData.length > 0) {
        maxScored = AttemptHistory.max_score;
      }

      const answerList = AttemptHistory.QuestionsData.reduce((acd, curr) => {
        let ans = "";
        const { scoring } = curr;

        if (!scoring.attempted) {
          ans = t("Not Attemptted");
        } else if (scoring.max_score != scoring.score) {
          if (scoring.score != 0) {
            ans = t("Partial Correct");
          } else {
            ans = t("Incorrect");
          }
        } else {
          ans = t("Correct");
        }
        acd.push(ans);
        return acd;
      }, []);
      console.log(answerList);
      const userName =
        UserDisplayName[UserDisplayName[0].Lang === lang ? 0 : 1].UserName;

      _tempRes.push(
        selectedAssessType === "Assignment" ? StudyGroupName : studyName,
        userName,
        ClassNumber,
        AttemptDate == null ? t("Not Attemptted") : AttemptDate,
        AttemptHistory.score,
        ...answerList
      );

      console.log(_tempRes);

      acc.push(_tempRes);
      return acc;
    }, []);

    const excelData = [
      [t("Title"), resName],
      !standalone ? [t("Group"), bookName + " - " + unitName] : "noData",
      [
        t("Type"),
        !selectedResAssignId ? t("Self Assessment") : t(selectedAssessType),
      ],
      [
        selectedAssessType == "Assignment" ? t("Assignment Name") : t("Class"),
        selectedAssessType == "Assignment"
          ? findSameRecord[0].AssignmentName
          : studyName,
      ],
      [t("Attempt"), t("All Attempt")],
      [t("No. of question"), questions.length],
      [t("Max score"), maxScored],
      null,
      null,
      ExportQuestionsRow(),
    ];
    const tempFilter = excelData.filter((o) => o !== "noData");
    const finalList = tempFilter.concat(studentsList);

    ExportDataToXlsx("AssessmentReports", finalList);
  };

    const toggleClickable = (val) => {
    if (val == 1) {
      setClickable(true);
    } else {
      setClickable(false);
    }
  };
  
  const toggleCheckResource = (
    e,
    bookSeriesIdArg,
    structureIdArg,
    val,
    bkName
  ) => {
    //alert(bookSeriesIdArg);
    e.stopPropagation();
    setFavouriteLoading(true)
    toggleClickable(1); //disable other action till the action completed
    console.log("newvals", bookSeriesIdArg, structureIdArg, val, bkName);
    // setresFavIds((prev) => ({
    //   ...prev,
    //   [structureIdArg]: val === "yes" ? true : false,
    // }));
    // const val = true ? 'yes': 'no'
    var respreferencesArr = [];
    //setmyResFav
    var latestStructureId = [];
    var preferencesObj = dashboard_book_level_favorites(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on adding to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        console.log("filterList", filterList);

        if (filterList.length > 0) {
          let updateStructureList = [];
          //'27210', '27209', '27211', '27212'
          filterList[0].StructureId.forEach(function (id, index) {
            updateStructureList.push(id);
          });
          updateStructureList.push(structureIdArg);
          console.log("added", updateStructureList);
          var updateObj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(updateStructureList)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };

          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          const indx = localrespreferencesArr.findIndex(
            (v) =>
              v.BSId == bookSeriesIdArg &&
              v.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          console.log(indx);
          console.log("aamer1", localrespreferencesArr);
          localrespreferencesArr.splice(indx, indx >= 0 ? 1 : 0);
          console.log("aamer1 splice", localrespreferencesArr);
          localrespreferencesArr.push(updateObj);
          console.log("aamer2", localrespreferencesArr);
          //console.log("suresh",localrespreferencesArr);
          respreferencesArr = [...localrespreferencesArr];
          latestStructureId = [...new Set(updateStructureList)];
        } else {
          console.log("suresh-exists -filterList.length<=0");
          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          let structureVal = [];
          structureVal.push(structureIdArg);
          var obj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(structureVal)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          localrespreferencesArr.push(obj);
          respreferencesArr = [...localrespreferencesArr];
          //respreferencesArr.push(localrespreferencesArr);
          latestStructureId = [...new Set(structureVal)];
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on removing to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        console.log("bremoved filter", filterList);
        //Removing From Structure
        let updateStructureList = [];
        filterList[0].StructureId.forEach(function (id, index) {
          updateStructureList.push(id);
        }); //\"27212\",\"27213\",\"27214\",\"27209\
        console.log("bremoved", updateStructureList);
        const indx = updateStructureList.findIndex((v) => v == structureIdArg);
        updateStructureList.splice(indx, indx >= 0 ? 1 : 0);
        console.log("removed", updateStructureList);
        //Removing From BookSeries
        let localrespreferencesArr = JSON.parse(
          preferencesObj[0].PreferenceValue
        );
        const bindx = localrespreferencesArr.findIndex(
          (v) => v.BSId == bookSeriesIdArg
        );
        console.log("aamer3 before", localrespreferencesArr);
        localrespreferencesArr.splice(bindx, bindx >= 0 ? 1 : 0);
        var updateObj = {
          BSId: bookSeriesIdArg,
          StructureId: [...new Set(updateStructureList)],
          SchoolYear: selectedSchoolYear.SchoolYearId,
        };
        console.log("aamer3 after", localrespreferencesArr);
        localrespreferencesArr.push(updateObj);
        respreferencesArr = [...localrespreferencesArr];
        /// respreferencesArr.push(localrespreferencesArr);
        latestStructureId = [...new Set(updateStructureList)];
      }
    } else {
      let structureVal = [];
      structureVal.push(structureIdArg);
      var obj = {
        BSId: bookSeriesIdArg,
        StructureId: structureVal,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
      respreferencesArr.push(obj);
      latestStructureId = structureVal;
    }
    console.log("latestStructureId", latestStructureId);

    var data = {
      PreferenceName: "DASHBOARD_BOOK_LEVEL_NEW_FAVORITES",
      // PreferenceValue: JSON.stringify([]),
      PreferenceValue: JSON.stringify(respreferencesArr),
    };
    //setFavorites(respreferencesArr);
    //setmyResFav([...new Set(latestStructureId)]);
    console.log("data", data);
    const callback = (result) => {
      console.log("result", result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });
      localStorage.setItem("userData", JSON.stringify(userData));
      console.log("ddd", respreferencesArr);
      setTimeout(() => {
        setFavouriteLoading(false)
        toggleClickable(0); //enable once the action completed
      }, 2000);
    };
    var favStudyGroupListArr = [];
    var selectedLevelForBook = respreferencesArr.filter(
      // (o) => o.BSId == selectedBookId
      // (o) => o.BSId == props.location.state.selectedBookId
      // (o) => o.BSId == bookData.BookId
      (o) => o.BSId == bookData.Books[0].BookId
    );
    if (selectedLevelForBook.length > 0) {
      favStudyGroupListArr = studyGroupList.filter((o) =>
        selectedLevelForBook[0].StructureId.includes(o.StudyGroupId)
      );
    }
    // console.log("qwerty--studyGroupList", studyGroupList);
    // console.log("qwerty--favStudyGroupListArr", favStudyGroupListArr);
    // console.log("qwerty--respreferencesArr", respreferencesArr);
    // console.log("qwerty--selectedBookId", props.location.state.selectedBookId);
    // console.log('qwerty-bookData',bookData)
    setfavStudyGroupList(favStudyGroupListArr);
    setfavBookLevel(respreferencesArr);
    UpdateUserPreferencesMutation(data, callback);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      
      <div className={`report-box report-box-main-section  ${
          clickable ? "not-clickable" : ""
        }`}>
      <div className="filter-row select-tab-excell">
          
          <div className="main-year-drop-down-sdl">
            {classNameLoadings ? (
                      <div className="class-dropdown-loader-alignment">
                        <Loading type={`normal`} />
                      </div>
                    ) : (
            <span
              class="year-drop-down-sdl-result"
              onClick={() => {
                setShowClassDropdown(!showClassDropdown);
                // setShowClassData([favBookData.BookId]);
              }}
              ref={classseries_dropdownRef}
              title={`${t(appendClass())} ${
                selectGroupClass ? selectGroupClass : selectClass.StudyGroupName
              }`}
            >
              {`${t(appendClass())} ${
                selectGroupClass ? selectGroupClass : selectClass.StudyGroupName
              }`}
            </span>)}
            {/* {(selectedAssessType === "All Result" ||
              selectedAssessType === "Self Assessment") && (
              <select
                disabled={!loading && charts && questions ? false : true}
                onChange={(e) => {
                  setselectedStudyGroupId(e.target.value);
                  setcharts(null);
                  SaveSelectOptions();
                }}
                value={selectedStudyGroupId || ""}
              >
                {studyGroupList &&
                  studyGroupList.map((obj, index) => {
                    return (
                      <option key={index} value={obj.StudyGroupId}>
                        {
                          obj.StudyGroupName[
                            obj.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                        }
                      </option>
                    );
                  })}
              </select>
            )} */}
          </div>

          {showClassDropdown ? (
            <div className="Component1 main-component2">
              <div className="main-container">
                <div
                  onClick={() => {
                    setFavouriteValue(1);
                    setSdlActiveClass("all");
                  }}
                  className={`static-tab-dropdown-one1 static-tab-dropdown-all ${
                    sdlActiveClass == "all" ? "active-tab-dropdown1" : ""
                  }`}
                >
                  {t("All")}
                </div>
                <div
                  onClick={() => {
                    setFavouriteValue(0);
                    setSdlActiveClass("favourite");
                  }}
                  className={`static-tab-dropdown-one1 static-tab-dropdown-favourite ${
                    sdlActiveClass == "favourite" ? "active-tab-dropdown1" : ""
                  }`}
                >
                  {t("Favourites")}
                </div>
              </div>
              {sdlActiveClass == "all" &&
                studyGroupList.map((eleClass) => {
                  var selectedLevelForBook = favBookLevel.filter(
                    (o) => o.BSId == bookPrizm
                  );
                  var heartIcon = false;
                  if (selectedLevelForBook.length > 0) {
                    if (
                      selectedLevelForBook[0].StructureId.includes(
                        eleClass.StudyGroupId
                      )
                    ) {
                      heartIcon = true;
                    }
                  }
                  console.log("heartIcon", heartIcon, selectedLevelForBook);

                  return (
                    <div className="main-class-container1">
                      <div
                        className="class-rows class-rows1"
                        onClick={() => {
                          if(selectedStudyGroupId == eleClass.StudyGroupId){
                            setClassNameLoadings(true)
                            setStudentListing(true)
                            setClassFlag(classFlag+1)
                            setShowClassDropdown(false);
                            setTimeout(() => {
                              setClassNameLoadings(false)
                              setStudentListing(false)
                            }, 1000);
                            return;
                          }
                          setSelectClass([
                            {
                              StudyGroupName:
                                eleClass.StudyGroupName[
                                  eleClass.StudyGroupName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StudyGroupName,
                              StudyGroupId: eleClass.StudyGroupId,
                            },
                          ]);
                          setSelectGroupClass(
                            eleClass.StudyGroupName[
                              eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                            ].StudyGroupName
                          );
                          setClassId(eleClass.StudyGroupId);
                          setShowClassDropdown(false);
                          // alert(eleClass.StudyGroupId)
                          setselectedStudyGroupId(eleClass.StudyGroupId);
                          // alert(selectedStudyGroupId)
                          setselectedStudyGroupName(
                            "Class " +
                              eleClass.StudyGroupName[
                                eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                              ].StudyGroupName
                          );
                          setClassFlag(classFlag+1)
                          setcharts(null);
                          SaveSelectOptions(eleClass.StudyGroupId,"Class " +
                          eleClass.StudyGroupName[
                            eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName);
                          // setShowClassData([]);
                        }}
                      >
                        {`${t("Class")} ${
                          eleClass.StudyGroupName[
                            eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                        }`}
                      </div>

                      {!heartIcon ? (
                        <div className="like-rows like-rows1">
                          {favouriteLoading && favouriteLoadingId == eleClass.StudyGroupId ? <Loading type={`favourite`} /> :<img
                            src={likeIcon}
                            onClick={(e) => {
                              setFavouriteLoadingId(eleClass.StudyGroupId)
                              toggleCheckResource(
                                e,
                                bookPrizm,
                                eleClass.StudyGroupId,
                                "yes",
                                eleClass.StudyGroupName[
                                  eleClass.StudyGroupName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StudyGroupName
                              );
                            }}
                          />}
                        </div>
                      ) : (
                        <div className="like-rows like-rows1">
                          {favouriteLoading && favouriteLoadingId == eleClass.StudyGroupId ? <Loading type={`favourite`} /> :<img
                            src={likedIcon}
                            onClick={(e) => {
                              setFavouriteLoadingId(eleClass.StudyGroupId)
                              toggleCheckResource(
                                e,
                                bookPrizm,
                                eleClass.StudyGroupId,
                                "no",
                                eleClass.StudyGroupName[
                                  eleClass.StudyGroupName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StudyGroupName
                              );
                            }}
                          />}
                        </div>
                      )}
                    </div>
                  );
                })}

              {sdlActiveClass == "favourite" &&
              favStudyGroupList.length <= 0 ? (
                <label className="empty-favourite-mesaage">
                  {t("No favourite classes added yet.")}
                </label>
              ) : (
                ""
              )}
              {sdlActiveClass == "favourite" &&
                favStudyGroupList.map((eleClass) => {
                  return (
                    <div className="main-class-container1">
                      <div
                        className="class-rows class-rows1"
                        onClick={() => {
                          if(selectedStudyGroupId == eleClass.StudyGroupId){
                            setClassNameLoadings(true)
                            setStudentListing(true)
                            setClassFlag(classFlag+1)
                            setShowClassDropdown(false);
                            setTimeout(() => {
                              setClassNameLoadings(false)
                              setStudentListing(false)
                            }, 1000);
                            return;
                          }
                          setSelectClass([
                            {
                              StudyGroupName:
                                eleClass.StudyGroupName[
                                  eleClass.StudyGroupName[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StudyGroupName,
                              StudyGroupId: eleClass.StudyGroupId,
                            },
                          ]);
                          setSelectGroupClass(
                            eleClass.StudyGroupName[
                              eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                            ].StudyGroupName
                          );
                          console.log("aamestudygroup", selectClass);
                          setShowClassDropdown(false);
                          setselectedStudyGroupId(eleClass.StudyGroupId);
                          // alert(selectedStudyGroupId)
                          setselectedStudyGroupName(
                            "Class " +
                              eleClass.StudyGroupName[
                                eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                              ].StudyGroupName
                          );
                          setcharts(null);
                          setClassFlag(classFlag+1)
                          SaveSelectOptions(eleClass.StudyGroupId, "Class " +
                          eleClass.StudyGroupName[
                            eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName);

                          // setShowClass(false);
                          // setShowClassData([]);
                        }}
                      >
                        {`${t("Class")} ${
                          eleClass.StudyGroupName[
                            eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                          ].StudyGroupName
                        }`}
                      </div>

                      <div className="like-rows like-rows1">
                        <img
                          src={likedIcon}
                          onClick={(e) => {
                            toggleCheckResource(
                              e,
                              bookPrizm,
                              eleClass.StudyGroupId,
                              "no",
                              eleClass.StudyGroupName[
                                eleClass.StudyGroupName[0].Lang == lang ? 0 : 1
                              ].StudyGroupName
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/* {!loading && charts && !window.cordova && !isInappBrowser && (
            <button
              className="btn btn-primary"
              onClick={() => {
                HandelReportExcell();
              }}
            >
              <img src={download_excel} />
              {t("Download")}
            </button>
          )} */}
          <div className="distribution-section">
            <h3 className="distribution-section-score">{t("Score Distribution")}</h3>
            <div className="distribution-section-bottom">
              <label className="distribution-section-bottom-submitted">
                {t("Submitted")}:
              </label>
              {!classNameLoadings && charts ? (
                <label className="distribution-section-bottom-total">
                  {charts.noOfSubmit ? charts.noOfSubmit : 0} {t("student(s)")}
                </label>
              ) : (
                <label className="distribution-section-bottom-total">
                  <>
                    <Loading type={"normal"} />
                    <br />
                  </>
                </label>
              )}
            </div>
          </div>
        </div>
        {!classNameLoadings && charts ? (
          <div className="chart-row">
            <Chart {...props} charts={charts} />
          </div>
        ) : (
          <>
            <Loading type={"normal"} />
            <br />
          </>
        )}
        {!loading && (

<section className="question__container">
<h3 className="question__head">{t("Question Performance")}</h3>
<div className="question__numbers">
{questions != null || tagsData != null ? (
                questions ? (
                  questions.length != 0 ? (
                    questions.map((item, index) => {
                      return (
    // <div className="question__number" onClick={() => onClickQuestion(index + 1)}>Q{index + 1}</div>);
    <div className="question__number" onClick={() => onClickQuestion(index + 1)}>{t("Item")} &nbsp;{index + 1}</div>);
  }) ) : (
    <div className="no-data">{t("No submission record")}</div>
  ) ) : (
    <div className="no-data">{t("No submission record")}</div>
  )
) : (
  <div className="question-loading">
    {t("No submission record")}
  </div>
)}
  
</div>
</section>



         
        )}

        <div className="type-row">
          {/* {this.renderDummyData()} */}
          {/* {!loading   && (
            <FocusSubfocus
              {...props}
              openState={openState}
              questions={questions}
              tagsData={tagsData}
              // resourceData={resourceData}
              // studyGroupData={studyGroupData}
              // resourceIdGa={resourceIdGa}
              // B2C={B2C}
              // standalone={standalone}
            />
          )} */}
        </div>
        {!loading && (
          <Suggestion
            action={"all_result"}
            openState={openState}
            handleOpenTab={handleOpenTab}
            selectedStudyGroupId={selectedStudyGroupId}
            bookData={bookData}
            activity={activity}
            // B2C={B2C}
            resourceId={resourceId}
            inappData={props.inappData}
            {...props}
          />
        )}

        <div className="all-result-row">
        {studentListing ? <Loading type={`normal`} /> : <StudentList
            {...props}
            selectedStudyGroupId={selectedStudyGroupId}
            classGroup={selectedStudyGroupName}
            selectedResAssignId={selectedResAssignId}
            handleReportData={handleReportData}
            questions={questions}
            // isInappBrowser={props.isInappBrowser}
            selectedReportType={selectedReportType}
            // resourceData={resourceData}
            selectedAssessType={selectedAssessType}
            assignmentList={assignmentList}
            assignmentData={assignmentData}
            // standalone={standalone}
            resourceId={resourceId}
            classFlag={classFlag}
            // studyGroupData={studyGroupData}
          />}
        </div>
      </div>
    </>
  );
}
export default index;
