import moment from "moment";
const GetDueDateStyle = (dateval, fontWeightBool = false) => {
  if (moment(moment().format("YYYY-MM-DD")).diff(dateval) > 0) {
    if (fontWeightBool) {
      return {
        fontWeight: 600,
        color: "#db0020",
      };
    }
    return {
      color: "#db0020",
    };
  }
  return {
    color: "#000000",
  };
};

export default GetDueDateStyle;
