import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class Guest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return (
      <div className="class-nb-buttons">
        <div
          className="nav navbar-nav float-right be-user-nav"
          id="be-navbar-collapse"
        >
          <ul className="navbar-nav">
            {localStorage.getItem("i18nextLng") == "en-US" ? (
              <li className="nav-item">
                <a>
                  <button
                    type="button"
                    onClick={() => i18n.changeLanguage("en-US")}
                    className="link-button nav-link"
                  >
                    {t("Chinese")}
                  </button>
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <a>
                  <button
                    type="button"
                    onClick={() => i18n.changeLanguage("zh-HK")}
                    className="link-button nav-link ss"
                  >
                    {t("English")}
                  </button>
                </a>
              </li>
            )}

            <li className="nav-item">
              <NavLink to="/login">
                <button type="button" className="link-button nav-link">
                  {t("Login")}
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Guest;
