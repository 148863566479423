import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import rvideoicon from "../../../../../assets/img/B2C/Revision_Video.svg";
import saicon from "../../../../../assets/img/B2C/Start_Assessment.svg";
import raicon from "../../../../../assets/img/B2C/Result_Answer.svg";
import aficon from "../../../../../assets/img/B2C/Analysis_FollowUp.svg";
// import temp from "../../../../../assets/img/B2C/icon-video-link.svg";
import PropTypes from "prop-types";
import "./index.scss";
import B2CAssessmentList from "../../../../../Services/api/GraphQL/Query/B2CAssessmentList";
import GetEPSToken from "../../../../../Services/api/GraphQL/Query/GetEPSToken";

import VidoeLightBox from "../../../../../Components/Feature/VideoLightBox";
import ImageLightBox from "../../../../../Components/Feature/ImageLightBox";
import { isSafari } from "react-device-detect";
import Loading from "../../../../../Components/Loading/Loading";
import { suggestionTypeIconMapping } from "../../../../..//Services/Common/ResourcesTypeIcon";
import { config } from "../../../../../Config/config";

var browserRef = null;

const AssessmentList = (props) => {
  const { t, BookName, BookSeries, B2C } = props;
  // console.log(props, "Props");
  const [overlayLoading, setoverlayLoading] = useState(false);
  const [topic, settopic] = useState(null);
  const [data, setdata] = useState(null);

  const [fetchingEPSResourceToken, setfetchingEPSResourceToken] =
    useState(false);
  const [VideoLightBoxOpenState, setVideoLightBoxOpenState] = useState(null);
  const [ImageLightBoxOpenState, setImageLightBoxOpenState] = useState(null);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const [boxs, setboxs] = useState([]);
  useEffect(() => {
    console.log(props.match.params.subid.split("+"), BookName, BookSeries);
    if (props.match.params?.subid?.split("+").length == 5) {
      //fetch query with book id & HierarchyReference
      getPageData();
    } else {
      return props.history.replace(
        "/c/myresview/" +
          props.match.params.id +
          "/" +
          props.match.params.book +
          "/assessments"
      );
    }
  }, []);

  useEffect(() => {
    console.log(topic);
    if (topic != null) {
      let contents = [];
      //1:pre videos
      let allTagsPreRes = [];
      data.learnosityTagHierarchyStructure[0].RelatedTagType.map(
        (obj, tags_i) => {
          obj.Tags.map((tag) => {
            if (tag.TopicPreStudyResources.Resources) {
              tag.TopicPreStudyResources.Resources.map((r) =>
                allTagsPreRes.push(r)
              );
            }
          });
        }
      );
      console.log(allTagsPreRes);
      if (allTagsPreRes.length > 0) {
        let _video_content = [];
        // _video_content.push(
        //   <div className="img_w_text">
        //     {/* <img src={rvideoicon}></img> */}
        //     <div className="title">{t("Revision Video​")}</div>
        //   </div>
        // );
        allTagsPreRes.map((tagRes) => {
          _video_content.push(
            <div
              className="item"
              onClick={() => onClickFollowUp({ Resource: tagRes }, "RBA")}
            >
              <div className="title">
                {
                  tagRes.ResourceLangs[
                    lang == tagRes.ResourceLangs[0].Lang ? 0 : 1
                  ].ResourceName
                }
              </div>
              <i className="icon mdi mdi-chevron-right"></i>
            </div>
          );
        });
        contents.push({
          type: "Revison_Before_Assessment",
          content: _video_content,
        });
      }
      //2:assessment
      const targetAssessment =
        data.learnosityTagHierarchyStructure[0].AdoptedLearnosityResource[0]
          .RelatedResource[0];
      contents.push({
        type: "Assessment",
        content: [
          <div
            className="img_w_text"
            onClick={() => {
              console.log("Onclick start", targetAssessment, topic);
              onClickAssessment("START", targetAssessment);
            }}
          >
            <img src={saicon}></img>
            <div className="title">{t("Start Assessment")}</div>
          </div>,
          <div
            className="img_w_text"
            onClick={() => {
              console.log("Onclick RA", targetAssessment);
              onClickAssessment("RA", targetAssessment);
            }}
          >
            <img src={raicon}></img>
            <div className="title">{t("Result & Answer")}</div>
          </div>,
          <div
            className="img_w_text"
            onClick={() => {
              console.log("Onclick AF", targetAssessment);
              onClickAssessment("AS", targetAssessment);
            }}
          >
            <img src={aficon}></img>
            <div className="title">{t("Analysis & Follow-up")}</div>
          </div>,
        ],
      });

      //3:follow up
      let _contents = [],
        suggestions = [];
      console.log(targetAssessment);
      targetAssessment.LearnositySuggestionMapping.map((obj, i) => {
        obj.MappedResources.map((mr, mi) => {
          if (
            suggestions
              .map((o) => o.Resource.ResourceId)
              .indexOf(mr.Resource.ResourceId) != -1
          )
            return;
          suggestions.push(mr);
        });
      });

      suggestions = suggestions.sort(
        (a, b) =>
          parseInt(a.Resource.DisplaySequence) -
          parseInt(b.Resource.DisplaySequence)
      );
      console.log(suggestions);

      suggestions.map((mr, mi) => {
        _contents.push(
          <div className="item" onClick={() => onClickFollowUp(mr)}>
            <img
              src={suggestionTypeIconMapping(mr.Resource.ResourceType)}
            ></img>
            <div className="title">
              {
                mr.Resource.ResourceLangs[
                  lang == mr.Resource.ResourceLangs[0].Lang ? 0 : 1
                ].ResourceName
              }
            </div>
            <i className="icon mdi mdi-chevron-right"></i>
          </div>
        );
      });

      if (suggestions.length > 0) {
        contents.push({
          type: "Follow_Up",
          content: _contents,
        });
      }

      console.log(contents);

      setboxs(contents);
    }
  }, [topic]);

  const getPageData = () => {
    const params = props.match.params.subid.split("+");
    const variables = {
      BookId: props.match.params.book,
      HierarchyReference: params[0],
      TagInfoInputObj: {
        Level1TagType: params[1],
        Level1TagName: params[2],
        Level2TagType: params[3],
        Level2TagName: params[4],
      },
    };
    B2CAssessmentList(variables).then((data) => {
      console.log(data);
      if (data.learnosityTagHierarchyStructure.length == 0) {
        return props.history.replace(
          "/c/myresview/" +
            props.match.params.id +
            "/" +
            props.match.params.book +
            "/assessments"
        );
      }
      setdata(data);
      settopic(
        data.learnosityTagHierarchyStructure[0].AdoptedLearnosityResource[0]
          .Level2TagName
      );
    });
  };

  const onClickFollowUp = (data, type) => {
    console.log(data);
    switch (type) {
      case "RBA":
        gaTrack(
          "send",
          "event",
          "Supplementary",
          "Click before assessment in assessment",
          "Resource:" +
            data.Resource.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            data.Resource.IngestionId +
            ", Book Series:" +
            BookSeries[0].BookSeriesName +
            ", Book:" +
            BookName[0].BookName +
            ", Topic:" +
            topic +
            ", Role:" +
            userData?.UserType
        );
        break;

      default:
        gaTrack(
          "send",
          "event",
          "Supplementary",
          "Click follow up in assessment",
          "Resource:" +
            data.Resource.ResourceLangs[0].ResourceName +
            ", Resource ID:" +
            data.Resource.IngestionId +
            ", Book Series:" +
            BookSeries[0].BookSeriesName +
            ", Book:" +
            BookName[0].BookName +
            ", Topic:" +
            topic +
            ", Role:" +
            userData?.UserType
        );
        break;
    }
    openResource(data);
  };

  const onClickAssessment = (type, resource) => {
    console.log(type, resource);
    switch (type) {
      case "START":
        try {
          gaTrack(
            "send",
            "event",
            "Supplementary",
            "Click start in assessment's analysis",
            "Assessment:" +
              resource.ResourceLangs[0].ResourceName +
              ", Resource ID:" +
              resource.IngestionId +
              ", Book Series:" +
              BookSeries[0].BookSeriesName +
              ", Book:" +
              BookName[0].BookName +
              ", Topic:" +
              topic +
              ", Role:" +
              userData?.UserType
          );
        } catch (error) {
          console.log(error);
        }
        break;
      case "RA":
        try {
          gaTrack(
            "send",
            "event",
            "Supplementary",
            "Click result in assessment's analysis",
            "Assessment:" +
              resource.ResourceLangs[0].ResourceName +
              ", Resource ID:" +
              resource.IngestionId +
              ", Book Series:" +
              BookSeries[0].BookSeriesName +
              ", Book:" +
              BookName[0].BookName +
              ", Topic:" +
              topic +
              ", Role:" +
              userData?.UserType
          );
        } catch (error) {
          console.log(error);
        }
        break;
      case "AS":
        try {
          gaTrack(
            "send",
            "event",
            "Supplementary",
            "Click analysis in assessment's analysis",
            "Assessment:" +
              resource.ResourceLangs[0].ResourceName +
              ", Resource ID:" +
              resource.IngestionId +
              ", Book Series:" +
              BookSeries[0].BookSeriesName +
              ", Book:" +
              BookName[0].BookName +
              ", Topic:" +
              topic +
              ", Role:" +
              userData?.UserType
          );
        } catch (error) {
          console.log(error);
        }
        break;
      default:
        console.log("case not match");
        return;
    }

    if (!window.cordova) {
      let path;
      switch (type) {
        case "START":
          path = props.location.pathname + "/" + resource.ResourceId + "/start";
          break;
        case "RA":
          path =
            props.location.pathname + "/" + resource.ResourceId + "/result";
          break;
        case "AS":
          path = props.location.pathname + "/" + resource.ResourceId + "/as";
          break;
        default:
          path = props.location.pathname + "/" + resource.ResourceId + "/start";
          break;
      }
      console.log(path, "path");
      props.history.push(path);
    }

    if (window.cordova) {
      handleAppLearnosity(resource, type);
    }
  };

  const handleAppLearnosity = (resource, type) => {
    console.log("[Open assessment] App - Inapp browser");
    // if (browserRef != null) {
    // browserRef = null;
    // }
    setoverlayLoading(true);
    var backText = lang == "en-US" ? "Close" : "關閉";
    var assessmentName =
      resource.ResourceLangs[
        lang == resource.ResourceLangs[0].Lang ? 0 : 1
      ].ResourceName.trim();
    var sessionId = localStorage.getItem("SessionId");
    var tokenJson;
    // if (userData?.UserType == "T") {
    //   tokenJson = {
    //     SessionId: sessionId,
    //     userId: JSON.parse(localStorage.getItem("userData")).UserId,
    //   };
    // }

    // if (userData?.UserType == "S") {
    tokenJson = {
      SessionId: sessionId,
      userId: JSON.parse(localStorage.getItem("userData")).UserId,
    };
    // }
    console.log(tokenJson, assessmentName.trim());
    if (B2C) {
      assessmentName = "";
    }
    let pageContentUrl = config.InAppBrowserAssessmentURL;
    // b2c=false&groupId=null&bookId=24&unitId=15&resourceId=102&bookseriesId=659&lang=en-US&page=start
    pageContentUrl = `${pageContentUrl}b2c=${B2C}&groupId=${null}&bookId=${
      props.match.params.book
    }&unitId=${null}&resourceId=${resource.ResourceId}&bookseriesId=${
      props.match.params.id
    }&lang=${lang}`;

    switch (type) {
      case "START":
        pageContentUrl = pageContentUrl + "&page=start";
        //?`https://iteach2.dev.pearson.com.hk/assessment=api/start`:`https://longmanplus.pearson.com.hk/assessment=api/start`
        break;
      case "RA":
        pageContentUrl = pageContentUrl + "&page=result";
        break;
      case "AS":
        pageContentUrl = pageContentUrl + "&page=as";
        break;
      default:
        pageContentUrl = pageContentUrl + "&page=start";
        break;
    }
    console.log(pageContentUrl, "open");

    browserRef = window.cordova.InAppBrowser.open(
      pageContentUrl,
      "_blank",
      "closebuttoncaption=" +
        backText +
        ",hidenavigationbuttons=yes,location=no,hideurlbar=yes,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=yes,toolbar=no"
    );
    browserRef.addEventListener("exit", function (e) {
      console.log("exit", browserRef);
      browserRef.close();
      browserRef = null;
    });
    browserRef.addEventListener("loadstop", function (e) {
      browserRef.executeScript({
        code:
          '\
            localStorage.setItem("SessionId","' +
          tokenJson.SessionId +
          '");\
            localStorage.setItem("in-app-browser-data-userid","' +
          tokenJson.userId +
          '");\
            var body = document.querySelector("body");\
            var menuBar = document.createElement("div");\
            var back = document.createElement("div");\
            var title = document.createElement("div");\
            var callBackButton = document.createElement("div");\
            back.innerHTML = "' +
          backText +
          '";\
            title.innerHTML = "' +
          assessmentName +
          '";\
            menuBar.appendChild(back);\
            menuBar.appendChild(title);\
            menuBar.appendChild(callBackButton);\
            callBackButton.id = "app-message-receiver";\
            callBackButton.onclick= function(){\
              localStorage.setItem("close",true);\
              var leaveText = "";\
              if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
              {\
                leaveText = "Are you sure to leave?"\
              }\
              else {\
                leaveText = "確定離開?"\
              }\
              var r = confirm(leaveText);\
              if (r == true) {\
                webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "openResource",data:localStorage.getItem("callbackdata")}));\
              } \
            };\
            back.onclick= function(){\
                localStorage.setItem("close",true);\
                var leaveText = "";\
                if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
                {\
                  leaveText = "Are you sure to leave?"\
                }\
                else {\
                  leaveText = "確定離開?"\
                }\
                var r = confirm(leaveText);\
                if (r == true) {\
                    webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));\
                } \
            };\
            menuBar.classList.add("inapp-browser-menu");\
            back.classList.add("inapp-browser-menu-back");\
            title.classList.add("inapp-browser-menu-title");\
            body.appendChild(menuBar);\
          ',
      });

      browserRef.insertCSS({
        code: "\
              .inapp-browser-menu {\
                  position: fixed;\
                  top: 0;\
                  right: 0;\
                  left: 0;\
                  z-index: 10001;\
                  padding: 10px;\
                  height: 45px;\
                  background-color: #fff;\
                  border-bottom: 1px solid #dddddd;\
                  display: flex;\
                  justify-content: center;}\
              .inapp-browser-menu-back {\
                  position: absolute;\
                  left: 25px;}\
              .inapp-browser-menu-title{\
              }\
          ",
      });
      browserRef.show();

      setoverlayLoading(false);
    });

    browserRef.addEventListener("message", function (params) {
      if (params.data.action === "closeIAB") {
        console.log("closeIAB");
        browserRef.close();
        browserRef = null;
      }
      if (params.data.action === "openResource") {
        console.log("openResource");
        // getUnitAssessments(true);
        browserRef.close();
        browserRef = null;
        setTimeout(() => {
          openResource(JSON.parse(params.data.data));
        }, 500);
      }
    });
  };
  const getEPSTokenForDownload = (resId, options, callback) => {
    setfetchingEPSResourceToken(true);
    var value = {
      ResourceId: [resId],
    };
    try {
      GetEPSToken(value).then((data) => {
        console.log(data);
        if (options.platform == "Web") {
          forWebAction(data, options, callback);
        }
        if (options.platform == "App") {
          callback(data);
          setfetchingEPSResourceToken(false);
        }
      });
    } catch (e) {
      setfetchingEPSResourceToken(false);
      console.log(e);
      window.alert(e.message);
    }
  };

  const forWebAction = (data, options, callback) => {
    if (!isSafari) {
      try {
        switch (options.restype) {
          case "IMAGE":
            if (options.actionType == "download") {
              var a = document.createElement("a");
              a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
              a.target = "_blank";
              a.download = options.actionType;
              document.body.appendChild(a);
              a.click();
              a.remove();
            } else {
              console.log("open");
              callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
            }
            setfetchingEPSResourceToken(false);
            break;
          default:
            console.log("download");
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            document.body.appendChild(a);
            console.log(a);
            a.click();
            a.remove();

            setfetchingEPSResourceToken(false);
            break;
        }
      } catch (e) {
        setfetchingEPSResourceToken(false);
        console.log("Featch EPS doanload error", e);
        window.alert(e.message);
      }
    } else {
      let res = data.resourceSearch.Resources[0];
      let url = res.ResourceAccessUrl;

      // this.setState({
      setfetchingEPSResourceToken(false);
      setmessageLightboxData({
        isOpen: true,
        title:
          res.ResourceLangs[lang == res.ResourceLangs.Lang ? 0 : 1]
            .ResourceName,
        message: "",
        onConfirm: () => {
          setmessageLightboxData(messageBoxInitState);
        },
        onCancel: () => {
          setmessageLightboxData(messageBoxInitState);
        },
        customButton: () => {
          return (
            <a
              className="btn btn-primary"
              onClick={() => {
                setmessageLightboxData(messageBoxInitState);
              }}
              href={url}
              target="_blank"
            >
              {t("Open")}
            </a>
          );
        },
      });
    }
  };

  const openResource = (data) => {
    let appReceiver = document.getElementById("app-message-receiver");
    console.log(appReceiver);
    console.log(data);
    if (appReceiver) {
      switch (data.Resource.ResourceType) {
        case "IMAGE":
        case "YOUTUBE":
        case "VIMEO":
          console.log("[appReceiver] Find - but open as light box");
          break;
        case "VIDEO":
        case "AUDIO":
        case "WORKSHEET":
        case "TEACHER_GUIDE":
        case "IACTIVITY":
        case "INTERACTIVE":
        case "OPE":
        case "WEB_LINK":
        case "LEARNOSITY":
        case "DOCUMENT":
        case "PACK":
        default:
          localStorage.setItem("callbackdata", JSON.stringify(data));
          console.log("[appReceiver] Find - start passing data");
          return appReceiver.click();
      }
    }
    console.log("[appReceiver] Not Find - start open as web");
    //No EPS 'EBOOK4','IACTIVITY','ILESSON','OPE','LEARNOSITY'
    //'DOCUMENT','WORKSHEET','TEACHER_GUIDE','AUDIO','VIDEO','WEB_LINK','INTERACTIVE','IMAGE','PACK'
    switch (data.Resource.ResourceType) {
      case "WEB_LINK":
        if (window.cordova)
          window.cordova.InAppBrowser.open(
            data.Resource.Linkage,
            "_blank",
            `hidenavigationbuttons=no,location=no,hideurlbar=no,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=no,toolbar=yes,footer=yes,closebuttoncaption=${t(
              "Close"
            )}`
          );
        else window.open(data.Resource.Linkage, "_blank");
        break;
      case "VIDEO":
      case "DOCUMENT":
      case "WORKSHEET":
      case "TEACHER_GUIDE":
      case "AUDIO":
      case "PACK":
        if (data.Resource.FileSource == "eps") {
          getEPSTokenForDownload(
            data.Resource.ResourceId,
            {
              actionType: "open",
              restype: data.Resource.ResourceType,
              platform: window.cordova ? "App" : "Web",
            },
            (path) => {
              //new tab
              if (window.cordova)
                window.open(
                  path.resourceSearch.Resources[0].ResourceAccessUrl,
                  "_system"
                );
              else window.open(path, "_blank");
            }
          );
        } else {
          window.open(
            data.Resource.ResourceAccessUrl,
            window.cordova ? "_system" : "_blank"
          );
        }
        break;
      case "IMAGE":
        if (data.Resource.FileSource == "eps") {
          console.log("[image] eps");
          // eps download or open
          getEPSTokenForDownload(
            data.Resource.ResourceId,
            {
              actionType: "open",
              restype: data.Resource.ResourceType,
              platform: "Web",
            },
            (path) => {
              //imagebox
              setImageLightBoxOpenState({
                id: data.Resource.ResourceId,
                image: path,
                obj: data,
              });
              setfetchingEPSResourceToken(false);
            }
          );
        } else {
          console.log("[image] not eps");
          setImageLightBoxOpenState({
            id: data.Resource.ResourceId,
            image: data.Resource.ResourceAccessUrl,
            obj: data,
          });
          setfetchingEPSResourceToken(false);
        }

        break;

      case "IACTIVITY":
        if (window.cordova) {
          const SessionId = localStorage.getItem("SessionId");
          const interactivePath =
            config.openResourcesApi +
            `&resid=${data.ResourceId.ResourceId}&sessionid=${SessionId}`;
          window.open(interactivePath, "_system");
        } else {
          window.open(data.Resource.ResourceAccessUrl, "_blank");
        }
        break;
      case "EBOOK4":
      case "OPE":
        if (window.cordova)
          window.open(data.Resource.ResourceAccessUrl, "_system");
        else window.open(data.Resource.ResourceAccessUrl, "_blank");
        break;
      case "LEARNOSITY":
        if (window.cordova) {
          handleAppLearnosity(data.Resource);
        } else {
          console.log(props.location, props.match);
          let newURL;
          newURL =
            window.location.protocol +
            "//" +
            window.location.host +
            `/c/myresview/` +
            props.match.params.id +
            "/" +
            props.match.params.book +
            "/assessments/" +
            props.match.params.subid +
            "/" +
            data.Resource.ResourceId +
            "/start";
          console.log(newURL);
          window.open(newURL, "_blank");
        }

        break;
      case "YOUTUBE":
        handleVideoBox({
          ...data.Resource,
          type: "youtube",
          obj: data.Resource,
        });
        break;
      case "VIMEO":
        handleVideoBox({
          ...data.Resource,
          type: "vimeo",
          obj: data.Resource,
        });
        break;
      case "INTERACTIVE":
        if (window.cordova) {
          const SessionId = localStorage.getItem("SessionId");
          const interactivePath =
            config.openResourcesApi +
            `&resid=${data.Resource.ResourceId}&sessionid=${SessionId}`;
          window.open(interactivePath, "_system");
        } else {
          if (data.Resource.FileSource == "eps") {
            // eps download or open
            getEPSTokenForDownload(
              data.Resource.ResourceId,
              {
                actionType: "open",
                restype: data.Resource.ResourceType,
                platform: "Web",
              },
              (path) => {
                //new tab
                window.open(path, "_blank");
              }
            );
          } else {
            window.open(data.Resource.ResourceAccessUrl, "_blank");
          }
        }
        break;
      default:
        window.open(
          data.Resource.ResourceAccessUrl,
          window.cordova ? "_system" : "_blank"
        );
        break;
    }
  };

  const downloadResource = (data) => {
    var appReceiver = document.getElementById("app-message-receiver");
    console.log(appReceiver);
    console.log(data);
    if (appReceiver) {
      switch (data.Resource.ResourceType) {
        case "IMAGE":
          if (data.Resource.FileSource == "eps") {
            getEPSTokenForDownload(
              data.Resource.ResourceId,
              {
                actionType: "open",
                restype: data.Resource.ResourceType,
                platform: "App",
              },
              (path) => {
                //new tab
                var newdata = { ...data };
                newdata.Resource.ResourceAccessUrl =
                  path.resourceSearch.Resources[0].ResourceAccessUrl; //replace the latest token url
                localStorage.setItem("callbackdata", JSON.stringify(newdata));
                console.log("[appReceiver] Find - start passing data");
                return appReceiver.click();
              }
            );
          } else {
            window.open(data.Resource.ResourceAccessUrl, "_blank");
          }
          break;
      }
    } else {
      if (data.Resource.FileSource == "eps") {
        getEPSTokenForDownload(
          data.Resource.ResourceId,
          {
            actionType: "open",
            restype: data.Resource.ResourceType,
            platform: "Web",
          },
          (path) => {
            window.open(path, window.cordova ? "_system" : "_blank");
          }
        );
      } else {
        window.open(
          data.Resource.ResourceAccessUrl,
          window.cordova ? "_system" : "_blank"
        );
      }
    }
  };

  const handleVideoBox = (data) => {
    console.log(data);
    setVideoLightBoxOpenState(
      data ? { type: data.type, videoPath: data.Linkage, isOpen: true } : null
    );
  };

  const handleImageBox = (id, path) => {
    setImageLightBoxOpenState(id ? { name: id, image: path } : id);
  };

  return (
    <div className="b2c_assessment_list">
      <div className="box-container">
        {boxs.map((e, i) => {
          return (
            <div className="cell" data-count={boxs.length}>
              <div className="title">{t(e.type.replaceAll("_", " "))}</div>
              <div className="content">{e.content.map((c, ci) => c)}</div>
            </div>
          );
        })}
      </div>
      {/* {overlayLoading && <Loading />} */}
      {(fetchingEPSResourceToken || overlayLoading) && <Loading />}
      <VidoeLightBox
        handleVideoBox={handleVideoBox}
        VideoLightBoxOpenState={VideoLightBoxOpenState}
      />
      <ImageLightBox
        isOpen={ImageLightBoxOpenState ? true : false}
        handleImageBox={handleImageBox}
        ImageLightBoxOpenState={ImageLightBoxOpenState}
        downloadImage={(data) => downloadResource(data)}
      />
    </div>
  );
};

AssessmentList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default AssessmentList;
