
import React, { useState, useEffect, useRef } from "react";
import Pagination from "../../../Pagination/PEC";
import { fetchQuery, graphql } from "relay-runtime";
import { useDispatch, useSelector } from "react-redux";
import environment from "../../../../Environment";
import NavBar from "./NavBar";
import moment from "moment";
import "./ViewAllNotificationPEC.scss";
import {
  handbook_book,
  handbook_bookseries,
  handbook_level,
  handbook_book_tab,
  handbook_level_tab,
  handbook_book_name,
  handbook_book_name_tab,
  dashboard_book_favorites,
  dashboard_book_level_favorites,
} from "../../../../Actions";

import Loading from "../../../Loading/PEC/Loading";
import likedIcon from "../../../../assets/img/pec/all-images/icon-heart-grey-small.png";
import likeIcon from "../../../../assets/img/pec/all-images/icon-heart-outline-grey-small.png";
import GetNotificationData from "../../../../Components/Feature/Function/GetNotificationData";
import DashboardStudyGroup from "../../../../Services/api/GraphQL/Query/DashboardStudyGroup";
import NotificationPopUp from "../../../../Layouts/DashboardLayout/FavouritePage/NotificationPopUp";
import ReadResourceSharingMutation from "../../../../Services/api/GraphQL/Mutation/ReadResourceSharingMutation";
import UpdateUserPreferencesMutation from "../../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
// import NotificationPopUp from "../../../../Layouts/DashboardLayout/FavouritePage/NotificationPopUp";

const ViewAllNotificationPEC = (props) => {
  const { setAssignmentDetailsData, onCloseButton } = props;

  const dispatch = useDispatch();
  const assignmentList = useSelector(
    (state) => state.HandbookReducer.handbookData
  );
  const totalCount = useSelector(
    (state) => state.HandbookReducer.TotalNumberOfRecord
  );

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userrole = userData?.UserType;
  const lang = localStorage.getItem("i18nextLng");
  const role = localStorage.getItem("role");

  const [orderBy, setOrderBy] = useState("LAST_MODIFY_DATE"); //BookSeries ASSIGNMENT_NAME  SUBJECT  BookSeries  ASSIGN_DATE  DUE_DATE LAST_MODIFY_DATE
  const [order, setOrder] = useState("DESC"); //ASC   DESC
  const [loading, setLoading] = useState(true);

  const [notclickable, setnotClickable] = useState(false);

  const [SelectedClass, setSelectedClass] = useState("");
  // const [myHandbookBookFav, setmyHandbookBookFav] = useState([]);
  const [favSubjectList, setfavSubjectList] = useState([]);
  // const [favLevelList, setfavLevelList] = useState([]);

  const [allBookSeriesLevels, setallBookSeriesLevels] = useState([]);

  const [subjectList, setSubjectList] = useState([]);
  const [bookData, setbookData] = useState([]);
  const [subjectId, setSubjectId] = useState("");

  // const [studentClassId, setstudentClassId] = useState("");
  // const [myHandbookBookFavTab, setmyHandbookBookFavTab] = useState(0);

  // const [myHandbookLevelFav, setmyHandbookLevelFav] = useState([]);
  // const [myHandbookLevelFavTab, setmyHandbookLevelFavTab] = useState(0);

  // const [favSeriesIds, setfavSeriesIds] = useState({});
  // const [favHandbookLevelIds, setfavHandbookLevelIds] = useState({});
  const [handBookDataView, sethandBookDataView] = useState([]);
  // series states
  const [showSeries, setShowSeries] = useState(false);

  //BookSeries and Book Name
  const [selectedSeries, setSelectedSeries] = useState("");
  const [selectedBookSeries, setSelectedBookSeries] = useState("");

  //DropDown Selected Book and BookSeries Name
  const [dropdownBookSeriesId, setdropdownBookSeriesId] = useState("");
  const [dropdownBookId, setdropdownBookId] = useState("");
  const [dropdownLevelId, setdropdownLevelId] = useState("");

  const [seriesActiveClass, setSeriesActiveClass] = useState("all");

  const [favSeriesList, setFavSeriesList] = useState([]);
  // const [scrollPopUp, setScrollPopUp] = useState(0);

  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   document.scrollingElement.scrollTop = 0;
  // }, [scrollPopUp]);

  //   book series states
  const [showBookSeries, setShowBookSeries] = useState(false);
  const [bookSeriesActiveClass, setBookSeriesActiveClass] = useState("all");

  const [favBookSeriesList, setFavBookSeriesList] = useState([]);

  //   class series states
  //     const ClassSeries = ["Class 1A", "Class 1B", "Class 2A", "Class 2B"];
  const [selectedClassSeries, setSelectedClassSeries] = useState("");
  const [showClassSeries, setShowClassSeries] = useState(false);
  const [classActiveClass, setClassActiveClass] = useState("all");
  const [studyGroupList, setstudyGroupList] = useState([]);
  const [favStudyGroupList, setfavStudyGroupList] = useState([]);
  const [page, setPage] = useState(1);
  //
  const [loadingNotification, setLoadingNotification] = useState(true);
  const series_dropdownRef = useRef(null);
  const books_dropdownRef = useRef(null);
  const classes_dropdownRef = useRef(null);

  //Notification Popup
  const [showNotificatioPopUp, setShowNotificatioPopUp] = useState(false);
  const [notificationTitle, setnotificationTitle] = useState("");
  const [deleteUpdate, setdeleteUpdate] = useState(false);
  const [notificationResourceInfo, setnotificationResourceInfo] = useState({});

  const [favLoading, setFavLoading] = useState(false);
  const [favLoadingId, setFavLoadingId] = useState('');

  const ViewAllNotificationPECStudentListQuery = graphql`
    query ViewAllNotificationPECStudentListQuery(
      $UserId: String!
      $SchoolYearId: String!
      $PageNo: Int
      $OrderBy: AssignmentOrderByEnumType
      $Order: DisplayOrderEnumType
      $PageSize: Int
      $BookSeriesId: String!
    ) {
      user(UserId: $UserId) {
        AssignmentBelongsTo(
          SchoolYearId: $SchoolYearId
          PageNo: $PageNo
          PageSize: $PageSize
          OrderBy: $OrderBy
          Order: $Order
          BookSeriesId: $BookSeriesId
        ) {
          TotalNumberOfRecord
          ResourceAssignments {
            AssignmentName
            LastUpdateDate
            Subject {
              SubjectId
              SubjectCode
              SubjectName {
                SubjectId
                SubjectName
              }
            }
            BookSeries {
              BookSeriesId
              DisplaySequence
              BookSeriesName {
                Lang
                BookSeriesName
              }
              AdoptedBooks(SchoolYearId: $SchoolYearId) {
                id
                BookId
                BookName {
                  Lang
                  BookName
                }
                BookImage {
                  FilePath
                }
              }
              Books {
                id
                BookId
                BookName {
                  Lang
                  BookName
                }
                BookImage {
                  FilePath
                }
              }
            }
            CreateDate
            CreateByUser {
              UserDisplayName {
                UserName
              }
            }
            ResourceAssignId
            DueDate
            AssignmentTargets(FilterCurrentUser: true) {
              ResourceAssignId
              User {
                UserDisplayName {
                  Lang
                  UserName
                  UserId
                }
                BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                  ClassNumber
                  StudyGroup {
                    BookSeries {
                      BookSeriesId
                    }
                    StudyGroupName {
                      Lang
                      StudyGroupName
                    }
                  }
                }
              }
              StudyGroup {
                StudyGroupName {
                  Lang
                  StudyGroupName
                }
                BookSeries {
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
            }
            AssignmentTargetStatus(UserId: $UserId) {
              ResourceAssignId
              Status
              UserId
            }
          }
        }
      }
    }
  `;
  const ViewAllNotificationPECTeacherListQuery = graphql`
    query ViewAllNotificationPECTeacherListQuery(
      $UserId: String!
      $SchoolYearId: String!
      $PageNo: Int
      $OrderBy: AssignmentOrderByEnumType
      $Order: DisplayOrderEnumType
      $PageSize: Int
      $StudyGroupId: String!
      $BookSeriesId: [String]
    ) {
      user(UserId: $UserId) {
        AssignmentCreated(
          SchoolYearId: $SchoolYearId
          PageNo: $PageNo
          PageSize: $PageSize
          OrderBy: $OrderBy
          Order: $Order
          BookSeriesId: $BookSeriesId
          StudyGroupId: $StudyGroupId
        ) {
          TotalNumberOfRecord
          ResourceAssignments {
            AssignmentName
            LastUpdateDate
            Subject {
              SubjectId
              SubjectCode
              SubjectName {
                SubjectId
                SubjectName
              }
            }
            BookSeries {
              BookSeriesId
              DisplaySequence
              BookSeriesName {
                Lang
                BookSeriesName
              }
              AdoptedBooks(SchoolYearId: $SchoolYearId) {
                id
                BookId
                BookName {
                  Lang
                  BookName
                }
                BookImage {
                  FilePath
                }
              }
              Books {
                id
                BookId
                BookName {
                  Lang
                  BookName
                }
                BookImage {
                  FilePath
                }
              }
            }
            CreateDate
            CreateByUser {
              UserDisplayName {
                UserName
              }
            }
            ResourceAssignId
            DueDate
            AssignmentTargets {
              ResourceAssignId
              UserId
              User {
                UserDisplayName {
                  Lang
                  UserName
                  UserId
                }
                BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                  ClassNumber
                  StudyGroup {
                    BookSeries {
                      BookSeriesId
                    }
                    StudyGroupName {
                      Lang
                      StudyGroupName
                    }
                  }
                }
              }
              StudyGroup {
                StudyGroupName {
                  Lang
                  StudyGroupName
                }
                StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
                  UserId
                }
                BookSeries {
                  BookSeriesName {
                    Lang
                    BookSeriesName
                  }
                }
              }
            }
            AssignmentTargetStatus {
              ResourceAssignId
              Status
              UserId
            }
          }
        }
      }
    }
  `;

  const ViewAllNotificationPECSubjectListQuery = graphql`
    query ViewAllNotificationPECSubjectListQuery(
      $UserId: String!
      $SchoolYearId: String!
    ) {
      user(UserId: $UserId) {
        AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {
          BookSeriesId
          DisplaySequence
          BookSeriesName {
            BookSeriesId
            BookSeriesName
          }
          AdoptedBooks(SchoolYearId: $SchoolYearId) {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
          }
          Books {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
          }
        }
      }
    }
  `;

  const ViewAllNotificationPECTeacherSubjectListQuery = graphql`
    query ViewAllNotificationPECTeacherSubjectListQuery(
      $UserId: String!
      $SchoolYearId: String!
      $FilterOnboarded: Boolean!
    ) {
      user(UserId: $UserId) {
        UserId
        School {
          StudyGroups(
            SchoolYearId: $SchoolYearId
            FilterOnboarded: $FilterOnboarded
          ) {
            StudyGroupId
            BookSeries {
              IngestionId
              BookSeriesId
              DisplaySequence
              BookSeriesName {
                BookSeriesId
                BookSeriesName
              }
              AdoptedBooks(SchoolYearId: $SchoolYearId) {
                id
                BookId
                BookName {
                  Lang
                  BookName
                }
                BookImage {
                  FilePath
                }
              }
              Books {
                id
                BookId
                BookName {
                  Lang
                  BookName
                }
                BookImage {
                  FilePath
                }
              }
              Subject {
                DisplaySequence
                SubjectId
                SubjectName {
                  SubjectName
                  Lang
                }
              }
            }
            StudyGroupName {
              StudyGroupName
              Lang
            }
          }
        }
      }
    }
  `;

  useEffect(() => {
    if (lang == "en-US") {
      moment.locale("en-US");
    } else {
      moment.locale("zh-HK");
    }

    if (userrole == "S") {
      //setLoadingNotification(true);
      var variables = {
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
        SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
          .SchoolYearId,
        OrderBy: "LAST_MODIFY_DATE",
        PageNo: page,
        PageSize: 10,
      };
      GetNotificationData(variables, role, "all").then((data) => {
        if (data.user.NotificationList.NotificationList) {
          dispatch({
            type: "SET_HANDBOOK_TOTAL_ITEMS",
            TotalNumberOfRecord:
              data.user.NotificationList.TotalNumberOfRecord || [],
          });
          sethandBookDataView(data.user.NotificationList.NotificationList);
        }

        // setnotificationUnreadCount(10);

        setLoadingNotification(false);
      });
      // const variables = {
      //   UserId: userData.UserId,
      //   SchoolYearId: selectedSchoolYear.SchoolYearId,
      //   FilterOnboarded: false,
      // };
      // DashboardStudyGroup(variables, role).then((data) => {
      //   const {
      //     user: { BelongsToStudyGroup = [{}] },
      //   } = data || {};
      // let defaultGroup = BelongsToStudyGroup.filter((obj) => {
      //       return (
      //         obj.StudyGroup.BookSeries.IngestionId != null ||
      //           obj.StudyGroup.BookSeries.IngestionId != ""
      //       );
      //     });
      // setStudentClassId(defaultGroup[0].StudyGroupId);

      // });
    }
    if (role == "teacher") {
      //get allowes subject
      fetchSubjectQuery().then((data) => {
        if (role == "student") {
          var bkSort = [...data.user.AssignmentBookSeriesList];

          setSubjectList(
            bkSort.sort((a, b) => {
              return a.DisplaySequence - b.DisplaySequence;
            })
          );
        } else {
          var filterNoIngestionIdGroups = data.user.School.StudyGroups.filter(
            (item, index) => {
              return (
                item.BookSeries.IngestionId != null &&
                item.BookSeries.IngestionId != ""
              );
            }
          ).sort((a, b) => {
            return a.StudyGroupName[
              a.StudyGroupName[0].Lang == lang ? 0 : 1
            ].StudyGroupName.localeCompare(
              b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
                .StudyGroupName
            );
          });
          var allsubjects = filterNoIngestionIdGroups
            .filter((item, index) => {
              return (
                filterNoIngestionIdGroups
                  .map((o) => {
                    // return o.BookSeries.Subject.SubjectId;
                    return o.BookSeries.BookSeriesId;
                  })
                  // .indexOf(item.BookSeries.Subject.SubjectId) === index
                  .indexOf(item.BookSeries.BookSeriesId) === index
              );
            })
            .sort((a, b) => {
              return (
                // a.BookSeries.Subject.DisplaySequence -
                // b.BookSeries.Subject.DisplaySequence
                a.BookSeries.DisplaySequence - b.BookSeries.DisplaySequence
              );
            });

          var subjectList = [];
          allsubjects.map((o) => {
            // subjectList.push(o.BookSeries.Subject);
            subjectList.push(o.BookSeries);
          });

          var favBookup = [];
          var myFavBookSeriesTemp = [];
          if (role != "student") {
            var preferencesObj = handbook_bookseries(userData.Preferences);

            if (preferencesObj.length > 0) {
              let preferencesArr = JSON.parse(
                preferencesObj[0].PreferenceValue
              );
              if (preferencesArr.length > 0) {
                let filterList = preferencesArr.filter(
                  (o) => o.SchoolYear == selectedSchoolYear.SchoolYearId
                );
                if (filterList.length > 0) {
                  filterList[0].BSId.forEach(function (id, index) {
                    var heartObj = filterNoIngestionIdGroups.filter(
                      (o) => o.BookSeries.BookSeriesId == id
                    );
                    if (heartObj.length > 0) {
                      var obj = {
                        BookSeriesId: id,
                        DisplaySequence: heartObj[0].BookSeries.DisplaySequence,
                        name: heartObj[0].BookSeries.BookSeriesName[
                          heartObj[0].BookSeries.BookSeriesName[0].Lang == lang
                            ? 0
                            : 1
                        ].BookSeriesName,
                      };
                      myFavBookSeriesTemp.push(obj);
                    } else {
                      //var obj = { BookSeriesId: id , name:""};
                    }
                  });
                }

                if (myFavBookSeriesTemp.length > 0) {
                  myFavBookSeriesTemp.sort(compareSequence);
                }
                setFavSeriesList(myFavBookSeriesTemp);
              }
            }
          }

          var filterSelectBookSeriesId = "";
          var filterSelectBookId = "";
          var filteredSelectBookSeriesName = "";
          var filteredSelectBookName = "";
          var filteredBooks = [];
          if (myFavBookSeriesTemp.length > 0) {
            var listArray = subjectList.filter(
              (o) => o.BookSeriesId == myFavBookSeriesTemp[0].BookSeriesId
            );

            if (listArray.length > 0) {
              filteredBooks = listArray[0].AdoptedBooks;
              setbookData(listArray[0].AdoptedBooks);
              //setdropdownBookId(listArray[0].Books[0].BookId)
              filterSelectBookSeriesId = listArray[0].BookSeriesId;
              filterSelectBookId = listArray[0].AdoptedBooks[0].BookId;
              filteredSelectBookSeriesName =
                listArray[0].BookSeriesName[
                  listArray[0].BookSeriesName[0].Lang == lang ? 0 : 1
                ].BookSeriesName;
              filteredSelectBookName =
                listArray[0].AdoptedBooks[0].BookName[
                  listArray[0].AdoptedBooks[0].BookName[0].Lang == lang ? 0 : 1
                ].BookName;
            } else {
              setbookData(subjectList[0].AdoptedBooks);
              // setdropdownBookId(subjectList[0].Books[0].BookId)
              filterSelectBookId = subjectList[0].AdoptedBooks[0].BookId;
              filterSelectBookSeriesId = subjectList[0].BookSeriesId;
              filteredSelectBookSeriesName =
                subjectList[0].BookSeriesName[
                  subjectList[0].BookSeriesName[0].Lang == lang ? 0 : 1
                ].BookSeriesName;
              filteredBooks = subjectList[0].AdoptedBooks;
              filteredSelectBookName =
                subjectList[0].AdoptedBooks[0].BookName[
                  subjectList[0].AdoptedBooks[0].BookName[0].Lang == lang
                    ? 0
                    : 1
                ].BookName;
            }
          } else {
            setbookData(subjectList[0].AdoptedBooks);
            // setdropdownBookId(subjectList[0].Books[0].BookId)
            filterSelectBookId = subjectList[0].AdoptedBooks[0].BookId;
            filterSelectBookSeriesId = subjectList[0].BookSeriesId;
            filteredSelectBookSeriesName =
              subjectList[0].BookSeriesName[
                subjectList[0].BookSeriesName[0].Lang == lang ? 0 : 1
              ].BookSeriesName;
            filteredBooks = subjectList[0].AdoptedBooks;
            filteredSelectBookName =
              subjectList[0].AdoptedBooks[0].BookName[
                subjectList[0].AdoptedBooks[0].BookName[0].Lang == lang ? 0 : 1
              ].BookName;
          }

          //Selecting Favorite Books
          var preferencesObj = dashboard_book_favorites(userData.Preferences);
          var myFavBookSeries = [];
          if (preferencesObj.length > 0) {
            let preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

            if (preferencesArr.length > 0) {
              var listArraySelect = preferencesArr.filter(
                (o) =>
                  o.BSId == filterSelectBookSeriesId &&
                  o.SchoolYear == selectedSchoolYear.SchoolYearId
              );

              if (listArraySelect.length > 0) {
                listArraySelect[0].StructureId.forEach(function (id, index) {
                  var heartBooks = filteredBooks.filter((o) => o.BookId == id);
                  var obj = {
                    BookId: id,
                    name: heartBooks[0].BookName[
                      heartBooks[0].BookName[0].Lang == lang ? 0 : 1
                    ].BookName,
                  };
                  myFavBookSeries.push(obj);
                });
              }
              if (myFavBookSeries.length > 0) {
                myFavBookSeries.sort(compareNames);
              }

              setFavBookSeriesList(myFavBookSeries);
            }
          }
          if (myFavBookSeries.length > 0) {
            filterSelectBookId = myFavBookSeries[0].BookId;
          }

          var favbooklevelvar = [];
          var preferencesObjB = dashboard_book_level_favorites(
            userData.Preferences
          );

          var bookSeriesLevel = filterNoIngestionIdGroups.filter(
            (o) => o.BookSeries.BookSeriesId == filterSelectBookSeriesId
          );

          if (preferencesObjB.length > 0) {
            var preferencesArrB = JSON.parse(
              preferencesObjB[0].PreferenceValue
            );
            let filterList = preferencesArrB.filter(
              (o) =>
                o.BSId == filterSelectBookId &&
                o.SchoolYear == selectedSchoolYear.SchoolYearId
            );
            if (filterList.length > 0) {
              filterList[0].StructureId.forEach(function (id, index) {
                var bookLevel = bookSeriesLevel.filter(
                  (o) => o.StudyGroupId == id
                );
                if (bookLevel.length > 0) {
                  var obj = {
                    StudyGroupId: id,
                    name: bookLevel[0].StudyGroupName[
                      bookLevel[0].StudyGroupName[0].Lang == lang ? 0 : 1
                    ].StudyGroupName,
                  };
                  favbooklevelvar.push(obj);
                }
              });
            }
            if (favbooklevelvar.length > 0) {
              favbooklevelvar.sort(compareNames);
            }

            setfavStudyGroupList(favbooklevelvar);
          }

          if (favbooklevelvar.length > 0) {
            setdropdownLevelId(favbooklevelvar[0].StudyGroupId);
            setSelectedClassSeries(favbooklevelvar[0].name);
          } else {
            setdropdownLevelId(bookSeriesLevel[0].StudyGroupId);
            setSelectedClassSeries(
              bookSeriesLevel[0].StudyGroupName[
                bookSeriesLevel[0].StudyGroupName[0].Lang == lang ? 0 : 1
              ].StudyGroupName
            );
          }

          setallBookSeriesLevels(filterNoIngestionIdGroups);
          setstudyGroupList(bookSeriesLevel);
          setSubjectList(subjectList);
          setdropdownBookSeriesId(filterSelectBookSeriesId);
          setSelectedSeries(filteredSelectBookSeriesName);
          setSelectedBookSeries(filteredSelectBookName);
          if (myFavBookSeries.length > 0) {
            setdropdownBookId(myFavBookSeries[0].BookId);
            setSelectedBookSeries(myFavBookSeries[0].name);
          } else {
            setdropdownBookId(filterSelectBookId);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      dropdownBookSeriesId != "" &&
      subjectList.length > 0 &&
      allBookSeriesLevels.length > 0
    ) {
      setLoadingNotification(true);
      var listArray = subjectList.filter(
        (o) => o.BookSeriesId == dropdownBookSeriesId
      );
      var filterSelectBookId = listArray[0].AdoptedBooks[0].BookId;
      var filteredBooks = listArray[0].AdoptedBooks;
      setbookData(listArray[0].AdoptedBooks);
      var preferencesObj = dashboard_book_favorites(userData.Preferences);
      var myFavBookSeries = [];

      if (preferencesObj.length > 0) {
        let preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        if (preferencesArr.length > 0) {
          var listArraySelect = preferencesArr.filter(
            (o) =>
              o.BSId == dropdownBookSeriesId &&
              o.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          if (listArraySelect.length > 0) {
            listArraySelect[0].StructureId.forEach(function (id, index) {
              var heartBooks = filteredBooks.filter((o) => o.BookId == id);

              if (heartBooks.length > 0) {
                var obj = {
                  BookId: id,
                  name: heartBooks[0].BookName[
                    heartBooks[0].BookName[0].Lang == lang ? 0 : 1
                  ].BookName,
                };
                myFavBookSeries.push(obj);
              }
            });
          }
          if (myFavBookSeries.length > 0) {
            myFavBookSeries.sort(compareNames);
          }
          setFavBookSeriesList(myFavBookSeries);
        }
      }
      if (myFavBookSeries.length > 0) {
        setdropdownBookId(myFavBookSeries[0].BookId);
        setSelectedBookSeries(myFavBookSeries[0].name);
        filterSelectBookId = myFavBookSeries[0].BookId;
      } else {
        setdropdownBookId(filterSelectBookId);
      }

      var favbooklevelvar = [];
      var preferencesObjB = dashboard_book_level_favorites(
        userData.Preferences
      );
      var filterNoIngestionIdGroups = [...allBookSeriesLevels];

      var bookSeriesLevel = filterNoIngestionIdGroups.filter(
        (o) => o.BookSeries.BookSeriesId == dropdownBookSeriesId
      );

      if (preferencesObjB.length > 0) {
        var preferencesArrB = JSON.parse(preferencesObjB[0].PreferenceValue);
        let filterList = preferencesArrB.filter(
          (o) =>
            o.BSId == filterSelectBookId &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        if (filterList.length > 0) {
          filterList[0].StructureId.forEach(function (id, index) {
            var bookLevel = bookSeriesLevel.filter((o) => o.StudyGroupId == id);
            if (bookLevel.length > 0) {
              var obj = {
                StudyGroupId: id,
                name: bookLevel[0].StudyGroupName[
                  bookLevel[0].StudyGroupName[0].Lang == lang ? 0 : 1
                ].StudyGroupName,
                SchoolYear: filterList[0].SchoolYear,
              };
              favbooklevelvar.push(obj);
            }
          });
        }
        if (favbooklevelvar.length > 0) {
          favbooklevelvar.sort(compareNames);
        }
        setfavStudyGroupList(favbooklevelvar);
      }

      if (favbooklevelvar.length > 0) {
        setdropdownLevelId(favbooklevelvar[0].StudyGroupId);
        setSelectedClassSeries(favbooklevelvar[0].name);
      } else {
        setdropdownLevelId(bookSeriesLevel[0].StudyGroupId);
        setSelectedClassSeries(
          bookSeriesLevel[0].StudyGroupName[
            bookSeriesLevel[0].StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName
        );
      }

      setstudyGroupList(bookSeriesLevel);
      setLoadingNotification(false);
    }
  }, [dropdownBookSeriesId]);

  const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // const targetEle = series_dropdownRef.current;
    // if (targetEle) {
    //   const { top } = targetEle.getBoundingClientRect();
    //   window.scrollTo({ top: window.pageYOffset + top, behavior: "smooth" });
    // }
  };

  const getHandBookData = async () => {
    setLoadingNotification(true);
    sethandBookDataView([]);
    //setNoticationLoading(true);
    var favBookSeriesId = [];
    //var preferencesObj = bookseries_favorites(userData.Preferences);
    var variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      StudyGroupId: parseInt(dropdownLevelId),
      BookId: parseInt(dropdownBookId),
      OrderBy: "LAST_MODIFY_DATE",
      PageNo: page,
      PageSize: 10,
    };
    await GetNotificationData(variables, userrole).then((data) => {
      if (data.user.NotificationList.NotificationList) {
        dispatch({
          type: "SET_HANDBOOK_TOTAL_ITEMS",
          TotalNumberOfRecord:
            data.user.NotificationList.TotalNumberOfRecord || [],
        });
        sethandBookDataView(data.user.NotificationList.NotificationList);
        setLoadingNotification(false);
      } else {
        setLoadingNotification(false);
      }
    });
  };
  useEffect(() => {
    if (userrole == "T") {
      if (dropdownBookId != "" && dropdownLevelId != "") {
        getHandBookData();
      }
    } else {
      getHandBookData();
    }
  }, [dropdownBookId, dropdownLevelId, page]);

  useEffect(() => {
    if (
      dropdownBookId != "" &&
      studyGroupList.length > 0 &&
      allBookSeriesLevels.length > 0 &&
      dropdownBookSeriesId != ""
    ) {
      var favbooklevelvar = [];
      var preferencesObjB = dashboard_book_level_favorites(
        userData.Preferences
      );
      var filterNoIngestionIdGroups = [...allBookSeriesLevels];

      var bookSeriesLevel = filterNoIngestionIdGroups.filter(
        (o) => o.BookSeries.BookSeriesId == dropdownBookSeriesId
      );

      if (preferencesObjB.length > 0) {
        var preferencesArrB = JSON.parse(preferencesObjB[0].PreferenceValue);
        let filterList = preferencesArrB.filter(
          (o) =>
            o.BSId == dropdownBookId &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        if (filterList.length > 0) {
          filterList[0].StructureId.forEach(function (id, index) {
            var bookLevel = bookSeriesLevel.filter((o) => o.StudyGroupId == id);
            if (bookLevel.length > 0) {
              var obj = {
                StudyGroupId: id,
                name: bookLevel[0].StudyGroupName[
                  bookLevel[0].StudyGroupName[0].Lang == lang ? 0 : 1
                ].StudyGroupName,
              };
              favbooklevelvar.push(obj);
            }
          });
        }
        if (favbooklevelvar.length > 0) {
          favbooklevelvar.sort(compareNames);
        }
        setfavStudyGroupList(favbooklevelvar);
      }

      if (favbooklevelvar.length > 0) {
        setdropdownLevelId(favbooklevelvar[0].StudyGroupId);
        setSelectedClassSeries(favbooklevelvar[0].name);
      } else {
        setdropdownLevelId(bookSeriesLevel[0].StudyGroupId);
        setSelectedClassSeries(
          bookSeriesLevel[0].StudyGroupName[
            bookSeriesLevel[0].StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName
        );
      }

      setstudyGroupList(bookSeriesLevel);
    }
  }, [dropdownBookId]);

  useEffect(() => {
    if (deleteUpdate) {
      setLoadingNotification(true);
      sethandBookDataView([]);
      //setNoticationLoading(true);
      var favBookSeriesId = [];
      //var preferencesObj = bookseries_favorites(userData.Preferences);
      var variables = {
        UserId: userData.UserId,
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        StudyGroupId: parseInt(dropdownLevelId),
        BookId: parseInt(dropdownBookId),
        OrderBy: "LAST_MODIFY_DATE",
        PageNo: page,
        PageSize: 10,
      };
      GetNotificationData(variables, userrole).then((data) => {
        if (data.user.NotificationList.NotificationList) {
          dispatch({
            type: "SET_HANDBOOK_TOTAL_ITEMS",
            TotalNumberOfRecord:
              data.user.NotificationList.TotalNumberOfRecord || [],
          });
          sethandBookDataView(data.user.NotificationList.NotificationList);
          setLoadingNotification(false);
        } else {
          setLoadingNotification(false);
        }
      });
    }
  }, [deleteUpdate]);

  // useEffect(()=>{

  //      setLoadingNotification(true);
  //      sethandBookDataView([]);
  //      //setNoticationLoading(true);
  //      var favBookSeriesId = [];
  //      //var preferencesObj = bookseries_favorites(userData.Preferences);
  //      var variables = {
  //        UserId: userData.UserId,
  //        SchoolYearId: selectedSchoolYear.SchoolYearId,
  //        StudyGroupId: parseInt(dropdownLevelId),
  //        BookId: parseInt(dropdownBookId),
  //        OrderBy: "LAST_MODIFY_DATE",
  //        PageNo: page,
  //        PageSize: 3,
  //      };
  //      GetNotificationData(variables, userrole).then((data) => {
  //        if (data.user.NotificationList.NotificationList) {
  //          dispatch({
  //            type: "SET_HANDBOOK_TOTAL_ITEMS",
  //            TotalNumberOfRecord: data.user.NotificationList.TotalNumberOfRecord || [],
  //          });
  //          sethandBookDataView(data.user.NotificationList.NotificationList);
  //          setLoadingNotification(false);
  //        } else {
  //          setLoadingNotification(false);
  //        }
  //      });

  //  },[page,totalCount])

  // useEffect(()=>{

  //      setLoadingNotification(true);
  //      sethandBookDataView([]);
  //      //setNoticationLoading(true);
  //      var favBookSeriesId = [];
  //      //var preferencesObj = bookseries_favorites(userData.Preferences);
  //      var variables = {
  //        UserId: userData.UserId,
  //        SchoolYearId: selectedSchoolYear.SchoolYearId,
  //        StudyGroupId: parseInt(dropdownLevelId),
  //        BookId: parseInt(dropdownBookId),
  //        OrderBy: "LAST_MODIFY_DATE",
  //        PageNo: page,
  //        PageSize: 3,
  //      };
  //      GetNotificationData(variables, userrole).then((data) => {
  //        if (data.user.NotificationList.NotificationList) {
  //          dispatch({
  //            type: "SET_HANDBOOK_TOTAL_ITEMS",
  //            TotalNumberOfRecord: data.user.NotificationList.TotalNumberOfRecord || [],
  //          });
  //          sethandBookDataView(data.user.NotificationList.NotificationList);
  //          setLoadingNotification(false);
  //        } else {
  //          setLoadingNotification(false);
  //        }
  //      });

  //  },[page, totalCount])

  const compareNames = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  const compareSequence = (a, b) => {
    return a.age - b.age;
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const getTotalPage = (total) => {
    if (parseInt(total) > 10) {
      var totalPages = Math.ceil(parseInt(total) / 10);
      return totalPages;
    } else {
      return 1;
    }
  };

  const fetchSubjectQuery = () => {
    var variables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
    };
    if (role == "teacher") {
      variables = {
        ...variables,
        FilterOnboarded: false,
      };
    }
    return fetchQuery(
      environment,
      role == "student"
        ? ViewAllNotificationPECSubjectListQuery
        : ViewAllNotificationPECTeacherSubjectListQuery,
      variables
    );
  };

  const saveFavourites = (
    bookSeriesId,
    val,
    bookSeriesNamearg,
    displaySeq = 0
  ) => {
    setnotClickable(true);
    setFavLoading(true)
    if (val == "yes") {
      var newSubFav = [
        ...favSeriesList,
        {
          BookSeriesId: bookSeriesId,
          DisplaySequence: displaySeq,
          name: bookSeriesNamearg,
          SchoolYear: selectedSchoolYear.SchoolYearId,
        },
      ];
      // setFavSeriesList((prev) => [
      //   ...prev,
      //   { BookSeriesId: bookSeriesId, name: bookSeriesNamearg },
      // ]);
      newSubFav.sort(compareSequence);
      setFavSeriesList(newSubFav);
    } else {
      const favSeries = favSeriesList.filter(
        (el) => el.BookSeriesId !== bookSeriesId
      );
      setFavSeriesList(favSeries);
    }

    var preferencesObj = handbook_bookseries(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        // on adding the book series to favorite , starts here

        // on adding the book series to favorite , ends here
        let updateBookList = [];
        if (preferencesArr.length > 0) {
          let filterList = preferencesArr.filter(
            (o) => o.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          if (filterList.length > 0) {
            filterList[0].BSId.forEach(function (id, index) {
              updateBookList.push(id);
            });
            updateBookList.push(bookSeriesId);
            var obj = {
              BSId: updateBookList,
              SchoolYear: selectedSchoolYear.SchoolYearId,
            };
          } else {
            var obj = {
              BSId: [...new Set(bookSeriesId)],
              SchoolYear: selectedSchoolYear.SchoolYearId,
            };
          }
        } else {
          var preferencesArr = [];
          let favoritesBook = [];
          favoritesBook.push(bookSeriesId);
          var obj = {
            BSId: favoritesBook,
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        if (preferencesArr.length > 0) {
          let filterList = preferencesArr.filter(
            (o) => o.SchoolYear == selectedSchoolYear.SchoolYearId
          );

          if (filterList.length > 0) {
            let updateBookList1 = filterList[0].BSId;
            const indx = updateBookList1.findIndex((v) => v == bookSeriesId);
            // on removing the book series to favorite , starts here
            // on removing the book series to favorite , ends here
            updateBookList1.splice(indx, indx >= 0 ? 1 : 0);
            var obj = {
              BSId: updateBookList1,
              SchoolYear: selectedSchoolYear.SchoolYearId,
            };
          }
        }
      }
    } else {
      var preferencesArr = [];
      let favoritesBook = [];
      favoritesBook.push(bookSeriesId);
      var obj = {
        BSId: favoritesBook,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
    }
    var updatePrefrenceArr = [];
    // var obj = {
    //   BSId: [],
    //   SchoolYear: selectedSchoolYear.SchoolYearId,
    // };
    updatePrefrenceArr.push(obj);
    var data = {
      PreferenceName: "HANDBOOK_BOOK_SERIES",
      PreferenceValue: JSON.stringify(updatePrefrenceArr),
    };

    const callback = (result) => {
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
      setFavLoadingId('')
      setTimeout(() => {
        setFavLoading(false)
        setnotClickable(false);
      }, 2000);
    };
    UpdateUserPreferencesMutation(data, callback);
    //setmyFavSeries(obj.BSId);

    var latestStructureId = obj.BSId;
    var favlevelup = subjectList.filter((obj) => {
      return latestStructureId.includes(obj.BookSeriesId);
    });
    setfavSubjectList(favlevelup);
  };

  const saveFavouritesBook = (
    e,
    bookSeriesIdArg,
    structureIdArg,
    val,
    bkName
  ) => {
    //e.stopPropagation();
    //props.toggleClickable(1); //disable other action till the action completed
    setFavLoading(true)
    setnotClickable(true);
    // setresFavIds((prev) => ({
    //   ...prev,
    //   [structureIdArg]: val === "yes" ? true : false,
    // }));
    // const val = true ? 'yes': 'no'
    var respreferencesArr = [];
    //setmyResFav
    var latestStructureId = [];
    var preferencesObj = dashboard_book_favorites(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on adding to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );

        if (filterList.length > 0) {
          let updateStructureList = [];
          //'27210', '27209', '27211', '27212'
          filterList[0].StructureId.forEach(function (id, index) {
            updateStructureList.push(id);
          });
          updateStructureList.push(structureIdArg);
          var updateObj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(updateStructureList)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };

          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          const indx = localrespreferencesArr.findIndex(
            (v) =>
              v.BSId == bookSeriesIdArg &&
              v.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          localrespreferencesArr.splice(indx, indx >= 0 ? 1 : 0);

          localrespreferencesArr.push(updateObj);
          respreferencesArr = [...localrespreferencesArr];
          latestStructureId = [...new Set(updateStructureList)];
        } else {
          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          let structureVal = [];
          structureVal.push(structureIdArg);
          var obj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(structureVal)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          localrespreferencesArr.push(obj);
          respreferencesArr = [...localrespreferencesArr];
          //respreferencesArr.push(localrespreferencesArr);
          latestStructureId = [...new Set(structureVal)];
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on removing to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        //Removing From Structure
        let updateStructureList = [];
        filterList[0].StructureId.forEach(function (id, index) {
          updateStructureList.push(id);
        }); //\"27212\",\"27213\",\"27214\",\"27209\
        const indx = updateStructureList.findIndex((v) => v == structureIdArg);
        updateStructureList.splice(indx, indx >= 0 ? 1 : 0);
        //Removing From BookSeries
        let localrespreferencesArr = JSON.parse(
          preferencesObj[0].PreferenceValue
        );
        const bindx = localrespreferencesArr.findIndex(
          (v) =>
            v.BSId == bookSeriesIdArg &&
            v.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        localrespreferencesArr.splice(bindx, bindx >= 0 ? 1 : 0);
        var updateObj = {
          BSId: bookSeriesIdArg,
          StructureId: [...new Set(updateStructureList)],
          SchoolYear: selectedSchoolYear.SchoolYearId,
        };
        localrespreferencesArr.push(updateObj);
        respreferencesArr = [...localrespreferencesArr];
        /// respreferencesArr.push(localrespreferencesArr);
        latestStructureId = [...new Set(updateStructureList)];
      }
    } else {
      let structureVal = [];
      structureVal.push(structureIdArg);
      var obj = {
        BSId: bookSeriesIdArg,
        StructureId: structureVal,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
      respreferencesArr.push(obj);
      latestStructureId = structureVal;
    }

    var data = {
      PreferenceName: "DASHBOARD_BOOK_FAVORITES",
      // PreferenceValue: JSON.stringify([]),
      PreferenceValue: JSON.stringify(respreferencesArr),
    };
    //setFavorites(respreferencesArr);

    const callback = (result) => {
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });
      localStorage.setItem("userData", JSON.stringify(userData));
      setFavLoadingId('')
      setTimeout(() => {
        setFavLoading(false)
        setnotClickable(false);
      }, 2000);
      //var obj = { BSId: bookSeriesIdArg, StructureId: [...new Set(latestStructureId)] };

      // props.toggleClickable(0); //enable once the action completed
    };
    // setfavBookLevel(respreferencesArr);
    UpdateUserPreferencesMutation(data, callback);
  };

  const saveFavouritesBookLevel = (
    e,
    bookSeriesIdArg,
    structureIdArg,
    val,
    bkName
  ) => {
    //e.stopPropagation();
    //props.toggleClickable(1); //disable other action till the action completed
    setFavLoading(true)
    setnotClickable(true);
    // setresFavIds((prev) => ({
    //   ...prev,
    //   [structureIdArg]: val === "yes" ? true : false,
    // }));
    // const val = true ? 'yes': 'no'
    var respreferencesArr = [];
    //setmyResFav
    var latestStructureId = [];
    var preferencesObj = dashboard_book_level_favorites(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on adding to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );

        if (filterList.length > 0) {
          let updateStructureList = [];
          //'27210', '27209', '27211', '27212'
          filterList[0].StructureId.forEach(function (id, index) {
            updateStructureList.push(id);
          });
          updateStructureList.push(structureIdArg);
          var updateObj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(updateStructureList)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };

          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          const indx = localrespreferencesArr.findIndex(
            (v) =>
              v.BSId == bookSeriesIdArg &&
              v.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          localrespreferencesArr.splice(indx, indx >= 0 ? 1 : 0);
          localrespreferencesArr.push(updateObj);
          respreferencesArr = [...localrespreferencesArr];
          latestStructureId = [...new Set(updateStructureList)];
        } else {
          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          let structureVal = [];
          structureVal.push(structureIdArg);
          var obj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(structureVal)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          localrespreferencesArr.push(obj);
          respreferencesArr = [...localrespreferencesArr];
          //respreferencesArr.push(localrespreferencesArr);
          latestStructureId = [...new Set(structureVal)];
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on removing to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        //Removing From Structure
        let updateStructureList = [];
        filterList[0].StructureId.forEach(function (id, index) {
          updateStructureList.push(id);
        }); //\"27212\",\"27213\",\"27214\",\"27209\
        const indx = updateStructureList.findIndex((v) => v == structureIdArg);
        updateStructureList.splice(indx, indx >= 0 ? 1 : 0);
        //Removing From BookSeries
        let localrespreferencesArr = JSON.parse(
          preferencesObj[0].PreferenceValue
        );
        const bindx = localrespreferencesArr.findIndex(
          (v) =>
            v.BSId == bookSeriesIdArg &&
            v.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        localrespreferencesArr.splice(bindx, bindx >= 0 ? 1 : 0);
        var updateObj = {
          BSId: bookSeriesIdArg,
          StructureId: [...new Set(updateStructureList)],
          SchoolYear: selectedSchoolYear.SchoolYearId,
        };
        localrespreferencesArr.push(updateObj);
        respreferencesArr = [...localrespreferencesArr];
        /// respreferencesArr.push(localrespreferencesArr);
        latestStructureId = [...new Set(updateStructureList)];
      }
    } else {
      let structureVal = [];
      structureVal.push(structureIdArg);
      var obj = {
        BSId: bookSeriesIdArg,
        StructureId: structureVal,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
      respreferencesArr.push(obj);
      latestStructureId = structureVal;
    }

    var data = {
      PreferenceName: "DASHBOARD_BOOK_LEVEL_NEW_FAVORITES",
      // PreferenceValue: JSON.stringify([]),
      PreferenceValue: JSON.stringify(respreferencesArr),
    };
    //setFavorites(respreferencesArr);

    const callback = (result) => {
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });
      localStorage.setItem("userData", JSON.stringify(userData));
      setFavLoadingId('')
      setTimeout(() => {
        setFavLoading(false)
        setnotClickable(false);
      }, 2000);
      //var obj = { BSId: bookSeriesIdArg, StructureId: [...new Set(latestStructureId)] };

      // props.toggleClickable(0); //enable once the action completed
    };
    //setfavBookLevel(respreferencesArr);
    UpdateUserPreferencesMutation(data, callback);
  };

  const fetchAssignmentQuery = () => {
    var tempSub = [];
    var tempClass = [];
    if (subjectId != "") {
      tempSub.push(subjectId);
    }
    if (SelectedClass != "") {
      tempClass.push(SelectedClass);
    }
    var variables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      OrderBy: orderBy,
      Order: order,
      PageNo: page,
      PageSize: 10,
      BookSeriesId: tempSub,
    };
    var svariables = {
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      OrderBy: orderBy,
      Order: order,
      PageNo: page,
      PageSize: 10,
      BookSeriesId: subjectId,
    };
    if (role == "teacher") {
      variables = {
        ...variables,
        StudyGroupId: SelectedClass,
      };

      return fetchQuery(
        environment,
        ViewAllNotificationPECTeacherListQuery,
        variables
      );
    } else {
      return fetchQuery(
        environment,
        ViewAllNotificationPECStudentListQuery,
        svariables
      );
    }
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        series_dropdownRef.current !== null &&
        !series_dropdownRef.current.contains(e.target) &&
        !document.querySelector(".Component2").contains(e.target)
      ) {
        setShowSeries(false);
      }
    };
    if (showSeries) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showSeries]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        books_dropdownRef.current !== null &&
        !books_dropdownRef.current.contains(e.target) &&
        !document.querySelector(".Component2").contains(e.target)
      ) {
        setShowBookSeries(false);
      }
    };
    if (showBookSeries) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showBookSeries]);

  useEffect(() => {
    // alert('inside')
    console.log('inside useEffect ',showClassSeries,'favLoading: '+favLoading, 'favLoadingId: '+favLoadingId)
    const pageClickEvent = (e) => {
      if (
        classes_dropdownRef.current !== null &&
        !classes_dropdownRef.current.contains(e.target) &&
        !document.querySelector(".Component2").contains(e.target)
      ) {
        setShowClassSeries(false);
      }
    };
    if (showClassSeries) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showClassSeries]);

  const doSorting = (val) => {
    setLoadingNotification(true);
    var notifyData = [...handBookDataView];
    sethandBookDataView([]);
    if (val == 0) {
      notifyData.sort(function (a, b) {
        return (
          new moment(a.SharingObj.EnterDate) -
          new moment(b.SharingObj.EnterDate)
        );
      });
      sethandBookDataView(notifyData);
      setLoadingNotification(false);
    } else {
      notifyData.sort(function (a, b) {
        return (
          new moment(b.SharingObj.EnterDate) -
          new moment(a.SharingObj.EnterDate)
        );
      });
      sethandBookDataView(notifyData);
      setLoadingNotification(false);
    }
  };
  const {
    t,
    HandBookData,
    showHandbookPopup,
    setShowHandbookPopup,
    handbookNotificationTitle,
    setHandbookNotificationTitle,
    showNotificationDataUsingPopUp,
  } = props;

  const handbookPagination = (pageNo) => {
    setLoadingNotification(true);
    setTimeout(() => {
      setLoadingNotification(false);
    }, 2000);
  };
  const setOveflowProperty = () => {
    if (
      document.querySelector(
        ".ReactModal__Content.ReactModal__Content--after-open"
      )
    ) {
      // document
      //   .querySelector(".ReactModal__Content.ReactModal__Content--after-open")
      //   .setAttribute("style", "overflow: inherit");
      document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
        el.setAttribute("style", "overflow: inherit");    
      })
    }
  };

  const updateReadNotification = (dataArg) => {
    const callback = (result) => {
      console.log("Read",result)
      var outRes = JSON.parse(result.readResourceSharing)
      dispatch({
        type: "UPDATE_NOTIFICATION_COUNT",
        count: parseInt(outRes.unreadCount),
      });
    // setnotificationCountLocal(parseInt(outRes.unreadCount));
     console.log("ss",outRes.unreadCount) ;
    };
    //props.toggleNotificationPopUp("delete");
    //refreshhandbook(HandbookPopupFlag);
    ReadResourceSharingMutation(dataArg, callback);
  };


  const toggleNotificationPopUp = (val, resourceObj, msg) => {
    if (val == "yes") {

      if(role == "student"){
        var variables = {
          SharingId: resourceObj.SharingId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
        };
        updateReadNotification(variables)
      }
      

      setdeleteUpdate(false);
      setnotificationTitle(msg);
      setnotificationResourceInfo(resourceObj);
      // document.querySelector(
      //   ".ReactModal__Content.ReactModal__Content--after-open"
      // )
      //   ? (document.querySelector(
      //       ".ReactModal__Content.ReactModal__Content--after-open"
      //     ).style.overflow = "inherit")
      //   : "";
      // function to set the overflow property so that pop up can be shown in full page
      // setOveflowProperty();
      setShowNotificatioPopUp(true);
      // scrollToTopOfPage();
      // setScrollPopUp(scrollPopUp + 1);
    } else if (val == "no") {
      // document.querySelector(
      //   ".ReactModal__Content.ReactModal__Content--after-open"
      // )
      //   ? (document.querySelector(
      //       ".ReactModal__Content.ReactModal__Content--after-open"
      //     ).style.overflow = "auto")
      //   : "";
      setShowNotificatioPopUp(false);
      //setNotificationPopupOverlay(false);
      //setdeleteupdate(true);
    } else if (val == "delete") {
      // document.querySelector(
      //   ".ReactModal__Content.ReactModal__Content--after-open"
      // )
      //   ? (document.querySelector(
      //       ".ReactModal__Content.ReactModal__Content--after-open"
      //     ).style.overflow = "auto")
      //   : "";
      setShowNotificatioPopUp(false);
      //setNotificationPopupOverlay(false);
      setdeleteUpdate(true);
    } else {
    }
  };

  //code to remove the red dot if user opens the pop up starts here
  document.querySelectorAll('.main__handbooks-table-row').forEach(el=>{
    el.addEventListener('click', function(){
    if(el.firstChild.querySelector('span')){
      el.firstChild.querySelector('span').remove()
    }
  });	
  })
  //code to remove the red dot if user opens the pop up ends here

  return (
    <>
      <div className="name-banner">
        <NavBar
          {...props}
          isAssessmentView={false}
          backTitle={null}
          showRigthButton={true}
          showFlag={true}
        />
        <div className="loggedin-details">
          <p className="handbook-details">
            <span className="handbook-name">{t("Handbook")}</span>
          </p>
        </div>
      </div>
      <div
        className={`be-content card-pb bg-color site-wrapper site-wrapper-right be-content-padding border-radius-bottom ${
          notclickable ? "not-clickable" : ""
        }`}
      >
        {userrole == "T" && (
          <div className="main__handbook-section">
            <div className="main__handbook-section-filter">
              <label className="main__handbook-section-filter-by">
                {t("Filter by")}:
              </label>
              <div className="main__handbook-section-filter-selection">
                {/* Book Series Dropdown */}
                <div className="handbook-series-filter-main">
                  <span
                    className="handbook-series-filter series-handbook-series-filter"
                    onClick={() => setShowSeries(!showSeries)}
                    ref={series_dropdownRef}
                    title={selectedSeries ? selectedSeries : "Series"}
                  >
                    {selectedSeries ? selectedSeries : "Series"}
                  </span>
                  {showSeries && (
                    <div
                      className={`Component2 main-component4 main-component-expanded ${
                        notclickable ? "not-clickable" : ""
                      }`}
                    >
                      <div className="main-container">
                        <div
                          onClick={() => {
                            setSeriesActiveClass("all");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-all ${
                            seriesActiveClass == "all"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("All")}
                        </div>
                        <div
                          onClick={() => {
                            setSeriesActiveClass("favourite");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-favourite ${
                            seriesActiveClass == "favourite"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("Favourites")}
                        </div>
                      </div>
                      {seriesActiveClass == "all" &&
                        subjectList.map((obj) => {
                          var selectedLevelForBook = favSeriesList.filter(
                            (o) => o.BookSeriesId == obj.BookSeriesId
                          );
                          var heartIcon = false;
                          if (selectedLevelForBook.length > 0) {
                            heartIcon = true;
                          }
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1 handbook-series-selection-name"
                                onClick={() => {
                                  setPage(1);
                                  setSelectedSeries(
                                    obj.BookSeriesName[
                                      obj.BookSeriesName[0].Lang == lang ? 0 : 1
                                    ].BookSeriesName
                                  );
                                  setdropdownBookSeriesId(obj.BookSeriesId);
                                  // setLoadingNotification(true);
                                  // setTimeout(() => {
                                  //   setLoadingNotification(false);
                                  // }, 1200);
                                  setShowSeries(false);
                                }}
                                title={
                                  obj.BookSeriesName[
                                    obj.BookSeriesName[0].Lang == lang ? 0 : 1
                                  ].BookSeriesName
                                }
                              >
                                {
                                  obj.BookSeriesName[
                                    obj.BookSeriesName[0].Lang == lang ? 0 : 1
                                  ].BookSeriesName
                                }
                              </div>

                              {!heartIcon ? (
                                <div className="like-rows like-rows1">
                                  <img
                                    src={likeIcon}
                                    onClick={(e) => {
                                      saveFavourites(
                                        obj.BookSeriesId,
                                        "yes",
                                        obj.BookSeriesName[
                                          obj.BookSeriesName[0].Lang == lang
                                            ? 0
                                            : 1
                                        ].BookSeriesName,
                                        obj.DisplaySequence
                                      );

                                      //   add to favourite code starts here
                                      // setFavSeriesList((prev) => [
                                      //   ...prev,
                                      //   {
                                      //     BookSeriesId: obj.BookSeriesId,
                                      //     name: obj.BookSeriesName[
                                      //       obj.BookSeriesName[0].Lang == lang
                                      //         ? 0
                                      //         : 1
                                      //     ].BookSeriesName,
                                      //   },
                                      // ]);
                                      //   add to favourite code ends here
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="like-rows like-rows1">
                                  <img
                                    src={likedIcon}
                                    onClick={(e) => {
                                      saveFavourites(
                                        obj.BookSeriesId,
                                        "no",
                                        obj.BookSeriesName[
                                          obj.BookSeriesName[0].Lang == lang
                                            ? 0
                                            : 1
                                        ].BookSeriesName,
                                        obj.DisplaySequence
                                      );

                                      //   remove from favourite code starts here
                                      // const favSeries = favSeriesList.filter(
                                      //   (el) =>
                                      //     el.BookSeriesId != obj.BookSeriesId
                                      // );
                                      // setFavSeriesList(favSeries);
                                      //   remove from favourite code ends here
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {seriesActiveClass == "favourite" &&
                      favSeriesList.length <= 0 ? (
                        <label className="empty-favourite-mesaage">
                          {t("No favourite book series added yet.")}
                        </label>
                      ) : (
                        ""
                      )}
                      {seriesActiveClass == "favourite" &&
                        favSeriesList.map((eleClass) => {
                          var heartObj = subjectList.filter(
                            (o) => o.BookSeriesId == eleClass.BookSeriesId
                          );
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1 handbook-series-selection-name"
                                onClick={() => {
                                  setPage(1);
                                  setSelectedSeries(eleClass.name);
                                  setdropdownBookSeriesId(
                                    eleClass.BookSeriesId
                                  );
                                  setdropdownBookId("");
                                  // setLoadingNotification(true);
                                  // setTimeout(() => {
                                  //   setLoadingNotification(false);
                                  // }, 1200);
                                  setShowSeries(false);
                                }}
                                title={eleClass.name}
                              >
                                {eleClass.name}
                              </div>

                              <div className="like-rows like-rows1">
                                <img
                                  src={likedIcon}
                                  onClick={(e) => {
                                    saveFavourites(
                                      eleClass.BookSeriesId,
                                      "no",
                                      eleClass.name,
                                      eleClass.DisplaySequence
                                    );
                                    //   remove from favourite code starts here
                                    //   const favSeries = favSeriesList.filter(
                                    //     (el) =>
                                    //       el.BookSeriesId !== eleClass.BookSeriesId
                                    //   );
                                    //   setFavSeriesList(favSeries);
                                    //   remove from favourite code ends here
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
                {/* Book Dropdown */}
                <div className="handbook-series-filter-main">
                  <span
                    className="handbook-book-filter"
                    onClick={() => setShowBookSeries(!showBookSeries)}
                    ref={books_dropdownRef}
                    title={selectedBookSeries ? selectedBookSeries : "Book"}
                  >
                    {selectedBookSeries ? selectedBookSeries : "Book"}
                  </span>
                  {showBookSeries && (
                    <div
                      className={`Component2 main-component4 ${
                        notclickable ? "not-clickable" : ""
                      }`}
                    >
                      <div className="main-container">
                        <div
                          onClick={() => {
                            setBookSeriesActiveClass("all");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-all ${
                            bookSeriesActiveClass == "all"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("All")}
                        </div>
                        <div
                          onClick={() => {
                            setBookSeriesActiveClass("favourite");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-favourite ${
                            bookSeriesActiveClass == "favourite"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("Favourites")}
                        </div>
                      </div>
                      {bookSeriesActiveClass == "all" &&
                        bookData.map((eleClass) => {
                          var selectedLevelForBook = favBookSeriesList.filter(
                            (o) => o.BookId == eleClass.BookId
                          );
                          var heartIcon = false;
                          if (selectedLevelForBook.length > 0) {
                            heartIcon = true;
                          }
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1 handbook-series-selection-name"
                                onClick={() => {
                                  setPage(1);
                                  setSelectedBookSeries(
                                    eleClass.BookName[
                                      eleClass.BookName[0].Lang == lang ? 0 : 1
                                    ].BookName
                                  );
                                  setdropdownLevelId("");
                                  setdropdownBookId(eleClass.BookId);
                                  setShowBookSeries(false);
                                  // setLoadingNotification(true);
                                  // setTimeout(() => {
                                  //   setLoadingNotification(false);
                                  // }, 1200);
                                }}
                                title={
                                  eleClass.BookName[
                                    eleClass.BookName[0].Lang == lang ? 0 : 1
                                  ].BookName
                                }
                              >
                                {
                                  eleClass.BookName[
                                    eleClass.BookName[0].Lang == lang ? 0 : 1
                                  ].BookName
                                }
                              </div>

                              {!heartIcon ? (
                                <div className="like-rows like-rows1">
                                  <img
                                    src={likeIcon}
                                    onClick={(e) => {
                                      saveFavouritesBook(
                                        e,
                                        dropdownBookSeriesId,
                                        eleClass.BookId,
                                        "yes",
                                        eleClass.BookName[
                                          eleClass.BookName[0].Lang == lang
                                            ? 0
                                            : 1
                                        ].BookName
                                      );
                                      //   add to favourite code starts here
                                      // setFavBookSeriesList((prev) => [
                                      //   ...prev,
                                      //   {
                                      //     BookId: eleClass.BookId,
                                      //     name: eleClass.BookName[
                                      //       eleClass.BookName[0].Lang == lang
                                      //         ? 0
                                      //         : 1
                                      //     ].BookName,
                                      //   },
                                      // ]);
                                      var newBookFav = [
                                        ...favBookSeriesList,
                                        {
                                          BookId: eleClass.BookId,
                                          name: eleClass.BookName[
                                            eleClass.BookName[0].Lang == lang
                                              ? 0
                                              : 1
                                          ].BookName,
                                        },
                                      ];
                                      if (newBookFav.length > 0) {
                                        newBookFav.sort(compareNames);
                                      }
                                      setFavBookSeriesList(newBookFav);

                                      //   add to favourite code ends here
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="like-rows like-rows1">
                                  <img
                                    src={likedIcon}
                                    onClick={(e) => {
                                      saveFavouritesBook(
                                        e,
                                        dropdownBookSeriesId,
                                        eleClass.BookId,
                                        "no",
                                        eleClass.BookName[
                                          eleClass.BookName[0].Lang == lang
                                            ? 0
                                            : 1
                                        ].BookName
                                      );
                                      // remove fav book starts here
                                      const favSeries =
                                        favBookSeriesList.filter(
                                          (el) => el.BookId !== eleClass.BookId
                                        );
                                      setFavBookSeriesList(favSeries);
                                      // remove fav book ends here
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {bookSeriesActiveClass == "favourite" &&
                      favBookSeriesList.length <= 0 ? (
                        <label className="empty-favourite-mesaage">
                          {t("No favourite book added yet.")}
                        </label>
                      ) : (
                        ""
                      )}
                      {bookSeriesActiveClass == "favourite" &&
                        favBookSeriesList.map((eleClass) => {
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1 handbook-series-selection-name"
                                onClick={() => {
                                  setPage(1);
                                  setSelectedBookSeries(eleClass.name);
                                  setdropdownBookId(eleClass.BookId);
                                  setShowBookSeries(false);
                                  // setLoadingNotification(true);
                                  // setTimeout(() => {
                                  //   setLoadingNotification(false);
                                  // }, 1200);
                                }}
                                title={eleClass.name}
                              >
                                {eleClass.name}
                              </div>

                              <div className="like-rows like-rows1">
                                <img
                                  src={likedIcon}
                                  onClick={(e) => {
                                    saveFavouritesBook(
                                      e,
                                      dropdownBookSeriesId,
                                      eleClass.BookId,
                                      "no",
                                      eleClass.name
                                    );
                                    // remove fav book starts here
                                    const favSeries = favBookSeriesList.filter(
                                      (el) => el.BookId !== eleClass.BookId
                                    );
                                    setFavBookSeriesList(favSeries);
                                    // remove fav book ends here
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
                {/* Class Dropdown */}
                <div className="handbook-series-filter-main">
                  <span
                    className="handbook-class-filter"
                    onClick={() => setShowClassSeries(!showClassSeries)}
                    ref={classes_dropdownRef}
                    title={selectedClassSeries ? selectedClassSeries : "Class"}
                  >
                    {selectedClassSeries
                      ? `${t("Class")} ${selectedClassSeries}`
                      : "Class"}
                  </span>
                  {showClassSeries && (
                    <div
                      className={`Component2 main-component4 ${
                        notclickable ? "not-clickable" : ""
                      }`}
                    >
                      <div className="main-container">
                        <div
                          onClick={() => {
                            setClassActiveClass("all");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-all ${
                            classActiveClass == "all"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("All")}
                        </div>
                        <div
                          onClick={() => {
                            setClassActiveClass("favourite");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-favourite ${
                            classActiveClass == "favourite"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("Favourites")}
                        </div>
                      </div>
                      {classActiveClass == "all" &&
                        studyGroupList.map((eleClass) => {
                          var selectedLevelForBook = favStudyGroupList.filter(
                            (o) => o.StudyGroupId == eleClass.StudyGroupId
                          );
                          var heartIcon = false;
                          if (selectedLevelForBook.length > 0) {
                            heartIcon = true;
                          }
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1 handbook-series-selection-name"
                                onClick={() => {
                                  setPage(1);
                                  setSelectedClassSeries(
                                    eleClass.StudyGroupName[
                                      eleClass.StudyGroupName[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StudyGroupName
                                  );
                                  setdropdownLevelId(eleClass.StudyGroupId);
                                  setShowClassSeries(false);
                                  // alert('onclick - parent')
                                  // setLoadingNotification(true);
                                  // setTimeout(() => {
                                  //   setLoadingNotification(false);
                                  // }, 1200);
                                }}
                                title={
                                  eleClass.StudyGroupName[
                                    eleClass.StudyGroupName[0].Lang == lang
                                      ? 0
                                      : 1
                                  ].StudyGroupName
                                }
                              >
                                {`${t("Class")} ${
                                  eleClass.StudyGroupName[
                                    eleClass.StudyGroupName[0].Lang == lang
                                      ? 0
                                      : 1
                                  ].StudyGroupName
                                }`}
                              </div>

                              {!heartIcon ? (
                                <div className="like-rows like-rows1">
                                  {favLoading && favLoadingId == eleClass.StudyGroupId ? 
                                  <Loading type={`favourite`} /> :<img
                                    src={likeIcon}
                                    onClick={(e) => {
                                      // alert('onclick')
                                      setFavLoadingId(eleClass.StudyGroupId)
                                      saveFavouritesBookLevel(
                                        e,
                                        dropdownBookId,
                                        eleClass.StudyGroupId,
                                        "yes",
                                        eleClass.StudyGroupName[
                                          eleClass.StudyGroupName[0].Lang ==
                                          lang
                                            ? 0
                                            : 1
                                        ].StudyGroupName
                                      );
                                      //   add to favourite code starts here

                                      // setfavStudyGroupList((prev) => [
                                      //   ...prev,
                                      //   {
                                      //     StudyGroupId: eleClass.StudyGroupId,
                                      //     name: eleClass.StudyGroupName[
                                      //       eleClass.StudyGroupName[0].Lang ==
                                      //       lang
                                      //         ? 0
                                      //         : 1
                                      //     ].StudyGroupName,
                                      //   },
                                      // ]);

                                      var newFav = [
                                        ...favStudyGroupList,
                                        {
                                          StudyGroupId: eleClass.StudyGroupId,
                                          name: eleClass.StudyGroupName[
                                            eleClass.StudyGroupName[0].Lang ==
                                            lang
                                              ? 0
                                              : 1
                                          ].StudyGroupName,
                                        },
                                      ];
                                      newFav.sort(compareNames);
                                      setfavStudyGroupList(newFav);
                                      //   add to favourite code ends here
                                    }}
                                  />}
                                </div>
                              ) : (
                                <div className="like-rows like-rows1">
                                  {favLoading && favLoadingId === eleClass.StudyGroupId ? 
                                  <Loading type={`favourite`} /> : <img
                                    src={likedIcon}
                                    onClick={(e) => {
                                      // alert('onclick')
                                      setFavLoadingId(eleClass.StudyGroupId)
                                      saveFavouritesBookLevel(
                                        e,
                                        dropdownBookId,
                                        eleClass.StudyGroupId,
                                        "no",
                                        eleClass.StudyGroupName[
                                          eleClass.StudyGroupName[0].Lang ==
                                          lang
                                            ? 0
                                            : 1
                                        ].StudyGroupName
                                      );
                                      //   remove from favourite code starts here
                                      const favSeries =
                                        favStudyGroupList.filter(
                                          (el) =>
                                            el.StudyGroupId !==
                                            eleClass.StudyGroupId
                                        );
                                      setfavStudyGroupList(favSeries);
                                      //   remove from favourite code ends here
                                    }}
                                  />}
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {classActiveClass == "favourite" &&
                      favStudyGroupList.length <= 0 ? (
                        <label className="empty-favourite-mesaage">
                          {t("No favourite class added yet.")}
                        </label>
                      ) : (
                        ""
                      )}
                      {classActiveClass == "favourite" &&
                        favStudyGroupList.map((eleClass) => {
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1 handbook-series-selection-name"
                                onClick={() => {
                                  setPage(1);
                                  setSelectedClassSeries(eleClass.name);
                                  setdropdownLevelId(eleClass.StudyGroupId);
                                  setShowClassSeries(false);
                                  // setLoadingNotification(true);
                                  // setTimeout(() => {
                                  //   setLoadingNotification(false);
                                  // }, 1200);
                                }}
                                title={eleClass.name}
                              >
                                {`${t("Class")} ${eleClass.name}`}
                              </div>

                              <div className="like-rows like-rows1">
                                <img
                                  src={likedIcon}
                                  onClick={(e) => {
                                    saveFavouritesBookLevel(
                                      e,
                                      dropdownBookId,
                                      eleClass.StudyGroupId,
                                      "no",
                                      eleClass.name
                                    );
                                    //   remove from favourite code starts here
                                    const favSeries = favStudyGroupList.filter(
                                      (el) =>
                                        el.StudyGroupId !==
                                        eleClass.StudyGroupId
                                    );
                                    setfavStudyGroupList(favSeries);
                                    //   remove from favourite code ends here
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* {handBookDataView.length > 0 && (
              <div className="main__handbook-section-sorting">
                <label className="main__handbook-section-filter-by">
                  {t("Sort date")}:
                </label>
                <select
                  className="handbook-series-sorting sortings-section"
                  onChange={(e) => {
                    setLoadingNotification(true);
                    setTimeout(() => {
                      setLoadingNotification(false);
                    }, 1200);
                    doSorting(e.target.value);
                  }}
                >
                  <option value="0">Ascending</option>
                  <option value="1" selected>
                    Descending
                  </option>
                </select>
              </div>
            )} */}
          </div>
        )}
        <div className="main__handbooks">
          {loadingNotification && <Loading type="normal" />}

          {!loadingNotification && handBookDataView.length <= 0 && (
            <div className="no-notification notification__handbook-no-notification">
              <div className="handbook-notification-text notification-handbook-margin">
                {/* {t("There is no data")} */}
                {t("No notifications")}
              </div>
            </div>
          )}

          {!loadingNotification && handBookDataView.length > 0 && (
            <table className="main__handbooks-table">
              <thead>
                <th className="main__handbooks-table-head"><label class="main__handbooks-label-unread"></label>{t("Book")}</th>
                <th className="main__handbooks-table-head">{t("Resources")}</th>
                <th className="main__handbooks-table-head">{t("Date")}</th>
                {userrole == "T" && (
                  <th className="main__handbooks-table-head">{t("To")}</th>
                )}
                <th className="main__handbooks-table-head">{t("From")}</th>
              </thead>
              <tbody>
                {handBookDataView.map((data) => {
                  console.log('data',data)
                  return (
                    <tr
                      className="main__handbooks-table-row"
                      onClick={() => {
                        setTimeout(() => {
                          setOveflowProperty();
                          toggleNotificationPopUp(
                            "yes",
                            data.SharingObj,
                            data.SharingObj.RelatedResourcesConfig[0].Resource
                              .ResourceLangs[
                              data.SharingObj.RelatedResourcesConfig[0].Resource
                                .ResourceLangs[0].Lang == lang
                                ? 0
                                : 1
                            ].ResourceName
                          );
                        });
                      }}
                    >
                      <td className="main__handbooks-table-data main__handbooks-book">
                        <div className="main__handbooks-book-series-name" title={
                          data.SharingObj.RelatedResourcesConfig[0].Resource
                            .BookSeries.BookSeriesName[
                            data.SharingObj.RelatedResourcesConfig[0].Resource
                              .BookSeries.BookSeriesName[0].Lang == lang
                              ? 0
                              : 1
                          ].BookSeriesName +" "+
                          data.SharingObj.RelatedResourcesConfig[0].Resource
                            .Book.BookName[
                            data.SharingObj.RelatedResourcesConfig[0].Resource
                              .Book.BookName[0].Lang == lang
                              ? 0
                              : 1
                          ].BookName
                        }>
                          <label className="main__handbooks-label-unread">
                          {data.SharingObj.SharingReadTargetStatus.length ==0 && role == "student" && 
            ( <span className="main__handbooks-unread"></span>)}
            </label>
                          
                        {
                          data.SharingObj.RelatedResourcesConfig[0].Resource
                            .BookSeries.BookSeriesName[
                            data.SharingObj.RelatedResourcesConfig[0].Resource
                              .BookSeries.BookSeriesName[0].Lang == lang
                              ? 0
                              : 1
                          ].BookSeriesName +" "+
                          data.SharingObj.RelatedResourcesConfig[0].Resource
                            .Book.BookName[
                            data.SharingObj.RelatedResourcesConfig[0].Resource
                              .Book.BookName[0].Lang == lang
                              ? 0
                              : 1
                          ].BookName
                        }
                        </div>
                      </td>
                      <td className="main__handbooks-table-data main__handbooks-topic">
                        {
                          data.SharingObj.RelatedResourcesConfig[0].Resource
                            .ResourceLangs[
                            data.SharingObj.RelatedResourcesConfig[0].Resource
                              .ResourceLangs[0].Lang == lang
                              ? 0
                              : 1
                          ].ResourceName
                        }
                      </td>
                      <td className="main__handbooks-table-data main__handbooks-text">
                        {moment(data.SharingObj.EnterDate).format("YYYY-MM-DD")}
                      </td>
                      {userrole == "T" && (
                        <td className="main__handbooks-table-data main__handbooks-text">
                          {
                            data.SharingObj.RelatedResourcesConfig[0].StudyGroup
                              .StudyGroupName[[0].Lang == lang ? 0 : 1]
                              .StudyGroupName
                          }
                        </td>
                      )}
                      <td className="main__handbooks-table-data main__handbooks-text">
                        {
                          data.SharingObj.EnterByUser.UserDisplayName[
                            data.SharingObj.EnterByUser.UserDisplayName[0]
                              .Lang == lang
                              ? 0
                              : 1
                          ].UserName
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {handBookDataView.length > 0 && (
        <div className="main__handbooks-pagination">
          <Pagination
            currentPage={page}
            totalPage={getTotalPage(totalCount)}
            onPageChange={onPageChange}
            t={t}
            handbookPagination={handbookPagination}
          />
        </div>
      )}
      {showNotificatioPopUp && (
        <NotificationPopUp
            {...props}
          toggleNotificationPopUp={toggleNotificationPopUp}
          notificationTitle={notificationTitle}
          HandbookPopupFlag={false}
          notificationResourceInfo={notificationResourceInfo}
          showNotificatioPopUp={showNotificatioPopUp}
          setShowNotificatioPopUp={setShowNotificatioPopUp}
          t={t}
          notificationPopupOverlay={""}
          setNotificationPopupOverlay={showNotificatioPopUp}
        />
      )}
    </>
  );
};

export default ViewAllNotificationPEC;
