import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import environment from "../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import ResultList from "./ResultList";
import SingleResult from "./SingleResult";
import AllStudentResult from "./AllStudentResult";
import banner from "../../../../../assets/img/banner/home-intro-photo.png";
import moment from "moment";
import Loading from "../../../../Loading/Loading";
import StudentAttemptyHistory from "../../../../../Services/api/GraphQL/Query/StudentAttemptyHistory";
import { activityLayoutTab } from "../../../../../Services/Common/common";

const ResultTabSelfLearnAttemptHistoryQuery = graphql`
  query ResultTabSelfLearnAttemptHistoryQuery(
    $UserId: String!
    $ResourceId: String!
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(ResourceId: $ResourceId) {
        ResourceId
        IsCompleted
        AttemptSessionId
        AttemptDate
        UserId
      }
    }
  }
`;

const index = (props) => {
  const { match, t, B2C, activity, inappData } = props;
  const params = props.standalone ? match.params.resId.split("&") : null;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [standalone, setstandalone] = useState(props.standalone);
  const [loading, setloading] = useState(true);
  const [isInappBrowser, setisInappBrowser] = useState(props.isInappBrowser);
  const [selectedHistory, setselectedHistory] = useState(null);
  const [selectedValueId, setselectedValueId] = useState(null);
  const [changeclick, setchangeclick] = useState(0);
  const [SelfLearnAttemptHistoryArray, setSelfLearnAttemptHistoryArray] =
    useState(null);

  useEffect(() => {
    //alert("hello")
    activityLayoutTab({ disable: true, isInappBrowser });

    console.log(userType, B2C, "hithere");
    if (userType == "T" && !B2C && !activity) {
      setloading(false);
    } else if (userType === "S" || B2C || activity) {
      getSelfLearnAttemptHistory();
    }
  }, []);

  const onClickHistory = (item, id, change = 0) => {
    // const userType = localStorage.getItem("role");

    if (!isInappBrowser) {
      window.document
        .getElementById("studygroup_back")
        ?.classList.add("dim-button");
    }
    // window.document
    //   .getElementById("assessment_tab")
    //   ?.classList.add("dim-button");
    // window.document.getElementById("result_tab")?.classList.add("dim-button");
    // if (userType == "T" && !B2C) {
    //   window.document.getElementById("answer_tab")?.classList.add("dim-button");
    // }

    console.log(item, id);
    // this.setState({ selectedHistory: item, selectedValueId: id });
    setselectedHistory(item);
    setselectedValueId(id);
    if (change == 0) {
      setchangeclick(0);
    } else {
      setchangeclick(changeclick + change);
    }
  };

  const getSelfLearnAttemptHistory = () => {
    if (standalone) {
      var variables;
      if (activity) {
        // resId = props.match.params.resId;
        variables = {
          ResourceId: props.match.params.resId,
          UserId: userData?.UserId,
          IncludeAssignmentRecord: !B2C ? true : false,
          Lang: lang == "en-US" ? "EN" : "TC",
        };
      } else {
        variables = {
          UserId: userData.UserId,
          ResourceAssignId: params[2],
          UserId: userData?.UserId,
          // IncludeAssignmentRecord: !B2C ? true : false,
          Lang: lang == "en-US" ? "EN" : "TC",
        };
      }
      // variables = {
      //   UserId: userData.UserId,
      //   ResourceAssignId: params[2],
      //   Lang: lang == "en-US" ? "EN" : "TC",
      // };
      StudentAttemptyHistory(variables, activity ? false : true).then(
        (data) => {
          console.log(data);
          let targetRes;
          let _Resources;
          if (activity) {
            const {
              user: { SelfLearnAttemptHistory = [{}] },
            } = data || {};
            _Resources = SelfLearnAttemptHistory;
          } else {
            const {
              resourceAssignment: { Resources = [{}] },
            } = data || {};
            _Resources = Resources;
          }
          targetRes = _Resources.filter((o, i) => {
            return o.ResourceAssignResId == params[1];
          });
          console.log(targetRes);
          if (targetRes.length == 0) {
            activityLayoutTab({ disable: false, isInappBrowser });
          }
          setselectedHistory(
            activity ? targetRes[0] : targetRes[0].LearnosityAttemptHistory[0]
          );
          setselectedValueId(0);
          setSelfLearnAttemptHistoryArray(
            activity ? targetRes : targetRes[0].LearnosityAttemptHistory
          );
          setloading(false);
        }
      );
    } else {
      var variables;
      if (!isInappBrowser) {
        variables = {
          UserId: userData?.UserId,
          ResourceId: match.params.resId,
          Lang: lang == "en-US" ? "EN" : "TC",
        };
      } else {
        variables = {
          UserId: userData?.UserId,
          ResourceId: inappData.resourceId,
          Lang: lang == "en-US" ? "EN" : "TC",
        };
      }
      fetchQuery(
        environment,
        ResultTabSelfLearnAttemptHistoryQuery,
        variables
      ).then((data) => {
        //console.log(data);
        var listArray = [...data.user.SelfLearnAttemptHistory];
        // listArray.sort(function (a, b) {
        //   return new moment(a.AttemptDate) - new moment(b.AttemptDate);
        // });
        console.log(listArray);
        onClickHistory(listArray[0], 0);
        // const userType = localStorage.getItem("role");
        if (userType == "S") {
          if (listArray.length == 0) {
            activityLayoutTab({ disable: false, isInappBrowser });
          }
        }
        // if (userType == "T" && !B2C) {
        //   window.document.getElementById("answer_tab") &&
        //     window.document
        //       .getElementById("answer_tab")
        //       ?.classList.add("dim-button");
        // }

        setSelfLearnAttemptHistoryArray(listArray);
        setloading(false);
      });
    }
  };
  if (loading) return <Loading />;
  if (userType == "T" && !B2C && !activity) {
    return (
      <AllStudentResult
        {...props}
        // isInappBrowser={props.isInappBrowser}
        // B2C={B2C}
        // resData={resData}
        // resourceIdGa={resData?.ResourceId}
        // resourceIngestionIdGa={resData?.IngestionId}
        // standalone={props.standalone}
        // activity={activity}
      />
    );
  }
  if (userType == "S" || B2C || activity) {
    if (SelfLearnAttemptHistoryArray.length > 0) {
      return (
        <div className="result-tab">
          <div className="report-box">
            {/* <ResultList
              {...props}
              HistoryArray={SelfLearnAttemptHistoryArray}
              onClickHistory={onClickHistory}
              selectedValueId={selectedValueId}
               
            /> */}

            <SingleResult
              {...props}
              onClickHistory={onClickHistory}
              inappData={inappData}
              // resourceId={resData?.ResourceId}
              selectedHistory={selectedHistory}
              changeclick={changeclick}
              action={"in-page"}
              
              // isInappBrowser={isInappBrowser}
              // t={t}
              // B2C={B2C}
              {...props}
              // resData={resData}
              // standalone={standalone}
            />
          </div>
        </div>
      );
    } else {
      // if (!isInappBrowser) {
      //   window.document.getElementById("studygroup_back") &&
      //     window.document
      //       .getElementById("studygroup_back")
      //       ?.classList.remove("dim-button");
      // }
      // window.document.getElementById("assessment_tab") &&
      //   window.document
      //     .getElementById("assessment_tab")
      //     ?.classList.remove("dim-button");
      // window.document.getElementById("result_tab") &&
      //   window.document
      //     .getElementById("result_tab")
      //     ?.classList.remove("dim-button");

      return (
        <div className="result-tab">
          <div className="no-history">
            <div className="model-answer-box-no-permission">
              <div className="model-answer-image">
                <img src={banner} />
              </div>
              <div className="model-answer-text">
                <h3>{t("Finish the assessment first")}</h3>
                <h5>
                  {t(
                    "Your result can be reviewed after you have finished the assessment."
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};
export default index;
