/**
 * @generated SignedSource<<5c5f128cdf26b1116016356c9cf7cdc6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type MyResourcesQuery$variables = {|
  UserId: string,
|};
export type MyResourcesQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
      +ForReport: ?string,
      +id: ?string,
      +DisplaySequence: ?string,
      +HierarchyReference: ?string,
      +HierarchyReferenceLabelEn: ?string,
      +HierarchyReferenceLabelChi: ?string,
      +AdoptedPearsonLevel: ?$ReadOnlyArray<?{|
        +id: ?string,
        +PearsonLevelId: ?string,
        +TransferSequence: ?string,
        +LevelName: ?$ReadOnlyArray<?{|
          +LevelName: ?string,
          +PearsonLevelId: ?string,
        |}>,
      |}>,
    |}>,
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +IsB2CBookSeries: ?boolean,
      +DisplaySequence: ?number,
      +BookSeriesId: ?string,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +BookSeriesColorTone: ?string,
      +BookSeriesImage: ?{|
        +id: ?string,
        +FileName: ?string,
        +FilePath: ?string,
        +FileId: ?string,
      |},
      +BookSeriesBGImage: ?{|
        +id: ?string,
        +FileId: ?string,
        +FilePath: ?string,
        +FileName: ?string,
      |},
      +Books: ?$ReadOnlyArray<?{|
        +DisplaySequence: ?number,
        +BookId: ?string,
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +id: ?string,
        |}>,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FileId: ?string,
          +FilePath: ?string,
          +FileName: ?string,
          +id: ?string,
        |},
        +Units: ?$ReadOnlyArray<?{|
          +UnitName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +UnitName: ?string,
            +UnitTitle: ?string,
          |}>,
          +MasterTeachingPlanResourcesWithFilter: ?$ReadOnlyArray<?{|
            +DisplaySequence: ?number,
            +Resource: ?{|
              +ResourceId: ?string,
              +ResourceType: ?string,
              +RelatedId: ?string,
            |},
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
export type MyResourcesQuery = {|
  variables: MyResourcesQuery$variables,
  response: MyResourcesQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "UserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PearsonLevelId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnosityTagHierarchy",
  "kind": "LinkedField",
  "name": "AdoptedTagHierarchy",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ForReport",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "HierarchyReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "HierarchyReferenceLabelEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "HierarchyReferenceLabelChi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "pearsonLevel",
      "kind": "LinkedField",
      "name": "AdoptedPearsonLevel",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "TransferSequence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "pearsonLevelLang",
          "kind": "LinkedField",
          "name": "LevelName",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "LevelName",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsB2CBookSeries",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesColorTone",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FilePath",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "systemFile",
  "kind": "LinkedField",
  "name": "BookSeriesImage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "systemFile",
  "kind": "LinkedField",
  "name": "BookSeriesBGImage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v14/*: any*/),
    (v13/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnosityTagHierarchy",
  "kind": "LinkedField",
  "name": "AdoptedTagHierarchy",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "systemFile",
  "kind": "LinkedField",
  "name": "BookImage",
  "plural": false,
  "selections": [
    (v14/*: any*/),
    (v13/*: any*/),
    (v12/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "unitLang",
  "kind": "LinkedField",
  "name": "UnitName",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UnitName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UnitTitle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = [
  {
    "kind": "Literal",
    "name": "ResourceType",
    "value": "LEARNOSITY"
  }
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyResourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "unit",
                    "kind": "LinkedField",
                    "name": "Units",
                    "plural": true,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": (v22/*: any*/),
                        "concreteType": "masterTeachingPlanResource",
                        "kind": "LinkedField",
                        "name": "MasterTeachingPlanResourcesWithFilter",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "resource",
                            "kind": "LinkedField",
                            "name": "Resource",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "MasterTeachingPlanResourcesWithFilter(ResourceType:\"LEARNOSITY\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyResourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "unit",
                    "kind": "LinkedField",
                    "name": "Units",
                    "plural": true,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": (v22/*: any*/),
                        "concreteType": "masterTeachingPlanResource",
                        "kind": "LinkedField",
                        "name": "MasterTeachingPlanResourcesWithFilter",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "resource",
                            "kind": "LinkedField",
                            "name": "Resource",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "MasterTeachingPlanResourcesWithFilter(ResourceType:\"LEARNOSITY\")"
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c72248e1de35ecfa8ae7a4d1928bf641",
    "id": null,
    "metadata": {},
    "name": "MyResourcesQuery",
    "operationKind": "query",
    "text": "query MyResourcesQuery(\n  $UserId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    AdoptedTagHierarchy {\n      ForReport\n      id\n      DisplaySequence\n      HierarchyReference\n      HierarchyReferenceLabelEn\n      HierarchyReferenceLabelChi\n      AdoptedPearsonLevel {\n        id\n        PearsonLevelId\n        TransferSequence\n        LevelName {\n          LevelName\n          PearsonLevelId\n        }\n      }\n    }\n    AdoptedBookSeries {\n      IsB2CBookSeries\n      DisplaySequence\n      BookSeriesId\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      BookSeriesColorTone\n      BookSeriesImage {\n        id\n        FileName\n        FilePath\n        FileId\n      }\n      BookSeriesBGImage {\n        id\n        FileId\n        FilePath\n        FileName\n      }\n      Books {\n        DisplaySequence\n        BookId\n        AdoptedTagHierarchy {\n          id\n        }\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FileId\n          FilePath\n          FileName\n          id\n        }\n        Units {\n          UnitName {\n            Lang\n            UnitName\n            UnitTitle\n          }\n          MasterTeachingPlanResourcesWithFilter(ResourceType: LEARNOSITY) {\n            DisplaySequence\n            Resource {\n              ResourceId\n              ResourceType\n              RelatedId\n              id\n            }\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ee7f4661a5a565b0964ad45ab69dacae";

module.exports = ((node/*: any*/)/*: Query<
  MyResourcesQuery$variables,
  MyResourcesQuery$data,
>*/);
