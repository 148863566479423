/**
 * @generated SignedSource<<89204eb945e606963bef3d9b8f7007d9>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ViewAllNotificationSubjectListQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type ViewAllNotificationSubjectListQuery$data = {|
  +user: ?{|
    +AssignmentBookSeriesList: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +DisplaySequence: ?number,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +BookSeriesName: ?string,
      |}>,
    |}>,
  |},
|};
export type ViewAllNotificationSubjectListQuery = {|
  variables: ViewAllNotificationSubjectListQuery$variables,
  response: ViewAllNotificationSubjectListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewAllNotificationSubjectListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AssignmentBookSeriesList",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ViewAllNotificationSubjectListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AssignmentBookSeriesList",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22c7100501fd3f3fc09ce570f2fe90b7",
    "id": null,
    "metadata": {},
    "name": "ViewAllNotificationSubjectListQuery",
    "operationKind": "query",
    "text": "query ViewAllNotificationSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    AssignmentBookSeriesList(SchoolYearId: $SchoolYearId) {\n      BookSeriesId\n      DisplaySequence\n      BookSeriesName {\n        BookSeriesId\n        BookSeriesName\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8ddd42d9410397cfeea8a3e431bbedb0";

module.exports = ((node/*: any*/)/*: Query<
  ViewAllNotificationSubjectListQuery$variables,
  ViewAllNotificationSubjectListQuery$data,
>*/);
