/**
 * @generated SignedSource<<e99e58facf6bd96b0c42c48df2b02b3b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UserTypeEnumType = "T" | "S" | "P" | "%future added value";
export type ViewableByStudentsTableGetListQuery$variables = {|
  StudyGroupId: string,
  SchoolYearId: string,
  UserType: UserTypeEnumType,
  ResourceId: string,
|};
export type ViewableByStudentsTableGetListQuery$data = {|
  +studyGroup: ?{|
    +StudyGroupUser: ?$ReadOnlyArray<?{|
      +ClassNumber: ?string,
      +User: ?{|
        +UserId: ?string,
        +UserDisplayName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +UserName: ?string,
        |}>,
      |},
      +AssignedForResource: ?boolean,
    |}>,
  |},
|};
export type ViewableByStudentsTableGetListQuery = {|
  variables: ViewableByStudentsTableGetListQuery$variables,
  response: ViewableByStudentsTableGetListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserType"
},
v4 = [
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v5 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId"
},
v6 = [
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "UserType",
    "variableName": "UserType"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ClassNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "userLang",
  "kind": "LinkedField",
  "name": "UserDisplayName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UserName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "ResourceId",
      "variableName": "ResourceId"
    },
    (v5/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "AssignedForResource",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewableByStudentsTableGetListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "StudyGroup",
        "kind": "LinkedField",
        "name": "studyGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "StudyGroupUser",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "User",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ViewableByStudentsTableGetListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "StudyGroup",
        "kind": "LinkedField",
        "name": "studyGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "StudyGroupUser",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "User",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dbafb696ab313cc497d7fe53f1c5db9",
    "id": null,
    "metadata": {},
    "name": "ViewableByStudentsTableGetListQuery",
    "operationKind": "query",
    "text": "query ViewableByStudentsTableGetListQuery(\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n  $UserType: UserTypeEnumType!\n  $ResourceId: String!\n) {\n  studyGroup(StudyGroupId: $StudyGroupId) {\n    StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: $UserType) {\n      ClassNumber\n      User {\n        UserId\n        UserDisplayName {\n          Lang\n          UserName\n        }\n        id\n      }\n      AssignedForResource(ResourceId: $ResourceId, SchoolYearId: $SchoolYearId)\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "61156f47f3d6247979aa6b4b3299a9a0";

module.exports = ((node/*: any*/)/*: Query<
  ViewableByStudentsTableGetListQuery$variables,
  ViewableByStudentsTableGetListQuery$data,
>*/);
