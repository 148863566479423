import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";
import banner from "../../../assets/img/B2C/banner_default.jpg";
import Loading from "../../../Components/Loading/Loading";
import "./index.scss";
import Footer from "../../../Components/Footer/Footer";
import B2CAssessments from "./Assessments";
import ActivityLayout from "../../ActivityLayout";
import AssessmentList from "./Assessments/AssessmentList";
import AdoptedBookSeriesInfo from "../../../Services/api/GraphQL/Query/AdoptedBookSeriesInfo";
import B2CResourcesCenter from "./ResourcesCenter";

const B2CBody = (props) => {
  const { t, B2C } = props;
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.SystemReducer.lang);
  const layout = useSelector((state) => state.CommonNavBarReducer.layout);
  const layoutInfo = useSelector((state) => state.CommonNavBarReducer.info);
  const userData = useSelector((state) => state.UserReducer.user);
  const [BookName, setBookName] = useState(null);
  const [BookSeries, setBookSeries] = useState(null);
  const [activeTab, setactiveTab] = useState("assessment");
  const [loading, setloading] = useState(true);

  const [bkSeriesIngestId, setbkSeriesIngestId] = useState(null);
  useEffect(() => {
    console.log("[]once", props);
    let pathChecking = "assessment";
    if (
      matchPath(props.location.pathname, "/c/myresview/:id/:book/assessments")
    )
      pathChecking = "assessment";
    if (matchPath(props.location.pathname, "/c/myresview/:id/:book/expla"))
      pathChecking = "exp_videos";

    setactiveTab(pathChecking);
    console.log(props, "././");
    if (layout != "class") {
      let variables = {
        UserId: userData?.UserId,
        BookSeriesId: props.match.params.id,
        BookId: props.match.params.book,
      };
      AdoptedBookSeriesInfo(variables).then((data) => {
        console.log("AdoptedBookSeriesInfo", data);
        if (
          data.user.AdoptedBookSeries.length == 0 ||
          data.user.AdoptedBookSeries[0].Books.length == 0
        )
          return props.history.replace("/m/resource");
        if (layout != "class")
          dispatch({
            type: "SET_LAYOUT",
            info: {
              B2C: B2C,
              title: data.user.AdoptedBookSeries[0].BookSeriesName,
              book: data.user.AdoptedBookSeries[0].Books[0],
              bkSeriesIngestId: data.user.AdoptedBookSeries[0].IngestionId,
              // resources: data.resourceSearch.Resources[0],
              // backTitle: data.user.AdoptedBookSeries[0].Books[0].BookName,
            },
            layout: "class",
          });
        setbkSeriesIngestId(data.user.AdoptedBookSeries[0].IngestionId);
        setBookName(data.user.AdoptedBookSeries[0].Books[0].BookName);
        setBookSeries(data.user.AdoptedBookSeries[0].BookSeriesName);
        setloading(false);
      });
    } else {
      setbkSeriesIngestId(layoutInfo.bkSeriesIngestId);
      setBookName(layoutInfo.book.BookName);
      setBookSeries(layoutInfo.title);
      setloading(false);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    // <div className="be-container">
    //   <div className={"be-content card-pb bg-color site-wrapper"}>
    //     <div id="a" className="dashboard-body">
    // <div
    //   id="studygroup"
    //   className={`card ${
    //     layout == "activity" ? (layoutInfo.B2C ? "b2c" : "b2b") : ""
    //   }`}
    // >
    <div className={"b2c theme_9394"} id="class_body">
      <Switch>
        <Route
          exact
          path={"/c/myresview/:id/:book/assessments"}
          component={(p) => (
            <BannerWrap
              show={true}
              {...props}
              t={t}
              BookName={BookName}
              activeTab={activeTab}
            >
              <B2CAssessments {...p} t={t} B2C={true} />
            </BannerWrap>
          )}
        />
        <Route
          exact
          path={"/c/myresview/:id/:book/expla"}
          component={(p) => (
            <BannerWrap
              show={true}
              {...props}
              t={t}
              BookName={BookName}
              activeTab={activeTab}
            >
              <B2CResourcesCenter
                {...props}
                {...p}
                B2C={true}
                BookName={BookName}
                BookSeries={BookSeries}
                bkSeriesIngestId={bkSeriesIngestId}
              />
            </BannerWrap>
          )}
        />

        <Route
          exact
          path={"/c/myresview/:id/:book/assessments/:subid"}
          component={(p) => (
            <BannerWrap
              show={true}
              {...props}
              t={t}
              BookName={BookName}
              activeTab={activeTab}
            >
              <AssessmentList
                {...props}
                {...p}
                B2C={true}
                BookName={BookName}
                BookSeries={BookSeries}
              />
            </BannerWrap>
          )}
        />

        <Route
          path="/c/myresview/:id/:book/assessments/:subid/:resId"
          component={(p) => (
            // <BannerWrap
            //   show={false}
            //   {...props}
            //   t={t}
            //   BookName={BookName}
            //   activeTab={activeTab}
            // >
            <ActivityLayout
              {...p}
              t={props.t}
              B2C={true}
              isInappBrowser={false}
            />
            // </BannerWrap>
          )}
        />

        <Redirect to={"/c/myresview/:id/:book/assessments"} />
      </Switch>
      <Footer />
      {/* </div>
          </div>
        </div> */}
    </div>
    //</div>
  );
};

const BannerWrap = (props) => {
  const { activeTab, BookName, t, show } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);
  return (
    <>
      {show && (
        <div
          className="top-section"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="content-center">
            <div>{BookName[lang == BookName[0].Lang ? 0 : 1].BookName}</div>
          </div>
          {/* menu */}
          <div
            className={`menu ${activeTab} ${
              matchPath(
                props.location.pathname,
                "/c/myresview/:id/:book/assessments/:subid"
              )
                ? "hide"
                : ""
            }`}
          >
            {!matchPath(
              props.location.pathname,
              "/c/myresview/:id/:book/assessments/:subid"
            ) && (
              <ul>
                <li
                  className={`${
                    activeTab == "assessment" ? "active" : ""
                  } assessment`}
                  onClick={() => {
                    if (
                      !matchPath(
                        props.location.pathname,
                        "/c/myresview/:id/:book/assessments"
                      )
                    ) {
                      props.history.push(
                        "/c/myresview/" +
                          props.match.params.id +
                          "/" +
                          props.match.params.book +
                          "/assessments"
                      );
                    }
                  }}
                >
                  <div>
                    <div className="title">{t("Assessments")}</div>
                  </div>
                </li>
                <li
                  className={`${
                    activeTab == "exp_videos" ? "active" : ""
                  } exp_videos`}
                  onClick={() => {
                    if (
                      !matchPath(
                        props.location.pathname,
                        "/c/myresview/:id/:book/expla"
                      )
                    ) {
                      props.history.push(
                        "/c/myresview/" +
                          props.match.params.id +
                          "/" +
                          props.match.params.book +
                          "/expla"
                      );
                    }
                  }}
                >
                  <div>
                    <div className="title">{t("SelflearningR")}</div>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      )}
      <div className="body">{props.children}</div>
    </>
  );
};

export default B2CBody;
