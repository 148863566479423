import React, {useMemo} from "react";
import useInAppBrowser from "../../hooks/useInAppBrowser";

const Footer = () => {

    const year = useMemo(() => {
        new Date().getFullYear()
    }, []);
    const isEnglish = localStorage.getItem("i18nextLng-inapp") === "en-US" ||
        localStorage.getItem("i18nextLng") === "en-US";
    const { systemBrowserOpenLink } = useInAppBrowser();

    const openLink = (targetUrl) => {
        if (!window.cordova) {
            window.open(targetUrl, "_blank");
            return;
        }
        systemBrowserOpenLink(targetUrl);
    }

    return (
        <footer className="footer font-small blue">
            {
                isEnglish ?
                    (<div className="footer-copyright text-center py-5">
                        <a
                            onClick={() =>
                                openLink(
                                    "https://www.pearson.com.hk/en_GB/end-user-license-agreement.html"
                                )
                            }
                            // target="_blank"
                        >
                            End User License Agreement
                        </a>
                        |
                        <a
                            onClick={() =>
                                openLink(
                                    "https://www.pearson.com.hk/en_GB/universal-terms-of-service.html"
                                )
                            }
                            // target="_blank"
                        >
                            Universal Terms of Service
                        </a>
                        |
                        <a
                            onClick={() =>
                                openLink(
                                    "https://www.pearson.com.hk/en_GB/acceptable-use-policy.html"
                                )
                            }
                            // target="_blank"
                        >
                            Acceptable Use Policy
                        </a>
                        |
                        <a
                            onClick={() =>
                                openLink(
                                    "https://www.pearson.com.hk/en_GB/privacy-policy.html"
                                )
                            }
                            // target="_blank"
                        >
                            Privacy Policy
                        </a>
                        |
                        <a
                            onClick={() =>
                                openLink(
                                    "https://www.pearson.com.hk/en_GB/personal-information-collection-statement.html"
                                )
                            }
                            // target="_blank"
                        >
                            Personal Information Collection Statement
                        </a>
                        |
                        <a
                            onClick={() =>
                                openLink(
                                    "https://www.pearson.com.hk/en_GB/image-credits.html"
                                )
                            }
                            // target="_blank"
                        >
                            Image Credits
                        </a>
                        |{" "}
                        <span>
                        <span style={{whiteSpace: "nowrap"}}>
                          Copyright © {year} Pearson Education Asia Limited.
                        </span>{" "}
                            <span style={{whiteSpace: "nowrap"}}>
                          All rights reserved.
                        </span>
                      </span>
                    </div>) :
                    (
                        <div className="footer-copyright text-center py-2">
                            <a
                                onClick={() =>
                                    openLink(
                                        "https://www.pearson.com.hk/zh_HK/end-user-license-agreement.html"
                                    )
                                }
                                // target="_blank"
                            >
                                使用者授權合約
                            </a>
                            |
                            <a
                                onClick={() =>
                                    openLink(
                                        "https://www.pearson.com.hk/zh_HK/universal-terms-of-service.html"
                                    )
                                }
                                // target="_blank"
                            >
                                通用服務條款
                            </a>
                            |
                            <a
                                onClick={() =>
                                    openLink(
                                        "https://www.pearson.com.hk/zh_HK/acceptable-use-policy.html"
                                    )
                                }
                                // target="_blank"
                            >
                                可接受使用政策
                            </a>
                            |
                            <a
                                onClick={() =>
                                    openLink(
                                        "https://www.pearson.com.hk/zh_HK/privacy-policy.html"
                                    )
                                }
                                // target="_blank"
                            >
                                私隱政策
                            </a>
                            |
                            <a
                                onClick={() =>
                                    openLink(
                                        "https://www.pearson.com.hk/zh_HK/personal-information-collection-statement.html"
                                    )
                                }
                                // target="_blank"
                            >
                                收集個人資料聲明
                            </a>
                            |
                            <a
                                onClick={() =>
                                    openLink(
                                        "https://www.pearson.com.hk/zh_HK/image-credits.html"
                                    )
                                }
                                // target="_blank"
                            >
                                圖像版權
                            </a>
                            |{" "}
                            <span>
                            <span style={{whiteSpace: "nowrap"}}>
                              Copyright © {year} Pearson Education Asia Limited.
                            </span>{" "}
                            <span style={{whiteSpace: "nowrap"}}>
                              All rights reserved.
                            </span>
                          </span>
                        </div>
                    )
            }
        </footer>
    );
}

export default Footer;
