/**
 * @generated SignedSource<<595c38a7a292b9128b07c0b977d8da10>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type MySchoolBookSeriesDataQuery$variables = {|
  UserId: string,
|};
export type MySchoolBookSeriesDataQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesId: ?string,
      +IsB2CBookSeries: ?boolean,
      +IngestionId: ?string,
      +DisplaySequence: ?number,
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +BookSeriesImage: ?{|
        +FilePath: ?string,
      |},
      +Books: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +BookImage: ?{|
          +FilePath: ?string,
        |},
        +PearsonLevelId: ?string,
        +PearsonLevel: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +PearsonLevelId: ?string,
            +Lang: ?string,
            +LevelName: ?string,
          |}>,
        |},
      |}>,
      +UsefulTools: ?$ReadOnlyArray<?{|
        +id: ?string,
        +ToolsCode: ?string,
        +ToolsType: ?string,
        +ApplicableRole: ?string,
        +ToolsIconFile: ?{|
          +FileName: ?string,
          +FilePath: ?string,
          +LastUpdateDate: ?string,
        |},
        +UsefulToolsLangs: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +ToolsName: ?string,
          +Description: ?string,
          +UsefulToolsId: ?string,
        |}>,
        +WebURL: ?string,
        +WebURLChi: ?string,
        +IOSAppId: ?string,
        +IOSAppURL: ?string,
        +IOSAppURLChi: ?string,
        +IOSAppName: ?string,
        +Subject: ?{|
          +SubjectCode: ?string,
          +SubjectName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +SubjectName: ?string,
          |}>,
          +DisplaySequence: ?number,
          +BookSeries: ?$ReadOnlyArray<?{|
            +BookSeriesName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +BookSeriesName: ?string,
            |}>,
          |}>,
        |},
        +SubjectId: ?string,
        +AndroidAppId: ?string,
        +AndroidAppURL: ?string,
        +AndroidAppURLChi: ?string,
        +WebSupported: ?string,
        +TabletSupported: ?string,
        +MobileSupported: ?string,
        +PCSupported: ?string,
        +EnterDate: ?string,
        +LastUpdateDate: ?string,
        +DisplaySequence: ?number,
      |}>,
    |}>,
  |},
|};
export type MySchoolBookSeriesDataQuery = {|
  variables: MySchoolBookSeriesDataQuery$variables,
  response: MySchoolBookSeriesDataQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "UserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsB2CBookSeries",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FilePath",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PearsonLevelId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "kind": "LinkedField",
  "name": "LevelName",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LevelName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ToolsCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ToolsType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ApplicableRole",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LastUpdateDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "usefulToolsLang",
  "kind": "LinkedField",
  "name": "UsefulToolsLangs",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ToolsName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UsefulToolsId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "WebURL",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "WebURLChi",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IOSAppId",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IOSAppURL",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IOSAppURLChi",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IOSAppName",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SubjectCode",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "subjectLang",
  "kind": "LinkedField",
  "name": "SubjectName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "SubjectName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SubjectId",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AndroidAppId",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AndroidAppURL",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AndroidAppURLChi",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "WebSupported",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TabletSupported",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "MobileSupported",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PCSupported",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EnterDate",
  "storageKey": null
},
v39 = [
  (v9/*: any*/),
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MySchoolBookSeriesDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "systemFile",
                "kind": "LinkedField",
                "name": "BookSeriesImage",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "systemFile",
                    "kind": "LinkedField",
                    "name": "BookImage",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "PearsonLevel",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "usefulTools",
                "kind": "LinkedField",
                "name": "UsefulTools",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "systemFile",
                    "kind": "LinkedField",
                    "name": "ToolsIconFile",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v9/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subject",
                    "kind": "LinkedField",
                    "name": "Subject",
                    "plural": false,
                    "selections": [
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "bookSeries",
                        "kind": "LinkedField",
                        "name": "BookSeries",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/),
                  (v35/*: any*/),
                  (v36/*: any*/),
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v20/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MySchoolBookSeriesDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "systemFile",
                "kind": "LinkedField",
                "name": "BookSeriesImage",
                "plural": false,
                "selections": (v39/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "systemFile",
                    "kind": "LinkedField",
                    "name": "BookImage",
                    "plural": false,
                    "selections": (v39/*: any*/),
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "PearsonLevel",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "usefulTools",
                "kind": "LinkedField",
                "name": "UsefulTools",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "systemFile",
                    "kind": "LinkedField",
                    "name": "ToolsIconFile",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v9/*: any*/),
                      (v20/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subject",
                    "kind": "LinkedField",
                    "name": "Subject",
                    "plural": false,
                    "selections": [
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "bookSeries",
                        "kind": "LinkedField",
                        "name": "BookSeries",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/),
                  (v35/*: any*/),
                  (v36/*: any*/),
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v20/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "066e2f93c90a8a1ef1acd38e58841b8f",
    "id": null,
    "metadata": {},
    "name": "MySchoolBookSeriesDataQuery",
    "operationKind": "query",
    "text": "query MySchoolBookSeriesDataQuery(\n  $UserId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    AdoptedBookSeries {\n      BookSeriesId\n      IsB2CBookSeries\n      IngestionId\n      DisplaySequence\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      BookSeriesImage {\n        FilePath\n        id\n      }\n      Books {\n        id\n        BookId\n        BookName {\n          Lang\n          BookName\n        }\n        BookImage {\n          FilePath\n          id\n        }\n        PearsonLevelId\n        PearsonLevel {\n          PearsonLevelId\n          LevelName {\n            PearsonLevelId\n            Lang\n            LevelName\n          }\n          id\n        }\n      }\n      UsefulTools {\n        id\n        ToolsCode\n        ToolsType\n        ApplicableRole\n        ToolsIconFile {\n          FileName\n          FilePath\n          LastUpdateDate\n          id\n        }\n        UsefulToolsLangs {\n          Lang\n          ToolsName\n          Description\n          UsefulToolsId\n        }\n        WebURL\n        WebURLChi\n        IOSAppId\n        IOSAppURL\n        IOSAppURLChi\n        IOSAppName\n        Subject {\n          SubjectCode\n          SubjectName {\n            Lang\n            SubjectName\n          }\n          DisplaySequence\n          BookSeries {\n            BookSeriesName {\n              Lang\n              BookSeriesName\n            }\n            id\n          }\n          id\n        }\n        SubjectId\n        AndroidAppId\n        AndroidAppURL\n        AndroidAppURLChi\n        WebSupported\n        TabletSupported\n        MobileSupported\n        PCSupported\n        EnterDate\n        LastUpdateDate\n        DisplaySequence\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8f432cace59088b26d8758b2bec57c57";

module.exports = ((node/*: any*/)/*: Query<
  MySchoolBookSeriesDataQuery$variables,
  MySchoolBookSeriesDataQuery$data,
>*/);
