import * as types from "./types";
const initState = {
  handbookData: [],
  TotalNumberOfRecord: 0,
};
const NotificationListReducer = (state = initState, action) => {
  console.log("[NotificationListReducer]", state, action);
  switch (action.type) {
    case types.NotificationListReducer.SET_NOTIFICATION_LIST:
      return {
        ...state,
        handbookData: action.handbookData,
      };
    case types.NotificationListReducer.SET_NOTIFICATION_LIST_TOTAL_ITEMS:
      return {
        ...state,
        TotalNumberOfRecord: action.TotalNumberOfRecord,
      };
    default:
      return state;
  }
};

export default NotificationListReducer;
