/**
 * @generated SignedSource<<4be0bf4e5ee79174964e4535610de87e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type OnBoardingGetBookSeriesStudyGroupListQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
  BookSeriesId: $ReadOnlyArray<string>,
  FilterOnboarded: boolean,
|};
export type OnBoardingGetBookSeriesStudyGroupListQuery$data = {|
  +user: ?{|
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +StudyGroupName: ?string,
        |}>,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
        |},
        +Level: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +LevelName: ?string,
            +Lang: ?string,
          |}>,
          +DisplaySequence: ?string,
        |},
      |}>,
    |},
  |},
|};
export type OnBoardingGetBookSeriesStudyGroupListQuery = {|
  variables: OnBoardingGetBookSeriesStudyGroupListQuery$variables,
  response: OnBoardingGetBookSeriesStudyGroupListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "FilterOnboarded"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  },
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PearsonLevelId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "kind": "LinkedField",
  "name": "LevelName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LevelName",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnBoardingGetBookSeriesStudyGroupListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OnBoardingGetBookSeriesStudyGroupListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ac6f4df30f57ed126bd315dfc18636d",
    "id": null,
    "metadata": {},
    "name": "OnBoardingGetBookSeriesStudyGroupListQuery",
    "operationKind": "query",
    "text": "query OnBoardingGetBookSeriesStudyGroupListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $BookSeriesId: [String!]!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, BookSeriesId: $BookSeriesId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        StudyGroupName {\n          Lang\n          StudyGroupName\n        }\n        BookSeries {\n          BookSeriesId\n          id\n        }\n        Level {\n          PearsonLevelId\n          LevelName {\n            LevelName\n            Lang\n          }\n          DisplaySequence\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a695b8426aa5c193484cb9fe5571426b";

module.exports = ((node/*: any*/)/*: Query<
  OnBoardingGetBookSeriesStudyGroupListQuery$variables,
  OnBoardingGetBookSeriesStudyGroupListQuery$data,
>*/);
