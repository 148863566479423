import { config } from "../../Config/config";
const initGA = async () => {
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    window.location.host.indexOf(".dev") != -1 ||
      window.location.host.indexOf("localhost") != -1
       ? "https://www.google-analytics.com/analytics_debug.js"
      : "https://www.google-analytics.com/analytics.js",
    "gaTrack"
  );
   
   
};

export default initGA;
