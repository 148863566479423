import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import icon from "../../assets/img/character2.svg";

import CircularProgressChart from "../Chart/CircularProgressChart";

// import goldMedal from "../../assets/img/gold-medal.svg";
// import silverMedal from "../../assets/img/silver.svg";
// import bronzeMedal from "../../assets/img/bronze.svg";
// import emptyMedal from "../../assets/img/empty.svg";

import Loading from "../Loading/Loading";

import "./index.scss";
import GetResourceReportStatistic from "../../Services/api/GraphQL/Query/GetResourceReportStatistic";
import { renderMedalImage } from "../../Services/Common/ScoreMedalMapping";

const MyRankingCard = (props) => {
  const { isInappBrowser, inappData, t } = props;
  const dispatch = useDispatch();
  const resourceStatisticData = useSelector(
    (state) => state.ActivityReportReducer.data
  );
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    getReportStatistic();
  }, []);

  const getReportStatistic = async () => {
    try {
      let variables = {};
      if (!isInappBrowser) {
        variables = {
          ...variables,
          ResourceId: props.match.params.resId,
        };
      } else {
        variables = {
          ...variables,
          ResourceId: inappData.resourceId,
        };
      }
      await GetResourceReportStatistic(variables).then((data) => {
        console.log(data);
        if (data.resourceSearch.Resources.length == 0) {
          throw "[getReportStatistic] 0 Resources return";
        }
        let reportStatistic =
          data.resourceSearch.Resources[0].LearnosityReportStatistic != ""
            ? JSON.parse(
                data.resourceSearch.Resources[0].LearnosityReportStatistic
              )
            : null;
        dispatch({
          type: "SET_REPORT_DATA",
          data: reportStatistic,
        });
        console.log("Report", reportStatistic);
        setisLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const renderMedalImage = (score) => {
  //   let medal = goldMedal;
  //   if (score <= 79) medal = bronzeMedal;
  //   if (score <= 89 && score >= 80) medal = silverMedal;
  //   return <img src={medal}></img>;
  // };

  return (
    <div className="my-score ranking">
      {/* top left image */}
      <div className="c-img">
        <img src={icon} />
        <div className="po-size">
          <div className="title">{t("Average Score of Other Users")}</div>
        </div>
      </div>
      {/* score card */}
      <div className="my-score-card">
        {/* my score chart */}
        <div className="my-score-chart">
          {isLoading ? (
            <div className="box-loading">
              <Loading type="inpage" />
            </div>
          ) : (
            <CircularProgressChart
              radius={100}
              progress={
                resourceStatisticData
                  ? Math.round(resourceStatisticData.averageScore)
                  : 0
              }
              strokeWidth={10}
              strokeColor="#37670b"
              trackStrokeColor="#84bd01"
              strokeLinecap="round"
              trackStrokeWidth={10}
              counterClockwise
            >
              <div className="progress-chart-center">
                {renderMedalImage(
                  resourceStatisticData
                    ? Math.round(resourceStatisticData.averageScore)
                    : null
                )}
                <div className="title">{t("AverageScore2")}</div>
                <div className="progress">
                  {resourceStatisticData ? (
                    <>
                      {Math.round(resourceStatisticData.averageScore)}
                      <span>%</span>
                    </>
                  ) : (
                    "---"
                  )}
                </div>
              </div>
            </CircularProgressChart>
          )}
        </div>
      </div>
      <div className="message">
        *{t("The average score is updated every 24 hours")}
      </div>
    </div>
  );
};

export default MyRankingCard;
