/**
 * @generated SignedSource<<bdf673c2f93ae4af3ccff09c86d27b68>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type CompleteAssignmentMutation$variables = {|
  ResourceAssignResId: string,
  Lang?: ?LangEnumType,
  SessionId: string,
|};
export type CompleteAssignmentMutation$data = {|
  +completeAssignment: ?string,
|};
export type CompleteAssignmentMutation = {|
  variables: CompleteAssignmentMutation$variables,
  response: CompleteAssignmentMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceAssignResId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SessionId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang"
      },
      {
        "kind": "Variable",
        "name": "ResourceAssignResId",
        "variableName": "ResourceAssignResId"
      },
      {
        "kind": "Variable",
        "name": "SessionId",
        "variableName": "SessionId"
      }
    ],
    "kind": "ScalarField",
    "name": "completeAssignment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteAssignmentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompleteAssignmentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f1335c90092780584f8703697cc9d34d",
    "id": null,
    "metadata": {},
    "name": "CompleteAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteAssignmentMutation(\n  $ResourceAssignResId: String!\n  $Lang: LangEnumType\n  $SessionId: String!\n) {\n  completeAssignment(ResourceAssignResId: $ResourceAssignResId, Lang: $Lang, SessionId: $SessionId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "1c13366c670572486f23ad08c87c4673";

module.exports = ((node/*: any*/)/*: Mutation<
  CompleteAssignmentMutation$variables,
  CompleteAssignmentMutation$data,
>*/);
