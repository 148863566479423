import React, { Component } from "react";
import Modal from "react-modal";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "@u-wave/react-youtube";
import "./index.scss";
const customStyles = {
  content: {
    maxWidth: "100%",
    background: "transparent",
    border: "none",
    boxShadow: "none",
    height: "100%",
  },
};
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VideoLightBoxOpenState: props.VideoLightBoxOpenState,
      // toggleNotificationPopUp: props.toggleNotificationPopUp,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Re-run the filter whenever the list array or filter text change.
    // Note we need to store prevPropsList and prevFilterText to detect changes.

    if (props.VideoLightBoxOpenState !== state.VideoLightBoxOpenState) {
      return {
        VideoLightBoxOpenState: props.VideoLightBoxOpenState,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    var that = this;
    var checkingData;
    checkingData = setInterval(() => {
      if (that.state.VideoLightBoxOpenState) {
        // this.videoTypeHandle(that.state.VideoLightBoxOpenState.type);
        clearInterval(checkingData);
      }
    }, 1000);
  }

  videoTypeHandle = (type) => {
    var result;
    switch (type) {
      case "vimeo":
        console.log("vimeo");

        result = type;
        var width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        var height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        height = height * 0.8;
        console.log(width, height);
        // this.state.toggleNotificationPopUp("no");
        return (
          <Vimeo
            className="video-element"
            width={width}
            height={height}
            onReady={this.vimeoOnReady()}
            video={this.state.VideoLightBoxOpenState.videoPath}
            autoplay
            // responsive={true}
          />
        );
      case "youtube":
        console.log("youtube");
        const { videoPath } = this.state.VideoLightBoxOpenState;

        result = type;
        // this.state.toggleNotificationPopUp("no");
        return (
          <YouTube
            video={this.getYouTubeID(videoPath)}
            autoplay
            className="youtube"
          />
        );
      case "file":
        console.log("file");

        result = type;
        break;
    }

    console.log(result);
  };

  vimeoOnReady = () => {
    var vimeo = document.getElementsByClassName("video-element");
    console.log(vimeo);
  };

  handleModalClose = () => {
    this.props.handleVideoBox(null);
  };

  getYouTubeID = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  };

  render() {
    const { VideoLightBoxOpenState } = this.state;
    if (!VideoLightBoxOpenState) {
      return "";
    }
    return (
      <Modal
        isOpen={VideoLightBoxOpenState.isOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => this.handleModalClose()}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div id="video" className="light-box">
          <div className="light-box-top-tool">
            <button onClick={() => this.handleModalClose()}>
              <i className="icon mdi mdi-close" />
            </button>
          </div>
          <div className="light-box-body" id={VideoLightBoxOpenState.type}>
            {this.videoTypeHandle(VideoLightBoxOpenState.type)}
          </div>
        </div>
      </Modal>
    );
  }
}

export default index;
