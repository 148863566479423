/**
 * @generated SignedSource<<8d61536f2ba5e1b1c1ea21ee6777fc43>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReadResourceMutation$variables = {|
  StructureId: string,
  ResourceId: string,
  SchoolYearId: string,
|};
export type ReadResourceMutation$data = {|
  +readResource: ?string,
|};
export type ReadResourceMutation = {|
  variables: ReadResourceMutation$variables,
  response: ReadResourceMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StructureId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId"
      },
      {
        "kind": "Variable",
        "name": "StructureId",
        "variableName": "StructureId"
      }
    ],
    "kind": "ScalarField",
    "name": "readResource",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReadResourceMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReadResourceMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c6e70284c00a31d6b751ac62ef9462c5",
    "id": null,
    "metadata": {},
    "name": "ReadResourceMutation",
    "operationKind": "mutation",
    "text": "mutation ReadResourceMutation(\n  $StructureId: String!\n  $ResourceId: String!\n  $SchoolYearId: String!\n) {\n  readResource(StructureId: $StructureId, ResourceId: $ResourceId, SchoolYearId: $SchoolYearId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "733e2493b7f23ca59a43de8cfa4d2b27";

module.exports = ((node/*: any*/)/*: Mutation<
  ReadResourceMutation$variables,
  ReadResourceMutation$data,
>*/);
