import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const B2CAssessmentListQuery = graphql`
  query B2CAssessmentListQuery(
    $BookId: String!
    $HierarchyReference: String!
    $TagInfoInputObj: TagInfoInputObj
  ) {
    learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {
      DisplaySequence
      HierarchyReferenceLabelEn
      HierarchyReferenceLabelChi
      HierarchyReference
      RelatedTagType {
        Tags(BookId: $BookId) {
          TopicPreStudyResources(BookId: $BookId, PageSize: 999, Order: ASC) {
            TotalNumberOfRecord
            Resources {
              IngestionId
              ResourceId
              ResourceLangs {
                Lang
                ResourceName
              }
              ResourceType
              Linkage
              ResourceAccessUrl
              FileSource
            }
          }
        }
      }
      AdoptedLearnosityResource(BookId: $BookId, TagInfo: $TagInfoInputObj) {
        LearnosityReferenceId
        Level1TagType
        Level1TagName
        Level2TagType
        Level2TagName
        RelatedResource(BookId: $BookId) {
          ResourceId
          ResourceLangs {
            Lang
            ResourceName
          }
          IngestionId
          LearnosityTagHierarchyRef
          LearnositySuggestionMapping {
            MappedResources {
              Resource {
                DisplaySequence
                IngestionId
                ResourceId
                ResourceLangs {
                  Lang
                  ResourceName
                }
                FileSource
                RelatedId
                ResourceType
                Linkage
                ResourceAccessUrl
              }
            }
          }
        }
      }
    }
  }
`;
const B2CAssessmentList = (variables) => {
  return fetchQuery(environment, B2CAssessmentListQuery, variables);
};

export default B2CAssessmentList;
