/**
 * @generated SignedSource<<90e74d48aa71e2c3326f3c1b6f03084f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type AssessmentGetLearnosityObjectMutation$variables = {|
  ResourceId: string,
  Lang?: ?LangEnumType,
  StudyGroupId?: ?string,
  SchoolYearId: string,
  Credential?: ?string,
|};
export type AssessmentGetLearnosityObjectMutation$data = {|
  +attemptSelfLearn: ?string,
|};
export type AssessmentGetLearnosityObjectMutation = {|
  variables: AssessmentGetLearnosityObjectMutation$variables,
  response: AssessmentGetLearnosityObjectMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Credential"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "Credential",
        "variableName": "Credential"
      },
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang"
      },
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupId",
        "variableName": "StudyGroupId"
      }
    ],
    "kind": "ScalarField",
    "name": "attemptSelfLearn",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssessmentGetLearnosityObjectMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssessmentGetLearnosityObjectMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "f156a8fb8757f0c0e8eeb7e0736276d3",
    "id": null,
    "metadata": {},
    "name": "AssessmentGetLearnosityObjectMutation",
    "operationKind": "mutation",
    "text": "mutation AssessmentGetLearnosityObjectMutation(\n  $ResourceId: String!\n  $Lang: LangEnumType\n  $StudyGroupId: String\n  $SchoolYearId: String!\n  $Credential: String\n) {\n  attemptSelfLearn(ResourceId: $ResourceId, Lang: $Lang, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId, Credential: $Credential)\n}\n"
  }
};
})();

(node/*: any*/).hash = "78e4f19f3d39587c2b1df48e4fba5a43";

module.exports = ((node/*: any*/)/*: Mutation<
  AssessmentGetLearnosityObjectMutation$variables,
  AssessmentGetLearnosityObjectMutation$data,
>*/);
