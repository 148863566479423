/**
 * @generated SignedSource<<65b8a346800f4783498e1f196f9253bc>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationPopUpGetEPSTokenStudentQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type NotificationPopUpGetEPSTokenStudentQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceDownloadUrl: ?string,
      +ResourceFileNameIfAny: ?string,
      +ResourceAccessUrl: ?string,
      +EpsAccessToken: ?string,
    |}>,
  |},
|};
export type NotificationPopUpGetEPSTokenStudentQuery = {|
  variables: NotificationPopUpGetEPSTokenStudentQuery$variables,
  response: NotificationPopUpGetEPSTokenStudentQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceDownloadUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceFileNameIfAny",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EpsAccessToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationPopUpGetEPSTokenStudentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationPopUpGetEPSTokenStudentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d7d5ac7c6d64c4c0ca577af45595ad7",
    "id": null,
    "metadata": {},
    "name": "NotificationPopUpGetEPSTokenStudentQuery",
    "operationKind": "query",
    "text": "query NotificationPopUpGetEPSTokenStudentQuery(\n  $ResourceId: [String]!\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n    Resources {\n      ResourceDownloadUrl\n      ResourceFileNameIfAny\n      ResourceAccessUrl\n      EpsAccessToken\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a9d9351a2af5da722a389fdc28364b8b";

module.exports = ((node/*: any*/)/*: Query<
  NotificationPopUpGetEPSTokenStudentQuery$variables,
  NotificationPopUpGetEPSTokenStudentQuery$data,
>*/);
