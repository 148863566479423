import React from "react";
import PropTypes from "prop-types";
import "./Loading.scss";

const Loading = (props) => {
  const renderNormal = () => {
    return (
      <div className="splash-container">
        <div className="loader mx-auto" />
      </div>
    );
  };
  const renderFullPage = () => {
    return (
      <div className="loading">
        <div className="sprint">
          <div className="splash-container">
            <div className="loader mx-auto" />
          </div>
        </div>
      </div>
    );
  };
  const renderInPage = () => {
    return (
      <div className="splash-container in-page">
        <div className="loader mx-auto" />
      </div>
    );
  };
  const checkType = (type) => {
    switch (type) {
      case "normal":
        return renderNormal();
      case "inpage":
        return renderInPage();
      default:
        return renderFullPage();
    }
  };

  return checkType(props.type);
};

Loading.propTypes = {
  type: PropTypes.oneOf(["normal", "inpage", null]),
};

export default Loading;
