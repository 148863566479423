import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetResourceReportStatisticQuery = graphql`
  query GetResourceReportStatisticQuery($ResourceId: [String]!) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        LearnosityReportStatistic
      }
    }
  }
`;

const GetResourceReportStatistic = (variables) => {
  return fetchQuery(environment, GetResourceReportStatisticQuery, variables);
};

export default GetResourceReportStatistic;
