/**
 * @generated SignedSource<<bcc6f0c2ab7da2f2fc3465c60cc91a78>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ResourcesSearchCheckAccessRightStudentQuery$variables = {|
  IngestionId: $ReadOnlyArray<?string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type ResourcesSearchCheckAccessRightStudentQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +ResourceType: ?string,
      +IngestionId: ?string,
      +BookSeriesId: ?string,
      +BookId: ?string,
      +ResourceDownloadUrl: ?string,
      +ResourceFileNameIfAny: ?string,
      +ResourceAccessUrl: ?string,
      +EpsAccessToken: ?string,
      +Linkage: ?string,
      +FileSource: ?string,
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +ResourceName: ?string,
        +Lang: ?string,
      |}>,
    |}>,
  |},
|};
export type ResourcesSearchCheckAccessRightStudentQuery = {|
  variables: ResourcesSearchCheckAccessRightStudentQuery$variables,
  response: ResourcesSearchCheckAccessRightStudentQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "IngestionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "IngestionId",
    "variableName": "IngestionId"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceDownloadUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceFileNameIfAny",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EpsAccessToken",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Linkage",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileSource",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResourcesSearchCheckAccessRightStudentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ResourcesSearchCheckAccessRightStudentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f4daca03da3bdd6e61aa26ad2909bcb",
    "id": null,
    "metadata": {},
    "name": "ResourcesSearchCheckAccessRightStudentQuery",
    "operationKind": "query",
    "text": "query ResourcesSearchCheckAccessRightStudentQuery(\n  $IngestionId: [String]!\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(IngestionId: $IngestionId, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n    Resources {\n      ResourceId\n      ResourceType\n      IngestionId\n      BookSeriesId\n      BookId\n      ResourceDownloadUrl\n      ResourceFileNameIfAny\n      ResourceAccessUrl\n      EpsAccessToken\n      Linkage\n      FileSource\n      ResourceLangs {\n        ResourceName\n        Lang\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e132317e41d2cee9108bc6975e7e248c";

module.exports = ((node/*: any*/)/*: Query<
  ResourcesSearchCheckAccessRightStudentQuery$variables,
  ResourcesSearchCheckAccessRightStudentQuery$data,
>*/);
