import {config} from "../Config/config";

const useGraphQL = () => {

    const mutation = async (mutation, variables) => {

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': localStorage.getItem('SessionId')
            },
            body: JSON.stringify({
                query:mutation,
                variables:variables
            }),
        };

        try {
            const response = await fetch(config.grqphQl_url, options);
            return response.json();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return {mutation};
}

export default useGraphQL;