import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const MyResourceReportQuery = graphql`
  query MyResourceReportQuery($UserId: String!, $PearsonLevelId: String!) {
    user(UserId: $UserId) {
      AdoptedTagHierarchy {
        ForReport
        id
        DisplaySequence
        HierarchyReference
        HierarchyReferenceLabelEn
        ReferenceLevelScore(PearsonLevelId: $PearsonLevelId) {
          id
          HierarchyReference
          MyScore
          MyMaxScore
          AllScore
          AllMaxScore
          PearsonLevelId
          LastUpdateDate
          TagType
          TagName
        }
        RelatedTagType {
          id
          TagType
          EnterDate
          Tags {
            id
            DisplaySequence
            TagName
            TagLongName
            TagDisplayNameEn
            TagDisplayNameChi
          }
          ParentTagType {
            TagType
          }
        }
      }
    }
  }
`;

const MyResourceReport = (variables) => {
  return fetchQuery(environment, MyResourceReportQuery, variables);
};

export default MyResourceReport;
