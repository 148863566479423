import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetHawkKeyQuery = graphql`
  query GetHawkKeyQuery($platform: ExternalPlatformEnumType!) {
    hawkKey(platform: $platform)
  }
`;

const GetHawkKey = (variables) => {
  return fetchQuery(environment, GetHawkKeyQuery, variables);
};

export default GetHawkKey;
