/**
 * @generated SignedSource<<f5936893b03bed2bb6ed6291d5aad77f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GetLearnositySuggestionMappingQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
|};
export type GetLearnositySuggestionMappingQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnositySuggestionMapping: ?$ReadOnlyArray<?{|
        +TagName: ?string,
        +TagObjectiveName: ?string,
        +Criteria: ?string,
        +ConditionRule: ?string,
        +TagType: ?string,
        +MappedResources: ?$ReadOnlyArray<?{|
          +DisplayOrder: ?string,
          +Resource: ?{|
            +IngestionId: ?string,
            +ResourceId: ?string,
            +RelatedId: ?string,
            +ResourceDownloadUrl: ?string,
            +ResourceFileNameIfAny: ?string,
            +ResourceAccessUrl: ?string,
            +ResourceType: ?string,
            +Linkage: ?string,
            +FileSource: ?string,
            +Downloadable: ?string,
            +ResourceLangs: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +ResourceName: ?string,
            |}>,
          |},
        |}>,
      |}>,
    |}>,
  |},
|};
export type GetLearnositySuggestionMappingQuery = {|
  variables: GetLearnositySuggestionMappingQuery$variables,
  response: GetLearnositySuggestionMappingQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagObjectiveName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Criteria",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ConditionRule",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplayOrder",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "RelatedId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceDownloadUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceFileNameIfAny",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Linkage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FileSource",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Downloadable",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetLearnositySuggestionMappingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "resourceLearnositySuggestionMapping",
                "kind": "LinkedField",
                "name": "LearnositySuggestionMapping",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMappingResource",
                    "kind": "LinkedField",
                    "name": "MappedResources",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resource",
                        "kind": "LinkedField",
                        "name": "Resource",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetLearnositySuggestionMappingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "resourceLearnositySuggestionMapping",
                "kind": "LinkedField",
                "name": "LearnositySuggestionMapping",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resourceLearnositySuggestionMappingResource",
                    "kind": "LinkedField",
                    "name": "MappedResources",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "resource",
                        "kind": "LinkedField",
                        "name": "Resource",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5de13d94e3368bd2923d69ab742e2449",
    "id": null,
    "metadata": {},
    "name": "GetLearnositySuggestionMappingQuery",
    "operationKind": "query",
    "text": "query GetLearnositySuggestionMappingQuery(\n  $ResourceId: [String]!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      LearnositySuggestionMapping {\n        TagName\n        TagObjectiveName\n        Criteria\n        ConditionRule\n        TagType\n        MappedResources {\n          DisplayOrder\n          Resource {\n            IngestionId\n            ResourceId\n            RelatedId\n            ResourceDownloadUrl\n            ResourceFileNameIfAny\n            ResourceAccessUrl\n            ResourceType\n            Linkage\n            FileSource\n            Downloadable\n            ResourceLangs {\n              Lang\n              ResourceName\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "27f202aaa2d351903594358818896e46";

module.exports = ((node/*: any*/)/*: Query<
  GetLearnositySuggestionMappingQuery$variables,
  GetLearnositySuggestionMappingQuery$data,
>*/);
