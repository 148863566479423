import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const LoginreCaptchaTokenQuery = graphql`
  query LoginreCaptchaTokenQuery($token: String!) {
    reCaptchaToken(token: $token)
  }
`;

const LoginreCaptchaToken = (variables) => {
  return fetchQuery(environment, LoginreCaptchaTokenQuery, variables);
};

export default LoginreCaptchaToken;
