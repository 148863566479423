/**
 * @generated SignedSource<<0d839b98916572691fda2123bcc55848>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AdvanceViewRedoGetSelfLearnConfigQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  StudyGroupId: $ReadOnlyArray<?string>,
  SchoolYearId: string,
|};
export type AdvanceViewRedoGetSelfLearnConfigQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceId: ?string,
      +ResourceLangs: ?$ReadOnlyArray<?{|
        +ResourceName: ?string,
        +Lang: ?string,
      |}>,
      +VisibleByStudent: ?string,
      +ShareableToStudent: ?string,
      +SelfLearnConfiguration: ?$ReadOnlyArray<?{|
        +ConfigName: ?string,
        +ConfigValue: ?string,
        +StudyGroupId: ?string,
        +ResourceId: ?string,
      |}>,
    |}>,
  |},
|};
export type AdvanceViewRedoGetSelfLearnConfigQuery = {|
  variables: AdvanceViewRedoGetSelfLearnConfigQuery$variables,
  response: AdvanceViewRedoGetSelfLearnConfigQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "resourceLang",
  "kind": "LinkedField",
  "name": "ResourceLangs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ResourceName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Lang",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "VisibleByStudent",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ShareableToStudent",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ConfigName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ConfigValue",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdvanceViewRedoGetSelfLearnConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "resourceSelflearnConfiguration",
                "kind": "LinkedField",
                "name": "SelfLearnConfiguration",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdvanceViewRedoGetSelfLearnConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "resourceSelflearnConfiguration",
                "kind": "LinkedField",
                "name": "SelfLearnConfiguration",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v4/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eaf61046c9f1c3b43b9fc4c8e818f652",
    "id": null,
    "metadata": {},
    "name": "AdvanceViewRedoGetSelfLearnConfigQuery",
    "operationKind": "query",
    "text": "query AdvanceViewRedoGetSelfLearnConfigQuery(\n  $ResourceId: [String]!\n  $StudyGroupId: [String]!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      ResourceId\n      ResourceLangs {\n        ResourceName\n        Lang\n      }\n      VisibleByStudent\n      ShareableToStudent\n      SelfLearnConfiguration(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        ConfigName\n        ConfigValue\n        StudyGroupId\n        ResourceId\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1f81234f79299bceca6472547fbdedf0";

module.exports = ((node/*: any*/)/*: Query<
  AdvanceViewRedoGetSelfLearnConfigQuery$variables,
  AdvanceViewRedoGetSelfLearnConfigQuery$data,
>*/);
