import React, { useEffect, useState, useRef } from "react";
import ProgressBar from "react-customizable-progressbar";
import PropTypes from "prop-types";
import "./index.scss";
const CircularProgressChart = (props) => {
  const {
    //   radius,
    progress,
    //   strokeWidth,
    //   strokeColor,
    //   strokeLinecap,
    //   trackStrokeWidth,
    //   counterClockwise,
  } = props;
  console.log("[chart render]");
  return (
    <ProgressBar className="progress-chart" {...props}>
      {props.children || ""}
    </ProgressBar>
  );
};

CircularProgressChart.propTypes = {
  radius: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  strokeColor: PropTypes.string.isRequired,
  //   circleTwoStroke: PropTypes.string.isRequired,
};
export default CircularProgressChart;
