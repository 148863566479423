import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import AssessmentInfo from "../../../Layouts/ActivityLayout/B2Ccontent/B2CBody/AssessmentInfo";
import ResultTab from "../../../Layouts/ActivityLayout/B2Ccontent/B2CBody/ResultAndAnswer";
import AnalysisAndSuggestion from "../../../Layouts/ActivityLayout/B2Ccontent/B2CBody/AnalysisAndSuggestion";
// import AnalysisAndSuggestion from "./B2CBody/AnalysisAndSuggestion";
// import AssesmentGroupIconActive from "../../../assets/img/Assemment-Group-Icon-InActive.svg";
// import AssesmentGroupIconNotActive from "../../../assets/img/Assemment-Group-Icon-Active.svg";
// import ResultAnswerIconActive from "../../../assets/img/Result-Answer-Icon-InActive.svg";
// import ResultAnswerIconNotActive from "../../../assets/img/Result-Answer-Icon-Active.svg";
// import AnalysisAndSuggestionIconActive from "../../../assets/img/group-27.svg";
// import AnalysisAndSuggestionIconNotActive from "../../../assets/img/group-27-copy.svg";
import "../../../Layouts/ActivityLayout/B2Ccontent/index.scss";
import "./index.scss";
const index = (props) => {
  let location = useLocation();
  const { t, bookData, resourceData, B2C, isReadyToRender, inappData } = props;
  const [tabState, settabState] = useState(null);
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  useEffect(() => {
    checkTabStatus();
  }, [location]);
  const checkTabStatus = async () => {
    const path = props.location.pathname;
    switch (path) {
      case props.match.url + "/start":
        settabState("assessment");
        break;
      case props.match.url + "/result":
        settabState("result");
        break;
      case props.match.url + "/as":
        settabState("as");
        break;
      case props.match.url + "/answer":
        settabState("answer");
        break;
      default:
        settabState("assessment");
        break;
    }
  };

  return (
    <div className="card ani-card learnosity" id="b2c">
      <div id="b2c" className={`sub-menu ${tabState}`}>
        <div className="menu-title">
          {
            resourceData.ResourceLangs[
              resourceData.ResourceLangs[0].Lang == lang ? 0 : 1
            ].ResourceName
          }
        </div>
        <div className={`menu-list`}>
          <ul>
            <li className="tab">
              <NavLink
                id={"assessment_tab"}
                to={props.match.url + "/start"}
                className="tab-item assessment"
                onClick={() => {
                  // checkTabStatus();
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:Analysis & Follow up, Assessment:" +
                      resourceData.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      resourceData.IngestionId +
                      ", Book Series:" +
                      bookData.BookSeriesName[0].BookSeriesName +
                      ", Book:NoData" +
                      ", Chapter:NoData" +
                      ", Role:" +
                      userType +
                      ", From:Supplementary"
                  );
                  // settabState("assessment");
                }}
              >
                <div className="tab-body">
                  {/* <img
                    src={
                      tabState == "assessment"
                        ? AssesmentGroupIconActive
                        : AssesmentGroupIconNotActive
                    }
                  /> */}
                  <span
                    className={`icon-tab-icon-assessment ${
                      tabState == "assessment" ? "active" : ""
                    }`}
                  ></span>
                  {t("Assessment")}
                </div>
              </NavLink>
            </li>
            <li className="tab">
              <NavLink
                id={"result_tab"}
                to={props.match.url + "/result"}
                className="tab-item result"
                onClick={() => {
                  // checkTabStatus();
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:Result & Answer, Assessment:" +
                      resourceData.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      resourceData.IngestionId +
                      ", Book Series:" +
                      bookData.BookSeriesName[0].BookSeriesName +
                      ", Book:NoData" +
                      ", Chapter:NoData" +
                      ", Role:" +
                      userType +
                      ", From:Supplementary"
                  );
                  // settabState("result");
                }}
              >
                <div className="tab-body">
                  {/* <img
                    src={
                      tabState == "result"
                        ? ResultAnswerIconActive
                        : ResultAnswerIconNotActive
                    }
                  /> */}
                  <span
                    className={`icon-tab-icon-result ${
                      tabState == "result" ? "active" : ""
                    }`}
                  ></span>
                  {t("ResultAndAnswer")}
                </div>
              </NavLink>
            </li>
            <li className="tab">
              <NavLink
                id={"as_tab"}
                to={props.match.url + "/as"}
                className="tab-item as"
                onClick={() => {
                  // checkTabStatus();
                  gaTrack(
                    "send",
                    "event",
                    "Assessment",
                    "Click tab",
                    "Tab:AnalysisAndSuggestion, " +
                      "Role:" +
                      userType +
                      ", From:Supplementary"
                  );
                  // settabState("as");
                }}
              >
                <div className="tab-body">
                  {/* <img
                    src={
                      tabState == "as"
                        ? AnalysisAndSuggestionIconActive
                        : AnalysisAndSuggestionIconNotActive
                    }
                  /> */}
                  <span
                    className={`icon-tab-icon-analysis ${
                      tabState == "as" ? "active" : ""
                    }`}
                  ></span>
                  {t("AnalysisAndSuggestion")}
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={`body ${tabState}`}>
        {tabState && (
          <Switch>
            <Route
              exact
              path="/assessment=api/start"
              component={(p) => (
                <AssessmentInfo
                  t={t}
                  isInappBrowser={true}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                  inappData={inappData}
                />
              )}
            />
            <Route
              exact
              path="/assessment=api/result"
              component={(p) => (
                <ResultTab
                  t={t}
                  isInappBrowser={true}
                  bookData={bookData}
                  //   resourceIdGa={resourceIdGa}
                  //   resourceIngestionIdGa={resourceIngestionIdGa}
                  resourceData={resourceData}
                  //   studyGroupData={studyGroupData}
                  B2C={B2C}
                  {...p}
                  isReadyToRender={isReadyToRender}
                  inappData={inappData}
                />
              )}
            />
            <Route
              exact
              path="/assessment=api/as"
              component={(p) => (
                <AnalysisAndSuggestion
                  {...p}
                  t={t}
                  isInappBrowser={true}
                  bookData={bookData}
                  resourceData={resourceData}
                  B2C={B2C}
                  isReadyToRender={isReadyToRender}
                  inappData={inappData}
                />
              )}
            />
            <Redirect to="/assessment=api/start" />
          </Switch>
        )}{" "}
      </div>
    </div>
  );
};

export default index;
