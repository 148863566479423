import { commitMutation, graphql } from "react-relay";
import environment from "../../../Environment";

const mutation = graphql`
  mutation ReadResourceAssignmentMutation($ResourceAssignId: String!) {
    readResourceAssignment(ResourceAssignId: $ResourceAssignId)
  }
`;

export default function ReadResourceAssignmentMutation(data, action) {
  const variables = {
    ResourceAssignId: data.ResourceAssignId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
