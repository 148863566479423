import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const CheckAuthQuery = graphql`
  query CheckAuthQuery {
    schoolYears {
      SchoolYearId
      SchoolYearName
      IsEffective
      EffectiveStartDate
      EffectiveEndDate
    }
  }
`;

const CheckAuth = () => {
  return fetchQuery(environment, CheckAuthQuery);
};

export default CheckAuth;
