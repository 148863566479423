import React from "react";
import Loading from "../../../Loading/Loading";
import { Trans } from "react-i18next";
export default (props) => {
  const {
    modalData,
    levelList,
    onChange,
    onClose,
    onConfirm,
    loadingList,
    studyGroupInfo,
    t,
    levelOptions,
  } = props;

  var lang = localStorage.getItem("i18nextLng");
  console.log(levelOptions);
  const mapOnOff = (id) => {
    if (levelOptions.indexOf(id) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id="settings_modal_studygroup">
      <div className="modal-header modal-header-colored icon-header">
        <h3 className="modal-title">
          <Trans
            i18nKey="For your teacher's view, please select the book level of you will teach in class"
            count={2}
          >
            {{
              bookseriesname:
                studyGroupInfo.BookSeries.BookSeriesName[
                  studyGroupInfo.BookSeries.BookSeriesName[0].Lang == lang
                    ? 0
                    : 1
                ].BookSeriesName,
              studygroupname:
                studyGroupInfo.StudyGroupName[
                  studyGroupInfo.StudyGroupName[0].Lang == lang ? 0 : 1
                ].StudyGroupName,
            }}
          </Trans>
        </h3>
      </div>
      <div className="modal-body border-top border-bottom with-image-pop-up">
        <div className="class_title">
          <div>{t("Level")}</div>
          <div>{t(`Teacher's view`)}</div>
        </div>
        <div className="class-list">
          {loadingList == true ? (
            <Loading type="normal" />
          ) : (
            levelList.map((obj, index) => {
              console.log(obj);
              return (
                <div className="item" key={index}>
                  <div className="title">
                    <h5>{obj.LevelName}</h5>
                  </div>
                  <div className="option">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id={"checkbox_" + obj.PearsonLevelId}
                        onChange={(e) => {
                          onChange(e, obj.PearsonLevelId);
                        }}
                        defaultChecked={mapOnOff(obj.PearsonLevelId)}
                      />
                      <span className="slider round"></span>
                      {mapOnOff(obj.PearsonLevelId) == true ? (
                        <span className="on">{t("ON")}</span>
                      ) : (
                        <span className="off">{t("OFF")}</span>
                      )}
                    </label>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="modal-footer">
        <div className="modal-btn-right">
          <button className="btn btn-secondary" onClick={() => onClose()}>
            {t("Cancel")}
          </button>
          <button className="btn btn-primary" onClick={() => onConfirm()}>
            {t("Save")}
          </button>
        </div>
      </div>
      <button
        type="button"
        className="react-modal-close normal"
        title="Close"
        onClick={() => onClose()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};
