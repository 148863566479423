import React, { Component } from "react";
import './index.scss'
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    
    return (
      <div className="progress-bar">
        <div className="filler" style={{ width: `${this.props.percentage}%` }} />
      </div>
    );
  }
}

export default index;
