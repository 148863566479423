import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetB2CAttemptHistoryQuery = graphql`
  query GetB2CAttemptHistoryQuery(
    $UserId: String!
    $ResourceId: String!
    $ResourceIds: [String!]
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(ResourceId: $ResourceId) {
        ResourceId
        IsCompleted
        AttemptSessionId
        AttemptDate
        UserId
        Score
        MaxScore
        LearnositySessionRawResult
      }
    }
    resourceSearch(ResourceId: $ResourceIds) {
      Resources {
        TagData
        LearnosityReportStatistic
        LearnosityTagHierarchyRef
        LearnositySuggestionMapping {
          TagName
          TagObjectiveName
          Criteria
          ConditionRule
          TagType
          MappedResources {
            DisplayOrder
            Resource {
              IngestionId
              ResourceId
              RelatedId
              ResourceDownloadUrl
              ResourceFileNameIfAny
              ResourceAccessUrl
              ResourceType
              Linkage
              FileSource
              Downloadable
              ResourceLangs {
                Lang
                ResourceName
              }
            }
          }
        }
      }
    }
  }
`;
const GetB2CAttemptHistory = (variables) => {
  return fetchQuery(environment, GetB2CAttemptHistoryQuery, variables);
};

export default GetB2CAttemptHistory;
