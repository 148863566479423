import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import { Trans } from "react-i18next";
import environment from "../../../../Environment";
import ViewRedoTable from "./ViewRedoTable";
import Loading from "../../../Loading/Loading";

import "./index.scss";

const AdvanceViewRedoGetSelfLearnConfigQuery = graphql`
  query AdvanceViewRedoGetSelfLearnConfigQuery(
    $ResourceId: [String]!
    $StudyGroupId: [String]!
    $SchoolYearId: String!
  ) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        ResourceId
        ResourceLangs {
          ResourceName
          Lang
        }
        VisibleByStudent
        ShareableToStudent
        SelfLearnConfiguration(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
        ) {
          ConfigName
          ConfigValue
          StudyGroupId
          ResourceId
        }
      }
    }
  }
`;

export default (props) => {
  const {
    t,
    onClose,
    onConfirm,
    unitTitle,
    assessmentsData,
    studyGroupInfo,
    listAdvance,
    loadingBox,
  } = props;
  console.log(assessmentsData);

  const assessmentTitle =
    studyGroupInfo.BookSeries.Books[0].BookName[
      studyGroupInfo.BookSeries.Books[0].BookName[0].Lang == lang ? 0 : 1
    ].BookName +
    " - " +
    studyGroupInfo.BookSeries.BookSeriesName[
      studyGroupInfo.BookSeries.BookSeriesName[0].Lang == lang ? 0 : 1
    ].UnitLabel +
    " " +
    unitTitle[unitTitle[0].Lang == lang ? 0 : 1].UnitName;
  const lang = localStorage.getItem("i18nextLng");
  const selectedSchoolYear = localStorage.getItem("selectedSchoolYear");
  const userTypeGA = JSON.parse(localStorage.getItem("userData")).UserType;

  const [viewList, setViewList] = useState([]);
  const [redoList, setRedoList] = useState([]);
  const [finalViewList, setFinalViewList] = useState([]);
  const [finalRedoList, setFinalRedoList] = useState([]);

  const [tabName, setTabName] = useState("view-tab");
  const [loading, setLoading] = useState(true);
  const [resName, setresName] = useState("");
  const statusMapping = {
    hide: "HideAccessment",
    show: "ShowAccessment",
    release: "ReleaseRedo",
  };

  const [isEmpty, setisEmty] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchSelfLearnConfig().then((data) => {
      getSelfLearnConfig(
        data,
        `ShowHide`,
        `view`,
        `ShowAccessment`,
        setViewList
      );
      getSelfLearnConfig(
        data,
        `ReleaseRedo`,
        `redo`,
        `DisableRedo`,
        setRedoList
      );
    });
  }, []);

  useEffect(() => {
    if (viewList.length > 0) {
      setLoading(false);
    }
  }, [viewList.length]);

  const getStatus = (status) => {
    return statusMapping[status] || "DisableRedo";
  };

  const fetchSelfLearnConfig = () => {
    const studyGroupIds = listAdvance.map((eobj) => eobj.StudyGroupId);
    const resourceIds = assessmentsData.map((i) => i.ResourceId);
    const variables = {
      ResourceId: resourceIds,
      StudyGroupId: studyGroupIds,
      SchoolYearId: JSON.parse(selectedSchoolYear).SchoolYearId,
    };

    return fetchQuery(
      environment,
      AdvanceViewRedoGetSelfLearnConfigQuery,
      variables
    );
  };

  const getSelfLearnConfig = (data, configName, type, status, setState) => {
    const {
      resourceSearch: { Resources = [{}] },
    } = data || {};
    console.log(data);
    const configData = Resources.reduce(
      (
        acc,
        {
          SelfLearnConfiguration,
          ResourceId,
          ResourceLangs,
          ShareableToStudent,
          VisibleByStudent,
        },
        i
      ) => {
        const filterd = SelfLearnConfiguration.filter(
          ({ ConfigName }) => ConfigName === configName
        );

        const result = {
          ResourceName:
            ResourceLangs[ResourceLangs[0].Lang == lang ? 0 : 1].ResourceName,
          ShareableToStudent: ShareableToStudent,
          VisibleByStudent: VisibleByStudent,
          // DisplaySequence: DisplaySequence[i],
          type,
        };
        setresName(
          ResourceLangs[ResourceLangs[0].Lang == lang ? 0 : 1].ResourceName
        );

        if (filterd.length > 0) {
          const studyGroupIds = filterd.map(({ StudyGroupId }) => StudyGroupId);

          listAdvance.map((studyGroup) => {
            const { StudyGroupId, StudyGroupName } = studyGroup;
            const group = {
              ...result,
              ResourceId: ResourceId,
              StudyGroupId,
              StudyGroupName,
            };

            const studyGroupIndex = studyGroupIds.indexOf(StudyGroupId);
            if (studyGroupIndex !== -1) {
              const { ConfigValue } = filterd[studyGroupIndex];
              const config = getStatus(ConfigValue);
              acc.push({
                ...group,
                config,
              });
              return;
            }
            acc.push({
              ...group,
              config:
                VisibleByStudent == "N" &&
                ShareableToStudent == "Y" &&
                configName == "ShowHide"
                  ? "HideAccessment"
                  : status,
            });
          });
        } else {
          console.log(status);

          const listarr = listAdvance.map((cobj) => ({
            ...cobj,
            ...result,
            ResourceId: ResourceId,
            config:
              VisibleByStudent == "N" &&
              ShareableToStudent == "Y" &&
              configName == "ShowHide"
                ? "HideAccessment"
                : status,
          }));
          acc.push(...listarr);
        }

        return acc;
      },
      []
    );
    const configDataFilterd = configData.filter((obj) => {
      if (obj.ShareableToStudent == "N" && obj.VisibleByStudent == "N") {
        return;
      } else {
        return obj;
      }
    });

    const configDataReduce = configDataFilterd.reduce((acc, data, i) => {
      const index = acc.findIndex((a) => a.resId === data.ResourceId);
      const { StudyGroupId, config, StudyGroupName } = data;
      if (index === -1) {
        return acc.concat({
          resId: data.ResourceId,
          resName: data.ResourceName,
          // DisplaySequence: data.DisplaySequence,
          type,
          studyData: [{ StudyGroupId, config, StudyGroupName }],
        });
      }

      acc[index].studyData.push({ StudyGroupId, config, StudyGroupName });
      return acc;
    }, []);

    const resourceIds = assessmentsData.filter((obj) => {
      if (
        obj.Resource.ShareableToStudent == "N" &&
        obj.Resource.VisibleByStudent == "N"
      ) {
        return;
      } else {
        return obj;
      }
    });

    const configDataOrder = resourceIds.map((item, i) => {
      const finded = configDataReduce.findIndex(
        (obj) => obj.resId === item.ResourceId
      );
      return configDataReduce[finded];
    });
    console.log(resourceIds);
    console.log(configDataOrder);

    if (configDataOrder === null || configDataOrder.length === 0) {
      setisEmty(true);
      setLoading(false);
    }

    setState(configDataOrder);
  };

  const handleGa = (tab, action) => {
    const isTab = () => {
      if (tab.length > 0) {
        return ", Tab: " + tab;
      } else {
        return "";
      }
    };
    gaTrack(
      "send",
      "event",
      "Assessment setting",
      action,
      "Assessment:" +
        resName +
        ", Book Series:" +
        studyGroupInfo.BookSeries.BookSeriesName[0].BookSeriesName +
        ", Book:" +
        studyGroupInfo.BookSeries.Books[0].BookName[0].BookName +
        ", Chapter:" +
        studyGroupInfo.BookSeries.Books[0].Units[0].UnitName[0].UnitTitle +
        ", Class Level:" +
        studyGroupInfo.Level.LevelName[0].LevelName +
        isTab() +
        ", Role:" +
        userTypeGA
    );
  };

  return loadingBox == true ? (
    <Loading type={"inpage"} />
  ) : (
    <div id="advance_viewRedo">
      <div className="modal-header modal-header-colored icon-header">
        <h3 className="modal-title">
          <Trans i18nKey="Assessment setting for" count={1}>
            {{
              name: assessmentTitle,
            }}
          </Trans>
        </h3>
      </div>

      <div className="modal-body with-image-pop-up">
        <div className="viewredo-tab">
          <ul>
            <li
              onClick={() => {
                setTabName(`view-tab`),
                  handleGa("show hide", "Change tab - advanced");
              }}
            >
              <div className={`${tabName == "view-tab" ? "active" : ""} `}>
                {t("Show")}
              </div>
            </li>
            <li
              onClick={() => {
                setTabName(`redo-tab`),
                  handleGa("redo", "Change tab - advanced");
              }}
            >
              <div className={`${tabName == "redo-tab" ? "active" : ""} `}>
                {t("Redo")}
              </div>
            </li>
          </ul>
        </div>

        <div
          className="class-list"
          style={{ display: tabName === "view-tab" ? "block" : "none" }}
        >
          {loading == true ? (
            <Loading type="normal" />
          ) : (
            <ViewRedoTable
              listAdvance={listAdvance}
              tabName={`Show to student`}
              studyGroupListAdvance={viewList}
              onListChange={(data) => setFinalViewList(data)}
              statusMapping={{
                hide: "HideAccessment",
                show: "ShowAccessment",
              }}
              t={t}
            />
          )}
        </div>

        <div
          className="class-list"
          style={{ display: tabName === "redo-tab" ? "block" : "none" }}
        >
          {loading == true ? (
            <Loading type="normal" />
          ) : (
            <ViewRedoTable
              listAdvance={listAdvance}
              tabName={`Release Redo button`}
              onListChange={(data) => setFinalRedoList(data)}
              studyGroupListAdvance={redoList}
              statusMapping={{
                hide: "DisableRedo",
                show: "ReleaseRedo",
              }}
              t={t}
            />
          )}
        </div>
      </div>

      <div className="modal-footer">
        <div className="modal-btn-right">
          <button
            className="btn btn-secondary"
            onClick={() => {
              onClose(), handleGa("", "Cancel setting - advanced");
            }}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary"
            disabled={isEmpty === true ? true : false}
            onClick={() => {
              onConfirm(finalViewList, finalRedoList),
                handleGa("", "Save setting - advanced");
            }}
          >
            {t("Save")}
          </button>
        </div>
      </div>

      <button
        type="button"
        className="react-modal-close normal"
        title="Close"
        onClick={() => {
          onClose(), handleGa("", "Cancel setting - advanced");
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};
