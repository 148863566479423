import { commitMutation, graphql } from "react-relay";
import environment from "../../../Environment";

const mutation = graphql`
  mutation ReadResourceAssignmentResourceMutation(
    $ResourceAssignResId: String!
  ) {
    readResourceAssignmentResource(ResourceAssignResId: $ResourceAssignResId)
  }
`;

export default function ReadResourceAssignmentResourceMutation(data, action) {
  const variables = {
    ResourceAssignResId: data.ResourceAssignResId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
