/**
 * @generated SignedSource<<5f069646ca362ad16a7d8c967ffacc68>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LoginreCaptchaTokenQuery$variables = {|
  token: string,
|};
export type LoginreCaptchaTokenQuery$data = {|
  +reCaptchaToken: ?boolean,
|};
export type LoginreCaptchaTokenQuery = {|
  variables: LoginreCaptchaTokenQuery$variables,
  response: LoginreCaptchaTokenQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ScalarField",
    "name": "reCaptchaToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginreCaptchaTokenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginreCaptchaTokenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "133ba678615c0d4c4b55f5846f126d97",
    "id": null,
    "metadata": {},
    "name": "LoginreCaptchaTokenQuery",
    "operationKind": "query",
    "text": "query LoginreCaptchaTokenQuery(\n  $token: String!\n) {\n  reCaptchaToken(token: $token)\n}\n"
  }
};
})();

(node/*: any*/).hash = "7fc6585a4c1cb625ef9b338d410e4670";

module.exports = ((node/*: any*/)/*: Query<
  LoginreCaptchaTokenQuery$variables,
  LoginreCaptchaTokenQuery$data,
>*/);
