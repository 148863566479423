import { commitMutation, graphql } from "react-relay";
import environment from "../../../../../Environment";

const mutation = graphql`
  mutation AssessmentGetLearnosityObjectMutation(
    $ResourceId: String!
    $Lang: LangEnumType
    $StudyGroupId: String
    $SchoolYearId: String!
    $Credential: String
  ) {
    attemptSelfLearn(
      ResourceId: $ResourceId
      Lang: $Lang
      StudyGroupId: $StudyGroupId
      SchoolYearId: $SchoolYearId
      Credential: $Credential
    )
  }
`;

export default function AssessmentGetLearnosityObjectMutation(values, action) {
  let variables = {
    ResourceId: values.ResourceId,
    Lang: values.Lang,
    Credential: values.Credential,
    SchoolYearId: values.SchoolYearId,
    StudyGroupId: values.StudyGroupId ? values.StudyGroupId : null,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
