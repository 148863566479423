import {useTranslation} from "react-i18next";

const useIosOpenLearnosity = () => {
    const OPEN_LEARNOSITY_INAPPBROWSER_SETTING = "closebuttoncaption=BACK,hidenavigationbuttons=yes,hidden=yes,location=no,hideurlbar=yes,toolbarposition=top,toolbar=no";
    const {t} = useTranslation();

    const openLearnosity = (hostname, url) => {
        console.log("openLearnosity: ", hostname, url)
        // var OPEN_LEARNOSITY_INAPPBROWSER_SETTING = "closebuttoncaption=BACK,hidenavigationbuttons=yes,hidden=yes,location=no,hideurlbar=yes,toolbarposition=top,toolbar=no";
        // var hostname = "pec.stg.pearson.com.hk";
        // var url = "/a/studygroup/796/assessment/qs&330/10216/start";
        var ref = cordova.InAppBrowser.open("https://"+hostname + url, "_blank", OPEN_LEARNOSITY_INAPPBROWSER_SETTING);
        var isReloaded = false
        // var ref = cordova.InAppBrowser.open("https://pec.dev.pearson.com.hk/"+"a/studygroup/801/assessment/qs&431/12053/start", "_blank");
        ref.addEventListener('loadstop', function() {
            ref.executeScript({
                code: `localStorage.setItem("SessionId", '${window.localStorage.getItem("SessionId")}');`
            });
            ref.executeScript({
                code: `localStorage.setItem("userData", JSON.stringify(${window.localStorage.getItem("userData")}));`
            })
            ref.executeScript({
                code: `localStorage.setItem("effectiveSchoolYear", JSON.stringify(${window.localStorage.getItem("effectiveSchoolYear")}));`
            })
            ref.executeScript({
                code: `localStorage.setItem("i18nextLng", '${window.localStorage.getItem("i18nextLng")}');`
            })
            if (!isReloaded) {
                ref.executeScript({
                    code: `location.href='${"https://"+hostname + url}';`
                })
                isReloaded = true
            } else {

                ref.show()
                setTimeout(() => {
                    ref.executeScript({
                        code: `
                            setInterval(function() {
                                try {
                                    var old_element = document.getElementsByClassName('main__iframe-close-btn')[0];
                                    var new_element = old_element.cloneNode(true);
                                    old_element.parentNode.replaceChild(new_element, old_element);
                                    document.getElementsByClassName('main__iframe-close-btn')[0]
                                        .addEventListener('click', function(e) {
                                            var confirmation = confirm("${t('Are you sure you want to leave?')}")
                                            if (confirmation) {
                                                webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: 'closeIAB'}))
                                            } else {
                                                e.preventDefault()
                                            }
                                        });
                                } catch (e) {
                                }
                                try {
                                    document.getElementsByClassName('test-dialog-quit')[0]
                                        .addEventListener('click', function(e) {
                                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: 'closeIAB'}))
                                        });
                                } catch (e) {
                                }
                            }, 500)
                        `
                    });
                }, 1000);
            }
        });

        ref.addEventListener('message', function(event) {
            if (event.data.action === 'closeIAB') {
                ref.close()
            }
        });
    }

    return { openLearnosity }
}


export default useIosOpenLearnosity;