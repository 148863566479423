import React, { Component } from "react";
import { Doughnut, Bar, Chart } from "react-chartjs-2";
import BasicCharts from "./BasicCharts";
import { Trans } from "react-i18next";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

//   renderDoughnutChart = () => {
// const { charts } = this.props;
//     var ctx = document.getElementById("doughnut-chart").getContext("2d");
//     var myBar = new Chart(ctx, {
//       type: "doughnut",
//       data: {
//         labels: ["Red", "Green"],
//         datasets: [
//           {
//             data: [
//               charts.DoughnutChartData.aveScore,
//               100 - charts.DoughnutChartData.aveScore,
//             ],
//             backgroundColor: ["#008637", "#dddddd"],
//             hoverBackgroundColor: ["#008637", "#dddddd"],
//             borderWidth: 0,
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         legend: {
//           display: false,
//         },
//         rotation: 1 * Math.PI,
//         circumference: 1 * Math.PI,
//         tooltips: {
//           enabled: false,
//         },
//         cutoutPercentage: 70,
//       },
//     });
//   };

  // componentDidMount() {
  //   this.renderDoughnutChart();
  // }

  // componentDidUpdate(prevProps){
  //   if(prevProps.charts.DoughnutChartData.aveScore !== this.props.charts.DoughnutChartData.aveScore){
  //     this.renderDoughnutChart();
  //   }
  // }

  render() {
    const data = {};
    const { t, charts } = this.props;
    const yobj = {
      stepSize: charts.noOfSubmit,
      beginAtZero: true,
    };
    return (
      <>
        <div className="right-chart">
          {/* <div className="report-chart-title">
            {`${t("Score Distribution")} `}
            <Trans i18nKey="Submitted students" count={1}>
              {{
                title: charts.noOfSubmit ? charts.noOfSubmit : 0,
              }}
            </Trans>
          </div> */}
          <div className="chart-box">
            <div className="left-title left-title-result">
              {t("No. of students")}
            </div>
            {/* <div className="bottom-title">{t("Score (%)")}</div> */}
            <div className="bottom-title bottom-title-result">
              {t("Overall Score (%)")}
            </div>
            <div className="chart">
              {/* <canvas id="bar-chart" /> */}
              {/*  <Bar
                width={620}
                height={250}
                data={data2}
                options={{ maintainAspectRatio: false }}
                legend={{ display: false }}
            />*/}
              <BasicCharts
                submitted={yobj}
                datasets={{
                  labels: ["0-20%", "21-40%", "41-60%", "61-80%", "81-100%"],
                  datasets: [
                    {
                      label: "",
                      fill: true,
                      lineTension: 0.1,
                      // backgroundColor: "rgba(75,192,192,0.4)",
                      backgroundColor: [
                        "#CA2C2E",
                        "#FB842D",
                        "#FBC12D",
                        "#B2CF00",
                        "#008638",
                      ],
                      borderColor: "rgba(75,192,192,1)",
                      borderCapStyle: "butt",
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: "miter",
                      pointBorderColor: "rgba(75,192,192,1)",
                      pointBackgroundColor: "#fff",
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: "rgba(75,192,192,1)",
                      pointHoverBorderColor: "rgba(220,220,220,1)",
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      beginAtZero: true,
                      data: [
                        charts.BarChartData.chartsRage.zeroToTwenty,
                        charts.BarChartData.chartsRage.twentyToForty,
                        charts.BarChartData.chartsRage.fortyToSixty,
                        charts.BarChartData.chartsRage.sixtyToEighty,
                        charts.BarChartData.chartsRage.eightyTohundred,
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default index;
