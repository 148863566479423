/**
 * @generated SignedSource<<d32970f61a071f4063962ff5d9345eea>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ViewAllNotificationPECTeacherSubjectListQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
  FilterOnboarded: boolean,
|};
export type ViewAllNotificationPECTeacherSubjectListQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +BookSeries: ?{|
          +IngestionId: ?string,
          +BookSeriesId: ?string,
          +DisplaySequence: ?number,
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +BookSeriesId: ?string,
            +BookSeriesName: ?string,
          |}>,
          +AdoptedBooks: ?$ReadOnlyArray<?{|
            +id: ?string,
            +BookId: ?string,
            +BookName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +BookName: ?string,
            |}>,
            +BookImage: ?{|
              +FilePath: ?string,
            |},
          |}>,
          +Books: ?$ReadOnlyArray<?{|
            +id: ?string,
            +BookId: ?string,
            +BookName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +BookName: ?string,
            |}>,
            +BookImage: ?{|
              +FilePath: ?string,
            |},
          |}>,
          +Subject: ?{|
            +DisplaySequence: ?number,
            +SubjectId: ?string,
            +SubjectName: ?$ReadOnlyArray<?{|
              +SubjectName: ?string,
              +Lang: ?string,
            |}>,
          |},
        |},
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
      |}>,
    |},
  |},
|};
export type ViewAllNotificationPECTeacherSubjectListQuery = {|
  variables: ViewAllNotificationPECTeacherSubjectListQuery$variables,
  response: ViewAllNotificationPECTeacherSubjectListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "FilterOnboarded"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded"
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v5/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "FilePath",
  "storageKey": null
},
v18 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v16/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "systemFile",
    "kind": "LinkedField",
    "name": "BookImage",
    "plural": false,
    "selections": [
      (v17/*: any*/)
    ],
    "storageKey": null
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SubjectId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "subjectLang",
  "kind": "LinkedField",
  "name": "SubjectName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "SubjectName",
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v22 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v16/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "systemFile",
    "kind": "LinkedField",
    "name": "BookImage",
    "plural": false,
    "selections": [
      (v17/*: any*/),
      (v13/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewAllNotificationPECTeacherSubjectListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "book",
                        "kind": "LinkedField",
                        "name": "AdoptedBooks",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "book",
                        "kind": "LinkedField",
                        "name": "Books",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subject",
                        "kind": "LinkedField",
                        "name": "Subject",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ViewAllNotificationPECTeacherSubjectListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "book",
                        "kind": "LinkedField",
                        "name": "AdoptedBooks",
                        "plural": true,
                        "selections": (v22/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "book",
                        "kind": "LinkedField",
                        "name": "Books",
                        "plural": true,
                        "selections": (v22/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subject",
                        "kind": "LinkedField",
                        "name": "Subject",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5ba2fb6cb50ae728ab671f494a6cd59",
    "id": null,
    "metadata": {},
    "name": "ViewAllNotificationPECTeacherSubjectListQuery",
    "operationKind": "query",
    "text": "query ViewAllNotificationPECTeacherSubjectListQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    UserId\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        BookSeries {\n          IngestionId\n          BookSeriesId\n          DisplaySequence\n          BookSeriesName {\n            BookSeriesId\n            BookSeriesName\n          }\n          AdoptedBooks(SchoolYearId: $SchoolYearId) {\n            id\n            BookId\n            BookName {\n              Lang\n              BookName\n            }\n            BookImage {\n              FilePath\n              id\n            }\n          }\n          Books {\n            id\n            BookId\n            BookName {\n              Lang\n              BookName\n            }\n            BookImage {\n              FilePath\n              id\n            }\n          }\n          Subject {\n            DisplaySequence\n            SubjectId\n            SubjectName {\n              SubjectName\n              Lang\n            }\n            id\n          }\n          id\n        }\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "40a2197875f311160dd0be53ac634277";

module.exports = ((node/*: any*/)/*: Query<
  ViewAllNotificationPECTeacherSubjectListQuery$variables,
  ViewAllNotificationPECTeacherSubjectListQuery$data,
>*/);
