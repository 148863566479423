import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import environment from "../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { config } from "../../../../../../Config/config";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
// import bell from "../../../../../../assets/img/suggestion-icon.svg";
// import image from "../../../../../../assets/img/file_type/image.svg";
// import video from "../../../../../../assets/img/file_type/video.svg";
// import pdf from "../../../../../../assets/img/file_type/pdf.svg";
// import exercise from "../../../../../../assets/img/file_type/exercise.svg";
// import openIcon from "../../../../../../assets/img/open-in-new-24-px.svg";
// import ppt from "../../../../../../assets/img/file_type/ppt.svg";
import Loading from "../../../../../Loading/Loading";
// import ProgressBar from "../../../../../Feature/ProgressBar";
// import Suggestion from "../Suggection";
// import FocusSubfocus from "../FocusSubfocus";
import monent from "moment";
import useEncryption from "../../../../../../hooks/useEncryption";
//import { activityLayoutTab } from "../../../../../../Services/Common/common";
// var reportScript;
var reportsApp;

const SingleResultStudentReportHistoryPrizmQuery = graphql`
  query SingleResultStudentReportHistoryPrizmQuery(
    $UserId: String!
    $ResourceId: String!
    $Lang: LangEnumType!
    $Credential: String
  ) {
    user(UserId: $UserId) {
      UserId
      SelfLearnAttemptHistory(
        ResourceId: $ResourceId
        IncludeAssignmentRecord: true
      ) {
        IsCompleted

        AttemptDate
        LearnositySessionRawResult
        Score
        MaxScore
        AttemptSessionId
        LearnositySessionResult(Lang: $Lang, Credential: $Credential)
      }
    }
  }
`;

const SingleResultStudentReportPrizmQuery = graphql`
  query SingleResultStudentReportPrizmQuery(
    $SessionId: String!
    $Lang: LangEnumType!
    $Credential: String
  ) {
    learnositySelfLearnResult(SessionId: $SessionId) {
      AttemptSessionId
      AttemptDate
      IsCompleted
      CompletedDate
      UserId
      ResourceId
      LearnositySessionResult(Lang: $Lang, Credential: $Credential)
      UserDetail {
        UserDisplayName {
          Lang
          UserName
        }
      }
    }
  }
`;

const index = (props) => {
  const {
    B2C,
    t,
    resourceData,
    bookData,
    isInappBrowser,
    standalone,
    inappData,
    activity,
    selectedHistory,
    changeclick,
  } = props;

  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = localStorage.getItem("i18nextLng");
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre")?decryptToLocal(window.localStorage.getItem("userCre")):null;
  const [loading, setloading] = useState(true);
  const [HistoryArray, setHistoryArray] = useState(null);
  const [studentDisplayName, setstudentDisplayName] = useState(null);
  const [openState, setopenState] = useState(["Suggestion"]);
  const [itemTagsData, setitemTagsData] = useState(null);
  // const [reportLoading, setreportLoading] = useState(true);
  const [reportObject, setreportObject] = useState(null);
  const [reportData, setreportData] = useState(null);
  const [questionsData, setquestionsData] = useState(null);
  const [question, setquestion] = useState(null);
  const [correctCount, setcorrectCount] = useState(0);
  const [attemptDate, setattemptDate] = useState("");
  const [timeTaken, settimeTaken] = useState("");
  const [submitURL, setsubmitURL] = useState("");

  

  // const [selectedHistory, setselectedHistory] = useState(props.selectedHistory);
  const [defauleAttemptDate, setdefauleAttemptDate] = useState(null);
  // HistoryArray: null,
  // loading: true,
  // studentDisplayName: null,
  // openState: ["Suggestion"],
  // itemTagsData: null,
  // reportLoading: true,
  // reportData: null,
  // questionsData: null,

  useEffect(() => {
    console.log("[checkReportScriptIsReady]", props.action);

    switch (props.action) {
      case "in-page":
        if (selectedHistory != null) {
          //get object by  key
          getReportPrizmForStudent(selectedHistory);
          //alert(selectedHistory.AttemptSessionId);
          //getReportObjectByAttemptSessionId(selectedHistory.AttemptSessionId);
        }
        break;

      case "page":
        if (props.standalone) {
          if (props.location.state.session_id) {
            // const userType = localStorage.getItem("role");
            // if (!props.isInappBrowser) {
            //   window.document
            //     .getElementById("studygroup_back")
            //     ?.classList.add("dim-button");
            // }
            // window.document
            //   .getElementById("assessment_tab")
            //   ?.classList.add("dim-button");
            // window.document
            //   .getElementById("result_tab")
            //   ?.classList.add("dim-button");
            // if (userType == "T") {
            //   window.document
            //     .getElementById("answer_tab")
            //     ?.classList.add("dim-button");
            //   window.document
            //     .getElementById("back_result")
            //     ?.classList.add("dim-button");
            // }

            getReportBySessionId();
          } else {
            console.log("no session id", props.location);
          }
        } else {
          if (
            // !this.props.match.params.questionId &&
            !props.match.params.userId
          ) {
            return props.history.push(
              "/a/studygroup/" +
                props.match.params.id +
                "/assessment/" +
                props.match.params.bookNunitId +
                "/" +
                props.match.params.resId +
                "/result"
            );
          }
          if (!props.location.state) {
            return props.history.push(
              "/a/studygroup/" +
                props.match.params.id +
                "/assessment/" +
                props.match.params.bookNunitId +
                "/" +
                props.match.params.resId +
                "/result"
            );
          }
          if (props.location.state.session_id) {
            // const userType = localStorage.getItem("role");
            if (!props.isInappBrowser) {
              // window.document
              //   .getElementById("studygroup_back")
              //   ?.classList.add("dim-button");
            }

            if (userType == "T") {
              // window.document
              //   .getElementById("back_result")
              //   ?.classList.add("dim-button");
            }

            getReportBySessionId();
          } else {
            console.log("no session id", props.location);
          }
        }

        break;
    }
  }, []);

  useEffect(() => {
    if (props.changeclick != 0) {
      console.log(props, "componentWillReceiveProps");
      if (props.selectedHistory != null) {
        // setselectedHistory(props.selectedHistory);
        getReportObjectByAttemptSessionId(
          props.selectedHistory.AttemptSessionId
        );
      }
    }
  }, [props.changeclick]);

  const getReportObjectByAttemptSessionId = (id) => {
    let variables = {
      SessionId: id,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(
      environment,
      SingleResultStudentReportPrizmQuery,
      variables
    ).then((data) => {
      console.log(data);

      if (!data.learnositySelfLearnResult) {
        console.log("Faill to get Data : learnositySelfLearnResult null");
      }

      setstudentDisplayName(userData.UserDisplayName);
      setloading(false);
      // this.setState({
      //   loading: false,
      // });
      //getReport(objectData);
    });
  };

  const getStudentHistoryList = (userid, resid) => {
    let variables = {
      UserId: userid,
      ResourceId: resid,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(
      environment,
      SingleResultStudentReportHistoryPrizmQuery,
      variables
    ).then((data) => {
      var listArray = [...data.user.SelfLearnAttemptHistory];
      var templistArray = [...data.user.SelfLearnAttemptHistory];
      listArray.sort(function (a, b) {
        return new monent(a.AttemptDate) - new monent(b.AttemptDate);
      });
      listArray.reverse();
      var _defauleAttemptDate = listArray.length - 1;

      setHistoryArray(templistArray);
      setdefauleAttemptDate(_defauleAttemptDate);
      if (!listArray[0].Score || !listArray[0].MaxScore) {
        setcorrectCount("");
      } else {
        var score =
          (parseFloat(listArray[0].Score) / parseInt(listArray[0].MaxScore)) *
          100;
        //var score = score +  parseFloat(((data[0].Score / data[0].MaxScore) * 100).toFixed(0));
        console.log(data, score);
        setcorrectCount(Math.round(score) + "%");
      }
      var timeset = convertHMS(listArray);
      settimeTaken(timeset);
      setsubmitURL(listArray[0].LearnositySessionResult)
      console.log(listArray, _defauleAttemptDate);
      setloading(false);
    });
  };

  const getReportPrizm = (sessionId) => {};

  const convertHMS = (attemptObj) => {
    var str = attemptObj[0].LearnositySessionRawResult
      ? attemptObj[0].LearnositySessionRawResult
      : "";
    if (str != "") {
      var final = str.replace(/\\/g, "");
      var json_data = JSON.parse(final);
      var value = "";
      console.log(json_data);
      // if(json_data.length > 0){
      //   var scoreVal = json_data.filter((data) => {
      //     return data.contentType =="LEARNETIC";
      //   });
      //   if(scoreVal.length > 0)
      //   value = scoreVal[0].totalSecondsTracked;
      // }
      value = json_data.totalSecondsTracked;
      if (value != "") {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const getReportPrizmForStudent = (attemptObj) => {
    let variables;

    variables = {
      SessionId: attemptObj.AttemptSessionId,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(
      environment,
      SingleResultStudentReportPrizmQuery,
      variables
    ).then((data) => {
      config.mode == "dev" && console.log(data);

      setattemptDate(data.learnositySelfLearnResult.AttemptDate);
      setstudentDisplayName(
        data.learnositySelfLearnResult.UserDetail.UserDisplayName
      );

      getStudentHistoryList(
        data.learnositySelfLearnResult.UserId,
        data.learnositySelfLearnResult.ResourceId
      );
      //setloading(false);
    });
  };

  const openSubmission = () => {
    //alert("cominh");
    window.open(submitURL, "_blank");
  }

  const getReportBySessionId = () => {
    let variables;

    variables = {
      SessionId: props.location.state.session_id,
      Lang: lang == "en-US" ? "EN" : "TC",
      Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
    };

    fetchQuery(
      environment,
      SingleResultStudentReportPrizmQuery,
      variables
    ).then((data) => {
      config.mode == "dev" && console.log(data);

      setattemptDate(data.learnositySelfLearnResult.AttemptDate);
      setstudentDisplayName(
        data.learnositySelfLearnResult.UserDetail.UserDisplayName
      );

      getStudentHistoryList(
        data.learnositySelfLearnResult.UserId,
        data.learnositySelfLearnResult.ResourceId
      );
    });
  };
  return (
    <>
      {props.action == "page" && !loading && (
        <div className="result-menu" style={{ marginTop: "0" }}>
          <a
            id={"back_result"}
            exact
            onClick={() => {
              var newS = props.location.pathname.replace(
                "/" + props.match.params.userId,
                ""
              );
              props.history.push({
                pathname: newS,
                state: {
                  group_id: selectOptions.selectedStudyGroupId,
                  leftMenu: props.location.state.leftMenu,
                },
              });
              dispatch({
                type: "SET_SELECTED_TYPE",
                selectOptions: {
                  selectedStudyGroupId: selectOptions.selectedStudyGroupId,
                  selectedStudyGroupName: selectOptions.selectedStudyGroupName,
                  setselectedResAssignId: "",
                  selectedReportType: "",
                  selectedAssessType: "",
                },
              });
            }}
          >
            {t("Result")}
          </a>
          <div className="gap">
            <i className="icon mdi mdi-chevron-right"></i>
          </div>
          <div className="active">
            {studentDisplayName &&
            studentDisplayName[
              lang == studentDisplayName[0].Lang ? 0 : 1
            ].UserName.trim().length == 0
              ? studentDisplayName[0].UserName
              : studentDisplayName[lang == studentDisplayName[0].Lang ? 0 : 1]
                  .UserName}
          </div>
        </div>
      )}
      {loading && (
        <div className="splash-container">
          <div className="loader mx-auto" />
        </div>
      )}
      {/* {!loading && ( */}
      <div className={props.action == "in-page" ? "" : "report-box"}>
        {!loading && (
          <>
            <div className="filter-row">
              <div className="user-report">
                <h5>
                  <Moment format="YYYY-MM-DD HH:mm">{attemptDate}</Moment>
                </h5>
              </div>
            </div>

            <div className="questions-bar">
              <table>
                <thead>
                  <tr>
                    <th>{t("Student")}</th>
                    <th>{t("Score")}</th>
                    <th>{t("Time")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {studentDisplayName &&
                      studentDisplayName[
                        lang == studentDisplayName[0].Lang ? 0 : 1
                      ].UserName.trim().length == 0
                        ? studentDisplayName[0].UserName
                        : studentDisplayName[
                            lang == studentDisplayName[0].Lang ? 0 : 1
                          ].UserName}
                    </td>
                    <td>{correctCount}</td>
                    <td>{timeTaken}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* <div id="sessions-summary">
        <button
            class="btn btn-primary view-submission"
            onClick={() => {
              //alert("test");
              openSubmission();
             
            }}
          
          >
            View Submission{" "}
            <i class="icon mdi mdi-open-in-new new-open-icon"></i>
          </button>
        </div> */}

        {/* <div className="type-row">{this.renderDummyData()}</div> */}
        {/* <Suggestion
        {...this.props}
        reportData={reportData}
        itemTagsData={itemTagsData}
        action="teacher_view_student"
      /> */}

        <br />
        <div id="session-detail"></div>
      </div>
      {/* // )} */}
    </>
  );
};

export default index;
