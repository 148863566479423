/**
 * @generated SignedSource<<693e31c12408a49e1a27acc9244dd702>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UnitListGetSelfLearnConfigQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  StudyGroupId: $ReadOnlyArray<?string>,
  SchoolYearId: string,
|};
export type UnitListGetSelfLearnConfigQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +SelfLearnConfiguration: ?$ReadOnlyArray<?{|
        +ConfigName: ?string,
        +ConfigValue: ?string,
        +StudyGroupId: ?string,
        +ResourceId: ?string,
      |}>,
    |}>,
  |},
|};
export type UnitListGetSelfLearnConfigQuery = {|
  variables: UnitListGetSelfLearnConfigQuery$variables,
  response: UnitListGetSelfLearnConfigQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  },
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ConfigName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ConfigValue",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitListGetSelfLearnConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "resourceSelflearnConfiguration",
                "kind": "LinkedField",
                "name": "SelfLearnConfiguration",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnitListGetSelfLearnConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "resourceSelflearnConfiguration",
                "kind": "LinkedField",
                "name": "SelfLearnConfiguration",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0359c25b472d1dc9a398d3918f7dfde",
    "id": null,
    "metadata": {},
    "name": "UnitListGetSelfLearnConfigQuery",
    "operationKind": "query",
    "text": "query UnitListGetSelfLearnConfigQuery(\n  $ResourceId: [String]!\n  $StudyGroupId: [String]!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      SelfLearnConfiguration(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        ConfigName\n        ConfigValue\n        StudyGroupId\n        ResourceId\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ac826316cf3d0225864ebaa448cba7f9";

module.exports = ((node/*: any*/)/*: Query<
  UnitListGetSelfLearnConfigQuery$variables,
  UnitListGetSelfLearnConfigQuery$data,
>*/);
