import { createBrowserHistory, createHashHistory } from "history";

var history = () => {
  if (window.cordova) {
    //basename: document.location.href.replace('file://', '')
    return createHashHistory();
  } else {
    if (window.location.pathname.indexOf("/user_contingency") != -1) {
      return createBrowserHistory({ basename: "user_contingency" });
    }
    return createBrowserHistory();
  }
};

export default history();
