import * as types from "./types";
import i18n from "../i18n";
const initState = {
  from: localStorage.getItem("from") || "Web",
  lang:
    localStorage.getItem("i18nextLng-inapp") == null
      ? localStorage.getItem("i18nextLng") || "en-US"
      : localStorage.getItem("i18nextLng-inapp"),
  effectiveSchoolYear: localStorage.getItem("effectiveSchoolYear")
    ? JSON.parse(localStorage.getItem("effectiveSchoolYear"))
    : null,
  selectedSchoolYear: localStorage.getItem("selectedSchoolYear")
    ? JSON.parse(localStorage.getItem("selectedSchoolYear"))
    : null,
};
const SystemReducer = (state = initState, action) => {
  console.log("[SystemReducer]", state, action);
  switch (action.type) {
    case types.SystemReducer.SET_LANG:
      i18n.changeLanguage(action.lang);
      localStorage.setItem("i18nextLng", action.lang);
      return {
        ...state,
        lang: action.lang,
      };
    case types.SystemReducer.SET_SCHOOL_YEAR:
      return {
        ...state,
        effectiveSchoolYear: action.effectiveSchoolYear,
      };
    case types.SystemReducer.SET_COSTOM_SCHOOL_YEAR:
      localStorage.setItem(
        "selectedSchoolYear",
        JSON.stringify(action.selectedSchoolYear)
      );
      return {
        ...state,
        selectedSchoolYear: action.selectedSchoolYear,
      };
    case types.SystemReducer.SET_PLATFROM:
      return {
        ...state,
        from: action.from,
      };
    default:
      return state;
  }
};

export default SystemReducer;
