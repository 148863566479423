/**
 * @generated SignedSource<<07361a9c07d10e13ae9b0cab6e2396b2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type MySchoolBookSeriesDataTeacherCheckingQuery$variables = {|
  SchoolCode: string,
  SchoolYearId: string,
|};
export type MySchoolBookSeriesDataTeacherCheckingQuery$data = {|
  +school: ?{|
    +Schools: ?$ReadOnlyArray<?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +IsB2CBookSeries: ?boolean,
          +IngestionId: ?string,
        |},
      |}>,
    |}>,
  |},
|};
export type MySchoolBookSeriesDataTeacherCheckingQuery = {|
  variables: MySchoolBookSeriesDataTeacherCheckingQuery$variables,
  response: MySchoolBookSeriesDataTeacherCheckingQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "SchoolCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "SchoolYearId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "SchoolCode",
    "variableName": "SchoolCode"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "FilterOnboarded",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsB2CBookSeries",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MySchoolBookSeriesDataTeacherCheckingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "schoolSearchResult",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "Schools",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MySchoolBookSeriesDataTeacherCheckingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "schoolSearchResult",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "Schools",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6d2914e3d2f423160455fe42f828443",
    "id": null,
    "metadata": {},
    "name": "MySchoolBookSeriesDataTeacherCheckingQuery",
    "operationKind": "query",
    "text": "query MySchoolBookSeriesDataTeacherCheckingQuery(\n  $SchoolCode: String!\n  $SchoolYearId: String!\n) {\n  school(SchoolCode: $SchoolCode) {\n    Schools {\n      StudyGroups(FilterOnboarded: true, SchoolYearId: $SchoolYearId) {\n        BookSeries {\n          BookSeriesId\n          IsB2CBookSeries\n          IngestionId\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "877bb7e990a699c64a40db20f0e0b507";

module.exports = ((node/*: any*/)/*: Query<
  MySchoolBookSeriesDataTeacherCheckingQuery$variables,
  MySchoolBookSeriesDataTeacherCheckingQuery$data,
>*/);
