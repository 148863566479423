import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "../../../Environment";
import moment from "moment";
import Loading from "../../Loading/Loading";
import { Trans } from "react-i18next";
import { config } from "../../../Config/config";
import "./ViewAssignmentStudents.scss";
import checkIcon from "../../../assets/img/check-24-px.svg";
import crossIcon from "../../../assets/img/close-24-px.svg";
const ViewAssignmentResourceByStudentsQuery = graphql`
  query ViewAssignmentResourceByStudentsQuery(
    $ResourceAssignId: String!
    $FilterCurrentuser: Boolean
    $SchoolYearId: String!
  ) {
    resourceAssignment(ResourceAssignId: $ResourceAssignId) {
      ResourceAssignId
      AssignmentName
      DueDate
      AssignmentDescription
      Resources {
        ResourceAssignResId
        DisplaySequence
        Resource {
          ResourceLangs {
            ResourceName
            Lang
          }
          ResourceId
        }
        ResourceId
        ResourceAssignTargetStatus {
          UserId
          Status
          StatusTime
        }
      }
      AssignmentTargetStatus {
        UserId
        Status
        StatusTime
      }
      AssignmentTargets(FilterCurrentUser: $FilterCurrentuser) {
        ResourceAssignId
        UserId
        StudyGroupId
        StudyGroup {
          StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
            User {
              UserId
              UserName
              UserDisplayName {
                UserId
                Lang
                UserName
              }
              
            }
            ClassNumber
            UserId
          }
          StudyGroupName {
            StudyGroupId
            StudyGroupName
            Lang
          }
        }
        User {
          UserId
          UserDisplayName {
            UserName
            Lang
          }
          BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
            ClassNumber
            StudyGroup {
              BookSeries {
                BookSeriesId
              }
              StudyGroupName {
                Lang
                StudyGroupName
              }
            }
          }
        }
      }
    }
  }
`;
export default (props) => {
  console.log(props);
  const { t, resourceAssignId, resourceId } = props;
  const selectedSchoolYear = localStorage.getItem("selectedSchoolYear");

  const role = localStorage.getItem("role");
  const lang = localStorage.getItem("i18nextLng");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [studentList, setstudentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dueDate, setDueDate] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [page, setPage] = useState(1); //1
  //const [orderBy, setOrderBy] = useState("READ"); //BookSeries ASSIGNMENT_NAME  SUBJECT  BookSeries  ASSIGN_DATE  DUE_DATE
  const [order, setOrder] = useState("ASC"); //ASC   DESC
  const [orderBy, setOrderBy] = useState("READ"); //STUDENT   READ

  useEffect(() => {
    if (lang == "en-US") {
      moment.locale("en-US");
    } else {
      moment.locale("zh-HK");
    }
    fetchAssignmentDetailsQuery().then((data) => {
      console.log(data);
      const { resourceAssignment } = data || {};
      renderTheViews(resourceAssignment);
    });
  }, []);

  useEffect(() => {
    //setLoading(true);
    const temp = [...studentList];
    if (orderBy == "READ") {
      if (order == "DESC") {
        temp.sort(function (a, b) {
          var va = a.statusTime === null ? "" : "" + a.statusTime,
            vb = b.statusTime === null ? "" : "" + b.statusTime;
          return va < vb ? 1 : va === vb ? 0 : -1;
        });
      } else if (order == "ASC") {
        temp.sort(function (a, b) {
          var va = a.statusTime === null ? "" : "" + a.statusTime,
            vb = b.statusTime === null ? "" : "" + b.statusTime;
          return va > vb ? 1 : va === vb ? 0 : -1;
        });
      }
    }

    if (studentList.length != 0) {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;
      if (orderBy == "STUDENT") {
        if (order == "DESC") {
          temp.sort(function (a, b) {
            var va = a.classNameNumber,
              vb = b.classNameNumber;

            var aA = va.replace(reA, "");
            var bA = vb.replace(reA, "");
            if (aA === bA) {
              var aN = parseInt(va.replace(reN, ""), 10);
              var bN = parseInt(vb.replace(reN, ""), 10);
              return aN === bN ? 0 : aN < bN ? 1 : -1;
            } else {
              return aA < bA ? 1 : -1;
            }
          });
        } else if (order == "ASC") {
          temp.sort(function (a, b) {
            var va = a.classNameNumber,
              vb = b.classNameNumber;

            var aA = va.replace(reA, "");
            var bA = vb.replace(reA, "");
            if (aA === bA) {
              var aN = parseInt(va.replace(reN, ""), 10);
              var bN = parseInt(vb.replace(reN, ""), 10);
              return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
              return aA > bA ? 1 : -1;
            }
          });
        }
      }
    }
    console.log(temp);
    setstudentList(temp);
    //setLoading(false);
  }, [order, orderBy]); // will do action when oreder / orderby have changes

  const sortAlphaNum = (a, b) => {
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  };
  const filterUser = (userId) => {
    return function (element) {
      return element.UserId == userId;
    };
  };

  const filterResource = (resourceId) => {
    return function (element) {
      return element.ResourceId == resourceId;
    };
  };

  const renderTheViews = (obj) => {
    var userIds = [];
    //var userObj = [{"userId" : 1},{"userId" : 2}];
    var userObj = [];
    console.log(obj);
    setDueDate(obj.DueDate);
    obj.AssignmentTargets.map((record, index) => {
      if (!record.UserId) {
        if (!record.StudyGroup) return;
        if (!record.StudyGroup.StudyGroupUser) return;
        var studygroupnameInfo = record.StudyGroup.StudyGroupName[
          record.StudyGroup.StudyGroupName.Lang == lang
            ? 0
            : 1
        ].StudyGroupName;
        record.StudyGroup.StudyGroupUser.map((i) => {
          let usrObj ={};
          usrObj.UserId = i.User.UserId;
          usrObj.studyGroupName = studygroupnameInfo;
          usrObj.classNumber = i.ClassNumber;
          usrObj.classNameNumber =studygroupnameInfo+i.ClassNumber
          usrObj.displayName = i.User.UserDisplayName[
            i.User.UserDisplayName[0].Lang == lang ? 0 : 1
          ].UserName;
          userObj.push(usrObj);
           
        });
        //userIds = [...userIds, ..._userids];
      } else {
        let usrObj ={};
        usrObj.UserId = record.UserId;
        usrObj.studyGroupName = record.User.BelongsToStudyGroup[0].StudyGroup.StudyGroupName[
          record.User.BelongsToStudyGroup[0].StudyGroup.StudyGroupName.Lang == lang
          ? 0
          : 1
          ].StudyGroupName;
        usrObj.classNumber = record.User.BelongsToStudyGroup[0].ClassNumber;
        usrObj.classNameNumber =studygroupnameInfo+record.User.BelongsToStudyGroup[0].ClassNumber;
        usrObj.displayName = record.User.UserDisplayName[
          record.User.UserDisplayName[0].Lang == lang ? 0 : 1
        ].UserName;
        userObj.push(usrObj);
      }
    });

    let resourceObjStatus = obj.Resources.filter(filterResource(resourceId));

    var finalUserObj = [];
    userObj.map((i) => {
      let userInfo = {};
      
      let userObjStatus =
        resourceObjStatus[0].ResourceAssignTargetStatus.filter(
          filterUser(i.UserId)
        );
      if (userObjStatus.length > 0) {
        userInfo = {
          info: i,
          classNameNumber: i.studyGroupName+i.classNumber,
          status: userObjStatus[0],
          order: 1,
          statusTime: userObjStatus[0].StatusTime,
        };
      } else {
        userInfo = {
          info: i,
          classNameNumber: i.studyGroupName+i.classNumber,
          status: null,
          order: 2,
          statusTime: null,
        };
      }
      console.log(userInfo);
      finalUserObj.push(userInfo);
    });
    //Ascending By Read
    finalUserObj.sort((a, b) => {
      return a.order - b.order;
    });
    setstudentList(finalUserObj);
    setLoading(false);
  };

  const checkDueDate = (dateval) => {
    let due = moment(dueDate).format("YYYY-MM-DD");
    let seen = moment(dateval).format("YYYY-MM-DD");
    if (seen > due) {
      return false;
    }
    return true;
  };

  const getDueDateStyle = (dateval) => {
    let due = moment(dueDate).format("YYYY-MM-DD");
    let seen = moment(dateval).format("YYYY-MM-DD");
    if (seen > due) {
      return {
        color: "#db0020",
      };
    }
    return {
      color: "#000000",
    };
  };
  const fetchAssignmentDetailsQuery = () => {
    const variables = {
      ResourceAssignId: resourceAssignId,
      FilterCurrentuser: role == "teacher" ? false : true,
      SchoolYearId: JSON.parse(selectedSchoolYear).SchoolYearId,
    };

    return fetchQuery(
      environment,
      ViewAssignmentResourceByStudentsQuery,
      variables
    );
  };

  return (
    <section className="student-list">
      {loading == true ? (
        <Loading type="normal" />
      ) : (
        <table className="table student-list-table">
          <thead>
            <tr>
              <th
                style={{ cursor: "pointer" }}
                className="student-name-label"
                onClick={() => {
                  setOrder(order == "DESC" ? "ASC" : "DESC");
                  setOrderBy("STUDENT");
                }}
              >
                {t("Student")}
                {orderBy == "STUDENT" &&
                  (order == "DESC" ? (
                    <i
                      style={{ marginLeft: "5px" }}
                      className="icon mdi mdi-long-arrow-down"
                    ></i>
                  ) : (
                    <i
                      style={{ marginLeft: "5px" }}
                      className="icon mdi mdi-long-arrow-up"
                    ></i>
                  ))}
              </th>
              <th
                style={{ cursor: "pointer" }}
                className="viewed-label"
                onClick={() => {
                  setOrder(order == "DESC" ? "ASC" : "DESC");
                  setOrderBy("READ");
                }}
              >
                {t("Viewed")}
                {orderBy == "READ" &&
                  (order == "DESC" ? (
                    <i
                      style={{ marginLeft: "5px" }}
                      className="icon mdi mdi-long-arrow-down"
                    ></i>
                  ) : (
                    <i
                      style={{ marginLeft: "5px" }}
                      className="icon mdi mdi-long-arrow-up"
                    ></i>
                  ))}
              </th>
            </tr>
          </thead>
          <tbody>
            {studentList.map((rec, index) => {
              return (
                <tr key={index}>
                  <td className="student-name">
                    {rec.info.studyGroupName + " - "}
                    {rec.info.classNumber + " "}
                    {rec.info.displayName
                    }
                  </td>

                  <td className="viewed-date">
                    {rec.status && (
                      <span>
                        <img
                          src={
                            checkDueDate(rec.status.StatusTime)
                              ? checkIcon
                              : crossIcon
                          }
                        ></img>
                      </span>
                    )}
                    {rec.status && (
                      <span style={getDueDateStyle(rec.status.StatusTime)}>
                        {rec.status.StatusTime}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
};
