/**
 * @generated SignedSource<<e38b129d4951808090e4fd1b7bdf050f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type CompleteSelfLearnMutation$variables = {|
  ResourceId: string,
  SessionId: string,
  Lang?: ?LangEnumType,
  StudyGroupId?: ?string,
  SchoolYearId: string,
|};
export type CompleteSelfLearnMutation$data = {|
  +completeSelfLearn: ?string,
|};
export type CompleteSelfLearnMutation = {|
  variables: CompleteSelfLearnMutation$variables,
  response: CompleteSelfLearnMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SessionId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "Lang",
        "variableName": "Lang"
      },
      {
        "kind": "Variable",
        "name": "ResourceId",
        "variableName": "ResourceId"
      },
      {
        "kind": "Variable",
        "name": "SchoolYearId",
        "variableName": "SchoolYearId"
      },
      {
        "kind": "Variable",
        "name": "SessionId",
        "variableName": "SessionId"
      },
      {
        "kind": "Variable",
        "name": "StudyGroupId",
        "variableName": "StudyGroupId"
      }
    ],
    "kind": "ScalarField",
    "name": "completeSelfLearn",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteSelfLearnMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompleteSelfLearnMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "241ee863103fb23c14221d09f634164f",
    "id": null,
    "metadata": {},
    "name": "CompleteSelfLearnMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteSelfLearnMutation(\n  $ResourceId: String!\n  $SessionId: String!\n  $Lang: LangEnumType\n  $StudyGroupId: String\n  $SchoolYearId: String!\n) {\n  completeSelfLearn(ResourceId: $ResourceId, SessionId: $SessionId, Lang: $Lang, StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "7e79150a29a237d0773c2652466f8210";

module.exports = ((node/*: any*/)/*: Mutation<
  CompleteSelfLearnMutation$variables,
  CompleteSelfLearnMutation$data,
>*/);
