import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import AssessmentTab from "../PopUpView/Page/AssessmentLayout/AssessmentTab";
import AssessmentInfo from "../../Layouts/ActivityLayout/B2Ccontent/B2CBody/AssessmentInfo";
import ModelAnswerTab from "../PopUpView/Page/AssessmentLayout/ModelAnswerTab";
import ResultTab from "../PopUpView/Page/AssessmentLayout/ResultTab";
import ResultB2CTab from "../../Layouts/ActivityLayout/B2Ccontent/B2CBody/ResultAndAnswer";
import AnalysisAndSuggestion from "../../Layouts/ActivityLayout/B2Ccontent/B2CBody/AnalysisAndSuggestion";
// import { useLocation } from "react-router-dom";
import LearnosityLayoutGetTitle from "../../Services/api/GraphQL/Query/LearnosityLayoutGetTitle";
import Loading from "../Loading/Loading";
import Footer from "../Footer/Footer";
import QuestionView from "../PopUpView/Page/AssessmentLayout/ResultTab/AllStudentResult/QuestionView";
import { useDispatch, useSelector } from "react-redux";
import SingleResult from "../PopUpView/Page/AssessmentLayout/ResultTab/SingleResult/index";
// import NavBar from "../NavBar/Dashboard/NavBar";
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const LearnosityLayout = (props) => {
  console.log(props);
  const { match, t } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const layoutDesc = useSelector((state) => state.LayoutIdentifyReducer.layout);
  console.log(layoutDesc);
  const userType = userData?.UserType;
  const schoolCodeCheck = userData?.School?.SchoolCode;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [loading, setloading] = useState(true);
  const [resData, setresData] = useState(null);
  const [isInappBrowser, setisInappBrowser] = useState(false);
  const from = useSelector((state) => state.SystemReducer.from);
  const [B2C, setB2C] = useState(false);
  const [tabState, settabState] = useState(null);
  //   let query = useQuery();

  useEffect(() => {
   
    if (from === "App") {
      setisInappBrowser(true);
    }
    if (props.entry === "assignment") {
      dispatch({
        type: "SET_ENTRY_ASSESSMENT",
        entryPoints: "assignment",
      });
    }

    console.log(match.params.resId);
    switch (props.entry) {
      case "assignment":
        if (!match.params.resId) {
          return props.history.push("/");
        }
        let params = match.params.resId.split("&");
        if (params.length != 3) {
          return props.history.push("/");
        }
        let res_id = params[0];
        let assign_id = params[1];
        if (!res_id || !assign_id) {
          return props.history.push("/");
        }

        //start
        var variables = {
          ResourceId: [res_id],
        };
        LearnosityLayoutGetTitle(variables).then((data, error) => {
          console.log(data);
          const {
            resourceSearch: { Resources = [{}] },
          } = data || {};
          setresData({
            ...Resources[0],
            res_id,
            assign_id,
          });
          console.log(Resources[0]);
          setloading(false);
        });
        break;
      case "activity":
        if(layoutDesc=="b2c"){
          setB2C(true);
        }
        checkTabStatus();
        if (!match.params.resId) {
          return props.history.push("/");
        }
        let resId = match.params.resId;
        var variables = {
          ResourceId: [resId],
        };
        console.log(variables);
        LearnosityLayoutGetTitle(variables).then((data, error) => {
          console.log(data);
          const {
            resourceSearch: { Resources = [{}] },
          } = data || {};
          setresData({
            ...Resources[0],
            res_id: resId,
          });
          console.log(Resources[0]);
          setloading(false);
        });
        break;
      default:
        break;
    }
  }, []);

  const checkTabStatus = async () => {
    const path = props.location.pathname;
    switch (path) {
      case props.match.url + "/start":
        settabState("assessment");
        break;
      case props.match.url + "/result":
        settabState("result");
        break;
      case props.match.url + "/as":
        settabState("as");
        break;
      case props.match.url + "/answer":
        settabState("answer");
        break;
      default:
        settabState("assessment");
        break;
    }
  };
  console.log(resData);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="be-wrapper be-fixed-sidebar pop_up_view">
      <nav
        id="dashboard"
        className="navbar navbar-expand fixed-top class-nav-bar  studygroup"
      >
        <div className="main_nav_container">
          <div className="be-navbar-header">
            <div className="back">
              {/* <i className="icon mdi mdi-chevron-left"></i> */}
              {/* <div
                className="back-btnText"
                // onClick={() =>
                //   props.history.push("/assignment/start/info?token=123")
                // }
              >
                Dashboard
              </div> */}
            </div>
          </div>
          <div className="class-nb-title ">
            <div
              id="study-group-view-title"
              className="study-group-view-title"
              style={{ color: "black" }}
            >
              {!B2C &&
                resData &&
                resData.ResourceLangs[
                  lang == resData.ResourceLangs[0].Lang ? 0 : 1
                ].ResourceName}
            </div>
          </div>
          <div className="class-nb-buttons"></div>
        </div>
      </nav>
      <div className="be-container">
        <div
          id={"class_body"}
          className={
            "be-content card-pb bg-color site-wrapper " + (B2C ? "b2c" : "b2b")
          }
        >
          {!B2C && (
            <div id="a" className="dashboard-body">
              <div className="row">
                <div className="card ani-card learnosity api" id="assessment">
                  <div className="sub-menu">
                    <div className="menu-list">
                      <ul>
                        <li>
                          <div
                            id={"assessment_tab"}
                            className={`navlink ${
                              props.location.pathname.indexOf("/start") != -1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              gaTrack(
                                "send",
                                "event",
                                "Assessment",
                                "Click tab",
                                "Tab:Assessment, " + "Role:" + userType
                              );
                              if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                                window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                              }
                              props.history.push(
                                "/" +
                                  props.entry +
                                  "/" +
                                  match.params.resId +
                                  "/start"
                              );
                            }}
                          >
                            {t("Assessment")}
                          </div>
                        </li>
                        {userType == "T" && props.entry != "activity" && (
                          <li>
                            <div
                              id={"answer_tab"}
                              className={`navlink ${
                                props.location.pathname.indexOf("/answer") != -1
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                gaTrack(
                                  "send",
                                  "event",
                                  "Assessment",
                                  "Click tab",
                                  "Tab:Model Answer, " + "Role:" + userType
                                );
                                if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                                  window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                                }
                                props.history.push(
                                  "/" +
                                    props.entry +
                                    "/" +
                                    match.params.resId +
                                    "/answer"
                                );
                              }}
                            >
                              {t("Model Answers")}
                            </div>
                          </li>
                        )}
                        <li>
                          <div
                            id={"result_tab"}
                            className={`navlink ${
                              props.location.pathname.indexOf("/result") != -1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if(window.document.querySelector('.lrn_btn.lrn_pause_playback')){
                                window.document.querySelector('.lrn_btn.lrn_pause_playback').click();
                              }
                              props.history.push(
                                "/" +
                                  props.entry +
                                  "/" +
                                  match.params.resId +
                                  "/result"
                              );
                            }}
                          >
                            {userType == "S" ? t("My Result") : t("Result")}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="body">
                    {userType == "T" && (
                      <Switch>
                        <Route
                          exact
                          path={"/" + props.entry + "/:resId/start"}
                          component={(p) => (
                            <AssessmentTab
                              {...p}
                              {...props}
                              resourceData={resData}
                              bookData={resData.BookSeries}
                              isInappBrowser={isInappBrowser}
                              standalone={true}
                              activity={
                                props.entry == "activity" ? true : false
                              }
                              isReadyToRender={true}
                            />
                          )}
                        />
                        {props.entry != "activity" && (
                          <Route
                            exact
                            path={"/" + props.entry + "/:resId/answer"}
                            component={(p) => (
                              <ModelAnswerTab
                                {...p}
                                {...props}
                                resourceData={resData}
                                bookData={resData.BookSeries}
                                isInappBrowser={isInappBrowser}
                                standalone={true}
                                activity={true}
                                isReadyToRender={true}
                              />
                            )}
                          />
                        )}

                        <Route
                          exact
                          path={"/" + props.entry + "/:resId/result"}
                          component={(p) => (
                            <ResultTab
                              {...p}
                              {...props}
                              isInappBrowser={isInappBrowser}
                              standalone={true}
                              activity={
                                props.entry == "activity" ? true : false
                              }
                              isReadyToRender={true}
                              resourceData={resData}
                              bookData={resData.BookSeries}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={
                            "/" + props.entry + "/:resId/result/q/:questionId"
                          }
                          component={(p) => (
                            <QuestionView
                              resourceData={resData}
                              bookData={resData.BookSeries}
                              standalone={true}
                              isReadyToRender={true}
                              t={t}
                              {...p}
                              isInappBrowser={isInappBrowser}
                              activity={
                                props.entry == "activity" ? true : false
                              }
                            />
                          )}
                        />

                        <Route
                          exact
                          path={"/" + props.entry + "/:resId/result/:userId"}
                          component={(p) => (
                            <SingleResult
                              t={t}
                              {...p}
                              action={"page"}
                              {...props}
                              isInappBrowser={isInappBrowser}
                              standalone={true}
                              activity={
                                props.entry == "activity" ? true : false
                              }
                              isReadyToRender={true}
                              bookData={resData.BookSeries}
                              resourceData={resData}
                            />
                          )}
                        />
                      </Switch>
                    )}
                    {userType == "S" && (
                      <Switch>
                        <Route
                          exact
                          path={"/" + props.entry + "/:resId/start"}
                          component={(p) => (
                            <AssessmentTab
                              {...p}
                              {...props}
                              resourceData={resData}
                              bookData={resData.BookSeries}
                              isInappBrowser={isInappBrowser}
                              standalone={true}
                              activity={
                                props.entry == "activity" ? true : false
                              }
                              isReadyToRender={true}
                              entry={props.entry}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={"/" + props.entry + "/:resId/result"}
                          component={(p) => (
                            <ResultTab
                              {...p}
                              {...props}
                              resourceData={resData}
                              bookData={resData.BookSeries}
                              isInappBrowser={isInappBrowser}
                              standalone={true}
                              activity={
                                props.entry == "activity" ? true : false
                              }
                              isReadyToRender={true}
                            />
                          )}
                        />
                      </Switch>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {B2C && (
            <div className="card ani-card learnosity" id="b2c">
              <div id="b2c" className={`sub-menu ${tabState}`}>
                <div className="menu-title">
                  {resData &&
                    resData.ResourceLangs[
                      lang == resData.ResourceLangs[0].Lang ? 0 : 1
                    ].ResourceName}
                </div>
                <div className={`menu-list`}>
                  <ul>
                    <li className="tab">
                      <NavLink
                        id={"assessment_tab"}
                        to={props.match.url + "/start"}
                        className="tab-item assessment"
                        onClick={() => {
                          // checkTabStatus();
                          // checkTabStatus();
                          settabState("assessment");
                          gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Click tab",
                            "Tab:Analysis & Follow up, Assessment:" +
                              resData.ResourceLangs[0].ResourceName +
                              ", Resource ID:" +
                              resData.IngestionId +
                              ", Book Series:" +
                              resData.BookSeries.BookSeriesName[0]
                                .BookSeriesName +
                              ", Book:NoData" +
                              ", Chapter:NoData" +
                              ", Role:" +
                              userType +
                              ", From:Supplementary"
                          );
                          // settabState("report");
                        }}
                      >
                        <div className="tab-body">
                          {/* <img
                    src={
                      tabState == "assessment"
                        ? AssesmentGroupIconActive
                        : AssesmentGroupIconNotActive
                    }
                  /> */}
                          <span
                            className={`icon-tab-icon-assessment ${
                              tabState == "assessment" ? "active" : ""
                            }`}
                          ></span>
                          {t("Assessment")}
                        </div>
                      </NavLink>
                    </li>
                    <li className="tab">
                      <NavLink
                        id={"result_tab"}
                        to={props.match.url + "/result"}
                        className="tab-item result"
                        onClick={() => {
                          // checkTabStatus();
                          // checkTabStatus();
                          settabState("result");
                          gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Click tab",
                            "Tab:Result & Answer, Assessment:" +
                              resData.ResourceLangs[0].ResourceName +
                              ", Resource ID:" +
                              resData.IngestionId +
                              ", Book Series:" +
                              resData.BookSeries.BookSeriesName[0]
                                .BookSeriesName +
                              ", Book:NoData" +
                              ", Chapter:NoData" +
                              ", Role:" +
                              userType +
                              ", From:Supplementary"
                          );
                          // settabState("report");
                        }}
                      >
                        <div className="tab-body">
                          {/* <img
                    src={
                      tabState == "result"
                        ? ResultAnswerIconActive
                        : ResultAnswerIconNotActive
                    }
                  /> */}
                          <span
                            className={`icon-tab-icon-result ${
                              tabState == "result" ? "active" : ""
                            }`}
                          ></span>
                          {t("ResultAndAnswer")}
                        </div>
                      </NavLink>
                    </li>
                    <li className="tab">
                      <NavLink
                        id={"as_tab"}
                        to={props.match.url + "/as"}
                        className="tab-item as"
                        onClick={() => {
                          // checkTabStatus();
                          settabState("as");
                          gaTrack(
                            "send",
                            "event",
                            "Assessment",
                            "Click tab",
                            "Tab:AnalysisAndSuggestion, " +
                              "Role:" +
                              userType +
                              ", From:Supplementary"
                          );
                          // settabState("as");
                        }}
                      >
                        <div className="tab-body">
                          {/* <img
                    src={
                      tabState == "as"
                        ? AnalysisAndSuggestionIconActive
                        : AnalysisAndSuggestionIconNotActive
                    }
                  /> */}
                          <span
                            className={`icon-tab-icon-analysis ${
                              tabState == "as" ? "active" : ""
                            }`}
                          ></span>
                          {t("AnalysisAndSuggestion")}
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`body ${tabState}`}>
                {tabState && (
                  <Switch>
                    <Route
                      exact
                      path={"/" + props.entry + "/:resId/start"}
                      component={(p) => (
                        // <AssessmentTab
                        //   {...p}
                        //   {...props}
                        //   resourceData={resData}
                        //   bookData={resData.BookSeries}
                        //   isInappBrowser={isInappBrowser}
                        //   standalone={true}
                        //   activity={props.entry == "activity" ? true : false}
                        //   isReadyToRender={true}
                        //   entry={props.entry}
                        // />
                        <AssessmentInfo
                          t={t}
                          isInappBrowser={isInappBrowser}
                          bookData={resData.BookSeries}
                          //   resourceIdGa={resourceIdGa}
                          //   resourceIngestionIdGa={resourceIngestionIdGa}
                          resourceData={resData}
                          //   studyGroupData={studyGroupData}
                          standalone={true}
                          activity={props.entry == "activity" ? true : false}
                          B2C={B2C}
                          {...p}
                          isReadyToRender={true}
                          inappData={{
                            resourceId: match.params.resId,
                          }}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={"/" + props.entry + "/:resId/result"}
                      component={(p) => (
                        // <ResultTab
                        //   {...p}
                        //   {...props}
                        //   isInappBrowser={isInappBrowser}
                        //   standalone={true}
                        //   activity={props.entry == "activity" ? true : false}
                        //   isReadyToRender={true}
                        //   resourceData={resData}
                        //   bookData={resData.BookSeries}
                        // />

                        <ResultB2CTab
                          t={t}
                          isInappBrowser={isInappBrowser}
                          bookData={resData.BookSeries}
                          resourceData={resData}
                          activity={props.entry == "activity" ? true : false}
                          //   studyGroupData={studyGroupData}
                          B2C={B2C}
                          {...p}
                          isReadyToRender={true}
                          inappData={{
                            resourceId: match.params.resId,
                          }}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={"/" + props.entry + "/:resId/as"}
                      component={(p) => (
                        <AnalysisAndSuggestion
                          {...p}
                          {...props}
                          isInappBrowser={isInappBrowser}
                          standalone={true}
                          // bookData={bookData}
                          // resourceData={resData}
                          activity={props.entry == "activity" ? true : false}
                          B2C={B2C}
                          isReadyToRender={true}
                          resourceData={resData}
                          bookData={resData.BookSeries}
                          inappData={{
                            resourceId: match.params.resId,
                          }}
                        />
                      )}
                    />
                    <Redirect to="/assessment=api/start" />
                  </Switch>
                )}
              </div>
            </div>
          )}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LearnosityLayout;
