import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation AttemptAssignmentMutation(
    $ResourceAssignResId: String!
    $Credential: String
    $Lang: LangEnumType
  ) {
    attemptAssignment(ResourceAssignResId: $ResourceAssignResId, Lang: $Lang, Credential: $Credential) 
  }
`;

const AttemptAssignmentMutation = (values, action) => {
  const variables = {
    ResourceAssignResId: values.ResourceAssignResId,
    Credential: values.Credential,
    Lang: values.Lang,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
};

export default AttemptAssignmentMutation;
