import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation DeleteAssignmentMutation($ResourceAssignIds: [String]!) {
    deleteAssignment(ResourceAssignIds: $ResourceAssignIds)
  }
`;
export default function DeleteAssignmentMutation(data, action) {
  const variables = {
    ResourceAssignIds: data.ResourceAssignIds,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
