import * as types from "./types";
const initState = {
  entryPoints: "selfAssessment",
  selectOptions: {
    selectedStructureId: "",
    selectedAssessType: "",
    selectedReportType: "",
    selectedResAssignId: "",
    selectedStudyGroupName: null,
    selectedStudyGroupId: null,
    selectecResourceTab: "",
    selectedToggle: "",
    selectedResourceName:"",
    seletctedTabSDL: "",
    firstLevel: "",
    secondLevel: "",
    thirdLevel: "",
    backpathRes:"",
    showIframeOrSDL:"sdl"
  },
  resId: "",
  toggle: false,
  isEmtyRecords: true,
};

const EntryOfAssessmentReducer = (state = initState, action) => {
  let _isEmtyRecords = true;
  console.log("[EntryOfAssessmentReducer]", state, action);
  switch (action.type) {
    case types.EntryOfAssessmentReducer.SET_ENTRY_ASSESSMENT:
      return {
        ...state,
        entryPoints: action.entryPoints,
      };
    case types.EntryOfAssessmentReducer.SET_SELECTED_TYPE:
      const { selectOptions } = action;

      if (
        state.toggle ||
        selectOptions.selectedAssessType !== "" ||
        selectOptions.selectedReportType !== "" ||
        selectOptions.selectedResAssignId !== "" ||
        selectOptions.selectedStudyGroupId !== null
      ) {
        _isEmtyRecords = false;
      }

      return {
        ...state,
        isEmtyRecords: _isEmtyRecords,
        selectOptions: action.selectOptions,
      };
    case types.EntryOfAssessmentReducer.SET_RES_ID:
      return {
        ...state,
        resId: action.resId,
      };
    case types.EntryOfAssessmentReducer.SET_SHOWALL_TOGGLE:
      if (
        action.toggle ||
        state.selectOptions.selectedAssessType !== "" ||
        state.selectOptions.selectedReportType !== "" ||
        state.selectOptions.selectedResAssignId !== "" ||
        state.selectOptions.selectedStudyGroupId !== null
      ) {
        _isEmtyRecords = false;
      }
      return {
        ...state,
        isEmtyRecords: _isEmtyRecords,
        toggle: action.toggle,
      };

    default:
      return state;
  }
};

export default EntryOfAssessmentReducer;
