import React from "react";
import { Checkbox } from "semantic-ui-react";
import Loading from "../../../Loading/Loading";

export default (props) => {
  const {
    modalData,
    studyGroupList,
    onChange,
    onNotify,
    onClose,
    onConfirm,
    loadingList,
    t,
  } = props;

  console.log(studyGroupList);
  const mapOnOff = (id) => {
    const type = studyGroupList[0].type;
    if (type == "view") {
      if (
        studyGroupList
          .map((i) => {
            return i.StudyGroupId;
          })
          .indexOf(id) !== -1
      ) {
        if (
          studyGroupList[
            studyGroupList
              .map((i) => {
                return i.StudyGroupId;
              })
              .indexOf(id)
          ].config == "ShowResource"
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  var lang = localStorage.getItem("i18nextLng");
  return (
    <div id="viewRedo">
      <div className="modal-header modal-header-colored icon-header">
        <h3 className="modal-title">{modalData.title.header}</h3>
        <h5 className="modal-sub-title">{modalData.title.resName}</h5>
      </div>
      <div className="modal-body with-image-pop-up">
        <div className="class_title">
          <div>{t("Class")}</div>
          <div>{t("ShowPec")}</div>
        </div>
        <div className="class-list">
          {loadingList == true ? (
            <Loading type="normal" />
          ) : (
            studyGroupList.map((obj, index) => {
              console.log(obj);
              return (
                <div className="item" key={index}>
                  <div className="title">
                    <h5>{obj.StudyGroupName}</h5>
                  </div>
                  <div className="option">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id={"checkbox_" + obj.StudyGroupId}
                        onChange={(e) => {
                          onChange(e, obj.StudyGroupId);
                        }}
                        defaultChecked={mapOnOff(obj.StudyGroupId)}
                      />
                      <span className="slider round"></span>
                      {mapOnOff(obj.StudyGroupId) == true ? (
                        <span className="on">{t("ON")}</span>
                      ) : (
                        <span className="off">{t("OFF")}</span>
                      )}
                    </label>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="modal-footer">
        <label
          // key={obj.BookSeriesId}
          className="custom-control custom-control-sm custom-checkbox"
        >
          <input
            type="checkbox"
            className="custom-control-input"
            // value={obj.BookSeriesId}
            onChange={(e) => {
              onNotify(e);
            }}
          />
          <span
            className="custom-control-label"
            // id={obj.BookSeriesId}
          />
          {t("Send notification to students")}
        </label>
        <div className="modal-btn-right">
          <button
            className="btn btn-secondary"
            onClick={() => onClose(modalData.type)}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => onConfirm(modalData.title.resName, modalData.type)}
          >
            {t("Save")}
          </button>
        </div>
      </div>
      <button
        type="button"
        className="react-modal-close normal"
        title="Close"
        onClick={() => onClose(modalData.type)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </button>
    </div>
  );
};
