import React, { Component } from "react";
import Moment from "react-moment";
import hisIcon from "../../../../../../assets/img/group-18.svg";
import "./index.scss";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seletedValue: props.selectedValueId,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      seletedValue: props.selectedValueId,
    });
  }

  renderListRow = (HistoryArray) => {
    return HistoryArray.map((item, index) => {
      if (index == HistoryArray.length - 1) {
        return (
          <option key={index} value={index}>
            {this.props.t("Last Attempt")}
          </option>
        );
      }
      return (
        <Moment
          key={index}
          value={index}
          element="option"
          format="YYYY-MM-DD HH:mm"
        >
          {item.AttemptDate}
        </Moment>
        /*
        <option key={index} value={index}>
          {index == HistoryArray.length - 1 ? (
            "Last Attempt"
          ) : (
            
          )}
        </option>
        */
      );
    });
  };

  render() {
    const { HistoryArray } = this.props;
    const { seletedValue } = this.state;
    const lang = localStorage.getItem("i18nextLng");
    let UserDisplayName = JSON.parse(localStorage.getItem("userData"))
      .UserDisplayName;
    return (
      <div id="res-tag" className="">
        <div className="info-row">
          <div className="username_text">
            <h3>
              {UserDisplayName[
                UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
              ].UserName.trim() == 0
                ? UserDisplayName[UserDisplayName[0].Lang == "en-US" ? 0 : 1]
                    .UserName
                : UserDisplayName[UserDisplayName[0].Lang == lang ? 0 : 1]
                    .UserName}
            </h3>
            <h5>
              {
                <Moment format="YYYY-MM-DD HH:mm">
                  {HistoryArray[seletedValue].AttemptDate}
                </Moment>
              }
            </h5>
          </div>
          <div className="attempt-selection">
            <img src={hisIcon} />
            <select
              className=""
              defaultValue={seletedValue}
              onChange={(e) =>
                this.props.onClickHistory(
                  HistoryArray[e.target.value],
                  e.target.value
                )
              }
            >
              {this.renderListRow(HistoryArray)}
            </select>
            <div className="border"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
