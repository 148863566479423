/**
 * @generated SignedSource<<6a9bf8beb224c43e9d4da901530d0be0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AppSSOLoginGetEPSTokenQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
|};
export type AppSSOLoginGetEPSTokenQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +ResourceDownloadUrl: ?string,
      +ResourceFileNameIfAny: ?string,
      +ResourceAccessUrl: ?string,
      +EpsAccessToken: ?string,
    |}>,
  |},
|};
export type AppSSOLoginGetEPSTokenQuery = {|
  variables: AppSSOLoginGetEPSTokenQuery$variables,
  response: AppSSOLoginGetEPSTokenQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ResourceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceDownloadUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceFileNameIfAny",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAccessUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EpsAccessToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppSSOLoginGetEPSTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppSSOLoginGetEPSTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d8dc8b12f2b1d5dfd39bb43a5a72942",
    "id": null,
    "metadata": {},
    "name": "AppSSOLoginGetEPSTokenQuery",
    "operationKind": "query",
    "text": "query AppSSOLoginGetEPSTokenQuery(\n  $ResourceId: [String]!\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      ResourceDownloadUrl\n      ResourceFileNameIfAny\n      ResourceAccessUrl\n      EpsAccessToken\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f85521e692e630ed519de3032966711a";

module.exports = ((node/*: any*/)/*: Query<
  AppSSOLoginGetEPSTokenQuery$variables,
  AppSSOLoginGetEPSTokenQuery$data,
>*/);
