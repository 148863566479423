import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const ResourcesSearchCheckAccessRightGCQuery = graphql`
  query ResourcesSearchCheckAccessRightGCQuery($IngestionId: [String]!
     $UserId: String!
     $BookSeriesId : String!
     $BookId: String!) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesName {
          Lang
          BookSeriesName
        }
        Books(BookId: $BookId) {
          BookId
          BookName {
            Lang
            BookName
          }
        }

      }

    }
    resourceSearch(IngestionId: $IngestionId) {
      Resources {
        Thumbnail {
          FilePath
        }
        ResourceId
        ResourceType
        IngestionId
        BookSeriesId
        BookId
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
        Linkage
        FileSource
        ResourceLangs {
          ResourceName
          Lang
        }
      }
    }
  }
`;
const ResourcesSearchCheckAccessRightGCStudentQuery = graphql`
  query ResourcesSearchCheckAccessRightGCStudentQuery($IngestionId: [String]!
    $StudyGroupId: String!
    $SchoolYearId: String!
    $UserId: String!
    $BookSeriesId : String!
    $BookId: String!) {
      user(UserId: $UserId) {
        AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
          BookSeriesName {
            Lang
            BookSeriesName
          }
          Books(BookId: $BookId) {
            BookId
            BookName {
              Lang
              BookName
            }
          }
  
        }
  
      }
    resourceSearch(IngestionId: $IngestionId,StudyGroupId: $StudyGroupId
      SchoolYearId: $SchoolYearId) {
      Resources {
        Thumbnail {
          FilePath
        }
        ResourceId
        ResourceType
        IngestionId
        BookSeriesId
        BookId
        ResourceDownloadUrl
        ResourceFileNameIfAny
        ResourceAccessUrl
        EpsAccessToken
        Linkage
        FileSource
        ResourceLangs {
          ResourceName
          Lang
        }
      }
    }
  }
`;
const ResourcesSearchCheckAccessRightGC = (variables, role="") => {

  if(role !=""){
    if (role == "T") {
      return fetchQuery(environment, ResourcesSearchCheckAccessRightGCQuery, variables);
    } else {
      return fetchQuery(environment, ResourcesSearchCheckAccessRightGCStudentQuery, variables);
    }
  }else {
    return fetchQuery(
      environment,
      ResourcesSearchCheckAccessRightGCQuery,
      variables
    );
  }
};

export default ResourcesSearchCheckAccessRightGC;
