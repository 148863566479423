//Main
// import React, { Component } from "react";
import LoginSAMMutation from "./LoginSAMMutation";
// import LoginBySSOMutation from "./LoginBySSOMutation";
// import environment from "../../../Environment";
// import { fetchQuery, graphql } from "relay-runtime";
import GetAllSchoolYears from "../../Components/Feature/Function/GetAllSchoolYears";
import GetUserData from "../../Components/Feature/Function/GetUserData";
// import { useDispatch } from "react-redux";
// const LoginUsingSSOTokenGetUserPreQuery = graphql`
//   query LoginUsingSSOTokenGetUserPreQuery {
//     schoolYears {
//       SchoolYearId
//       SchoolYearName
//       IsEffective
//     }
//   }
// `;
import moment from "moment";

import environment from "../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import useGATracking from "../../hooks/useGATracking";

// import { config } from "../../../Config/config";
// const dispatch = useDispatch();

const handleError = (props) => {
  // localStorage.removeItem("SessionId");
  // localStorage.removeItem("accessToken");
  // localStorage.removeItem("ssoToken");
  // localStorage.removeItem("userData");
  props.dispatch({
    type: "SET_USER",
    user: null,
  });
  // props.dispatch({
  //   type: "SET_USER_PREFERENCES",
  //   user_preferences: null,
  // });
};

const LoginUsingIES = (props, isComingFrom) => {
  const {sendGaEvent} = useGATracking();
  console.log("[Start LoginUsingIES]", props, isComingFrom);
//   const variables = {
//     pearsonExtSTGSSOSession: props.ssoToken,
//   };

  const result = (respone, error) => {
    console.log("[Result]", respone, error);
        if (respone.login == null) {
          gaTrack("send", "event", "Login", "Failed", "DB error");
          console.log("Error: Seems is SAM issue!");
          handleError(props);
          props.LoginFail(true);
          props.loadingStyle && props.loadingStyle(false);
        
      } else {
        getUserInfo(respone.login, isComingFrom, props, sendGaEvent);
      }
    
  };
    //if from normal login
    LoginSAMMutation(props.userName, props.ssoToken, props.platform,props.passwordType, result);
  
};

// const getSchoolYear = (loginData, props) => {
//   fetchQuery(environment, LoginUsingSSOTokenGetUserPreQuery).then((data) => {
//     console.log(data);
//     var haveEffective = false;
//     var schoolYearList = [];
//     data.schoolYears.map((obj) => {
//       schoolYearList.push(obj);
//       if (obj.IsEffective == "true") {
//         haveEffective = true;
//         localStorage.setItem("effectiveSchoolYear", JSON.stringify(obj));
//       }
//     });

//     if (haveEffective) {
//       localStorage.setItem("effectiveSchoolYear", JSON.stringify(obj));
//     }
//     //check match two account for ios review
//     if (
//       (loginData.User.UserName == "tp16992702" ||
//         loginData.User.UserName == "sp16992703") &&
//       window.cordova
//     ) {
//       props.history.push("/m/studynotes");
//     } else {
//       props.history.push("/m/myschool");
//     }
//   });
// };

const getUserInfo = (loginData, isComingFrom, props, sendGaEvent) => {
  // props.loadingStyle && props.loadingStyle(true);
  console.log("[Start get user info]", loginData);
  localStorage.setItem("userData", JSON.stringify(loginData.User));
  localStorage.setItem("SessionId", loginData.SessionId);
  localStorage.setItem("accessToken", loginData.SAMAssessToken);
  localStorage.setItem("ssoToken", loginData.SAMSsoToken);
  if (isComingFrom == "ssologin") {
    localStorage.setItem("__from", "p");
  } else {
    localStorage.setItem("__from", "l");
  }
  var isAbleAccountType = false,
    sindex;
  // loginData.User.UserType.map((obj, index) => {
  //   if (config.userTypeList.indexOf(obj.RoleId) != -1 && !isAbleAccountType) {
  //     isAbleAccountType = true;
  //     sindex = index;
  //   }
  // });

  if (loginData.User.UserType == "T") {
    localStorage.setItem("role", "teacher");
    isAbleAccountType = true;
  } else if (loginData.User.UserType == "S") {
    localStorage.setItem("role", "student");
    isAbleAccountType = true;
  } else {
    localStorage.setItem("role", null);
  }
  if (isAbleAccountType) {
    // if (loginData.User.UserType[sindex].RoleId == "2") {
    //   localStorage.setItem("role", "teacher");
    // } else if (loginData.User.UserType[sindex].RoleId == "1") {
    //   localStorage.setItem("role", "student");
    // } else {
    //   localStorage.setItem("role", null);
    // }
  } else {
    window.alert("Login UserType Error!");
    handleError(props);
    // dispatch({
    //   type: "SET_USER",
    //   user: null,
    // });
    setTimeout(() => props.history.push("/"), 100);
    return;
  }

  // if (window.cordova) {
  //   window.ga.addCustomDimension(
  //     1,
  //     loginData.User.UserType,
  //     () => {
  //       console.log("[GA addCustomDimension 1] Success");
  //     },
  //     () => {
  //       console.log("[GA addCustomDimension 1] Error");
  //     }
  //   );
  //   window.ga.trackEvent(
  //     "Login",
  //     "Success",
  //     "Role:" + localStorage.getItem("role")
  //   );
  // } else {
  //   ReactGA.set({ dimension1: loginData.User.UserType });
  //   ReactGA.event({
  //     category: "Login",
  //     action: "Success",
  //     label: "Role:" + localStorage.getItem("role"),
  //   });
  // }

  gaTrack("set", "dimension1", loginData.User.UserType);
  gaTrack(
    "send",
    "event",
    "Login",
    "Success",
    "Role:" + localStorage.getItem("role")
  );

  var haveEffective = false;
  GetAllSchoolYears((list) => {
    var schoolYearList = list;
    console.log(list);
    schoolYearList.map((obj, index) => {
      if (obj.IsEffective == "true") {
        haveEffective = true;
        localStorage.setItem("effectiveSchoolYear", JSON.stringify(obj));
        props.dispatch({
          type: "SET_SCHOOL_YEAR",
          effectiveSchoolYear: obj,
        });
      }
    });

    if (!haveEffective) {
      props.dispatch({
        type: "SET_SCHOOL_YEAR",
        effectiveSchoolYear: schoolYearList[0],
      });
      localStorage.setItem(
        "effectiveSchoolYear",
        JSON.stringify(schoolYearList[0])
      );
    }

    GetUserData((userData) => {
      localStorage.setItem("userData", userData);
      var userObj = JSON.parse(userData);
      try {
        sendGaEvent('General_Login_server', {
          "user_Device": "App",
          "user_School": userObj?.School?.SchoolCode,
          "user_Role": userObj?.UserType,
          "user_Class": userObj?.UserType == "T" ? "" : userObj?.BelongsToStudyGroup[0]?.StudyGroup?.StudyGroupName[0]?.StudyGroupName ?? '',
          "user_Level": userObj?.UserType == "T" ? "" : userObj?.BelongsToStudyGroup[0]?.StudyGroup?.Level?.LevelName[0]?.LevelName ?? '',
        })
      } catch (error) {
        console.log('Error:', error);
      }
      props.dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: userObj.Preferences,
      });
      props.dispatch({
        type: "SET_COSTOM_SCHOOL_YEAR",
        selectedSchoolYear:
          userObj.AdoptedSchoolYear.sort(function (a, b) {
            return (
              new moment(b.EffectiveStartDate) -
              new moment(a.EffectiveStartDate)
            );
          })[0] || schoolYearList[0],
      });
      props.dispatch({
        type: "SET_USER",
        user: userObj,
      });
      gaTrack(
        "set",
        "dimension4",
        userObj.School ? userObj.School.SchoolCode : "NoData"
      );
      // dispatch({
      //   type: "SET_USER",
      //   user: userObj,
      // });

      //for qrcode flow
      //if localstorage find redirct path or action

      //check match two account for ios review
      if (
        // (userObj.UserName == "100357t04" ||
        //   userObj.UserName == "sp16992703") &&
        // window.cordova
          userObj.UserName === "100357t04" || userObj.UserName === "tp12519204"
      ) {
        props.history.push("/studynotes");
      } else {
        console.log(userObj, "testuserobj", JSON.parse(localStorage.getItem("effectiveSchoolYear"))
        .SchoolYearId);
        if (userObj.UserType == "T") {
          var listArray = [];
          userObj.School.SchoolAdoptedBookSeries.map((obj, index) => {
            if (obj.IngestionId == null || obj.IngestionId == "") return;
            listArray.push(obj);
          });

          var result = userObj.PreferencesWithYear.filter(obj => {
            return obj.PreferenceName === "ONBOARDING_SKIP"
          })
          console.log(listArray);
          if(userObj.AdoptedSchoolYear.length>0 && listArray.length > 0 
            && JSON.parse(localStorage.getItem("effectiveSchoolYear"))
            .SchoolYearId == result[0]?.SchoolYearId){
              props.history.push("/m");
            // if (
            //   userObj.PreferencesWithYear.map((obj) => {
            //     return obj.PreferenceName;
            //   }).indexOf("ONBOARDING_SKIP") != -1
            // ) {
            //   if (
            //     userObj.PreferencesWithYear[
            //       userObj.PreferencesWithYear.map((obj) => {
            //         return obj.PreferenceName;
            //       }).indexOf("ONBOARDING_SKIP")
            //     ].PreferenceValue == "true"
            //   ) {
            //     props.history.push("/m");
            //   } else {
            //     props.history.push("/onboarding");
            //   }
            // } else {
            //       props.history.push("/onboarding");
            //  }

          }else{
            props.history.push("/m");
          }
        } else {
          props.history.push("/m");
        }
      }
    });
  });
};

export default LoginUsingIES;
