import React, { Component } from "react";
import "./index.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const userType = localStorage.getItem("role");
    const { t, openModal } = this.props;
    return (
      // <div className="message-box inline" id={this.props.id}>
      <div className="message-box inline message-box-inline" id={this.props.id}>
        {/* {this.props.type == "ResourceCenter" && userType == "teacher" && (
          <button
            className="btn btn-primary settings"
            onClick={() => openModal()}
          >
            <div className="settings_stuygroup">
              <i className="icon mdi mdi-settings" />
              {" " + t("Settings")}
            </div>
          </button>
        )} */}
        {this.props.type == "ResourceCenter" && (
          <i className="icon mdi mdi-caret-up ResourceCenter"></i>
        )}
        {this.props.src && this.props.id == "Noti_tools" && (
          <div style={{ marginTop: "120px" }}>
            <img src={this.props.src} />
          </div>
        )}
        {this.props.src && this.props.id != "Noti_tools" && (
          <img
            className={
              this.props.type == "ResourceCenter" ||
              this.props.type == "B2CResourceCenter"
                ? "resourceCenterImg"
                : "defaultImg"
            }
            src={this.props.src}
          />
        )}
        <div className="message">{this.props.message}</div>
        {this.props.message2 !== "" ? (
          <div className="message">{this.props.message2}</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default index;
