/**
 * @generated SignedSource<<a3fa0fa56101aa95317b27c5f0b2073e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GraphQLQueryGetUserDetailsQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type GraphQLQueryGetUserDetailsQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
      +ClassNumber: ?string,
      +StudyGroupId: ?string,
      +StudyGroup: ?{|
        +StudyGroupId: ?string,
        +Level: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +LevelName: ?string,
            +PearsonLevelId: ?string,
          |}>,
          +DisplaySequence: ?string,
        |},
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
        +StudyGroupCode: ?string,
      |},
    |}>,
  |},
|};
export type GraphQLQueryGetUserDetailsQuery = {|
  variables: GraphQLQueryGetUserDetailsQuery$variables,
  response: GraphQLQueryGetUserDetailsQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ClassNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PearsonLevelId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "kind": "LinkedField",
  "name": "LevelName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LevelName",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupCode",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLQueryGetUserDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "BelongsToStudyGroup",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroup",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLQueryGetUserDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "BelongsToStudyGroup",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroup",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45b86db9a4f66d523806282af779b04b",
    "id": null,
    "metadata": {},
    "name": "GraphQLQueryGetUserDetailsQuery",
    "operationKind": "query",
    "text": "query GraphQLQueryGetUserDetailsQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n      ClassNumber\n      StudyGroupId\n      StudyGroup {\n        StudyGroupId\n        Level {\n          PearsonLevelId\n          LevelName {\n            Lang\n            LevelName\n            PearsonLevelId\n          }\n          DisplaySequence\n          id\n        }\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        StudyGroupCode\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "da1d7d2fbab6160dc691b8ae322aa376";

module.exports = ((node/*: any*/)/*: Query<
  GraphQLQueryGetUserDetailsQuery$variables,
  GraphQLQueryGetUserDetailsQuery$data,
>*/);
