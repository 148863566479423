import React, { useEffect, useState } from "react";
import Loading from "../../../Loading/Loading";
import { Trans } from "react-i18next";
import Modal from "react-modal";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import UpdateUserPreferencesMutation from "../../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";

export default (props) => {
  const { isOpen, onClose, t } = props;
  const dispatch = useDispatch();
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);

  const [checked, setChecked] = useState(false);

  const onClosex = () => {
      gaTrack(
        "send",
        "event",
        "General",
        "Privacy policy update",
        "Click:Close"
      );
      onClose();
  };

  const onOKx = () => {
    gaTrack(
      "send",
      "event",
      "General",
      "Privacy policy update",
      "Click:OK"
    );
    UpdateUserPreference();
    onClose();
};
  
  const onClickOpenLink = (link) => {

// var link = "";
//     if(linklang == "en-US"){
//       link = "https://www.pearson.com.hk/en_GB/privacy-policy.html";
//     }else{
//       link = "https://www.pearson.com.hk/zh_HK/privacy-policy.html";
//     }
    if (window.cordova) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };


  const UpdateUserPreference = () => {
   
    var data = {
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      PreferenceName: "PRIVACY_POLICY",
      PreferenceValue: "true",
    };

    const callback = (result) => {
      console.log(result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
      
    };
    UpdateUserPreferencesMutation(data, callback);
  };

 // console.log(props);
  return (
    <>
    <div className="modal-header messagebox">
    <div className="modal-title"> {t("Privacy Policy Updated")}</div>
  </div>
  
    <div className="modal-body">
      <div> <p>{t("Thanks for visiting and/or using Pearson product/services, to better protect your privacy, we have updated our Privacy Policy on October 18, 2021. You may peruse")} 
        <span className="privacy-text">
        {localStorage.getItem("i18nextLng") && (
          localStorage.getItem("i18nextLng") == "en-US" ? (
           <a   onClick={() =>
            onClickOpenLink("https://www.pearson.com.hk/en_GB/privacy-policy.html")
          }>{t("Privacy Policy")} </a> ) : (<a
            onClick={() =>
              onClickOpenLink("https://www.pearson.com.hk/zh_HK/privacy-policy.html")
            }
            // target="_blank"
          > {t("Privacy Policy")} </a>)
          )}
          </span>
          {t("for more details.")}</p>
        
          </div>
       
    </div>
    <div className="modal-footer messagebox">
    <div className="modal-btn-right ">
    <button
            className="btn btn-primary"
            onClick={() => {
              onOKx();
            }}
          >
            {t("Ok")}
          </button>
      
    </div>
  </div>
   

  </>

  );
};
