import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation CompleteAssignmentMutation(
    $ResourceAssignResId: String!
    $Lang: LangEnumType
    $SessionId: String!
  ) {
    completeAssignment(
      ResourceAssignResId: $ResourceAssignResId
      Lang: $Lang
      SessionId: $SessionId
    )
  }
`;
const CompleteAssignmentMutation = (values, action) => {
  const variables = {
    ResourceAssignResId: values.ResourceAssignResId,
    Lang: values.Lang,
    SessionId: values.SessionId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
};

export default CompleteAssignmentMutation;
