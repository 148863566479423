import React, { useState, useEffect } from "react";
import { fetchQuery, graphql } from "relay-runtime";
import environment from "../../../../Environment";
import peopleIcon from "../../../../assets/img/people-24-px.svg";
import Loading from "../../../Loading/Loading";
import moment from "moment";
import { Trans } from "react-i18next";
import FullScreenModal from "../../FullScreenModal";
import NotificationItem from "../../../NavBar/Dashboard/NotificationItem";
import MessageLightBox from "../../MessageLightBox";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";

const RelatedAssignmentTabelGetInfoQuery = graphql`
  query RelatedAssignmentTabelGetInfoQuery(
    $ResourceId: [String!]
    $StudyGroupId: String!
    $SchoolYearId: String!
  ) {
    resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {
      Resources {
        AssignementInvloved(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
        ) {
          LastUpdateDate
          BookSeries {
            BookSeriesId
            BookSeriesName {
              Lang
              BookSeriesName
            }
          }
          AssignmentName
          CreateDate
          DueDate
          AssignmentTargets(FilterCurrentUser: false) {
            ResourceAssignId
            UserId
            StudyGroupId

            User {
              UserDisplayName {
                Lang
                UserName
              }
              BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
                ClassNumber
                StudyGroup {
                  BookSeries {
                    BookSeriesId
                  }
                  StudyGroupName {
                    Lang
                    StudyGroupName
                  }
                }
              }
            }
            StudyGroup {
              StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
                UserId
              }
              StudyGroupName {
                StudyGroupId
                Lang
                StudyGroupName
              }
            }
          }
          AssignmentTargetStatus {
            ResourceAssignId
            UserId
            Status
          }
        }
      }
    }
  }
`;
export default (props) => {
  const {
    t,
    studyGroupId,
    resData,
    studyGroupData,
    resDataForGa,
    onClose,
  } = props;

  const schoolYearId = JSON.parse(localStorage.getItem("effectiveSchoolYear"))
    .SchoolYearId;

  const UserType = JSON.parse(localStorage.getItem("userData")).UserType;

  const dispatch = useDispatch();

  const userRelatedList = useSelector(
    (state) => state.ResCenterAssignedRelatedReducer.resCenterAssignedRelated
  );
  const totalCount = useSelector(
    (state) => state.ResCenterAssignedRelatedReducer.TotalNumberOfRecord
  );
  console.log(totalCount, "totalCount");

  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const [loading, setloading] = useState(true);
  const [listloading, setListLoading] = useState(true);
  const [bookserires, setBookserires] = useState("");
  const [isModalOpen, setisModalOpen] = useState(false);
  const [assignmentDetailsData, setAssignmentDetailsData] = useState({
    isOpen: false,
    resId: 0,
    assignmentName: "",
  });
  const [messageLightBoxOpen, setMessageLightBoxOpen] = useState(false);
  const [assignmentData, setassignmentData] = useState(null);
  const [orderBy, setOrderBy] = useState("ASSIGNMENT_LIST");
  const [order, setOrder] = useState("ASC"); //ASC   DESC

  console.log(resDataForGa);

  useEffect(() => {
    if (assignmentDetailsData.isOpen) {
      setisModalOpen(true);
    } else {
      setisModalOpen(false);
    }
  }, [assignmentDetailsData]);

  useEffect(() => {
    window.reloadRelatedAss = reloadRelatedAss;
    reloadRelatedAss();
  }, [totalCount]);

  const reloadRelatedAss = () => {
    setListLoading(true);
    GetRelatedAssignmentList().then((data) => {
      console.log(data);
      console.log("loading query 1");
      const {
        resourceSearch: { Resources = [{}] },
      } = data || {};

      if (
        Resources[0].AssignementInvloved === null ||
        Resources[0].AssignementInvloved.length === 0
      ) {
        setloading(false);
        setListLoading(false);
        onClose();
        dispatch({
          type: "SET_RESCENTER_RELOAD",
          resCenterIsReload: true,
        });
        dispatch({
          type: "SET_RESCENTERASSIGNEDRELATED",
          resCenterAssignedRelated: [],
        });
        return;
      }
      const BookSeries = Resources[0].AssignementInvloved[0].BookSeries;
      setBookserires(BookSeries);

      const assignementInvloved = Resources[0].AssignementInvloved;

      const userListInfo = assignementInvloved.reduce(
        (
          acc,
          {
            AssignmentName,
            CreateDate,
            DueDate,
            AssignmentTargetStatus,
            AssignmentTargets,
          }
        ) => {
          acc.push({
            AssignmentName,
            CreateDate,
            DueDate,
            AssignmentTargetStatus,
            AssignmentTargets,
            BookSeries,
          });

          return acc;
        },
        []
      );
      console.log(userListInfo, "userListInfo");
      dispatch({
        type: "SET_RESCENTERASSIGNEDRELATED",
        resCenterAssignedRelated: userListInfo,
      });
      // dispatch({
      //   type: "SET_SET_RESCENTER_ASSIGNED_RELATED_TOTAL_ITEMS",
      //   TotalNumberOfRecord: userRelatedList.length,
      // });
      setListLoading(false);
      setloading(false);
    });
  };

  const GetRelatedAssignmentList = () => {
    const variables = {
      StudyGroupId: studyGroupId,
      SchoolYearId: schoolYearId,
      ResourceId: resData.resId,
    };
    return fetchQuery(
      environment,
      RelatedAssignmentTabelGetInfoQuery,
      variables
    );
  };
  const renderAssignee = (targets) => {
    // var bookSeriesData = BookSeries;
    var current_target_classes = targets
      .filter((item, index) => {
        return item.User == null;
      })
      .sort((a, b) => {
        return a.StudyGroup.StudyGroupName[
          a.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
        ].StudyGroupName.localeCompare(
          b.StudyGroup.StudyGroupName[
            b.StudyGroup.StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName
        );
      });
    var current_target_users = targets.filter((item, index) => {
      return item.StudyGroup == null;
    });
    var tempUsers = [],
      userObj = {};
    if (current_target_users !== null || current_target_users.length !== 0) {
      current_target_users.map((item, index) => {
        const matchGroup = item.User.BelongsToStudyGroup.map((i) => {
          return i.StudyGroup.BookSeries.BookSeriesId;
        }).indexOf(bookserires.BookSeriesId);
        if (matchGroup != -1) {
          userObj = {
            ...item,
            UserGroup: item.User.BelongsToStudyGroup[matchGroup],
          };
          delete userObj.BelongsToStudyGroup;
        } else {
          userObj = { ...item };
        }
        tempUsers.push(userObj);
      });
    }

    current_target_users = tempUsers;

    if (role == "T") {
      //For teacher
      return (
        <>
          <span className="notification-item-body">
            {current_target_classes.map((obj, i) => {
              if (i == 0) {
                return (
                  <Trans key={i} i18nKey="ClassTitle" count={1}>
                    {{
                      class:
                        obj.StudyGroup.StudyGroupName[
                          lang == obj.StudyGroup.StudyGroupName[0].Lang ? 0 : 1
                        ].StudyGroupName,
                    }}
                  </Trans>
                );
              } else {
                return (
                  <React.Fragment key={i}>
                    {", "}
                    <Trans i18nKey="ClassTitle" count={1} key={i}>
                      {{
                        class:
                          obj.StudyGroup.StudyGroupName[
                            lang == obj.StudyGroup.StudyGroupName[0].Lang
                              ? 0
                              : 1
                          ].StudyGroupName,
                      }}
                    </Trans>
                  </React.Fragment>
                );
              }
            })}
            {current_target_classes.length != 0 &&
              current_target_users.length != 0 &&
              ", "}
            {current_target_users.length > 0 &&
              current_target_users.map((u, i) => {
                if (i == 0)
                  return (
                    u.UserGroup.StudyGroup.StudyGroupName[
                      lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang
                        ? 0
                        : 1
                    ].StudyGroupName +
                    "-" +
                    u.UserGroup.ClassNumber +
                    " " +
                    u.User.UserDisplayName[
                      u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                    ].UserName
                  );
                else
                  return (
                    ", " +
                    u.UserGroup.StudyGroup.StudyGroupName[
                      lang == u.UserGroup.StudyGroup.StudyGroupName[0].Lang
                        ? 0
                        : 1
                    ].StudyGroupName +
                    "-" +
                    u.UserGroup.ClassNumber +
                    " " +
                    u.User.UserDisplayName[
                      u.User.UserDisplayName[0].Lang == lang ? 0 : 1
                    ].UserName
                  );
              })}
          </span>
        </>
      );
    }
  };

  const renderTheViews = (obj) => {
    var userIds = [];

    obj.AssignmentTargets.map((record, index) => {
      if (!record.UserId) {
        var _userids = record.StudyGroup.StudyGroupUser.map((i) => {
          return i.UserId;
        });
        userIds = [...userIds, ..._userids];
      } else {
        userIds.push(record.UserId);
      }
    });
    userIds = [...new Set(userIds)];
    if (userIds.indexOf(userData.UserId) != -1) {
      //it will show the views for teacher only, so no need to check is student or not
      userIds.splice(userIds.indexOf(userData.UserId), 1);
    }

    return obj.AssignmentTargetStatus.length + " / " + userIds.length;
  };
  const SortByData = (type) => {
    console.log(userRelatedList);
    if (orderBy === "ASSIGNMENT_LIST") {
      const sorted = userRelatedList.sort((a, b) => {
        return type === "DESC"
          ? ("" + a.AssignmentName).localeCompare(b.AssignmentName)
          : ("" + b.AssignmentName).localeCompare(a.AssignmentName);
      });
      dispatch({
        type: "SET_RESCENTERASSIGNEDRELATED",
        resCenterAssignedRelated: sorted,
      });
      setOrder(type === "DESC" ? "DESC" : "ASC");
    }
    if (orderBy === "DATE") {
      const sorted = userRelatedList.sort((a, b) => {
        return type === "DESC"
          ? ("" + a.CreateDate).localeCompare(b.CreateDate)
          : ("" + b.CreateDate).localeCompare(a.CreateDate);
      });
      dispatch({
        type: "SET_RESCENTERASSIGNEDRELATED",
        resCenterAssignedRelated: sorted,
      });
      setOrder(type === "DESC" ? "DESC" : "ASC");
    }
    if (orderBy === "DUE_DATE") {
      const sorted = userRelatedList.sort((a, b) => {
        return type === "DESC"
          ? ("" + a.DueDate).localeCompare(b.DueDate)
          : ("" + b.DueDate).localeCompare(a.DueDate);
      });
      dispatch({
        type: "SET_RESCENTERASSIGNEDRELATED",
        resCenterAssignedRelated: sorted,
      });
      setOrder(type === "DESC" ? "DESC" : "ASC");
    }
  };

  return loading == true ? (
    <Loading type={"inpage"} />
  ) : (
    <React.Fragment>
      {!isMobileOnly ? (
        <table className="table">
          <thead>
            <tr>
              <th
                className="related-assignment"
                onClick={() => {
                  setOrderBy("ASSIGNMENT_LIST");
                  if (orderBy == "ASSIGNMENT_LIST") {
                    setOrder(order == "DESC" ? "ASC" : "DESC");
                    SortByData(order == "DESC" ? "ASC" : "DESC");
                  }
                }}
              >
                {t("Assignment") + " "}
                {orderBy === "ASSIGNMENT_LIST" ? (
                  order === "DESC" ? (
                    <i className="icon mdi mdi-long-arrow-down"></i>
                  ) : (
                    <i className="icon mdi mdi-long-arrow-up"></i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th
                className="related-assignment-date"
                onClick={() => {
                  setOrderBy("DATE");
                  if (orderBy == "DATE") {
                    setOrder(order == "DESC" ? "ASC" : "DESC");
                    SortByData(order == "DESC" ? "ASC" : "DESC");
                  }
                }}
              >
                {t("Date") + " "}
                {orderBy === "DATE" ? (
                  order === "DESC" ? (
                    <i className="icon mdi mdi-long-arrow-down"></i>
                  ) : (
                    <i className="icon mdi mdi-long-arrow-up"></i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th className="related-assignment-to"> {t("To")}</th>
              <th
                className="related-assignment-due-date"
                onClick={() => {
                  setOrderBy("DUE_DATE");
                  if (orderBy == "DUE_DATE") {
                    setOrder(order == "DESC" ? "ASC" : "DESC");
                    SortByData(order == "DESC" ? "ASC" : "DESC");
                  }
                }}
              >
                {t("Due Date") + " "}
                {orderBy === "DUE_DATE" ? (
                  order === "DESC" ? (
                    <i className="icon mdi mdi-long-arrow-down"></i>
                  ) : (
                    <i className="icon mdi mdi-long-arrow-up"></i>
                  )
                ) : (
                  ""
                )}
              </th>
              <th className="related-assignment-read"> {t("Read")}</th>
            </tr>
          </thead>
          <tbody>
            {listloading && (
              <tr className="loading_tr">
                <td colSpan="6">
                  <Loading type={"inpage"} />
                </td>
              </tr>
            )}
            {!listloading &&
              userRelatedList.map((info, index) => {
                return (
                  <tr
                    key={index}
                    onClick={(e) => {
                      if (
                        e.target.className == "assign_views_number_area" ||
                        e.target.className == "assign_views_number" ||
                        e.target.className == "people_icn" ||
                        e.target.className == "views_number_sub" ||
                        e.target.className == "people_icn_img"
                      ) {
                        return;
                      } else {
                        setAssignmentDetailsData({
                          isOpen: true,
                          resId: info.AssignmentTargets[0].ResourceAssignId,
                          assignmentName: info.AssignmentName,
                        });

                        gaTrack(
                          "send",
                          "event",
                          resDataForGa.comeFrom == "selfAssessment"
                            ? "Assessment assigned popup"
                            : "Resource assigned popup",
                          "Open related assignment",
                          (resDataForGa.comeFrom == "selfAssessment"
                            ? "Assessment:"
                            : "Resource:") +
                            resData.resName +
                            ", Resource ID:" +
                            resData.resIngestionId +
                            ", Book Series:" +
                            studyGroupData.BookSeries.BookSeriesName[0]
                              .BookSeriesName +
                            (resDataForGa.comeFrom == "selfAssessment"
                              ? ""
                              : ", View:" + resDataForGa.listBy) +
                            ", Resource Level:" +
                            resDataForGa.resLevel +
                            ", Resource Page:" +
                            resDataForGa.currentPage +
                            ", Class Level:" +
                            studyGroupData.Level.LevelName[0].LevelName +
                            ", Role:" +
                            UserType
                        );
                      }
                    }}
                  >
                    <td className="related_assignment_name">
                      {info.AssignmentName}
                    </td>
                    <td>
                      {/* {info.CreateDate === null
                        ? "-"
                        : moment(info.CreateDate).format("YYYY-MM-DD")} */}
                      {info.LastUpdateDate === null
                        ? "-"
                        : moment(info.LastUpdateDate).format("YYYY-MM-DD")}
                    </td>
                    <td>{renderAssignee(info.AssignmentTargets)}</td>
                    <td>
                      {info.DueDate === null
                        ? "-"
                        : moment(info.DueDate).format("YYYY-MM-DD")}
                    </td>
                    <td className="assign_views_number_area">
                      <div
                        className="assign_views_number"
                        onClick={() => {
                          setMessageLightBoxOpen(true);

                          setassignmentData({
                            assignmentName: info.AssignmentName,
                            resourceAssignId:
                              info.AssignmentTargets[0].ResourceAssignId,
                            resourceId: resData.resId,
                          });
                          gaTrack(
                            "send",
                            "event",
                            resDataForGa.comeFrom == "selfAssessment"
                              ? "Assessment assigned popup"
                              : "Resource assigned popup",
                            "View read status",
                            (resDataForGa.comeFrom == "selfAssessment"
                              ? "Assessment:"
                              : "Resource:") +
                              resData.resName +
                              ", Resource ID:" +
                              resData.resIngestionId +
                              ", Book Series:" +
                              studyGroupData.BookSeries.BookSeriesName[0]
                                .BookSeriesName +
                              (resDataForGa.comeFrom == "selfAssessment"
                                ? ""
                                : ", View:" + resDataForGa.listBy) +
                              ", Resource Level:" +
                              resDataForGa.resLevel +
                              ", Resource Page:" +
                              resDataForGa.currentPage +
                              ", Class Level:" +
                              studyGroupData.Level.LevelName[0].LevelName +
                              ", Role:" +
                              UserType
                          );
                        }}
                      >
                        <span className="people_icn">
                          <img
                            src={peopleIcon}
                            className="people_icn_img"
                          ></img>
                        </span>
                        <span className="views_number_sub">
                          {renderTheViews(info)}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <React.Fragment>
          <div className="related_assignment_title_moble">
            {t("Assignment")}
          </div>
          <NotificationItem
            from="RelatedAssignment"
            notificationData={userRelatedList}
            handleAssignmentDetails={(obj) => {
              setAssignmentDetailsData({
                isOpen: obj.isOpen,
                resId: obj.resId,
                AssignmentName: obj.AssignmentName,
              });
            }}
            t={t}
          />
        </React.Fragment>
      )}

      <FullScreenModal
        type="viewAssignmentDetails"
        isOpen={assignmentDetailsData.isOpen}
        isModalOpen={isModalOpen}
        onClose={() => {
          setAssignmentDetailsData({
            isOpen: false,
            resId: 0,
            AssignmentName: "",
          });
        }}
        t={t}
        resourceAssignId={assignmentDetailsData.resId}
      />
      <MessageLightBox
        isOpen={messageLightBoxOpen}
        type={"AssignmentStudents"}
        assignmentName={assignmentData?.assignmentName}
        resourceAssignId={assignmentData?.resourceAssignId}
        resourceName={resData.resName}
        t={t}
        onClose={() => setMessageLightBoxOpen(!messageLightBoxOpen)}
      />
    </React.Fragment>
  );
};
