export const getMetadata = ({ key, itemApp }) => {
  var metadata;
  itemApp.getMetadata(function (obj) {
    metadata = obj;
  });
  if (typeof key === "undefined") {
    return metadata;
  } else {
    return metadata[key];
  }
};

export const getObjectKeys = ({ obj }) => {
  var keys = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      keys.push(key);
    }
  }
  return keys;
};
