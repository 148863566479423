import React, { useEffect, useState } from "react";
import environment from "../../../../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import Loading from "../../../../../../Loading/PEC/Loading";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import sorting_arrow_black from "../../../../../../../assets/img/pec/all-images/sorting-arrow.png";
import sorting_arrow_black_asc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc.png";
import sorting_arrow_black_desc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc.png";
import sorting_arrow_white from "../../../../../../../assets/img/pec/all-images/sorting-arrow-white.png";
import sorting_arrow_white_asc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-asc-white.png";
import sorting_arrow_white_desc from "../../../../../../../assets/img/pec/all-images/sorting-icon/Sort-sorting-desc-white.png";
import {isIOS} from "react-device-detect";
import useIosOpenPrizm from "../../../../../../../hooks/useIosOpenPrizm";
import useEncryption from "../../../../../../../hooks/useEncryption";

const StudentResultPagePecGetLearnosityTeacherReportPrizmQuery = graphql`
  query StudentResultPagePecGetLearnosityTeacherReportPrizmQuery(
    $ResourceId: [String]!
    $StudyGroupId: String!
    $SchoolYearId: String!
    $IncludeAssignmentRecord: Boolean
    $Credential: String
  ) {
    studyGroup(StudyGroupId: $StudyGroupId) {
      StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {
        ClassNumber
        User {
          UserId
          UserDisplayName {
            Lang
            UserName
          }
        }
      }
    }
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        TagData
        SelfLearnFirstAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          LearnositySessionResult(Credential: $Credential)
          AttemptSessionId
          AttemptDate
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              UserId
              ClassNumber
            }
          }
        }
        SelfLearnLastAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          LearnositySessionResult(Credential: $Credential)
          AttemptSessionId
          AttemptDate
          Score
          MaxScore
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              UserId
              ClassNumber
            }
          }
        }
        SelfLearnAttemptHistoryByStudyGroup(
          StudyGroupId: $StudyGroupId
          SchoolYearId: $SchoolYearId
          IncludeAssignmentRecord: $IncludeAssignmentRecord
        ) {
          LearnositySessionRawResult
          LearnositySessionResult(Credential: $Credential)
          AttemptSessionId
          AttemptDate
          Score
          MaxScore
          UserDetail {
            UserId
            UserDisplayName {
              Lang
              UserName
            }
            BelongsToStudyGroup(
              StudyGroupId: $StudyGroupId
              SchoolYearId: $SchoolYearId
            ) {
              UserId
              ClassNumber
            }
          }
        }
      }
    }
  }
`;

const studentResultPagePec = (props) => {
  // const _selectedStudyGroupId = props.selectedStudyGroupId;
  const {
    isInappBrowser,
    t,
    assignmentList,
    standalone,
    resourceId,
    assignmentData,
    prizmBookId,
    selectedStudyGroupName,
  } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;

  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;

  const lang = useSelector((state) => state.SystemReducer.lang);
  const [sortColumn, setSortColumn] = useState('')
  const [loading, setloading] = useState(true);
  const [teacherReport, setteacherReport] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [reportStatistics, setreportStatistics] = useState({});
  const [initReady, setinitReady] = useState(false);
  const [tagsData, settagsData] = useState(null);
  const [questionCount, setquestionCount] = useState(0);
  const [exerciseNames, setexerciseNames] = useState([]);
  const [classAvgPrizm, setclassAvgPrizm] = useState("");
  const [classAvgProgress, setclassAvgProgress] = useState("");
  const [exerciseOverallFinalPrizm, setexerciseOverallFinalPrizm] = useState(
    []
  );
  const {iosOpenPrizmSubmission} = useIosOpenPrizm();

  const [selectedStudyGroupIdX, setselectedStudyGroupIdX] = useState(
    props.selectedStudyGroupId
  );
  const [selectedReportType, setselectedReportType] = useState(
    props.selectedReportType
  ); //SelfLearnFirstAttemptHistoryByStudyGroup /  SelfLearnLastAttemptHistoryByStudyGroup

  const [selectedAssessType, setselectedAssessType] = useState(
    props.selectedAssessType
  );

  const entry = useSelector(
    (state) => state.EntryOfAssessmentReducer.entryPoints
  );
  console.log(selectedAssessType, selectedReportType);

  useEffect(() => {
    //alert("cominh");
    setloading(true);
    // alert(selectedStudyGroupIdX);
    if (selectedStudyGroupIdX) {
      getLearnosityTeacherReport();
    }
  }, [selectedStudyGroupIdX]);

  useEffect(() => {
    setselectedStudyGroupIdX(props.selectedStudyGroupId);
  }, [props.selectedStudyGroupId]);

  const doOpenSubmission = (submitURL, studName) => {
    console.log("HERE IS SUBMIT: ", submitURL)
    if (isIOS) {
      iosOpenPrizmSubmission(submitURL, studName);
      return;
    }
    var sarg = props.match.params.id;

    props.history.push({
      pathname:
        "/a/studygroup/" +
        sarg +
        "/assessment/" +
        "prizm&" +
        prizmBookId +
        "/" +
        resourceId +
        "/submission",
      state: {
        backURL: "Result",
        previewURL: submitURL,
        bookVal: prizmBookId,
        studName: studName,
      },
    });
  };

  const getLearnosityTeacherReport = () => {
    // alert("working");
    var value;
    console.log("HERE IT IS")
    if (!isInappBrowser) {
      value = {
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        StudyGroupId: selectedStudyGroupIdX,
        ResourceId: [resourceId],
        IncludeAssignmentRecord:
          selectedAssessType === "All Result" ? true : false,
        Lang: lang == "en-US" ? "EN" : "TC",
        Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
      };
    } else {
      value = {
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        StudyGroupId: selectedStudyGroupIdX,
        ResourceId: [
          standalone == true
            ? props.match.params.resId.split("&")[0]
            : props.inappData.resourceId,
        ],
        IncludeAssignmentRecord:
          selectedAssessType === "All Result" ? true : false,
        Lang: lang == "en-US" ? "EN" : "TC",
        Credential: window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken")
      };
    }
    fetchQuery(
      environment,
      StudentResultPagePecGetLearnosityTeacherReportPrizmQuery,
      value
    ).then((data) => {
      console.log(data);

      const {
        studyGroup: { StudyGroupUser = [{}] },
        resourceSearch: { Resources = [{}] },
      } = data || {};
      console.log(Resources, StudyGroupUser);
      const TagData = JSON.parse(Resources[0].TagData);
      //start handle the list of users in studygroup
      /*
        {
          UserId
          UserDisplayName
          AttemptHistory {  //First/Last
            session_id
            QuestionsData [
              q1 {
                max_score
                score
                ...
              }
            ]
          }
        }
      */
      var argRec = [];
      if (!Resources) {
        argRec = Resources;
      }

      var StudyGroupUserTemp = [...StudyGroupUser]
      StudyGroupUserTemp.sort(function (a, b) {
        return a.ClassNumber - b.ClassNumber;
      });
      handleTeacherReortData(Resources, StudyGroupUserTemp, TagData);
      console.log(Resources, StudyGroupUser);
    });
  };

  const rawTimeTaken = (attemptObj) => {
    var str = attemptObj.LearnositySessionRawResult
      ? attemptObj.LearnositySessionRawResult
      : "";
    if (str != "") {
      var final = str.replace(/\\/g, "");
      var json_data = JSON.parse(final);
      var value = "";
      console.log(json_data);
      // if(json_data.length > 0){
      //   var scoreVal = json_data.filter((data) => {
      //     return data.contentType =="LEARNETIC";
      //   });
      //   if(scoreVal.length > 0)
      //   value = scoreVal[0].totalSecondsTracked;
      // }
      value = json_data.totalSecondsTracked;
      if (value != "") {
        return parseInt(value, 10);
      } else {
        return 0;
      }
    }
    return 0;
  };

  const getAverageTime = (array) => {
    var times = [3600, 60, 1],
      parts = array.map((s) =>
        s.split(":").reduce((s, v, i) => s + times[i] * v, 0)
      ),
      avg = Math.round(parts.reduce((a, b) => a + b, 0) / parts.length);

    return times
      .map((t) => [Math.floor(avg / t), (avg %= t)][0])
      .map((v) => v.toString().padStart(2, 0))
      .join(":");
  };

  const convertHMS = (attemptObj) => {
    var str = attemptObj.LearnositySessionRawResult
      ? attemptObj.LearnositySessionRawResult
      : "";
    if (str != "") {
      var final = str.replace(/\\/g, "");
      var json_data = JSON.parse(final);
      var value = "";
      console.log(json_data);
      // if(json_data.length > 0){
      //   var scoreVal = json_data.filter((data) => {
      //     return data.contentType =="LEARNETIC";
      //   });
      //   if(scoreVal.length > 0)
      //   value = scoreVal[0].totalSecondsTracked;
      // }
      value = json_data.totalSecondsTracked;
      if (value != "") {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const compareNames = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  const getExerciseNames = (exerciseDet) => {
    // var final = exerciseDet.replace(/\\/g, "");
    // console.log("sss",final);
    var exerciseInfo = [];
    exerciseDet.map((det, index) => {
      var obj = { name: det.pageName };
      exerciseInfo.push(obj);
    });
    exerciseInfo.sort(compareNames);
    return exerciseInfo;
  };

  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  const handleTeacherReortData = (dataLists, studentLists, TagData) => {
    let teacherReportTableData = [],
      attemptsData = [],
      newArray = [],
      questionCount = 0;
    setexerciseNames([]);
    setexerciseOverallFinalPrizm([]);
    setclassAvgProgress("");
    setclassAvgPrizm("");
    var exerciseNamesT = [];

    // if(TagDataArg){
    //   TagData = TagDataArg;
    // }
    var overallExerciseAvg = [];
    var exerciseAttemptCount = [];
    var collectExercises = [];
    var uniqueExercise = [];
    if (dataLists.length != 0 && dataLists[0][selectedReportType].length != 0) {
      dataLists[0][selectedReportType].map((attm, index) => {
        var json_data = JSON.parse(attm.LearnositySessionRawResult);
        if (json_data.pageScore) {
          json_data.pageScore.map((det, index) => {
            collectExercises.push(det.pageName);
          });
          //exerciseNamesT = getExerciseNames(json_data.pageScore);
        }
      });
    }
    if (collectExercises.length > 0) {
      uniqueExercise = collectExercises.filter(onlyUnique);
    }
    uniqueExercise.map((det, index) => {
      var obj = { name: det };

      exerciseNamesT.push(obj);
    });
    if (exerciseNamesT.length > 0) {
      exerciseNamesT.sort(compareNames);
    }

    setexerciseNames(exerciseNamesT);

    console.log("AllExercies", collectExercises);

    if (dataLists.length != 0 && dataLists[0][selectedReportType].length != 0) {
      dataLists[0][selectedReportType].map((attm, index) => {
        var json_data = JSON.parse(attm.LearnositySessionRawResult);
        console.log("crack", json_data);
        var studentAttemptedPages = 0;
        var studentExerciseScore = [];
        if (json_data.pageScore) {
          // if (exerciseNamesT.length == 0)
          //   exerciseNamesT = getExerciseNames(json_data.pageScore);
          studentAttemptedPages = 1;
          json_data.pageScore.map((det, index) => {
            console.log("love", det.score.scaled * 100);
            if (!overallExerciseAvg[det.pageName]) {
              overallExerciseAvg[det.pageName] = 0;
            }

            studentExerciseScore[det.pageName] = det.score.scaled * 100;
            overallExerciseAvg[det.pageName] =
              overallExerciseAvg[det.pageName] + det.score.scaled * 100;
          });
        }
        // added new logic for zero value due to sorting issue starts here
        if (exerciseNamesT.length > 0) {
          exerciseNamesT.map((info, j) => {
            // alert(j);
            if (!studentExerciseScore[info.name]) {
              studentExerciseScore[info.name] = 0;
            }
          });
        }
        // added new logic for zero value due to sorting issue ends here
        //questionCount = json_data.items.length;
        let _temp;
        if (selectedAssessType === "Assignment") {
          _temp =
            attm.UserDetail.BelongsToStudyGroup[0].StudyGroup.StudyGroupName;
        }
        var timeTaken = convertHMS(attm);
        var rawTime = rawTimeTaken(attm);
        attemptsData.push({
          StudyGroupName:
            selectedAssessType === "Assignment"
              ? _temp[_temp[0].Lang === lang ? 0 : 1].StudyGroupName
              : "",
          ClassNumber: attm.UserDetail.BelongsToStudyGroup[0].ClassNumber,
          UserId: attm.UserDetail.UserId,
          user_id: attm.UserDetail.UserId,
          UserDisplayName: attm.UserDetail.UserDisplayName,
          AttemptDate: attm.AttemptDate,
          submitURL: attm.LearnositySessionResult,
          AttemptHistory: {
            attemptedCount: studentAttemptedPages,
            session_id: attm.AttemptSessionId,
            score: attm.Score,
            max_score: attm.MaxScore,
            time_taken: timeTaken,
            raw_time: rawTime,
            exerciseScore: studentExerciseScore,
          },
        });
      });
    }
    console.log(attemptsData);
    console.log(studentLists, teacherReportTableData);

    // if(TagDataArg){
    //   TagData = TagDataArg;
    // }
    // if (dataLists.length != 0 && dataLists[0][selectedReportType].length != 0) {
    //   dataLists[0][selectedReportType].map((attm, index) => {
    //     var json_data = JSON.parse(attm.LearnositySessionRawResult);
    //     console.log(json_data);
    //     //questionCount = json_data.items.length;
    //     let _temp;
    //     if (selectedAssessType === "Assignment") {
    //       _temp =
    //         attm.UserDetail.BelongsToStudyGroup[0].StudyGroup.StudyGroupName;
    //     }
    //     var timeTaken = convertHMS(attm);
    //     attemptsData.push({
    //       StudyGroupName:
    //         selectedAssessType === "Assignment"
    //           ? _temp[_temp[0].Lang === lang ? 0 : 1].StudyGroupName
    //           : "",
    //       ClassNumber: attm.UserDetail.BelongsToStudyGroup[0].ClassNumber,
    //       UserId: attm.UserDetail.UserId,
    //       user_id: attm.UserDetail.UserId,
    //       UserDisplayName: attm.UserDetail.UserDisplayName,
    //       AttemptDate: attm.AttemptDate,
    //       submitURL: "",
    //       AttemptHistory: {
    //         session_id: null,
    //         score: 0,
    //         max_score: 0,
    //         time_taken: 0,
    //         raw_time: 0,
    //         exerciseScore: [],
    //       },
    //     });
    //   });
    // }
    console.log(attemptsData);
    console.log(studentLists, teacherReportTableData);

    if (studentLists.length > 0) {
      studentLists.map((obj, index) => {
        var matchId = attemptsData
          .map((i) => {
            return i.UserId;
          })
          .indexOf(obj.User.UserId);
        if (matchId != -1) {
          teacherReportTableData.push(attemptsData[matchId]);
        } else {

          var notAttemptedExScore = []
          if (exerciseNamesT.length > 0) {
            exerciseNamesT.map((info, j) => {
              // alert(j);
              
              notAttemptedExScore[info.name] = "";
              
            });
          }
          teacherReportTableData.push({
            StudyGroupName:
              selectedAssessType === "Assignment" ? obj.StudyGroupName : "",
            ClassNumber: obj.ClassNumber,
            UserId: obj.User.UserId,
            UserDisplayName: obj.User.UserDisplayName,
            user_id: null,
            AttemptDate: null,
            submitURL: "",
            AttemptHistory: {
              attemptedCount: 0,
              session_id: null,
              score: "",
              max_score: 0,
              time_taken: 0,
              exerciseScore: notAttemptedExScore,
            },
          });
        }
      });
    }

    console.log(teacherReportTableData, "test");
    if (dataLists.length != 0) {
      if (dataLists[0][selectedReportType].length != 0) {
        //manage users data on charts [Start]
        var charts = {};
        // var userKey = Object.keys(data[e].users);
        var StudentsScore = [];
        var timeData = [];
        let maxScore = 0;
        var totalTime = 0;
        teacherReportTableData.map((userObj, i) => {
          let totalScore = 0;

          if (userObj.AttemptHistory.session_id == null) return;
          if (maxScore == 0) {
            maxScore = userObj.AttemptHistory.max_score;
            totalScore = userObj.AttemptHistory.score;
          } else {
            totalScore = userObj.AttemptHistory.score;
          }
          if (userObj.AttemptHistory.time_taken != "") {
            timeData.push(userObj.AttemptHistory.time_taken);
          }

          StudentsScore.push({
            StudentName: userObj.user_id,
            totalScore: totalScore,
            totalTime: userObj.AttemptHistory.raw_time,
          });
        });
        console.log(StudentsScore, "StudentsScore");
        console.log("chill", overallExerciseAvg);
        console.log("names", exerciseNamesT);
        var exerciseOverallFinalT = [];
        if (exerciseNamesT.length > 0) {
          exerciseNamesT.map((info, j) => {
            // alert(j);
            console.log("ind", overallExerciseAvg[info.name]);
            exerciseOverallFinalT[info.name] = Math.round(
              overallExerciseAvg[info.name] / StudentsScore.length
            );
          });
          setexerciseOverallFinalPrizm(exerciseOverallFinalT);
          console.log("chill", exerciseOverallFinalT);
        }

        // manage no.submmited
        const studScorePercents = StudentsScore.map((obj1) => {
          return parseFloat(((obj1.totalScore / maxScore) * 100).toFixed(0));
        });
        var chartsRage = {
          zeroToTwenty: 0,
          twentyToForty: 0,
          fortyToSixty: 0,
          sixtyToEighty: 0,
          eightyTohundred: 0,
        };

        for (let studScorePercent of studScorePercents) {
          // console.log(studScorePercent);
          if (studScorePercent <= 20) {
            chartsRage.zeroToTwenty++;
          }
          if (studScorePercent > 20 && studScorePercent <= 40) {
            chartsRage.twentyToForty++;
          }

          if (studScorePercent > 40 && studScorePercent <= 60) {
            chartsRage.fortyToSixty++;
          }
          if (studScorePercent > 60 && studScorePercent <= 80) {
            chartsRage.sixtyToEighty++;
          }
          if (studScorePercent > 80 && studScorePercent <= 100) {
            chartsRage.eightyTohundred++;
          }
        }

        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = StudentsScore.length - 1; i >= 0; i--) {
          tmp = StudentsScore[i].totalScore;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        const highestFinal = (highest / maxScore) * 100;
        const lowestFinal = (lowest / maxScore) * 100;

        var aveScore = 0,
          finAvg = 0,
          temp = 0;

        StudentsScore.map((obj, i) => {
          // console.log(obj.totalScore);

          temp += parseFloat(obj.totalScore);
        });
        aveScore = Math.round((temp / StudentsScore.length / maxScore) * 100);
        let finscore =
          (parseFloat(temp) / StudentsScore.length / parseFloat(maxScore)) *
          100;
        //score = score / data.length;
        finAvg = Math.round(finscore);
        setclassAvgPrizm(Math.round(finAvg));
        setclassAvgProgress(
          getScoreAndAverageClass(Math.round(finAvg), "score")
        );

        // finAvg = parseFloat(((temp / StudentsScore.length) * 100)).toFixed(0);
        console.log("ddd", aveScore, temp, StudentsScore.length, maxScore);
        console.log(
          "hello",
          totalTime,
          highestFinal,
          lowestFinal,
          aveScore,
          getAverageTime(timeData)
        );

        var statisticsData = {
          highVal: Math.round(highestFinal),
          lowVal: Math.round(lowestFinal),
          avgVal: finAvg,
          avgTime: getAverageTime(timeData),
        };
        setreportStatistics(statisticsData);
        charts = {
          DoughnutChartData: {
            aveScore: aveScore.toFixed(0),
            highestFinal: highestFinal.toFixed(0),
            lowestFinal: lowestFinal.toFixed(0),
          },
          BarChartData: {
            chartsRage: chartsRage,
          },
          noOfSubmit: StudentsScore.length,
        };
        console.log(
          charts,
          "manage users data on charts",
          teacherReportTableData
        );
        //manage users data on charts [End]
        const _tempInfo = {
          dataLists: dataLists,
          TagData: TagData,
        };
        //Calculating Avg for Exercise
        // teacherReportTableData.map((userObj, i) => {
        //   userObj.AttemptHistory.exerciseScore
        // });

        props.handleReportData(newArray, charts, TagData, _tempInfo);
      } else {
        var statisticsData = {
          highVal: "",
          lowVal: "",
          avgVal: "",
          avgTime: "",
        };
        setreportStatistics(statisticsData);

        props.handleReportData(
          [],
          {
            DoughnutChartData: {
              aveScore: 0,
              highestFinal: 0,
              lowestFinal: 0,
            },
            BarChartData: {
              chartsRage: {
                zeroToTwenty: 0,
                twentyToForty: 0,
                fortyToSixty: 0,
                sixtyToEighty: 0,
                eightyTohundred: 0,
              },
            },
          },
          null,
          []
        );
      }
    } else {
      props.handleReportData(
        [],
        {
          DoughnutChartData: {
            aveScore: 0,
            highestFinal: 0,
            lowestFinal: 0,
          },
          BarChartData: {
            chartsRage: {
              zeroToTwenty: 0,
              twentyToForty: 0,
              fortyToSixty: 0,
              sixtyToEighty: 0,
              eightyTohundred: 0,
            },
          },
        },
        null,
        []
      );
    }

    console.log("Result", teacherReportTableData);
    setteacherReport(teacherReportTableData);
    //setquestionCount(questionCount);
    settagsData(TagData); //  For tag
    setloading(false);
  };

  const renderQuestionTitle = () => {
    var titles = [];
    for (let i = 0; i < questionCount; i++) {
      titles.push(
        <th key={i} className="lrn-item-header">
          Q{i + 1}
        </th>
      );
    }
    return titles;
  };

  const onClickStudentTitle = (id, session_id, attemptDate) => {
    var gqString = "Click student performance";

    var userType = "";
    // if (isInappBrowser) {
    //   userType = userType == "teacher" ? "T" : "S";
    // } else {
    //   userType = JSON.parse(userData).UserType;
    // }
    if (!standalone) {
      // gaTrack(
      //   "send",
      //   "event",
      //   "Assessment",
      //   gqString,
      //   "Assessment:" +
      //     resourceData[0].ResourceName +
      //     ", Resource ID:" +
      //     resourceIngestionIdGa +
      //     ", Level:" +
      //     studyGroupData.Level.LevelName[0].LevelName +
      //     ", Book Series:" +
      //     studyGroupData.BookSeries.BookSeriesName[0].BookSeriesName +
      //     ", Book:" +
      //     studyGroupData.BookSeries.Books[0].BookName[0].BookName +
      //     ", Chapter:" +
      //     studyGroupData.BookSeries.Books[0].Units[0].UnitName[0].UnitName +
      //     ", Role:" +
      //     userType
      // );
    }

    props.history.push(props.location.pathname + "/" + id, {
      session_id: session_id,
      attemptDate: attemptDate,
      userId: id,
      // questions: data[e].users[id].items,
    });
  };
  console.log(props);
  console.log(teacherReport, "teacherReport");

  const getScoreAndAverageClass = (scoreVal, scoreType) => {
    if (scoreVal === "") {
      return "";
    } else {
      if (scoreType == "score") {
        if (scoreVal <= 20) {
          return "student-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "student-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "student-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "student-progressbar-high";
        } else if (scoreVal > 80) {
          return "student-progressbar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "average") {
        if (scoreVal <= 20) {
          return "progress-linebar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "progress-linebar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "progress-linebar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "progress-linebar-high";
        } else if (scoreVal > 80) {
          return "progress-linebar-highest";
        } else {
          return "";
        }
      }
    }
  };



const compareOnKeyDesc = (key) => {
  return function(a, b) {

    
    if (a.AttemptHistory.exerciseScore[key] === b.AttemptHistory.exerciseScore[key]) {
      return 0;
  }

  // nulls sort after anything else
  if (a.AttemptHistory.exerciseScore[key] === null || a.AttemptHistory.exerciseScore[key] === "") {
      return 1;
  }
  if (b.AttemptHistory.exerciseScore[key] === null || b.AttemptHistory.exerciseScore[key] === "") {
      return -1;
  }

   
  
  return parseInt(a.AttemptHistory.exerciseScore[key]) < parseInt(b.AttemptHistory.exerciseScore[key]) ? 1 : -1;
    };
  }
const compareOnKey = (key) => {
  return function(a, b) {


    if (a.AttemptHistory.exerciseScore[key] === b.AttemptHistory.exerciseScore[key]) {
      return 0;
  }

 
  // nulls sort after anything else
  if (a.AttemptHistory.exerciseScore[key] === null || a.AttemptHistory.exerciseScore[key] === "") {
      return 1;
  }
  if (b.AttemptHistory.exerciseScore[key] === null || b.AttemptHistory.exerciseScore[key] === "") {
      return -1;
  }

   
  
  return parseInt(a.AttemptHistory.exerciseScore[key]) < parseInt(b.AttemptHistory.exerciseScore[key]) ? -1 : 1;
    };
  }
 

  const alphabetically = (a, b) => {
    // equal items sort equally
    if (a.AttemptHistory.score === b.AttemptHistory.score) {
        return 0;
    }

    // nulls sort after anything else
    if (a.AttemptHistory.score === null || a.AttemptHistory.score === "") {
        return 1;
    }
    if (b.AttemptHistory.score === null || b.AttemptHistory.score === "") {
        return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    
        return parseInt(a.AttemptHistory.score) < parseInt(b.AttemptHistory.score) ? -1 : 1;
  

    // if descending, highest sorts first
    //return a.AttemptHistory.score < b.AttemptHistory.score ? 1 : -1;
}

const alphabeticallyDesc = (a, b) => {
  // equal items sort equally
  if (a.AttemptHistory.score === b.AttemptHistory.score) {
      return 0;
  }

  // nulls sort after anything else
  if (a.AttemptHistory.score === null || a.AttemptHistory.score === "") {
      return 1;
  }
  if (b.AttemptHistory.score === null || b.AttemptHistory.score === "") {
      return -1;
  }

   
  // if descending, highest sorts first
  return parseInt(a.AttemptHistory.score) < parseInt(b.AttemptHistory.score) ? 1 : -1;
}

  // const exerciseNames = [
  //   { name: "Ex. 1: School activities" },
  //   { name: "Ex. 2: School events and activities" },
  //   { name: "Ex. 3: School events and activities" },
  //   { name: "Ex. 4: School events and activities" },
  //   { name: "Ex. 5: School events and activities" },
  // ];

  var singleColumnClass = "";
  if (exerciseNames.length <= 1) {
    singleColumnClass = "single-column-border-radius";
  }

  // calculate avarage function
  const calculateAvg = (val1, val2) => {
    return Math.round((val1 / val2) *100) ? Math.round((val1 / val2) *100) : 0
  } 

  return (
    <>
      {loading ? (
        <div
          className="loading-box"
          style={{
            display: `${!initReady ? "block" : "none"}`,
            position: `${!initReady ? "relative" : "absolute"}`,
          }}
        >
          <Loading type="normal" />
        </div>
      ) : (
        <div className="main__section-student-unit-grid result-page">
          {/* students details table */}
          <table className="main__section-student-unit-grid-details">
            <thead className="result-page-table-head-height">
              <tr className="main__section-student-unit-grid-details-bottom-row">
                <th className="main__section-student-unit-grid-details-bottomheading-primary"
                onClick={()=>{
                  setSortColumn('No')
                  const sortedData = teacherReport.sort((a,b) => sortOrder=='asc' ? parseInt(b.ClassNumber) - parseInt(a.ClassNumber) : parseInt(a.ClassNumber) - parseInt(b.ClassNumber));
                  setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                  setteacherReport(sortedData)
                  }}>
                  {t("No")}
                  <img
                    src={sortColumn != 'No' ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_asc : sorting_arrow_black_desc}
                    alt="sorting"
                    className="table-sorting-arrow"
                  />
                </th>
                <th className="main__section-student-unit-grid-details-bottomheading-primary"
                onClick={()=>{
                  setSortColumn('Student Name')
                  const sortedData = teacherReport.sort(function(a, b){                   
                  if(sortOrder=='asc'){
                    if(a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName < b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return -1; }
                    if(a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName > b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return 1; }
                    return 0;
                  } else {
                    if(a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName < b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return 1; }
                    if(a.UserDisplayName[lang == a.UserDisplayName[0].Lang ? 0 : 1].UserName > b.UserDisplayName[lang == b.UserDisplayName[0].Lang ? 0 : 1].UserName) { return -1; }
                    return 0;
                  }
                  })
                  setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                  setteacherReport(sortedData)
                }}>
                  {t("Student Name")}
                  <img
                    src={sortColumn != 'Student Name' ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_desc : sorting_arrow_black_asc}
                    alt="sorting"
                    className="table-sorting-arrow"
                  />
                </th>
                <th className="main__section-student-unit-grid-details-bottomheading-primary">
                  {t("Submission")}
                </th>
              </tr>
            </thead>
            <tr className="main__section-student-unit-grid-details-up-row">
              <td>-</td>
              <td>{`${selectedStudyGroupName.includes('Class') ? '' : t("Class")}  ${selectedStudyGroupName}`}</td>
              <td></td>
            </tr>

            {teacherReport.map((user, index) => {
              console.log('qwerty res', user)
              return (
                <tr className="main__section-student-unit-grid-details-down-row">
                  <td className="main__section-student-unit-grid-details-down-row-number">
                    {/* {index + 1} */}
                    {user.ClassNumber}
                  </td>
                  <td className="main__section-student-unit-grid-details-down-row-name">
                    {
                      user.UserDisplayName[
                        lang == user.UserDisplayName[0].Lang ? 0 : 1
                      ].UserName
                    }
                  </td>

                  <td className="main__section-student-unit-grid-details-down-row-submission">
                    {user.submitURL != "" && (
                      <button
                        className="buttons btns submission-view-button"
                        onClick={() =>
                          doOpenSubmission(
                            user.submitURL,
                            user.UserDisplayName[
                              lang == user.UserDisplayName[0].Lang ? 0 : 1
                            ].UserName
                          )
                        }
                      >
                        {t("View")}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
          {/* overall table */}
          <table className="main__section-student-unit-grid-grade overall-table">
            <thead className="result-page-table-head-height">
              <tr className="main__section-student-unit-grid-grade-bottom-row">
                <th className="main__section-student-unit-grid-details-bottomheading"
                onClick={()=>{
                  setSortColumn('Overall')
                  console.log('value teacherReport',teacherReport)
                  var sortedDataOverAll;
                  if(sortOrder == "asc"){
                    sortedDataOverAll = teacherReport.sort(alphabeticallyDesc)
                  }else{
                     sortedDataOverAll = teacherReport.sort(alphabetically)
                  }
                  setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                  setteacherReport(sortedDataOverAll)
                  }}>
                  {t("Overall")}
                  <img
                    src={sortColumn != 'Overall' ? sorting_arrow_white : sortOrder=='asc' ? sorting_arrow_white_asc : sorting_arrow_white_desc}
                    alt="sorting"
                    className="table-sorting-arrow"
                  />
                </th>
              </tr>
            </thead>
            <tr className="main__section-student-unit-grid-grade-up-row">
              <td
                className={`column-data-with-progress-color progress-bar-center-position`}
              >
                {classAvgProgress != "" ? (
                  <div
                    className={`student-progressbar ${classAvgProgress}`}
                    role="progressbar"
                    aria-valuenow={classAvgPrizm}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": classAvgPrizm }}
                  ></div>
                ) : (
                  <div>
                    <span class="main__section-unit-grid-details-progress">
                    <i class="main__section-unit-grid-details-progress-bar">
                      -
                    </i>
                  </span>
                  </div>
                )}
              </td>
            </tr>
            {teacherReport.map((user) => {
              var percentageOverAll = "";
              if (user.AttemptHistory.session_id != null) {
                percentageOverAll = Math.round(
                  (user.AttemptHistory.score / user.AttemptHistory.max_score) *
                    100
                );
              }

              var studentProgressLevelClass = getScoreAndAverageClass(
                percentageOverAll,
                "score"
              );

              return (
                <tr className="main__section-student-unit-grid-grade-down-row">
                  <td
                    className={`column-data-with-progress progress-bar-center-position`}
                  >
                    {studentProgressLevelClass != "" ? (
                      <div
                        className={`student-progressbar ${studentProgressLevelClass}`}
                        role="progressbar"
                        aria-valuenow={percentageOverAll}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ "--value": percentageOverAll }}
                      ></div>
                    ) : (
                      <span class="main__section-unit-grid-details-progress">
                        <i class="main__section-unit-grid-details-progress-bar">
                          -
                        </i>
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
          {/* school activities table */}
          <div className="school_activities_table">
            <table className="main__section-student-unit-grid-details">
              <thead className="result-page-table-head-height">
                <tr className="main__section-student-unit-grid-details-bottom-row">
                  {exerciseNames.map((data) => (
                    <th className="main__section-student-unit-grid-details-bottomheading-primary result-page-th-heading result-table-heading"
                    onClick={()=>{
                      setSortColumn(data.name)
                     var  SortedExData = []
                      if(sortOrder=="asc"){
                        SortedExData = teacherReport.sort(compareOnKeyDesc(data.name))
                      }else {
                         SortedExData = teacherReport.sort(compareOnKey(data.name))
                      }
                      
                      setSortOrder(sortOrder=='asc' ? 'desc': 'asc')
                      setteacherReport(SortedExData)
                      }}>
                      <div className="student-result-container">
                        <div
                          className="student-result-container-left"
                          title={data.name}
                        >
                          {data.name}
                        </div>
                        <div className="student-result-container-right">
                          <img
                            className="student-result-container-img"
                            src={sortColumn != data.name ? sorting_arrow_black : sortOrder=='asc' ? sorting_arrow_black_asc: sorting_arrow_black_desc}
                            alt="icon"
                          />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tr className="main__section-student-unit-grid-view-up-row activities-table-data">
                {exerciseNames.map((data) => {
                  var exProgressLevelClass = "";
                  var exerciseScoreGot = "";
                  if (exerciseOverallFinalPrizm[data.name]) {
                    exProgressLevelClass = getScoreAndAverageClass(
                      Math.round(exerciseOverallFinalPrizm[data.name]),
                      "score"
                    );
                    exerciseScoreGot = Math.round(
                      exerciseOverallFinalPrizm[data.name]
                    );
                  }

                  return (
                    <td
                      className={`progress-bar-center-position single-progress-bar-center-position ${singleColumnClass}`}
                    >
                      {exProgressLevelClass != "" ? (
                        <div className="progressbar-contents">
                          <div
                            className={`student-progressbar ${exProgressLevelClass}`}
                            role="progressbar"
                            aria-valuenow={exerciseScoreGot}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ "--value": exerciseScoreGot }}
                          ></div>
                        </div>
                      ) : (
                        <div className="progressbar-contents">
                          <span class="main__section-unit-grid-details-progress">
                            <i class="main__section-unit-grid-details-progress-bar">
                              -
                            </i>
                          </span>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>

              {teacherReport.map((data) => {
                return (
                  <tr className="main__section-student-unit-grid-details-down-row">
                    {exerciseNames.map((exer) => {
                      var studentProgressLevelClass1 = "";
                      var scoreGot = "";
                      // console.log("crack", data.AttemptHistory);
                      // console.log(
                      //   "len",
                      //   data.AttemptHistory.exerciseScore.length
                      // );
                      if (data.AttemptHistory.exerciseScore[exer.name]) {
                        // console.log(
                        //   "hate",
                        //   data.AttemptHistory.exerciseScore[exer.name]
                        // );
                        studentProgressLevelClass1 = getScoreAndAverageClass(
                          Math.round(
                            data.AttemptHistory.exerciseScore[exer.name]
                          ),
                          "score"
                        );
                        scoreGot = Math.round(
                          data.AttemptHistory.exerciseScore[exer.name]
                        );
                      } else {
                        if (data.AttemptHistory.attemptedCount > 0) {
                          studentProgressLevelClass1 = getScoreAndAverageClass(
                            Math.round(0),
                            "score"
                          );
                          scoreGot = Math.round(0);
                        }
                      }

                      return (
                        <td
                          className={`main__section-student-unit-grid-details-down-row-number progress-bar-center-position ${singleColumnClass}`}
                        >
                          {studentProgressLevelClass1 != "" ? (
                            <div
                              className={`student-progressbar ${studentProgressLevelClass1}`}
                              role="progressbar"
                              aria-valuenow={scoreGot}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ "--value": scoreGot }}
                            ></div>
                          ) : (
                            <span class="main__section-unit-grid-details-progress">
                              <i class="main__section-unit-grid-details-progress-bar">
                                -
                              </i>
                            </span>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default studentResultPagePec;
