import * as types from "./types";
const initState = {
  layout: "b2b", 
};
const LayoutIdentifyReducer = (state = initState, action) => {
  console.log("[LayoutIdentifyReducer]", state, action);
  switch (action.type) {
    case types.LayoutIdentifyReducer.SET_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    default:
      return state;
  }
};

export default LayoutIdentifyReducer;
