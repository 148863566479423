import React, {useEffect, useRef, useState} from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../Config/config";
import LoginUsingIES from "./LoginUsingIES";
import LoginreCaptchaToken from "../../Services/api/GraphQL/Query/LoginreCaptchaToken";
import {
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import LoginNavbar from "./LoginNavBar.js";
import Loading from "../../Components/Loading/Loading";
import Footer from "../../Components/Footer/Footer";
import MessageLightBox from "../../Components/Feature/MessageLightBox";
import "./login.scss";
import LoginCard from "./LoginCard";
import useEncryption from "../../hooks/useEncryption";

const Login = (props) => {

  const { t } = props;
  const lang = window.localStorage.getItem("i18nextLng");
  const userData = useSelector((state) => state.UserReducer.user);
  const formRef = useRef(null);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSAMDown, setIsSAMDown] = useState(false);
  const [showUserNameInvalidMsg, setShowUserNameInvalidMsg] = useState(false);
  const [showPasswordInvalidMsg, setShowPasswordInvalidMsg] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const dispatch = useDispatch();
  const IES_LOGIN_PASSWORD_TYPE = "PASSWORD";
  const {encryptToLocal} = useEncryption();



  const getCurrentPlatform = () => {
    //Test Commit
    if (isMobileOnly && window.cordova) {
      return "MOBILE";
    }
    if (isTablet && window.cordova) {
      return "TABLET";
    }
    return "MOBILE";
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!validation()) {
      setIsLoginFailed(true)
      return;
    }
    setIsLoginFailed(false);
    if (window.cordova) {
      postMutation();
    } else {
      runCaptcha();
    }
  }
  const validation = () => {
    const userName = formRef.current.userName.value.trim();
    const password = formRef.current.passvalue.value.trim();
    let isValid = true;
    if (userName.length === 0) {
      setShowUserNameInvalidMsg(true);
      isValid = false;
    }
    if (password.length === 0) {
      setShowPasswordInvalidMsg(true);
      isValid = false;
    }
    return isValid;
  };


  const postMutation = () => {
    //test
    setIsLoading(true);
    setIsLoginFailed(false);

    const platform = getCurrentPlatform();
    console.log("[Login with platform]", platform);
    LoginUsingIES(
      {
        ...props,
        userName: formRef.current.userName.value.trim(),
        ssoToken: formRef.current.passvalue.value.trim(),
        platform: platform,
        passwordType: IES_LOGIN_PASSWORD_TYPE,
        SAMDown: setIsSAMDown,
        LoginFail: setIsLoginFailed,
        loadingStyle: setIsLoading,
        dispatch: dispatch,
      },
      "login"
    );
    // dispatch({
    //   type: "SET_USER_CREDENTIALS",
    //   userCredentials:{
    //     userName:formRef.current.userName.value.trim(),
    //     password:formRef.current.password.value.trim()
    //   }
    // })
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {},
    });
    window.localStorage.setItem("userCre", encryptToLocal(formRef.current.passvalue.value.trim()))
  };

  const onClickOpenLink = (link, type) => {
    switch (type) {
      case "CreateAccount":
        let action = localStorage.getItem("_action")
          ? JSON.parse(localStorage.getItem("_action"))
          : null;
        let registerURL = config.createAccountURL;
        if (action?.type == "QR_CODE_OPEN_RESOURCE") {
          console.log("[Create Account] action find", action);
          registerURL = config.createAccountURLQRCode.registerURL;
          let returnURL =
            config.createAccountURLQRCode.redirctURL +
            "action=QR_CODE&pin=" +
            action.data.pin +
            "resId=" +
            action.data.resourcesId;
          registerURL = registerURL.replace("{pin}", action.data.pin);
          registerURL = registerURL.replace(
            "{returnURL}",
            encodeURIComponent(returnURL)
          );
          console.log(registerURL);
        }
        console.log("[CreateAccount]  unknown type of action");
        if (window.cordova) {
          return window.open(registerURL, "_system");
        } else {
          return window.open(registerURL, "_blank");
        }

      default:
        if (window.cordova) {
          window.open(link, "_system");
        } else {
          window.open(link, "_blank");
        }
        break;
    }
  };

  const runCaptcha = () => {
    try {
      const callback = (captcha) => {
        var variables = {
          token: captcha,
        };
        LoginreCaptchaToken(variables).then((data) => {
          console.log(data);
          const { reCaptchaToken } = data || {};
          console.log(reCaptchaToken);
          if (reCaptchaToken == true) {
            postMutation();
          } else {
            // this.setState({ isOpen: true });
            setisOpen(true);
          }
        });
      };
      grecaptcha
        .execute("6Lf1mwEVAAAAAIpy6FiT8sYU2IHX8jk3hrHR_MkL", {
          action: "homepage",
        })
        .then(function (token) {
          const captcha = token;
          console.log(captcha);
          callback(captcha);
        });
    } catch (e) {
      postMutation();
    }
  };

  return <>
    { userData ?
        <Redirect to="/m/myschool" />:
        <>
          <div className={"login-page"}>
            <div className="login-container">
              {isLoading && <Loading />}
              {isOpen && (
                  <MessageLightBox
                      title={t("MaliciousTitle")}
                      isOpen={isOpen}
                      type={"messageBox"}
                      message={t("MaliciousContent_1")}
                      message2={t("MaliciousContent_2")}
                      message3={t("MaliciousContent_3")}
                      onClose={() => {
                        setisOpen(false);
                      }}
                      customButton={() => {
                        return (
                            <button
                                className="btn btn-primary"
                                onClick={() => setisOpen(false)}
                            >
                              {t("OK")}
                            </button>
                        );
                      }}
                  />
              )}
              <LoginNavbar t={t} lang={lang} />
              <LoginCard
                  formRef={formRef}
                  isLoginFailed={isLoginFailed}
                  isSAMDown={isSAMDown}
                  showUserNameInvalidMsg={showUserNameInvalidMsg}
                  setShowUserNameInvalidMsg={setShowUserNameInvalidMsg}
                  showPasswordInvalidMsg={showPasswordInvalidMsg}
                  setShowPasswordInvalidMsg={setShowPasswordInvalidMsg}
                  onClickOpenLink={onClickOpenLink}
                  onFormSubmit={onFormSubmit}
                  t={t}
              />
            </div>
          </div>
          <Footer />
        </>
    }
  </>
};

export default Login;