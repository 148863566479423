import { commitMutation, graphql } from "react-relay";
import environment from "../../Environment";

const mutation = graphql`
  mutation LoginSAMMutation(
    $userName: String!
    $password: String!
    $platform: LoginPlatformEnumType!
    $passwordType : PwdTypeEnumType!
  ) {
    login(userName: $userName, password: $password, platform: $platform, passwordType: $passwordType) {
      SAMAssessToken
      SAMRefreshToken
      SAMSsoToken
      SessionId
      SAMExpiresIn
      User {
        UserId
        UserType
        AdoptedSchoolYear {
          SchoolYearId
          SchoolYearName
          IsEffective
          EffectiveStartDate
          EffectiveEndDate
        }
      }
    }
  }
`;

export default function LoginSAMMutation(userName, token, platform, passwordType, action) {
  const variables = {
    userName: userName,
    password: token,
    platform: platform,
    passwordType: passwordType,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
}
