/**
 * @generated SignedSource<<65d67dd7cde0e910e267d769c606d42c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GetUserDataQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
|};
export type GetUserDataQuery$data = {|
  +user: ?{|
    +UserId: ?string,
    +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
      +SchoolYearId: ?string,
      +ClassNumber: ?string,
      +StudyGroupId: ?string,
      +StudyGroup: ?{|
        +StudyGroupId: ?string,
        +Level: ?{|
          +PearsonLevelId: ?string,
          +LevelName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +LevelName: ?string,
            +PearsonLevelId: ?string,
          |}>,
          +DisplaySequence: ?string,
        |},
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
        +StudyGroupCode: ?string,
      |},
    |}>,
    +UserName: ?string,
    +UserType: ?string,
    +UserDisplayName: ?$ReadOnlyArray<?{|
      +Lang: ?string,
      +UserName: ?string,
    |}>,
    +Preferences: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
    +AdoptedSchoolYear: ?$ReadOnlyArray<?{|
      +SchoolYearId: ?string,
      +SchoolYearName: ?string,
      +IsEffective: ?string,
      +EffectiveStartDate: ?string,
      +EffectiveEndDate: ?string,
    |}>,
    +PreferencesWithYear: ?$ReadOnlyArray<?{|
      +PreferenceName: ?string,
      +PreferenceValue: ?string,
      +SchoolYearId: ?string,
    |}>,
    +School: ?{|
      +SchoolCode: ?string,
      +SchoolAdoptedBookSeries: ?$ReadOnlyArray<?{|
        +BookSeriesId: ?string,
        +LeapBookSeriesId: ?string,
        +IngestionId: ?string,
      |}>,
    |},
  |},
|};
export type GetUserDataQuery = {|
  variables: GetUserDataQuery$variables,
  response: GetUserDataQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ClassNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PearsonLevelId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "kind": "LinkedField",
  "name": "LevelName",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LevelName",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupCode",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "userLang",
  "kind": "LinkedField",
  "name": "UserDisplayName",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PreferenceName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PreferenceValue",
  "storageKey": null
},
v19 = [
  (v17/*: any*/),
  (v18/*: any*/),
  (v5/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsEffective",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EffectiveStartDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EffectiveEndDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolCode",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LeapBookSeriesId",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v29 = [
  (v17/*: any*/),
  (v18/*: any*/),
  (v5/*: any*/),
  (v28/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUserDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "BelongsToStudyGroup",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroup",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "schoolYear",
            "kind": "LinkedField",
            "name": "AdoptedSchoolYear",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "PreferencesWithYear",
            "args": (v4/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              (v24/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "bookSeries",
                "kind": "LinkedField",
                "name": "SchoolAdoptedBookSeries",
                "plural": true,
                "selections": [
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetUserDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "BelongsToStudyGroup",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroup",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v28/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": null
              },
              (v28/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v29/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "schoolYear",
            "kind": "LinkedField",
            "name": "AdoptedSchoolYear",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v28/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "PreferencesWithYear",
            "args": (v4/*: any*/),
            "concreteType": "UserSchoolyearPreference",
            "kind": "LinkedField",
            "name": "Preferences",
            "plural": true,
            "selections": (v29/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              (v24/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "bookSeries",
                "kind": "LinkedField",
                "name": "SchoolAdoptedBookSeries",
                "plural": true,
                "selections": [
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": null
              },
              (v28/*: any*/)
            ],
            "storageKey": null
          },
          (v28/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab34c5d842b8a20ff25e1781d6bbd424",
    "id": null,
    "metadata": {},
    "name": "GetUserDataQuery",
    "operationKind": "query",
    "text": "query GetUserDataQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n) {\n  user(UserId: $UserId) {\n    UserId\n    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n      SchoolYearId\n      ClassNumber\n      StudyGroupId\n      StudyGroup {\n        StudyGroupId\n        Level {\n          PearsonLevelId\n          LevelName {\n            Lang\n            LevelName\n            PearsonLevelId\n          }\n          DisplaySequence\n          id\n        }\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        StudyGroupCode\n        id\n      }\n      id\n    }\n    UserName\n    UserType\n    UserDisplayName {\n      Lang\n      UserName\n    }\n    Preferences {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    AdoptedSchoolYear {\n      SchoolYearId\n      SchoolYearName\n      IsEffective\n      EffectiveStartDate\n      EffectiveEndDate\n      id\n    }\n    PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {\n      PreferenceName\n      PreferenceValue\n      SchoolYearId\n      id\n    }\n    School {\n      SchoolCode\n      SchoolAdoptedBookSeries(SchoolYearId: $SchoolYearId) {\n        BookSeriesId\n        LeapBookSeriesId\n        IngestionId\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d2b4a6cbcacd891aa5c987c214af6542";

module.exports = ((node/*: any*/)/*: Query<
  GetUserDataQuery$variables,
  GetUserDataQuery$data,
>*/);
