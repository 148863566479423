import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import banner from "../../../../../assets/img/banner/home-intro-photo.png";
import ModelAnswerTabObject from "../../../../../Services/api/GraphQL/Query/ModelAnswerTabObject";
import { activityLayoutTab } from "../../../../../Services/Common/common";
import {isIOS} from "react-device-detect";
var itemApp;
// var timeChecking;
var LearnositySession_id = null;
var LearnosityObject = null;
var isInappBrowser = false;

const index = (props) => {
  const { t, isReadyToRender, resourceData, resData, match, isInappBrowser } = props;
  const params = props.standalone ? match.params.resId.split("&") : null;
  const userData = useSelector((state) => state.UserReducer.user);
  const userType = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [loading, setloading] = useState(true);
  const [initReady, setinitReady] = useState(false);
  const [AnswerPermission, setAnswerPermission] = useState(false);
  const [standalone, setstandalone] = useState(props.standalone);

  useEffect(() => {
    activityLayoutTab({ disable: true, isInappBrowser });

    setAnswerPermission(true);
    getModelAnswerTabQuery();
  }, []);

  const getModelAnswerTabQuery = () => {
    if (standalone) {
      var variables;
      variables = {
        ResourceId: [resourceData.res_id],
        Lang: lang == "en-US" ? "EN" : "TC",
      };

      ModelAnswerTabObject(variables).then((data) => {
        console.log(data);
        if (data.resourceSearch.Resources.length == 0) {
          console.log("[Error] Resources Not find");
          return;
        }
        LearnosityObject = JSON.parse(
          data.resourceSearch.Resources[0].LearnosityModelAnswer
        );
        LearnositySession_id = JSON.parse(
          data.resourceSearch.Resources[0].LearnosityModelAnswer
        ).request.session_id;
        setloading(false);
        startLearnosityInit();
      });
    } else {
      // var lang;
      var variables;
      if (!isInappBrowser) {
        variables = {
          ResourceId: [match.params.resId],
          Lang: lang == "en-US" ? "EN" : "TC",
        };
      } else {
        variables = {
          ResourceId: [props.inappData.resourceId],
          Lang: lang == "en-US" ? "EN" : "TC",
        };
      }

      ModelAnswerTabObject(variables).then((data) => {
        console.log(data);
        if (data.resourceSearch.Resources.length == 0) {
          console.log("[Error] Resources Not find");
          return;
        }
        LearnosityObject = JSON.parse(
          data.resourceSearch.Resources[0].LearnosityModelAnswer
        );
        LearnositySession_id = JSON.parse(
          data.resourceSearch.Resources[0].LearnosityModelAnswer
        ).request.session_id;
        setloading(false);
        startLearnosityInit();
      });
    }
  };

  const readyListener = () => {
    console.log(
      "Learnosity Items API is ready",
      itemApp.questions(),
      itemApp.questions().length
    );
    if (isIOS) {
      const videoIframe = document.querySelectorAll("mediaelementwrapper iframe")?.[0]
      if (videoIframe) {
        videoIframe.src = "https://"+ videoIframe.src.split("://")[1]
      }
    }
    setinitReady(true);
    activityLayoutTab({ disable: false, isInappBrowser });
  };

  const errorListener = (e) => {
    // Adds a listener to all error codes.
    console.log("Error Code ", e.code);
    console.log("Error Message ", e.msg);
    console.log("Error Detail ", e.detail);
    console.log(props.match.url + "/answer", props.location.pathname);
  };

  const startLearnosityInit = () => {
    var callbacks = {
      errorListener: errorListener,
      readyListener: readyListener,
    };
    try {
      itemApp = window.LearnosityItems.init(LearnosityObject, callbacks);
      itemApp.on("item:changed", (itemIndex) => {
        scroll.scrollToTop();
      });
    } catch (e) {
      console.log(e, "ERRRRRORRRR");
    }
  };

  if (loading) return <div></div>;
  if (userType == "T") {
    return (
      <>
        <div
          style={{
            height: `${!initReady ? "100px" : "0px"}`,
          }}
        >
          <div
            className="learnosity_loading"
            style={{
              display: `${initReady ? "none" : "block"}`,
              position: "absolute",
              width: "100%",
            }}
          >
            <div className="splash-container">
              <div className="loader mx-auto" />
            </div>
          </div>
        </div>
        <div
          style={{
            visibility: `${initReady ? "visible" : "hidden"}`,
            height: `${initReady ? "auto" : "0px"}`,
            //position: `${initReady ? "relative" : "absolute"}`
          }}
        >
          <div id="learnosity_assess"></div>
        </div>
      </>
    );
  }

  if (userType == "S") {
    if (AnswerPermission) {
      return (
        <>
          <div
            style={{
              height: `${!initReady ? "100px" : "0px"}`,
            }}
          >
            <div
              className="learnosity_loading"
              style={{
                display: `${initReady ? "none" : "block"}`,
                position: "absolute",
                width: "100%",
              }}
            >
              <div className="splash-container">
                <div className="loader mx-auto" />
              </div>
            </div>
          </div>
          <div
            style={{
              visibility: `${initReady ? "visible" : "hidden"}`,
              height: `${initReady ? "auto" : "0px"}`,
              //position: `${initReady ? "relative" : "absolute"}`
            }}
          >
            <div id="learnosity_assess"></div>
          </div>
        </>
      );
    } else {
      return (
        <div className="model-answer-box-no-permission">
          <div className="model-answer-image">
            <img src={banner} />
          </div>
          <div className="model-answer-text">
            <h3>{t("Finish the assessment first")}</h3>
            <h5>
              {t(
                "The model answer will be shown after you have submitted the assessment 3 times."
              )}
            </h5>
          </div>
        </div>
      );
    }
  }
};

export default index;
