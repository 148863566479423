/**
 * @generated SignedSource<<c92b0da20ece018e70efb14b691d4e52>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LangEnumType = "EN" | "TC" | "%future added value";
export type QuestionViewSingleQuestionQuery$variables = {|
  ResourceId: $ReadOnlyArray<?string>,
  Lang?: ?LangEnumType,
|};
export type QuestionViewSingleQuestionQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +LearnosityModelAnswer: ?string,
    |}>,
  |},
|};
export type QuestionViewSingleQuestionQuery = {|
  variables: QuestionViewSingleQuestionQuery$variables,
  response: QuestionViewSingleQuestionQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "Lang",
      "variableName": "Lang"
    }
  ],
  "kind": "ScalarField",
  "name": "LearnosityModelAnswer",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionViewSingleQuestionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuestionViewSingleQuestionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d02a7451bd4a920a5bed03b4eacd16f",
    "id": null,
    "metadata": {},
    "name": "QuestionViewSingleQuestionQuery",
    "operationKind": "query",
    "text": "query QuestionViewSingleQuestionQuery(\n  $ResourceId: [String]!\n  $Lang: LangEnumType\n) {\n  resourceSearch(ResourceId: $ResourceId) {\n    Resources {\n      LearnosityModelAnswer(Lang: $Lang)\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5c53731cb3af9a46d762d715927e8ce9";

module.exports = ((node/*: any*/)/*: Query<
  QuestionViewSingleQuestionQuery$variables,
  QuestionViewSingleQuestionQuery$data,
>*/);
