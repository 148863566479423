import React from "react";
import Loading from "../../../Loading/Loading";
import { Trans } from "react-i18next";
import "./index.scss";

import AdditionalResource from "../../../OnBoarding/AdditionalResource.js";
export default (props) => {
  const {
    isOpen,
    onClose,
    t,
    resourceData,
    changefun,
    okBtn,
    checkfun,
    selectedResourceData,
  } = props;

  return (
    <div id="assignment_views" className="fullscreen_modal_container">
      <div className="modal-header assignment-student-head">
        <button
          type="button"
          className="react-modal-close normal"
          title="Close"
          onClick={() => onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1"
            viewBox="0 0 24 24"
          >
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
          </svg>
        </button>
        <div className="asignment-title">
          <p>{t("Select Additional Resource")}</p>
        </div>
      </div>
      <div className="modal-body assignment-student-list border-top border-bottom">
        <AdditionalResource
          t={t}
          changefun={changefun}
          resourceData={resourceData}
          selectedResourceData={selectedResourceData}
          checkfun={checkfun}
        />
      </div>
      <div className="modal-footer">
        <div className="modal-btn-right">
          <button
            // style={{ marginTop: "20px" }}
            className="btn btn-secondary"
            onClick={() => onClose()}
          >
            {t("Cancel")}
          </button>
          <button
            // style={{ marginTop: "20px" }}
            className="btn btn-primary"
            onClick={() => okBtn()}
          >
            {t("Ok")}
          </button>
        </div>
      </div>
    </div>
  );
};
