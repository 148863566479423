import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const LearnosityLayoutGetTitleQuery = graphql`
  query LearnosityLayoutGetTitleQuery($ResourceId: [String]!) {
    resourceSearch(ResourceId: $ResourceId) {
      Resources {
        RelatedId
        ResourceId
        IngestionId
        ResourceLangs {
          Lang
          ResourceName
        }
        BookSeries {
          BookSeriesName {
            BookSeriesName
          }
        }
      }
    }
  }
`;

const LearnosityLayoutGetTitle = (variables) => {
  return fetchQuery(environment, LearnosityLayoutGetTitleQuery, variables);
};

export default LearnosityLayoutGetTitle;
