import React, { useEffect, useState } from "react";
import Loading from "../../../Loading/Loading";
import { Trans } from "react-i18next";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import UpdateUserPreferencesMutation from "../../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";

export default (props) => {
  const { isOpen, onClose, t, bookSeriesData } = props;

  var newArray = bookSeriesData.filter(function (el) {
    return el.IngestionId === "cep_3e" ||
           el.IngestionId === "js_eltspark_spark" ||
           el.IngestionId === "JS_Chi_Classicalreading" ||
           el.IngestionId === "js_eltedge_edge"
  });

  console.log(props);
  const dispatch = useDispatch();
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  const userData = useSelector((state) => state.UserReducer.user);

  const [checked, setChecked] = useState(false);

  const onClosex = () => {
    if (checked) {
      UpdateUserPreference();
      onClose();
    } else {
      onClose();
    }
  };
  const onCheck = () => {
    setChecked(!checked);
  };
  console.log(checked);
  const UpdateUserPreference = () => {
    var data = {
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      PreferenceName: "NEW_ONBOARDING_SKIP",
      PreferenceValue: "true",
    };

    const callback = (result) => {
      console.log(result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));
    };
    UpdateUserPreferencesMutation(data, callback);
  };

  console.log(props);
  return (
    <div
      id="assignment_views"
      className="fullscreen_modal_container first_onboarding"
    >
      <div className="modal-header">
        <div className="onBoardingTitle">
          <h4>
            <p className="card-text-no-title text-center">
              {t("You have new content!")}
            </p>
          </h4>
        </div>
      </div>
      <div className="modal-body">
        <ul className="apps">
          {newArray.map((data, i) => (
            <li className="useful_app text-center" key={i}>
              <img
                src={
                  data.BookSeriesImage
                    ? window.cordova
                      ? "https:" + data.BookSeriesImage.FilePath
                      : data.BookSeriesImage.FilePath
                    : ""
                }
              />
            </li>
          ))}
        </ul>
        <div>
          <ul className="bookseries">
            {newArray.map((data, i) => (
              <li>
                {" "}
                - &nbsp;
                {
                  data.BookSeriesName[
                    data.BookSeriesName[0].Lang == lang ? 0 : 1
                  ].BookSeriesName
                }
              </li>
            ))}
          </ul>
        </div>
        <p>{t("Please click the button below to review your titles.")}</p>
      </div>
      <div className="modal-footer">
        <div className="text-center">
          <button
            className="btn btn-primary btn-xl"
            style={{ width: "100%", marginBottom: "4px" }}
            onClick={() => {
              gaTrack(
                "send",
                "event",
                "Onboarding",
                "Reselect",
                "From:My School"
              );

              if (userData.AdoptedSchoolYear.length > 1) {
                props.history.push({
                  pathname: "/onboarding",
                  state: { step: 2 },
                });
              } else if (bookSeriesData.length > 0) {
                props.history.push({
                  pathname: "/onboarding",
                  state: { step: 3 },
                });
              } else {
                props.history.push({
                  pathname: "/onboarding",
                  state: { step: 2 },
                });
              }
              UpdateUserPreference();
            }}
          >
            {t("Manage my books and classes")}
          </button>
        </div>
        <button
          style={{ width: "100%" }}
          className="btn btn-secondary"
          onClick={() => onClosex()}
        >
          {t("Close")}
        </button>
        <label
          style={{ marginTop: "10px" }}
          className="custom-control custom-control-sm custom-checkbox"
        >
          <input
            type="checkbox"
            className="custom-control-input"
            value=""
            onChange={() => onCheck()}
            checked={checked}
          />
          <span
            className="custom-control-label"
            // id={obj.BookSeriesId}
          />
          {t("Don't show again")}
        </label>
      </div>
    </div>
  );
};
