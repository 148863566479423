import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import icon from "../../assets/img/character2.svg";
// import { Translation } from "react-i18next";
// import { Bar } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";

// import goldMedal from "../../assets/img/gold-medal.svg";
// import silverMedal from "../../assets/img/silver.svg";
// import bronzeMedal from "../../assets/img/bronze.svg";
// import emptyMedal from "../../assets/img/empty.svg";

import Loading from "../Loading/Loading";
import MyResourceReport from "../../Services/api/GraphQL/Query/MyResourceReport";
import { isMobileOnly } from "react-device-detect";

const ReportLineChart = (props) => {
  // const { t, i18n } = useTranslation();
  const {
    subjectName,
    levelName,
    // isInappBrowser,
    // inappData,
    t,
    levelId,
    subjectId,
  } = props;
  const lang = useSelector((state) => state.SystemReducer.lang);
  // const dispatch = useDispatch();
  // const [myScoreVal, setmyScoreVal] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [chartInfo, setchartInfo] = useState("");
  const [elementIndex, setelementIndex] = useState(0);
  const [topicData, settopicData] = useState([]);

  const [endval, setendval] = useState(5);
  const [totalElements, settotalElements] = useState(0);
  const effectiveSchoolYear = useSelector(
    (state) => state.SystemReducer.effectiveSchoolYear
  );
  const userData = useSelector((state) => state.UserReducer.user);
  const [OrderBy, setOrderBy] = useState("HIGH");

  useEffect(() => {
    getScoreList();
  }, []);

  // useEffect(() => {
  //   console.log(i18n);
  // }, [i18n]);

  useEffect(() => {
    setisLoading(true);
    getScoreList();
  }, [levelId, subjectId]);

  useEffect(() => {
    if (topicData.length != 0) {
      if (OrderBy == "HIGH") {
        prepareChartData(
          topicData.sort((a, b) => b.MyScore - a.MyScore),
          elementIndex,
          endval
        );
      } else {
        prepareChartData(
          topicData.sort((a, b) => a.MyScore - b.MyScore),
          elementIndex,
          endval
        );
      }
    }
  }, [lang]);

  const getScoreList = () => {
    var variables;
    variables = {
      UserId: userData.UserId,
      SchoolYearId: effectiveSchoolYear.SchoolYearId,
      PearsonLevelId: levelId,
    };

    MyResourceReport(variables).then((data) => {
      console.log("subject" + subjectId);
      var tagList = data.user.AdoptedTagHierarchy.filter(
        (o) => o.ForReport == "Y" && o.HierarchyReference == subjectId
      );

      if (tagList[0].ReferenceLevelScore.length > 0) {
        if (tagList[0].RelatedTagType.length > 0) {
          var relatedTags = tagList[0].RelatedTagType;
          let tagsWithoutParent = relatedTags.filter(
            (o) => o.ParentTagType != null
          );
          var relatedTagType = tagsWithoutParent[0].Tags;
          console.log();
          var chartData = [];

          relatedTagType.map(function (obj, index) {
            var chartObj = {};

            let scoreVal = tagList[0].ReferenceLevelScore.filter(
              (o) =>
                o.PearsonLevelId == levelId &&
                o.TagType == tagsWithoutParent[0].TagType &&
                o.TagName == obj.TagName
            );
            if (scoreVal.length > 0) {
              var mypercentage;
              var allpercentage;
              if (scoreVal[0].MyScore == null) {
                mypercentage = null;
              } else {
                mypercentage = Math.round(
                  (scoreVal[0].MyScore / scoreVal[0].MyMaxScore) * 100
                );
              }
              if (scoreVal[0].AllScore == null) {
                allpercentage = null;
              } else {
                allpercentage = Math.round(
                  (scoreVal[0].AllScore / scoreVal[0].AllMaxScore) * 100
                );
              }
              chartObj.TagName = obj.TagName;
              chartObj.ParentTagType = tagsWithoutParent[0].TagType;
              chartObj.TagLongName = obj.TagLongName;
              chartObj.DisplaySequence = obj.DisplaySequence;
              chartObj.TagDisplayNameEn = obj.TagDisplayNameEn;
              chartObj.TagDisplayNameChi = obj.TagDisplayNameChi;
              chartObj.TagType = scoreVal[0].TagType;
              chartObj.MyScore = mypercentage;
              chartObj.MyMaxScore = scoreVal[0].MyMaxScore;
              chartObj.AllScore = allpercentage;
              chartObj.AllMaxScore = scoreVal[0].AllMaxScore;
              chartData.push(chartObj);
            } else {
              // chartObj.TagName = obj.TagName;
              // chartObj.ParentTagType = tagsWithoutParent[0].TagType;
              // chartObj.TagLongName = obj.TagLongName;
              // chartObj.DisplaySequence = obj.DisplaySequence;
              // chartObj.TagDisplayNameEn = obj.TagDisplayNameEn;
              // chartObj.TagDisplayNameChi = obj.TagDisplayNameChi;
              // chartObj.TagType = tagsWithoutParent[0].TagType;
              // chartObj.MyScore = null;
              // chartObj.MyMaxScore = null;
              // chartObj.AllScore = null;
              // chartObj.AllMaxScore = null;
              // chartData.push(chartObj);
            }
          });

          chartData.sort(function (a, b) {
            var seq1 = parseInt(a.DisplaySequence),
              seq2 = parseInt(b.DisplaySequence);
            if (seq1 < seq2) return -1;
            if (seq1 > seq2) return 1;
            return 0;
          });
          console.log(chartData);
          settotalElements(chartData.length);
          settopicData(chartData);
          prepareChartData(
            OrderBy == "LOW"
              ? chartData.sort((a, b) => a.MyScore - b.MyScore)
              : chartData.sort((a, b) => b.MyScore - a.MyScore),
            0,
            5
          );
        } else {
        }
      } else {
        var chartData = [];
        var chartObj = {};
        chartObj.TagName = "NoData";
        chartObj.TagLongName = "NoData";
        chartObj.TagDisplayNameEn = "NoData";
        chartObj.TagDisplayNameChi = "NoData";
        chartObj.MyScore = null;
        chartObj.MyMaxScore = null;
        chartObj.AllScore = null;
        chartObj.AllMaxScore = null;
        chartData.push(chartObj);
        settotalElements(chartData.length);
        settopicData(chartData);
        prepareChartData(chartData, 0, 5);
      }

      console.log(chartData);
      setisLoading(false);
    });
  };

  const prepareChartData = (chartarg, start, end) => {
    console.log("chartarg", chartarg);
    var labelNames = [];
    var myScoreArray = [];
    var otherScoreArray = [];
    let slicedElements = chartarg.slice(start, end);
    slicedElements.map(function (obj, index) {
      let labelNameLang =
        lang == "en-US" ? obj.TagDisplayNameEn : obj.TagDisplayNameChi;
      let lname = labelNameLang ? labelNameLang : obj.TagLongName;
      // let lname = "testingggggggggggggggggggggggggggggggggggg";
      //labelNames.push(labelNameLang ? labelNameLang : obj.TagLongName);
      //labelNames.push(lname.match(/.{1,10}/g));

      labelNames.push(lname.split(" "));
      myScoreArray.push(obj.MyScore);
      otherScoreArray.push(obj.AllScore);
    });
    console.log(myScoreArray);
    var tooltipSeriesMyPerformance = t("My Performance");
    var tooltipSeriesOther = t("Performance of Other Users");
    let chartObjData = {
      series: [
        {
          name: tooltipSeriesMyPerformance,
          data: myScoreArray,
        },
        {
          name: tooltipSeriesOther,
          data: otherScoreArray,
        },
      ],
      options: {
        colors: ["#12b2a6", "#ffbb1c"],
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        chart: {
          type: "bar",
          height: 400,
          width: "50%",
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        fill: {
          colors: ["#12b2a6", "#ffbb1c"],
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        stroke: {
          show: false,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              // maxWidth: 80,
              colors: ["#151515"],
              fontSize: "12px",
            },
          },
        },
        xaxis: {
          categories: labelNames,
          min: 0,
          max: 100,
          range: 10,

          labels: {
            show: true,
            minHeight: 50,
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
          },
        },
        responsive: [
          {
            breakpoint: 500,
            options: {
              xaxis: {
                min: 0,
                max: 100,
                range: 20,
              },
              yaxis: {
                show: false,
              },
            },
          },
        ],
      },
    };
    console.log(chartObjData);
    setchartInfo(chartObjData);
  };
  const showNextTopic = () => {
    let currentIndex = elementIndex;
    let endIndex = endval;
    setelementIndex(currentIndex + 1);
    setendval(endIndex + 1);
    prepareChartData(topicData, currentIndex + 1, endIndex + 1);
  };

  const showPreviousTopic = () => {
    let currentIndex = elementIndex;
    let endIndex = endval;
    setelementIndex(currentIndex - 1);
    setendval(endIndex - 1);
    prepareChartData(topicData, currentIndex - 1, endIndex - 1);
  };

  const checkTopArrow = () => {
    if (elementIndex == 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkDownArrow = () => {
    if (totalElements <= 5) {
      return false;
    }
    if (totalElements - elementIndex > 5) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="box-loading">
          <Loading type="inpage" />
        </div>
      ) : (
        <React.Fragment>
          {" "}
          <div className="heading">
            <div className="line"></div>
            <div className="chart-title">{t("By Topic")}</div>
            <div className="line"></div>
            <div className="chart-view">
              <div className="score-order">
                {t("Order by result")}
                <select
                  value={OrderBy}
                  onChange={(e) => {
                    setOrderBy(e.target.value);
                    gaTrack(
                      "send",
                      "event",
                      "Supplementary",
                      "Sorting of learning topic in performance",
                      "Sorting:" +
                        e.target.value +
                        ", Subject:" +
                        subjectName +
                        ", Level:" +
                        levelName +
                        ", Role:" +
                        userData.UserType
                    );
                    if (e.target.value == "HIGH") {
                      prepareChartData(
                        topicData.sort((a, b) => b.MyScore - a.MyScore),
                        elementIndex,
                        endval
                      );
                    } else {
                      prepareChartData(
                        topicData.sort((a, b) => a.MyScore - b.MyScore),
                        elementIndex,
                        endval
                      );
                    }
                  }}
                >
                  <option value={"HIGH"}>{t("High to Low​")}</option>
                  <option value={"LOW"}>{t("Low to High")}</option>
                </select>
              </div>
              <div className="subchart">
                <div className="chartdiv">
                  <ReactApexChart
                    options={chartInfo.options}
                    series={chartInfo.series}
                    type="bar"
                    height={400}
                  />
                </div>
                <div className="arrow">
                  {checkTopArrow() ? (
                    <div
                      className="top-arrow active"
                      onClick={() => {
                        showPreviousTopic();
                      }}
                    >
                      <i className="icon mdi mdi-chevron-up"></i>
                    </div>
                  ) : (
                    <div className="top-arrow inactive">
                      <i className="icon mdi mdi-chevron-up"></i>
                    </div>
                  )}
                  {checkDownArrow() ? (
                    <div
                      className="down-arrow active"
                      onClick={() => {
                        showNextTopic();
                      }}
                    >
                      <i className="icon mdi mdi-chevron-down"></i>
                    </div>
                  ) : (
                    <div className="down-arrow inactive">
                      <i className="icon mdi mdi-chevron-down"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReportLineChart;
