import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const TopicListingOptionsQuery = graphql`
  query TopicListingOptionsQuery(
    $UserId: String!
    $BookSeriesId: String!
    $BookId: String!
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesId
        BookSeriesName {
          BookSeriesName
          Lang
        }
        Books(BookId: $BookId) {
          BookId
          BookName {
            BookName
            Lang
          }
          AdoptedTagHierarchy {
            HierarchyReference
            DisplaySequence
            HierarchyReferenceLabelEn
            HierarchyReferenceLabelChi
          }
        }
      }
    }
  }
`;

const TopicListingOptions = (variables) => {
  return fetchQuery(environment, TopicListingOptionsQuery, variables);
};

export default TopicListingOptions;
