import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation MigrateStandaloneAttemptHistoryMutation(
    $StandaloneAttemptSessionId: String!
    $ResourceIngestionId: String!
  ) {
    migrateStandaloneAttemptHistory(
      StandaloneAttemptSessionId: $StandaloneAttemptSessionId
      ResourceIngestionId: $ResourceIngestionId
    )
  }
`;
const MigrateStandaloneAttemptHistory = (values, action) => {
  const variables = {
    StandaloneAttemptSessionId: values.StandaloneAttemptSessionId,
    ResourceIngestionId: values.ResourceIngestionId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
};

export default MigrateStandaloneAttemptHistory;
