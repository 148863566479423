import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const TopicContentsQuery = graphql`
  query TopicContentsQuery(
    $HierarchyReference: String!
    $BookId: String!
    $UserId: String!
  ) {
    learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {
      DisplaySequence
      HierarchyReferenceLabelEn
      HierarchyReferenceLabelChi
      HierarchyReference
      RelatedTagType {
        Tags {
          TagType
          TagName
          TagLongName
          TagDisplayNameEn
          TagDisplayNameChi
          DisplaySequence
        }
      }
      AdoptedLearnosityResource(BookId: $BookId) {
        LearnosityReferenceId
        Level1TagType
        Level1TagName
        Level2TagType
        Level2TagName
        RelatedResource(BookId: $BookId) {
          ResourceId
          SelfLearnAttemptHistory(
            UserId: $UserId
            IncludeAssignmentRecord: false
          ) {
            CompletedDate
            Score
            MaxScore
            IsCompleted
          }
        }
      }
    }
  }
`;

const TopicContents = (variables) => {
  return fetchQuery(environment, TopicContentsQuery, variables);
};

export default TopicContents;
