/**
 * @generated SignedSource<<12ebbf29b55e445f3f240df8c63c1a02>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type OnBoardingTeacherStudyGroupQuery$variables = {|
  UserId: string,
  SchoolYearId: string,
  FilterOnboarded: boolean,
|};
export type OnBoardingTeacherStudyGroupQuery$data = {|
  +user: ?{|
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +IngestionId: ?string,
        |},
      |}>,
    |},
  |},
|};
export type OnBoardingTeacherStudyGroupQuery = {|
  variables: OnBoardingTeacherStudyGroupQuery$variables,
  response: OnBoardingTeacherStudyGroupQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "FilterOnboarded"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "FilterOnboarded",
    "variableName": "FilterOnboarded"
  },
  {
    "kind": "Variable",
    "name": "SchoolYearId",
    "variableName": "SchoolYearId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IngestionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnBoardingTeacherStudyGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OnBoardingTeacherStudyGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e90169eedd89dbee1d706904eaa89253",
    "id": null,
    "metadata": {},
    "name": "OnBoardingTeacherStudyGroupQuery",
    "operationKind": "query",
    "text": "query OnBoardingTeacherStudyGroupQuery(\n  $UserId: String!\n  $SchoolYearId: String!\n  $FilterOnboarded: Boolean!\n) {\n  user(UserId: $UserId) {\n    School {\n      StudyGroups(SchoolYearId: $SchoolYearId, FilterOnboarded: $FilterOnboarded) {\n        StudyGroupId\n        BookSeries {\n          BookSeriesId\n          IngestionId\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ef6e1f483a1a4bbbfbe7c15aa4606bcd";

module.exports = ((node/*: any*/)/*: Query<
  OnBoardingTeacherStudyGroupQuery$variables,
  OnBoardingTeacherStudyGroupQuery$data,
>*/);
