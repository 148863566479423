import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetB2CTopicResourcesQuery = graphql`
  query GetB2CTopicResourcesQuery(
    $UserId: String!
    $BookSeriesId: String!
    $BookId: String!
    $TagName: String!
    $PageNo: Int
    $PageSize: Int
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        Books(BookId: $BookId) {
          AdoptedTagHierarchy {
            RelatedTagType {
              Tags(BookId: $BookId, TagName: $TagName) {
                TopicPreStudyResources(
                  BookId: $BookId
                  IncludeFollowUpRes: true
                  PageNo: $PageNo
                  PageSize: $PageSize
                  Order: ASC
                ) {
                  Resources {
                    IngestionId
                    ResourceId
                    RelatedId
                    ResourceDownloadUrl
                    ResourceFileNameIfAny
                    ResourceAccessUrl
                    ResourceType
                    Linkage
                    FileSource
                    Downloadable
                    Thumbnail {
                      FilePath
                    }
                    ResourceLangs {
                      Lang
                      ResourceName
                    }
                  }
                  TotalNumberOfRecord
                }
              }
            }
          }
        }
      }
    }
  }
`;
const GetB2CTopicResources = (variables) => {
  return fetchQuery(environment, GetB2CTopicResourcesQuery, variables);
};

export default GetB2CTopicResources;
