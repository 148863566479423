import { commitMutation, graphql } from "react-relay";
import environment from "../../../../Environment";

const mutation = graphql`
  mutation UpdateUserPreferencesMutation(
    $SchoolYearId: String!
    $PreferenceName: String!
    $PreferenceValue: String!
  ) {
    modifyUserPreference(
      SchoolYearId: $SchoolYearId
      PreferenceName: $PreferenceName
      PreferenceValue: $PreferenceValue
    ) {
      UserId
      Preferences {
        PreferenceName
        PreferenceValue
        SchoolYearId
      }
      PreferencesWithYear: Preferences(SchoolYearId: $SchoolYearId) {
        PreferenceName
        PreferenceValue
        SchoolYearId
      }
    }
  }
`;

const UpdateUserPreferencesMutation = (data, action) => {
  // const udata = JSON.parse(data);
  const variables = {
    // SchoolYearId: data.SchoolYearId ? data.SchoolYearId : "",
    SchoolYearId: data.SchoolYearId ? data.SchoolYearId : "",
    PreferenceName: data.PreferenceName,
    PreferenceValue: data.PreferenceValue,
  };
  console.log("zxczxcz", { data, variables });

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      action(response);
      console.log(response);
      console.log("Response received from server.");
    },
    onError: (err) => console.log(err, "err"),
  });
};
export default UpdateUserPreferencesMutation;
