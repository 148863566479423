/**
 * @generated SignedSource<<57c1e2675307db0af98fa8fed62bec2e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfigNameEnumType = "ShowAccessment" | "HideAccessment" | "ReleaseRedo" | "DisableRedo" | "ShowResource" | "HideResource" | "%future added value";
export type SelfLearnConfigurationInputType = {|
  ResourceId?: ?string,
  SchoolYearId: string,
  StudyGroupId: string,
  StructureId?: ?string,
  Action: ConfigNameEnumType,
|};
export type UpdateSelfLearnConfigMutation$variables = {|
  NotifyStudent: boolean,
  SelfLearnConfigurations: $ReadOnlyArray<?SelfLearnConfigurationInputType>,
|};
export type UpdateSelfLearnConfigMutation$data = {|
  +updateSelfLearnConfig: ?boolean,
|};
export type UpdateSelfLearnConfigMutation = {|
  variables: UpdateSelfLearnConfigMutation$variables,
  response: UpdateSelfLearnConfigMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "NotifyStudent"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "SelfLearnConfigurations"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "NotifyStudent",
        "variableName": "NotifyStudent"
      },
      {
        "kind": "Variable",
        "name": "SelfLearnConfigurations",
        "variableName": "SelfLearnConfigurations"
      }
    ],
    "kind": "ScalarField",
    "name": "updateSelfLearnConfig",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSelfLearnConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSelfLearnConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c3d79f2409ce38e00e48a5eae8916ad",
    "id": null,
    "metadata": {},
    "name": "UpdateSelfLearnConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSelfLearnConfigMutation(\n  $NotifyStudent: Boolean!\n  $SelfLearnConfigurations: [SelfLearnConfigurationInputType]!\n) {\n  updateSelfLearnConfig(NotifyStudent: $NotifyStudent, SelfLearnConfigurations: $SelfLearnConfigurations)\n}\n"
  }
};
})();

(node/*: any*/).hash = "0e8c24489d9e5d12ebb73ff35720cf28";

module.exports = ((node/*: any*/)/*: Mutation<
  UpdateSelfLearnConfigMutation$variables,
  UpdateSelfLearnConfigMutation$data,
>*/);
