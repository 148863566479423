import React from "react";

export default (props) => {
  const { onCloseButton, design } = props;
  return (
    <button
      type="button"
      className={`react-modal-close circle ${design} `}
      title="Close"
      onClick={() => onCloseButton()}
    >
      <div>
        <i className="icon mdi mdi-close" />
      </div>
    </button>
  );
};
