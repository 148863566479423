import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./index.scss";
import myscoreicon from "../../../../assets/img/character.svg";
import otherscoreicon from "../../../../assets/img/character2.svg";
import nodataimg from "../../../../assets/img/bird.svg";
import MyResourceScore from "../../../../Services/api/GraphQL/Query/MyResourceScore";
import Loading from "../../../../Components/Loading/Loading";
import ReportChart from "../../../../Components/ReportChart";
import ReportLineChart from "../../../../Components/ReportLineChart";
function index(props) {
  const { t, levelId, subjectId, subjectName, levelName } = props;

  const lang = useSelector((state) => state.SystemReducer.lang);
  const [myScoreVal, setmyScoreVal] = useState("");
  const [allScoreVal, setallScoreVal] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [isChart, setisChart] = useState(true);

  const effectiveSchoolYear = useSelector(
    (state) => state.SystemReducer.effectiveSchoolYear
  );
  const userData = useSelector((state) => state.UserReducer.user);
  useEffect(() => {
    getScoreList();
  }, []);

  useEffect(() => {
    setisLoading(true);
    getScoreList();
  }, [levelId, subjectId]);

  const getScoreList = () => {
    var variables;
    variables = {
      UserId: userData.UserId,
      SchoolYearId: effectiveSchoolYear.SchoolYearId,
      PearsonLevelId: levelId,
    };

    MyResourceScore(variables).then((data) => {
      console.log(data.user.AdoptedTagHierarchy);
      let tagList = data.user.AdoptedTagHierarchy.filter(
        (o) => o.ForReport == "Y" && o.HierarchyReference == subjectId
      );
      console.log(tagList);
      if (tagList[0].ReferenceLevelScore.length > 0) {
        let scoreVal = tagList[0].ReferenceLevelScore.filter(
          (o) => o.PearsonLevelId == levelId && o.TagType == "[ROOT]"
        );
        if (scoreVal[0].AllScore == null) {
          setallScoreVal("");
        } else {
          setallScoreVal(
            Math.round((scoreVal[0].AllScore / scoreVal[0].AllMaxScore) * 100)
          );
        }

        if (scoreVal[0].MyScore == null) {
          setmyScoreVal("");
        } else {
          setmyScoreVal(
            Math.round((scoreVal[0].MyScore / scoreVal[0].MyMaxScore) * 100)
          );
        }
        setisChart(true);
      } else {
        setallScoreVal("");
        setmyScoreVal("");
        setisChart(false);
      }
    });
    setisLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="box-loading">
          <Loading type="inpage" />
        </div>
      ) : (
        <React.Fragment>
          <div className="numbergroup">
            <div
              className={`numberbox  ${
                myScoreVal == "" ? "inactiveself" : "numberbox-self"
              }`}
            >
              <div className="character-area">
                <img src={myscoreicon}></img>
              </div>

              <div className="numberbox-text">
                <div className="titletext">{t("My Average Score")}:</div>
                <div className="scoretext">
                  {" "}
                  {myScoreVal == "" ? "-" : myScoreVal}
                </div>
              </div>
            </div>
            <div
              className={`numberbox  ${
                allScoreVal == "" ? "inactiveall" : "numberbox-all"
              }`}
            >
              <div className="character-area">
                <img src={otherscoreicon}></img>
              </div>
              <div className="numberbox-text">
                <div className="titletext">
                  {" "}
                  {t("OtherUsersAverageScore​1​")}:
                </div>
                <div className="scoretext">
                  {" "}
                  {allScoreVal == "" ? "-" : allScoreVal}
                </div>
              </div>
            </div>
          </div>
          <div className="score-identifier">
            <div className="my-circle"></div>
            <div className="score-label">{t("My Performance")}</div>
            <div className="other-circle"></div>
            <div className="score-label2">
              {t("Performance of Other Users")}
            </div>
          </div>

          <div className={`chart-analyis ${isChart ? "" : "nochart"}`}>
            <div className="panel-heading">
              <p className="title">{t("Performance Analysis")}</p>
            </div>
            {isChart ? (
              <div className="chartSection">
                <div className="radardiv">
                  <ReportChart
                    t={t}
                    {...props}
                    levelId={levelId}
                    subjectId={subjectId}
                  />
                </div>
                <div className="center-line-div">
                  <div className="center-line"></div>
                </div>
                <div className="bardiv">
                  <ReportLineChart
                    t={t}
                    {...props}
                    levelId={levelId}
                    subjectId={subjectId}
                  />
                </div>
              </div>
            ) : (
              <div className="nochart-data">
                {" "}
                <img src={nodataimg} />
                <p>{t("There is no analysis yet.")}</p>
              </div>
            )}
          </div>
          <div className="notification">
            <div>*{t("The data is updated every 24 hours")}</div>

            <div>#{t("This page is best viewed in a web browser.")}</div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default index;
