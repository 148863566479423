import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const AdoptedBookSeriesInfoQuery = graphql`
  query AdoptedBookSeriesInfoQuery(
    $UserId: String!
    $BookSeriesId: String!
    $BookId: String!
  ) {
    user(UserId: $UserId) {
      AdoptedBookSeries(BookSeriesId: $BookSeriesId) {
        BookSeriesId
        IngestionId
        BookSeriesName {
          BookSeriesName
          Lang
        }
        Books(BookId: $BookId) {
          BookName {
            Lang
            BookName
          }
        }
      }
    }
  }
`;
const AdoptedBookSeriesInfo = (variables) => {
  return fetchQuery(environment, AdoptedBookSeriesInfoQuery, variables);
};

export default AdoptedBookSeriesInfo;
