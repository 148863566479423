/**
 * @generated SignedSource<<8c1c13f3c649e1029f35c07df04be058>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GetB2CResourcesCenterTopicsMenuQuery$variables = {|
  UserId: string,
  BookSeriesId: string,
  BookId: string,
|};
export type GetB2CResourcesCenterTopicsMenuQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +BookSeriesName: ?$ReadOnlyArray<?{|
        +Lang: ?string,
        +BookSeriesName: ?string,
      |}>,
      +Books: ?$ReadOnlyArray<?{|
        +BookName: ?$ReadOnlyArray<?{|
          +Lang: ?string,
          +BookName: ?string,
        |}>,
        +AdoptedTagHierarchy: ?$ReadOnlyArray<?{|
          +RelatedTagType: ?$ReadOnlyArray<?{|
            +Tags: ?$ReadOnlyArray<?{|
              +TagName: ?string,
              +TagLongName: ?string,
              +TagType: ?string,
            |}>,
          |}>,
          +AdoptedLearnosityResource: ?$ReadOnlyArray<?{|
            +Level2TagType: ?string,
            +Level2TagName: ?string,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
export type GetB2CResourcesCenterTopicsMenuQuery = {|
  variables: GetB2CResourcesCenterTopicsMenuQuery$variables,
  response: GetB2CResourcesCenterTopicsMenuQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "BookId",
    "variableName": "BookId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "bookLang",
  "kind": "LinkedField",
  "name": "BookName",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagLongName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TagType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level2TagType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Level2TagName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetB2CResourcesCenterTopicsMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "kind": "LinkedField",
                    "name": "AdoptedTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "kind": "LinkedField",
                        "name": "RelatedTagType",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "kind": "LinkedField",
                            "name": "Tags",
                            "plural": true,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "LearnosityActivityTagInfo",
                        "kind": "LinkedField",
                        "name": "AdoptedLearnosityResource",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetB2CResourcesCenterTopicsMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "Books",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnosityTagHierarchy",
                    "kind": "LinkedField",
                    "name": "AdoptedTagHierarchy",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnosityTagHierarchyTagTypeType",
                        "kind": "LinkedField",
                        "name": "RelatedTagType",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "concreteType": "LearnosityTagHierarchyTagType",
                            "kind": "LinkedField",
                            "name": "Tags",
                            "plural": true,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "LearnosityActivityTagInfo",
                        "kind": "LinkedField",
                        "name": "AdoptedLearnosityResource",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ae98be47c45d8a3a101298fc5193021",
    "id": null,
    "metadata": {},
    "name": "GetB2CResourcesCenterTopicsMenuQuery",
    "operationKind": "query",
    "text": "query GetB2CResourcesCenterTopicsMenuQuery(\n  $UserId: String!\n  $BookSeriesId: String!\n  $BookId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      BookSeriesName {\n        Lang\n        BookSeriesName\n      }\n      Books(BookId: $BookId) {\n        BookName {\n          Lang\n          BookName\n        }\n        AdoptedTagHierarchy {\n          RelatedTagType {\n            Tags(BookId: $BookId) {\n              TagName\n              TagLongName\n              TagType\n              id\n            }\n            id\n          }\n          AdoptedLearnosityResource(BookId: $BookId) {\n            Level2TagType\n            Level2TagName\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0059d45f3bbd797a5862af8a01348464";

module.exports = ((node/*: any*/)/*: Query<
  GetB2CResourcesCenterTopicsMenuQuery$variables,
  GetB2CResourcesCenterTopicsMenuQuery$data,
>*/);
