import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const MyResourceScoreQuery = graphql`
  query MyResourceScoreQuery($UserId: String!, $PearsonLevelId: String!) {
    user(UserId: $UserId) {
      AdoptedTagHierarchy {
        ForReport
        id
        DisplaySequence
        HierarchyReference
        HierarchyReferenceLabelEn
        AdoptedPearsonLevel {
          id
          PearsonLevelId
          LevelName {
            LevelName
            PearsonLevelId
          }
        }
        ReferenceLevelScore(PearsonLevelId: $PearsonLevelId) {
          id
          HierarchyReference
          MyScore
          MyMaxScore
          AllScore
          AllMaxScore
          PearsonLevelId
          LastUpdateDate
          TagType
          TagName
        }
      }
    }
  }
`;

const MyResourceScore = (variables) => {
  return fetchQuery(environment, MyResourceScoreQuery, variables);
};

export default MyResourceScore;
