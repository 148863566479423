import { combineReducers } from "redux";
import UserReducer from "./User";
import SystemReducer from "./System";
import StudyGroupHandbookReducer from "./StudyGroupHandbook";
import NotificationListReducer from "./NotificationList";
import HandbookReducer from "./HandBook";
import ResCenterAssignedRelatedReducer from "./ResCenterAssignedRelated";
import ResCenterListReloadReducer from "./ResCenterListReload";
import EntryOfAssessmentReducer from "./EntryOfAssessment";
import CommonNavBarReducer from "./CommonNavBar";
import LayoutIdentifyReducer from "./LayoutIdentify";
import ActivityReportReducer from "./ActivityReport";
import EntryOfResourceReducer from "./EntryOfResource";
import StudentResultReducer from "./StudentResult";
import OpenInstallModalReducer from "./OpenInstallModal";
export default combineReducers({
  UserReducer,
  SystemReducer,
  StudyGroupHandbookReducer,
  NotificationListReducer,
  HandbookReducer,
  ResCenterAssignedRelatedReducer,
  ResCenterListReloadReducer,
  EntryOfAssessmentReducer,
  EntryOfResourceReducer,
  CommonNavBarReducer,
  ActivityReportReducer,
  LayoutIdentifyReducer,
  StudentResultReducer,
  OpenInstallModalReducer,
});
