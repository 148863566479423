import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MessageBox from "../../../../Components/Feature/MessageBox";
import icon from "../../../../assets/img/Group 102.png";
import banner from "../../../../assets/img/bird.svg";
import defaultImage from "../../../../assets/img/B2C/group-144.svg";
import GetB2CResourcesCenterTopicsMenu from "../../../../Services/api/GraphQL/Query/GetB2CResourcesCenterTopicsMenu";
import GetB2CTopicResources from "../../../../Services/api/GraphQL/Query/GetB2CTopicResources";
import { suggestionTypeIconMapping } from "../../../../Services/Common/ResourcesTypeIcon";
import VidoeLightBox from "../../../../Components/Feature/VideoLightBox";
import { config } from "../../../../Config/config";
import ImageLightBox from "../../../../Components/Feature/ImageLightBox";
var browserRef = null;
import {
  isMobile,
  isBrowser,
  isTablet,
  isMobileOnly,
  osName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera,
} from "react-device-detect";
import "./index.scss";
import GetEPSToken from "../../../../Services/api/GraphQL/Query/GetEPSToken";
import Loading from "../../../../Components/Loading/Loading";

const B2CResourcesCenter = (props) => {
  const { t, bkSeriesIngestId } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const lang = useSelector((state) => state.SystemReducer.lang);
  const [preSetData, setpreSetData] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [mobileOpen, setmobileOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchingEPSResourceToken, setfetchingEPSResourceToken] =
    useState(false);
  const [videoLightBoxOpen, setVideoLightBoxOpen] = useState(null);
  const [imageLightBoxOpen, setImageLightBoxOpen] = useState(null);

  const [menuList, setmenuList] = useState(null);
  const [selectedTopic, setselectedTopic] = useState(null);
  const [pageNo, setpageNo] = useState(1);
  const [totalCount, settotalCount] = useState(0);
  const [resourcesList, setresourcesList] = useState(null);
  const [groupBy, setgroupBy] = useState(
    localStorage.getItem("_B2C_RC_VIEW") != null
      ? localStorage.getItem("_B2C_RC_VIEW")
      : "Group"
  );

  const [showEmpty, setshowEmpty] = useState(false);

  useEffect(() => {
    //get the list of menu (Topics)
    // const start = async () => {
    if (config.hidden.b2cRcenter.indexOf(bkSeriesIngestId) != -1) {
      setshowEmpty(true);
    } else {
      getMenuTopicsList();
    }
    setLoading(false);

    // };
    // start();
  }, []);

  useEffect(() => {
    if (selectedTopic != null) {
      //call query get resources
      setmobileOpen(false);
      console.log("call query get resources");
      getSelectedTopicResources();
    }
  }, [selectedTopic, pageNo]); //listen on menuList

  const getSelectedTopicResources = async () => {
    let variables = {
      UserId: userData?.UserId,
      BookSeriesId: props.match.params.id,
      BookId: props.match.params.book,
      TagName: selectedTopic.Level2TagName,
      PageNo: pageNo,
      PageSize: 12,
    };
    await GetB2CTopicResources(variables).then((data) => {
      let filterData =
        data.user.AdoptedBookSeries[0]?.Books[0]?.AdoptedTagHierarchy.filter(
          (o) => {
            return o.RelatedTagType.filter((r) => {
              return r.TagType == selectedTopic.TagType;
            });
          }
        ) || [];
      console.log(filterData);
      const TargetTag = filterData[0]?.RelatedTagType.filter(
        (t) => t.Tags.length != 0
      )[0]?.Tags;
      const TargetTagResourcesList = TargetTag[0]?.TopicPreStudyResources || {
        Resources: null,
        TotalNumberOfRecord: 0,
      };
      const totalPages =
        TargetTagResourcesList.TotalNumberOfRecord == 0
          ? 0
          : Math.round(TargetTagResourcesList.TotalNumberOfRecord / 12);
      console.log(
        TargetTag,
        TargetTagResourcesList,
        Math.round(TargetTagResourcesList.TotalNumberOfRecord / 12)
      );

      setresourcesList(
        TargetTagResourcesList.TotalNumberOfRecord == 0
          ? []
          : TargetTagResourcesList.Resources
      );
      settotalCount(totalPages);

      // setresourcesList([1, 2, 3]);
      // settotalCount(3);
    });
  };

  const getMenuTopicsList = async () => {
    let variables = {
      UserId: userData?.UserId,
      BookSeriesId: props.match.params.id,
      BookId: props.match.params.book,
    };
    try {
      await GetB2CResourcesCenterTopicsMenu(variables).then((data) => {
        console.log(data);
        // if(data.user.AdoptedBookSeries.length==0)
        let topicsList = [];
        let allTags = [];
        const adoptedArray =
          data.user.AdoptedBookSeries[0]?.Books[0]?.AdoptedTagHierarchy || [];
        adoptedArray.map((o, i) => {
          o.RelatedTagType.map((to, ti) => {
            if (to.Tags.length != 0) allTags = [...allTags, ...to.Tags];
          });
        });

        console.log("All tags ", allTags);
        adoptedArray.map((o, i) => {
          o.AdoptedLearnosityResource.map((ao, ai) => {
            if (
              topicsList
                .map((k) => k.Level2TagName)
                .indexOf(ao.Level2TagName) == -1
            ) {
              const targetTag = allTags.filter(
                (t) => t.TagName == ao.Level2TagName
              );
              topicsList.push({
                TagType: targetTag[0]?.TagType,
                Level2TagName: ao.Level2TagName,
                Level2TagType: ao.Level2TagType,
                Level2TagLongName: targetTag[0]?.TagLongName || "---",
              });
            }
          });
        });
        console.log("all topics remove duplicate ", topicsList);
        setpreSetData({
          book: data.user.AdoptedBookSeries[0]?.Books[0],
          bookSeries: data.user.AdoptedBookSeries[0],
        });
        setmenuList(topicsList);
      });
    } catch (error) {
      console.log("[Error] GetB2CResourcesCenterTopicsMenu : ", error);
    }
  };

  const getEPSToken = (resId, options, callback) => {
    setfetchingEPSResourceToken(true);
    var value = {
      ResourceId: resId,
    };
    try {
      GetEPSToken(value).then((data) => {
        console.log(data);
        if (options.platform == "Web") {
          forWebAction(data, options, callback);
        }
        if (options.platform == "App") {
          // this.forAppAction(data, options, callback);
          callback(data);
        }
      });
    } catch (e) {
      setfetchingEPSResourceToken(false);
      setisLoading(false);
      // setresourceDataLoading(false);

      console.log(e);
      window.alert(e.message);
    }
  };

  const forWebAction = (data, options, callback) => {
    try {
      switch (options.restype) {
        case "IMAGE":
          if (options.actionType == "download") {
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            a.download = options.actionType;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again

            setfetchingEPSResourceToken(false);
          } else {
            console.log("open");
            callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
            // var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
          }

          break;
        default:
          if (options.actionType == "download") {
            console.log("download");
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            a.download = options.actionType;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            console.log(a);
            a.click();
            a.remove(); //afterwards we remove the element again
          } else {
            console.log("open");
            var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
            window.open(url, "_blank");
          }

          setfetchingEPSResourceToken(false);
          break;
      }
    } catch (e) {
      setfetchingEPSResourceToken(false);
      console.log("Featch EPS doanload error", e);
      window.alert(e.message);
    }
  };

  const handleVideoBox = (data) => {
    console.log(data);

    setVideoLightBoxOpen(
      data
        ? {
            type: data.type,
            videoPath: data.Linkage,
            isOpen: true,
          }
        : null
    );
  };
  const handleImageBox = (id, path) => {
    setImageLightBoxOpen(id ? { name: id, image: path } : id);
  };

  const openResource = (data, type) => {
    console.log(data);
    if (type == "open") {
      gaTrack(
        "send",
        "event",
        "Supplementary",
        "Open resource in resource center",
        "Resource:" +
          data.ResourceLangs[0].ResourceName +
          ", Resource ID:" +
          data.IngestionId +
          ", View:" +
          groupBy +
          ", Book Series:" +
          preSetData.bookSeries.BookSeriesName[0].BookSeriesName +
          ", Page:" +
          selectedTopic.Level2TagLongName +
          ", Book:" +
          preSetData.book.BookName[0].BookName +
          ", Role:" +
          userData?.UserType
        // Resource, Resource ID, Book Series, View, Resource Page, Book, Role
      );
    }
    if (type == "download") {
      gaTrack(
        "send",
        "event",
        "Supplementary",
        "Download resource in resource center",
        "Resource:" +
          data.ResourceLangs[0].ResourceName +
          ", Resource ID:" +
          data.IngestionId +
          ", View:" +
          groupBy +
          ", Book Series:" +
          preSetData.bookSeries.BookSeriesName[0].BookSeriesName +
          ", Page:" +
          selectedTopic.Level2TagLongName +
          ", Book:" +
          preSetData.book.BookName[0].BookName +
          ", Role:" +
          userData?.UserType
        // Resource, Resource ID, Book Series, View, Resource Page, Book, Role
      );
    }
    if (window.cordova) {
      // app
      if (isAndroid) {
        console.log("[App] device: isAndroid");
        switch (data.ResourceType) {
          case "EBOOK4":
            window.open(data.ResourceAccessUrl, "_system");
            break;
          case "IMAGE":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "App",
                },
                (path) => {
                  //imagebox
                  if (type == "download") {
                    window.open(
                      path.resourceSearch.Resources[0].ResourceAccessUrl,
                      "_system"
                    );
                    // setLoading(false);
                    // setresourceDataLoading(false);
                  } else {
                    setImageLightBoxOpen({
                      id: data.ResourceId,
                      image: path.resourceSearch.Resources[0].ResourceAccessUrl,
                      obj: data,
                    });
                    setfetchingEPSResourceToken(false);
                  }
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_system");
            }
            break;
          case "WEB_LINK":
            browserRef = window.cordova.InAppBrowser.open(
              data.Linkage,
              "_blank",
              `hidenavigationbuttons=no,location=no,hideurlbar=no,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=no,toolbar=yes,footer=yes,closebuttoncaption=${t(
                "Close"
              )}`
            );
            break;
          case "YOUTUBE":
            handleVideoBox({ ...data, type: "youtube", obj: data });
            break;
          case "VIMEO":
            handleVideoBox({ ...data, type: "vimeo", obj: data });
            break;

          case "DOCUMENT":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  platform: "App",
                },
                (path) => {
                  //success call back
                  // this.AppDownloader(data);
                  window.open(
                    path.resourceSearch.Resources[0].ResourceAccessUrl,
                    "_system"
                  );
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_system");
            }
            break;
          case "LEARNOSITY":
            handleAppLearnosity(data);
            break;
          case "INTERACTIVE":
            const SessionId = localStorage.getItem("SessionId");
            const interactivePath =
              config.openResourcesApi +
              `&resid=${data.ResourceId}&sessionid=${SessionId}`;
            window.open(interactivePath, "_system");
            break;
          default:
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "App",
                },
                (path) => {
                  //success call back
                  // this.AppDownloader(data);
                  window.open(
                    path.resourceSearch.Resources[0].ResourceAccessUrl,
                    "_system"
                  );
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_system");
            }
            break;
        }
      }
      if (isIOS) {
        console.log("[App] device: isIOS");
        switch (data.ResourceType) {
          case "EBOOK4":
            window.open(data.ResourceAccessUrl, "_system");
            break;
          case "IMAGE":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "App",
                },
                (path) => {
                  //imagebox
                  if (type == "download") {
                    window.open(
                      path.resourceSearch.Resources[0].ResourceAccessUrl,
                      "_system"
                    );

                    // setLoading(false);
                    // setresourceDataLoading(false);
                  } else {
                    setImageLightBoxOpen({
                      id: data.ResourceId,
                      image: path.resourceSearch.Resources[0].ResourceAccessUrl,
                      obj: data,
                    });
                    setfetchingEPSResourceToken(false);
                  }
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_system");
            }
            break;
          case "WEB_LINK":
            browserRef = window.cordova.InAppBrowser.open(
              data.Linkage,
              "_blank",
              `hidenavigationbuttons=no,location=no,hideurlbar=no,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=no,toolbar=yes,footer=yes,closebuttoncaption=${t(
                "Close"
              )}`
            );
            break;
          case "YOUTUBE":
            handleVideoBox({ ...data, type: "youtube", obj: data });
            break;
          case "VIMEO":
            handleVideoBox({ ...data, type: "vimeo", obj: data });
            break;

          case "DOCUMENT":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  platform: "App",
                },
                (path) => {
                  //success call back
                  // this.AppDownloader(data);
                  window.open(
                    path.resourceSearch.Resources[0].ResourceAccessUrl,
                    "_system"
                  );
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_system");
            }
            break;
          case "LEARNOSITY":
            handleAppLearnosity(data);
            break;
          case "INTERACTIVE":
            const SessionId = localStorage.getItem("SessionId");
            const interactivePath =
              config.openResourcesApi +
              `&resid=${data.ResourceId}&sessionid=${SessionId}`;
            window.open(interactivePath, "_system");
            break;
          default:
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "App",
                },
                (path) => {
                  //success call back
                  // this.AppDownloader(data);
                  window.open(
                    path.resourceSearch.Resources[0].ResourceAccessUrl,
                    "_system"
                  );
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_system");
            }
            break;
        }
      }
    }

    if (
      //web
      !window.cordova &&
      (isBrowser ||
        isChrome ||
        isFirefox ||
        isSafari ||
        isOpera ||
        isIE ||
        isEdge ||
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        ))
    ) {
      if (isChrome) {
        console.log("[App] device Browser: isChrome");
      }
      if (isFirefox) {
        console.log("[App] device Browser: isFirefox");
      }
      if (isSafari) {
        console.log("[App] device Browser: isSafari");
      }
      if (isOpera) {
        console.log("[App] device Browser: isOpera");
      }
      if (isIE) {
        console.log("[App] device Browser: isIE");
      }
      if (isEdge) {
        console.log("[App] device Browser: isEdge");
      }
      if (
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        )
      ) {
        console.log(
          "[App] device Browser: should be samsung",
          navigator.userAgent
        );
      }

      //check is app only and no web
      if (type == "open") {
        console.log("open");
        switch (data.ResourceType) {
          case "WEB_LINK":
          case "VIDEO":
          case "DOCUMENT":
          case "AUDIO":
          case "OTHER":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(data.ResourceId, {
                actionType: "open",
                restype: data.ResourceType,
                platform: "Web",
              });
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;
          case "EBOOK4":
            window.open(data.ResourceAccessUrl, "_blank");
            break;
          case "IMAGE":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "Web",
                },
                (path) => {
                  //imagebox

                  setImageLightBoxOpen({
                    id: data.ResourceId,
                    image: path,
                    obj: data,
                  });
                  setfetchingEPSResourceToken(false);
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;
          case "LEARNOSITY":
            var newURL = window.location.pathname.replace(
              props.match.url,
              "/a/studygroup/" + tabClassId == null
                ? props.match.params.id
                : tabClassId +
                    "/assessment/" +
                    "qs" +
                    "/" +
                    data.ResourceId +
                    "/start"
            );
            window.open(newURL, "_blank");

            break;
          case "YOUTUBE":
            handleVideoBox({ ...data, type: "youtube", obj: data });
            break;
          case "VIMEO":
            handleVideoBox({ ...data, type: "vimeo", obj: data });
            break;

          default:
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(data.ResourceId, {
                actionType: "open",
                restype: data.ResourceType,
                platform: "Web",
              });
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;
        }
      }

      if (type == "download") {
        console.log("download");
        switch (data.ResourceType) {
          case "WEB_LINK":
          case "VIDEO":
          case "DOCUMENT":
          case "AUDIO":
          case "EBOOK4":
          case "LEARNOSITY":
          case "OTHER":
          case "IMAGE":
          default:
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(data.ResourceId, {
                actionType: "download",
                restype: data.ResourceType,
                platform: "Web",
              });
            } else {
              console.log(data);
              var a = document.createElement("a");
              a.href = data.ResourceAccessUrl;
              a.download = true;
              a.target = "_blank";
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
            }
            break;
        }
      }

      //only web
    }
  };

  const renderResources = () => {
    if (groupBy == "Group")
      return resourcesList.map((e, i) => <GroupRespurceItem {...e} key={i} />);
    return resourcesList.map((e, i) => <ListRespurceItem {...e} key={i} />);
  };

  const GroupRespurceItem = (resource) => {
    return (
      <div
        className="group-item"
        onClick={() => {
          openResource(resource, "open");
        }}
      >
        <img
          className="img"
          src={
            resource.Thumbnail != "" && resource.Thumbnail != null
              ? window.cordova
                ? "https:" + resource.Thumbnail.FilePath
                : resource.Thumbnail.FilePath
              : defaultImage
          }
          // style={{
          //   backgroundImage: `url(${
          //     resource.Thumbnail != "" && resource.Thumbnail != null
          //       ? window.cordova
          //         ? "https:" + resource.Thumbnail.FilePath
          //         : resource.Thumbnail.FilePath
          //       : defaultImage
          //   })`,
          // }}
        ></img>
        <p className="title">
          {
            resource.ResourceLangs[
              resource.ResourceLangs[0].Lang == lang ? 0 : 1
            ].ResourceName
          }
        </p>
      </div>
    );
  };

  const ListRespurceItem = (resource) => {
    // console.log(resource);
    return (
      <div
        className="list-item"
        onClick={() => {
          openResource(resource, "open");
        }}
      >
        <div className="left">
          <img src={suggestionTypeIconMapping(resource.ResourceType)}></img>
          <div className="list-title">
            {
              resource.ResourceLangs[
                resource.ResourceLangs[0].Lang == lang ? 0 : 1
              ].ResourceName
            }
          </div>
        </div>
        <div className="right">
          <i className="icon mdi mdi-chevron-right"></i>
        </div>
      </div>
    );
  };

  const renderOptions = () => {
    for (let index = 0; index < totalCount == 0 ? 1 : totalCount; index++) {
      // const element = array[index];
      return <option value={index + 1}>{index + 1}</option>;
    }
  };
  if (loading) return "";
  if (showEmpty)
    return (
      <MessageBox
        type={"B2CResourceCenter"}
        src={banner}
        message={t("There are no resources in this category.")}
      />
    );
  return (
    <div className="b2c_rcenter">
      <VidoeLightBox
        handleVideoBox={handleVideoBox}
        VideoLightBoxOpenState={videoLightBoxOpen}
      />

      <ImageLightBox
        isOpen={imageLightBoxOpen ? true : false}
        handleImageBox={handleImageBox}
        ImageLightBoxOpenState={imageLightBoxOpen}
        downloadImage={() => openResource(imageLightBoxOpen.obj, "download")}
      />

      {!window.cordova && fetchingEPSResourceToken && <Loading />}

      <div className="content-row">
        <div className="left-menu-box">
          <div
            className="mobile-title"
            onClick={() => setmobileOpen(!mobileOpen)}
          >
            <div>
              {t("Browse the resource...")}{" "}
              <div className="handle">
                <i className={`icon mdi mdi-more-vert`} />
              </div>
            </div>
          </div>
          <div className={`pannel ${mobileOpen ? "open" : ""}`}>
            <ul>
              {menuList &&
                menuList.map((o, i) => {
                  return (
                    <li
                      key={i}
                      className={`${
                        selectedTopic?.Level2TagName == o.Level2TagName
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        gaTrack(
                          "send",
                          "event",
                          "Supplementary",
                          "Open page in resource center",
                          "Page:" +
                            o.Level2TagLongName +
                            ", Book Series:" +
                            preSetData.bookSeries.BookSeriesName[0]
                              .BookSeriesName +
                            ", Book:" +
                            preSetData.book.BookName[0].BookName +
                            ", Role:" +
                            userData?.UserType
                        );
                        setpageNo(1);
                        setselectedTopic(o);
                      }}
                    >
                      {o.Level2TagLongName}
                    </li>
                  );
                })}

              {/* <li className="active">Topic 1</li> */}
            </ul>
          </div>
        </div>
        <div className="content-box">
          {selectedTopic && (
            <div className="top-row">
              <div className="title">{selectedTopic.Level2TagLongName}</div>
              <div className="right-buttons">
                <ul className="button-list">
                  <li
                    className="group-item"
                    onClick={() => {
                      gaTrack(
                        "send",
                        "event",
                        "Supplementary",
                        "Change view in resource center",
                        "View:Thumbnail, Role:" + userData?.UserType
                      );
                      localStorage.setItem("_B2C_RC_VIEW", "Group");
                      setgroupBy("Group");
                    }}
                  >
                    <i
                      className={`icon mdi mdi-view-module ${
                        groupBy == "Group" ? "active" : ""
                      }`}
                    ></i>
                  </li>
                  <li
                    className="group-item"
                    onClick={() => {
                      gaTrack(
                        "send",
                        "event",
                        "Supplementary",
                        "Change view in resource center",
                        "View:List, Role:" + userData?.UserType
                      );
                      localStorage.setItem("_B2C_RC_VIEW", "List");
                      setgroupBy("List");
                    }}
                  >
                    <i
                      className={`icon mdi mdi-view-list ${
                        groupBy == "List" ? "active" : ""
                      }`}
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="resource-body">
            {mobileOpen && (
              <div
                className={`menu-overlay ${mobileOpen ? "open" : ""}`}
                // onClick={() => setmobileOpen(false)}
              ></div>
            )}
            {resourcesList != null && renderResources()}
            {selectedTopic == null && (
              <MessageBox
                type={"ResourceCenter"}
                src={icon}
                message={t("Please select the resource to view")}
              />
            )}
          </div>
          {resourcesList != null && (
            <div className="footer-paginate">
              <select
                disabled={
                  resourcesList.length == 0 || totalCount == 0 ? true : false
                }
                className="paginate"
                onChange={(e) => {
                  setpageNo(parseInt(e.target.value));
                }}
              >
                {
                  renderOptions()
                  // .map((a, i) => (a = i))
                }
              </select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default B2CResourcesCenter;
