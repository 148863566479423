/**
 * @generated SignedSource<<00ac107a441cde9e4525b32ee7f26901>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type CheckAuthQuery$variables = {||};
export type CheckAuthQuery$data = {|
  +schoolYears: ?$ReadOnlyArray<?{|
    +SchoolYearId: ?string,
    +SchoolYearName: ?string,
    +IsEffective: ?string,
    +EffectiveStartDate: ?string,
    +EffectiveEndDate: ?string,
  |}>,
|};
export type CheckAuthQuery = {|
  variables: CheckAuthQuery$variables,
  response: CheckAuthQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SchoolYearName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IsEffective",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EffectiveStartDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EffectiveEndDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckAuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "schoolYear",
        "kind": "LinkedField",
        "name": "schoolYears",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CheckAuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "schoolYear",
        "kind": "LinkedField",
        "name": "schoolYears",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6395a906745a2c83910354c69021a22",
    "id": null,
    "metadata": {},
    "name": "CheckAuthQuery",
    "operationKind": "query",
    "text": "query CheckAuthQuery {\n  schoolYears {\n    SchoolYearId\n    SchoolYearName\n    IsEffective\n    EffectiveStartDate\n    EffectiveEndDate\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1da7f7b10ea8bb53e62b7cb09152ee95";

module.exports = ((node/*: any*/)/*: Query<
  CheckAuthQuery$variables,
  CheckAuthQuery$data,
>*/);
