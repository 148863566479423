import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import  from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const index = (props) => {
  //   const { t } = props;
  let query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    gaTrack("set", "dimension3", "App");
    const action = query.get("action");
    const lang = query.get("lang");
    if (lang) {
      dispatch({
        type: "SET_LANG",
        lang: query.get("lang"),
      });
    }
    switch (action) {
      case "INAPP":
      default:
        const groupId = query.get("groupId");
        const bookseriesId = query.get("bookseriesId");
        const bookId = query.get("bookId");
        const unitId = query.get("unitId");
        const resourceId = query.get("resourceId");
        const assignId = query.get("assignId");
        const b2c = query.get("b2c");
        const page = query.get("page");
        const inappData = {
          groupId,
          bookseriesId,
          bookId,
          unitId,
          resourceId,
          assignId,
          b2c,
          page,
        };
        // setinappData(inappData);
        console.log("inappData", inappData);
        localStorage.setItem("inappData", JSON.stringify(inappData));
        props.history.replace("/assessment=api/" + inappData.page);

        break;
    }
  }, []);

  return "";
};

export default index;
