import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetLearnosityTagHierarchyRefQuery = graphql`
  query GetLearnosityTagHierarchyRefQuery($HierarchyReference: String!) {
    learnosityTagHierarchyStructure(HierarchyReference: $HierarchyReference) {
      HierarchyReference
      RelatedTagType {
        HierarchyTagTypesId
        HierarchyReference
        TagType
        Description
        Tags {
          TagName
          TagLongName
        }
        ParentTagType {
          HierarchyTagTypesId
          HierarchyReference
          TagType
          Description
        }
      }
    }
  }
`;

const GetLearnosityTagHierarchyRef = (variables) => {
  return fetchQuery(environment, GetLearnosityTagHierarchyRefQuery, variables);
};

export default GetLearnosityTagHierarchyRef;
