import environment from "../../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
const DashboardStudyGroupStudentQuery = graphql`
  query DashboardStudyGroupStudentQuery(
    $UserId: String!
    $SchoolYearId: String!
  ) {
    user(UserId: $UserId) {
      UserId
      BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {
        SchoolYearId
        ClassNumber
        StudyGroupId
        StudyGroup {
          StudyGroupId
          Level {
            PearsonLevelId
            LevelName {
              Lang
              LevelName
              PearsonLevelId
            }
            PearsonLevelId
            DisplaySequence
          }
          LeapBookSeriesID
          BookSeries {
            BookSeriesId
            IngestionId
            IsB2CBookSeries
            DisplaySequence
            BookSeriesImage {
              FilePath
            }
            BookSeriesName {
              BookSeriesName
              Lang
            }
            Subject {
              SubjectName {
                SubjectName
                Lang
              }
            }
          }
          LeapLevelId
          StudyGroupName {
            StudyGroupName
            Lang
          }
          StudyGroupCode
          StudyGroupTheme {
            ColorTone
            BookSeriesImage {
              FilePath
            }
          }
        }
      }
    }
  }
`;

const DashboardStudyGroupTeacherQuery = graphql`
  query DashboardStudyGroupTeacherQuery(
    $UserId: String!
    $SchoolYearId: String!
    $FilterOnboarded: Boolean!
  ) {
    user(UserId: $UserId) {
      UserId
      School {
        StudyGroups(
          SchoolYearId: $SchoolYearId
          FilterOnboarded: $FilterOnboarded
        ) {
          StudyGroupId
          Level {
            PearsonLevelId
            LevelName {
              Lang
              LevelName
              PearsonLevelId
            }
            PearsonLevelId
            DisplaySequence
          }
          LeapBookSeriesID
          BookSeries {
            BookSeriesId
            IngestionId
            DisplaySequence
            BookSeriesImage {
              FilePath
            }
            BookSeriesName {
              BookSeriesName
              Lang
            }
            Subject {
              SubjectName {
                SubjectName
                Lang
              }
            }
          }
          LeapLevelId
          StudyGroupName {
            StudyGroupName
            Lang
          }
          StudyGroupCode
          StudyGroupTheme {
            ColorTone
            BookSeriesImage {
              FilePath
            }
          }
          StudyGroupInSameLevel(SchoolYearId: $SchoolYearId) {
            StudyGroupId
            StudyGroupName {
              Lang
              StudyGroupName
            }
          }
        }
      }
    }
  }
`;

const DashboardStudyGroup = (variables, role) => {
  return fetchQuery(
    environment,
    role == "S"
      ? DashboardStudyGroupStudentQuery
      : DashboardStudyGroupTeacherQuery,
    variables
  );
};

export default DashboardStudyGroup;
