/**
 * @generated SignedSource<<a06274f05264377c1ff13630ca4a81ac>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type RelatedAssignmentTabelGetInfoQuery$variables = {|
  ResourceId?: ?$ReadOnlyArray<string>,
  StudyGroupId: string,
  SchoolYearId: string,
|};
export type RelatedAssignmentTabelGetInfoQuery$data = {|
  +resourceSearch: ?{|
    +Resources: ?$ReadOnlyArray<?{|
      +AssignementInvloved: ?$ReadOnlyArray<?{|
        +LastUpdateDate: ?string,
        +BookSeries: ?{|
          +BookSeriesId: ?string,
          +BookSeriesName: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +BookSeriesName: ?string,
          |}>,
        |},
        +AssignmentName: ?string,
        +CreateDate: ?string,
        +DueDate: ?string,
        +AssignmentTargets: ?$ReadOnlyArray<?{|
          +ResourceAssignId: ?string,
          +UserId: ?string,
          +StudyGroupId: ?string,
          +User: ?{|
            +UserDisplayName: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +UserName: ?string,
            |}>,
            +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
              +ClassNumber: ?string,
              +StudyGroup: ?{|
                +BookSeries: ?{|
                  +BookSeriesId: ?string,
                |},
                +StudyGroupName: ?$ReadOnlyArray<?{|
                  +Lang: ?string,
                  +StudyGroupName: ?string,
                |}>,
              |},
            |}>,
          |},
          +StudyGroup: ?{|
            +StudyGroupUser: ?$ReadOnlyArray<?{|
              +UserId: ?string,
            |}>,
            +StudyGroupName: ?$ReadOnlyArray<?{|
              +StudyGroupId: ?string,
              +Lang: ?string,
              +StudyGroupName: ?string,
            |}>,
          |},
        |}>,
        +AssignmentTargetStatus: ?$ReadOnlyArray<?{|
          +ResourceAssignId: ?string,
          +UserId: ?string,
          +Status: ?string,
        |}>,
      |}>,
    |}>,
  |},
|};
export type RelatedAssignmentTabelGetInfoQuery = {|
  variables: RelatedAssignmentTabelGetInfoQuery$variables,
  response: RelatedAssignmentTabelGetInfoQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ResourceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v3 = [
  {
    "kind": "Literal",
    "name": "PageSize",
    "value": 9999
  },
  {
    "kind": "Variable",
    "name": "ResourceId",
    "variableName": "ResourceId"
  }
],
v4 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId"
},
v5 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LastUpdateDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookSeriesId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "bookSeriesLang",
  "kind": "LinkedField",
  "name": "BookSeriesName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "BookSeriesName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AssignmentName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "CreateDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DueDate",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "FilterCurrentUser",
    "value": false
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ResourceAssignId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "UserId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "userLang",
  "kind": "LinkedField",
  "name": "UserDisplayName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "UserName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = [
  (v4/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ClassNumber",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v20/*: any*/)
  ],
  "storageKey": null
},
v22 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "UserType",
    "value": "S"
  }
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    (v16/*: any*/),
    (v8/*: any*/),
    (v20/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "ResourceAssignmentTargetStatus",
  "kind": "LinkedField",
  "name": "AssignmentTargetStatus",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedAssignmentTabelGetInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "resourceAssignment",
                "kind": "LinkedField",
                "name": "AssignementInvloved",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "ResourceAssignmentTarget",
                    "kind": "LinkedField",
                    "name": "AssignmentTargets",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "User",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": (v18/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "kind": "LinkedField",
                            "name": "BelongsToStudyGroup",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StudyGroup",
                                "kind": "LinkedField",
                                "name": "StudyGroup",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "bookSeries",
                                    "kind": "LinkedField",
                                    "name": "BookSeries",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v21/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyGroup",
                        "kind": "LinkedField",
                        "name": "StudyGroup",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v22/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "kind": "LinkedField",
                            "name": "StudyGroupUser",
                            "plural": true,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "AssignmentTargets(FilterCurrentUser:false)"
                  },
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RelatedAssignmentTabelGetInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "resourceSearchResult",
        "kind": "LinkedField",
        "name": "resourceSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resource",
            "kind": "LinkedField",
            "name": "Resources",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "resourceAssignment",
                "kind": "LinkedField",
                "name": "AssignementInvloved",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "bookSeries",
                    "kind": "LinkedField",
                    "name": "BookSeries",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v25/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "ResourceAssignmentTarget",
                    "kind": "LinkedField",
                    "name": "AssignmentTargets",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "User",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": (v18/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "kind": "LinkedField",
                            "name": "BelongsToStudyGroup",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StudyGroup",
                                "kind": "LinkedField",
                                "name": "StudyGroup",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "bookSeries",
                                    "kind": "LinkedField",
                                    "name": "BookSeries",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v25/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v21/*: any*/),
                                  (v25/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v25/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudyGroup",
                        "kind": "LinkedField",
                        "name": "StudyGroup",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v22/*: any*/),
                            "concreteType": "studyGroupUserCustomType",
                            "kind": "LinkedField",
                            "name": "StudyGroupUser",
                            "plural": true,
                            "selections": [
                              (v15/*: any*/),
                              (v25/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "AssignmentTargets(FilterCurrentUser:false)"
                  },
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5bf0892d4f707fc2aaa7021208bfc081",
    "id": null,
    "metadata": {},
    "name": "RelatedAssignmentTabelGetInfoQuery",
    "operationKind": "query",
    "text": "query RelatedAssignmentTabelGetInfoQuery(\n  $ResourceId: [String!]\n  $StudyGroupId: String!\n  $SchoolYearId: String!\n) {\n  resourceSearch(ResourceId: $ResourceId, PageSize: 9999) {\n    Resources {\n      AssignementInvloved(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId) {\n        LastUpdateDate\n        BookSeries {\n          BookSeriesId\n          BookSeriesName {\n            Lang\n            BookSeriesName\n          }\n          id\n        }\n        AssignmentName\n        CreateDate\n        DueDate\n        AssignmentTargets(FilterCurrentUser: false) {\n          ResourceAssignId\n          UserId\n          StudyGroupId\n          User {\n            UserDisplayName {\n              Lang\n              UserName\n            }\n            BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n              ClassNumber\n              StudyGroup {\n                BookSeries {\n                  BookSeriesId\n                  id\n                }\n                StudyGroupName {\n                  Lang\n                  StudyGroupName\n                }\n                id\n              }\n              id\n            }\n            id\n          }\n          StudyGroup {\n            StudyGroupUser(SchoolYearId: $SchoolYearId, UserType: S) {\n              UserId\n              id\n            }\n            StudyGroupName {\n              StudyGroupId\n              Lang\n              StudyGroupName\n            }\n            id\n          }\n        }\n        AssignmentTargetStatus {\n          ResourceAssignId\n          UserId\n          Status\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "cbf76fb53b2d1c9d254741fca606750b";

module.exports = ((node/*: any*/)/*: Query<
  RelatedAssignmentTabelGetInfoQuery$variables,
  RelatedAssignmentTabelGetInfoQuery$data,
>*/);
