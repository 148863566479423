import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";

const GetSmartClassTokenQuery = graphql`
  query GetSmartClassTokenQuery {
    smartClassSSOToken
  }
`;

const GetSmartClassToken = () => {
  return fetchQuery(environment, GetSmartClassTokenQuery);
};

export default GetSmartClassToken;
